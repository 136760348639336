import React, { useEffect, useState } from 'react'
import TextField from '../../../Components/common/TextField'
import Grid from '@mui/material/Grid'
import Button from '../../../Components/common/Button'
import MenuItem from '../../../Components/common/MenuItem'

import isEqual from 'react-fast-compare'
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import Notifications from 'react-notification-system-redux'
import Autocomplete from '../../../Components/common/Autocomplete'

// import {
//   addComputedAttribute,
//   addComputedAttributeDeviceWiseSelectedList
// } from './../../../Actions/ComputedAttributes'
import Checkbox from './../../../Components/common/Checkbox'
import { updateDevice } from '../../../Actions/Devices'
import instance from '../../../axios'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import { useDispatch, useSelector } from 'react-redux'

const Accumulators = (props) => {
  let resData = {
    totalDistance: null,
    hours: null,
    mileage: null,
    mileageAccuracy: '',
    mileageMinLimit: '',
    mileageMaxLimit: '',
    mFilter: false,
    mileageCounter: 'gps',
    mileageUnit: 'm',
    expression: ''
  }

  const [state, setState] = useState({
    isDisableBtn: true,
    assigned: false,
    currentId: 0,
    type: 'mileage',
    ...resData,
    resData
  })
  const [isLoading,setIsLoading]=useState(false)
  const devices=useSelector((state)=>state.devices.data)
  const {positions}=useSelector((state)=>state)
  const dispatch=useDispatch()

  


  const assignValues = () => {
    let exists = false
    let device = {}

    devices.map(d => {
      if (parseInt(props.deviceId) === parseInt(d.id)) {
        device = d
      }
      return null
    })
    positions.map(p => {
      if (parseInt(props.deviceId) === parseInt(p.deviceId)) {
        exists = true
        setState((prevState) => ({
          ...prevState,
          assigned: true,
          currentId: p.deviceId
        }))
        let a = p.attributes
        let attr = device.attributes || {}
        let expression = ''
        let mileageCounter = ''
        let mileageUnit = attr && attr.mileageUnit || 'm'
        let mileageMinLimit = attr && attr.mileageMinLimit || "0"
        let mileageMaxLimit = attr && attr.mileageMaxLimit || "0"
        let mFilter = attr?.mFilter || false
        if (
          attr &&
          attr.mileageCounter &&
          attr.mileageCounter !== 'gps' &&
          attr.mileageCounter !== 'deviceOdometer'
        ) {
          expression = attr.mileageCounter
          mileageCounter = 'alternative'
        } else {
          mileageCounter = attr.mileageCounter || 'gps'
        }
        if (attr && attr['report.ignoreOdometer']) {
          let resData = {
            totalDistance: a.totalDistance
              ? (a.totalDistance / 1000).toFixed(2)
              : null,
            hours: a.hours ? (a.hours / (1000 * 3600)).toFixed(2) : null,
            mileageAccuracy: attr.mileageAccuracy,
            mileageCounter,
            mileageUnit,
            mileageMinLimit,
            mileageMaxLimit,
            mFilter,
            expression
          }
          setState((prevState) => ({
            ...prevState,
            type: 'distance',
            ...resData,
            resData
          }))
        } else {
          let resData = {
            totalDistance: a.totalDistance
              ? (a.totalDistance / 1000).toFixed(2)
              : null,
            hours: a.hours ? (a.hours / (1000 * 3600)).toFixed(2) : null,
            mileageAccuracy: attr.mileageAccuracy,
            mileageCounter,
            mileageUnit,
            mileageMinLimit,
            mFilter,
            mileageMaxLimit,
            expression
          }
          setState((prevState) => ({
            ...prevState,
            type: 'mileage',
            ...resData,
            resData,
            isDisableBtn: true
          }))
        }
        return null
      }
      return null
    })
    if (!exists) {
      let resData = {
        // totalDistance: null,
        // hours: null,
        mileage: null,
        // mileageAccuracy: '',
        mileageCounter:
          // state.mileageCounter ? state.mileageAccuracy :
          device && device.attributes && device.attributes.mileageCounter
            ? device.attributes.mileageCounter
            : 'gps'
      }
      setState((prevState) => ({
        ...prevState,
        assigned: false,
        currentId: 0,
        type: 'mileage',
        ...resData,
        resData
      }))
    }
  }
  useEffect(() => {
    assignValues();
  }, []);
  useEffect(() => {
    if (devices.length && positions.length && state.currentId !== parseInt(props.deviceId)) {
      assignValues()
    }
  }, [devices, positions, props.deviceId, state.currentId])

  const handleChange = (name, event) => {
    let { resData } = state
    let dataValue =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    // if (!dataValue || dataValue === '0') {
    if (!dataValue) {
      dataValue = null
    }
    // if(state.mileageCounter==='gps'){
    setState((prevState) => ({
      ...prevState,
      [name]: name === "mFilter" ? !state.mFilter : dataValue
    }))
    let { hours, mileageAccuracy, totalDistance, mileageMinLimit, mileageMaxLimit, mFilter } = state
    let obj = {
      hours,
      mileageAccuracy,
      totalDistance,
      mileageMinLimit,
      mFilter,
      mileageMaxLimit
    }
    let value = !isEqual(resData, obj)
    setState((prevState) => ({
      ...prevState,
      isDisableBtn: !value
    }))
  }

  const handleChange2 = (name, newValue) => {
    // let { resData } = state
    setState((prevState) => ({
      ...prevState,
      [name]: newValue
    }))
  }

  const save = () => {
    const { hours, totalDistance, mileageAccuracy } = state
    let id = props.deviceId
      let form = {
        totalDistance: totalDistance * 1000,
        mileageAccuracy: mileageAccuracy,
        hours: hours * 1000 * 3600,
        deviceId: id,

      }


      if (id) {
        updateAccumulators(id, form)
    }
  }

  const updateDevices = dev => {
    let visible = dev.visible
    delete dev.visible
    delete dev.indeterminate
    delete dev.parent
    // fetch(`/api/devices/${dev.id}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...dev
    //   })
    // })
    setIsLoading(true)
    instance({
      url: `/api/devices/${dev.id}`,
      method: 'PUT',
      data: {
        ...dev
      }
    })
    // .then(response => {
    //   if (response.status === 200) {
    //     response.json()
        .then(device => {
          device['visible'] = visible || false
          dispatch(updateDevice(device))
          //props.enqueueSnackbar(props.translate('accumulatorIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          dispatch(
            Notifications.success({
              message: props.translate('accumulatorIsUpdated'),
              autoDismiss: 10
            })
          )
    setIsLoading(false)

          setState((prevState)=>({
            ...prevState,
            isDisableBtn:true
          }))
        })
        .catch(error => {
    setIsLoading(false)
          
          errorHandler(error,dispatch)})
    //   }
    // })
  }

  const updateAccumulators =(id, form)=> {
    setIsLoading(true)
    instance({
      url: `/api/devices/${id}/accumulators`,
      method: 'PUT',
      data: {
        ...form
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars

          let device = devices.find(
            d => parseInt(props.deviceId) === parseInt(d.id)
          )

          device.attributes['mileageAccuracy'] = form['mileageAccuracy']

          let mileageCounter = ''
          if (
            state &&
            state.mileageCounter !== 'gps' &&
            state.mileageCounter !== 'deviceOdometer'
          ) {
            mileageCounter = state.expression
          } else {
            mileageCounter = state.mileageCounter || 'gps'
          }

          device.attributes['mileageCounter'] = mileageCounter
          if(state.mileageUnit && state.mileageCounter !== 'gps'){
            device.attributes['mileageUnit'] = state.mileageUnit
            if(state.mFilter){
              device.attributes['mileageMinLimit'] = state.mileageMinLimit ? parseFloat(state.mileageMinLimit) : 0
              device.attributes['mileageMaxLimit'] = state.mileageMaxLimit ? parseFloat(state.mileageMaxLimit) : 0
              device.attributes['mFilter'] = state.mFilter
            }
            else{
              device.attributes['mFilter'] = false
              delete device.attributes['mileageMinLimit']
              delete device.attributes['mileageMaxLimit']
            }
          }
          else{
            delete device.attributes['mileageUnit']
            delete device.attributes['mileageMinLimit']
            delete device.attributes['mileageMaxLimit']
            delete device.attributes['mFilter']
          }

          updateDevices(device)

          //props.enqueueSnackbar(props.translate('accumulatorIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        // } else {
        //   throw response
        // }
      })
      .catch(error => {
    setIsLoading(false)

        errorHandler(error,dispatch)}
      )
  }

  // const addComputedAttributes= (deviceId, form) =>{
  //   instance({
  //     url: `/api/attributes/computed/`,
  //     method: 'POST',
  //     data: {
  //       ...form
  //     }
  //   })
  //         .then(Attribute => {
  //           dispatch(
  //             addComputedAttributeDeviceWiseSelectedList(Attribute)
  //           )
  //           dispatch(addComputedAttribute(Attribute))
  //           //props.enqueueSnackbar(props.translate('newAttributeAdded'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
  //           dispatch(
  //             Notifications.success({
  //               message: props.translate('newAttributeAdded'),
  //               autoDismiss: 10
  //             })
  //           )
  //         computedAttributes_Selection(deviceId, Attribute.id)
  //       //   })
  //       // } else {
  //       //   throw response
  //       // }
  //     })
  //     .catch(error => {errorHandler(error,dispatch)})
  // }

  // const computedAttributes_Selection =(deviceId, attributeId)=> {
  //   let obj = {
  //     deviceId,
  //     attributeId
  //   }
  //   instance({
  //     url: `/api/permissions/`,
  //     method: 'POST',
  //     data: {
  //       ...obj
  //     }
  //   })
  //     .then(response => {// eslint-disable-line no-unused-vars
  //         dispatch(
  //           Notifications.success({
  //             message:props.translate('attributeAssignedSuccessfully'),
  //             autoDismiss: 10
  //           })
  //         )
  //     })
  //     .catch(error => {errorHandler(error,dispatch)})
  // }

  return (
    <div>
      <div
        style={{ textAlign: 'right', padding: '16px' }}
        className='clearfix'
      >
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('deviceMileage')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <TextField
              id='mileageCounter'
              label={props.translate('mileageCounter')}
              type='text'
              value={state.mileageCounter ? state.mileageCounter : 'gps'}
              onChange={e => handleChange('mileageCounter', e)}
              variant='outlined'
              margin='dense'
              fullWidth
              select
            >
              <MenuItem value={'gps'}>{props.translate('gps')}</MenuItem>
              <MenuItem value={'deviceOdometer'}> {props.translate('deviceOdometer')} </MenuItem>
              <MenuItem value={'alternative'}>  {props.translate('alternativeSensor')} </MenuItem>
            </TextField>
          </Grid>
          {state.mileageCounter !== 'gps' &&
          state.mileageCounter !== 'deviceOdometer' ? (
            <Grid item sm={4} xs={12}>
              <Autocomplete
                translate={props.translate}
                value={state.expression || ''}
                suggestions={props.suggestions}
                handleChange={handleChange2}
                fieldName='expression'
                label={props.translate('input')}
                prefix='position'
              />
            </Grid>
          ) : null}
          {state.mileageCounter === 'gps' ?
            <Grid item sm={4} xs={12}>
            <TextField
              id='totalDistance'
              label={props.translate('odometer') + ' (Km)'}
              type='number'
              value={state.totalDistance || '0'}
              onChange={e => handleChange('totalDistance', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>:null}
          <Grid item sm={4} xs={12}>
            <TextField
              id='mileageAccuracy'
              label={props.translate('deviceMileageAccuracyPercentage')}
              type='number'
              value={state.mileageAccuracy || '0'}
              onChange={e => handleChange('mileageAccuracy', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          {((state.mileageCounter !== 'gps') &&
            (state.mileageCounter === 'deviceOdometer'||state.mileageCounter === 'alternative' )) ? (
            <Grid item sm={4} xs={12}>
             <TextField
              id='mileageUnit'
              label={props.translate('mileageUnit')}
              type='text'
              value={  state.mileageUnit ? state.mileageUnit : 'gps'  }
              onChange={e => handleChange('mileageUnit', e)}
              variant='outlined'
              margin='dense'
              fullWidth
              select
            >
              <MenuItem value={'m'}>{props.translate('meter')}</MenuItem>
              <MenuItem value={'mi'}>{props.translate('miles')} </MenuItem>
              <MenuItem value={'nmi'}>{props.translate('noticalMiles')}</MenuItem>
              <MenuItem value={'km'}>{props.translate('kilometer')}</MenuItem>
            </TextField>
            </Grid>
          ) : null}
          {((state.mileageCounter !== 'gps') &&
            (state.mileageCounter === 'deviceOdometer'||state.mileageCounter === 'alternative' )) ? (
              <Grid item sm={4} xs={12}>
                <Checkbox
                  checked={state.mFilter &&  state.mFilter}
                  onChange={e => handleChange('mFilter',e)}
                  canAssign
                  canRemove
                  label={
                    state.mFilter===true
                      ? props.translate('enable') +" " + props.translate('mileageLimit')
                      : props.translate('disable') +" " + props.translate('mileageLimit')
                  }
                />
             </Grid>
               
            ) : null}
        </Grid>
        <Grid container spacing={2}>
          {((state.mileageCounter !== 'gps') && (state.mFilter) &&
              (state.mileageCounter === 'deviceOdometer'||state.mileageCounter === 'alternative' )) ? (
              <Grid item sm={4} xs={12}>
              <TextField
                  id='mileageMinLimit'
                  label={props.translate('mileageMinLimit')}
                  type='text'
                  value={state.mileageMinLimit === null ? '':state.mileageMinLimit}
                  onChange={e => handleChange('mileageMinLimit', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            ) : null}
            {((state.mileageCounter !== 'gps') && (state.mFilter) &&
              (state.mileageCounter === 'deviceOdometer'||state.mileageCounter === 'alternative' )) ? (
              <Grid item sm={4} xs={12}>
              <TextField
                  id='mileageMinLimit'
                  label={props.translate('mileageMaxLimit')}
                  type='text'
                  value={state.mileageMaxLimit === null ? '':state.mileageMaxLimit}
                  onChange={e => handleChange('mileageMaxLimit', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            ) : null}
        </Grid>
        {props.selecteditem &&
          props.selecteditem.model !== 'FX Tracker' && (
            <div
              style={{ textAlign: 'right', padding: '16px 16px 8px 0' }}
              className='clearfix'
            >
              <h4 className='v-tab-heading pull-left with-border'>
                {props.translate('reportEngineHours')}
              </h4>
            </div>
          )}
        <Grid container spacing={2}>
          {props.selecteditem &&
            props.selecteditem.model !== 'FX Tracker' && (
              <Grid item sm={4} xs={12}>
                <TextField
                  id='hours'
                  label={props.translate('reportEngineHours') + ' (Hrs)'}
                  type='number'
                  value={state.hours || '0'}
                  onChange={e => handleChange('hours', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            )}
          <Grid item xs={12} style={{ textAlign: 'right',marginTop:'20px' }}>
            {props.isVisablBtn && (
              <Button
                disabled={!state.currentId || state.isDisableBtn ||isLoading}
                // disabled={state.isDisableBtn}
                onClick={save}
              >
                  {isLoading?"loading":props.translate('sharedSave')}
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
      {checkPrivileges('workflow') && (
      <>
        <div
          style={{ textAlign: 'right', padding: '16px' }}
          className='clearfix'
        >
          <h4 className='v-tab-heading pull-left with-border'>
            {props.translate('Work Flow')}
          </h4>
        </div>
        <div style={{ padding: '0 16px' }}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <TextField
                id='interCityMileage'
                label={props.translate('OutSide City Mileage') + ' (Km)'}
                type='number'
                value={state.interCityMileage || ''}
                onChange={e => handleChange('interCityMileage', e)}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                id='cityMileage'
                label={props.translate('InSide City Mileage') + ' (Km)'}
                type='number'
                value={state.cityMileage || ''}
                onChange={e => handleChange('cityMileage', e)}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
          </Grid>
  
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
                <p>Work Flow : {props.selecteditem?.attributes?.onWorkFlow ? "on": "off"}</p>
            </Grid>

          </Grid>
          <Grid container spacing={2}>
            
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              {props.isVisablBtn && (
                <Button
                  disabled={!state.currentId || state.isDisableBtn || isLoading}
                  // disabled={state.isDisableBtn}
                  onClick={save}
                >
                  {isLoading?"loading":props.translate('sharedSave')}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        </>
     )} 
    </div>
  )
}
export default Accumulators