import React from "react";
import { connect } from "react-redux";
import Card from "../../Dashboard/components/Card/CardPremium";
import { GeoList } from "../../Dashboard";
import Scrollbar from "react-scrollbars-custom";


const ComponentBase = React.memo(({themecolors, gadget, geofences, insideGeofence, handleItem})=> {
  return (<Card themecolors={themecolors}>
    <h3 className="cart-title">{gadget.name}</h3>
    <Scrollbar
        disableTracksWidthCompensation={true}
        style={{
            height: 38 * 9
        }}
        >
        <GeoList
        data={geofences}
        showSelectedUnits={handleItem}
        geoIds={insideGeofence}
        />
    </Scrollbar>
  </Card>);
});

ComponentBase.displayName = 'ComponentBase';

const mapStateToProps = state => ({
    themecolors: state.themeColors,
    geofences: state.geoFence,
    insideGeofence: state.insideGeofence,
});
export const Component = connect(mapStateToProps)(ComponentBase)
Component.displayName = 'ConnectedComponent';
