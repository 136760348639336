import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { EditItem } from './EditItem'
import AddUserModal from './addUserModal'
import MarkerMap from '../common/Maps'
import CustomDialog from '../common/Dialog'
import { addUser, removeUser, updateUser } from '../../Actions/Users'
import Button from '../common/Button'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './usersModal.scss'
import isEqual from 'react-fast-compare'
import { checkPrivileges, errorHandler, validEmailPattern } from '../../Helpers/index'
import Notifications from 'react-notification-system-redux'
import { checkUnitsWithStandardType } from './userDataTypes'
import Loader from '../../Layout/Loader'
import instance from '../../axios'
import { useDispatch, useSelector } from 'react-redux'
const userDataFormat = (data, extAttriubtes, itemId) => {
  if (data.roleId === 'defaultRole') {
    data.roleId = ''
  }
  return {
    administrator: data.userType === 1 ? false : true,
    attributes: extAttriubtes ? extAttriubtes : {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : '',
    deviceLimit: data.deviceLimit,
    deviceReadonly:
      data.deviceReadonly === 'on' || data.deviceReadonly === true
        ? true
        : false,
    disabled: data.disabled === 'on' || data.disabled === true ? true : false,
    email: data.email.trim(),
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: itemId ? itemId : 0,
    latitude: data.latitude ? data.latitude : 0,
    limitCommands:
      data.limitCommands === 'on' || data.limitCommands === true ? true : false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    map: data.map,
    name: data.name.trim(),
    password: data.password,
    phone: data.phone,
    poiLayer: data.poiLayer,
    readonly: data.readonly === 'on' || data.readonly === true ? true : false,
    token: data.token,
    twelveHourFormat:
      data.twelveHourFormat === 'on' || data.twelveHourFormat === true
        ? true
        : false,
    userLimit: data.userLimit,
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId ? data.roleId : data.userType === 1 ? 2 : 3,
    userType: parseInt(data.userType) || 1,
    parentId: data.parentId || 0
  }
}
const attributesDefaultValue = {
  distanceUnit: 'km',
  speedUnit: 'kmh',
  volumeUnit: 'ltr',
  measurementStandard: 'metric',
  currencyLabel: 'AED',
  viewIMEI: false,
  viewModel: false,
   unitsViewType:"default"
}
const formDefaultState = {
  name: '',
  email: '',
  attributes: { ...attributesDefaultValue },
  password: "",
  confirmPassword: "",
  phone: '',
  map: '',
  latitude: '',
  longitude: '',
  zoom: 12,
  twelveHourFormat: '',
  coordinateFormat: '',
  poiLayer: '',
  disabled: '',
  admin: '',
  readonly: '',
  deviceReadonly: '',
  limitCommands: '',
  deviceLimit: 0,
  userLimit: 0,
  token: '',
  expirationTime: null,
  userType: '',
  accountId: 0,
  parentId: 0
}
const userInitialStates = {
  loading: false,
  deleteProcess: false,
  selectedUser: '',
  addOption: false,
  editOption: false,
  u_attriubtes: '',
  selectedOption: false,
  passwordOption: false,
  selectedUserTrackesList: '',
  isSelectedUser: false,
  localTime: new Date(),
  directOption: false,
  isVisableUserBtn: false,
  isAttChanged: false,
  usesId: '',
  isMapModal: false,
  duplicateEntry: false,
  isVisableAddlocBtn: false,
  localPostionObj: '',
  selectedUsers: '',
  usersArray: [],
  itemAttributes: {},
  attributeChangesMessage: '',
  defaultAccountsList: [],
  defaultUsersList: [],
  accountSelectedData: '',
  userSelectedData: '',
  isUserRecivedList: true,
  // all fields of form
  form: {
    ...formDefaultState
  },
  lengthCheck: false,
  lowerCaseCheck: false,
  upperCaseCheck: false,
  numberCheck: false,
  characterCheck: false,
  confirmPasswordCheck: false
}
const usersReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM':
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload
        }
      };
    case 'SET_IS_VALID_PASS':
      return {
        ...state,
        isValidPassword: action.payload
      };
    case 'SET_IS_LENGTH_CHK':
      return {
        ...state,
        lengthCheck: action.payload
      };
    case 'SET_IS_LOWER_CSE_CHK':
      return {
        ...state,
        lowerCaseCheck: action.payload
      };
    case 'SET_IS_UPPER_CSE_CHK':
      return {
        ...state,
        upperCaseCheck: action.payload
      };
    case 'SET_IS_NUMBER_CHK':
      return {
        ...state,
        numberCheck: action.payload
      };
    case 'SET_IS_CHAR_CHK':
      return {
        ...state,
        characterCheck: action.payload
      };
    case 'SET_IS_CNFRM_PASS_CHK':
      return {
        ...state,
        confirmPasswordCheck: action.payload
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'SET_IS_DELETE_PROCESS':
      return {
        ...state,
        deleteProcess: action.payload
      };
    case 'SET_IS_ADD_OPTION':
      return {
        ...state,
        addOption: action.payload
      };
    case 'SET_IS_EDIT_OPTION':
      return {
        ...state,
        editOption: action.payload
      };
    case 'SET_U_ATTRIBUTES':
      return {
        ...state,
        u_attriubtes: action.payload
      };
    case 'SET_IS_SELECTED_OPTION':
      return {
        ...state,
        selectedOption: action.payload
      };
    case 'SET_IS_PASSWORD_OPTION':
      return {
        ...state,
        passwordOption: action.payload
      };
    case 'SET_SELECTED_USER_TRACKES_LIST':
      return {
        ...state,
        selectedUserTrackesList: action.payload
      };
    case 'SET_IS_SELECTED_USER':
      return {
        ...state,
        isSelectedUser: action.payload
      };
    case 'SET_LOCAL_TIME':
      return {
        ...state,
        localTime: action.payload
      };
    case 'SET_IS_DIRECT_OPTION':
      return {
        ...state,
        directOption: action.payload
      };
    case 'SET_IS_VISABLE_USER_BTN':
      return {
        ...state,
        isVisableUserBtn: action.payload
      };
    case 'SET_IS_ATT_CHANGED':
      return {
        ...state,
        isAttChanged: action.payload
      };
    case 'SET_USER_ID':
      return {
        ...state,
        usesId: action.payload
      };
    case 'SET_IS_MAP_MODAL':
      return {
        ...state,
        isMapModal: action.payload
      };
    case 'SET_IS_DUPLICATE_ENTRY':
      return {
        ...state,
        duplicateEntry: action.payload
      };
    case 'SET_IS_VISABLE_ADD_LOC_BTN':
      return {
        ...state,
        isVisableAddlocBtn: action.payload
      };
    case 'SET_LOCAL_POSTION_OBJ':
      return {
        ...state,
        localPostionObj: action.payload
      };
    case 'SET_SELECTED_USER':
      return {
        ...state,
        selectedUser: action.payload
      };
    case 'SET_SELECTED_USERS':
      return {
        ...state,
        selectedUsers: action.payload
      };
    case 'SET_USERS_ARRAY':
      return {
        ...state,
        usersArray: action.payload
      };
    case 'SET_ITEM_ATTRIBUTES':
      return {
        ...state,
        itemAttributes: action.payload
      };
    case 'SET_ATTRIBUTES_MESSAGE':
      return {
        ...state,
        attributeChangesMessage: action.payload
      };
    case 'SET_DEFAULT_ACC_LIST':
      return {
        ...state,
        defaultAccountsList: action.payload
      };
    case 'SET_DEFAULT_USERS_LIST':
      return {
        ...state,
        defaultUsersList: action.payload
      };
    case 'SET_ACC_SELECTED_DATA':
      return {
        ...state,
        accountSelectedData: action.payload
      };
    case 'SET_USER_SELECTED_DATA':
      return {
        ...state,
        userSelectedData: action.payload
      };
    case 'SET_IS_USER_RECIVED_LIST':
      return {
        ...state,
        isUserRecivedList: action.payload
      };
    default:
      return state;
  }
};
export const UsersModal = (props) => {
  const [state, dispatchReducer] = useReducer(usersReducer, userInitialStates);
  const logInUser = useSelector((state) => state.logInUsers)
  const dispatch = useDispatch()
const [isLoading,setIsLoading]=useState(false)
  // const fetchAccountsList = (id) => {
  //   let params = {
  //     accountId: id,
  //     limit: 100
  //   }
  //   if (props.logInUser.userType === -1) {
  //     params = {
  //       ...params,
  //       all: true
  //     }
  //   }
  //   instance({
  //     url: `/api/accounts/all`,
  //     method: 'GET',
  //     params: {
  //       ...params
  //     }
  //   }).then(userlist => {
  //     let result =
  //       userlist.data &&
  //       userlist.data.map(item => ({
  //         ...item,
  //         label: item.label || item.name,
  //         value: item.id
  //       }))
  //     result.unshift({
  //       id: props.ServerSetting.id,
  //       label: props.ServerSetting.name,
  //       value: props.ServerSetting.id
  //     })
  //     dispatchReducer({ type: "SET_DEFAULT_ACC_LIST", payload: result })
  //   }).catch(() => { })
  // }
  const fetchUsersList = (id) => {
    instance({
      url: `/api/users/list`,
      method: 'GET',
      params: {
        accountId: id,
        limit: 100
      }
    }).then(userlist => {
      let result =
        userlist.data &&
        userlist.data.map(item => ({
          ...item,
          label: item.label || item.name,
          value: item.id
        }))
      if (parseInt(id) === parseInt(props.ServerSetting.id)) {
        result.unshift({
          id: logInUser.id,
          label: logInUser.name,
          value: logInUser.id
        })
      }
      dispatchReducer({ type: "SET_DEFAULT_ACC_LIST", payload: result })
      dispatchReducer({ type: "SET_IS_USER_RECIVED_LIST", payload: true })
    }).catch(() => { })
  }
  const checkAttributes = (data) => {
    let form = {
      coordinateFormat: data.coordinateFormat,
      poiLayer: data.poiLayer,
      latitude: data.latitude,
      longitude: data.longitude,
      zoom: data.zoom,
      attributes: {
        'web.liveRouteLength':
          data.attributes && data.attributes['web.liveRouteLength'],
        'web.maxZoom': data.attributes && data.attributes['web.maxZoom'],
        'web.selectZoom': data.attributes && data.attributes['web.selectZoom'],
        timezone: data.attributes && data.attributes.timezone,
        distanceUnit: data.attributes && data.attributes.distanceUnit,
        speedUnit: data.attributes && data.attributes.speedUnit,
        volumeUnit: data.attributes && data.attributes.volumeUnit,
        measurementStandard: data.attributes && data.attributes.measurementStandard,
        viewIMEI: data.attributes && data.attributes.viewIMEI,
        viewModel: data.attributes && data.attributes.viewModel,
        unitsViewType:data?.attributes.unitsViewType?data?.attributes.unitsViewType:"default"
      }
    }
    return form
  }

  const changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

    if (name === 'measurementStandard') {
      let data = checkUnitsWithStandardType(value)
      dispatchReducer({
        type: "SET_FORM", payload:
        {
          attributes: {
            ...state.form.attributes,
            [name]: event.target.type === 'number' ? parseInt(value) : value,
            distanceUnit: data[0].distanceUnit.key,
            speedUnit: data[0].speedUnit.key,
            volumeUnit: data[0].volumeUnit.key
          }
        }
      })
      checkRequiredFields()
    } else {
      dispatchReducer({
        type: "SET_FORM", payload:
        {
          attributes: {
            ...state.form.attributes,
            [name]: value

          }
        }
      })
      checkRequiredFields()
    }
  }

  const timezone_handleChange = (name, value) => {
    if (name === 'timezone') {
      if (!value || value.value === 'None') {
        delete state.form.attributes[name]
        dispatchReducer({
          type: "SET_FORM", payload:
          {
            attributes: { ...state.form.attributes }
          }
        })
        checkRequiredFields()
      }
      else {
        dispatchReducer({
          type: "SET_FORM", payload:
          {
            attributes: {
              ...state.form.attributes,
              [name]: value.value
            }
          }
        })
        checkRequiredFields()
      }
    } else {
      if (name === 'accountId') {
        dispatchReducer({
          type: "SET_FORM", payload: { [name]: value.value, userId: "" }
        })
        dispatchReducer({ type: "SET_USER_SELECTED_DATA", payload: "" })
        dispatchReducer({ type: "SET_IS_USER_RECIVED_LIST", payload: false })
        fetchUsersList(value.value)
        checkRequiredFields()
      } else if (name === 'parentId') {
        dispatchReducer({
          type: "SET_FORM", payload: { [name]: value.value }
        })
        dispatchReducer({
          type: "SET_USER_SELECTED_DATA", payload: value
        })
        checkRequiredFields()
      } else {
        dispatchReducer({
          type: "SET_FORM", payload: { [name]: value.value }
        })
        checkRequiredFields()
      }
    }
  }

  const au_submit = () => {
    let { name, email, password, attributes } = state.form
    dispatchReducer({ type: "SET_IS_LOADING", payload: true })
    if (
      name.trim()?.length > 0 &&
      email.trim()?.length > 0 &&
      password.trim()?.length > 0
    ) {
      dispatchReducer({ type: "SET_SELECTED_USERS", payload: "" })
      const obj = userDataFormat(state.form, attributes)
      instance({
        url: `/api/users/`,
        method: 'POST',
        data: {
          ...obj
        }
      })
        .then(user => {
          let parent = {
            parentId: logInUser.id,
            parentName: logInUser.name
          }
          user = { ...user, ...parent }

          props.fetchUserLimit(false)

          dispatch(addUser(user))
          
          //props.enqueueSnackbar(props.translate('userIsCreated'), { autoHideDuration: 1000 });
          dispatch(
            Notifications.success({
              message: props.translate('userIsCreated'),
              autoDismiss: 10
            })
          )
          dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: false })
          dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: "" })
          dispatchReducer({ type: "SET_SELECTED_USERS", payload: "" })
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
          dispatchReducer({ type: "SET_IS_LOADING", payload: false })
          dispatchReducer({ type: "SET_FORM", payload: { ...formDefaultState } })
          props.history.push('/users/' + user.id + '#device')
        })
        .catch(error => {
          if(error.message==="bad request (HINT: Duplicate entry )"){
            dispatch(
              Notifications.error({
                message: props.translate('Email Already Registered'),
                autoDismiss: 10
              }))
              errorCallBack()
          }else{
            errorHandler(error, dispatch, errorCallBack)
          }
        })
    } else {
      dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
      dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
      dispatch(Notifications.error({ message: props.translate('emptyField'), autoDismiss: 10 }))
    }
  }

  const errorCallBack = () => {
    dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
    dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: false })
    dispatchReducer({ type: "SET_IS_LOADING", payload: false })
  }

  const uu_submit = () => {
    const { selectedUser } = state
    const { attributes } = state.form
    let { name, email } = state.form
    if (name.trim().length > 0 && email.trim().length > 0) {
      let itemId = selectedUser.id

      const obj = userDataFormat(state.form, attributes, itemId)

      if (state.form && !state.form.password) {
        delete state.form.password
      }

      setIsLoading(true)
      instance({
        url: `/api/users/${itemId}`,
        method: 'PUT',
        data: {
          ...obj
        }
      })
        .then(user => {
          let parent = {
            parentId: logInUser.id,
            parentName: logInUser.name
          }
          user = { ...user, ...parent }

          dispatch(updateUser(user))
          props.getCurrentUser(user.id)
          userSelection(user)
          dispatch(
            Notifications.success({
              message: props.translate('userIsUpdated'),
              autoDismiss: 10
            })
          )
          props.fetchUserLimit(false)
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
      setIsLoading(false)

        })

        .catch(error => {
          if(error.message==="bad request (HINT: Duplicate entry )"){
            dispatch(
              Notifications.error({
                message: props.translate('Email Already Registered'),
                autoDismiss: 10
              }))
              errorCallBack()
      setIsLoading(false)

          }else{
      setIsLoading(false)

            errorHandler(error, dispatch, errorCallBack)
          }
        })
    } else {
      dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
      dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
      dispatch(
        Notifications.error({
          message: props.translate('emptyField'),
          autoDismiss: 10
        })
      )
      setIsLoading(false)

    }
  }

  const deleteUser = useCallback((selectedUser) => {
    if (window.confirm('Are you Sure to Delete')) {
      if (state.selectedUser.id) {
        instance({
          url: `/api/users/${state.selectedUser.id}`,
          method: 'DELETE',
          data: {
            ...selectedUser
          }
        })
          .then(response => {// eslint-disable-line no-unused-vars
            dispatch(removeUser(selectedUser))
            dispatch(
              Notifications.success({
                message: props.translate('userIsRemoved'),
                autoDismiss: 10
              })
            )
            props.onCloseModal()
            dispatchReducer({ type: "SET_IS_DELETE_PROCESS", payload: false })
            dispatchReducer({ type: "SET_SELECTED_USERS", payload: "" })
            dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
            dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          })
          .catch(error => { errorHandler(error, dispatch) })
      }
    }
  }, [])

  const changeAttribute = (name, value, selectedOption, updateAtt) => {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: { ...updateAtt } })
      checkForAttrubutesChanged()
    } else {
      dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: { ...state.selectedUser.attributes, [name]: value } })
      checkForAttrubutesChanged()
    }
  }

  const checkForAttrubutesChanged = (selectedOption) => {
    let changed = false
    if (state.selectedUser) {
      changed = !isEqual(
        state.u_attriubtes,
        state.selectedUser.attributes
      )
    } else {
      changed = !isEmpty(state.u_attriubtes)
    }
    let { name, email } = state.form
    let selectedUser = {}
    if (state.editOption) {
      if ((name && email) || (name && email && changed)) {
        selectedUser = state.u_attriubtes
        if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: changed })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: changed })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...selectedUser } })
        } else {
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: changed })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: changed })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...state.itemAttributes, ...selectedUser } })
        }
      } else {
        selectedUser = state.u_attriubtes
        if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...selectedUser } })
        } else {
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...state.itemAttributes, ...selectedUser } })
        }
      }
    } else {
      if ((name && email) || (name && email && changed)) {
        selectedUser = state.u_attriubtes
        if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...selectedUser } })
          checkRequiredFields()
        } else {
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: true })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: true })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...state.itemAttributes, ...selectedUser } })
          checkRequiredFields()
        }
      } else {
        selectedUser = state.u_attriubtes
        if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...selectedUser } })
          checkRequiredFields()
        } else {
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...state.itemAttributes, ...selectedUser } })
          checkRequiredFields()
        }
      }
    }

    if (changed) {
      dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: 'Attributes changed in pending' })
    } else {
      dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: '' })
    }
  }
  const isEmpty = obj => {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }
    return true
  }

  const modalControle = useCallback(() => {
    dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
    dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
    dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
    dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: false })
    dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: false })
    dispatchReducer({ type: "SET_FORM", payload: "" })
    props.onCloseModal()
  }, [])
  // const addUser = () => {
  //   dispatchReducer({ type: "SET_SELECTED_USER", payload: "" })
  //   dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: {} })
  //   dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: true })
  // }
  const updateUsers = () => {
    dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: true })
  }

  const userSelection = (item) => {
    dispatchReducer({ type: "SET_SELECTED_USER", payload: item })
    dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: item.attributes })
    dispatchReducer({ type: "SET_FORM", payload: { ...item } })
  }

  const setExpirationTime = (e) => {
    e.stopPropagation()
    if (state.form.expirationTime) {
      dispatchReducer({ type: "SET_FORM", payload: { expirationTime: null } })
    }
    checkRequiredFields()
  }
  const copyTokenToClipboard = () => {
    const { form } = state;
    const token = form.token;

    const hostUrl = props.ServerSetting?.attributes?.hostUrl || '';
    const tokenUrl = `${hostUrl}/loginasuser?&${btoa(token)}`;
    const tempTextarea = document.createElement('textarea');

    tempTextarea.value = props.viewScreen === 'edit' ? tokenUrl : token;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);
    dispatch(
      Notifications.success({
        message: props.translate('Token copied to clipboard'),
        autoDismiss: 10
      })
    )
  };
  const generateToken = () => {
    let symbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i,
      newToken = ''
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    dispatchReducer({ type: "SET_FORM", payload: { token: newToken } })
    checkRequiredFields()
  }

  const timeDateOnchange = (date) => {
    dispatchReducer({ type: "SET_FORM", payload: { timeDate: date } })
  }

  const verifyPassword = () => {
    const { password, confirmPassword } = state.form
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const isLengthValid = password?.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(password);
    const hasUpperCaseValid = hasUpperCase.test(password);
    const hasNumberValid = hasNumber.test(password);
    const hasSpecialCharValid = hasSpecialChar.test(password);
    const isPasswordCheck = password === confirmPassword;
    if (isPasswordCheck) {
      dispatchReducer({ type: "SET_IS_CNFRM_PASS_CHK", payload: true });
    } else {
      dispatchReducer({ type: "SET_IS_CNFRM_PASS_CHK", payload: false });

    }
    dispatchReducer({ type: "SET_IS_LENGTH_CHK", payload: isLengthValid });
    dispatchReducer({ type: "SET_IS_LOWER_CSE_CHK", payload: hasLowerCaseValid });
    dispatchReducer({ type: "SET_IS_UPPER_CSE_CHK", payload: hasUpperCaseValid });
    dispatchReducer({ type: "SET_IS_NUMBER_CHK", payload: hasNumberValid });
    dispatchReducer({ type: "SET_IS_CHAR_CHK", payload: hasSpecialCharValid });

    const isPasswordValid =
      isLengthValid && hasLowerCaseValid && hasUpperCaseValid && hasNumberValid && hasSpecialCharValid;
    dispatchReducer({ type: "SET_IS_VALID_PASS", payload: isPasswordValid });
    if (isPasswordValid)
      checkRequiredFields();
  };

  const handleChange = useCallback((name) => (event) => {
    const { target } = event;
    let updateForm = {};
    if (target?.type === 'checkbox') {
      updateForm[name] = target.checked;
    } else if (name === 'expirationTime') {
      updateForm[name] = event.toISOString()
    } else if (name === 'deviceLimit' || name === 'userLimit') {
      updateForm[name] = target.value < -1 ? -1 : target.value
    } else if (target.type === 'number') {
      updateForm[name] = parseFloat(target.value)
    } else {
      updateForm[name] = target.value;
    }
    if (name === 'password' || name === 'confirmPassword') {
      verifyPassword();
    }
    dispatchReducer({
      type: 'SET_FORM',
      payload: updateForm
    });
    checkRequiredFields();
  }, [state.form, checkRequiredFields, verifyPassword])

  useEffect(() => {
    if (state.form.password || state.form.confirmPassword) {
      verifyPassword()
    }
  }, [state.form.password, state.form.confirmPassword])
 
  const checkRequiredFields = () => {
    let { zoom } = state.form;
    let { name, email, password, confirmPassword, userType } = state.form;
    let value = !isEqual(state.form, state.selectedUser);
    let valid = true;
    if (state.editOption) {
      if (password && confirmPassword && password === confirmPassword) {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: valid });
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: !valid });
      } else if (name && email && userType && value && password === confirmPassword) {
        if (!validEmailPattern.test(email)) {
          valid = false;
        }
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: valid });
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: !valid });
      } else {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: !state.isAttChanged });
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: false });
      }
    } else {
      if (name && email && userType && password && confirmPassword && password === confirmPassword && value && zoom > 2 && zoom < 19) {
        if (!validEmailPattern.test(email)) {
          valid = false;
        }
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: valid });
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: !valid });
      } else {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: state.isAttChanged });
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: false });
      }
    }
  }
  useEffect(() => {
    checkRequiredFields()
  }, [state.form])
  const handleClickShowPostion = () => {
    dispatchReducer({ type: "SET_IS_MAP_MODAL", payload: true })
    dispatchReducer({ type: "SET_IS_VISABLE_ADD_LOC_BTN", payload: false })
  }
  const getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      dispatchReducer({ type: "SET_LOCAL_POSTION_OBJ", payload: { latlng, zoom } })
      dispatchReducer({ type: "SET_IS_VISABLE_ADD_LOC_BTN", payload: true })
    }
  }
  const setSelectedLocation = () => {
    const { localPostionObj } = state
    if (localPostionObj) {
      dispatchReducer({
        type: "SET_FORM", payload: {
          latitude: localPostionObj.latlng.lat,
          longitude: localPostionObj.latlng.lng,
          zoom: localPostionObj.zoom
        }
      })
      dispatchReducer({ type: "SET_IS_MAP_MODAL", payload: false })
      checkRequiredFields()
    }
  }
  const onCloseModal = () => {
    dispatchReducer({ type: "SET_IS_MAP_MODAL", payload: false })
  }

  useEffect(() => {
    if (props.showItemDetail) {
      dispatchReducer({ type: "SET_SELECTED_USER", payload: props.selecteditem })
    } else {
      if (
        props.activeOperation === 'edit' &&
        (!state.editOption ||
          !isEqual(state.selectedUser, props.selecteditem))
      ) {
        let formData = props.selecteditem
        if (state.selectedUser.id === props.selecteditem.id) {
          formData = state.selectedUser
        }
        dispatchReducer({ type: "SET_SELECTED_USER", payload: props.selecteditem })
        dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: true })
        dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: false })
        dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: props.selecteditem.attributes })
        dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
        dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
        dispatchReducer({ type: "SET_FORM", payload: { ...formDefaultState, ...formData } })
        updateUsers(props.selecteditem)
      }
      if (props.activeOperation === 'changePassword' &&
        (!state.editOption ||
          !isEqual(state.selectedUser, props.selecteditem))
      ) {
        dispatchReducer({ type: "SET_SELECTED_USER", payload: props.selecteditem })
        dispatchReducer({ type: "SET_FORM", payload: { ...formDefaultState, ...props.selecteditem } })
        dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: true })
      }
      if (props.activeOperation === 'delete') {
        deleteUser(props.selecteditem)
      }
      if (props.activeOperation === 'add' && !state.addOption) {
        let defaulfFeildsSetting = checkAttributes(logInUser)
        dispatchReducer({ type: "SET_SELECTED_USER", payload: "" })
        dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: false })
        dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: true })
        dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: {} })
        dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
        dispatchReducer({ type: "SET_FORM", payload: { ...formDefaultState, ...defaulfFeildsSetting } })
      }
    }
    if (props.resourceList) {
      modalControle()
    }
  }, [props.activeOperation, props.selecteditem])
  const { selectedUser, loading } = state

  return (
    <section style={{ width: '100%' }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {props.viewScreen === 'add' ? (
            <>
              {' '}
              {state.isUserRecivedList ? (
                <AddUserModal
                  {...state}
                  {...props}
                  formSubmit={au_submit}
                  form={state.form}
                  timeDateOnchange={timeDateOnchange}
                  generateToken={generateToken}
                  copyTokenToClipboard={copyTokenToClipboard}
                  modalControle={modalControle}
                  changeAttribute={changeAttribute}
                  handleChange={handleChange}
                  buttonText={props.translate('sharedCreate')}
                  handleClickShowPostion={handleClickShowPostion}
                  setExpirationTime={setExpirationTime}
                  btnCancelNotShow={false}
                  changeAttribute2={changeAttribute2}
                  isHaveAccess={'userCreate'}
                  canUpdate={checkPrivileges('userCreate')}
                  userType={logInUser.userType}
                  timezone_handleChange={timezone_handleChange}
                  confirmPasswordCheck={state.confirmPasswordCheck}
                  translate={props.translate}
                  activeOperation={props.activeOperation}

                />
              ) : (
                <Loader />
              )}
            </>
          ) : null}
          {props.viewScreen === 'edit' ? (
            <>
              <AddUserModal
                {...state}
                {...props}
                lengthCheck={state.lengthCheck}
                lowerCaseCheck={state.lowerCaseCheck}
                upperCaseCheck={state.upperCaseCheck}
                numberCheck={state.numberCheck}
                characterCheck={state.characterCheck}
                isValidPassword={state.isValidPassword}
                confirmPasswordCheck={state.confirmPasswordCheck}
                viewScreen={props.viewScreen}
                selectedUser={selectedUser}
                activeOperation={props.activeOperation}
                formSubmit={uu_submit}
                token={state.token}
                timeDate={state.timeDate}
                timeDateOnchange={timeDateOnchange}
                generateToken={generateToken}
                copyTokenToClipboard={copyTokenToClipboard}

                modalControle={modalControle}
                changeAttribute={changeAttribute}
                handleChange={handleChange}
                form={state.form}
                editOption={state.editOption}
                buttonText={props.translate('sharedUpdate')}
                isVisableUserBtn={state.isVisableUserBtn}
                handleClickShowPostion={handleClickShowPostion}
                duplicateEntry={state.duplicateEntry}
                setExpirationTime={setExpirationTime}
                itemAttributes={state.itemAttributes}
                attributeChangesMessage={state.attributeChangesMessage}
                btnCancelNotShow={true}
                changeAttribute2={changeAttribute2}
                isHaveAccess={'userUpdate'}
                canUpdate={checkPrivileges('userUpdate')}
                renderForm={props.renderForm}
                ServerSetting={props.ServerSetting}
                userType={logInUser.userType}
                timezone_handleChange={timezone_handleChange}
                isLoading={isLoading}
              />
            </>
          ) : null}
          {state.viewScreen === 'view' ? (
            <EditItem
              selectedItem={state.selectedUser}
              handleClose={modalControle}
              form={state.form}
            />
          ) : null}
          {state.isMapModal && (
            <CustomDialog
              title={props.translate('setLocation')}
              themecolors={props.themecolors}
              visable={true}
              onClose={onCloseModal}
              isVisableBtn={true}
              draggable={true}
              CancelText={props.translate('sharedBack')}
              headerActions={
                <Button
                  size='small'
                  variant='contained'
                  color='secondary'
                  disabled={!state.isVisableAddlocBtn}
                  onClick={() => setSelectedLocation()}
                >
                  {' '}
                  {props.translate('sharedAdd')}
                </Button>
              }
              bodyPadding={0}
            >
              <MarkerMap
                getPostion={getPostion}
                minHeight={500}
                zoom={state.selectedUser.zoom || 0}
                lat={state.selectedUser.latitude || 0}
                lng={state.selectedUser.longitude || 0}
                height={'100%'}
              />
            </CustomDialog>
          )}
        </>
      )}
    </section>
  )
}

