import React, { PureComponent } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from './../common/MenuItem'
import Style from 'style-it'
   
 
export default class UnitMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      commandModal: false,
      list: []
    }
  }

  handleClose = () => {
    this.props.handleClose()
  }

  componentWillReceiveProps (NextProps) {
    this.setState({ anchorEl: NextProps.anchorEl })
  }

   
 

  render () {
    // const styles = {
    //   ListItemIcon: {
    //     fontSize: 18,
    //     fill: 'currentColor'
    //   },
    //   ListItemIconWrap: {
    //     marginRight: 10,
    //     color: 'inherit',
    //     minWidth: 18
    //   }
    // }
    return (
       <Style>
        {`
          .custom-menu-paper {
            background: ${this.props.themecolors.backgroundColor};
            color: ${this.props.themecolors.textColor}
          }
        `}
        <div>
          <Menu
            id={1}
            anchorEl={this.props.anchorE2}
            open={Boolean(this.props.anchorE2)}
            onClose={this.props.handleClose}
            classes={{ paper: 'custom-menu-paper' }}
          > 
              <MenuItem key={1} value={1} onClick={(e)=>this.props.createGeofence(e)}>  {this.props.translate('geoFenceCreate')} </MenuItem>
          </Menu> 
        </div>
      </Style>
    )
  }
}
