import React, {  useState } from 'react';
// import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import InfiniteScroll from 'react-infinite-scroll-component';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { checkPrivileges } from '../../../Helpers'
import Clip from '../../../assets/nav/clip.svg'
import Loader from './../../Loader'
// import AddRecourcesGroup from '../../../Components/Recources/AddRecourcesGroup';
import Collapse from "@mui/material/Collapse"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ChevronRight"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Translate } from 'react-localize-redux';
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag';
import Notifications from 'react-notification-system-redux';
import { toggleAllGroupDevices } from '../../../Actions/Devices';

const RecourcesList = (props) => {
  // const [items, setItems] = useState({})

  const endMessage =
    props.resources && props.resources.total > 0 ? null : (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        {props.translate('notFound')}
      </p>
    )
  return (
    <>
      <div className='sidebar-users-list'>
        <Scrollbar
          disableTracksWidthCompensation={true}
          onScroll={props.view==="groups"?props.handleScrollGroups:null}
          scrollerProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props
              return (
                <div {...restProps} ref={elementRef} id='scrollableVehicle' />
              )
            }
          }}
        >
          {checkPrivileges('itemgroup') && props.resources && props.resources.loading ? <p style={{ color: '#ccc' }}><Loader defaultStyle={true} /></p> : (
            <InfiniteScroll
              dataLength={props.resources.data.length}
              next={props.nextResources}
              // hasMore={props.itemPagination.hasNext}
              // loader={<Loader defaultStyle={false} />}
              scrollableTarget='scrollableVehicle'
              endMessage={endMessage}
            >
              <div className="group-view list-row-condest">
                <ul className='list-view-group'>
                  <ListRow
                    // editOption={editOption}
                    {...props}
                  />
                </ul>
              </div>
            </InfiniteScroll>
          )}
        </Scrollbar>
      </div>
    </>
  );
}

const ListRow1 = (props) => {
  const [collapseId, setCollapseId] = useState(0)
  const [collapseIds, setCollapseIds] = useState({})
  // const [isChecked, setIsChecked] = useState(true)
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteSelection, setDeleteSelection] = useState(null);

  const toggleAllDevices = (groupId, event) => {
    // console.log("Grp=====", groupId, event.target.checked);
    const isCheckedv = event.target && event.target.checked;
    // console.log("res====",props.fromArray );

    if (isCheckedv !== null) {
      props.dispatch(toggleAllGroupDevices({ checked: isCheckedv }, true, props.fromArray));
      // setIsChecked(newChecked);
      setCollapseIds((prev) => ({
        ...prev,
        [groupId]: {
          ...prev[groupId],
          isChecked: isCheckedv
        }
      }))
  
    }
  }

  const collapseGroup = (collapseIdp) => {
    // console.error(collapseId)
    if (collapseId !== collapseIdp) {
      setCollapseId(collapseIdp);
      props.fetchNestedItems(collapseIdp, 1);
    } else {
      setCollapseId(0);
    }
  };

  const confirmDelete = (deleteSelection) => {
    setDeleteSelection(deleteSelection);
    setOpenConfirm(true);
  };


  const resetDelete = () => {
    setOpenConfirm(false);
    setDeleteSelection(null);
  };

  const deleteNow = () => {
    if (deleteSelection) {
      resetDelete();
      props.deleteResource(deleteSelection.id, (response) => {
        if (response.state === 200) {
          dispatch(Notifications.success({
            message: <Translate id='groupDeleted' />,
            autoDismiss: 10,
          }));
        }
        resetDelete();
      });
    }
  };
  const { ItemChild, ItemChildProps, fromArray, exact, itemType } = props;
  return (
    <>
      {openConfirm && (
        <ConfirmDialoag onCancel={resetDelete} onOk={deleteNow} title={<Translate id="areYouWantToDelete" />}>
          {deleteSelection.name}
        </ConfirmDialoag>
      )}
      {props.resources.data.map((item, index) => (
        <li key={index}>
          <div className='clearfix group-list-row list-row'>
            <label className='checkbox' onClick={() => collapseGroup(item.id)}>
              <span className='unit-name' style={{ minWidth: 200, maxWidth: 200, marginLeft: 0 }}>
                {item.id === collapseId ? (
                  <ExpandMoreIcon style={{ width: 20, height: 20, verticalAlign: 'middle', marginRight: 5 }} />
                ) : (
                  <ExpandLessIcon style={{ width: 20, height: 20, verticalAlign: 'middle', marginRight: 5 }} />
                )}
                {item.name}{"       "}{"  -  "}{"     "}
                {item?.itemCount || "  "}
              </span>
            </label>
            <div className='pull-right'>
              {checkPrivileges('itemgroupDelete') && (
                <span className='action-item hovered' title={props.translate('delete')} onClick={() => confirmDelete(item)}>
                  <DeleteIcon style={{ fontSize: 16 }} />
                </span>
              )}
              {checkPrivileges('itemgroupUpdate') && !props.disableEdit && (
                <span className='action-item hovered' title={props.translate('edit')} onClick={() => props.onEditResource(item)}>
                  <EditIcon style={{ fontSize: 16 }} />
                </span>
              )}
              {checkPrivileges('itemgroupLinkItem') && (
                <span className='action-item hovered' title={props.translate('assign')} onClick={() => props.onLinkResource(item)}>
                  <svg fill='currentColor' width={14} height={14}>
                    <use xlinkHref={`${Clip}#icon`} />
                  </svg>
                </span>
              )}
            </div>
          </div>
          <Collapse style={{ display: 'block' }} in={item.id === collapseId} timeout='auto' unmountOnExit>
            <div style={{ height: 200 }}>
              {props.nestedResources && props.nestedResources.loading && item.id === collapseId ? (
                <p style={{ color: '#ccc' }}><Loader defaultStyle={true} /></p>
              ) : null}
              {props.nestedResources && !props.nestedResources.loading ? (
                <Scrollbar disableTracksWidthCompensation={true}>
                  {itemType === 'Device' ? (
                    props.nestedResources.total > 0 && fromArray && fromArray.length ? (
                      <ul className="list-view list-view-children">
                        {props.monitoring && (
                          <li style={{ paddingRight: 0, padding: "3px 0 3px 0px", paddingLeft: 0 }}>
                            <label className='checkbox' style={{ display: 'inline-block' }}>
                              <input
                                type="checkbox"
                                name={`isChecked_${item.id}`}
                                checked={collapseIds[item.id]?.isChecked === undefined ? true : collapseIds[item.id]?.isChecked}
                                onChange={(event) => toggleAllDevices(item.id, event)}
                              />
                              <span className='theme-input-checkbox'>
                                <CheckBoxIcon className='on-active material-icons' style={{ fontSize: 22, color: 'inherit' }} />
                                <CheckBoxOutlineBlankIcon className='on-inactive material-icons' style={{ fontSize: 22, color: 'inherit' }} />
                                <label style={{ marginLeft: "10px" }}> Select All </label>
                              </span>
                            </label>
                          </li>
                        )}
                        {fromArray.map(e => (
                          <ItemChild key={e.id} item={props.nestedResources.data.find(row => row.id === e.id) ? e : (exact ? null : e)} {...ItemChildProps} />
                        ))}
                      </ul>
                    ) : (
                      <p style={{ textAlign: 'center', color: '#ccc' }}><Translate id='notFound' /></p>
                    )
                  ) : null}
                  {itemType !== 'Device' ? (
                    props.nestedResources.total > 0 ? (
                      props.nestedResources.data.map(e => (
                        <ItemChild key={e.id} item={fromArray && fromArray.find(row => row.id === e.id) ? fromArray.find(row => row.id === e.id) : (exact ? null : e)} {...ItemChildProps} collapseIds={item.id}/>
                      ))
                    ) : (
                      <p style={{ textAlign: 'center', color: '#ccc' }}><Translate id='notFound' /></p>
                    )
                  ) : null}
                </Scrollbar>
              ) : null}
            </div>
          </Collapse>
        </li>
      ))}
    </>
  );
}
export const ListRow = (ListRow1)
export default RecourcesList;