import React from 'react'
import { connect } from 'react-redux'
// import Icon from '@mui/material/Icon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
import { withLocalize } from 'react-localize-redux'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Style from 'style-it'
library.add(faSatelliteDish)

// class Jobs extends Component {
  const Jobs =(props)=>{
  // render () {
    let data = ''
    if (props.jobs.length) {
      data = props.jobs.map(job => (
        <ListRow
          key={job.id}
          data={job}
          {...props}
          removedItem={props.removedItem}
          editItem={props.editItem}
          selecteItem={props.selecteItem}
        />
      ))
    }
    return (
      <Style>
        {`
      			.material-icons {vertical-align: middle; color: inherit}
			`}
        <div>
          {data && (
            <div className='individual-view'>
              <ul className='list-view with-padding-right'>{data}</ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
// }

// export class ListRows extends Component {
  export const ListRows =(props)=>{
  // render () {
    if (props.children.length) {
      return (
        <ul className='list-view'>
          {props.children.map(data => (
            <ListRow key={data.id} data={data} {...props} />
          ))}
        </ul>
      )
    } else {
      return ''
    }
  }
// }
// export class ListRow extends Component {
  const ListRow =(props)=>{

  // render () {
    return (
      <li>
        <Link
          // onClick={handleClick}
          className={
            'clearfix list-row ' +
            (parseInt(props.match.params.id) ===
            parseInt(props.data.id)
              ? 'active'
              : '')
          }
          to={'/schedules/' + props.data.id}
        >
          <label
            className='checkbox'
            //onClick={() => props.selecteItem(props.data)}
          >
            <span
              className='unit-name'
              style={{ minWidth: 220, maxWidth: 220, marginLeft: 0 }}
            >
              <span
                style={{
                  marginRight: '5px',
                  // display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              >
                <AccountCircleIcon/>
              </span>{' '}
              {props.data.description}
            </span>
          </label>
          <div className='pull-right'>
            {checkPrivileges('jobDelete') && (
              <span
                className='action-item hovered'
                title={props.translate('delete')}
                onClick={() => props.removedItem(props.data)}
              >
                <DeleteIcon style={{fontSize: 16}}/>
              </span>
            )}
            {checkPrivileges('jobUpdate') && (
              <span
                className='action-item hovered'
                title={props.translate('edit')}
                onClick={() => props.editItem(props.data)}
              >
                <EditIcon style={{fontSize: 16}} />{' '}
              </span>
            )}
          </div>
        </Link>
      </li>
    )
  }
// }

const mapState = state => {
  var job = state.jobs.filter(
    item =>
      item.description.toLowerCase().indexOf(state.searchJob) !== -1 ||
      item.id.indexOf(state.searchJob) !== -1
  )
  return {
    jobs: job
  }
}

const mapStateToProps = connect(mapState)
export const JobsShortList = mapStateToProps(withLocalize(Jobs))
