import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDevices,
  addDevices,
  // removeDevice,
  updateDevice
} from '../../Actions/Devices'
import { updateGroups } from '../../Actions/Groups'
import AddDevice from './addDevice'
import isEqual from 'react-fast-compare'
import DevicePortList from './devicePortList'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'

const CancelToken = axios.CancelToken
let source

const advanceSettingsForm = {
  minimalNoDataDuration: 600,
  minimalParkingDuration: 300,
  minimalTripDuration: 60,
  minimalTripDistance: 100,
  speedThreshold: 1.61987,
  processInvalidPositions: false,
  useIgnition: '2',
  storeTime: 10
}
const formDefaultState = {
  attributes: { ...advanceSettingsForm }
}
const deviceDataFormat = (data, extAttriubtes, id) => ({
  id: id ? id : 0,
  attributes: extAttriubtes
    ? { ...data.attributes, ...extAttriubtes }
    : { ...data.attributes },
  name: data.name.trim(),
  uniqueId: (data.uniqueId && data.uniqueId) || '',
  status: data.status,
  disabled: data.disabled,
  lastUpdate: data.lastUpdate,
  positionId: data.positionId,
  groupId: data.groupId,
  phone: data.phone,
  model: data.model,
  contact: data.contact,
  category: data.category,
  geofenceIds: data.geofenceIds,
  parentId: data.parentId || 0,
  expirationTime: data.expirationTime || ''
})

const deviceModal = (props) => {
  const [state, setState] = useState({
    addOption: false,
    editOption: false,
    selectedDevice: '',
    selectedUsers: '',
    d_attriubtes: '',
    defaultDeviceList: false,
    selectedOption: false,
    directOption: false,
    isVisableDeviceBtn: false,
    isAttChanged: false,
    duplicateIdentifier: false,
    usesId: '',
    categoriesList: [],
    unitList: [],
    page: 1,
    pageSize: 10,
    itemSearch: '',
    deviceArray: [],
    itemAttributes: {},
    attributeChangesMessage: '',
    // all fields of form
    form: { ...formDefaultState }
  })

const [isLoading,setIsLoading]=useState(false)

  const devices = useSelector((state) => state.devices.data)
  const group = useSelector((state) => state.groups)
  const themecolors = useSelector((state) => state.themeColors)
  const logInUser = useSelector((state) => state.logInUsers)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.activeOperation === 'add' && !state.addOption) {
      setState((prevState) => ({
        ...prevState,
        selectedDevice: '',
        addOption: true,
        editOption: false,
        itemAttributes: {},
        attributeChangesMessage: '',
        isVisableDeviceBtn: false,
        isAttChanged: false,
        form: { ...formDefaultState }
      }));
    } else if (
      props.activeOperation === 'edit' &&
      (!state.editOption || parseInt(state.selectedDevice.id) !== parseInt(props.id))
    ) {
      const updatedAttributes = {
        ...advanceSettingsForm,
        ...props.selecteditem.attributes,
        speedLimit: props.selecteditem.attributes && props.selecteditem.attributes.speedLimit
          ? Math.round(props.selecteditem.attributes.speedLimit * 1.852)
          : '',
        minimalTripDistance: props.selecteditem.attributes && props.selecteditem.attributes.minimalTripDistance
          ? props.selecteditem.attributes.minimalTripDistance
          : 100
      };

      setState((prevState) => ({
        ...prevState,
        selectedDevice: {
          ...props.selecteditem,
          attributes: updatedAttributes
        },
        editOption: true,
        addOption: false,
        attributeChangesMessage: '',
        isVisableDeviceBtn: false,
        isAttChanged: false,
        itemAttributes: updatedAttributes,
        form: {
          ...props.selecteditem,
          attributes: updatedAttributes
        }
      }));
    }

    if (props.id) {
      setState((prevState) => ({
        ...prevState,
        addOption: false
      }));
    }
  }, [props.activeOperation,props.id]);

  useEffect(() => {
    if (props.activeOperation === 'add' && !state.addOption) {
      setState((prevState) => ({
        ...prevState,
        selectedDevice: '',
        addOption: true,
        editOption: false,
        itemAttributes: {},
        form: { ...formDefaultState }
      }));
    } else if (
      props.activeOperation === 'edit' &&
      (!state.editOption || parseInt(state.selectedDevice.id) !== parseInt(props.id))
    ) {
      const updatedAttributes = {
        ...advanceSettingsForm,
        ...props.selecteditem.attributes,
        speedLimit: props.selecteditem.attributes && props.selecteditem.attributes.speedLimit
          ? Math.round(props.selecteditem.attributes.speedLimit / 0.539957)
          : '',
        minimalTripDistance: props.selecteditem.attributes && props.selecteditem.attributes.minimalTripDistance
          ? props.selecteditem.attributes.minimalTripDistance
          : 100
      };

      setState((prevState) => ({
        ...prevState,
        selectedDevice: {
          ...props.selecteditem,
          attributes: updatedAttributes
        },
        editOption: true,
        addOption: false,
        attributeChangesMessage: '',
        itemAttributes: updatedAttributes,
        form: {
          ...props.selecteditem,
          attributes: updatedAttributes
        }
      }));
    }

    fetchCategories();
    if (props.id) {
      setState((prevState) => ({
        ...prevState,
        addOption: false
      }));
    }
    fetchDeviceModel();
  }, []);


  useEffect(()=>{
    fetchDeviceModel()
  },[state.pageSize,state.page,state.itemSearch])


  const fetchDeviceModel = () => {
    let { page, pageSize, itemSearch } = state
    instance(`/api/devices/models?&page=${page}&limit=${pageSize}&search=${itemSearch}`)
      .then(response => {
        setState((prev) => ({
          ...prev,
          unitList: response
        }))
      })
      .catch(() => {
        // errorHandler(error, this.props.dispatch)
      })
  }

  const fetchCategories = () => {
    instance(`/api/devices/categories`)
      .then(response => {
        setState((prev) => ({
          ...prev,
          categoriesList: response?.categories
        }))
      })
      .catch(() => {
        // errorHandler(error, this.props.dispatch)
      })
  }

  // const editEnable = (selected) => {
  //   if (selected[0]) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       itemAttributes: selected[0].attributes,
  //       directOption: true,
  //       editOption: true,
  //       selectedDevice: selected[0],
  //       selectedOption: true,
  //       defaultDeviceList: false,
  //       form: { ...selected[0] },
  //     }));
  //     ;
  //   } else {
  //     setState((prevState) => ({
  //       ...prevState,
  //       itemAttributes: state.d_attriubtes,
  //       editOption: true,
  //       defaultDeviceList: false,
  //       selectedOption: false,
  //       directOption: false,
  //     }));
  //     ;
  //   }
  // };

  // const removeEnable = (selected) => {
  //   if (selected[0]) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       directOption: true,
  //       selectedDevice: selected[0],
  //       defaultDeviceList: false,
  //       attributeChangesMessage: '',
  //       form: '',
  //     }));
  //     deleteDevice();
  //   } else {
  //     setState((prevState) => ({
  //       ...prevState,
  //       defaultDeviceList: true,
  //       selectedOption: false,
  //       directDelete: false,
  //       attributeChangesMessage: '',
  //       form: '',
  //     }));
  //     deleteDevice();
  //   }
  // };

  const submitAdddevice = () => {
    let { attributes, name, device, model } = state.form
    // if (this.state.form.port) {
    //   let port = this.state.form.port
    //   attributes = { ...attributes, port }
    // }
    if (name.trim().length > 0) {
      const obj = deviceDataFormat(state.form, attributes)
      if (obj.attributes && !obj.attributes.devicePassword) {
        delete state.form.attributes.devicePassword
      }
      if (device && model) {
        obj.attributes = {
          ...obj.attributes,
          port: parseInt(device),
          protocol: typeof model === 'object' ? model.protocol : model
        }
      }
      if (obj.attributes && obj.attributes.speedLimit) {
        obj.attributes = {
          ...obj.attributes,
          speedLimit: obj.attributes.speedLimit * 0.539957
        }
      }
      obj.attributes.minimalTripDistance =
        attributes.minimalTripDistance
      obj.attributes = {
        ...obj.attributes,
        'parking.maxIdleSpeed': (1.61987).toString(),
        'parking.minIdleTime': (1000 * 60 * 5).toString()
      }
      obj.model = typeof model === 'object' ? model.label : model
      setState((prevState) => ({
        ...prevState,
        isVisableDeviceBtn: false
      }))
setIsLoading(true)
      instance({
        url: `/api/devices/`,
        method: 'POST',
        headers: {
          Accept: 'application/json; odata=verbose',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(device => {
          dispatch(addDevices([device]))
          props.checkedDeviceLimit()
          dispatch(
            Notifications.success({
              message: props.translate('trackerIsAdded'),
              autoDismiss: 10
            })
          )
setIsLoading(false)

          setState((prevState) => ({
            ...prevState,
            addOption: false,
            selectedDevice: '',
            defaultDeviceList: true,
            // isVisableDeviceBtn: false,
            isAttChanged: false,
            form: '',
            attributeChangesMessage: ''
          }))
          if (device.model !== 'FX Tracker') {
            props.history.push(
              '/units/' + device.id + '#sensors',
              'createMood'
            )
          } else {
            props.history.push(
              '/units/' + device.id + '#sharedDeviceAccumulators',
              'createMood'
            )
          }
        }
        )
        .catch(error => {
          errorHandler(error, dispatch, errorCallBack)
        })
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisableDeviceBtn: false,
        isAttChanged: false
      }))
      dispatch(
        Notifications.error({
          message: props.translate('uniqueIdIsAllreadyInUsed'),
          autoDismiss: 10
        })
      )
    }
  }

  const errorCallBack = (data) => {
    if (data.message.includes("Duplicate entry"))
      setState((prevState) => ({
        ...prevState,
        duplicateIdentifier: true
      }))
  }

  // const deleteDevice = () => {
  //   if (window.confirm('Are you Sure to Delete')) {
  //     const { selectedDevice } = state
  //     instance({
  //       url: `/api/devices/${selectedDevice.id}`,
  //       method: 'DELETE',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //       .then(response => {// eslint-disable-line no-unused-vars
  //         // if (response.status === 204) {
  //         if (props.activeOperation === 'ownDevice') {
  //           dispatch(removeDevice(selectedDevice.id))
  //         } else {
  //           const array = state.deviceArray.filter(
  //             gr => gr.id !== selectedDevice.id
  //           )
  //           setState((prevState) => ({
  //             ...prevState,
  //             deviceArray: array
  //           }))
  //         }

  //         //this.props.enqueueSnackbar(this.props.translate('trackersIsDeleted'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
  //         dispatch(
  //           Notifications.success({
  //             message: props.translate('trackersIsDeleted'),
  //             autoDismiss: 10
  //           })
  //         )
  //         // } else {
  //         //   throw response
  //         // }
  //       })
  //       .catch(error => { errorHandler(error, dispatch) })
  //     setState((prevState) => ({
  //       ...prevState,
  //       selectedDevice: '',
  //       addOption: false,
  //       editOption: false,
  //       defaultDeviceList: true,
  //       attributeChangesMessage: ''
  //     }))
  //   } else {
  //     setState((prevState) => ({
  //       ...prevState,
  //       selectedDevice: '',
  //       addOption: false,
  //       editOption: false,
  //       defaultDeviceList: true,
  //       attributeChangesMessage: ''
  //     }))
  //   }
  // }

  const submitEditdevice = () => {
    let { attributes, name, device, model } = state.form
    let { selectedDevice, form } = state

    if (name.trim().length > 0) {
      const obj = deviceDataFormat(form, attributes, selectedDevice?.id)
      if (
        obj.attributes &&
        obj.attributes.devicePassword !== undefined &&
        obj.attributes.devicePassword === ""
      ) {
        delete obj.attributes['devicePassword']
      }
      delete obj.indeterminate
      delete obj.parent
      if (device && model) {
        obj.attributes = {
          ...obj.attributes,
          port: parseInt(device),
          protocol: typeof model === 'object' ? model.protocol : model
        }
      }
      obj.model = typeof model === 'object' ? model.label : model
      if (obj.attributes && obj.attributes.speedLimit) {
        obj.attributes = {
          ...obj.attributes,
          speedLimit: obj.attributes.speedLimit * 0.539957
        }
      }
      const final = {
        ...obj,
        attributes: {
          ...obj.attributes,
          speedLimit:
            form.attributes && form.attributes.speedLimit
              ? form.attributes.speedLimit * 0.539957
              : ''
        }
      }
setIsLoading(true)
      instance({
        url: `/api/devices/${selectedDevice.id}`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...final
        }
      })
        .then(device => {
          device['visible'] = selectedDevice.visible || false
          dispatch(updateDevice(device))
          props.updateSelectedItem(device)
          dispatch(
            Notifications.success({
              message: props.translate('trackersIsUpdated'),
              autoDismiss: 10
            })
          )
          setIsLoading(false)
          if (props.activeOperation === 'edit') {
            setState((prevState) => ({
              ...prevState,
              isVisableDeviceBtn: false,
              isAttChanged: false,
              attributeChangesMessage: ''
            }))
          } else {
            setState((prevState) => ({
              ...prevState,
              editOption: false,
              defaultDeviceList: true,
              isVisableDeviceBtn: false,
              isAttChanged: false,
              attributeChangesMessage: ''
            }))
          }
        })
        .catch(error => {
          errorHandler(error, dispatch)
        })
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisableDeviceBtn: false,
        isAttChanged: false
      }))
      dispatch(
        Notifications.error({
          message: props.translate('emptyField'),
          autoDismiss: 10
        })
      )
    }
  }

  const modalControle = () => {
    source = CancelToken.source()
    if (state.isVisableDeviceBtn) {
      fatchDevice(logInUser.id)
    }
    setState((prevState) => ({
      ...prevState,
      addOption: false,
      editOption: false,
      selectedDevice: '',
      defaultDeviceList: true,
      form: '',
      isVisableDeviceBtn: false,
      isAttChanged: false,
      duplicateIdentifier: false,
      attributeChangesMessage: '',
      isVisablePortList: false
    }))
    if (props.onCloseModal) {
      props.onCloseModal()
    }
  }

  // const addDevice = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     selectedDevice: '',
  //     addOption: true,
  //     editOption: false,
  //     itemAttributes: {},
  //     defaultDeviceList: false,
  //     isVisableDeviceBtn: false,
  //     isAttChanged: false,
  //     form: '',
  //     attributeChangesMessage: ''
  //   }))
  // }


  // const updateDevices = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     editOption: true,
  //     defaultDeviceList: false
  //   }))
  // }

  const changeAttribute = (name, value, selectedOption, updateAtt) => {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      setState((prevState) => ({
        ...prevState,
        d_attriubtes: { ...updateAtt }
      }))
      checkForAttrubutesChanged(selectedOption)
    } else {
      setState((prevState) => ({
        ...prevState,
        d_attriubtes: {
          ...state.selectedDevice.attributes,
          [name]: value
        }
      }))
      checkForAttrubutesChanged()
    }
  }

  const changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
  
    if (!value) {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...state.form,
          attributes: {
            ...state.form.attributes,
            [name]: ''
          }
        }
      }));
    } else if (name === "speedLimit") {
      if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...state.form,
            attributes: {
              ...state.form.attributes,
              [name]: value
            }
          }
        }));
      } else {
        dispatch(
          Notifications.error({
            message: props.translate('The speed limit (km/h) must be a non-negative integer. Please enter a valid value.'),
            autoDismiss: 10
          })
        )
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...state.form,
          attributes: {
            ...state.form.attributes,
            [name]: value
          }
        }
      }));
    }
  };
  

  const handleChangeLicenseExpDate = (name, event) => {

    if (name === 'expirationTime') {
      if (event) {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...state.form,
            [name]: event.toISOString()
          }
        }))

      }
      else {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...state.form,
            expirationTime: ''
          }
        }))

      }
    }
    else {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...state.form,
          attributes: {
            ...state.form.attributes,
            [name]: event ? event.toISOString() : ''
          }
        }
      }))

    }
  }

  const checkForAttrubutesChanged = (selectedOption) => {
    let changed = false
    if (state.selectedDevice) {
      changed = !isEqual(
        state.d_attriubtes,
        state.selectedDevice.attributes
      )
    } else {
      changed = !isEmpty(state.d_attriubtes)
    }
    let { name, uniqueId } = state.form
    let selectedDevice = {}
    if ((name && uniqueId) || (name && uniqueId && changed)) {
      selectedDevice = state.d_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        setState((prevState) => ({
          ...prevState,
          isVisableDeviceBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedDevice }
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isVisableDeviceBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...state.itemAttributes, ...selectedDevice }
        }))
      }
    } else {
      selectedDevice = state.d_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        setState((prevState) => ({
          ...prevState,
          isVisableDeviceBtn: false,
          isAttChanged: changed,
          itemAttributes: { ...selectedDevice }
        }))

      } else {
        setState((prevState) => ({
          ...prevState,
          isVisableDeviceBtn: false,
          isAttChanged: false,
          itemAttributes: { ...state.itemAttributes, ...selectedDevice }
        }))

      }
    }
    if (changed) {
      setState((prevState) => ({
        ...prevState,
        attributeChangesMessage: 'Change in attributes is pending'
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        attributeChangesMessage: ''
      }))

    }
  }
  const isEmpty = obj => {
    for (let key in obj) {
      if (obj.prototype.hasOwnProperty.call(key)) return false
    }
    return true
  }

  const handleChange = name => event => {
    const { target } = event
    const re = /^[a-zA-Z0-9_.-]*$/;
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value !== '' && ((name === 'uniqueId') ? re.test(value) : true)) {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: value
        }
      }))

    } else {
      if (re.test(value) || value === '') {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [name]: null
          }
        }))

      }
    }
  }
  const handleChange2 = (target, option) => {
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (value !== '') {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [option.name]: value
        }
      }))

    }
  }

  const port_handleChange = (name, value) => {
    if ((!("" + (state.form.uniqueId || "")).trim()) && value && value.key === 'FX Tracker') {
      instance({
        url: `/api/devices/uniqueid`,
        method: 'GET'
      })

        .then(dataResp => {
          setState((prev) => ({
            ...prev,
            form: {
              ...state.form,
              uniqueId: dataResp.uniqueId
            }
          }))
          setState((prev) => ({
            ...prev,
            form: {
              ...prev.form,
              [name]: value.key,
              device: value.port
            }
          }))

        }).catch(() => {
        })
    } else {
      if (value !== null) {
        setState((prev) => ({
          ...prev,
          form: {
            ...prev.form,
            [name]: value,
            device: value.port
          }
        }))

      } else {
        setState((prev) => ({
          ...prev,
          form: {
            ...prev.form,
            [name]: '',
            device: ''
          }
        }))

      }
    }
  }

  const timezone_handleChange = (name, value) => {
    if (!value || value.value === 'None') {
      delete state.form.attributes[name]
      setState((prev) => ({
        ...prev,
        form: {
          ...state.form,
          attributes: {
            ...state.form.attributes
          }
        }
      }))

    } else {
      setState((prev) => ({
        ...prev,
        form: {
          ...state.form,
          attributes: {
            ...state.form.attributes,
            [name]: value.value
          }
        }
      }))

    }
  }

  useEffect(() => {
    let { selectedDevice } = state
    let { name, uniqueId, model } = state.form
    let valueOfPort = false
    if (uniqueId) {
      valueOfPort = true
    }
    if (model && model === 'FX Tracker') {
      valueOfPort = true
    }
    let value = !isEqual(state.form, selectedDevice)
    if (name && model && valueOfPort && value) {
      setState((prevState) => ({
        ...prevState,
        isVisableDeviceBtn: true,
        duplicateIdentifier: false
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisableDeviceBtn: state.isAttChanged,
        duplicateIdentifier: false
      }))
    }
  }, [state.form])

  // const checkRequiredFields = () => {
  //   let { selectedDevice } = state
  //   let { name, uniqueId, model } = state.form
  //   let valueOfPort = false
  //   if (uniqueId) {
  //     valueOfPort = true
  //   }
  //   if (model && model === 'FX Tracker') {
  //     valueOfPort = true
  //   }
  //   let value = !isEqual(state.form, selectedDevice)
  //   if (name && model && valueOfPort && value) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       isVisableDeviceBtn: true,
  //       duplicateIdentifier: false
  //     }))
  //   } else {
  //     setState((prevState) => ({
  //       ...prevState,
  //       isVisableDeviceBtn: state.isAttChanged,
  //       duplicateIdentifier: false
  //     }))
  //   }
  // }

  // const devicesSelection = (item) => {
  //   if (item) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       selectedDevice: item,
  //       selectedOption: true,
  //       defaultDeviceList: false,
  //       directOption: false,
  //       d_attriubtes: item.attributes,
  //       form: {
  //         ...item,
  //         attributes: {
  //           ...item.attributes,
  //           speedLimit: Math.round(item.attributes.speedLimit / 0.539957)
  //         }
  //       }
  //     }))
  //   }
  // }

  const fatchDevice = id => {
    if (!devices.length) {
      instance({
        method: 'GET',
        url: `/api/devices?userId=${id}`,
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        // if (response.status === 200) {
        dispatch(getDevices(response))
        setState((prevState) => ({
          ...prevState,
          usesId: logInUser.id
        }))

        // }
      }).catch(() => {
        // errorHandler(error, this.props.dispatch)
      })
    }
    //get groups
    if (!group.length) {
      instance({
        method: 'GET',
        url: `/api/groups?userId=${id}`,
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        // if (response.status === 200) {
        dispatch(updateGroups(response))
        // }
      }).catch(() => {
        // errorHandler(error, this.props.dispatch)
      })
    }
  }

  const addNewGroups = () => {
    props?.addNewGroups()
  }
  const showPortList = () => {
    setState((prevState) => ({
      ...prevState,
      isVisablePortList: true
    }))
  }

  const onCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      isVisablePortList: false
    }))
  }

  const handleChangeRowsPerPage = value => {
    setState((prevState) => ({
      ...prevState,
      pageSize: value
    }))
  }

  const handleChangePage = value => {
    setState((prevState) => ({
      ...prevState,
      page: value
    }))

  }

  const SearchItem = (searchValue) => {
    setState((prevState) => ({
      ...prevState,
      page: 1,
      itemSearch: searchValue
    }))
  }



  const checkFloteNumber = (n) => {
    return Number(n) === n && n % 1 !== 0
  }

  const setPagination = (items) => {
    let nItem = { ...items }
    let lastPage = nItem.total / state.pageSize
    let IsFloate = checkFloteNumber(lastPage)
    delete nItem.data
    nItem.pageSize = state.pageSize
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
    return nItem
  }
  return (
    <Fragment>
      {state.addOption && (
        <Fragment>
          <AddDevice
            onlyAttributes={props.onlyAttributes}
            themecolors={themecolors}
            group={group}
            formSubmit={submitAdddevice}
            changeAttribute={changeAttribute}
            modalControle={modalControle}
            buttonText='sharedSave'
            isVisableDeviceBtn={state.isVisableDeviceBtn}
            duplicateIdentifier={state.duplicateIdentifier}
            itemAttributes={state.itemAttributes}
            attributeChangesMessage={state.attributeChangesMessage}
            form={state.form}
            handleChange={handleChange}
            handleChange2={handleChange2}
            isHaveAccess='deviceCreate'
            showPortList={showPortList}
            btnCancelNotShow={true}
            showPort={true}
            categoriesList={state.categoriesList}
            addOption={state.addOption}
            serverIp={
              props.ServerSetting
                ? props.ServerSetting.attributes.serverIp
                : ''
            }
            port_handleChange={port_handleChange}
            timezone_handleChange={timezone_handleChange}
            changeAttribute2={changeAttribute2}
            handleChangeLicenseExpDate={handleChangeLicenseExpDate}
            logInUser={logInUser}
            unitList={state.unitList}
            translate={props.translate}
            isLoading={isLoading}
          />
          {state.isVisablePortList && (
            <DevicePortList
              themecolors={themecolors}
              onCloseModal={onCloseModal}
              translate={props.translate}
              unitList={state.unitList}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              SearchItem={SearchItem}
              setPagination={setPagination}
              page={state.page}
              pageSize={state.pageSize}
            />
          )}
        </Fragment>
      )}
      {state.editOption && (
        <Fragment>
          <AddDevice
            editOption
            onlyAttributes={props.onlyAttributes}
            selectedDevice={state.selectedDevice}
            themecolors={themecolors}
            group={group}
            changeAttribute={changeAttribute}
            formSubmit={submitEditdevice}
            handleChange={handleChange}
            handleChange2={handleChange2}
            form={state.form}
            modalControle={modalControle}
            buttonText='update'
            isVisableDeviceBtn={state.isVisableDeviceBtn}
            duplicateIdentifier={state.duplicateIdentifier}
            itemAttributes={state.itemAttributes || ''}
            attributeChangesMessage={state.attributeChangesMessage}
            btnCancelNotShow={true}
            addNewGroups={addNewGroups}
            isHaveAccess='deviceUpdate'
            timezone_handleChange={timezone_handleChange}
            changeAttribute2={changeAttribute2}
            handleChangeLicenseExpDate={handleChangeLicenseExpDate}
            notShowGroupBtn={props.notShowGroupBtn}
            logInUser={logInUser}
            port_handleChange={port_handleChange}
            unitList={state.unitList}
            translate={props.translate}
            isLoading={isLoading}

          />
        </Fragment>
      )}

      {/* {this.state.selectedDevice ?
        <EditItem
          selectedItem={this.state.selectedDevice}
          handleClose={this.modalControle}
          editEnable={this.editEnable}
          removeEnable={this.removeEnable} />
        : null} */}
    </Fragment>
  )

}
export const DeviceModal = deviceModal