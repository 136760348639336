import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MainUnitModal from './MainUnitModal'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { removeDevice, resetFilters } from './../../Actions/Devices'
import Layout from '../../Layout'
import { getGroups, saveGroupWiseDevices } from '../../Actions/Groups'
import EmptyState from '../../Components/common/EmptyState'
import { DeviceModal } from '../../Components/Devices/deviceModal'
import { checkPrivileges, errorHandler } from '../../Helpers/index'
import './Style.scss'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import Notifications from 'react-notification-system-redux'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'
import instance from '../../axios'
import axios from 'axios'
let cancelTokenSource = null;

const deviceDataFormat = {
  id: '',
  attributes: '',
  name: '',
  uniqueId: '',
  status: '',
  disabled: '',
  lastUpdate: '',
  positionId: '',
  groupId: '',
  phone: '',
  model: '',
  contact: '',
  category: '',
  geofenceIds: ''
}

const Units = (props) => {
  const [state, setState] = useState({
    page: 1,
    limit: 50,
    loading: false,
    hasMore: true,
    isNewAddEnable: false,
    activeOperation: '',
    slectedUnits: '',
    resFormData: { ...deviceDataFormat },
    resourceList: false,
    linkResource: false,
    initFetch: false,
    currentPage2: 1,
    pageSize2: 50,
    itemPagination2: {
      items: [],
      total: 0,
      currentPage: 0,
      currentDevice: props.deviceId,
      hasNext: true,
      searchText2: ''
    },
    searchText2: ''
  })

  useEffect(() => {
    if (state.itemPagination2.items) {
      props.dispatch(getGroups(state.itemPagination2?.items));
    }
  }, [state.itemPagination2.items]);

  const removeDeviceId = () => {
    let { slectedUnits } = state
    instance({
      url: `/api/devices/${slectedUnits.id}`,
      method: 'DELETE'
    })
      .then(response => {
        if (response) {
          props.dispatch(
            Notifications.success({
              message: props.translate('trackersIsDeleted'),
              autoDismiss: 10
            })
          )
          props.dispatch(removeDevice(slectedUnits.id))
          props.history.push('/units')
          setState((prevState) => ({
            ...prevState,
            onDeleteConfirmationUnits: false
          }))
        }
      }).catch(error => { errorHandler(error, props.dispatch) })
  }

  const addNewGroup = () => {
    props.history.push('/units/add/group')
  }

  useEffect(() => {
    if (props.addUnitScreen) {
      setState((prevState) => ({
        ...prevState,
        isNewAddEnable: true,
        activeOperation: 'add',
      }))
    } else if (props.match.params.id) {
      setState((prevState) => ({
        ...prevState,
        isNewAddEnable: false,
        activeOperation: 'edit'
      }))
    }

  }, [props.addUnitScreen, props.match.params.id]);

  const addNewDevice = () => {
    props.history.push('/units/add/unit')
  }

  const checkedDeviceLimit = () => {
    setState((prevState) => ({
      ...prevState,
      checkeDeviceLimit: true
    }))
  }
  const checkedDeviceLimitBackCall = () => {
    setState((prevState) => ({
      ...prevState,
      checkeDeviceLimit: false
    }))
  }

  const onRemovedDevice = (e, item) => {
    e.preventDefault()
    setState((prevState) => ({
      ...prevState,
      slectedUnits: item,
      onDeleteConfirmationUnits: true
    }))
    return false
  }

  const onCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      isNewAddEnable: false,
      activeOperation: ''
    }))
  }

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationUnits: false
    }))
  }

  const fetchMoreItems2 = () => {
    setState((prevState) => ({
      ...prevState,
      searchText2: ""
    }))
  }

  const resetFrom = () => {
    setState((prevState) => ({
      ...prevState,
      activeOperation: ''
    }))
  }


  const showResources = (resourceList) => {
    if (!resourceList) {
      props.dispatch(saveGroupWiseDevices([]))
      props.dispatch(resetFilters())
    }
    props.callOnMount()
    setState((prevState) => ({
      ...prevState,
      resourceList,
      isVisable: false,
      showItemDetail: '',
      activeOperation: '',
    }))
  }
  const addResource = () => {
    setState((prevState) => ({
      ...prevState,
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'addResource',
      selecteditem: '',
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    }))
  }
  const onEditResource = (item) => {
    setState((prevState) => ({
      ...prevState,
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'editResource',
      selecteditem: item,
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    }))
  }
  const onLinkResource = (item) => {
    setState((prevState) => ({
      ...prevState,
      linkResource: true,
      selecteditem: item,
    }))
    props.fetchNestedItems(item.id, 1)
  }
  const onCloseResource = () => {
    setState((prevState) => ({
      ...prevState,
      linkResource: false,
    }))
    onCloseModal();
  }

  const searchItems2 = text => {
    setState((prevState) => ({
      ...prevState,
      searchText2: text
    }))
  
  }


  const fetchData2 = async (page, limit, logInUsers,reset=false) => {
    if(cancelTokenSource){
      cancelTokenSource.cancel("")
    }

    cancelTokenSource=axios.CancelToken.source()
    let searchText=state.searchText2 || ""
    if(reset||searchText){
      setState((prevState) => ({
        ...prevState,
        loading: true,
        groupsFetched: true,
        itemPagination2:{...prevState.itemPagination2,items:[]}
      }));
    }
   await instance({
      url: `/api/groups/get`,
      method: 'GET',
      cancelToken: cancelTokenSource.token,
      params: {
        page: page,
        limit: limit,
        search: searchText
      }
    })
    .then((response) => {
      const totalGroupsData = response?.total;
      const getGroupsData = response?.data;
      if (getGroupsData && getGroupsData.length > 0) {
        const newGroups = getGroupsData?.map((item) => ({
          ...item,
          check: false,
        }));
        setState((prevState) => {
          const combinedGroups =reset || searchText
          ? newGroups: [...prevState.itemPagination2.items, ...newGroups];
          const uniqueGroups = combinedGroups.filter(
            (group, index, self) =>
              index === self.findIndex((g) => g.id === group.id)
          );
          return {
            ...prevState,
            itemPagination2: {
              ...prevState.itemPagination2,
              items: uniqueGroups
            },
            loading: false,
            hasMore: uniqueGroups.length < totalGroupsData,
            page:response.page
          };
        });
      } else {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          hasMore: false,
        }));
      }
    }).catch(() => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      })
  }
  // useEffect(()=>{
  //   fetchData2(state.page, state.limit, props.logInUser);  
  // },[state.page,state.limit, props.logInUser])

  useEffect(()=>{
    fetchData2(state.page, state.limit, props.logInUser,true)
  },[state.searchText2])

  const handleScroll = (values) => {
    const { scrollTop, clientHeight, scrollHeight,zoomLevel } = values;
    const {  hasMore } = state;
    let threshold;
    if (zoomLevel === 1) {
      threshold = 26;
    } else if (zoomLevel < 1) {
      threshold = scrollHeight * (1 - zoomLevel); 
    } else {
      threshold = 0;
    }
    if (hasMore) {
      if (Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight)-threshold) {
    fetchData2(state.page+1, state.limit, props.logInUser);

  }}}

 const switchToResource=()=>{
    setState((prevState)=>({
      ...prevState,
      page:1,
      limit:50
    }))
      }


  useEffect(() => {
    return () => {
      setState((prevState) => ({
        ...prevState,
        isNewAddEnable: false,
        activeOperation: '',
        slectedUnits: '',
        resFormData: { ...deviceDataFormat },

        initFetch: false,
        currentPage: 1,
        pagesize: 50,
        resourceList: false,
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: props.deviceId,
          hasNext: true,
          searchText: ''
        },
        currentPage2: 1,
        pageSize2: 50,
        searchText2: '',
        itemPagination2: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: props.deviceId,
          hasNext: true,
          searchText2: ''
        }
      }))
    }
  }, [])

  if (checkPrivileges('device') || checkPrivileges('group')) {
    const endMessage =
      props.itemPagination && props.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {props.translate('notFound')}{' '}
        </p>
      )
    return (
      <Layout
        {...props}
        removeDevice={onRemovedDevice}
        resetFrom={resetFrom}
        addDevice={addNewDevice}
        checkedDeviceLimit={checkedDeviceLimitBackCall}
        checkeDeviceLimit={state.checkeDeviceLimit}
        classFromChildren='has-padding'
        goAddGroup={addNewGroup}
        itemPagination={{ ...state.itemPagination, endMessage }}
        fetchMoreItems2={fetchMoreItems2}
        itemPagination2={{ ...state.itemPagination2 }}
        searchItems2={searchItems2}
        showResources={showResources}
        onEditResource={onEditResource}
        onLinkResource={onLinkResource}
        addResource={addResource}
        resourceList={state.resourceList}
        handleScrollResources={handleScroll}
        switchToResource={switchToResource}
      >
        {!['addResource', 'editResource'].includes(state.activeOperation) ? <div
          className='main-content-page'
          style={{
            background: props.themecolors.backgroundColor,
            color: props.themecolors.textColor,
            borderRadius: 6
          }}
        >
          {state.onDeleteConfirmationUnits && (
            <ConfirmDialoag
              onCancel={onCancel}
              onOk={removeDeviceId}
              title={props.translate('areYouWantToDelete')}
              children={state.slectedUnits.name}
            />
          )}

          {state.isNewAddEnable && props.ServerSetting ? (
            <DeviceModal
              {...props}
              logInUser={props.logInUser}
              onCloseModal={onCloseModal}
              activeOperation={state.activeOperation}
              selecteditem={state.selecteditem}
              checkedDeviceLimit={checkedDeviceLimit}
            />
          ) : props.match.params.id ? (
            <MainUnitModal
              id={props.match.params.id}
              history={props.history}
              hash={props.location.hash}
              translate={props.translate}
            />
          ) : (
            <EmptyState text={props.translate('noTrackersSelected')} />
          )}
        </div> : null}
        <ResourceModal
          changeResource={props.changeResource}
          selectedResourse={state.selecteditem}
          activeOperation={state.activeOperation}
          fetchNestedItems={props.fetchNestedItems}
          itemPagination={props.devices3}
          assignItem={props.assignItem}
          unassignItem={props.unassignItem}
          nestedResources={props.nestedResources}
          translate={props.translate}
          editResource={state.editResource}
          linkResource={state.linkResource}
          onCloseResource={onCloseResource}
          themecolors={props.themecolors}
          itemType='Device'
          title='device'
        />
      </Layout>
    )
  } else {
    return null
  }
}

const mapState = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  positions: state.positions,
  devices: state.devices,
  devices3: state.devices3,
  themecolors: state.themeColors
})

Units.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = connect(mapState)
export default mapStateToProps((withLocalize(withResources(Units, 'Device'))))