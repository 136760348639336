import React from "react";
import { useStore } from "react-redux";

export const SingleItem = React.memo(({ field, value, collection, item, children }) => {
    const ItemState =  useStore().getState()[collection];
    if(item === 'single'){
      return children(ItemState && ItemState.find(item => item[field] === value) || {});
    }else{
      return children(ItemState);
    }
});
SingleItem.displayName = 'SingleItem';
