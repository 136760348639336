import React, { PureComponent } from 'react'
import Menu from './../Menu/CommonMenu'
import MenuItem from './../common/MenuItem'
// import Checkbox from './../common/Checkbox'
import { withLocalize } from 'react-localize-redux'
// import Icon from '@mui/material/Icon'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { connect } from 'react-redux'
import './PositionMenu.scss'
import { Tooltip } from '@mui/material'
import Style from 'style-it'
import { applyDevicesFilter } from '../../Actions/Devices'
// import axios from 'axios';
import { fetchVehicles, fetchGeofences, fetchDrivers, fetchDevices, fetchUsers } from './../../Actions/Devices/index'
import { fetchTrailers } from './../../Actions/Trailer/index'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Notifications from 'react-notification-system-redux'
import Button from '../common/Button'
import Radio from '../common/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
// import PublishIcon from '@mui/icons-material/Publish';
import ExportExcel from '../common/ExportExcel'
// import ImportExcel from '../common/ImportExcel'
import { checkPrivileges, errorHandler } from '../../Helpers'
import CustomDialog from '../common/Dialog';
import instance from '../../axios'
import {unitsList} from '../Devices/unitsPortList'
class ExportImportMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      process: false,
      enableImportPopup: false,
      uploadedFile: '',
      insertionMode: '1',
      selectedFile: '',
      uploadStatus:false,
      serviceStatus:false,
      serviceData: [],
      importPolygon:false,
      isSubmit: true
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  onCloseModal = () => {
    this.setState({
      enableImportPopup: false,
      selectedFile: '',
      isSubmit: true
    })
  }
  onCloseServiceModel = () => {
    this.setState({
      enableImportPopup: false,
      serviceStatus:false,
      selectedFile: '',
      isSubmit: true
    })
  }
  onOpenModal = (name,e) => {
    this.setState({
      enableImportPopup: true,
      uploadStatus: name ==='uploadStatus'?true:false,
      importPolygon:e?true:false
    })
  }
  uploadFile = e => {
    this.setState({
      uploadedFile: e.target.files && e.target.files[0],
      selectedFile: e.target.files && e.target.files[0] && e.target.files[0].name,
      isSubmit: false
    })
  }
  selectInsertionMode = e => {
    this.setState({
      insertionMode: e.target.value
    })
  }
  formSubmit = async(e, fileName) => {
    let api, message= '';
    if(['vehicles','drivers','devices','binders','users','services'].includes(fileName)){
        api = `/api/${fileName}/import`;
    } else if(fileName === 'uploadStatus'){
      api = `api/services/import/status`;
    }
    else if(fileName === 'geofences'){
       this.state.importPolygon ?  api = `/api/geofences/import/polygon` : api = `/api/geofences/import/circle`
    }
    if(e){
        const file = await this.convertBase64(e);
        const base64 = file.split("base64,");
        instance({
            method: 'POST',
            url: api,
            data: {
                name: "anyName",
                contentType: "@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                oldUrl:"",
                insertionMode: this.state.insertionMode,
                data: base64[1]
            }
          })
            .then(response =>  {
              this.setState({importPolygon:false})
                // if(response && response.data && response.data.status === 'success'){
                if (fileName === "services") {
                    message = `${response.length} services imported`;
                    this.onCloseModal();
                    this.props.importFunc(response);
                }else if(fileName === 'vehicles'){
                    fetchVehicles(this.props.dispatch,this.props.logInUser)
                }else if(fileName === 'drivers'){
                    fetchDrivers(this.props.dispatch,this.props.logInUser)
                }else if(fileName === 'devices'){
                    this.props.dispatch(fetchDevices(this.props.logInUser, true))
                }else if(fileName === 'geofences'){
                    fetchGeofences(this.props.dispatch,this.props.logInUser)
                }else if(fileName === 'binders'){
                    fetchTrailers(this.props.dispatch,this.props.logInUser)
                }else if(fileName === 'users'){
                    fetchUsers(this.props.dispatch,this.props.logInUser)
                }
                const b = [];
                const c = response;
                Object.keys(c).map(key => {
                  if(c[key] && key !== 'total' && c.total !== c.skiped) b.push({type: this.props.translate(key), value: c[key]})
                  })
                  b.map((data, i) => {
                    
                    if(b.length > 1) {
                      if((b.length - 2) === i){
                        message += data.value +" "+ data.type + " "+ this.props.translate('AND').toLowerCase() + " "
                      }else if((b.length - 1) !== i){
                        message += data.value +" "+ data.type + ", "
                      }else{
                        message +=  data.value +" "+ data.type
                      }
                    }else{
                      message +=  data.value +" "+ data.type
                    }
                    
                  })
                  if(fileName === 'uploadStatus'){
                    this.setState({serviceStatus:true, serviceData:response, uploadStatus:false})
                    message += this.props.translate('Successfully Uploaded')
                }
                  else if(b.length === 0 && fileName !== "services"){
                    message = this.props.translate('allItemsOmitted')
                  }else{
                    message += this.props.translate('successfully')
                  }
                  if(fileName !== "services"){
                  this.props.dispatch(
                    Notifications.success({
                      message: message,
                      autoDismiss: 10
                    })
                  )}
                  this.onCloseModal()
            //     }else{
            //     this.props.dispatch(
            //         Notifications.error({
            //           message: this.props.translate('invalidFileFormat'),
            //           autoDismiss: 10
            //         })
            //       )
            // }
            })
            .catch(error => {
              errorHandler(error, this.props.dispatch)
              this.onCloseModal()
            })
    }
  }
  convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
          const fileReader =new FileReader();
          fileReader.readAsDataURL(file);

          fileReader.onload = () => {
              resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
              reject(error)
          };
      });
  }

  componentWillMount () {}

  showSelectedUnits (data) {
    const list = [...this.props.filterList]
    let newList = []
    if (list.includes(data)) {
      list.map(l => (l != data ? newList.push(l) : null))
    } else {
      newList = [...list].concat(data)
    }
    this.props.dispatch(applyDevicesFilter(newList))
  }

  componentWillReceiveProps () {}

  wrapperXML = (name) => {
    let data = [];
      name.map((e,i)=>{
        data.push((i+1) +' '+ (e+'\n'))
      }
    )

    return data
  }
  clickDownload = (fileName) => {
    instance(`/api/devices/categories`)
    .then(response=>{
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href =
        'data: application/xml;charset=utf-8,' +
        encodeURIComponent(
          this.wrapperXML(
            response.categories
          )
        )
        a.href = a.href.replaceAll('%2C', '')
      a.download = fileName + '.txt'
      a.click()
      a.remove()
      return null
    })
    .catch(error => {
      errorHandler(error, this.props.dispatch)
      })
  }
  DownloadDeviceModal = (fileName) => {
      let res = unitsList&&unitsList.map(a => a.device)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href =
        'data: application/xml;charset=utf-8,' +
        encodeURIComponent(
          this.wrapperXML(res)
        )
        a.href = a.href.replaceAll('%2C', '')
        a.download = fileName + '.txt'
        a.click()
        a.remove()
        return null
  }

  render () {
    const { anchorEl } = this.state
    const empty = null
    const {data, fileName,checkPrivilege } = this.props
    return <Style>
        {`
      .main-menu-list-wrapper {
        background-color: ${this.props.themecolors&&this.props.themecolors.themeLightColor};
      }
      a {
        color: inherit
      }
      `}
        <div>
          <a
            href={empty}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup='true'
            onClick={this.handleClick}
          >
            {
              this.props.isMaintenance && this.props.isMaintenance?(
              <Button style={{ marginLeft: 10,paddingTop:0.5,paddingBottom:0.5,paddingRight:0.5,minheight:28}} >
              {this.props.translate('select')}
               <KeyboardArrowDownIcon  style={{backgroundColor:'rgba(4, 82, 102,0.4)',marginLeft:6,width:20,height:26,borderRadius:4}} />
             </Button>):(
                <MoreVertIcon style={{fontSize: 26, marginRight: -8, verticalAlign: 'middle'}}/>
             )
            }
            
          </a>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            themecolors={this.props.themecolors}
          >
            { fileName !== 'users'  && fileName !== 'vehicles' ? <ExportExcel
              type='Sample'
              fileName={fileName}
              data={data}
              title= 'Sample'
              sample={true}
              dispatch={this.props.dispatch}
              translate={this.props.translate}
            /> : null}
             {/* {fileName == 'geofences' ? <ExportExcel
              type='Sample'
              fileName={fileName}
              data={data}
              title= 'Sample'
              sample={true}
              dispatch={this.props.dispatch}
              translate={this.props.translate}
              polygon
            /> : null} */}
            {data && data.length ? <ExportExcel
              type='Excel'
              fileName={fileName}
              data={data}
              title= 'Excel'
              sample={false}
              dispatch={this.props.dispatch}
              isMaintenance={this.props.isMaintenance}
              ServerSetting={this.props.ServerSetting}
              logInUser={this.props.logInUser}
              translate={this.props.translate}
            /> : null}
            {fileName !== ('geofences')  && fileName !== ('services') && (data && data.length) ? <ExportExcel
              type='PDF'
              fileName={fileName}
              data={data}
              title= 'PDF'
              sample={false}
              dispatch={this.props.dispatch}
              translate={this.props.translate}
            /> : null}
            {/* {fileName === ('geofences') ? <ExportExcel
              type='PDF'
              fileName={fileName}
              data={data}
              title= 'PDF'
              sample={false}
              dispatch={this.props.dispatch}
              translate={this.props.translate}
              polygon
            /> : null}
            {fileName === ('geofences') ? <ExportExcel
              type='Excel'
              fileName={fileName}
              data={data}
              title= 'Excel'
              sample={false}
              dispatch={this.props.dispatch}
              isMaintenance={this.props.isMaintenance}
              ServerSetting={this.props.ServerSetting}
              logInUser={this.props.logInUser}
              translate={this.props.translate}
              polygon
            /> : null} */}
            {(fileName !== 'users'  && fileName !== 'vehicles' ) &&
            checkPrivileges(checkPrivilege|| '')
            ? (
              <Tooltip title={this.props.translate('uploadExcel')}>
                <MenuItem
                  style={{ minWidth: 'inherit' }}
                  component="label"
                  onClick= {this.onOpenModal}
                >
                  {this.props.translate('uploadExcel')}
                </MenuItem>
            </Tooltip>
            ) : null}
              {/* {fileName === 'geofences' &&
              checkPrivileges(checkPrivilege|| '')
              ? (
                <Tooltip title=  {this.props.translate('Import Polygon')}>
                  <MenuItem
                    style={{ minWidth: 'inherit' }}
                    component="label"
                    onClick= {e=> this.onOpenModal('Polygon',true)}
                  >
                    {this.props.translate('Import Polygon')}
                    
                  </MenuItem>
              </Tooltip>
              ) : null} */}
            { fileName === 'devices' ? 
              <MenuItem
                style={{ minWidth: 'inherit' }}
                component="label"
                onClick={()=> this.clickDownload('Categories')}
              >
              {this.props.translate("sampleCategory")}
              </MenuItem> : null}
              {fileName === 'devices' ?
              <MenuItem
                  style={{ minWidth: 'inherit' }}
                  component="label"
                  onClick= {()=> this.DownloadDeviceModal('DeviceModal')}
                >
              {this.props.translate("sampleDevicesModel")}
                </MenuItem>: null}
          </Menu>
              {this.state.enableImportPopup && (
                <CustomDialog
                  title ='Import Excel'
                  themecolors={this.props.themecolors}
                  visable={true}
                  onClose={this.onCloseModal}
                  bodyPadding={10}
                  headerActions={
                    <Button
                      onClick={() => this.formSubmit(this.state.uploadedFile, this.state.uploadStatus? 'uploadStatus' :this.props.fileName)}
                      disabled={this.state.isSubmit}>
                      {this.props.translate('Submit')}
                    </Button>
                  }
                  isVisableBtn
                  noFullScreen
                >
                  <label
                  style={{padding: 10,
                    border: `1px dashed`,
                    borderRadius: 6,
                    marginBottom: 20,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  >
                    <div style={{marginRight: 'auto'}}>
                      {fileName !== 'geofences' ?
                        ( this.state.selectedFile || 'Upload Excel') : ( this.state.selectedFile || 'Geofences Upload')}
                      <input
                        type="file"
                        onChange={e => this.uploadFile(e)}
                        hidden
                      />
                    </div>
                  <Button 
                    component='span'
                  >browse file</Button>
                </label>
                <RadioGroup
                  aria-label='selectedDate'
                  name='selectedDate'
                  value={this.state.insertionMode}
                  onChange={e => this.selectInsertionMode(e)}
                  row
                >
                  <FormControlLabel
                    classes={{
                      label: 'form-contol-label'
                    }}
                    value= {'1'}
                    control={<Radio size='small' />}
                    label={this.props.translate('addNewData')}
                    labelPlacement='end'
                  />
                  { fileName !== 'geofences' && fileName !== ('services') &&
                  <FormControlLabel
                    classes={{
                      label: 'form-contol-label'
                    }}
                    value={'2'}
                    control={<Radio size='small' />}
                    label={this.props.translate('addNewAndUpdate')}
                    labelPlacement='end'
                  />}
                </RadioGroup>
              </CustomDialog>)}
              {this.state.serviceStatus && (
                <CustomDialog
                  title ='Services'
                  themecolors={this.props.themecolors}
                  visable={true}
                  onClose={this.onCloseServiceModel}
                  bodyPadding={10}
                  isVisableBtn
                  noFullScreen
                >
                  <div>
                  <table style={{ width: 500 }}>
                        <thead>
                            <th>Vehicle Plate </th>
                            <th> Message </th>
                        </thead>
                        <tbody>
                            {Object.entries(this.state.serviceData).map((key, val) => {
                                return (<tr key={val}>
                                      <td>{key[0]}</td>
                                      <td>{key[1]}</td>
                                    </tr>)
                                 })
                              }
                        </tbody>
                    </table>
                  </div>
                  

              </CustomDialog>)}
        </div>
    </Style>
  }
}
const mapStateToProps = (state) => ({
  logInUser: state.logInUsers,
  filterList: state.filterList && state.filterList.filters ? state.filterList.filters : [],
  ServerSetting:state.ServerSetting,
  // logInUser:state.logInUsers
})

export default connect(mapStateToProps)(withLocalize(ExportImportMenu))
