import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
// import isEqual from 'react-fast-compare'
import moment from 'moment'
import { withLocalize } from 'react-localize-redux'
import Layout from './../../Layout'
import Loader from './../../Layout/Loader'
import Tabs from './../../Components/common/Tabs'
import EmptyState from './../../Components/common/EmptyState'
import { removeTemplate } from './../../Actions/Templates'
import { checkPrivileges, errorHandler } from '../../Helpers'
import Notifications from 'react-notification-system-redux'
import instance from '../../axios'

const reportTypes = [
  { id: 'tripssummary', label: 'Trips', type: 'reportTrip' },
  { id: 'stopssummary', label: 'Stops', type: 'reportStop' },
  { id: 'eventssummary', label: 'Events', type: 'reportEvent' },
  { id: 'enginehourssummary', label: 'Engine Hours', type: 'reportEngineHour' },
  { id: 'geosummary', label: 'Geofence', type: 'reportGeo' },
  { id: 'fuelsummary', label: 'Fuel', type: 'reportFuel' }
]

const itemGroupTypes = {
  device: 'Device',
  driver: 'Driver',
  vehicle: 'Vehicle',
}

const checkReportTypes= () => {
  let array = reportTypes
  array.map((item, index) => {
    if (!checkPrivileges(item.type)) {
      array.splice(index)
    }
    return null
  })
}
let columns = []
let source;
const ReportData = {}

const Reports = (props) => {
    // checkReportTypes()

    const [state,setState] =useState({
      updateReportUnits:null,
      templatesCall: false,
      view: 'displayForm',
      from: '',
      to: '',
      selectedDate: '',
      reportType: 0,
      reportType1: 0,
      deviceId: [],
      vehicleId: [],
      groupId: [],
      driverId: [],
      geofenceId: [],
      sensorId: [],
      sensorValues: [{ input: "", index: "", value: "" }],
      reportsData: ReportData,
      tripsDialog: false,
      loading: false,
      validatedForm: false,
      detail: false,
      detail1: false,
      groupView: 1,
      reportUnits: 'units',
      notificationIds: [],
      types: [],
      minimalNoDataDuration: 0,
      minimalParkingDuration: 0,
      minimalTripDuration: 0,
      minimalTripDistance: 0,
      speedThreshold: 0,
      processInvalidPositions: false,
      useIgnition: false,
      controlDays: [1, 2, 3, 4, 5, 6, 7],
      controlTimeStart: '00:00',
      controlTimeEnd: '23:59',
      controlTime: [0, 1439],
      geoTimeThreshold: 1,
      isSensorTracing: false,
      timeError: false,
      isSubmit: false,
      tripsColumns: {
        averageSpeed: { checked: true },
        distance: { checked: false },
        driverName: { checked: false },
        driverUniqueId: { checked: false },
        duration: { checked: true },
        maxSpeed: { checked: true },
        spentFuel: { checked: false },
        endAddress: { checked: true },
        endOdometer: { checked: false },
        endPositionId: { checked: false },
        endLat: { checked: false },
        endLon: { checked: false },
        startAddress: { checked: true },
        startOdometer: { checked: false },
        startPositionId: { checked: false },
        startLat: { checked: false },
        startLon: { checked: false },
        endTime: { checked: false },
        startTime: { checked: false }
      },
      reportDevices: [],
      advanceSettingsForm: {},
      allowFields: [],
      eventType: 'notification',
      steps: [{ id: 1, title: 'selectReportTemplate' }, { id: 2, title: 'selectReportTimeControl' }],
      activeStep: 0,
      allSensors: false
    })

  // shouldComponentUpdate(nextProps, nextState) {
  //   return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  // }


  useEffect(() => {
      if (source) {
        source.cancel();
      }
  }, [source]);

    useEffect(() => {
    checkReportTypes();
  }, []);

  useEffect(()=>{
    if (state.reportUnits === '' && state.updateReportUnits !== null )
    setState(prevState=>({
      ...prevState,
      reportUnits: state.updateReportUnits,
      vehicleId: [],
      deviceId: [],
      groupId: [],
      driverId: []
    }))
  },[state.reportUnits,state.updateReportUnits])

 const switchGroupView = (type) => {
    setState(prevState=>({
      ...prevState,
      groupView: type,
      groupId: [],
      vehicleId: [],
      deviceId: [],
      driverId: []
    }));
  }

 const advanceFormChange = (event, name) => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value

    setState(prevState=>({
      ...prevState,
      advanceSettingsForm: { ...state.advanceSettingsForm, [name]: value }
    }))
  }

 const handleChange = (name, event) => {
    let v = event.target.value;
    if (name === 'geoTimeThreshold') v = event.target.value < 0 ? 0 : event.target.value
    setState(prevState=>({
      ...prevState,
      [name]: v
    }))
  }


 const reportTypeSelection = (type) => {
    setState(prevState=>({
      ...prevState,
      reportUnits: '',
      updateReportUnits:type
    }))
  }

//  const onCloseModal = (name) => {
//   setState(prevState=>({
//     ...prevState, [name]: false }))
//   }

const saveData = (blob, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setState((prevState) => ({ ...prevState, loading: false }));
    }, 0);
  }
  setState((prevState) => ({ ...prevState, loading: false }));
};

  // calcTime = (date, offset) => {
  //   let d = new Date(date)

  //   let utc = d.getTime() + d.getTimezoneOffset() * 60000

  //   let nd = new Date(utc + 3600000 * offset)

  //   return nd.toISOString()
  // }

  const createReport = (type) => {
    const duration = moment.duration((moment(state.to)).diff(state.from));
    const hours = duration.asHours();
    
    setState((prevState) => ({
      ...prevState,
      isSubmit: true
    }));
    
    if (!(state.isSensorTracing && (hours >= 24))) {
      let header;
      let { reportType } = state;

      setState((prevState) => ({
        ...prevState,
        detail1:
          reportType === 1 ||
          reportType === 2 ||
          reportType === 3 ||
          reportType === 4 ||
          reportType === 5 ||
          reportType === 6 ||
          reportType === 13
            ? true
            : false
      }));

      let form = {
        rpTmplId: state.reportType,
        to: state.to,
        from: state.from,
        controlDays: state.controlDays,
        controlTimeStart: moment()
          .startOf('day')
          .add(state.controlTime[0], 'minutes')
          .format('HH:mm'),
        controlTimeEnd: moment()
          .startOf('day')
          .add(state.controlTime[1], 'minutes')
          .format('HH:mm'),
        deviceIds: state.deviceId || [],
        vehicleIds: state.vehicleId || [],
        itemGroupIds: state.groupId || [],
        types: state.types,
        itemGroupType: state.groupView === 2 ? itemGroupTypes[state.reportUnits] : '',
        notificationIds: state.notificationIds,
        geofenceIds: state.geofenceId || [],
        sensors: state.sensorId || [],
        detail: false,
        driverIds: state.driverId || [],
        mail: false,
        geoTimeThreshold: (state.geoTimeThreshold || 0) * 60000
      };

      let temp = '';
      
      props.templates.map(t => {
        if (form.rpTmplId === t.rpTmpl.id) {
          temp = t.rpTmpl.name;
        }
        return null;
      });

      let accept = 'application/pdf';
      if (type === 'downloadPdf' || type === 'downloadExcel') {
        if (type === 'downloadPdf') {
          accept = 'application/pdf';
        } else {
          accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }

        header = {
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: accept
          }),
          method: 'POST',
          body: JSON.stringify(form)
        };

        setState((prevState) => ({
          ...prevState,
          loading: true,
          reportsData: {}
        }))
          fetch('/api/reports/dynamic', { ...header })
            .then(response1 => {
              if (response1.status === 200) {
                response1.blob().then(response => {
                  if (type === 'downloadPdf') {
                    saveData(response, (temp || state.reportType) + '-reports.pdf');
                  } else {
                    saveData(response, (temp || state.reportType) + '-reports.xlsx');
                  }
                  setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    detail: prevState.detail1
                  }));
                });
              } else {
                throw response1;
              }
            })
            .catch(error => {
              if (error && error.text) {
                error.text().then(err => {
                  let message = err.split("-")[0];
                  if (message) {
                    props.dispatch(
                      Notifications.error({
                        message: props.translate(message),
                        autoDismiss: 10
                      })
                    );
                  }
                });
              }
              setState((prevState) => ({
                ...prevState,
                loading: false
              }));
            });
      } else if (type === 'email') {
        form.mail = true;

        header = {
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }),
          method: 'POST',
          body: JSON.stringify(form)
        };

        setState((prevState) => ({
          ...prevState,
          loading: true,
          reportsData: {}
        }))
          fetch('/api/reports/dynamic', { ...header })
            .then(response => {
              setState((prevState) => ({
                ...prevState,
                loading: false
              }));
              if (response.ok) {
                setState((prevState) => ({
                  ...prevState,
                  detail: prevState.detail1
                }));
                props.dispatch(
                  Notifications.success({
                    message: props.translate('emailSentSuccessfully'),
                    autoDismiss: 10
                  })
                );
              } else {
                throw response;
              }
            }).catch(error => {
              if (error && error.text) {
                error.text().then(err => {
                  let message = err.split("-")[0];
                  if (message) {
                    props.dispatch(
                      Notifications.error({
                        message: props.translate(message),
                        autoDismiss: 10
                      })
                    );
                  }
                });
              }
              setState((prevState) => ({
                ...prevState,
                loading: false
              }));
            });
      } else {
        setState((prevState) => ({
          ...prevState,
          loading: true,
          reportsData: {},
          reportType1: '',
          detail: false
        }))
          instance({
            url: `/api/reports/dynamic`,
            method: 'POST',
            data: {
              ...form
            }
          })
            .then(reports => {
              if (reports.success) {
                if (reports.report && reports.report.sheets) {
                  const uniqueColumns = reports.report.sheets.flatMap((sheet) => 
                    sheet.sections?.flatMap((section) => section.columns || []) || []
                  );
                  const filterColumns = uniqueColumns.filter((value, index, self) => 
                    index === self.findIndex((col) => col.f === value.f)
                  );
                  setState((prevState) => ({
                    ...prevState,
                    reportsData: {
                      ...reports.report,
                      sheets: reports.report.sheets.map((sheet) => ({
                        ...sheet,
                        sections: sheet.sections?.map((section) => ({
                          ...section,
                          columns: filterColumns
                        })) || [],
                      })) || [],
                    },
                    reportType1: prevState.reportType,
                    detail: prevState.detail1,
                  }));
                }
              }
            })
            .catch(() => {
              setState((prevState) => ({
                ...prevState,
                loading: false
              }));
            });
      }
    } else {
      validatedCurrentStep(1, '', -1);
      props.dispatch(
        Notifications.error({
          message: props.translate('sensorTracingLimitError'),
          autoDismiss: 10
        })
      );
      setState((prevState) => ({
        ...prevState,
        timeError: true
      }));
    }
  };

  


  const updateState = (updates) => {
    setState((prevState) => ({ ...prevState, ...updates }));
  };

  const showDates = (e, date) => {
    const duration = moment.duration(moment(state.to).diff(state.from));
    const hours = duration.asHours();

    if (state.isSubmit && state.isSensorTracing && hours >= 24) {
      updateState({ timeError: true });
    } else {
      updateState({ timeError: false });
    }

    let timezone = 'Asia/Dubai';
    if (props.ServerSetting?.attributes?.timezone) {
      timezone = props.ServerSetting.attributes.timezone;
    }
    if (props.logInUser?.attributes?.timezone) {
      timezone = props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    const startDateUtc = moment(date.startDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const endDateUtc = moment(date.endDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const timeFormat = props.logInUser?.twelveHourFormat ? 'hh:mm A' : 'HH:mm';

    updateState({
      selectedDate: `${moment(date.startDate).format('YYYY-MM-DD ' + timeFormat)} - ${moment(date.endDate).format('YYYY-MM-DD ' + timeFormat)}`,
      from: startDateUtc,
      to: endDateUtc
    });
  };

  useEffect(() => {
    if (
      (state.groupId.length || state.vehicleId.length || state.deviceId.length || state.driverId.length) &&
      state.reportType &&
      state.selectedDate
    ) {
      updateState({ validatedForm: true });
    } else {
      updateState({ validatedForm: false });
    }
  }, [state.groupId, state.vehicleId, state.deviceId, state.driverId, state.reportType, state.selectedDate]);


  // const showDates = (date) => {
  //   console.warn('Date',date)
  //   const duration = moment.duration((moment(state.to)).diff(state.from));
  //   const hours = duration.asHours();
  //   if (state.isSubmit && state.isSensorTracing && hours >= 24) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       timeError: true
  //     }));
  //   } else {
  //     setState((prevState) => ({
  //       ...prevState,
  //       timeError: false
  //     }));
  //   }

  //   let timezone = 'Asia/Dubai';
  //   if (
  //     props.ServerSetting &&
  //     props.ServerSetting.attributes &&
  //     props.ServerSetting.attributes.timezone
  //   ) {
  //     timezone = props.ServerSetting.attributes.timezone;
  //   }
  //   if (
  //     props.logInUser &&
  //     props.logInUser.attributes &&
  //     props.logInUser.attributes.timezone
  //   ) {
  //     timezone = props.logInUser.attributes.timezone;
  //   }
  //   moment.tz.setDefault(timezone);

  //   var formatedDate = {
  //     from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
  //     to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
  //   }

  //   let timeFormat = 'HH:mm';
  //   if (props.logInUser && props.logInUser.twelveHourFormat) {
  //     timeFormat = 'hh:mm A';
  //   }

  //   setState((prevState) => ({
  //     ...prevState,
  //     selectedDate:
  //       moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
  //       ' - ' +
  //       moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
  //     ...formatedDate
  //   }))

  //     if (
  //       (state.groupId.length ||
  //         state.vehicleId.length ||
  //         state.deviceId.length ||
  //         state.driverId.length) &&
  //       state.reportType &&
  //       state.selectedDate
  //     ) {
  //       setState((prevState) => ({
  //         ...prevState,
  //         validatedForm: true
  //       }));
  //     } else {
  //       setState((prevState) => ({
  //         ...prevState,
  //         validatedForm: false
  //       }));
  //     }
  // };

  const selectedNotifications = (types) => {
    setState((prevState) => {
      const updatedState = { ...prevState, types };

      if (
        (updatedState.groupId.length ||
          updatedState.vehicleId.length ||
          updatedState.deviceId.length ||
          updatedState.driverId.length) &&
        updatedState.reportType &&
        updatedState.selectedDate
      ) {
        updatedState.validatedForm = true;
      } else {
        updatedState.validatedForm = false;
      }

      return updatedState;
    });
  };

  const selectedEvents = (notificationIds) => {
    setState((prevState) => {
      const updatedState = { ...prevState, notificationIds };

      if (
        (updatedState.groupId.length ||
          updatedState.vehicleId.length ||
          updatedState.deviceId.length ||
          updatedState.driverId.length) &&
        updatedState.reportType &&
        updatedState.selectedDate
      ) {
        updatedState.validatedForm = true;
      } else {
        updatedState.validatedForm = false;
      }

      return updatedState;
    });
  };

  const selectedDevices = (deviceId) => {
    setState((prevState) => {
      const newDeviceId = (deviceId && deviceId.length) ? deviceId : [];
      const updatedState = {
        ...prevState,
        deviceId: newDeviceId
      };
      updatedState.validatedForm = 
        (updatedState.groupId.length ||
         updatedState.vehicleId.length ||
         updatedState.deviceId.length ||
         updatedState.driverId.length) &&
        updatedState.selectedDate;

      return updatedState;
    });
  };

  const selectedGroups = (groupId) => {
    setState((prevState) => {
      const newGroupId = (groupId && groupId.length) ? groupId : [];
      const updatedState = {
        ...prevState,
        groupId: newGroupId
      };
      updatedState.validatedForm = 
        (updatedState.groupId.length ||
         updatedState.vehicleId.length ||
         updatedState.deviceId.length ||
         updatedState.driverId.length) &&
        updatedState.selectedDate;

      return updatedState;
    });
  };

  const selectedVehicles = (vehicleId) => {
    setState((prevState) => {
      const newVehicleId = (vehicleId && vehicleId.length) ? vehicleId : [];
      const updatedState = {
        ...prevState,
        vehicleId: newVehicleId
      };
      updatedState.validatedForm = 
        (updatedState.groupId.length ||
         updatedState.vehicleId.length ||
         updatedState.deviceId.length ||
         updatedState.driverId.length) &&
        updatedState.selectedDate;

      return updatedState;
    });
  };

  const selectedDrivers = (driverId) => {
    setState((prevState) => {
      const newDriverId = (driverId && driverId.length) ? driverId : [];
      const updatedState = {
        ...prevState,
        driverId: newDriverId
      };
      updatedState.validatedForm = 
        (updatedState.groupId.length ||
         updatedState.vehicleId.length ||
         updatedState.deviceId.length ||
         updatedState.driverId.length) &&
        updatedState.selectedDate;

      return updatedState;
    });
  };

  const selectedGeofences = (geofenceId) => {
    setState((prevState) => {
      const newGeofenceId = (geofenceId && geofenceId.length) ? geofenceId : [];
      const updatedState = {
        ...prevState,
        geofenceId: newGeofenceId
      };
      updatedState.validatedForm = 
        (updatedState.groupId.length ||
         updatedState.vehicleId.length ||
         updatedState.deviceId.length ||
         updatedState.driverId.length) &&
        updatedState.selectedDate;

      return updatedState;
    });
  };

  const selectedSensors = (sensorId) => {
    setState((prevState) => {
      const allSensors = 
        sensorId.length === 1 && sensorId[0] && sensorId[0].value === 'allSensors';
      const newSensorId = sensorId.map(e => e.value);
      const newSensorValues = sensorId;
      const updatedState = {
        ...prevState,
        allSensors,
        sensorId: newSensorId,
        sensorValues: newSensorValues
      };
      updatedState.validatedForm = 
        (updatedState.groupId.length ||
         updatedState.vehicleId.length ||
         updatedState.deviceId.length ||
         updatedState.driverId.length) &&
        updatedState.selectedDate;

      return updatedState;
    });
  };

  const reportTypeChange = (event) => {
    const tpl = props.templates.find(
      temp => temp.rpTmpl.id === event.target.value
    );
    let allowFields = [];
    if (
      tpl.rpTmpl &&
      tpl.rpTmpl.attributes &&
      tpl.rpTmpl.attributes.allowFields
    ) {
      allowFields = tpl.rpTmpl.attributes.allowFields;
    }

    setState(prevState => {
      const currentSteps = prevState.steps.filter(step => step.id < 3);

      if (allowFields.some(r => ["eventType", "notification"].includes(r))) {
        // add notification step
        currentSteps.push({ id: 3, title: "selectEventOrNotification" });
      } else if (allowFields.some(r => ["geofence"].includes(r))) {
        // add geofence step
        currentSteps.push({ id: 4, title: "selectGeofence" });
      } else if (allowFields.some(r => ["sensor", "digitalSensor"].includes(r))) {
        // add sensor step
        currentSteps.push({ id: 5, title: "selectSensor" });
      }

      // Update state
      return {
        ...prevState,
        reportType: event.target.value,
        reportUnits: allowFields[0],
        allowFields,
        eventType: allowFields.includes(prevState.eventType)
          ? prevState.eventType
          : allowFields.includes('notification')
          ? 'notification'
          : allowFields.includes('eventType')
          ? 'eventType'
          : '',
        steps: currentSteps,
        validatedForm: (prevState.groupId.length ||
          prevState.vehicleId.length ||
          prevState.deviceId.length ||
          prevState.driverId.length) &&
          prevState.reportType &&
          prevState.selectedDate
      };
    });

    tpl.rpTmplTblWrappers.forEach(i => {
      if (i.rpTmplTbl.rpId === 8) {
        setState(prevState => ({
          ...prevState,
          isSensorTracing: true
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          isSensorTracing: false
        }));
      }
    });
  };

 const reportDetail = (checked) => {
  setState(prevState => ({...prevState, detail1: checked }))
  }

 const handleChangeType = (newEventType) => {
   setState(prevState => ({...prevState,
        eventType:newEventType
      }
    ))
  }

  useEffect(()=>{
    selectedNotifications([])
    selectedEvents([])
  },[state.eventType])

  const childRendered = () => {
    if (state.loading === true && state.reportsData.head) {
      setState(prevState => ({...prevState, loading: false }))
    }
  }

 const deleteTemplate = (id) => {
    instance({
      url: `/api/report/templates/${id}`,
      method: 'DELETE',
    })
      .then(() => {
       props.dispatch(removeTemplate({ id }))
      })
      .catch(error => { errorHandler(error, props.dispatch) })
  }

const  onDayChange = (checked, obj) => {
    let dayArry = []
    if (checked) {
      dayArry = (state.controlDays || []).concat(parseInt(obj.id))
    } else {
      dayArry = (state.controlDays || []).filter(
        day => day !== parseInt(obj.id)
      )
    }
    setState(prevState => ({...prevState,
      controlDays: dayArry
    }))
  }

  const handleTimeControl = (event, controlTime) => {
    setState(prevState => ({
      ...prevState,
      controlTime,
    }));
  };

 const goToNextStep = (step, type, index) => {
    validatedCurrentStep(step, type, index);
  }
const  goToBackStep = () => {
    const currentStep = state.activeStep;
    setState(prevState => ({...prevState, activeStep: currentStep - 1 }))
  }

 const validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if (step === 1) {
      if (state.reportType) {
        if (state.allowFields.some(r => ["device", "driver", "group", "vehicle"].includes(r)) && !state.groupId.length && !state.deviceId.length && !state.driverId.length && !state.vehicleId.length) {
          validated = false
          errorMsg = <span className="text-warning">{props.translate("Please must select anything from the above list section")}</span>
        }
        else {
          errorMsg = null
          validated = true
        }
      }
      else {
        validated = false
        errorMsg = <span className="text-warning">Please select a &apos;template&apos; from above</span>
      }
    }
    else if (step === 2) {
      if (state.from && state.to) {
        errorMsg = null
        if (state.steps.length === 2) {
          createReport(type)
        } else {
          validated = true
        }
      }
      else {
        validated = false
        errorMsg = <span className="text-warning">Please select &apos;from to&apos; from above</span>
      }
    }
    else if (step === 3) {
      errorMsg = null
      if (state.steps.length === 3) {
        createReport(type)
      } else {
        validated = true
      }
    }
    else if (step === 4) {
      validated = false
      errorMsg = <span className="text-warning">Please select a &apos;geofence&apos; in above list</span>
      if (state.geofenceId.length) {
        errorMsg = null
        createReport(type)
      }
    }
    else if (step === 5) {
      validated = false
      errorMsg = <span className="text-warning">Please enter a &apos;sensor&apos; in above form</span>
      if (state.sensorId.length) {
        createReport(type)
        errorMsg = null;
      }
    }

    if (validated === true) {
      setState(prevState => ({...prevState,activeStep: index + 1 }))
    }
   state.steps.map(s => {
      if (s.id === step) {
        steps.push({ ...s, errorMsg })
      }
      else steps.push(s)

      return null
    })
    setState(prevState => ({...prevState, steps }))
  }

    let serverTimeZoneName = 'Asia/Dubai'
    if (
     props.ServerSetting &&
     props.ServerSetting.attributes &&
     props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = props.ServerSetting.attributes.timezone
    }

    if (
     props.logInUser &&
     props.logInUser.attributes &&
     props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = props.logInUser.attributes.timezone
    }

    return (
      <div>
        <Layout
          {...props}
          classFromChildren='has-padding'
          {...state}
          switchGroupView={switchGroupView}
          handleChange={handleChange}
          goToNextStep={goToNextStep}
          goToBackStep={goToBackStep}
          deleteTemplate={deleteTemplate}
          handleChangeType={handleChangeType}
          reportTypeChange={reportTypeChange}
          createReport={createReport}
          advanceSettings={state.advanceSettingsForm.advanceSettings}
          advanceSettingsForm={state.advanceSettingsForm}
          advanceFormChange={advanceFormChange}
          reportTypeSelection={reportTypeSelection}
          showDates={showDates}
          selectedDevices={selectedDevices}
          eventType={state.eventType}
          selectedNotifications={selectedNotifications}
          selectedEvents={selectedEvents}
          selectedGroups={selectedGroups}
          selectedDrivers={selectedDrivers}
          selectedVehicles={selectedVehicles}
          selectedGeofences={selectedGeofences}
          selectedSensors={selectedSensors}
          handleTimeControl={handleTimeControl}
          reportTypes={reportTypes}
          reportDetail={reportDetail}
          validatedForm={state.validatedForm}
          reportUnits={state.reportUnits}
          onDayChange={onDayChange}
        >
          <div className='main-content-page'>
            {!state.loading && !state.reportsData.head ? (
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  borderRadius: 6,
                  color: props.themecolors.textColor
                }}
              >
                <EmptyState text={props.translate('noReportSelected')} />
              </div>
            ) : null}
            <Tabs
              heading={state.reportsData.head}
              onRender={childRendered}
              tabs={state.reportsData.sheets}
              columns={columns}
              reportType={state.reportType1}
              detail={state.detail}
              themecolors={props.themecolors}
              serverTimeZone={serverTimeZoneName}
              timeFormat={props.logInUser && props.logInUser.twelveHourFormat}
              fetching={state.loading}
              translate={props.translate}
            />
            <div
              style={{
                background: props.themecolors.backgroundColor,
                color: props.themecolors.textColor
              }}
            >
              {state.loading ? <Loader /> : null}
            </div>
          </div>
        </Layout>
      </div>
    )
}

const mapStateToProps = state => ({
  devices: state.devices.data,
  logInUser: state.logInUsers,
  groups: state.groups,
  templates: state.templates,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)((withLocalize(Reports)))
