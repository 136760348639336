import React, { useEffect, useRef, useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import Layout from './../../Layout'
import VehicleTagSelector from './../../Layout/Sidebar/Components/VehicleFilterModal'
import DriverTagSelector from './../../Layout/Sidebar/Components/DriverFilterModal'
import DrawMap from './../../Components/Maps/DrawMap'
import { checkPrivileges, errorHandler } from '../../Helpers'
import {
  resetFilters,
  unsetDeviceId,
} from '../../Actions/Devices'
import { getGroups, saveGroupWiseDevices } from '../../Actions/Groups'
import { connect } from 'react-redux'
import './Style.scss'
import axios from 'axios'
import Notifications from 'react-notification-system-redux'
import {TrackingBox} from '../../Components/common/TrackingBox'
import ResourceModal from '../../Components/Recources/resourceModal'
import withResources from '../HOCRecources'
import instance from '../../axios'
import moment from 'moment'
let cancelTokenSource = null;

const options = {
  width: 250,
  margin: 10
}

const maxBoxes = 20;

const Monitoring = (props) => {

  const [state, setState] = useState({
    page: 1,
    limit: 50,
    loading: false,
    hasMore: true,
      userInfoFront: {},
      userFetch: false,
      userId: props.logInUser && props.logInUser.id ? props.logInUser.id : 0,
      initFetch: false,
      prepareTags: false,
      vehicleModal: false,
      driverModal: false,
      resourceList: false,
      linkResource: false,
      selecteditem: '',
      showAll:false,
      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        isFetching: true,
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: props.deviceId,
        hasNext: true,
        searchText2: ''
      },
      boxes: [],
      sensorsDesc:[],
      showMonitoringTracks : false,
      routes : {},
      listType:"geofences"
    })

    let mapRef = useRef(null);
    let map = mapRef.current

 useEffect(() => {
  props.dispatch(saveGroupWiseDevices([]))
  props.dispatch(resetFilters())
  if (props.logInUser && props.logInUser.id) {
    fetchData2(
      props.logInUser.id,
      state.currentPage2,
      state.pageSize2
    )
  }
 }, [])

 useEffect(() => {
  if (state.itemPagination2.items) {
    props.dispatch(getGroups(state.itemPagination2?.items));
  }
}, [state.itemPagination2.items]);

  const showResources = (type) => {
    if(!type) {
      props.dispatch(saveGroupWiseDevices([]))
      props.dispatch(resetFilters())
    }
    setState((prevState) => ({ ...prevState, 
      resourceList: type
    }))
  }

  const showBox = ({id, name}) => {
    const boxes1 = state.boxes.filter(box => box.id !== id);
    const boxes = boxes1.filter((e, index) => (index + 1) < maxBoxes);
    setState((prevState) => ({ ...prevState,  
      boxes: [...boxes, { id, name, ...options }] }));
  }

  const closeBox = (id, event) => {
    event.stopPropagation()
    setState((prevState) => ({ ...prevState, boxes: state.boxes.filter(box => box.id !== id)}));
  } 

  const setMapRef = ref => {
    map = ref
  }

  const openDeviceSettings = deviceId => {
    props.history.push('/units/' + deviceId)
  }

  const fetchMoreItems2 = () => {
    setState((prevState) => ({ ...prevState, 
        searchText2: ''
      }))
  }

  const fetchData2 = async(page, limit, logInUsers,reset=false) => {
    if(cancelTokenSource){
      cancelTokenSource.cancel("")
    }

    cancelTokenSource=axios.CancelToken.source()
    let searchText=state.searchText2 || ""
    if(reset||searchText){
      setState((prevState) => ({
        ...prevState,
        loading: true,
        groupsFetched: true,
        itemPagination2:{...prevState.itemPagination2,items:[]}
      }));
    }
   await instance({
      url: `/api/groups/get`,
      method: 'GET',
      cancelToken: cancelTokenSource.token,
      params: {
        page: page,
        limit: limit,
        search: searchText
      }
    })
    .then((response) => {
      const totalGroupsData = response?.total;
      const getGroupsData = response?.data;
      if (getGroupsData && getGroupsData.length > 0) {
        const newGroups = getGroupsData?.map((item) => ({
          ...item,
          check: false,
        }));
        setState((prevState) => {
          const combinedGroups =reset || searchText
          ? newGroups: [...prevState.itemPagination2.items, ...newGroups];
          const uniqueGroups = combinedGroups.filter(
            (group, index, self) =>
              index === self.findIndex((g) => g.id === group.id)
          );
          return {
            ...prevState,
            itemPagination2: {
              ...prevState.itemPagination2,
              items: uniqueGroups
            },
            loading: false,
            hasMore: uniqueGroups.length < totalGroupsData,
            page:response.page
          };
        });
      } else {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          hasMore: false,
        }));
      }
    }).catch(() => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      })
  }

  // useEffect(()=>{
  //   fetchData2(state.page, state.limit, props.logInUser);  
  // },[state.page,state.limit, props.logInUser])

  useEffect(()=>{
    fetchData2(state.page, state.limit, props.logInUser,true)
  },[state.searchText2])

  const handleScroll = (values) => {
    const { scrollTop, clientHeight, scrollHeight,zoomLevel } = values;
    const {  hasMore } = state;
    let threshold;
    if (zoomLevel === 1) {
      threshold = 26; 
    } else if (zoomLevel < 1) {
      threshold = scrollHeight * (1 - zoomLevel); 
    } else {
      threshold = 0; 
    }
    if (hasMore) {
      if (Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight)-threshold) {
    fetchData2(state.page+1, state.limit, props.logInUser);

  }}}

 const switchToResource=()=>{
    setState((prevState)=>({
      ...prevState,
      page:1,
      limit:50
    }))
      }

  const searchItems2 = text => {
    setState((prevState) => ({ ...prevState, 
        searchText2: text
      }))
  }
useEffect(()=>{
return()=>{
  props.dispatch(unsetDeviceId(0))
  props.dispatch(resetFilters())
  props.dispatch(saveGroupWiseDevices([]))
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }
  setState((prevState)=>({
    ...prevState,
    userInfoFront:{},
    userFetch:false,
    userId:0,
    initFetch:false,
    vehicleModal:false,
    driverModal:false,
    resourceList:false,
    linkResource:false,
    selecteditem:"",
    currentPage2:1,
    pageSize2:-1,
    itemPagination2:{
      items:[],
      total:0,
      currentPage:0,
      currentDevice:props.deviceId,
      hasNext:true,
      searchText2:""
    }
  }))
}
},[])

  const copyToClipboard = (obj,chk) => {
    const el = document.createElement('textarea')
    chk ?el.value = obj :el.value = `${obj.latitude},${obj.longitude}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    props.dispatch(
      Notifications.success({
        message: 'Copied to clipboard!',
        autoDismiss: 10
      })
    )
  }

  const openVehicleTagsModal = () => {
    setState((prevState) => ({ ...prevState, 
      vehicleModal: true
    }))
  }

  const openDriverTagsModal = () => {
    setState((prevState) => ({ ...prevState, 
      driverModal: true
    }))
  }

  const cancelFilter = () => {
    setState((prevState) => ({ ...prevState, 
      vehicleModal: false,
      driverModal: false
    }))
  }

  const saveFilter = () => {
    setState((prevState) => ({ ...prevState, 
      vehicleModal: false,
      driverModal: false
    }))
  }

  const onLinkResource = (item) =>{
    setState((prevState) => ({ ...prevState, 
      linkResource: true,
      selecteditem: item,
    }))
    props.fetchNestedItems(item.id,1)
  }
  const onCloseResource = () =>{
    setState((prevState) => ({ ...prevState, 
      linkResource: false,
      selecteditem: ''
    }));
  }

  const getSensorsData = (id) =>{
       instance({
        url: `api/attributes/computed`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params :{
          deviceId: id ,
          category:"all",
          all:true
        }
      })
      .then(
        response => {
          if (response) {
            setState((prevState) => ({ ...prevState, 
              sensorsDesc:response
            }))
          }
          
        }).catch(() => {
          // errorHandler(error, props.dispatch)
        })
  }
 const  showAllTooltTip = ()=> {
     setState((prevState) => ({ ...prevState, showAll:!state.showAll}))
  }  

  const getTimezone = () => {
    let timezone = 'Asia/Dubai';
  
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone;
    }
  
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone;
    }
  
    return timezone;
  };

 const  hideTracks =() =>{
    setState((prevState) => ({ ...prevState, showMonitoringTracks:false, routes:{}}))
  }



 const  showTracks = (deviceId) => {
    setState((prevState) => ({ ...prevState, 
      showMonitoringTracks : true,
    }))

    const timezone = getTimezone();

    moment.tz.setDefault(timezone);

    const todayStart = moment().startOf('day');
    const todayEnd = moment().endOf('day');

    const formatedDate = {
        from: todayStart.toISOString(),
        to: todayEnd.toISOString()
    };

    setState((prevState) => ({ ...prevState, 
      showMonitoringTracks : false,
      tracksLoader:true,
    }));
      instance({
          url: `/api/reports/tracks?from=${formatedDate.from}&to=${formatedDate.to}&deviceId=${deviceId?.id}`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
      })
      .then(response => {
        let obj = response || {};
        const transformedObj = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key].length === 0) {
              props.dispatch(
                Notifications.success({
                  message: `No tracks found for vehicle ${deviceId.name}`,
                  autoDismiss: 10
                })
              );
            } else {
              obj[key].forEach((item, index) => {
                transformedObj[`${index + 1}`] = { visible: true, positions: item.positions, row: item.track };
              });
            }
          }
        }
      
        setState((prevState) => ({ ...prevState, 
          showMonitoringTracks: Object.keys(transformedObj).length > 0,
          tracksLoader: false,
          routes: transformedObj || {}
        }));
      })
      .catch(error => {errorHandler(error, props.dispatch)});
}
const showListType = (type) => {
  setState((prevState) => ({ ...prevState, 
    listType: type
  }))
}

    if (checkPrivileges('device')) {
      const endMessage =
        props.itemPagination && props.itemPagination.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            -- {props.translate('end')} --
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {props.translate('notFound')}
          </p>
        )
      const {devices3, ...resetProps}  = props;
      return (
        <Layout
          {...resetProps}
          sensorsDesc={state.sensorsDesc}
          monitoringMap={map}
          openDeviceSettings={openDeviceSettings}
          classFromChildren='main-view'
          getSensorsData={getSensorsData}
          showBox={showBox}
          openVehicleTagsModal={openVehicleTagsModal}
          openDriverTagsModal={openDriverTagsModal}
          itemPagination={{ ...state.itemPagination, endMessage }}
          // searchItems={searchItems}
          fetchMoreItems2={fetchMoreItems2}
          itemPagination2={{ ...state.itemPagination2 }}
          searchItems2={searchItems2}
          resourceList={state.resourceList}
          showResources={showResources}
          onLinkResource={onLinkResource}
          copyToClipboard={copyToClipboard}
          showAllTooltTip={showAllTooltTip}
          showListType={showListType}
          noSidebar={state.showMonitoringTracks}
          handleScrollResources={handleScroll}
          switchToResource={switchToResource}
          
        >
          <ResourceModal
            changeResource={props.changeResource}
            selectedResourse={state.selecteditem}
            activeOperation={state.activeOperation}
            onCloseResource={onCloseResource}
            itemPagination={devices3}
            assignItem={props.assignItem}
            unassignItem={props.unassignItem}
            fetchNestedItems={props.fetchNestedItems}
            nestedResources={props.nestedResources}
            translate={props.translate}
            linkResource={state.linkResource}
            themecolors={props.themecolors}
            itemType='Device'
            title='device'
          />
          <TrackingBox boxes={state.boxes} translate={props.translate} logInUser={props.logInUser} copyToClipboard={copyToClipboard} closeBox={closeBox} />
          
          <DrawMap
            {...resetProps}
            listType={state.listType}
            routes={state.routes}
            showMonitoringTracks={state.showMonitoringTracks}
            showTracks={showTracks}
            hideTracks={hideTracks}
            setMapRef={setMapRef}
            devices={props.devices.data}
            copyToClipboard={copyToClipboard}
            showAll={state.showAll}
            vehicles={props.vehicles}
            showListType={showListType}
          />

          {state.vehicleModal ? (
            <VehicleTagSelector
              userTags={props.logInUser.attributes}
              tags={props.tags}
              vehicleModal={state.vehicleModal}
              translate={props.translate}
              dispatch={props.dispatch}
              saveFilter={saveFilter}
              cancelFilter={cancelFilter}
            />
          ) : null}
          {state.driverModal ? (
            <DriverTagSelector
              userTags={props.logInUser.attributes}
              tags={props.driverTags}
              driverModal={state.driverModal}
              translate={props.translate}
              dispatch={props.dispatch}
              saveFilter={saveFilter}
              cancelFilter={cancelFilter}
            />
          ) : null}
        </Layout>
      )
    } else {
      return null
    }
  // }
}

const mapStateToProps = state => {
  return {
    devicesFetched: state.devices.isFetching,
    devices: state.devices,
    devices3: state.devices3,
    trailers: state.trailers,
    tags: state.tags,
    driverTags: state.driverTags,
    allNotifications: state.allNotifications,
    deviceId: state.deviceId,
    trackId: state.trackId,
    drivers: state.drivers,
    ServerSetting: state.ServerSetting,
    mapLayer: state.mapLayer,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    filterTagsList: state.filterTagsList,
    filterDriversList: state.filterDriversList,
    vehicles:state.vehicles,
    filterList:
      state.filterList && state.filterList.filters
        ? state.filterList.filters
        : []
  }
}

export default connect(mapStateToProps)(withLocalize(withResources(Monitoring, 'Device')))
