import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { EditItem } from './EditItem'
import CustomDialog from './../common/Dialog'
import {errorHandler } from './../../Helpers'
import { withLocalize } from 'react-localize-redux'
import Loader from '../../Layout/Loader'
import isEqual from 'react-fast-compare'
// import { addDriver, removeDriver, updateDriver } from './../../Actions/Drivers'
import { addTrailer, updateTrailer } from './../../Actions/Trailer'
// import {
//   updateDeviceUserWiseSelectedList,
//   RemoveDeviceUserWise,
//   addDeviceUserWise,
// } from './../../Actions/Notifications'
import AddTrailer from './addTrailer'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Notifications from 'react-notification-system-redux'
import instance from '../../axios'
import Axios from 'axios'

const trailerDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...extAttriubtes },
  name: data.name.trim(),
  description: "",
  uniqueId: data.uniqueId.trim(),
  type:data.type ? data.type:'trailer',
  expirationTime:data.expirationTime?data.expirationTime:''
})

const formDefault = {
  id:"",
  type:'',
  name: '',
  uniqueId: '',
  attributes: {},
  trailerAddress: '',
  expirationTime:''
}

// class trailerModal extends Component {
const trailerModal = (props) => {
  const [state, setState] = useState({
    dm_isVisable: false,
    selectedTrailer: '',
    addOption: false,
    editOption: false,
    dr_attriubtes: '',
    isVisibleBtn: false,
    isAttChanged: false,
    trailerUniqueId: '',
    form: formDefault,
    itemAttributes: {},
    attributeChangesMessage: '',
    removedIdPropcess: '',
    imagesData: '',
    uploadingImage:false
  })

  const [isLoading,setIsLoading]=useState(false)

  
  useEffect(() => {
    checkRequiredFields();
  }, [state.form]);

  useEffect(() => {
    const initializeComponent = async () => {
      if (props.activeOperation === '') {
        setState(prevState => ({
          ...prevState,
          editOption: false,
          addOption: false,
        }));
      }
      if (props.showItemDetail && props.trackersApiResponce) {
        let formData = setFormData(props.selecteditem);
        let attributes = props.selecteditem.attributes;
        let trackers = props.multiTrackers;
        formData = { ...formData, attributes, trackers };
        setState(prevState => ({
          ...prevState,
          selectedTrailer: formData,
          trailerUniqueId: props.selecteditem.id
        }));
      } else {
        if (props.activeOperation === 'edit') {
          let formData = await setFormData(props.selecteditem);
          let attributes = props.selecteditem.attributes;
          formData = { ...formData, attributes };
          if (
            !state.editOption ||
            !isEqual(state.selectedTrailer, formData)
          ) {
            setState(prevState => ({
              ...prevState,
              trailerUniqueId: props.selecteditem.id,
              selectedTrailer: formData,
              itemAttributes: props.selecteditem.attributes,
              editOption: true,
              addOption: false,
              dm_isVisable: true,
              attributeChangesMessage: '',
              isVisibleBtn: false,
              isAttChanged: false,
              dr_attriubtes: props.selecteditem.attributes,
              form: { ...formData }
            }));
            checkRequiredFields();
          }
        }
        if (props.activeOperation === 'add' && !state.addOption) {
          setState(prevState => ({
            ...prevState,
            trailerUniqueId: props.selecteditem.id,
            selectedTrailer: '',
            addOption: true,
            editOption: false,
            dm_isVisable: true,
            form: formDefault,
            isVisibleBtn: false,
            isAttChanged: false,
            attributeChangesMessage: ''
          }));
        }
      }
      if (props.selectedAddress !== '' && props.selectedAddress !== undefined) {
        setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            trailerAddress: props.selectedAddress
          }
        }));
      }
    };
  
    initializeComponent();
  }, []); 
  
  useEffect(() => {
    const updateComponentOnPropsChange = async () => {
      if (props.selecteditem.id !== state.trailerUniqueId) {
        if (props.showItemDetail && props.trackersApiResponce) {
          let formData = setFormData(props.selecteditem);
          let attributes = props.selecteditem.attributes;
          let trackers = props.multiTrackers;
          formData = { ...formData, attributes, trackers };
          setState(prevState => ({
            ...prevState,
            selectedTrailer: formData,
            trailerUniqueId: props.selecteditem.id
          }));
        } else {
          if (props.activeOperation === 'edit') {
            let formData = setFormData(props.selecteditem);
            let attributes = props.selecteditem.attributes;
            formData = { ...formData, attributes };
            if (
              !state.editOption ||
              !isEqual(state.selectedTrailer, formData)
            ) {
              setState(prevState => ({
                ...prevState,
                trailerUniqueId: props.selecteditem.id,
                selectedTrailer: formData,
                editOption: true,
                addOption: false,
                dm_isVisable: true,
                attributeChangesMessage: '',
                isVisibleBtn: false,
                isAttChanged: false,
                itemAttributes: props.selecteditem.attributes,
                dr_attriubtes: props.selecteditem.attributes,
                form: { ...formData }
              }));
              checkRequiredFields();
            }
          }
          if (props.activeOperation === 'add' && !state.addOption) {
            setState(prevState => ({
              ...prevState,
              trailerUniqueId: props.selecteditem.id,
              selectedTrailer: '',
              addOption: true,
              editOption: false,
              dm_isVisable: true,
              form: formDefault,
              isVisibleBtn: false,
              isAttChanged: false,
              attributeChangesMessage: ''
            }));
            checkRequiredFields();
            if (props.selectedAddress !== '' && props.selectedAddress !== undefined) {
              setState(prevState => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  trailerAddress: props.selectedAddress
                }
              }));
              checkRequiredFields();
            }
          }
        }
      }
      if (props.selectedAddress !== '' && props.selectedAddress !== undefined) {
        setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            trailerAddress: props.selectedAddress
          }
        }));
        checkRequiredFields();
      }
    };
  
    updateComponentOnPropsChange();
  }, [props.selecteditem.id, props.showItemDetail, props.trackersApiResponce, props.activeOperation, props.selectedAddress]);

      const getImage = async (id) => {
        try {
          const response = await Axios.get(`/api/media/binder/${id}`, {
            responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
          });
          const filename = id;
          const parts = filename.split('.');
          const extension = parts[parts.length - 1];
    
          if (response.status === 200) {
            const blob = new Blob([response.data], { type: `image/${extension}` });
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsDataURL(blob); 
            });
          } else {
            console.error('Failed to fetch image:', response.status, response.statusText);
            return '';
          }
        } catch (error) {
          console.error('Error in getImage:', error);
          return '';
        }
      };

    const setFormData = async (data) => {
      let trailerImg; 
      if (process.env.REACT_APP_SERVER_LOCAL === "true") {
        if (data.attributes.trailerImg) {
          try {
            trailerImg = await getImage(data.attributes.trailerImg);
          } catch (error) {
            console.error('Error getting image======', error);
            trailerImg = '';
          }
        }
      }
      else {
        if (data.attributes.trailerImg) {
          trailerImg = data.attributes.trailerImg; 
        }
      }
      let formData = {
        id: data.id,
        name: data.name,
        uniqueId: data.uniqueId,
        department: data.attributes.department || '',
        trailerImg: trailerImg || '',
        type: data.type || '',
        expirationTime: data.expirationTime || ''
      };
      return formData
    }
    
      const submitDataPepration = () => {
        let { itemAttributes, form } = state
        if (form.trailerAddress) {
          let trailerAddress = form.trailerAddress
          itemAttributes = {
            ...itemAttributes,
            trailerAddress: trailerAddress
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          }
        }
        if (form.attributes.tag_1 !== undefined) {
          let tag_1 = form.attributes.tag_1
          itemAttributes = { ...itemAttributes, tag_1 }
        }
        if (form.attributes.tag_2 !== undefined) {
          let tag_2 = form.attributes.tag_2
          itemAttributes = { ...itemAttributes, tag_2 }
        }
        if (form.attributes.tag_3 !== undefined) {
          let tag_3 = form.attributes.tag_3
          itemAttributes = { ...itemAttributes, tag_3 }
        }
        if (form.attributes.tag_4 !== undefined) {
          let tag_4 = form.attributes.tag_4
          itemAttributes = { ...itemAttributes, tag_4 }
        }
        if (form.attributes.tag_5 !== undefined) {
          let tag_5 = form.attributes.tag_5
          itemAttributes = { ...itemAttributes, tag_5 }
        }
    
        if (form.email) {
          let email = form.email
          itemAttributes = { ...itemAttributes, email }
        }
        if (form.licenseClass) {
          let licenseClass = form.licenseClass
          itemAttributes = { ...itemAttributes, licenseClass }
        }
        if (form.licenseNumb) {
          let licenseNumb = form.licenseNumb
          itemAttributes = { ...itemAttributes, licenseNumb }
        }
        if (form.licenseExpDate) {
          let licenseExpDate = form.licenseExpDate
          itemAttributes = { ...itemAttributes, licenseExpDate }
        }
        if (form.phone) {
          let phone = form.phone
          itemAttributes = { ...itemAttributes, phone }
        }
        if (form.department) {
          let department = form.department
          itemAttributes = { ...itemAttributes, department }
        }
        if (props.trailerLat) {
          let trailerLat = props.trailerLat
          itemAttributes = { ...itemAttributes, trailerLat }
        }
        if (props.trailerLon) {
          let trailerLon = props.trailerLon
          itemAttributes = { ...itemAttributes, trailerLon }
        }
        if (form.trailerImg) {
          let trailerImg = form.trailerImg
          itemAttributes = { ...itemAttributes, trailerImg }
        }
        return itemAttributes
      }

      const onCloseModal = () => {
        setState((prevState)=>({
          ...prevState,
          enableImagePopup: false
        }))
      }
    
    const imageDataPrepare = () => {
        const { imagesData} = state;
        if (process.env.REACT_APP_SERVER_LOCAL==="true") {
          if (state.editOption) {
            ud_submit();
          } else {
            ad_submit();
          }
    } else {
      if (imagesData) {
      setIsLoading(true)

            instance({
              url: `/api/uploads`,
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
             },
              data:{
                ...imagesData
              }
              }).then(response => {
            setState(prevState=>
              ({
                ...prevState,
                isVisibleBtn: false,
                form: {
                  ...prevState.form,
                  trailerImg: response.publicUrl ? response.publicUrl : ''
                }
              }))
      setIsLoading(false)

              setIsUpload(true)
                
          })
          .catch(error => {
      setIsLoading(false)

            console.error('Error in imageDataPrepare:', error);
            // Handle the error, display a message, etc.
          });
    }
    else {
      if (state.editOption) {
        ud_submit();
      } else {
        ad_submit();
      }
  } 
  }

  };
const[isUpload,setIsUpload]=useState(false)
  useEffect(()=>{
    if (state.editOption&&isUpload) {
      ud_submit();
      setIsUpload(false)
    } else {
      if(isUpload)
      {ad_submit();
      setIsUpload(false)}
    }
  },[isUpload])

      const ad_submit = () => {
        let { form } = state
        let preperData = {
          name: form.name,
          uniqueId: form.uniqueId,
          expirationTime:form.expirationTime?form.expirationTime:'',
          type:form.type?form.type:''
        }
        setState((prevState)=>({
          ...prevState,
          isVisibleBtn: false
        }))
        if (
          form.name &&
          form.name.trim() &&
          form.uniqueId &&
          form.uniqueId.trim()
        ) {
          let attriubtes = submitDataPepration()
          const obj = trailerDataFormat(preperData, attriubtes)
          setIsLoading(true)
          instance({
            url: '/api/binders',
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data:{
              ...obj
            }
            })
                .then(trailer => {
                    props.dispatch(addTrailer(trailer))
                    props.onCloseModal()
                    props.fetchMoreItems()
                    setState((prevState)=>({
                      ...prevState,
                      dm_isVisable: !state.dm_isVisable,
                      addOption: false,
                      dr_attriubtes: '',
                      selectedTrailer: '',
                      form: formDefault,
                      attributeChangesMessage: '',
                      imagesData: ''
                    }))
                    props.dispatch(
                      Notifications.success({
                        message: props.translate('trailerIsCreated'),
                        autoDismiss: 10
                      })
                    )
          setIsLoading(false)

            })
            .catch(error => {
          setIsLoading(false)

              errorHandler(error, props.dispatch)
            })
        } else {
          props.dispatch(
            Notifications.error({
              message: props.translate('emptyField'),
              autoDismiss: 10
            })
          )
        }
      }

     const ud_submit = () => {
        const { form } =state
        let preperData = {
          name: form.name,
          uniqueId: form.uniqueId,
          expirationTime:form.expirationTime?form.expirationTime:'',
          type:form.type?form.type:''
        }
        setState(prevState=>({
          ...prevState,
          isVisibleBtn: false
        }))
        let attriubtes = submitDataPepration()
        const obj = trailerDataFormat(preperData, attriubtes, state.trailerUniqueId)
        delete obj.attributes.lat
        delete obj.attributes.lng
        delete obj.attributes.trailerLng
        if (!obj.attributes.tag_1) delete obj.attributes.tag_1
        if (!obj.attributes.tag_2) delete obj.attributes.tag_2
        if (!obj.attributes.tag_3) delete obj.attributes.tag_3
        if (!obj.attributes.tag_4) delete obj.attributes.tag_4
        if (!obj.attributes.tag_5) delete obj.attributes.tag_5
        setIsLoading(true)
        instance({
          url: `/api/binders/${state.trailerUniqueId}`,
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data:{
            ...obj
          }
          }).then(trailer => {
                  props.dispatch(updateTrailer(trailer))
                  props.onCloseModal()
                  props.fetchMoreItems()
                  props.dispatch(
                    Notifications.success({
                      message: props.translate('trailerIsUpdated'),
                      autoDismiss: 10
                    })
                  )
                  setState(prevState=>({
                    ...prevState,
                    itemAttributes: trailer.attributes,
                    dr_attriubtes: trailer.attributes,
                    dm_isVisable: !state.dm_isVisable,
                    selectedTrailer: trailer,
                    attributeChangesMessage: '',
                    isVisibleBtn: false,
                    isAttChanged: false,
                    uploadingImage:false,
                    imagesData: ''
                  }))
        setIsLoading(false)

          })
          .catch(error => {
        setIsLoading(false)

            errorHandler(error, props.dispatch)
          })
      }


    const   onCloseSubModal = () => {
        setState((prevState)=>({
          ...prevState,
          dm_isVisable: false,
          addOption: false,
          editOption: false
        }))
        props.onCloseModal()
      }
    
      const changeAttribute = (name, value, selectedOption, updateAtt) => {
        if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            dr_attriubtes: updateAtt, // Directly update dr_attriubtes with the provided updateAtt
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            dr_attriubtes: {
              ...prevFormData.dr_attriubtes,
              [name]: value,
            },
          }));
        }
    
        checkForAttrubutesChanged(selectedOption); 
      };
    
    const   checkForAttrubutesChanged = selectedOption => {
        let changed = false
        if (state.selectedTrailer) {
          changed = !isEqual(
            state.dr_attriubtes,
            state.selectedTrailer.attributes
          )
        } else {
          changed = !isEmpty(state.dr_attriubtes)
        }
        let { name, uniqueId } = state.form
        let selectedTrailer = {}
        if ((name && uniqueId) || (name && uniqueId && changed)) {
          selectedTrailer = state.dr_attriubtes
          if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
            setState((prevState)=>({
              ...prevState,
              isVisibleBtn: changed,
              isAttChanged: changed,
              itemAttributes: { ...selectedTrailer }
            }))
          } else {
            setState((prevState)=>({
              ...prevState,
              isVisibleBtn: changed,
              isAttChanged: changed,
              itemAttributes: { ...prevState.itemAttributes, ...selectedTrailer }
            }))
          }
        } else {
          selectedTrailer = state.dr_attriubtes
          if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
            setState((prevState)=>({
              ...prevState,
                isVisibleBtn: false,
                isAttChanged: false,
                itemAttributes: { ...selectedTrailer }
              }
            ))
            checkRequiredFields()
          } else {
            setState((prevState)=>({
              ...prevState,
                isVisibleBtn: false,
                isAttChanged: false,
                itemAttributes: { ...prevState.itemAttributes, ...selectedTrailer }
              }
            ))
            checkRequiredFields()
          }
        }
        if (changed) {
          setState((prevState)=>({
            ...prevState,
            attributeChangesMessage: 'Change in attributes is pending'
          }))
        } else {
          setState((prevState)=>({
            ...prevState,
              attributeChangesMessage: ''
            }
          ))
          checkRequiredFields()
        }
      }
     const  isEmpty = obj => {
        for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
          return false;
        }
      }
        return true
      }


    const   handleChange = name => event => {
        const { target } = event
        if (name === 'licenseExpDate' || name === 'expirationTime') {
          setState((prevState)=>({
            ...prevState,
              form: {
                ...prevState.form,
                [name]: event.toISOString()
              }
            }
          ))
          checkRequiredFields()
        } else {
          setState((prevState)=>({
            ...prevState,
              form: {
                ...prevState.form,
                [name]: target.value
              }
            }
          ))
          checkRequiredFields()
        }
      }
     const  changeAttribute2 = (name, event) => {
        setState((prevState)=>({
          ...prevState,
              form: {
                ...prevState.form,
                attributes: {
                  ...prevState.form.attributes,
                  [name]: event.target.value
                }
              }
            }
          ))
          checkRequiredFields()
        
      }

     const  checkRequiredFields = () => {
        let { name, uniqueId } = state.form
        let value = !isEqual(state.form, state.selectedTrailer)
        if (name && uniqueId && value) {
          setState((prevState)=>({
            ...prevState,
            isVisibleBtn: true
          }))
        } else {
          setState((prevState)=>({
            ...prevState,
            isVisibleBtn: state.isAttChanged
          }))
        }
      }
    const   setExpirationTime = e => {
        e.stopPropagation()
        setState((prevState)=>({
          ...prevState,
            form: {
              ...prevState.form,
              licenseExpDate: null
            }
          }
        ))
        checkRequiredFields()
      }
    
    const uploadImage = async (event) => {
        let files = event.target.files
        const { form } = state;

        let imgData = ''
        let reader = new FileReader()
        if (files && files[0]) {
          if(process.env.REACT_APP_SERVER_LOCAL === "true" && form.id){

            const response = await fetch(`/api/uploads/binder/${form.id}`, {
              method: 'POST',
              body: files[0],
            });
            if (response.ok) {
              const responseData = await response.json(); // Parse JSON response
               const imageName = responseData.data.image;
               setState((prevState)=>({
                ...prevState,
                  imagesData:  URL.createObjectURL(files[0]),
                  form: {
                    ...prevState.form,
                    trailerImg: imageName ,
                    attributes:{
                      ...prevState.form.attributes,
                      trailerImg:imageName
                    }
                  }
                }
              ));
                  let { name, uniqueId } = state.form;
                  if (name && uniqueId) {
                    setState((prevState)=>({
                      ...prevState,
                      isVisableUserBtn: true,
                      uploadingImage:true
                    }));
                   checkRequiredFields()
                  } else {
                    setState((prevState)=>({
                      ...prevState,
                      isVisableUserBtn: state.isAttChanged,
                      uploadingImage:true

                    }));
                   checkRequiredFields()
                  }
                // }
      
            } else {
              throw Error(await response.text());
            }
          }
        else{
          reader.readAsDataURL(files[0])
          reader.onload = e => {
            imgData = e.target.result
            if (imgData.split(':')[1].split('/')[0] === 'image') {
              let obj = {
                name: `${state.form.uniqueId + new Date().getTime()}`,
                contentType: `${files[0].type}`,
                oldUrl: `${state.form.trailerImg}`,
                data: `${imgData.split('base64')[1].substring(1)}`
              }
              setState((prevState)=>({
                ...prevState,
                  imagesData: obj,
                  form: {
                    ...prevState.form,
                    trailerImg: imgData
                  }
                }
              ))
                  let { name, uniqueId } = state.form
                  if (name && uniqueId) {
                    setState((prevState)=>({
                      ...prevState,
                      isVisibleBtn: true
                    }))
                  } else {
                    setState((prevState)=>({
                      ...prevState,
                      isVisibleBtn: state.isAttChanged
                    }))
                  }
                // }
            } else {
              props.dispatch(
                Notifications.error({
                  message: props.translate('onlyAcceptImage'),
                  autoDismiss: 10
                })
              )
            }
          }
        }} else {
          setState((prevState)=>({
            ...prevState,
              imagesData: '',
              form: {
                ...prevState.form,
                trailerImg: props.selecteditem.attributes
                  ? props.selecteditem.attributes.trailerImg
                  : ''
              }
            }
          ))
            setState((prevState)=>({
              ...prevState,
                isVisibleBtn: state.isAttChanged
              }))
        }
      }

    const   imagePopup = () => {
        setState((prevState)=>({
          ...prevState,
          enableImagePopup: true
        }))
      }

        return (
          <div style={{ height: '100%', position: 'relative', zIndex: 9999 }}>
            {state.dm_isVisable && state.addOption && (
              <AddTrailer
                onClose={onCloseSubModal}
                selectedTrailers={state.selectedTrailer}
                formSubmit={imageDataPrepare}
                changeAttribute={changeAttribute}
                handleChange={handleChange}
                changeAttribute2={changeAttribute2}
                form={state.form}
                buttonText='sharedCreate'
                translate={props.translate}
                isVisibleBtn={state.isVisibleBtn}
                itemAttributes={state.itemAttributes}
                attributeChangesMessage={state.attributeChangesMessage}
                setExpirationTime={setExpirationTime}
                isHaveAccess='binderCreate'
                uploadImage={uploadImage}
                imagePopup={imagePopup}
                addOption
                isLoading={isLoading}
              />
            )}
            {state.selectedTrailer && state.editOption && (
              <>
              <AddTrailer
                onClose={onCloseSubModal}
                selectedTrailers={state.selectedTrailer}
                formSubmit={imageDataPrepare}
                changeAttribute={changeAttribute}
                handleChange={handleChange}
                changeAttribute2={changeAttribute2}
                form={state.form}
                buttonText='update'
                translate={props.translate}
                isVisibleBtn={state.isVisibleBtn}
                itemAttributes={state.itemAttributes}
                attributeChangesMessage={state.attributeChangesMessage}
                setExpirationTime={setExpirationTime}
                isHaveAccess='binderUpdate'
                imagesData={state.imagesData}
                uploadingImage={state.uploadingImage}
                uploadImage={uploadImage}
                imagePopup={imagePopup}
                editOption
                isLoading={isLoading}

              />
              </>
            )}
            {state.selectedTrailer &&
            props.showItemDetail &&
            props.trackersApiResponce 
            ? (
              <EditItem
                devices={props.devices}
                currentLocation={props.currentLocation}
                linkedPositionHistory={props.linkedPositionHistory}
                unLinkedPositionHistory={props.unLinkedPositionHistory}
                linkedDevice={props.linkedDevice}
                unLinkedDevice={props.unLinkedDevice}
                selectedItem={state.selectedTrailer}
                handleClose={onCloseSubModal}
                themeColors={props.themecolors}
                devicesIcons={props.devicesIcons}
                showOnMap={props.showOnMap}
              />
            ) : !state.addOption && !state.editOption ? (
              <Loader />
            ) : null}
            {state.enableImagePopup && state.form.trailerImg && (
              <CustomDialog
                themecolors={props.themecolors}
                visable={true}
                onClose={onCloseModal}
                bodyPadding={10}
                cancelText={props.translate('sharedCancel')}
                noFullScreen
              >
                <div style={{ minHeight: 200, margin: 0, textAlign: 'center' }}>
                  <img
                    style={{ width: 500, height: 400 }}
                    alt='trailerImage'
                    src={state.form.trailerImg}
                  />
                </div>
              </CustomDialog>
            )}
          </div>
        )
}

const mapState = state => ({
    devices2: state.devices2,
    drivers: state.drivers,
    trailers: state.trailers,
    devices: state.devices.data,
    themecolors: state.themeColors,
    selecteddevices: state.selecteddevices
  })


  const mapStateToProps = connect(mapState)
  export const TrailerModal = mapStateToProps((withLocalize(trailerModal)))

