import React, { Fragment, useEffect, useState } from 'react'
import Button from '../../../Components/common/Button'
import CustomDialog from '../../../Components/common/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import Ignition from './ignition'
import OtherSensors from './otherSensors'
import AggregateSensors from './aggregateSensors'
import isEqual from 'react-fast-compare'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import Notifications from 'react-notification-system-redux'
import ReactHtmlParser from 'react-html-parser'
import {
  updateComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise
} from './../../../Actions/ComputedAttributes'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import { ReactComponent as DiscreteIcon } from '../../../assets/sensors/discrete.svg'
import { ReactComponent as MeasurementIcon } from '../../../assets/sensors/centimeter.svg'
import { ReactComponent as OtherSensorIcon } from '../../../assets/sensors/sensor.svg'
import { ReactComponent as AggregateIcon } from '../../../assets/sensors/chip.svg'
import { ReactComponent as DecoderIcon } from '../../../assets/sensors/decoder.svg'
import Measurement from './measurement'
import { checkPrivileges, errorHandler } from '../../../Helpers/index'
import DecoderSensors from './decoderSensors'
import Table from '../../../Components/common/TableMultiCheckbox'
import instance from '../../../axios'

const Sensors = (props) => {
  const [state, setState] = useState({
    isEnableBtn: true,
    selecteItem: '',
    scale: {},
    modal: '',
    isVisableMeasurementBtn: false,
    isVisableDiscreteBtn: false,
    isVisableOthersBtn: false,
    isVisableAggregateBtn: false,
    isVisableDecoderBtn: false,
    form: {
      attribute: '',
      attributes: {
        minThreshold: 0,
        computationDuration: 0,
        computationType: 0,
        copyFromLast: false,
        applyInverse: false,
        decodeToDecimal: false,
        resetDriver: 'disabled',
        resetPassenger: "disabled",
        resetTrailer: "disabled",
        resetBin: "disabled",
        allowMinMax: true,
        v2Report: false
      }
    },
    operation: '',
    isSensorError: false,
    addButton: 'sharedSave',
    defaultData: '',
    showDialg: false,
    copySensor: {},
    uniqueModels: [],
    selectedModel: {},
    displayList: [],
    listData: false
  })

  const { positions, computedAttributesDeviceWise } = useSelector((state) => state)
  const dispatch = useDispatch()



  useEffect(() => {
    // Assuming props?.allDevices is your array of devices
    const allDevices = props?.allDevices || [];

    // Create an array to store unique models
    const uniqueModels = [];

    // Create a Set to keep track of seen models
    const seenModels = new Set();

    // Iterate through each device object
    allDevices.forEach(device => {
      const model = device.model;

      // Check if the model is not in the seenModels Set
      if (!seenModels.has(model)) {
        // Add the model to the uniqueModels array
        uniqueModels.push({ model });

        // Add the model to the seenModels Set to mark it as seen
        seenModels.add(model);
      }
    });

    // Now, uniqueModels contains an array of unique models
    const options = uniqueModels.map(modelObj => ({
      value: modelObj.model,
      label: modelObj.model,
    }));

    setState((prevState) => ({
      ...prevState,
      uniqueModels: options
    }))
  }, [props?.allDevices])

  useEffect(() => {
    if (positions.length && parseInt(props.selectedDeviceId) !== parseInt(state.selecteItem)) {
      setState((prevState) => ({
        ...prevState,
        selecteItem: props.selectedDeviceId
      }))
    }
  }, [positions, props.selectedDeviceId, state.selecteItem])

  const openDialog = type => {
    if (type === 'measurement') {
      setState((prevState) => ({
        ...prevState,
        modal: type,
        addButton: 'sharedSave',
        operation: '',
        isVisableMeasurementBtn: false,
        form: {
          attributes: {
            input: '',
            copyFromLast: false,
            resetDriver: 'disabled',
            resetBin: "disabled",
            resetPassenger: "disabled",
            resetTrailer: "disabled"
          }
        }

      }))
    } else if (type === 'others') {
      setState((prevState) => ({
        ...prevState,
        modal: type,
        addButton: 'sharedSave',
        operation: '',
        isVisableOthersBtn: false,
        copyFromLast: false,
        resetDriver: 'disabled',
        resetBin: "disabled",
        resetPassenger: "disabled",
        resetTrailer: "disabled",
        form: {
          attributes: {
            input: '',
            copyFromLast: false,
            decodeToDecimal: false,
            resetDriver: 'disabled',
            resetBin: "disabled",
            resetPassenger: "disabled",
            resetTrailer: "disabled"
          }
        }

      }))

    } else if (type === 'aggregate') {
      setState((prevState) => ({
        ...prevState,
        modal: type,
        addButton: 'sharedSave',
        operation: '',
        isVisableAggregateBtn: false,
        form: {
          attribute: '',
          copyFromLast: false,
          resetDriver: 'disabled',
          resetBin: "disabled",
          resetPassenger: "disabled",
          resetTrailer: "disabled",
          attributes: {
            minThreshold: 0,
            copyFromLast: false,
            decodeToDecimal: false,
            resetDriver: 'disabled',
            resetBin: "disabled",
            resetPassenger: "disabled",
            resetTrailer: "disabled"
          }
        }

      }))
    } else if (type === 'decoder') {
      setState((prevState) => ({
        ...prevState,
        modal: type,
        addButton: 'sharedSave',
        operation: '',
        isVisableDecoderBtn: false,
        form: {
          attribute: '',
          input: '',
          copyFromLast: false,
          valueFrom: "",
          arithmeticOpp: "",
          arithmeticArgument: "",
          "1stConv": "",
          "1stLittleEndian": true,
          "1stFullValue": true,
          "1stStartBit": "",
          "1stEndBit": "",
          "2ndConv": "",
          "2ndLittleEndian": true,
          "2ndFullValue": true,
          "need2ndConv": false,
          "2ndStartBit": "",
          "2ndEndBit": "",
          attributes: {
            input: '',
            copyFromLast: false,
            valueFrom: "",
            arithmeticOpp: "",
            arithmeticArgument: "",
            "1stConv": "",
            "1stLittleEndian": true,
            "1stFullValue": true,
            "1stStartBit": "",
            "1stEndBit": "",
            "2ndConv": "",
            "2ndLittleEndian": true,
            "2ndFullValue": true,
            "need2ndConv": false,
            "2ndStartBit": "",
            "2ndEndBit": ""
          }
        }

      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        modal: type,
        addButton: 'sharedSave',
        operation: '',
        isVisableDiscreteBtn: false,
        form: {
          copyFromLast: false,
          applyInverse: false,
          resetDriver: 'disabled',
          resetBin: "disabled",
          resetPassenger: "disabled",
          resetTrailer: "disabled",
          attributes: {
            minThreshold: 0,
            copyFromLast: false,
            decodeToDecimal: false,
            resetDriver: 'disabled',
            resetBin: "disabled",
            resetPassenger: "disabled",
            resetTrailer: "disabled"
          }
        }

      }))
    }
  }

  const onClose = () => {
    setState((prevState) => ({
      ...prevState,
      modal: ''
    }))
  }

  const handleChange = (name, event) => {

    if (name === 'IOtype' || name === 'key') {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          attributes: {
            ...prevState.form.attributes,
            [name]: event.target.value
          }
        }
      }))
    }
    else if (name === 'computationType' || name === 'computationDuration') {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          attributes: {
            ...prevState.form.attributes,
            [name]: parseInt(event.target.value)
          }
        }
      }))

    }
    else if (name === 'sensorType' && state.modal === 'measurement') {
      if (state.form[name] !== event.target.value) {
        let unit = ''
        if (event.target.value === 'fuel') {
          unit = 'L'
        } else if (event.target.value === 'temperature') {
          unit = '℃'
        } else if (event.target.value === 'rpm') {
          unit = 'rpm'
        } else if (
          event.target.value === 'averagFuelConsumption' ||
          event.target.value === 'instantFuelConsumption' ||
          event.target.value === 'mileage'
        ) {
          unit = 'L/100km'
        } else if (
          event.target.value === 'boardPower' ||
          event.target.value === 'humidity' ||
          event.target.value === 'counter'
        ) {
          unit = 'custom'
        } else if (event.target.value === 'speed') {
          unit = 'km/h'
        } else if (event.target.value === 'flowMeter') {
          unit = 'L'
        } else if (event.target.value === 'custom') {
          unit = 'custom'
        }
        let filterdataForm = state.form
        delete filterdataForm['accuracy']
        delete filterdataForm['customUnit']
        delete filterdataForm['drainByTime']
        delete filterdataForm['drainByDistance']
        let filterdata = state.form.attributes
        delete filterdata['accuracy']
        delete filterdata['customUnit']
        delete filterdata['drainByTime']
        delete filterdata['drainByDistance']
        setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [name]: event.target && event.target.value,
            attributes: {
              ...prevState.form.attributes,
              unit: unit,
              [name]: event.target && event.target.value
            },
            unit
          }
        }))

      }
    } else if (name === 'attribute' && state.modal === 'decoder') {
      const urlRegex = "^[a-zA-Z0-9]*$";
      if (event.target.value.match(urlRegex)) {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [name]: event.target && event.target.value,
            attributes: {
              ...prevState.form.attributes,
            },
          }
        }))
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: event.target ? event.target.value : event,
          attributes: {
            ...prevState.form.attributes,
          },
        }
      }))
    }
  }

  const checkVisablityOfSensor = (value, scale) => {
    if (scale) {
      setState((prevState) => ({
        ...prevState,
        isSensorError: value,
        scale
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        isSensorError: value,
      }))

    }
  }
  useEffect(() => {
    checkVisablity()
  }, [state.form, state.modal, state.isSensorError, state.scale, state.defaultData, state.operation])

  const checkVisablity = () => {
    const {
      modal,
      isSensorError,
      form,
      scale,
      defaultData,
      operation
    } = state
    let {
      attribute,
      description,
      input,
      unit,
      type,
      customUnit,
      attributes,
      category,
      check,
      expression,
      id,
      sensorType,
      valueFrom,
      need2ndConv,
      '1stFullValue': firstFullValue,
      '2ndFullValue': secondFullValue,
      '1stConv': firstConv,
      '2ndConv': secondConv,
      '1stEndBit': firstEndBit,
      '2ndEndBit': secondEndBit,
      '1stStartBit': firstStartBit,
      '2ndStartBit': secondStartBit,
    } = form
    if (modal === 'discrete') {
      if (
        attribute &&
        attribute.trim().length > 0 &&
        description &&
        description.trim().length > 0 &&
        input &&
        input.trim().length > 0
      ) {
        if (operation === 'edit') {
          let obj = {
            attribute,
            attributes,
            category,
            check,
            description,
            expression,
            id
          }
          let changed = !isEqual(
            obj,
            defaultData || obj.attributes,
            defaultData.attributes
          )
          setState((prevState) => ({
            ...prevState,
            isVisableDiscreteBtn: changed
          }))
        } else {
          setState((prevState) => ({
            ...prevState,
            isVisableDiscreteBtn: true
          }))
        }
      }
      else if (
        (attribute === "din" || attribute === "dout") &&
        description &&
        description.trim().length > 0
      ) {
        setState((prevState) => ({
          ...prevState,
          isVisableDiscreteBtn: true
        }))
      }
      else {
        setState((prevState) => ({
          ...prevState,
          isVisableDiscreteBtn: false
        }))
      }
    } else if (modal === 'others') {
      if (
        attribute &&
        attribute.trim().length > 0 &&
        description &&
        description.trim().length > 0
      ) {
        if (operation === 'edit') {
          let obj = {
            attribute,
            attributes,
            type,
            category,
            check,
            description,
            expression,
            id
          }
          let changed = !isEqual(obj, defaultData)
          setState((prevState) => ({
            ...prevState,
            isVisableOthersBtn: changed
          }))
        } else {
          setState((prevState) => ({
            ...prevState,
            isVisableOthersBtn: true
          }))
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          isVisableOthersBtn: false
        }))
      }
    } else if (modal === 'aggregate') {
      if (
        attribute &&
        attribute.trim().length > 0 &&
        description &&
        description.trim().length > 0
      ) {
        if (operation === 'edit') {
          let obj = {
            attribute,
            attributes,
            type,
            category,
            check,
            description,
            expression,
            id
          }
          let changed = !isEqual(obj, defaultData)
          setState((prevState) => ({
            ...prevState,
            isVisableAggregateBtn: changed
          }))
        } else {
          setState((prevState) => ({
            ...prevState,
            isVisableAggregateBtn: true
          }))
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          isVisableAggregateBtn: false
        }))
      }
    }
    else if (modal === 'decoder') {
      setState((prevState) => ({
        ...prevState,
        isVisableDecoderBtn: false
      }))
      if (
        ("" + description).trim().length > 0 &&
        valueFrom
      ) {
        if (description && input && valueFrom === 'decimal') {
          if (firstConv === 'decimal') {
            if (!need2ndConv) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && secondConv === 'decimal') {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && (secondConv === 'binary' || secondConv === 'hexDecimal')) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            }
          } else if (firstConv === 'binary' || firstConv === 'hexDecimal' || firstConv === 'ascii') {
            if (!need2ndConv) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && secondFullValue && secondConv === 'decimal') {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && secondFullValue && (secondConv === 'binary' || secondConv === 'hexDecimal')) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && !secondFullValue && secondConv === 'decimal' && secondStartBit && secondEndBit) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && !secondFullValue && (secondConv === 'binary' || secondConv === 'hexDecimal') && secondStartBit && secondEndBit) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            }
          }
        }

        else if (description && input && (valueFrom === 'binary' || valueFrom === 'hexDecimal')) {
          if (firstFullValue && firstConv === 'decimal') {
            if (!need2ndConv) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && secondConv === 'decimal') {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && (secondConv === 'binary' || secondConv === 'hexDecimal')) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            }
          } else if (firstFullValue && (firstConv === 'binary' || firstConv === 'hexDecimal' || firstConv === 'ascii')) {
            if (!need2ndConv) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && secondConv === 'decimal') {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && (secondConv === 'binary' || secondConv === 'hexDecimal' || secondConv === 'ascii')) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            }
          } else if (!firstFullValue && firstStartBit && firstEndBit && firstConv === 'decimal') {
            if (!need2ndConv) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && secondConv === 'decimal') {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && (secondConv === 'binary' || secondConv === 'hexDecimal')) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            }
          } else if (!firstFullValue && firstStartBit && firstEndBit && (firstConv === 'binary' || firstConv === 'hexDecimal' || firstConv === 'ascii')) {
            if (!need2ndConv) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && secondFullValue && secondConv === 'decimal') {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && secondFullValue && (secondConv === 'binary' || secondConv === 'hexDecimal')) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && !secondFullValue && secondConv === 'decimal' && secondStartBit && secondEndBit) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            } else if (need2ndConv && !secondFullValue && (secondConv === 'binary' || secondConv === 'hexDecimal') && secondStartBit && secondEndBit) {
              setState((prevState) => ({
                ...prevState,
                isVisableDecoderBtn: true
              }))
            }
          }
        }
      }
    }
    if (modal === 'measurement') {
      if (
        sensorType &&
        sensorType.trim() &&
        description &&
        description.trim() &&
        unit &&
        unit.trim() &&
        input &&
        input.trim() &&
        !isSensorError
      ) {
        let unitError = unit
          ? unit === 'custom' &&
          (!customUnit || (customUnit && !customUnit.trim().length))
          : true
        if (unit !== 'custom') {
          delete attributes.customUnit
        } else {
          attributes.customUnit = customUnit
        }
        if (attributes && !attributes.sensorType) {
          attributes.sensorType = attribute
        }
        let formObj = {
          attribute,
          type,
          attributes,
          category,
          description,
          expression,
          id,
          scale
        }
        setState((prevState) => ({
          ...prevState,
          isVisableMeasurementBtn: !isEqual(formObj, defaultData) && !unitError
        }))

      } else {
        setState((prevState) => ({
          ...prevState,
          isVisableMeasurementBtn: false
        }))
      }
    }
  }

  const changeAttribute = (name, value) => {
    if (
      name === 'multiplier' ||
      name === 'minThreshold' ||
      name === 'maxThreshold' ||
      name === 'index' ||
      name === 'accuracy'
    ) {
      value = parseFloat(value)
    }

    if (state.modal === 'measurement') {
      if (name === 'allowMinMax') {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            attributes: {
              ...prevState.form.attributes,
              allowMinMax: !value
            },
            deviceId: props.deviceId
          }
        }))

      } else if (name === 'v2Report') {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            attributes: {
              ...prevState.form.attributes,
              v2Report: value
            },
            deviceId: props.deviceId
          }
        }))

      }
      else {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
            attributes: {
              ...prevState.form.attributes,
              [name]: value
            },
            deviceId: props.deviceId
          }
        }))

      }
    } else {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: value,
          attributes: {
            ...prevState.form.attributes,
            [name]: value
          },
          deviceId: props.deviceId
        }
      }))

    }
  }

  const save = () => {
    const { modal, scale } = state

    if (modal === 'measurement') {
      const { description, id, attributes, attribute } = state.form
      let scaleObj = {}
      if (scale && scale.length) {
        scale.map(item => {
          let key = item.x,
            value = item.y
          scaleObj = { ...scaleObj, [key]: value }
          return null
        })
      }
      if (Object.keys(scaleObj).length) {
        attributes.scale = scaleObj
      } else {
        attributes.scale = { '0': 0 }
      }
      let form = {
        attribute:
          attributes && attributes.sensorType
            ? attributes.sensorType + '' + (attributes.index || '')
            : attribute,
        description,
        attributes,
        type: 'number',
        expression: '',
        category: 2,
        id: id || 0
      }
      if (!attributes.allowMinMax) {
        delete form.attributes.maxThreshold
        delete form.attributes.minThreshold
      }
      if (id) {
        form.id = id
        // method put
        updateComputedAttributes(props.deviceId, id, form)
      } else {
        addComputedAttributes(props.deviceId, form)
      }
    } else if (modal === 'others') {
      const {
        attribute,
        description,
        id,
        attributes
      } = state.form
      let form = {
        attribute,
        description,
        attributes,
        expression: '',
        type: 'string',
        category: 3,
        id: id || 0
      }
      if (id) {
        form.id = id
        // method put
        updateComputedAttributes(props.deviceId, id, form)
      } else {
        addComputedAttributes(props.deviceId, form)
      }
    } else if (modal === 'aggregate') {
      const {
        attribute,
        description,
        id,
        attributes,
        type,
        expStart,
        expOpt,
        expEnd
      } = state.form
      let form = {
        attribute,
        description,
        attributes,
        expression: " " + (expStart || "") + " " + (expOpt || "") + " " + (expEnd || ""),
        type: type || 'string',
        category: 4,
        id: id || 0
      }
      if (id) {
        form.id = id
        // method put
        updateComputedAttributes(props.deviceId, id, form)
      } else {
        addComputedAttributes(props.deviceId, form)
      }
    } else if (modal === 'decoder') {
      const {
        attribute,
        description,
        id,
        attributes: {
          '1stEndBit': firstEndBit,
          '2ndEndBit': secondEndBit,
          '1stStartBit': firstStartBit,
          '2ndStartBit': secondStartBit,
          arithmeticArgument,
          ...attributes
        },
        type,
      } = state.form
      let form = {
        attribute,
        description,
        attributes: {
          ...attributes,
          '1stStartBit': Number(firstStartBit),
          '1stEndBit': Number(firstEndBit),
          '2ndStartBit': Number(secondStartBit),
          '2ndEndBit': Number(secondEndBit),
          arithmeticArgument: Number(arithmeticArgument)
        },
        expression: "",//+(expStart || "") + " "+(expOpt || "") + " "+(expEnd || ""),
        type: type || 'string',
        category: -1,
        id: id || 0
      }
      if (id) {
        form.id = id
        // method put
        updateComputedAttributes(props.deviceId, id, form)
      } else {
        addComputedAttributes(props.deviceId, form)
      }
    } else {
      const { attribute, description, id, attributes } = state.form

      let form = {
        attribute: attribute === 'customDiscrete'
          || attribute === 'din'
          || attribute === 'dout'
          ? attributes.sensorType || attribute + '' + (attributes && attributes.index || '') : attribute,
        description,
        attributes,
        type: 'boolean',
        expression: '',
        category: 1,
        id: id || 0
      }
      if (id) {
        form.id = id
        // method put
        updateComputedAttributes(props.deviceId, id, form)
      } else {
        addComputedAttributes(props.deviceId, form)
      }
    }
  }

  const processAttributes = (form, attributeName, resetAttributeName) => {
    if (form.attribute !== attributeName) {
      delete form.attributes[resetAttributeName];
    }
    if (form.attributes && form.attributes[resetAttributeName] === 'disabled') {
      delete form.attributes[resetAttributeName];
    }
  }

  const updateComputedAttributes = (deviceId, id, form) => {
    const attributesToProcess = [
      { attribute: 'driverUniqueId', resetAttribute: 'resetDriver' },
      { attribute: 'passengerUniqueId', resetAttribute: 'resetPassenger' },
      { attribute: 'binUniqueId', resetAttribute: 'resetBin' },
      { attribute: 'trailerUniqueId', resetAttribute: 'resetTrailer' },
    ];
    attributesToProcess.forEach(attr => processAttributes(form, attr.attribute, attr.resetAttribute));

    setIsLoading(true)
    instance({
      url: `/api/sensors/${id}/${deviceId}`,
      method: 'PUT',
      data: {
        ...form
      }
    })
      .then(Attribute => {
        dispatch(updateComputedAttributeDeviceWise(Attribute))
        dispatch(
          Notifications.success({
            message: props.translate('sensorIsUpdated'),
            autoDismiss: 10
          })
        )
        setState((prevState) => ({
          ...prevState,
          isVisableMeasurementBtn: false,
          isVisableDiscreteBtn: false,
          isVisableOthersBtn: false,
          isVisableAggregateBtn: false,
          isVisableDecoderBtn: false,
          modal: ''
        }))
 setIsLoading(false)
      })
      .catch(error => { errorHandler(error, dispatch, errorCallBack) })
  }

  const errorCallBack = () => {
    setState((prevState) => ({
      ...prevState,
      isVisableMeasurementBtn: false,
      isVisableDiscreteBtn: false,
      isVisableOthersBtn: false,
      isVisableAggregateBtn: false,
      isVisableDecoderBtn: false,
      modal: ''
    }))
 setIsLoading(false)

  }
const [isLoading,setIsLoading]=useState(false)
  const addComputedAttributes = (deviceId, formData) => {
    let form = formData;
    const attributesToProcess = [
      { attribute: 'driverUniqueId', resetAttribute: 'resetDriver' },
      { attribute: 'passengerUniqueId', resetAttribute: 'resetPassenger' },
      { attribute: 'binUniqueId', resetAttribute: 'resetBin' },
      { attribute: 'trailerUniqueId', resetAttribute: 'resetTrailer' },
    ];
    attributesToProcess.forEach(attr => processAttributes(form, attr.attribute, attr.resetAttribute));
    if (form.attributes.valueFrom === 'decimal') { form.attributes['1stLittleEndian'] = false }
    if (form.attributes['1stConv'] === 'decimal') { form.attributes['2ndLittleEndian'] = false }
setIsLoading(true)
    instance({
      url: `/api/sensors/${deviceId}`,
      method: 'POST',
      data: {
        ...form
      }
    })
      .then(Attribute => {
        //props.dispatch(addComputedAttributeDeviceWiseSelectedList(Attribute));
        // props.dispatch(addComputedAttribute(Attribute));
        dispatch(
          Notifications.success({
            message: props.translate('sensorAdded'),
            autoDismiss: 10
          })
        )
        dispatch(updateComputedAttributeDeviceWise(Attribute))
        setState((prevState) => ({
          ...prevState,
          isVisableMeasurementBtn: false,
          isVisableOthersBtn: false,
          isVisableAggregateBtn: false,
          isVisableDecoderBtn: false,
          isVisableDiscreteBtn:false,
          modal: ''
        }))
setIsLoading(false)
 
      }).catch(error => { errorHandler(error, dispatch, errorCallBack) })
  }

  const onEdit = (item, mood) => {
    let scales = []
    let att = item.attributes
    if (mood === 'measurement') {
      for (let key1 in att.scale) {
        let a = {
          x: '',
          y: '',
          e: false
        }
        a.x = parseFloat(key1)
        a.y = att.scale[key1]
        scales.push(a)
      }
      item['scale'] = JSON.parse(JSON.stringify(scales))
      if (item.scale.length === 0) {
        item.scale.push({ x: 0, y: 0, e: false })
        scales.push({ x: 0, y: 0, e: false })
      }
      if (!att.sensorType) {
        att.sensorType = item.attribute
      }
    }

    const ex = item.expression.trim().split(' ');
    const exp = {};
    if (ex && ex.length && ex.length === 3) {
      exp.expStart = ex[0]
      exp.expOpt = ex[1]
      exp.expEnd = ex[2]
    }
    setState((prevState) => ({
      ...prevState,
      form: {
        ...item,
        ...exp,
        ...att
      },
      modal: mood,
      operation: 'edit',
      addButton: 'update',
      defaultData: item,
      scale: scales,
      isVisableDiscreteBtn: false,
      isVisableMeasurementBtn: false,
      isVisableAggregateBtn: false,
      isVisableDecoderBtn: false,
      isVisableOthersBtn: false

    }))
  }

  const onRemove = item => {
    setState((prevState) => ({
      ...prevState,
      computedAttForm: item,
      onDeleteConfirmationAttributes: true
    }))
  }
  const removeComputedAttributes = () => {
    instance({
      url: `/api/attributes/computed/${state.computedAttForm.id}`,
      method: 'DELETE'
    })
      .then(response => { // eslint-disable-line no-unused-vars
        dispatch(
          RemoveComputedAttributeDeviceWise(state.computedAttForm)
        )
        dispatch(
          Notifications.success({
            message: props.translate('attributeIsDelete'),
            autoDismiss: 10
          })
        )
        setState((prevState) => ({
          ...prevState,
          onDeleteConfirmationAttributes: false
        }))
      }).catch(error => { errorHandler(error, dispatch) })
  }

  const drawSensors = () => {
    let obj = {}
    computedAttributesDeviceWise.map((item) => {
      obj[item.category] = obj[item.category] || []
      obj[item.category].push(item)
      return null
    })

    let html = []
    Object.keys(obj).map(key => {
      if (parseInt(key) === 1) {
        html.push(
          <tr key={key + '_parent'} className='sensor_type_head'>
            <th colSpan={4} className='c_table_title'>
              {props.translate('discrete')}
            </th>
          </tr>
        )
        obj[key].map((i, k) => {
          html.push(
            <tr key={k + 'dis'}>
              <td style={{ width: '12%' }}>
                {/* {props.translate('sensorsTranslation.' + i.attribute)} */}
                {props.translate('sensorsTranslation.' + i.attribute)}
              </td>
              <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
              <td>{ReactHtmlParser(i.description)}</td>
              <td width='90' align='right'>
                {checkPrivileges('sensorCreate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('copySensor')}
                  >
                    <FileCopyIcon
                      fontSize='small'
                      onClick={() => props.onCopy(i, 'copySensor')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorUpdate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('edit')}
                  >
                    <EditIcon
                      fontSize='small'
                      onClick={() => onEdit(i, 'discrete')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorDelete') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('delete')}
                  >
                    <DeleteIcon
                      fontSize='small'
                      onClick={() => onRemove(i)}
                    />
                  </Tooltip>
                )}
              </td>
            </tr>
          )

          return null
        })
      } else if (parseInt(key) === 2) {
        html.push(
          <tr key={key + '_parentmes'} className='sensor_type_head'>
            <th colSpan={4} className='c_table_title'>
              {props.translate('measurement')}
            </th>
          </tr>
        )
        obj[key].map((i) => {
          html.push(
            <tr key={i.id + 'mes'}>
              <td style={{ width: '12%' }}>
                {i.attributes && i.attributes.sensorType
                  ? props.translate(
                    'sensorsTranslation.' + i.attributes.sensorType
                  )
                  : props.translate('sensorsTranslation.' + i.attribute)}
              </td>
              <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
              <td>{ReactHtmlParser(i.description)}</td>
              <td width='90' align='right'>
                {checkPrivileges('sensorCreate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('copySensor')}
                  >
                    <FileCopyIcon
                      fontSize='small'
                      onClick={() => props.onCopy(i, 'copySensor')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorUpdate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('edit')}
                  >
                    <EditIcon
                      fontSize='small'
                      onClick={() => onEdit(i, 'measurement')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorDelete') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('delete')}
                  >
                    <DeleteIcon
                      fontSize='small'
                      onClick={() => onRemove(i)}
                    />
                  </Tooltip>
                )}
              </td>
            </tr>
          )

          return null
        })
      } else if (parseInt(key) === 3) {
        html.push(
          <tr key={key + '_parent'} className='sensor_type_head'>
            <th colSpan={4} className='c_table_title'>
              {props.translate('otherSensors')}
            </th>
          </tr>
        )
        obj[key].map((i, k) => {
          html.push(
            <tr key={k + 'mes'}>
              <td style={{ width: '12%' }}>
                {props.translate('sensorsTranslation.' + i.attribute)}
              </td>
              <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
              <td>{ReactHtmlParser(i.description)}</td>
              <td width='90' align='right'>
                {checkPrivileges('sensorCreate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('copySensor')}
                  >
                    <FileCopyIcon
                      fontSize='small'
                      onClick={() => props.onCopy(i, 'copySensor')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorUpdate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('edit')}
                  >
                    <EditIcon
                      fontSize='small'
                      onClick={() => onEdit(i, 'others')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorDelete') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('delete')}
                  >
                    <DeleteIcon
                      fontSize='small'
                      onClick={() => onRemove(i)}
                    />
                  </Tooltip>
                )}
              </td>
            </tr>
          )

          return null
        })
      } else if (parseInt(key) === 4) {
        html.push(
          <tr key={key + '_parent'} className='sensor_type_head'>
            <th colSpan={4} className='c_table_title'>
              {props.translate('aggregateSensors')}
            </th>
          </tr>
        )
        obj[key].map((i, k) => {
          html.push(
            <tr key={k + 'mes'}>
              <td style={{ width: '12%' }}>
                {props.translate(i.attribute)}
              </td>
              <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
              <td>{ReactHtmlParser(i.description)}</td>
              <td width='90' align='right'>
                {checkPrivileges('sensorCreate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('copySensor')}
                  >
                    <FileCopyIcon
                      fontSize='small'
                      onClick={() => props.onCopy(i, 'copySensor')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorUpdate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('edit')}
                  >
                    <EditIcon
                      fontSize='small'
                      onClick={() => onEdit(i, 'aggregate')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorDelete') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('delete')}
                  >
                    <DeleteIcon
                      fontSize='small'
                      onClick={() => onRemove(i)}
                    />
                  </Tooltip>
                )}
              </td>
            </tr>
          )

          return null
        })
      } else if (parseInt(key) === -1) {
        html.push(
          <tr key={key + '_parent'} className='sensor_type_head'>
            <th colSpan={4} className='c_table_title'>
              {props.translate('decoderSensors')}
            </th>
          </tr>
        )
        obj[key].map((i, k) => {
          html.push(
            <tr key={k + 'mes'}>
              <td style={{ width: '12%' }}>
                {props.translate(i.attribute)}
              </td>
              <td style={{ width: '12%' }}>{i.attributes.input || ''}</td>
              <td>{ReactHtmlParser(i.description)}</td>
              <td width='90' align='right'>
                {checkPrivileges('sensorCreate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('copySensor')}
                  >
                    <FileCopyIcon
                      fontSize='small'
                      onClick={() => props.onCopy(i, 'copySensor')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorUpdate') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('edit')}
                  >
                    <EditIcon
                      fontSize='small'
                      onClick={() => onEdit(i, 'decoder')}
                    />
                  </Tooltip>
                )}
                {checkPrivileges('sensorDelete') && (
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={props.translate('delete')}
                  >
                    <DeleteIcon
                      fontSize='small'
                      onClick={() => onRemove(i)}
                    />
                  </Tooltip>
                )}
              </td>
            </tr>
          )

          return null
        })
      }
      return null
    })

    return (
      <table className='custom-table-1'>
        <tbody>{html}</tbody>
      </table>
    )
  }

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmationAttributes: false
    }))
  }

  const handleModelSelect = (name, event) => {
    // Update the selectedModel in state when a model is selected
    const val = event.target.value;
    setState((prevState) => ({
      ...prevState,
      listData: val ? true : false,
      selectedModel: val
    }))
    if (val) {
      getModelBasedDevices(event.target.value)
    }
  };

  const getModelBasedDevices = (id) => {
    instance({
      url: `/api/devices/list?userId=${props?.logInUser?.id}&all=true`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        limit: -1,
        model: id
      }
    })
      .then(res => {
        setState((prevState) => ({
          ...prevState,
          displayList: res.data
        }))
      }).catch(error => {
        console.error(error)
        // errorHandler(error, props.dispatch)
      })
  }

  const selectAllFilteredUnits = (e) => {
    let allData = state.displayList
    let sensorDeviceids = [];

    allData.map(dt => {
      dt.check = e ? true : false;
      // Push the ID into the sensorDeviceids array
      if (e) {
        sensorDeviceids.push(dt.id);
      }
    });
    if (e) {
      setState((prevState) => ({
        ...prevState,
        allDevices: allData
      }))
    }
    else {
      // sensorDeviceids=[]
      // props.getIdsfromChild(sensorDeviceids)
      setState((prevState) => ({
        ...prevState,
        allDevices: allData
      }))
    }

    props.getIdsfromChild(sensorDeviceids)
  }

  let { suggestions } = props
  let sug = []
  if (computedAttributesDeviceWise.length) {
    suggestions.map(item => {
      let prsntvalue = false
      computedAttributesDeviceWise.map(item1 => {
        if (item === item1.attributes.input) {
          prsntvalue = true
        }
        return null
      })
      if (!prsntvalue) {
        sug.push(item)
      }
      return null
    })
  } else {
    sug = suggestions
  }

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{ textAlign: 'right', padding: '16px' }}
        className='clearfix'
      >
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('sensors')}
        </h4>
      </div>
      <div
        style={{ width: '100%', boxSizing: 'border-box', padding: '0 16px' }}
      >
        {computedAttributesDeviceWise.length > 0 && (
          <Fragment>{drawSensors()}</Fragment>
        )}
        {state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeComputedAttributes}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              state.computedAttForm.description
            )}
          />
        )}
        {state.modal === 'discrete' && (
          <CustomDialog
            draggable
            onClose={onClose}
            title={props.translate('discrete')}
            cancelText={props.translate('sharedCancel')}
            headerActions={
              <Button
                disabled={!state.isVisableDiscreteBtn||isLoading}
                onClick={save}
              >
                {isLoading?"loading": props.translate(state.addButton)}
              </Button>
            }
            isVisableBtn={props.isVisablBtn}
            isVisablBtn={props.isVisablBtn}
            bodyPadding={20}
            maxWidth={'xs'}
          >
            <Ignition
              {...props}
              suggestions={sug}
              handleChange={handleChange}
              changeAttribute={changeAttribute}
              {...state}
            />
          </CustomDialog>
        )}
        {state.modal === 'measurement' && (
          <CustomDialog
            onClose={onClose}
            title={props.translate('measurement')}
            cancelText={props.translate('sharedCancel')}
            headerActions={
              <Button
                disabled={!state.isVisableMeasurementBtn||isLoading}
                onClick={save}
              >
                {isLoading?"loading": props.translate(state.addButton)}
              </Button>
            }
            isVisableBtn={props.isVisablBtn}
            isVisablBtn={props.isVisablBtn}
            bodyPadding={20}
            draggable={false}
            fullWidth
            maxWidth={'md'}
          >
            <Measurement
              {...props}
              suggestions={sug}
              handleChange={handleChange}
              changeAttribute={changeAttribute}
              checkVisablity={checkVisablityOfSensor}
              {...state}
            />
          </CustomDialog>
        )}
        {state.modal === 'others' && (
          <CustomDialog
            onClose={onClose}
            title={props.translate('otherSensors')}
            cancelText={props.translate('sharedCancel')}
            headerActions={
              <Button
                disabled={!state.isVisableOthersBtn||isLoading}
                onClick={save}
              >
                {isLoading?"loading": props.translate(state.addButton)}
              </Button>
            }
            isVisableBtn={props.isVisablBtn}
            isVisablBtn={props.isVisablBtn}
            bodyPadding={20}
            draggable={false}
            fullWidth
            maxWidth={'xs'}
          >
            <OtherSensors
              {...props}
              suggestions={sug}
              handleChange={handleChange}
              changeAttribute={changeAttribute}
              {...state}
            />
          </CustomDialog>
        )}
        {state.modal === 'aggregate' && (
          <CustomDialog
            onClose={onClose}
            title={props.translate('aggregateSensors')}
            cancelText={props.translate('sharedCancel')}
            headerActions={
              <Button
                disabled={!state.isVisableAggregateBtn||isLoading}
                onClick={save}
              >
                {isLoading?"loading": props.translate(state.addButton)}
              </Button>
            }
            isVisableBtn={props.isVisablBtn}
            isVisablBtn={props.isVisablBtn}
            bodyPadding={20}
            draggable={false}
            fullWidth
            maxWidth={'xs'}
          >
            <AggregateSensors
              {...props}
              suggestions={sug}
              handleChange={handleChange}
              changeAttribute={changeAttribute}
              {...state}
            />
          </CustomDialog>
        )}
        {state.modal === 'decoder' && (
          <CustomDialog
            onClose={onClose}
            title={props.translate('decoderSensors')}
            cancelText={props.translate('sharedCancel')}
            headerActions={
              <Button
                disabled={!state.isVisableDecoderBtn||isLoading}
                onClick={save}
              >
                {isLoading?"loading":props.translate(state.addButton)}
              </Button>
            }
            isVisableBtn={props.isVisablBtn}
            isVisablBtn={props.isVisablBtn}
            bodyPadding={20}
            draggable={false}
            fullWidth
            maxWidth={'sm'}
          >
            <DecoderSensors
              {...props}
              suggestions={sug}
              handleChange={handleChange}
              changeAttribute={changeAttribute}
              {...state}
            />
          </CustomDialog>
        )}

        {props.showDialg && (
          <CustomDialog
            title='Devices'
            themecolors={props.themecolors}
            visable={true}
            onClose={props.closeDialog}
            bodyPadding={10}
            isVisableBtn
            noFullScreen
            headerActions={
              <Button
                disabled={!(props.sensorDeviceids && props.sensorDeviceids.length)}
                onClick={props.submitCopySenors}
              >
                {props.translate('copySensor')}
              </Button>
            }
          >
            <div>
              <Table
                showDropDown
                selectedModel={state.selectedModel}
                options={state.uniqueModels}
                onSelect={handleModelSelect}
                insideDialog
                disableHead
                showCheckbox={true}
                showGroupAccess={props.showGroupAccess}
                checkHandleChange={props.Devices_Selection || ''}
                rows={state.listData ? state.displayList : props?.allDevices || []}
                componentData={state.listData ? state.displayList : props?.allDevices || []}
                isEditable
                canRemove={true}
                canAssign={true}
                className='custom-table2'
                rowsPerPage={props.rowsPerPage}
                handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                selectAll={state.listData ? selectAllFilteredUnits : props.selectAllCopySensors}
                copySensor
                searchable
                rowDefinition={[
                  {
                    id: 'label',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('sharedName')
                  },
                  {
                    id: 'category',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('category')
                  },
                  {
                    id: 'model',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('model')
                  }
                ]}
              />
            </div>
          </CustomDialog>
        )}

        {props.openResponseDialg && (
          <CustomDialog
            title='Devices'
            themecolors={props.themecolors}
            visable={true}
            onClose={props.closeDialog}
            bodyPadding={10}
            isVisableBtn
            noFullScreen
          >
            <div>
              <Table
                insideDialog
                disableHead
                // showCheckbox= {true}
                showGroupAccess={props.showGroupAccess}
                rows={props?.copiedData || []}
                componentData={props?.copiedData || []}
                isEditable
                // canRemove={true}
                // canAssign={true}
                className='custom-table2'
                rowsPerPage={props.rowsPerPage}
                handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                // selectAll={props.selectAll}
                rowDefinition={[
                  {
                    id: 'label',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('sharedName')
                  },
                  {
                    id: 'message',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('message')
                  }
                ]}
              />
            </div>
          </CustomDialog>
        )}
        {checkPrivileges('sensorCreate') && (
          <div className='sensors-buttons' style={{ display: "flex" }}>
            <Button
              disabled={!state.isEnableBtn}
              onClick={() => openDialog('discrete')}
              className='button-with-icon'
              style={{ display: "grid" }}
            >
              <DiscreteIcon fill='currentColor' width={60} height={60} />
              {props.translate('addDiscrete')}
            </Button>
            <Button
              disabled={!state.isEnableBtn}
              onClick={() => openDialog('measurement')}
              style={{ marginLeft: 10, display: "grid" }}
              className='button-with-icon'
            >
              <MeasurementIcon fill='currentColor' width={60} height={60} />
              {props.translate('addMeasurement')}
            </Button>
            <Button
              disabled={!state.isEnableBtn}
              onClick={() => openDialog('others')}
              style={{ marginLeft: 10, display: "grid" }}
              className='button-with-icon'
            >
              <OtherSensorIcon fill='currentColor' width={60} height={60} />

              {props.translate('addBinders')}
            </Button>
            <Button
              disabled={!state.isEnableBtn}
              onClick={() => openDialog('aggregate')}
              style={{ marginLeft: 10, display: "grid" }}
              className='button-with-icon'
            >
              <AggregateIcon fill='currentColor' width={60} height={60} />
              {props.translate('aggregateSensors')}
            </Button>
            <Button
              disabled={!state.isEnableBtn}
              onClick={() => openDialog('decoder')}
              style={{ marginLeft: 10, display: "grid" }}
              className='button-with-icon'
            >
              <DecoderIcon fill='currentColor' width={60} height={60} />
              {props.translate('decoderSensors')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
export default Sensors