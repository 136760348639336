import React from 'react';
import './index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faColumns,
  faTh,
  faBell,
  faComments,
  faImage,
  faTerminal,
} from '@fortawesome/free-solid-svg-icons';
//import ToggleLanguage from './../ToggleLanguage'
import { connect } from 'react-redux';


library.add([faColumns, faTh, faBell, faComments, faImage, faTerminal]);

library.add([faColumns, faTh, faBell, faComments, faImage, faTerminal]);

const Footer = (props) => {


  // const [move, setMove] = useState(true)

  /*setInterval( () => {
      setState({
        curTime : new Date().toLocaleTimeString()
      })
    },1000);*/

  // const play = () => {
  //   setMove(true)
  // };
  // const pause = () => {
  //   setMove(false)
  // };

    const { attributes } = props.ServerSetting;
    if (attributes && attributes.serviceTitle) {
      document.title = attributes.serviceTitle;
    } else {
      document.title = 'En Route Technologies';
    }
    // const emptyLink = '#';
    return (
      <footer
        className='fms-footer'
        style={{
          background: props.themecolors.backgroundColor,
          color: props.themecolors.textColor,
        }}
      >
        <table>
          <tbody>
            <tr>
              {/*<td className="toggleSidebar">
        				<a href={emptyLink} title="Unit Activation"><MaterialIcon size="16" icon="add_to_queue" /></a>
        			</td>
        			<td className="toggleMiniMaps">
                <a href={emptyLink} title="Units and Settings"><MaterialIcon size="16" icon="settings" /></a>
              </td>
              <td className="toggleMiniMaps">
        				<a href={emptyLink} title="Help"><MaterialIcon size="16" icon="help" /></a>
        			</td>*/}
              <td>
                &copy;{' '}
                {(attributes && attributes.footerText) || (
                  <span>enroutech.com</span>
                )}
              </td>
              {/* <td style={{ width: '8%' }}>
                <Ticker speed={10} mode='smooth' move={state.move}>
                  {obj => {
                    return (
                      <div
                        className='ticker-inner'
                        onMouseEnter={() => pause()}
                        onMouseLeave={() => play()}
                      >
                        {props.footerNotifications.map(ev => (
                          <div style={{ marginRight: 100 }}>
                            {ev.title} ({ev.name}){' '}
                          </div>
                        ))}
                      </div>
                    )
                  }}
                </Ticker>
              </td> */}
              {/* <td className="languageSection"><ToggleLanguage {...props} /></td> */}
              {/*<td className="activityCounterSection">
        				<Link to={'/notifications'} title="Alerts" ><FontAwesomeIcon icon={faBell} /><span className="badge">0</span></Link>
        				<a href={emptyLink} title="messages"><FontAwesomeIcon icon={faComments} /><span className="badge">0</span></a>
        				<a href={emptyLink}><FontAwesomeIcon icon={faImage} /><span className="badge">0</span></a>
        			</td>
              <td className="toggleConsole">
        				<a href={emptyLink}><FontAwesomeIcon icon={faTerminal} /></a>
        			</td>
        			<td className="dateTimeSection">{state.curTime}</td>*/}
            </tr>
          </tbody>
        </table>
      </footer>
    );
  }

const mapState = (state) => ({
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  footerNotifications: state.footerNotifications,
});

const mapStateToProps = connect(mapState);

export default mapStateToProps(Footer);
