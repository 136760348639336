import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import Layout from './../../Layout'
import { Grid } from '@mui/material'
import Card from './components/Card/Card'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  fetchSummery,
  applyDevicesFilter,
  applyDevicesCatFilter,
  resetFilters,
  resetCatFilters
} from './../../Actions/Devices'
import barChartDefault from './../../Components/common/Charts/barChartDefault'
import { ReactComponent as TrailerIcon } from './../../assets/dashboard/truck.svg'
import { ReactComponent as NotRegistered } from './../../assets/dashboard/not-registered.svg'
import { ReactComponent as GPSNotSupport } from './../../assets/dashboard/gps-no-support.svg'
import { ReactComponent as OnlineStatus } from './../../assets/dashboard/online.svg'
import { ReactComponent as OfflineStatus } from './../../assets/dashboard/offline.svg'
import { ReactComponent as DriverIcon } from './../../assets/dashboard/driver.svg'
import { ReactComponent as AssignedIcon } from './../../assets/dashboard/assigned.svg'
import { ReactComponent as UnAssignedIcon } from './../../assets/dashboard/not-assigned.svg'
import { ReactComponent as MovingIcon } from './../../assets/dashboard/van-moving.svg'
import { ReactComponent as IdlingIcon } from './../../assets/dashboard/idling.svg'
import { ReactComponent as TowingIcon } from './../../assets/dashboard/tow-truck.svg'
import { ReactComponent as ParkingIcon } from './../../assets/dashboard/parking.svg'
import { ReactComponent as GPSNotUpdated } from './../../assets/dashboard/gps-not-updated.svg'
import { ReactComponent as StopIcon } from './../../assets/dashboard/stop.svg'
import { ReactComponent as UnknownIcon } from './../../assets/dashboard/unknown.svg'
import { ReactComponent as PowerCutIcon } from './../../assets/monitoring/powerCut.svg'
import 'highcharts'
import Highcharts from 'highcharts';
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'
import Skeleton from '@mui/lab/Skeleton'
import Button from '../../Components/common/Button'
import DeviceSelector from '../../Components/Devices/DeviceSelector'
import DriverSelector from '../../Components/Devices/DriverSelector'
import { DistanceUnits, fonts } from '../../Helpers'
import Scrollbar from 'react-scrollbars-custom'

const initState = {
  userInfoFront: {},
  userFetch: false,
  devicesFetch: false,
  isFetching: false,
  enginHourReport: false,
  enginHourReportLoading: false,
  usersCount: {},
  devicesCount: {},
  report: {},
  mileage: {},
  pie1: null,
  positionsFetched: false,
  driverFetched: false,
  motionSelector: false,
  motionSelectorKey: '',
  deviceSelector: false,
  deviceSelectorKey: '',
  categorySelector: false,
  categorySelectorKey: '',
  geoSelector: false,
  geoSelectorKey: '',
  driverSelector: false,
  driverIds: [],
  driverSelectorKey: '',
  pieChart2: null,
  resArr2: {},
  resArr: {},
  drivers: {},
}


const colors = [
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#2c2b79',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#2c2b79',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#7cb5ec',
  '#434348',
  '#90ed7d'
]

const Dashboard = (props) => {
  const [state, setState] = useState(initState);
 
  const {
    ServerSetting,
    logInUsers,
    engineHourSummery,
    devices3,
    geofences,
    totalDevices,
    drivers,
    positions,
    insideGeofence,
    allComputedAttributes,
    themecolors
  }=props
  // const ServerSetting = useSelector(state => state.ServerSetting)
  // const logInUsers = useSelector(state => state.logInUsers)
  // const engineHourSummery = useSelector(state => state.engineHourSummery)
  // const devices3 = useSelector(state => state.devices3)
  // const geofences = useSelector(state => state.geoFence)
  // const totalDevices = useSelector(state => state.devices.total)
  // const drivers = useSelector(state => state.drivers)
  // const positions = useSelector(state => state.positions)
  // const insideGeofence = useSelector(state => state.insideGeofence)
  // const allComputedAttributes = useSelector(state => state.driverIdsList)
  // const themecolors = useSelector(state => state.themeColors)

  useEffect(() => {
    prepareStates(props);
  
    if (logInUsers && logInUsers.id) {
      props.dispatch(fetchSummery());
    }
  
    return () => {
      setState({ ...initState });
      props.dispatch(resetFilters());
      props.dispatch(resetCatFilters());
    };
  }, [logInUsers, props.dispatch]);

  useEffect(() => {
    if (
      !state.isFetching &&
      positions &&
      positions.length &&
      ServerSetting &&
      logInUsers &&
      !(engineHourSummery && engineHourSummery.data)
    ) {
      setState(prevState => ({ ...prevState, isFetching: true }));
      props.dispatch(
        fetchSummery({
          positions: positions,
          ServerSetting: ServerSetting,
          logInUsers: logInUsers
        })
      );
    }
    prepareStates(props);
  }, [positions, ServerSetting, logInUsers, engineHourSummery, props, prepareStates]);

  const resetSelectors = () => {
    setState((prevState) => ({
      ...prevState,
      motionSelector: false,
      motionSelectorKey: '',
      geoSelector: false,
      geoSelectorKey: '',
      deviceSelector: false,
      deviceSelectorKey: '',
      categorySelector: false,
      categorySelectorKey: '',
      driverSelector: false,
      driverSelectorKey: ''
    }));
  };

  const openCategorySelector = (key, name) => {
    resetSelectors();
    if (!state.categorySelector) {
      showSelectedUnits(key, 'category');
    }
    setState((prevState) => ({
      ...prevState,
      categorySelector: !state.categorySelector,
      categorySelectorKey: name
    }));
  };

  const openMotionSelector = (key, name) => {
    resetSelectors();
    if (!state.motionSelector) {
      showSelectedUnits(key, 'device');
    }
    setState((prevState) => ({
      ...prevState,
      motionSelector: !state.motionSelector,
      motionSelectorKey: name,
      motionSelectorName: key
    }));
  };

  const openDeviceSelector = (key, name) => {
    resetSelectors();
    if (!state.deviceSelector) {
      showSelectedUnits(key, 'device');
    }
    setState((prevState) => ({
      ...prevState,
      deviceSelector: !state.deviceSelector,
      deviceSelectorKey: name
    }));
  };

  const openGeoSelector = (key, name) => {
    resetSelectors();
    if (!state.geoSelector) {
      showSelectedUnits(key, 'geofence');
    }
    setState((prevState) => ({
      ...prevState,
      geoSelector: !state.geoSelector,
      geoSelectorKey: name
    }));
  };

  const openDriverSelector = (driverIds, name) => {
    resetSelectors();
    if (!state.driverSelector) {
      setState((prevState) => ({
        ...prevState,
        driverIds
      }));
    }
    setState((prevState) => ({
      ...prevState,
      driverSelector: !state.driverSelector,
      driverSelectorKey: name,
      modalData: {}
    }));
  };

  const prepareDriver = ({ drivers, positions }) => {
    let fixDriver = null;
    const driverLinked = [];
    let driverLinked2 = [];

    fixDriver = devices3.filter(data => data.attributes.fixDriverId)
    fixDriver.map(d => {
      driverLinked.push(d.attributes.fixDriverId)
      return null;
    })
    drivers.map(dr => {
      if (dr && dr.id && driverLinked.includes(dr.id)) {
        driverLinked2.push(dr.uniqueId)
      }
      return null;
    })

    let ids = []
    let drIds = []
    let inactive = []
    positions.map(pos => {
      if (
        pos &&
        pos.attributes &&
        pos.attributes.driverUniqueId &&
        allComputedAttributes?.includes(pos.deviceId) &&
        pos.attributes.driverUniqueId !== '0'
      ) {
        if (!moment(pos.serverTime).isBefore(moment().subtract(10, 'minutes'))) {
          ids.push(pos.attributes.driverUniqueId)
        } else {
          inactive.push(pos.attributes.driverUniqueId)
        }
      }
      return null;
    })
    drivers.map(dr => {
      if (dr && dr.uniqueId && dr.uniqueId !== '0') {
        drIds.push(dr.uniqueId)
      }
      return null;
    })
    const assigned = drIds.filter(d => driverLinked2.includes(d) || ids.includes(d))
    const unassigned = drIds.filter(d => !ids.includes(d) && !driverLinked2.includes(d))
    const unknown = ids.filter(d => !drIds.includes(d))
    setState(prevState => ({
      ...prevState,
      drivers: {
        assigned,
        unassigned,
        unknown,
        inactive,
        total: drivers.length
      }
    }));
  }

  const prepareStates = (n) => {
    if (n.positions && n.drivers && n.drivers.length && n.allComputedAttributes) {
      setState(prevState => ({ ...prevState, driverFetched: true }));
      prepareDriver(n);
    }

    if (n.devices3 && n.devices3.length) {
      const notification = n.allNotifications;
      let notRegistered = notification.notRegistered;

      let cat = {};

      n.devices3.map(v => {
        let c = v.category || 'default';
        cat[c] = cat[c] || 0;
        cat[c]++;
        return null;
      })

      if (!state.pie1) {
        let objList = { total: 0, data: [] }
        Object.keys(cat).map((e, i) => {
          objList.total = n.devices3.length;
          objList.data.push({
            value: cat[e],
            color: colors[i],
            name: props.translate(e),
            key: e,
            image: <img src={`/assets/category/default/${e}.svg`} alt='' />
          })
          return null;
        })

        setState(prevState => ({
          ...prevState,
          pie1: objList
        }));
      }

      let resArr2 = {
        total: notRegistered.length +
          notification.statusOnline.length +
          notification.statusOffline.length +
          notification.gpsNotUpdated.length,
        online: notification.statusOnline.length,
        offline: notification.statusOffline.length,
        notRegistered: notification.notRegistered.length,
        gpsNotUpdated: notification.gpsNotUpdated.length
      }

      let resArr = {};
      const total = notification.Moving.length +
        notification.overSpeed.length +
        notification.gpsNotUpdated.length +
        notification.Idling.length +
        notification.Towing.length +
        notification.statusParking.length +
        notification.stop.length +
        notification.statusOffline.length;
      resArr = {
        total,
        data: [
          {
            value: notification.Moving.length,
            color: '#28a745',
            name: props.translate('moving'),
            key: 'Moving',
            image: <MovingIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.Idling.length,
            color: '#e4d354',
            name: props.translate('idling'),
            key: 'Idling',
            image: <IdlingIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.Towing.length,
            color: '#f45b5b',
            name: props.translate('towing'),
            key: 'Towing',
            image: <TowingIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.statusParking.length,
            color: '#1E90FF',
            name: props.translate('parking'),
            key: 'statusParking',
            image: <ParkingIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.gpsNotUpdated.length,
            color: 'rgb(25, 118, 210)',
            name: props.translate('GPSNotUpdated'),
            key: 'gpsNotUpdated',
            image: <GPSNotUpdated width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.stop.length,
            color: '#87CEFA',
            name: props.translate('stop'),
            key: 'stop',
            image: <StopIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.statusOffline.length,
            color: '#dc3545',
            name: props.translate('unknown'),
            key: 'statusOffline',
            image: <OfflineStatus width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.notRegistered.length,
            color: '#566374',
            key: 'notRegistered',
            name: props.translate('tracker.notRegisteredYet'),
            image: <NotRegistered width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.powerCut.length,
            color: '#566374',
            key: 'powerCut',
            name: props.translate('devicePowerCutOn'),
            image: <PowerCutIcon width={20} height={20} fill="currentColor" />
          }
        ]
      }

      setState(prevState => ({
        ...prevState,
        resArr,
        pieChart2: resArr2
      }));
    }

    let reports = n.engineHourSummery.data

    const fontId = n.ServerSetting && n.ServerSetting.attributes && n.ServerSetting.attributes.fontFamily || 'ff-4';
    const fontFamily = fonts[fontId];
    if (
      reports &&
      reports.length &&
      !state.enginHourReportLoading
    ) {
        let report = reports

        if (report && report.length) {
          let idling = [
            {
              name: props.translate('reportEngineHours'),
              color: '#e4d354',
              borderColor: '#e4d354',
              data: []
            }
          ]
          let mileage = [
            {
              name: props.translate('sensorsTranslation.mileage'),
              color: '#22b2d4',
              borderColor: '#22b2d4',
              data: []
            }
          ]
          const data = []
          const data2 = []
          report.map(row => {

            row.data && row.data.map(c => {
              if(row.type === 'hours') {
                data.push({
                  y: c.hoursRaw / (1000 * 60 * 60),
                  x: c.name,
                  colorIndex: c.hours
                })
              } else {
                const v = parseFloat((c.totalDistance / 1000).toFixed(2))
                data2.push({
                  y: v > 2000 || v < 0 ? 0 : v,
                  x: c.name
                })
              }
              return null
            })
            return null
          })

          const labels = data.map(d => d.x)
          const labels2 = data2.map(d => d.x)

          idling[0].data = data.map(d => ({
            y: d.y,
            colorIndex: d.colorIndex
          }))

          mileage[0].data = data2.map(d => d.y)

          let barChart1 = {
            config: barChartDefault().initializeWithOptions({
              chart: {
                height: '300px'
              },

              title: {
                text: props.translate('reportEngineHours'),
                style: {
                  color: themecolors.textColor,
                  fontFamily
                }
              },

              xAxis: {
                lineColor: 'transparent',
                tickColor: 'transparent',
                categories: labels,
                labels: {
                  style: {
                    color: themecolors.textColor,
                    fontFamily
                  }
                }
              },
              yAxis: {
                gridLineWidth: 0,
                title: {
                  text: null,
                  style: {
                    color: themecolors.textColor,
                    fontFamily
                  }
                },
                labels: false 
              },
              legend: false ,
              plotOptions: {
                series: {
                  stacking: 'normal',
                  borderRadius: 7,
                  text: {
                    style: {
                      color: themecolors.textColor,
                      fontFamily
                    }
                  }
                }
              },
              series: idling,
              tooltip: {
                formatter: function () {
                  return this.x + ': <strong>' + this.colorIndex + '</strong>';
                }
              }
            })
          }
          let distanceUnit =
            logInUsers &&
            logInUsers.attributes &&
            logInUsers.attributes.distanceUnit
              ? logInUsers.attributes.distanceUnit
              : 'km'
          let barChart2 = {
            config: barChartDefault().initializeWithOptions({
              chart: {
                height: '300px'
              },

              title: {
                text: props.translate('deviceMileage'),
                style: {
                  color: themecolors.textColor,
                  fontFamily
                }
              },

              xAxis: {
                categories: labels2,
                lineColor: 'transparent',
                tickColor: 'transparent',
                labels: {
                  style: {
                    color: themecolors.textColor,
                    fontFamily
                  }
                }
              },
              yAxis: {
                gridLineWidth: 0,
                title: {
                  text: null,
                  style: {
                    color: themecolors.textColor,
                    fontFamily
                  }
                },
                labels: false 
              },
              legend: false ,
              plotOptions: {
                series: {
                  borderRadius: 7,
                  text: {
                    style: {
                      color: themecolors.textColor,
                      fontFamily
                    }
                  }
                }
              },
              series: mileage,
              tooltip: {
                formatter: function () {
                  return (
                    this.x + ': <strong>' + DistanceUnits(this.y * 1000, distanceUnit) + '</strong>'
                  )
                }
              }
            })
          }

          setState(prevState => ({
            ...prevState,
            report: barChart1,
            mileage: barChart2,
            enginHourReportLoading: false
          }))
        }
    }
  }


  // checkLastResponseTime = (posDate, lastUpdate) => {
  //   return (
  //     posDate &&
  //     moment(posDate).isBefore(moment().subtract(10, 'minutes')) &&
  //     !moment(lastUpdate).isBefore(moment().subtract(10, 'minutes'))
  //   )
  // }

  const showSelectedUnits = (data, type) => {
    if (type === 'device') {
      let newList = [].concat(data);

      props.dispatch(applyDevicesCatFilter([]));
      props.dispatch(applyDevicesFilter(newList));
    }

    if (type === 'geofence') {
      let newList = ['geoIds'];
      props.dispatch(applyDevicesCatFilter([]));
      props.dispatch(applyDevicesFilter(newList, data));
    }

    if (type === 'category') {
      let newList = [].concat(data);

      props.dispatch(applyDevicesFilter([]));
      props.dispatch(applyDevicesCatFilter(newList));
    }
  };

    const d = drivers;
    const g = geofences;
    const { attributes } = logInUsers
    const topRow =  g && g.length === 0 && d && d.length === 0 ? 4 : 3;

    return (
      <div>
      <Layout
        {...props}
        noSidebar
        classFromChildren={'has-padding no-sidebar dashboard-page'}
        {...state}
        >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={topRow}>
            <Card themecolors={themecolors}>
                  {!state.deviceSelector ? (
                    <>
                      <h3
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          textAlign: 'center',
                          marginBottom: 0,
                          marginTop: 8
                        }}
                      >
                        {props.translate('unitsStatus')}
                      </h3>
                      <h3
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          marginTop: 8,
                          marginBottom: 8
                        }}
                      >
                        <span>
                          <TrailerIcon style={{
                              verticalAlign: 'middle',
                              marginRight: 10,
                              width: 40,
                              display: 'inline-block'
                            }} />
                          <span
                            style={{
                              fontSize: 30,
                              verticalAlign: 'middle',
                              marginRight: 10,
                              display: 'inline-block'
                            }}
                          >
                            {devices3.length}
                          </span>
                          <span
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle'
                            }}
                          >
                            {props.translate('trackers')}
                          </span>
                        </span>
                      </h3>
                    </>
                  ) : (
                    <>
                      <h3
                        style={{
                          fontSize: 18,
                          display: 'flex',
                          fontWeight: 400,
                          textAlign: 'left',
                          marginBottom: 8,
                          marginTop: 8,
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>
                          {props.translate(state.deviceSelectorKey)} 
                          {(totalDevices)}
                        </span>
                        <Button
                        onClick={() => openDeviceSelector(null)}
                        >
                         {props.translate('sharedBack')} 
                        </Button>
                      </h3>
                    </>
                  )}
                {!state.deviceSelector ? (
                  <>
                    {state.pieChart2 && state.pieChart2.total ? (
                      <div className='driver-state-wrap'>
                        <div className='driver-state-container'>
                          <div
                            className='driver-state2 online hover'
                            onClick={() =>
                              openDeviceSelector(
                                'statusOnline',
                                props.translate('tracker.online')
                              )
                            }
                          >
                            <OnlineStatus width={32} height={32} fill="currentColor" />
                            <h3>{state.pieChart2.online}</h3>
                            <p>{props.translate('tracker.online')}</p>
                          </div>
                          <div
                            className='driver-state2 offline hover'
                            onClick={() =>
                              openDeviceSelector(
                                'statusOffline',
                                props.translate('tracker.offline')
                              )
                            }
                          >
                            <OfflineStatus width={32} height={32} fill="currentColor" />
                            <h3>{state.pieChart2.offline}</h3>
                            <p>{props.translate('tracker.offline')}</p>
                          </div>
                          <div
                            className='driver-state2 not-register hover'
                            onClick={() =>
                              openDeviceSelector(
                                'notRegistered',
                                props.translate('tracker.notRegisteredYet')
                              )
                            }
                          >
                            <NotRegistered width={32} height={32} fill="currentColor" />
                            <h3>{state.pieChart2.notRegistered}</h3>
                            <p>
                              {props.translate('tracker.notRegisteredYet')}
                            </p>
                          </div>
                          <div
                            className='driver-state2  not-update hover'
                            onClick={() =>
                              openDeviceSelector(
                                'gpsNotUpdated',
                                props.translate('tracker.gpsNotUpdated')
                              )
                            }
                          >
                            <GPSNotSupport width={32} height={32} fill="currentColor" />
                            <h3>{state.pieChart2.gpsNotUpdated}</h3>
                            <p>
                              {props.translate('tracker.gpsNotUpdated')}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <BarGraphSkeleton3 />
                    )}
                  </>
                ) : null}
                {state.deviceSelector ? (
                  <div
                    style={{
                      paddingBottom: 10
                    }}
                  >
                    <DeviceSelector readOnly rows={8} />
                  </div>
                ) : null}
            </Card>
          </Grid>
          {drivers && drivers.length ? (
            <Grid item xs={12} md={4} lg={topRow}>
              <Card themecolors={themecolors}>
                    {!state.driverSelector ? (
                      <>
                        <h3
                          style={{
                            fontSize: 18,
                            fontWeight: 400,
                            textAlign: 'center',
                            marginBottom: 0,
                            marginTop: 8
                          }}
                        >
                          {props.translate('sharedDrivers')}
                        </h3>
                        <h3
                          style={{
                            fontSize: 14,
                            fontWeight: 700,
                            marginTop: 8,
                            marginBottom: 8
                          }}
                        >
                          <span>
                            <DriverIcon style={{
                              verticalAlign: 'middle',
                              marginRight: 10,
                              width: 40,
                              display: 'inline-block'
                            }} />
                            <span
                              style={{
                                fontSize: 30,
                                verticalAlign: 'middle',
                                marginRight: 10,
                                display: 'inline-block'
                              }}
                            >
                              {state.drivers.total}
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {props.translate('sharedDrivers')}
                            </span>
                          </span>
                        </h3>
                      </>
                    ) : state.driverSelector ? (
                      <h3
                        style={{
                          fontSize: 18,
                          display: 'flex',
                          fontWeight: 400,
                          textAlign: 'left',
                          marginBottom: 8,
                          marginTop: 8,
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>
                          {props.translate(state.driverSelectorKey)} (
                          {state.driverIds.length})
                        </span>
                        <Button onClick={() => openDriverSelector(null)}>
                         {props.translate('sharedBack')}
                        </Button>
                      </h3>
                    ) : null}
                  {!state.driverSelector ? (
                    <>
                      {state.drivers && state.drivers.total ? (
                        <>
                          <div className='driver-state-wrap'>
                            <div className='driver-state-container'>
                              <div
                                className='driver-state hover'
                                onClick={() =>
                                  openDriverSelector(
                                    state.drivers.assigned,
                                    props.translate('assigned')
                                  )
                                }
                              >
                                <AssignedIcon width={32} height={32} fill="currentColor" />
                                <h3>{state.drivers.assigned.length}</h3>
                                <p>{props.translate('assigned')}</p>
                              </div>
                              <div
                                className='driver-state hover'
                                onClick={() =>
                                  openDriverSelector(
                                    state.drivers.unassigned,
                                    props.translate('unassigned')
                                  )
                                }
                              >
                                <UnAssignedIcon width={32} height={32} fill="currentColor" />
                                <h3>{state.drivers.unassigned.length}</h3>
                                <p>{props.translate('unassigned')}</p>
                              </div>
                              <div
                                className='driver-state hover'
                                onClick={() =>
                                  openDriverSelector(
                                    state.drivers.unknown,
                                    props.translate('unknown')
                                  )
                                }
                              >
                                <NotRegistered width={32} height={32} fill="currentColor" />
                                <h3>{state.drivers.unknown.length}</h3>
                                <p>{props.translate('unknown')}</p>
                              </div>
                              <div
                                className='driver-state hover'
                                onClick={() =>
                                  openDriverSelector(
                                    state.drivers.inactive,
                                    props.translate('nosignal')
                                    )
                                  }
                              >
                                <UnknownIcon width={32} height={32} fill="currentColor" />
                                <h3>{state.drivers.inactive.length}</h3>
                                <p>{props.translate('nosignal')}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <BarGraphSkeleton3 />
                      )}
                    </>
                  ) : null}

                  {state.driverSelector ? (
                    <div
                      className='driver-state-wrap'
                      style={{
                        paddingBottom: 10
                      }}
                    >
                      <DriverSelector
                        readOnly
                        applyFilter
                        // onDriverClick={onDriverClick}
                        rows={8}
                        displayList={state.driverIds}
                        viewIMEI={logInUsers.userType===-1?true:attributes&&attributes.viewIMEI?true:false}                      />
                    </div>
                  ) : null}
              </Card>
            </Grid>
          ) : null}

          <Grid item xs={12} md={4} lg={topRow}>
            <div
              style={{
                padding: 15,
                background: themecolors.backgroundColor,
                color: themecolors.textColor,
                borderRadius: 6,
                height: '100%'
              }}
            >
              {!state.motionSelector ? (
                <h3
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    textAlign: 'center',
                    marginBottom: 8,
                    marginTop: 0
                  }}
                >
                  {props.translate('motionStatus')}
                </h3>
              ) : (
                <h3
                  style={{
                    fontSize: 18,
                    display: 'flex',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginBottom: 8,
                    marginTop: 0,
                    justifyContent: 'space-between'
                  }}
                >
                  <span>
                    {props.translate(state.motionSelectorKey)} (
                    {totalDevices})
                  </span>
                  <Button onClick={() => openMotionSelector(null)}>
                    {props.translate('sharedBack')}
                  </Button>
                </h3>
              )}
              {!state.motionSelector ? (
                <>
                {/* {console.log("reSAa===",state.resArr )} */}
                {/* {console.log("re===",state.resArr )} */}
                  {state.resArr && state.resArr.total ? (
                    <MotionList
                      data={state.resArr}
                      showSelectedUnits={openMotionSelector}
                    />
                  ) : (
                    <BarGraphSkeleton2 />
                  )}
                </>
              ) : null}
              {state.motionSelector ? (
                <DeviceSelector motionSelectorName={state.motionSelectorName} readOnly rows={8}  dashboradDetails/>
              ) : null}
            </div>
          </Grid>
          {geofences.length ? (
            <Grid item xs={12} md={4} lg>
              <div
                style={{
                  padding: 15,
                  background: themecolors.backgroundColor,
                  color: themecolors.textColor,
                  borderRadius: 6,
                  height: '100%'
                }}
              >
                {!state.geoSelector ? (
                  <h3
                    style={{
                      fontSize: 18,
                      fontWeight: 400,
                      textAlign: 'center',
                      marginBottom: 8,
                      marginTop: 0
                    }}
                  >
                    {props.translate('sharedGeofence')}
                  </h3>
                ) : (
                  <h3
                    style={{
                      fontSize: 18,
                      display: 'flex',
                      fontWeight: 400,
                      textAlign: 'left',
                      marginBottom: 8,
                      marginTop: 0,
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>
                      {props.translate(state.geoSelectorKey)} (
                      {totalDevices}){' '}
                    </span>{' '}
                    <Button onClick={() => openGeoSelector(null)}>
                      {props.translate('sharedBack')}
                    </Button>
                  </h3>
                )}
                {!state.geoSelector &&
               geofences &&
               geofences.length ? (
                  <>
                    <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height: 38 * 9
                      }}
                    >
                      {geofences && geofences.length ? (
                        <GeoList
                          data={geofences}
                          showSelectedUnits={openGeoSelector}
                          geoIds={insideGeofence}
                        />
                      ) : (
                        <BarGraphSkeleton2 />
                      )}
                    </Scrollbar>
                  </>
                ) : null}
                {state.geoSelector ? (
                  <DeviceSelector readOnly rows={9} />
                ) : null}
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12} md={4} lg={topRow}>
            <div
              style={{
                padding: 15,
                background: themecolors.backgroundColor,
                color: themecolors.textColor,
                borderRadius: 6,
                height: '100%'
              }}
            >
              {!state.categorySelector ? (
                <h3
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    textAlign: 'center',
                    marginBottom: 8,
                    marginTop: 0
                  }}
                >
                  {props.translate('unitsTypes')}
                </h3>
              ) : (
                <h3
                  style={{
                    fontSize: 18,
                    display: 'flex',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginBottom: 8,
                    marginTop: 0,
                    justifyContent: 'space-between'
                  }}
                >
                  <span>
                    {props.translate(state.categorySelectorKey)} (
                    {totalDevices})
                  </span>
                  <Button onClick={() => openCategorySelector(null)}>
                    {props.translate('sharedBack')}
                  </Button>
                </h3>
              )}
              {!state.categorySelector ? (
                <>
                  <Scrollbar
                    disableTracksWidthCompensation={true}
                    style={{
                      height:
                        drivers && drivers.length
                          ? 38 * 6
                          : 38 * 9
                    }}
                  >
                    {state.pie1 && state.pie1.total ? (
                      <MotionList
                        data={state.pie1}
                        showSelectedUnits={openCategorySelector}
                      />
                    ) : (
                      <BarGraphSkeleton2 />
                    )}
                  </Scrollbar>
                </>
              ) : null}
              {state.categorySelector ? (
                <DeviceSelector readOnly rows={8} />
              ) : null}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={
              drivers && drivers.length
                ? geofences && !geofences.length
                  ? 12
                  : 9
                : 12
            }
          >
            <Grid container spacing={2}>
              {state.report &&
              state.report.config &&
              !state.enginHourReportLoading ? (
                <Grid item xs={12} md={6}>
                  <Card themecolors={themecolors}>
                   <div>
                       <ReactHighcharts
                        highcharts={Highcharts}
                        domProps={{ id: 'barChart1' }}
                        config={state.report.config}
                      /> 
                    </div>
                  </Card>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <Card themecolors={themecolors}>
                    <BarGraphSkeleton type={1} />
                  </Card>
                </Grid>
              )}
              {state.report &&
              state.report.config &&
              !state.enginHourReportLoading ? (
                <Grid item xs={12} md={6}>
                  <Card themecolors={themecolors}>
                   <div>
                       <ReactHighcharts
                        highcharts={Highcharts}
                        domProps={{ id: 'barChart2' }}
                        config={state.mileage.config}
                      /> 
                    </div>
                  </Card>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <Card themecolors={themecolors}>
                    <BarGraphSkeleton />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Layout>
      </div>
    )
}


const BarGraphSkeleton = (props) => {

  return (
    <div
      style={{
        marginTop: 20,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <div style={{ marginBottom: 36 }}>
        <Skeleton className="skeleton-pulse" width={100} height={20} />
      </div>
      <table
        style={{ width: '100%', borderCollapse: 'collapse' }}
        border="0"
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          <tr>
            <td
              align="right"
              width={200}
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className="skeleton-pulse" width={150} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className="skeleton-pulse" width={400} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className="skeleton-pulse" width={200} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className="skeleton-pulse" width={260} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className="skeleton-pulse" width={170} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className="skeleton-pulse" width={370} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className="skeleton-pulse" width={250} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className="skeleton-pulse" width={330} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className="skeleton-pulse" width={230} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className="skeleton-pulse" width={450} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className="skeleton-pulse" width={120} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className="skeleton-pulse" width={320} height={14} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginBottom: 20, marginTop: 30, display: 'flex' }}>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            paddingRight: 40,
          }}
        >
          <div style={{ marginRight: 9 }}>
            <Skeleton
              className="skeleton-pulse"
              variant="circle"
              width={12}
              height={12}
            />
          </div>
          <Skeleton className="skeleton-pulse" width={60} height={14} />
        </div>
        {props.type === 1 && (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              paddingRight: 40,
            }}
          >
            <div style={{ marginRight: 9 }}>
              <Skeleton
                className="skeleton-pulse"
                variant="circle"
                width={12}
                height={12}
              />
            </div>
            <Skeleton className="skeleton-pulse" width={60} height={14} />
          </div>
        )}
      </div>
    </div>
  );
};

const BarGraphSkeleton2 = () => {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
    </div>
  )
}
const BarGraphSkeleton3 = () => {
  return (
    <div
      style={{
        marginTop: 20,
        padding: 16,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
        <Skeleton className='skeleton-pulse' width={'48%'} height={116} />
        <Skeleton className='skeleton-pulse' width={'48%'} height={116} />
        <Skeleton className='skeleton-pulse' width={'48%'} height={116} />
        <Skeleton className='skeleton-pulse' width={'48%'} height={116} />
      </div>
    </div>
  )
}

export const MotionList = ({ data, showSelectedUnits, className, category }) => {
  
  
  const html = data?.data?.map(d => (
    <div
      key={d.name}
      className={'motion-status-bar hover ' + className}
      onClick={() => showSelectedUnits && showSelectedUnits(d.key, category ? 'category' : 'device', d.name)}
    >
      <span style={{ background: d.color }} className='motion-status-border' />{' '}
      <span className='motion-status-img'>{d.image}</span> <span className='motion-status-name'>{<Translate id={d.name}/>}</span>
      <span className='motion-status-count'>{d.value}</span>
    </div>
  ))
  return <div>{html}</div>
}

export const GeoList = ({ data, showSelectedUnits, className, geoIds }) => {
  data.sort((b, a) => (geoIds[a.id] && geoIds[a.id].length || 0)-(geoIds[b.id] && geoIds[b.id].length || 0))
  const html = data.map((d, i) => {
    return (
    <div
      key={d.id}
      className={'motion-status-bar hover ' + className}
      onClick={() => showSelectedUnits && showSelectedUnits(d.id, 'geofence', d.name)}
    >
      <span
        style={{ background: colors[i % colors.length] }}
        className='motion-status-border'
      />
      <span className='motion-status-img' />
      <span className='motion-status-name'>{d.name}</span>
      <span className='motion-status-count'>
        {geoIds && geoIds[d.id] && geoIds[d.id].length
          ? geoIds[d.id].length
          : 0}
      </span>
    </div>
  )
  
})
  return <div>{html}</div>
}

const mapStateToProps = state => {
  let driverIdsList = [];
  Object.values(state.allComputedAttributes).map(({item, deviceIds}) => {
    if(deviceIds && item.attribute) {
      driverIdsList = [...driverIdsList, ...deviceIds];
    }
    return null
  });
  return {
    ServerSetting: state.ServerSetting,
    logInUsers: state.logInUsers,
    engineHourSummery: state.engineHourSummery,
    devices3: state.devices3,
    geofences: state.geoFence,
    totalDevices: state.devices.total,
    allNotifications: state.allNotifications,
    drivers: state.drivers,
    positions: state.positions,
    insideGeofence: state.insideGeofence,
    allComputedAttributes: driverIdsList,
    themecolors: state.themeColors
  }
}

export default connect(mapStateToProps)(withLocalize(Dashboard))
