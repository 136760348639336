import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import { Grid, InputAdornment } from '@material-ui/core'
import SimpleModalWrapped from '../common/Modal'
import MenuItem from '../common/MenuItem'
import Button from '../common/Button'
import MarkerMap from '../common/Maps'
import CustomDialog from '../common/Dialog'
import SingleSelect from '../common/SingleSelect'
import TextField from '../../Components/common/TextField'
import instance from '../../axios'
import DateRangePicker from '../common/DateRangeHistoryPicker'
import moment from 'moment'
import { ReactComponent as WorkFlow } from './../../assets/workflow/road-arrow.svg'
// import { errorHandler } from '../../Helpers'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'
import { errorHandler } from '../../Helpers'

const DataFormat = (data) => {
  return {
    id: data && data.form && data.form.id ? data.form.id : 0,
    refNum: data && data.form && data.form.refNum ? data.form.refNum : 0,
    startPoint: data && data.value === 'tab2' ? data?.selectedMetrixData?.startPoint || '' : data && data.form && data.form.startPoint ? data.form.startPoint : '',
    startOutSkirts: data && data.value === 'tab2' ? data?.selectedMetrixData?.startOutSkirts || '' : data && data.form && data.form.startOutSkirts ? data.form.startOutSkirts : '',
    endPoint: data && data.value === 'tab2' ? data?.selectedMetrixData?.endPoint || '' : data && data.form && data.form.endPoint ? (data.form.endPoint) : '',
    endOutSkirts: data && data.value === 'tab2' ? data?.selectedMetrixData?.endOutSkirts || '' : data && data.form && data.form.endOutSkirts ? (data.form.endOutSkirts) : '',
    foodCost: data && data.value === 'tab2' ? data?.selectedMetrixData?.foodCost || 0 : data && data.form && data.form.foodCost ? (data.form.foodCost) : 0,
    perDiemCost: data && data.value === 'tab2' ? data?.selectedMetrixData?.perDiemCost || 0 : data && data.form && data.form.perDiemCost ? (data.form.perDiemCost) : 0,
    foodPlusPerDiemCost: data && data.value === 'tab2' ? data?.selectedMetrixData?.foodPlusPerDiemCost || 0 : data && data.form && data.form.perDiemCost ? (data.form.perDiemCost) : 0,
    totalCost: data && data.value === 'tab2' ? data?.selectedMetrixData?.totalCost || 0 : data && data.form && data.form.totalCost ? (data.form.totalCost) : 0,
    startLat: data && data.form.locationFromData && data.form.locationFromData.latitude ? data.form.locationFromData.latitude : '',
    startLng: data && data.form.locationFromData && data.form.locationFromData.longitude ? data.form.locationFromData.longitude : '',
    endLat: data && data.form.locationToData && data.form.locationToData.latitude ? data.form.locationToData.latitude : '',
    endLng: data && data.form.locationToData && data.form.locationToData.longitude ? data.form.locationToData.longitude : '',
    startTime: data && data.to ? data.from : '',
    endTime: data && data.from ? data.to : '',
    vehicleType: data && data.form && data.form.vehicleType ? data.form.vehicleType : '',
    interCity: data && data.value === 'tab2' ? true : false,
    deviceId: data && data.form && data.form.deviceId ? data.form.deviceId.id : '',
    cost: data && data.value === 'tab2' ? data?.selectedMetrixData?.cost || '' : data && data.form && data.form.cost ? parseInt(data.form.cost) : 0,
    mileage: data && data.value === 'tab2' ? data?.selectedMetrixData?.mileage || '' : data && data.form && data.form.mileage ? parseInt(data.form.mileage) : 0,
    startPositionId: data && data.startPositionId ? data.startPositionId : '',
    endPositionId: data && data.endPositionId ? data.endPositionId : '',
    status: data && data.status ? data.status : '',
    attributes: data && data.form && data.form.attributes ? data.form.attributes : {},
  }
}
export const WorkFlowForm = (props) => {
  const [state, setState] = useState({
    isMapModal: false,
    mapForm: {
      latitude: '',
      longitude: '',
      zoom: '',
      poiLayer: '',
    },
    isVisableAddlocBtn: false,
    localPostionObj: '',
    isVisableAddBtn: false,
    maps: [],
    locationMode: '',
    selectedMetrixData: {},
    selectedMetrixData1: {},
    selectedDate: '',
    devicesList: [],
    form: {
      cost: '',
      mileage: '',
      refNum: '',
      vehicleType: '',
      startPoint: '',
      endPoint: '',
      startOutSkirts: '',
      endOutSkirts: '',
      deviceId: {},
      locationFromData: {},
      locationToData: {},
    },
    getDeviceList: false,
    isVisible: true,
    locationFromAddress: '',
    locationToAddress: '',
    noOfDays: ""
    // value: props&&props.createMode?'tab1':'tab2',
  })
  const logInUser = useSelector((state) => state.logInUsers)
  const themecolors = useSelector((state) => state.themeColors)
  const devices = useSelector((state) => state.devices?.data || [],)
  const positions = useSelector((state) => state.positions)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.editMode) {
      const { formData } = props
      let selectedData = props.metrixData.filter(item => item.id === formData.attributes.tripOutSideId)
      let timeFormat = 'HH:mm'
      if (logInUser && logInUser.twelveHourFormat) {
        timeFormat = 'hh:mm A'
      }
      var formatedDate = {
        from: moment(formData.startTime && formData.startTime).toISOString(),
        to: moment(formData.endTime && formData.endTime).toISOString()
      }
      fetchAddress('locationFrom', formData && formData.startLat, formData && formData.startLng)
      fetchAddress('locationTo', formData && formData.endLat, formData && formData.endLng)
      setState((prevState) => ({
        ...prevState,
        value:formData.interCity ? 'tab2': 'tab1',
        form: {
          ...prevState.form,
          ...formData,
          cost: !formData.interCity ? formData ? formData.cost : '' : '',
          mileage: !formData.interCity ? formData ? formData.mileage : '' : '',
          startPoint: !formData.interCity ? formData ? formData.startPoint : '' : '',
          endPoint: !formData.interCity ? formData ? formData.endPoint : '' : '',
          startOutSkirts: !formData.interCity ? formData ? formData.startOutSkirts : '' : '',
          endOutSkirts: !formData.interCity ? formData ? formData.endOutSkirts : '' : '',
          // foodCost:!formData.interCity ?formData?formData.foodCost: '': '',
          // perDiemCost:!formData.interCity ?formData?formData.perDiemCost: '': '',
          // foodPlusPerDiemCost :!formData.interCity ?formData?(formData.foodCost + formData.perDiemCost) : '': '',
          // totalCost :!formData.interCity ?formData?((formData.foodCost + formData.perDiemCost) +formData.cost ) : '': '',
          deviceId: devices.find(item => item.id === formData.deviceId),
        },
        selectedMetrixData: formData.interCity ? selectedData[0] : {},
        selectedMetrixData1: formData.interCity ? selectedData[0] : {},

        selectedDate: moment(formData.startTime).format('YYYY-MM-DD ' + timeFormat) +
          ' - ' +
          moment(formData.endTime).format('YYYY-MM-DD ' + timeFormat),
        ...formatedDate

      }))
    }
    else {
      setState((prevState) => ({
        ...prevState,
        value: "tab1"
      }))
    }
  }, [])

  useEffect(() => {
    const { refNum, cost, startPoint, endPoint, vehicleType } = state.form
    const { to, from } = state
    // const { selectedMetrixData } = this.state
    if (state.value === 'tab1') {
      if (refNum && cost && startPoint && endPoint && vehicleType && to && from) {
        setState((prevState) => ({
          ...prevState,
          isVisible: false
        }))
      }
      else {
        setState((prevState) => ({
          ...prevState,
          isVisible: true
        }))
      }
    }
    else {
      if (refNum && vehicleType && to && from) {
        setState((prevState) => ({
          ...prevState,
          isVisible: false
        }))
      }
      else {
        setState((prevState) => ({
          ...prevState,
          isVisible: true
        }))
      }
    }

  }, [state.form.refNum, state.form.cost, state.form.startPoint, state.form.endPoint, state.form.vehicleType, state.to, state.from])


  const handleChange = (event, value) => {
    // setState({ value })
    if (value === 'startEndPoint') {
      setState((prevState) => ({
        ...prevState,
        selectedMetrixData: event.target.value,
        selectedMetrixData1: event.target.value
      }))
    }
    else if (value === 'mileage' || value === 'cost' || value === 'refNum' || value === 'vehicleType') {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [value]: event.target.value
        }
      }))
    }
    else if (event === 'deviceId' || event === 'startOutSkirts' || event === 'endOutSkirts' || event === 'startPoint' || event === 'endPoint' || event === 'cost') {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [event]: value
        }
      }))
    }
    else {
      setState((prevState) => ({
        ...prevState,
        [value]: event.target?.value
      }))
    }
  }

  const handleChangeTabs = (event, value) => {
    setState((prevState) => ({
      ...prevState,
      selectedMetrixData: {},
      selectedMetrixData1: {},
      value,
    }))
  }

  const handleClickShowPostion = (item) => {
    setState((prevState) => ({
      ...prevState,
      isMapModal: true,
      isVisableAddlocBtn: false,
      locationMode: item
    }))
  }

  const onCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      isMapModal: false
    }))
  }

  const setSelectedLocation = () => {
    const { localPostionObj } = state
    if (localPostionObj) {
      if (state.locationMode === 'locationFrom') {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            locationFromData: {
              ...prevState.form.locationFromData,
              latitude: localPostionObj.latlng.lat,
              longitude: localPostionObj.latlng.lng,
              zoom: localPostionObj.zoom
            }
          },
          isMapModal: false
        }))
        fetchAddress(state.locationMode, localPostionObj.latlng.lat, localPostionObj.latlng.lng)
      }
      else {
        setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            locationToData: {
              ...prevState.form.locationToData,
              latitude: localPostionObj.latlng.lat,
              longitude: localPostionObj.latlng.lng,
              zoom: localPostionObj.zoom
            }
          },
          isMapModal: false
        }))
        fetchAddress(state.locationMode, localPostionObj.latlng.lat, localPostionObj.latlng.lng)
      }
    }
  }


  const fetchAddress = (mode, lat, lng) => {
    axios.get(`http://172.105.79.247/nominatim/reverse.php?format=json&lat=${lat}&lon=${lng}&zoom=27&addressdetails=1`)
      .then(res => {
        const resp = res.data;
        if (mode === 'locationFrom') setState((prevState) => ({ ...prevState, locationFromAddress: resp.display_name }))
        if (mode === 'locationTo') setState((prevState) => ({ ...prevState, locationToAddress: resp.display_name }))
      })
      .catch(error => { console.warn('error ===', error) })
  }

  const getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      setState((prevState) => ({
        ...prevState,
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true
      }))
    }
  }

  const showDates = (e, date) => {
    let timezone = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone
    }
    if (
      logInUser &&
      logInUser.attributes &&
      logInUser.attributes.timezone
    ) {
      timezone = logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)
    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }



    const from = moment(formatedDate.from);
    const to = moment(formatedDate.to);

    // Calculate the difference in days
    const daysDifference = to.diff(from, 'days');


    let timeFormat = 'HH:mm'

    if (logInUser && logInUser.twelveHourFormat) {
      timeFormat = 'hh:mm A'
    }
    setState((prevState) => ({
      ...prevState,
      selectedMetrixData: {
        ...prevState.selectedMetrixData,
        foodPlusPerDiemCost: (state.selectedMetrixData.foodCost + state.selectedMetrixData.perDiemCost) * (daysDifference + 1),
        totalCost: (((state.selectedMetrixData.foodCost + state.selectedMetrixData.perDiemCost) * (daysDifference + 1)) + state.selectedMetrixData.cost)
      },
      noOfDays: daysDifference + 1,
      selectedDate:
        moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
        ' - ' +
        moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
      ...formatedDate
    }))
  }

  const getDevices = () => {
    const { from, to } = state
    const { vehicleType } = state.form
    let city = state && state.value === 'tab1' ? true : false
    if (vehicleType && from && to) {
      instance(`/api/workflows/available/devices?startTime=${from}&endTime=${to}&interCity=${city}&category=${vehicleType}`)
        .then(res => {
          let newList = res.map(item => {
            return (
              {
                ...item,
                mileage: positions.filter(p => p.deviceId === item.id)
              }
            )
          })
          if (newList.length) {
            let reslist = newList.map(item => {
              let mil = item && item.mileage && item.mileage[0] && item.mileage[0].attributes && item.mileage[0].attributes.totalDistance ? item.mileage[0].attributes.totalDistance : 0
              return (
                {
                  ...item,
                  mileage: (mil / 1000).toFixed(2)
                })
            })
            setState((prevState) => ({
              ...prevState,
              devicesList: reslist,
              getDeviceList: true
            }))
          }
          else {
            setState((prevState) => ({
              ...prevState,
              devicesList: [],
              getDeviceList: false
            }))
            dispatch(
              Notifications.error({
                message: props.translate('vehiclesNotAvailable'),
                autoDismiss: 10
              })
            )

          }
        })
        .catch(() => {
          setState((prevState) => ({
            ...prevState,
            getDeviceList: true
          }))
          // errorHandler(error, props.dispatch)
        })
    }
  }
  const isSubmit = () => {
    if (state.value === "tab2") {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          attributes: {
            ...prevState.form.attributes,
            tripOutSideId: state?.selectedMetrixData?.id || ''

          }
        }
      }))
      finalSubmit()
    }
    else {
      finalSubmit()
    }
  }

  const finalSubmit = () => {
    const obj = DataFormat(state, logInUser)

    instance({
      url: !props.editMode ? `/api/workflows` : `/api/workflows/${state.form.id}`,
      method: !props.editMode ? 'POST' : 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars
        dispatch(
          Notifications.success({
            message: props.editMode ? props.translate('workFlowUpdated') : props.translate('workFlowCreated'),
            autoDismiss: 10
          })
        )
        setState((prevState) => ({
          ...prevState,
          form: {},
          selectedDate: '',
          selectedMetrixData: '',
          selectedMetrixData1: {}
        }))
        props.fetchMoreItems()
        props.modelControl()
      })
      .catch(error => {errorHandler(error,dispatch)})
  }
  const { classes } = props;
  const { value } = state
  return (
    <div style={{ height: '100%', width: '40%', marginLeft: '350px' }}>
      <div style={{ background: themecolors.backgroundColor, color: themecolors.textColor, borderRadius: 6, position: 'relative' }}>
        <SimpleModalWrapped
          // {...props}
          visable
          title={props.translate('worKFLow')}
          modalControle={props.modelControl}
          notShowAttributesBtn
          btnCloseText='sharedBack'
          content={
            <>
              <Grid container spacing={2}>
                <Grid item md={4} >
                  <strong><p style={{ textAlign: 'center', marginTop: '14px' }}>{props.translate('refNum')}</p></strong>
                </Grid>
                <Grid item md={8} >
                  <TextField
                    id='name'
                    label={props.translate('refNum')}
                    required
                    placeholder=''
                    value={state.form?.refNum || ''}
                    onChange={e => handleChange(e, 'refNum')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    InputProps={{
                      classes: {
                        root: 'theme-cssOutlinedInput',
                        error: 'theme-input-error',
                        input: 'theme-input',
                        focused: 'theme-cssFocused',
                        notchedOutline: 'theme-notchedOutline'
                      }
                    }}
                    InputLabelProps={{
                      ...props.InputLabelProps,
                      classes: {
                        root: 'theme-label',
                        focused: 'theme-label-focused',
                        shrink: 'theme-label-shrink',
                        error: 'theme-label-error'
                      }
                    }}

                  />
                </Grid>
              </Grid>
              <div style={{ paddingTop: 17 }}>
                <AppBar
                  position='static'
                  color='inherit'
                  style={{ background: 'none', boxShadow: 'none' }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    TabIndicatorProps={{
                      style: { background: themecolors.themeLightColor + '40' }
                    }}
                    scrollButtons='on'
                    classes={{
                      root: 'custom-tabs-root',
                      flexContainer: 'custom-tabs',
                      scrollable: 'custom-tabs-scrollable',
                      indicator: 'custom-indicator',
                      scrollButtons: 'scrollable-buttons'
                    }}
                  >
                    <Tab
                      value='tab1'
                      label={props.translate('tripInsideCity')}
                      style={{
                        background: state.value === 'tab1' ? themecolors.themeLightColor : themecolors.backgroundColor,
                        minHeight: '29px',
                        borderRadius: '6px 6px 0 0',
                        margin: '0px -2px 0 2px',
                        color: themecolors.textColor,
                        border: `1px solid ${themecolors.themeLightColor}`
                      }}
                    />
                    {logInUser && logInUser.userType !== 1 ? (
                      <Tab
                        value='tab2'
                        label={props.translate('tripOutSideCity')}
                        style={{
                          background: state.value === 'tab2' ? themecolors.themeLightColor : themecolors.backgroundColor,
                          minHeight: '29px',
                          borderRadius: '6px 6px 0 0',
                          margin: '0 2px',
                          color: themecolors.textColor,
                          border: `1px solid ${themecolors.themeLightColor}`
                        }}
                      />
                    ) : null}

                  </Tabs>
                </AppBar>
                <Paper
                  style={{
                    padding: 16,
                    minHeight: 100,
                    background: themecolors.themeLightColor,
                    // padding: 10,
                    maxHeight: 575,
                    // overflow: 'auto',
                    boxSizing: 'border-box',
                    borderRadius: 10
                  }}
                  className='custom-menu-paper'
                  square={true}
                >
                  {value === 'tab1' && (
                    <div>
                      <TripDetails
                        translate={props.translate}
                        form={state.form || {}}
                        mode={state.mode}
                        handleChange={handleChange}
                        classes={classes}
                        handleClickShowPostion={handleClickShowPostion}
                        themecolors={themecolors}
                        locationFromAddress={state.locationFromAddress}
                        locationToAddress={state.locationToAddress}
                        logInUser={logInUser}
                      />
                    </div>
                  )}
                  {logInUser &&
                    logInUser.userType !== 1 &&
                    value === 'tab2' ? (
                    <div >
                      <TripOutSideDetails
                        translate={props.translate}
                        locationToData={state.locationToData || {}}
                        mode={state.mode}
                        handleChange={handleChange}
                        classes={classes}
                        noOfDays={state.noOfDays}
                        handleClickShowPostion={handleClickShowPostion}
                        themecolors={themecolors}
                        metrixData={props?.metrixData || []}
                        selectedMetrixData={state?.selectedMetrixData || {}}
                        selectedMetrixData1={state?.selectedMetrixData1 || {}}

                        logInUser={logInUser}
                      />
                    </div>
                  ) : null}
                </Paper>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginTop: 10 }}>
                  <TextField
                    id='vehicleType'
                    select
                    label={props.translate('vehicleType')}
                    value={state.form?.vehicleType || ''}
                    onChange={e => handleChange(e, 'vehicleType')}
                    margin='dense'
                    fullWidth
                  >
                    <MenuItem value={0}>
                      <em>{props.translate('None')}</em>
                    </MenuItem>
                    {props.categoriesList.map(option => (
                      <MenuItem key={option} value={option}>{props.translate(option)}</MenuItem>
                    ))}
                  </TextField>
                  {/* <SingleSelect
                        array={props?.categoriesList || []}
                        selectName='vehicleType'
                        label={props.translate('vehicleType')}
                          // value={props.form&&props.form.areaId&&props.form.areaId.key
                          //   ? {
                          //       id: props.form.areaId.key,
                          //       value: props.form.areaId.key,
                          //       label: props.form.areaId.name,
                          //       uniqueId:props.form.areaId.uniqueId,
                          //     }
                          //   : ''
                          // }
                          // value={props.form.vehicleType || ''}
                        // onChange={e => props.handleChange('vehicleType', e)}
                        handleChange={e=>handleChange('vehicleType',e )}
                        canRemove={true}
                        canAssign={true}
                    /> */}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={2} sm={2} md={2} lg={2} style={{ marginTop: '12px', paddingLeft: '12px' }}>
                  <span>{props.translate('Job Time')}</span>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <DateRangePicker
                    fill
                    error={
                      props.timeError
                    }
                    selectedDate={state.selectedDate}
                    label={
                      props.translate('reportFrom') +
                      ' ' +
                      props.translate('reportTo')
                    }
                    onEvent={showDates}
                    trip
                    workFlow
                  />
                </Grid>
                {/* <Grid item xs={5} sm={5} md={5} lg={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        error={false}
                        margin='dense'
                        label={props.translate('endDate')}
                        variant='outlined'
                        fullWidth
                        format='dd/MM/yyyy'
                        // value={
                        //   props.maintenanceForm.attributes &&
                        //   props.maintenanceForm.attributes.startDate
                        //     ? new Date(
                        //         `${props.maintenanceForm.attributes.startDate}`
                        //       ).toString()
                        //     : null
                        // }
                        onChange={e=>handleChange('endDate',e)}
                        InputProps={{
                        classes: {
                            root: 'theme-cssOutlinedInput',
                            error: 'theme-input-error',
                            input: 'theme-input',
                            focused: 'theme-cssFocused',
                            notchedOutline: 'theme-notchedOutline'
                        }
                        }}
                        InputLabelProps={{
                        ...props.InputLabelProps,
                        classes: {
                            root: 'theme-label',
                            focused: 'theme-label-focused',
                            shrink: 'theme-label-shrink',
                            error: 'theme-label-error'
                        }
                        }}
                    />
                        </MuiPickersUtilsProvider>
                </Grid>    */}
              </Grid>
              {(<Grid container spacing={1} style={{ margin: '5px 15px 0 0' }}>
                <Grid item sm={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button size='small' onClick={() => getDevices()} >
                    {props.translate('getvehicles')}{' '}
                  </Button>
                  {/* <Button
                      size='small'
                      // className='button-white'
                      onClick={()=>getDevices()} 
                      // disabled={state.isVisible}
                  >
                      {props.translate('getvehicles')}{' '}
                  </Button> */}
                </Grid>
              </Grid>)}
              {(state.getDeviceList || props.editMode) &&
                (<>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <SingleSelect
                        array={state?.devicesList || []}
                        selectName='deviceId'
                        label={props.translate('selectVehicle')}
                        value={state.form && state.form.deviceId && state.form.deviceId.id
                          ? {
                            id: state.form.deviceId.id,
                            value: state.form.deviceId.id,
                            label: state.form.deviceId.name,
                            uniqueId: state.form.deviceId.uniqueId,
                          }
                          : ''
                        }
                        handleChange={handleChange}
                        canRemove={true}
                        canAssign={true}
                        mileageCounter
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ margin: '5px 15px 0 0' }}>
                    <Grid item sm={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        size='small'
                        // className='button-white'
                        onClick={() => isSubmit()}
                        disabled={state.isVisible}
                      >
                        {props.createMode ? props.translate('Create') : props.translate('Update')}{' '}
                      </Button>
                    </Grid>
                  </Grid> </>)}
              {state.isMapModal && (
                <CustomDialog
                  title={props.translate('setLocation')}
                  themecolors={themecolors}
                  visable={true}
                  onClose={onCloseModal}
                  isVisableBtn={true}
                  draggable={true}
                  CancelText={props.translate('sharedBack')}
                  headerActions={
                    <Button
                      size='small'
                      variant='contained'
                      color='secondary'
                      disabled={!state.isVisableAddlocBtn}
                      onClick={() => setSelectedLocation()}
                    >
                      {' '}
                      {props.translate('sharedAdd')}
                    </Button>
                  }
                  bodyPadding={0}
                >
                  <MarkerMap
                    getPostion={getPostion}
                    minHeight={500}
                    zoom={state.mapForm.zoom || 0}
                    lat={state.mapForm.latitude || 0}
                    lng={state.mapForm.longitude || 0}
                    height={'100%'}
                  />
                </CustomDialog>
              )}
            </>}
        />
      </div>
    </div>

  )
}

const locationfieldsEnabled = true;

const TripDetails = ({
  // locationToData,
  handleChange,
  // themecolors,
  translate,
  // mode,
  // classes,
  // handleClickShowPostion,
  form,
  logInUser,
  // locationFromAddress,
  // locationToAddress
}) => {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            id='startPoint'
            required
            margin='dense'
            label={translate('startPoint')}
            variant='outlined'
            fullWidth
            value={form?.startPoint || ''}
            onChange={e => handleChange('startPoint', e.target.value)}
            InputLabelProps={{ style: { color: 'white' } }}
            locationfields={locationfieldsEnabled ? 1 : 0}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            id='startOutSkirts'
            required
            margin='dense'
            label={translate('startOutSkirts')}
            variant='outlined'
            fullWidth
            value={form?.startOutSkirts || ''}
            onChange={e => handleChange('startOutSkirts', e.target.value)}
            InputLabelProps={{ style: { color: 'white' } }}
            locationfields={locationfieldsEnabled ? 1 : 0}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            id='endPoint'
            required
            margin='dense'
            label={translate('endPoint')}
            variant='outlined'
            fullWidth
            value={form?.endPoint || ''}
            onChange={e => handleChange("endPoint", e.target.value)}
            InputLabelProps={{ style: { color: 'white' } }}
            locationfields={locationfieldsEnabled ? 1 : 0}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            id='endOutSkirts'
            required
            margin='dense'
            label={translate('endOutSkirts')}
            variant='outlined'
            fullWidth
            value={form?.endOutSkirts || ''}
            onChange={e => handleChange("endOutSkirts", e.target.value)}
            InputLabelProps={{ style: { color: 'white' } }}
            locationfields={locationfieldsEnabled ? 1 : 0}
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={1} style={{borderBottom: '1px solid' + themecolors.backgroundColor}}>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <TextField
                id='locationFrom'
                readOnly
                margin='dense'
                label={translate('locationFrom')}
                variant='outlined'
                fullWidth
                value={locationFromAddress?locationFromAddress:''}
                // value={form.locationFromData && form.locationFromData.latitude? `lat ${form.locationFromData.latitude} , lng ${form.locationFromData.longitude}` : ''}
                onChange={e => handleChange(e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
                locationfields={true?1:0}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
                  <Tooltip title={translate('addLocation')}>
                      <IconButton onClick={e=>handleClickShowPostion('locationFrom',e)}>
                          {<EditIcon  style={{ color: themecolors.backgroundColor }}/>}
                      </IconButton>
                    </Tooltip>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <TextField
                id='locationTo'
                readOnly
                margin='dense'
                label={translate('locationTo')}
                variant='outlined'
                fullWidth
                value={locationToAddress?locationToAddress:''}
                // value={form.locationToData && form.locationToData.latitude? `lat ${form.locationToData.latitude} , lng ${form.locationToData.longitude}` : ''}
                onChange={e => handleChange("startPoint",e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
            //     InputProps={{
            //     endAdornment: (
            //         <InputAdornment position='end'>
            //             <Tooltip title={translate('addLocation')}>
            //                 <IconButton onClick={e=>handleClickShowPostion('locationTo',e)}>
            //                     {<EditIcon  style={{ color: themecolors.backgroundColor}} />}
            //                 </IconButton>
            //                 </Tooltip>
            //         </InputAdornment>
            //     )
            //  }}
             locationfields={true?1:0}
              />
            </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                  <Tooltip title={translate('addLocation')}>
                      <IconButton onClick={e=>handleClickShowPostion('locationTo',e)}>
                          {<EditIcon  style={{ color: themecolors.backgroundColor }}/>}
                      </IconButton>
                    </Tooltip>
            </Grid>
          </Grid> */}
      <Grid container spacing={1} style={{ margin: '5px 0 0 0' }}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            id='cost'
            required
            margin='dense'
            label={translate('cost km')}
            variant='outlined'
            fullWidth
            value={form?.cost || ''}
            onChange={e => handleChange("cost", e.target.value)}
            InputLabelProps={{ style: { color: 'white' } }}
            locationfields={locationfieldsEnabled ? 1 : 0}
            InputProps={{
              style: { padding: 0 },
              endAdornment: (
                <InputAdornment
                  position='start'
                  disableTypography
                >
                  {logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                </InputAdornment>
              )
            }}

          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>

        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} style={{ marginTop: '12px' }}>
          {/* <span>Mileage</span> {"     "}{"."} {'20km'} */}
          {/* <TextField
                        id='mileage'
                        required
                        margin='dense'
                        label={translate('mileage')}
                        variant='outlined'
                        fullWidth
                        value={form?.mileage || ''}
                        onChange={e => handleChange("mileage",e.target.value)}
                        InputLabelProps={{style : {color : 'white'} }}
                        locationfields={true?1:0}
                      /> */}
        </Grid>
      </Grid>
    </div>
  )
}
const TripOutSideDetails = ({
  // form,
  handleChange,
  themecolors,
  translate,
  // mode,
  // classes,
  // handleClickShowPostion,
  metrixData,
  selectedMetrixData,
  selectedMetrixData1,
  logInUser,
  noOfDays
}) => {
  return (
    <div>
      <Grid container spacing={1} style={{ borderBottom: '1px solid' + themecolors.backgroundColor }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id='startEndPoint'
            select
            label={translate('startEndPoints')}
            value={selectedMetrixData1 || ''}
            onChange={(e) => handleChange(e, 'startEndPoint')}
            margin='dense'
            fullWidth
            locationfields={locationfieldsEnabled ? 1 : 0}
            InputProps={{
              classes: {
                root: 'theme-cssOutlinedInput',
                error: 'theme-input-error',
                input: 'theme-input',
                focused: 'theme-cssFocused',
                notchedOutline: 'theme-notchedOutline',
              },
            }}
          >
            {metrixData && metrixData.length ? (
              metrixData.map((option) => (
                <MenuItem key={option.id} value={option}>
                  <strong>
                    {translate(option.startPoint)}{' '}
                    <span>
                      <WorkFlow
                        style={{
                          verticalAlign: 'middle',
                          width: 50,
                          height: 16,
                          fill: '#00000',
                          display: 'inline-block',
                          marginTop: 2,
                        }}
                      />
                    </span>{' '}
                    {translate(option.endPoint)}
                  </strong>
                </MenuItem>
              ))
            ) : (
              <MenuItem value=''>No options available</MenuItem>
            )}
          </TextField>

        </Grid>
        {/* <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                id='locationTo'
                required
                margin='dense'
                label={translate('Location To')}
                variant='outlined'
                fullWidth
                value={locationToData && locationToData.latitude? `lat ${locationToData.latitude} , lng ${locationToData.longitude}` : ''}
                onChange={e => handleChange(e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
                InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <Tooltip title={translate('addLocation')}>
                            <IconButton onClick={e=>handleClickShowPostion('locationTo',e)}>
                                {<EditIcon  style={{ color: themecolors.backgroundColor}} />}
                            </IconButton>
                            </Tooltip>
                    </InputAdornment>
                )
             }}
             locationfields={true?1:0}
              />
            </Grid> */}
      </Grid>
      <Grid container spacing={1} style={{ margin: '5px 0 0 5px' }}>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('startPoint')} </span> : {"  "} {selectedMetrixData?.startPoint} {"  "}
        </Grid>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('startOutSkirts')} </span> : {"  "} {selectedMetrixData?.startOutSkirts} {"  "}
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ margin: '5px 0 0 5px' }}>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('endPoint')}</span> : {"  "} {selectedMetrixData?.endPoint || ''} {"   "}

        </Grid>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('endOutSkirts')}</span> : {"  "} {selectedMetrixData?.endOutSkirts || ''} {"   "}
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ margin: '5px 0 0 5px' }}>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('perDiemCost')}</span> : {"  "} {selectedMetrixData?.perDiemCost || 0} {"   "} {logInUser && logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
          {/* <span>{translate('perDiemCost')}</span> : {"  "} {noOfDays ? selectedMetrixData?.perDiemCost * noOfDays : selectedMetrixData?.perDiemCost || 0} {"   "} {logInUser&&logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'} */}
        </Grid>

        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('foodCost')}</span> : {"  "} {selectedMetrixData?.foodCost || 0} {"   "}    {logInUser && logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
        </Grid>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('No Of Days')}</span> : {"  "} {noOfDays ? noOfDays : 1}
        </Grid>

        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('foodCost+PerDiem')}</span> : {"  "} {noOfDays ? ((selectedMetrixData?.foodCost + selectedMetrixData?.perDiemCost) * noOfDays) : (selectedMetrixData?.foodCost + selectedMetrixData?.perDiemCost) || 0} {"   "}    {logInUser && logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ margin: '5px 0 0 5px' }}>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('deviceMileage')}</span> : {"  "} {selectedMetrixData?.mileage} {"  "} {"km"}
        </Grid>
        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('cost km')}</span> : {"  "} {selectedMetrixData?.cost || 0} {"   "} {logInUser && logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ margin: '5px 0 0 5px' }}>

        <Grid item xs={6} sm={6} md={5} lg={5}>
          <span>{translate('totalCost')}</span> : {"  "} {(selectedMetrixData?.cost || 0) + (noOfDays ? ((selectedMetrixData?.foodCost + selectedMetrixData?.perDiemCost) * noOfDays) : (selectedMetrixData?.foodCost + selectedMetrixData?.perDiemCost) || 0)}   {"   "} {logInUser && logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
        </Grid>
      </Grid>
    </div>
  )
}