import React from "react";
import { connect } from "react-redux";
import Card from "../../Dashboard/components/Card/CardPremium";
import { MotionList } from "../../Dashboard";
import { ReactComponent as MovingIcon } from './../../../assets/dashboard/van-moving.svg'
import { ReactComponent as IdlingIcon } from './../../../assets/dashboard/idling.svg'
import { ReactComponent as TowingIcon } from './../../../assets/dashboard/tow-truck.svg'
import { ReactComponent as ParkingIcon } from './../../../assets/dashboard/parking.svg'
import { ReactComponent as GPSNotUpdated } from './../../../assets/dashboard/gps-not-updated.svg'
import { ReactComponent as StopIcon } from './../../../assets/dashboard/stop.svg'
import { ReactComponent as NotRegistered } from './../../../assets/dashboard/not-registered.svg'
import { ReactComponent as OfflineStatus } from './../../../assets/dashboard/offline.svg'
import Scrollbar from "react-scrollbars-custom";

const ComponentBase = React.memo(({themecolors, gadget, allNotifications, handleItem})=> {
  const { Moving, overSpeed, gpsNotUpdated, Idling, Towing, statusParking, stop, statusOffline, notRegistered }  = allNotifications;
  const total =
      Moving.length +
      overSpeed.length +
      gpsNotUpdated.length +
      Idling.length +
      Towing.length +
      statusParking.length +
      stop.length +
      statusOffline.length
        
  const resArr = {
      total,
      data: [
        {
          value: Moving.length,
          color: '#28a745',
          name: 'moving',
          key: 'Moving',
          image: <MovingIcon width={20} height={20} fill="currentColor" />
        },
        {
          value: Idling.length,
          color: '#e4d354',
          name: 'idling',
          key: 'Idling',
          image: <IdlingIcon width={20} height={20} fill="currentColor" />
        },
        {
          value: Towing.length,
          color: '#f45b5b',
          name: 'towing',
          key: 'Towing',
          image: <TowingIcon width={20} height={20} fill="currentColor" />
        },
        {
          value: statusParking.length,
          color: '#1976d2',
          name: 'parking',
          key: 'statusParking',
          image: <ParkingIcon width={20} height={20} fill="currentColor" />
        },
        {
          value: gpsNotUpdated.length,
          color: '#8d43b4',
          name: 'GPSNotUpdated',
          key: 'gpsNotUpdated',
          image: <GPSNotUpdated width={20} height={20} fill="currentColor" />
        },
        {
          value: stop.length,
          color: '#2c2b79',
          name: 'stop',
          key: 'stop',
          image: <StopIcon width={20} height={20} fill="currentColor" />
        },
        {
          value: statusOffline.length,
          color: '#dc3545',
          name: 'unknown',
          key: 'statusOffline',
          image: <OfflineStatus width={20} height={20} fill="currentColor" />
        },
        {
          value: notRegistered.length,
          color: '#566374',
          key: 'notRegistered',
          name: 'tracker.notRegisteredYet',
          image: <NotRegistered width={20} height={20} fill="currentColor" />
        }
      ]
    }
  return (<Card themecolors={themecolors}>
    <h3 className="cart-title">{gadget.name}</h3>
    <Scrollbar disableTracksWidthCompensation>
      <MotionList
        data={resArr}
        showSelectedUnits={handleItem}
      />
    </Scrollbar>
  </Card>);
});

ComponentBase.displayName = 'ComponentBase';

const mapStateToProps = state => ({
    themecolors: state.themeColors,
    allNotifications: state.allNotifications
})

export const Component = connect(mapStateToProps)(ComponentBase)
Component.displayName = 'ConnectedComponent';
