import React, { useState,useEffect} from 'react'
import Sidebar from './Sidebar'
import Loader from './Loader'
import Footer from './Footer'
import { connect } from 'react-redux'
import {
  fetchDevices,
  fetchDevicesList,
  fetchMoreDevices,
  searchDevices
} from '../Actions/Devices'
import { logInUserInfo, getRoles } from './../Actions/Users'
import { withLocalize } from 'react-localize-redux'
import { ServerInfo } from './../Actions/serverSetting'
import CommonSection from '../Helpers/commonSection'
import Style from 'style-it'
import './index.scss'
import Scrollbar from 'react-scrollbars-custom'
import FeatureRow from '../Components/Features'
import {
  setCookie,
  checkedDevelopmentType,
  defaultDevicesLength,
  checkPrivileges,
} from '../Helpers'
import { getLayer } from './../Actions/Maps'
import instance from '../axios'

const Layout = (props) => {

  const [state,setState] = useState({
    loading: true,
    collapse_sidebar: '',
    collapse: false
  })

  useEffect(()=>{
    if (state.collapse) {
      collapseSidebarClass('sidebar-collapse')
    } else {
      collapseSidebarClass('')
    }
  },[state.collapse])

  useEffect(()=>{
    props.dispatch(fetchMoreDevices(true, defaultDevicesLength))
    if (!props.logInUser.id) {
      const fetchUrl = checkedDevelopmentType()
      instance({
        url: `/api/session`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params:{
          app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
          host: fetchUrl
        }
      })
          .then(userInfoFront => {
            const userPrivileges = null 

            const lang = userInfoFront?.attributes?.lang
           props.setActiveLanguage(lang || 'en')

            if (!userPrivileges) {
              instance({
                url: `/api/privileges`,
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                params:{
                  roleId: userInfoFront.roleId
                }
              })
                  .then(privileges => {
                    const privilegeKeys = []

                    privileges.map(item => {
                      privilegeKeys.push(item.key)
                      return false
                    })
                    localStorage.setItem(
                      'frontPrivileges',
                      JSON.stringify({ ...privilegeKeys })
                    )
                    setState(prevState=>({...prevState, loading: false }))
                    props.dispatch(logInUserInfo(userInfoFront))
                    props.dispatch(fetchDevicesList(userInfoFront))
                    props.dispatch(fetchDevices(userInfoFront))
 
                    if (userInfoFront.userType === -1) {
                      instance({
                        url: `/api/roles`,
                        method: 'GET',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json'
                        },
                        params:{
                          all: true
                        }
                      })
                          .then(role => {
                            props.dispatch(getRoles(role))
                      }).catch(() => {
                        // errorHandler(error, props.dispatch)
                      })
                    } else if (
                      userInfoFront.userType !== 1 &&
                      checkPrivileges('role')
                    ) {
                      instance({
                        url: `/api/roles`,
                        method: 'GET',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json'
                        },
                        params:{
                          userId: userInfoFront.id
                        }
                      })
                            .then(role => {
                              props.dispatch(getRoles(role))
                        }).catch(() => {
                          // errorHandler(error, props.dispatch)
                        })
                    }
              }).catch(() => {
                // errorHandler(error, props.dispatch)
              })
            } 
            if (userInfoFront) {
              instance({
                url: `/api/accounts`,
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                params:{
                  accountId: userInfoFront.accountId
                }
              })
                    .then(server => {
                      const domElements = document.getElementById('favicon')
                      if (
                        server &&
                        server[0].attributes &&
                        server[0].attributes.favIcon &&
                        domElements
                      ) {
                        domElements.setAttribute(
                          'href',
                          server[0].attributes.favIcon
                        )
                      }
                      props.dispatch(ServerInfo(server[0]))
                      props.dispatch(
                        getLayer({
                          id: userInfoFront.map || server[0].map || 'osm'
                        })
                      )
                }).catch(() => {
                  // errorHandler(error, props.dispatch)
                })
            }
        }).catch(() => {
          // errorHandler(error, props.dispatch)
          setCookie('JSESSIONID', 0)

          props.history.push(
            '/logout',
            props.history.location.pathname
          )
        })
    } else {
      setState(prevState=>({...prevState, loading: false }))
    }
  },[])

  useEffect(()=>{
    return()=>{
      props.dispatch(fetchMoreDevices(true))
      props.dispatch(searchDevices({ device: '' }))
    }
  },[])

 const collapseSidebarClass = (value) => {
    setState(prevState=>({ ...prevState, collapse_sidebar: value }))
  }

 const collapseSidebar = () => {
    const w = window.innerWidth
    if (w <= 959) {
      setState(prevState=>({ ...prevState, collapse: !state.collapse }))
    }
  }

 const collapseSidebarWithButton = () => {
    setState(prevState=>({...prevState, collapse: !state.collapse }))
  }

  // componentWillUnmount () {
  //   props.dispatch(fetchMoreDevices(true))
  //   props.dispatch(searchDevices({ device: '' }))
  // }

  // componentWillMount () {
  //   props.dispatch(fetchMoreDevices(true, defaultDevicesLength))
  //   if (!props.logInUser.id) {
  //     const fetchUrl = checkedDevelopmentType()
  //     instance({
  //       url: `/api/session`,
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       params:{
  //         app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
  //         host: fetchUrl
  //       }
  //     })
  //         .then(userInfoFront => {
  //           const userPrivileges = null 

  //           const lang = userInfoFront?.attributes?.lang
  //           props.setActiveLanguage(lang || 'en')

  //           if (!userPrivileges) {
  //             instance({
  //               url: `/api/privileges`,
  //               method: 'GET',
  //               headers: {
  //                 Accept: 'application/json',
  //                 'Content-Type': 'application/json'
  //               },
  //               params:{
  //                 roleId: userInfoFront.roleId
  //               }
  //             })
  //                 .then(privileges => {
  //                   const privilegeKeys = []

  //                   privileges.map(item => {
  //                     privilegeKeys.push(item.key)
  //                     return false
  //                   })
  //                   localStorage.setItem(
  //                     'frontPrivileges',
  //                     JSON.stringify({ ...privilegeKeys })
  //                   )
  //                   this.setState({ loading: false })
  //                   this.props.dispatch(logInUserInfo(userInfoFront))
  //                   this.props.dispatch(fetchDevicesList(userInfoFront))
  //                   this.props.dispatch(fetchDevices(userInfoFront))
 
  //                   if (userInfoFront.userType === -1) {
  //                     // fetch('/api/roles?all=true')
  //                     instance({
  //                       url: `/api/roles`,
  //                       method: 'GET',
  //                       headers: {
  //                         Accept: 'application/json',
  //                         'Content-Type': 'application/json'
  //                       },
  //                       params:{
  //                         all: true
  //                       }
  //                     })
  //                         .then(role => {
  //                           this.props.dispatch(getRoles(role))
  //                     }).catch(() => {
  //                       // errorHandler(error, this.props.dispatch)
  //                     })
  //                   } else if (
  //                     userInfoFront.userType !== 1 &&
  //                     checkPrivileges('role')
  //                   ) {
  //                     instance({
  //                       url: `/api/roles`,
  //                       method: 'GET',
  //                       headers: {
  //                         Accept: 'application/json',
  //                         'Content-Type': 'application/json'
  //                       },
  //                       params:{
  //                         userId: userInfoFront.id
  //                       }
  //                     })
  //                           .then(role => {
  //                             this.props.dispatch(getRoles(role))
  //                       }).catch(() => {
  //                         // errorHandler(error, this.props.dispatch)
  //                       })
  //                   }
  //             }).catch(() => {
  //               // errorHandler(error, this.props.dispatch)
  //             })
  //           } 
  //           if (userInfoFront) {
  //             instance({
  //               url: `/api/accounts`,
  //               method: 'GET',
  //               headers: {
  //                 Accept: 'application/json',
  //                 'Content-Type': 'application/json'
  //               },
  //               params:{
  //                 accountId: userInfoFront.accountId
  //               }
  //             })
  //                   .then(server => {
  //                     const domElements = document.getElementById('favicon')
  //                     if (
  //                       server &&
  //                       server[0].attributes &&
  //                       server[0].attributes.favIcon &&
  //                       domElements
  //                     ) {
  //                       domElements.setAttribute(
  //                         'href',
  //                         server[0].attributes.favIcon
  //                       )
  //                     }
  //                     this.props.dispatch(ServerInfo(server[0]))
  //                     this.props.dispatch(
  //                       getLayer({
  //                         id: userInfoFront.map || server[0].map || 'osm'
  //                       })
  //                     )
  //               }).catch(() => {
  //                 // errorHandler(error, this.props.dispatch)
  //               })
  //           }
  //       }).catch(() => {
  //         // errorHandler(error, this.props.dispatch)
  //         setCookie('JSESSIONID', 0)

  //         this.props.history.push(
  //           '/logout',
  //           this.props.history.location.pathname
  //         )
  //       })
  //   } else {
  //     this.setState({ loading: false })
  //   }
  // }

    if (props.notificationSystem.length > 2) {
      document.body.classList.add('shown-clearall-btn')
    } else {
      document.body.classList.remove('shown-clearall-btn')
    }
    if (props.ServerSetting && props.ServerSetting.attributes && props.ServerSetting.attributes.fontFamily) {
      document.body.classList.remove("ff-1", "ff-2", "ff-3", "ff-4", "ff-5")
      document.body.classList.add(props.ServerSetting.attributes.fontFamily)
    }

    if (!state.loading && props.ServerSetting) {
      return (<>
        <Style>
          {`
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child {
            background: none;           
          }
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child .unit-name {
            font-weight: 700;
          }
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child:before {
            background: ${props.themecolors.textColor};
          }
          .fms-sidebar .list-view {
            color:  ${props.themecolors.textColor}

          }
          .trip-time, .trip-distance {
            border-right: 1px solid ${props.themecolors.themeLightColor}80 
          }
          .page-title {
            border-bottom: 1px solid ${props.themecolors.themeLightColor}80 
          }
          .tracks-list-row {
            border: 1px solid ${props.themecolors.themeLightColor}80   
          }
          .list-view a.list-row {
            color: ${props.themecolors.themeListingInverse};
          }
          .droppable-element,
          .list-row,.list-row-1 {
            background: ${props.themecolors.themeListingColor}77;
            color: ${props.themecolors.themeListingInverse};
          }
          a.list-row.active,
          .list-row.active {
            background: ${props.themecolors.themeLightColor};
            color: ${props.themecolors.themeLightInverse};
          }
          .group-list-row:hover,
          .group-list-row {
            background: ${props.themecolors.themeLightColor};
            color: ${props.themecolors.themeLightInverse};
          }
          .category-item {
            background: ${props.themecolors.themeLightColor}33;
            color: ${props.themecolors.themeLightInverse};
          }
          .main-thumb-view .list-view.list-view-children .checkbox .v-checkbox {
            background: ${props.themecolors.themeDarkColor};
          }
          .main-thumb-view .group-list-row:hover,
          .main-thumb-view .group-list-row {
            background: none;
            color: ${props.themecolors.textColor};
          }
         .list-view a.list-row:hover,
         .list-row:hover,.list-row-1:hover {
          background: ${props.themecolors.themeLightColor};
          color: ${props.themecolors.themeLightInverse};
         }
         .main-thumb-view .fms-sidebar .list-row-condest .list-view-group > li > .list-row:first-child:before {
           background: ${props.themecolors.textColor}
         }
         .feature-section h3 {
          border-bottom: 1px solid ${props.themecolors.themeLightColor};
         }
         .feature-section .item-row:hover {
          color: ${props.themecolors.textColor}aa;
         }
         a {
           color: ${props.themecolors.themeDarkColor};
           cursor: pointer
          }
          .all-attribute-item:before {
            background: ${props.themecolors.textColor};
          }
          .widget-actions,
          .vis-dot.custom-timemachine-item,
          .vis-item.vis-background,
          .vis-item.vis-range {
            background: ${props.themecolors.backgroundColor};
            color: ${props.themecolors.textColor};
            border-color: ${props.themecolors.themeDarkColor};
          }
          .speed-text {
            color: ${props.themecolors.themeDarkColor};
          }
        .unit-tooltip-custom {
          background: ${props.themecolors.backgroundColor};
          color: ${props.themecolors.textColor};
        }
        .tab-title {
          background: ${props.themecolors.themeLightColor};
          color: ${props.themecolors.themeLightInverse};
        }
        .main-unit-modal-bg {
          background: ${props.themecolors.backgroundColor};
          color: ${props.themecolors.textColor};
         }
        .v-tab-heading {
          color: ${props.themecolors.textColor};
        }

        .custom-tab-button {
          min-height: 29px;
          border-radius: 6px 6px 0 0;
          margin: 0 2px;
          background: ${props.themecolors.themeLightColor};
          color: ${props.themecolors.themeLightInverse};
        }
        .custom-tab-button.custom-tab-button-selected,
        .custom-tab-button-selected {
          background: ${props.themecolors.backgroundColor};
          color: ${props.themecolors.textColor};
        }
        
        .custom-tab-button-1 {
          background: ${props.themecolors.themeLightColor}88;
          border-radius: 0
        }
        .custom-tab-button-1.custom-tab-button-selected {
          background: ${props.themecolors.themeLightColor};
          color: ${props.themecolors.themeLightInverse};
        }
        
        .theme-label {
          margin-top: -4px;
          font-size: 12px;
          color: ${props.themecolors.textColor}
        }
        .theme-label-shrink {
          margin-top: 0
        }
        .theme-label-focused {
          color: #fff;
          margin-top: 0;
        }
        .theme-label-error {
          color: ${props.themecolors.error}
        }
        .MuiOutlinedInput-notchedOutline,
        .theme-notchedOutline {
          border-color:  ${props.themecolors.themeLightColor} !important;
          border-radius: 4px
        }
        .theme-input-error .theme-notchedOutline {
          border-color:  ${props.themecolors.error} !important;
        }
        .theme-cssFocused .theme-notchedOutline {
          border-color:  ${props.themecolors.themeLightColor} !important;
        }
        .theme-textarea {
          font-size: 14px;
          padding: 0
        }
        .theme-input-select {
          border-color: ${props.themecolors.themeLightColor};
          color: ${props.themecolors.textColor};
          padding: 0;
          display: flex;
          height: auto
        }

        .theme-input-select.Mui-disabled {
          background-color: ${props.themecolors.themeLightColor}25;
        }
        .theme-input {
          border-color: ${props.themecolors.themeLightColor};
          color: ${props.themecolors.textColor};
          font-size: 12px;
          padding: 5px 14px;
          min-height: 18px
        }
        .MuiFormControl-marginDense {
          margin-top: 8px;
          margin-bottom: 4px;
        }
        .theme-cssOutlinedInput {
          color: inherit
        }
        .custom-table-1 .sensor_type_head + tr td, .custom-table-1 .sensor_type_head tr + th
        .custom-table-1 .sensor_type_head td, .custom-table-1 .sensor_type_head th {
          border-top: 0;
        }
        .custom-table-1 tbody tr:nth-child(Odd) {
          background: ${props.themecolors.themeLightColor}33
        }
        .custom-table-1 td, .custom-table-1 th {
          border-top: 1px solid ${props.themecolors.textColor}80
        }

        .custom-table-1 .sensor_type_head th {
          background: ${props.themecolors.themeLightColor};
          color: ${props.themecolors.themeLightInverse};
          border-radius: 6px;
        }
        .MuiSelect-iconOutlined,
        .menu-list-icon {
          color: ${props.themecolors.textColor};
        }
        .main-menu-list-wrapper {
          background-color: ${props.themecolors.themeLightColor};
        }

        

        .tab_menu_icon {
          color: ${props.themecolors.themeLightColor};
        }
        
        .tab-selected .tab_menu_icon {
          color: ${props.themecolors.themeDarkColor};
        }`}</Style>
          <div
            className={
              state.collapse_sidebar +
              ' ' +
              props.classFromChildren +
              ' ' +
              ' main-view ' +
              (localStorage.getItem('token') &&
              localStorage.getItem('backtoAdmin')
                ? 'shown-bta-bar'
                : '')
            }
          >
            <Style>
              {`
              .msg_body,
              .boxhandle-icon,
              .msg_head {
                background: ${props.themecolors.themeDarkColor};
                color: ${props.themecolors.themeInverse};
              }
              .custom-theme-label {
                font-size: 12px;
                display: block;
                margin-bottom: 5px;
              }
              .custom-theme-input:focus {
                outline: none
              }
              .custom-theme-input {
                color: ${props.themecolors.textColor};
                background: ${props.themecolors.backgroundColor};
                border-color: ${props.themecolors.themeLightColor};
                border-radius: 4px;
                font-size: 12px;
                padding: 5px 14px;
                font-family: inherit;
              }
              .dashboard-page {
                background: ${props.themecolors.dashboardBackgroundColor};
              }
              .leaflet-div-icon {
                background: ${props.themecolors.dashboardBackgroundColor} !important;
              }
        .material-icons {vertical-align: middle; color: inherit}
        .fms-sidebar .filter-row .filter-nav-link {
          color: ${props.themecolors.themeLightInverse};
          background: ${props.themecolors.themeLightColor};
        }
        .MuiPickersDay-day.MuiPickersDay-daySelected,
        .fms-sidebar .filter-row .filter-nav-link.active{
          color: ${props.themecolors.textColor};
          background: ${props.themecolors.backgroundColor};
        }

        .sidebar-bg-layer {
          color: ${props.themecolors.textColor};
          background: ${props.themecolors.backgroundColor};
        }

        .sidebar-collapse-button {
          cursor: pointer;
          color: ${props.themecolors.textColor};
          background: ${props.themecolors.backgroundColor};
        }

        .feature-row {
          color: ${props.themecolors.textColor};
          background: ${props.themecolors.backgroundColor};
        }
        .feature-section ul {
          color: ${props.themecolors.themeListingInverse};
          background: ${props.themecolors.themeListingColor};
        }
        .leaflet-draw-actions a:hover {
          background: ${props.themecolors.backgroundColor};
        }
        .leaflet-draw-actions a {
          border-left: 1px solid rgba(0,0,0,0.1);
          color: ${props.themecolors.textColor};
          background: ${props.themecolors.themeLightColor};
        }
        .dnd-ebuilder .tag,
        .meter-section .device-input {
          color: ${props.themecolors.themeInverse};
          background: ${props.themecolors.themeDarkColor}66;
        }
        .meter-section .device-input-alt {
          background: ${props.themecolors.themeInverse};
          color: ${props.themecolors.themeDarkColor};
        }
      .custom-tabs-root .custom-tabs-scrollable {
        margin-bottom: 0 !important;
      }
      .custom-tabs-root {
        min-height: inherit;
      }

      .custom-indicator {
        background: transparent;
        height: 1px
      }
    .MuiListSubheader-root {
      color: ${props.themecolors.textColor}88;
      background: ${props.themecolors.backgroundColor}
    }
    .custom-menu-paper {
      background: ${props.themecolors.backgroundColor};
      color: ${props.themecolors.textColor}
    }
    .custom-table tbody tr:nth-child(Even) {
      background: ${props.themecolors.themeLightColor}33;
    }
    .notification-head-row {
      background: ${props.themecolors.themeLightColor}88;
    }
    .unread .read-unread-mark {
      background: ${props.themecolors.textColor};
    }
    .unit-image {
      background: ${props.themecolors.themeLightColor}40;
    }
    .unit-image-active {
      background: ${props.themecolors.themeLightColor};
    }
    .theme-input-checkbox {
      padding: 2px;
    }

    .menu-poper {
      opacity: 1;
      z-index: 9;
    }
    .menu-popper-tooltip {
      background: ${props.themecolors.textColor};
      color: ${props.themecolors.backgroundColor};
      font-size: 12px;
    }
    .MuiTooltip-tooltipPlacementTop {
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .with-border:before {
      background: ${props.themecolors.textColor};

    }
    .notification-model-common-section {
      border-left: 1px solid ${props.themecolors.themeLightColor}
    }
    .tab-selected {
      background: ${props.themecolors.themeLightColor};
      color: ${props.themecolors.themeLightInverse} !important;
      opacity:1 !important;
    }


    .custom-tabs-root-border {
      border-bottom: 1px solid ${props.themecolors.themeLightColor}60;
    }

    .tab-container-outer {
      color: ${props.themecolors.textColor};
      background: ${props.themecolors.backgroundColor};
      border-radius: 6px
    }
    .notification-head {
      border-bottom: 1px solid ${props.themecolors.themeLightColor}
    }
    .notification-head,
    .notification-bar .infinite-scroll-component {
      background: ${props.themecolors.backgroundColor};
      color: ${props.themecolors.textColor}
    }
    
    .sidebar-collapse .sidebar-collapse-button {
      background: ${props.themecolors.backgroundColor};
    }
    
    .motion-status-bar {
      background: ${props.themecolors.themeListingColor};
      color: ${props.themecolors.themeListingInverse};
    }
    .motion-status-bar:hover {
      background: ${props.themecolors.themeLightColor};
      color: ${props.themecolors.themeLightInverse};
    }
    
    .react-base-table .MuiTableHead-root {
      box-shadow: none;
      background: ${props.themecolors.themeLightColor}80;
    }
    
    .react-base-table .MuiTableCell-root:not(:last-child) {
      border-right: 1px solid ${props.themecolors.themeLightColor}66
    }
    .react-base-table .react-base-table-wrapper {
      border-left: 1px solid ${props.themecolors.themeLightColor}66
    }

    .ReactTable .rt-noData {
      
      font-size: 1.5em;
      background: transparent;
      font-weight: bold
    }
    .ReactTable .rt-expander:after {
      display: none;
    }
    .ReactTable .rt-expander.-open {
      transform: rotate(90deg);
    }
    .ReactTable .rt-expander svg {
      font-size: inherit;
    }
    .ReactTable .rt-expander {
      color: inherit;
      font-size: 16px;
      transform: rotate(0deg);
      transition: all .3s cubic-bezier(.175,.885,.32,1.275)
    }
    .sidebar,
    .form-title-section,
    .overlayform:before,
    .overlayform {
      background-color: ${props.themecolors.backgroundColor};
      color: ${props.themecolors.textColor};
    }

    .ReactTable-2 .rt-td-1 {
      border-right: 1px solid ${props.themecolors.themeLightColor}66;
      border-bottom: 1px solid ${props.themecolors.textColor}99
    }
    .c-card-alter {
      color: ${props.themecolors.textColor};
      border: 1px solid ${props.themecolors.themeLightColor}66;
    }
    .ReactTable-2:first-child .rt-td-1:first-child {
      border-left: 0;
    }
    .ReactTable .-pagination .-btn {
      color: inherit;
    }
    .leaflet-container a.leaflet-popup-close-button {
      color: ${props.themecolors.textColor}
    }
    .position-box strong {
      background: ${props.themecolors.backgroundColor};
      color: ${props.themecolors.textColor}
    }
    .callout {
      background: ${props.themecolors.backgroundColor};
      color: ${props.themecolors.textColor} 
    }
    .form-contol-label {
       color: ${props.themecolors.textColor}!important
    }
    .report-sidebar-switch:after,
    .callout-body-inner .items .callout-list-item:hover {
      background: ${props.themecolors.themeLightColor}
    }
    .ReactTable .rt-th {
      white-space: normal;
      text-align: left;
      word-break: break-word;
    }
    @media(min-width: 960px) {
      .tab-selected {
        border-left: 4px solid ${props.themecolors.textColor} !important;
      }
    }
    .notification-wizard li .active,.notification-wizard li:hover > span{
      background: ${props.themecolors.themeLightColor};
    }
    .form-control,.rdt .form-control {
      background: ${props.themecolors.backgroundColor};
      color: ${props.themecolors.textColor};
      width: 100%;
      height: 28px;
      border: 1px solid ${props.themecolors.themeLightColor};
      border-radius: 4px;
    }
    .form-control:focus,.rdt .form-control:focus {
      border: 1px solid ${props.themecolors.themeLightColor};
      border-radius: 4px;
      outline: 0;
    }
    .stepper-step.stepper-step-active, .stepper-step.stepper-step-completed {
      color: ${props.themecolors.themeLightColor};
    }

    .rdtPicker {
      display: none;
      position: absolute;
      width: 250px;
      padding: 4px;
      margin-top: 1px;
      z-index: 99999 !important;
      background: ${props.themecolors.backgroundColor};
      color: ${props.themecolors.textColor};
      border: 1px solid ${props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtDay:hover,
    .rdtPicker td.rdtHour:hover,
    .rdtPicker td.rdtMinute:hover,
    .rdtPicker td.rdtSecond:hover,
    .rdtPicker .rdtTimeToggle:hover {
      background:${props.themecolors.themeLightColor};
      cursor: pointer;
    }
    .rdtPicker td.rdtOld,
    .rdtPicker td.rdtNew {
      color: ${props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtActive.rdtToday:before {
      border-bottom-color: ${props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtToday:before {
      content: '';
      display: inline-block;
      border-left: 7px solid transparent;
      border-bottom: 7px solid ${props.themecolors.themeDarkColor};
      border-top-color:${props.themecolors.themeLightColor};
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
    .rdtPicker td.rdtActive,
    .rdtPicker td.rdtActive:hover {
      background-color: #428bca;
      color: #fff;
      text-shadow: 0 -1px 0 ${props.themecolors.themeLightColor};
    }

    .rdtPicker td.rdtDisabled,
    .rdtPicker td.rdtDisabled:hover {
      background: none;
      color:${props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }

    .rdtPicker td span.rdtOld {
      color: ${props.themecolors.themeDarkColor};
    }
    .rdtPicker td span.rdtDisabled,
    .rdtPicker td span.rdtDisabled:hover {
      background: none;
      color: ${props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }
    .rdtPicker th.rdtDisabled,
    .rdtPicker th.rdtDisabled:hover {
      background: none;
      color:${props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }
    .rdtPicker thead tr:first-child th {
      cursor: pointer;
    }
    .rdtPicker thead tr:first-child th:hover {
      background: ${props.themecolors.themeLightColor};
    }

    .rdtPicker tfoot {
      border-top: 1px solid ${props.themecolors.themeDarkColor};
    }
    .rdtPicker button:hover {
      background-color: ${props.themecolors.themeLightColor};
    }
    .rdtPicker th {
      border-bottom: 1px solid ${props.themecolors.themeDarkColor};
    }
    .btn-group.btn-group-toggle.offset-sm-2 {
      display: flex;
      margin-left: 24%;
    }
    label.btn.btn-primary {
      width: 5%;
      margin: 0 8px;
    }
    .form-group {
      margin-bottom: 10px;
    }
    .row{
      margin:10px 0;
    }
    .list-menu-item {
      background-color: ${props.themecolors.backgroundColor};
      color: ${props.themecolors.textColor} !important;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 12px;
    }
      .list-menu-item:hover {
        background-color: ${props.themecolors.themeLightColor};
        color: ${props.themecolors.themeLightInverse};
      }
      .Mui-selected.selected_item:hover,
      .Mui-selected.selected_item {
        background-color: ${props.themecolors.themeLightColor};
        color: ${props.themecolors.themeLightInverse};
      }
      .driver-state-wrap .driver-state {
        background-color: ${props.themecolors.themeLightColor};
        color: ${props.themecolors.themeLightInverse};
      }
      .stat-block {
        background-color: ${props.themecolors.menuActiveBackground};
        color: ${props.themecolors.menuTextColor};
      }
      .common-tab .menu_item_text {
        font-size: 0.8125rem;
      }
      .common-tab {
        letter-spacing: inherit;
        min-height: 40px;
        -webkit-align-items: flex-start !important;
        padding: 6px 12px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 600 !important;
        font-size:0.875rem;
        white-space:normal !important;
        overflow:hidden;
        line-height:1.75;
        opacity:0.7;
        color: ${props.themecolors.textColor};
        
      }
        
      .main-content-page .MuiPaper-root {
        color: ${props.themecolors.textColor};
        border-radius:0px;
        box-shadow:none;
      }
      .theme-range-slider {
        color: ${props.themecolors.themeLightColor};
      }
      .rta__list {
        color: ${props.themecolors.textColor};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      }
      .rta__entity {
        background: ${props.themecolors.backgroundColor};
      }
      .rta__item:not(:last-child) {
        border-bottom-color: ${props.themecolors.themeLightColor};
      }
      .rta__entity--selected {
        color: ${props.themecolors.themeDarkColor};
        background: ${props.themecolors.textColor};
      }
      .react-time-picker__wrapper {
        border-color: ${props.themecolors.themeLightColor};
      }
      .react-time-picker__inputGroup__input {
        color: ${props.themecolors.textColor};
        background: ${props.themecolors.backgroundColor};
      }
    `}
            </Style>
            {props.noSidebar ? null : (
              <Sidebar
                {...props}
                sidebarClose={collapseSidebar}
                collapseSidebarWithButton={collapseSidebarWithButton}
                collapse={state.collapse}
              />
            )}
            <div className='main-view'>
              <Scrollbar>
                <div
                  className={
                    props.classFromChildren
                      ? props.classFromChildren
                      : ''
                  }
                >
                  {props.children}
                </div>
              </Scrollbar>
            </div>
            {props.logInUser &&
              props.logInUser.id &&
              props.location.pathname.indexOf('/monitoring') !== 0 &&
              props.location.pathname.indexOf('/dashboard') !== 0 && (
                <CommonSection
                  location={props.location}
                  showDeviceModal={!props.showDeviceModal}
                />
              )}

            {props.deviceId ? (
              <Feature
                {...props}
                themecolors={props.themecolors}
                currentPosition={props.currentPosition}
                currentDevice={props.currentDevice}
                translate={props.translate}
                logInUser={props.logInUser}
                copyToClipboard={props.copyToClipboard}
              />
            ) : null}
          </div>
          {props.afterView}
            <Footer history={props.history} />
          </>
        
      )
    } else {
      return <Loader />
    }
}

export function Feature (props) {
  return <FeatureRow
        {...props}
        themecolors={props.themecolors}
        currentPosition={props.currentPosition}
        currentDevice={props.currentDevice}
        translate={props.translate}
        logInUser={props.logInUser}
        copyToClipboard={props.copyToClipboard}
      />
}
const mapStateToProps = state => ({
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
  themecolors: state.themeColors,
  notificationSystem: state.notificationSystem
})

export default connect(mapStateToProps)(withLocalize(Layout))
