import React, { useState } from 'react';
import TextField from './../common/TextField';
import Button from '../common/Button';
import Grid from '@mui/material/Grid';
import Notifications from 'react-notification-system-redux';
import instance from '../../axios';
import { errorHandler } from '../../Helpers';
import { FormHelperText } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ChangedPassword = (props) => {
  const [state, setState] = useState({
    currentPawsErrorMgs: false,
    nPawsErrorMgs: false,
    form: {
      newPassword: '',
      reapetNewPassword: '',
      currentPassword: '',
    },
    lengthCheck: false,
    lowerCaseCheck: false,
    upperCaseCheck: false,
    numberCheck: false,
    characterCheck: false,
    confirmPasswordCheck: false,
    isValidPassword: false,
    isVisableUserBtn: false,
  });

  const formReset = () => {
    setState({
      ...state,
      currentPawsErrorMgs: false,
      nPawsErrorMgs: false,
      form: {
        newPassword: '',
        reapetNewPassword: '',
        currentPassword: '',
      },
    });
  };

  const goToLogoutpage = () => {
    localStorage.removeItem('backtoAdmin');
    props.history.push('/logout');
  };

  const formSubmit = () => {
    const { logInUser } = props;
    const { currentPassword, newPassword, reapetNewPassword } = state.form;

    if (
      newPassword.trim().length > 0 &&
      reapetNewPassword.trim().length > 0 &&
      currentPassword.trim().length > 0 &&
      newPassword === reapetNewPassword &&
      state.isValidPassword
    ) {
      instance({
        url: `api/users/${logInUser.id}/password`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: `cpass=${state.form.currentPassword}&npass=${state.form.newPassword}`,
      })
        .then(() => {
          props.dispatch(
            Notifications.success({
              message: props.translate('passwordIsChanged'),
              autoDismiss: 10,
            })
          );
          setState({
            ...state,
            form: {
              newPassword: '',
              reapetNewPassword: '',
              currentPassword: '',
            },
            nPawsErrorMgs: false,
            currentPawsErrorMgs: false,
          });
          goToLogoutpage();
        })
        .catch((error) => {
          errorHandler(error, props.dispatch, errorCallBack);
        });
    } else {
      props.dispatch(
        Notifications.error({
          message: props.translate('emptyField'),
          autoDismiss: 10,
        })
      );
    }
  };

  const errorCallBack = () => {
    setState({ ...state, currentPawsErrorMgs: true });
  };

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));

    if (name === 'newPassword' || name === 'reapetNewPassword') {
      verifyPassword(value, name);
    } else {
      checkRequiredFields();
    }
  };

  const verifyPassword = (value, name) => {
    const newPassword = name === 'newPassword' ? value : state.form.newPassword;
    const reapetNewPassword =
      name === 'reapetNewPassword' ? value : state.form.reapetNewPassword;

    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    const isLengthValid = newPassword.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(newPassword);
    const hasUpperCaseValid = hasUpperCase.test(newPassword);
    const hasNumberValid = hasNumber.test(newPassword);
    const hasSpecialCharValid = hasSpecialChar.test(newPassword);

    setState((prevState) => ({
      ...prevState,
      lengthCheck: isLengthValid,
      lowerCaseCheck: hasLowerCaseValid,
      upperCaseCheck: hasUpperCaseValid,
      numberCheck: hasNumberValid,
      characterCheck: hasSpecialCharValid,
      confirmPasswordCheck: newPassword === reapetNewPassword,
      isValidPassword:
        isLengthValid &&
        hasLowerCaseValid &&
        hasUpperCaseValid &&
        hasNumberValid &&
        hasSpecialCharValid,
    }));

    checkRequiredFields();
  };

  const checkRequiredFields = () => {
    const { newPassword, reapetNewPassword, currentPassword } = state.form;

    if (
      newPassword &&
      reapetNewPassword &&
      reapetNewPassword === newPassword &&
      state.isValidPassword &&
      currentPassword
    ) {
      setState((prevState) => ({
        ...prevState,
        isVisableUserBtn: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisableUserBtn: false,
      }));
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="newPassword"
            label={props.translate('newPassword')}
            type="password"
            placeholder=""
            error={state.nPawsErrorMgs}
            value={state.form.newPassword}
            onChange={handleChange('newPassword')}
            variant="outlined"
            margin="dense"
            fullWidth
          />
          <FormHelperText style={{ color: props.themecolors.textColor }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {state.lengthCheck ? (
                <CheckCircleIcon style={{ color: 'green', fontSize: 14 }} />
              ) : (
                <CancelIcon style={{ color: 'red', fontSize: 14 }} />
              )}
              <span style={{ marginLeft: '5px' }}>
                {props.translate('Length must be atleast 8 characters')}
              </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {state.numberCheck ? (
                <CheckCircleIcon style={{ color: 'green', fontSize: 14 }} />
              ) : (
                <CancelIcon style={{ color: 'red', fontSize: 14 }} />
              )}
              <span style={{ marginLeft: '5px' }}>
                {props.translate('Must contain atleast one Number')}
              </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {state.upperCaseCheck ? (
                <CheckCircleIcon style={{ color: 'green', fontSize: 14 }} />
              ) : (
                <CancelIcon style={{ color: 'red', fontSize: 14 }} />
              )}
              <span style={{ marginLeft: '5px' }}>
                {props.translate('Must contain atleast one Uppercase letter')}
              </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {state.lowerCaseCheck ? (
                <CheckCircleIcon style={{ color: 'green', fontSize: 14 }} />
              ) : (
                <CancelIcon style={{ color: 'red', fontSize: 14 }} />
              )}
              <span style={{ marginLeft: '5px' }}>
                {props.translate('Must contain atleast one Lowercase letter')}
              </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {state.characterCheck ? (
                <CheckCircleIcon style={{ color: 'green', fontSize: 14 }} />
              ) : (
                <CancelIcon style={{ color: 'red', fontSize: 14 }} />
              )}
              <span style={{ marginLeft: '5px' }}>
                {props.translate(
                  'Must contain atleast one Special character (!@#$%^&*(),.?:{}|<></>)'
                )}
              </span>
            </div>
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="reapetNewPassword"
            error={state.nPawsErrorMgs}
            label={props.translate('reNewPassword')}
            type="password"
            placeholder=""
            value={state.form.reapetNewPassword}
            onChange={handleChange('reapetNewPassword')}
            variant="outlined"
            margin="dense"
            fullWidth
          />
          <FormHelperText style={{ color: props.themecolors.textColor }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {state.confirmPasswordCheck ? (
                <CheckCircleIcon style={{ color: 'green', fontSize: 14 }} />
              ) : (
                <CancelIcon style={{ color: 'red', fontSize: 14 }} />
              )}
              <span style={{ marginLeft: '5px' }}>
                {props.translate('Both Passwords must be Equal')}
              </span>
            </div>
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={3} sm={6}>
          <TextField
            error={state.currentPawsErrorMgs}
            id="currentPassword"
            label={props.translate('userCurrentPassword')}
            type="password"
            placeholder=""
            value={state.form.currentPassword}
            onChange={handleChange('currentPassword')}
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
      <br />
      <Button
        variant="contained"
        onClick={formReset}
        style={{ marginRight: 8 }}
      >
        {props.translate('resetButton')}
      </Button>
      <Button
        variant="contained"
        disabled={!state.isVisableUserBtn}
        onClick={formSubmit}
      >
        {props.translate('changePassword')}
      </Button>
    </div>
  );
};

export default ChangedPassword;
