import React, { useState } from 'react';
import Button from './../common/Button';
import TextField from './../common/TextField';
import { SketchPicker } from 'react-color';
import ReactHtmlParser from 'react-html-parser';

export const EditForm = ({vector, updateName, updateColor, updateDescription, updateSpeedLimit, updatePolylineDistance, cancel, save, translate }) => {
  const [colorPicker, setColorPicker] = useState(false);

  const showColorPicker = () => setColorPicker(true);
  const hideColorPicker = () => setColorPicker(false);

  return (
    <form>
      <TextField
        fullWidth
        value={vector.name}
        label={translate('sharedName')}
        margin='dense'
        onChange={e => updateName(e.target.value, vector.id)}
      />
      <p>
        <label>{translate('selectColor')}</label>

        <div
          className='colorPicker-trigger'
          style={{
            background: vector.attributes && vector.attributes.color ? vector.attributes.color : '',
            display: 'inline-block',
            width: 28,
            height: 28,
            marginLeft: 10,
            verticalAlign: 'middle',
            position: 'relative',
            zIndex: 9
          }}
          onClick={showColorPicker}
        >
          {colorPicker && (
            <div
              style={{ position: 'absolute', top: 0, left: '-50%' }}
              onMouseLeave={hideColorPicker}
            >
              <SketchPicker
                color={vector.attributes.color}
                onChangeComplete={updateColor}
              />
            </div>
          )}
        </div>
      </p>
      <TextField
        fullWidth
        value={vector.description}
        label={translate('sharedDescription')}
        rows={5}
        multiline
        margin='dense'
        onChange={e => updateDescription(e.target.value)}
      />
      {/* <div style={{ zIndex: 2, position: 'relative' }}>
        <SearchCalendars
          isMulti={false}
          api='calendars'
          onChange={updateCalendar}
          placeholder={translate('searchCalendar')}
        />
      </div> */}

      <TextField
        type='number'
        fullWidth
        value={vector.attributes.speedLimit}
        label={translate('attributeSpeedLimit') + '(km)'}
        margin='dense'
        onChange={e => updateSpeedLimit(e.target.value)}
      />
      {vector.attributes && vector.attributes.type === 'polyline' ? (
        <TextField
          type='number'
          fullWidth
          value={vector.attributes.polylineDistance}
          label={translate('attributePolylineDistance') + '(km)'}
          margin='dense'
          onChange={e => updatePolylineDistance(e.target.value)}
        />
      ) : null}

      <p>
        <Button onClick={save}>
          {translate('sharedSave')}
        </Button>
        <Button
          type='button'
          variant='outlined'
          style={{ marginLeft: 8 }}
          onClick={cancel}
        >
          {translate('sharedCancel')}
        </Button>
      </p>
    </form>
  );
};

export const EditFormPOI = ({ vector, onChange, cancel, save, translate }) => {
  return (
    <form>
      <TextField
        fullWidth
        value={vector.name}
        label={translate('sharedName')}
        margin='dense'
        onChange={e => onChange('name', e.target.value)}
      />
      <p>
        <TextField
          fullWidth
          value={ReactHtmlParser(vector.description)}
          label={translate('sharedDescription')}
          rows={5}
          multiline
          margin='dense'
          onChange={e => onChange('description', e.target.value)}
        />
      </p>
      <Button onClick={save}>
        {translate('sharedSave')}
      </Button>
      <Button
        type='button'
        normal
        variant='outlined'
        style={{ marginLeft: 8 }}
        onClick={cancel}
      >
        {translate('sharedCancel')}
      </Button>
    </form>
  );
};

