import React, { useState } from 'react'
import List from '@mui/material/List'
import InfiniteScroll from 'react-infinite-scroll-component'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Loader from '../../Layout/Loader'
import moment from 'moment'
import { connect } from 'react-redux'
import Style from 'style-it'
import Collapse from '@mui/material/Collapse'
// import Icon from '@mui/material/Icon'
import CloseIcon from '@mui/icons-material/Close';
import { getDateFormat, getDateTimeFormat, getTimeFormat, setAttributeFormat } from '../../Helpers'
import PinIcon from './../../assets/nav/pin.svg'
import UserIcon from './../../assets/nav/user-two.svg'
import CoordinatesIcon from './../../assets/nav/coordinates.svg'
import ReactHtmlParser from 'react-html-parser'
import Tooltip from '@mui/material/Tooltip'
import {Types} from '../Notifications/Wizard/Screen2'


// class UserEvents extends React.PureComponent {
  const UserEvents =(props)=>{

    const [state, setState] = useState({
      selectedEventId: '',
      selecteditemId: '',
      currentDevice: '',
      prevProps: '',
      open: true
    })

  const selecteItem =(item) => {
    let open = true
    if (window.innerWidth <= 959) {
      open = false
    }
    setState((prevState) => ({ ...prevState, 
        open,
        selectedEventId: item.id
      }))
   props.showDetail(item)
  }
  // const openNotification =() =>{
  //   setState((prevState) => ({ ...prevState,  open: !state.open }))
  // }

  // render () {
//     let title = `${props.translate('unread')} (${props.notificationCount})`;
//     if(props.itemDetails &&props.itemDetails.length) {
//       title =props.translate('sharedLoading')  
//       if(props.itemDetails.length === 1) {
//         if(props.devices &&props.devices.length) {
//           const device =props.devices.find(d => parseInt(d.id) === parseInt(props.itemDetails[0]))
//           title = device && device.name || ""
//         }
//       }
//       else {
//         title = `${props.itemDetails.length} ${props.translate("devicesEvents")}` 
//       }
//     }
// console.info("title",title)
    let data = ''

    if (props.itemPagination.items.length) {
      data =props.itemPagination.items.map(event => (
        <ListRow
          key={event.id}
          data={event}
          {...props}
          selectedEventId={state.selectedEventId}
          serverTimeZone={props.serverTimeZone}
          selecteItem={selecteItem}
        />
      ))
    }

    if (props.loading) {
      return <Loader defaultStyle />
    } else {
      const endMessage =
       props.itemPagination &&props.itemPagination.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {' '}
            -- {props.translate('end')} --{' '}
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {' '}
            {props.translate('notFound')}{' '}
          </p>
        )
      return (
        <Style>
          {`
                
                .infinite-scroll-component .event-list-row {
                  border-bottom: 1px solid ${props.themecolors.textColor}22
                }
                .infinite-scroll-component .event-list-row.active,
                .infinite-scroll-component .event-list-row:hover {
                    background-color: ${props.themecolors&&props.themecolors.themeLightColor}88;
                } 
                
                `}
                <div style={{overflow: 'hidden'}}>
                  
          <div
            id='scrollableDiv'
            className='notification-scroller maintain-height'
          >
            {/* <div className="notification-head-row"><span>{title}</span> {props.notificationCount > 0 ? <a onClick={props.markAsReadAll} style={{color: 'inherit', fontWeight: 'normal', fontSize: '0.85em', marginLeft: 'auto'}} href={null}>{props.translate('Mark all as read')}</a> : null }</div> */}
            <div className="notification-head-row" style={{padding:"10px 0px"}}></div>

            <InfiniteScroll
              dataLength={props.itemPagination.items.length}
              next={() => {
               props.fetchMoreItems()
              }}
              hasMore={props.itemPagination.hasNext}
              loader={<Loader defaultStyle={true} />}
              endMessage={endMessage}
              className='center-height'
              scrollableTarget='scrollableDiv'
            >
              
              <div className='individual-view'>
                <Collapse in={state.open}>
                  <List component='nav'>{data}</List>
                </Collapse>
              </div>
            </InfiniteScroll>
          </div>
          </div>
        </Style>
      )
    }
  // }
}

export function ListRow (props) {
  return (
    <li
      className={
        `event-list-row ${props.data.isRead !== true ? 'unread' : 'read'} ${props.data.id === props.selectedEventId ? 'active' : ''}`
      }
      style={{cursor: 'pointer'}}
      onClick={() => props.selecteItem(props.data)}
    >
      <Tooltip title={`Not read yet`}><span className="read-unread-mark"></span></Tooltip>
      <span className='event-title'>{props.data.name}</span>
      <span className='event-name'>{props.data.deviceName}</span>
      <span className='event-date'>
        {moment(props.data.eventTime)
          .tz(props.serverTimeZone)
          .format(getDateTimeFormat())}
      </span>
    </li>
  )
}

// class UserEventDetails2 extends Component {
  const UserEventDetails2 =(props)=>{
    // const [state, setState] = useState({
    //   currentItemId: '',
    //   isDetailsCompleted: false,
    //   geofenceName: null
    // })
  
  
  // componentWillReceiveProps(n) {
  //   // if(n.data && n.data.positionId !==state.currentItemId) {
  //   //  setState({currentItemId: n.data.positionId})
  //   // }
  // }
  
  // useEffect(() => {
  //   setState((prevState) => ({ ...prevState, currentItemId: n.data.positionId}))
  // }, [])
  
 
  
  /* drawUnit = data => {
    if (data && data.type === 'deviceOverspeed') {
      return data.attributes && data.attributes.speed
        ? ' (' +
            setAttributeFormat('speed', data.attributes.speed) +
            ')'
        : null
    } else if (
      data &&
      ([
        'geofenceEnter',
        'deviceEnterGeofence',
        'deviceExitGeofence',
        'geofenceExit'
      ].includes(data.type) ||
        data.geofenceName)
    ) {
      if (!state.geofenceName && !data.geofenceName) {
        return ''
      } else {
        return ` [  ${state.geofenceName} ${
          data.geofenceName ? data.geofenceName : ''
        } ]`
      }
    }
    return null
  } */

  // render () {
    // let drawUnit =drawUnit(props.data)
    
    let value = '';
    Object.keys(Types).map(key => {
      if(Types[key]&&Types[key].find(type => type.value ===props.data.type)){
          value = key
      }
       })
    return (
      <div>
        {<Card
            style={{
              borderRadius: 10,
              boxShadow: 'none',
              background:props.themecolors.backgroundColor,
              color:props.themecolors.textColor
            }}
          >
            <CardContent
              className='card-content'
              style={{ padding: '6px 12px' }}
            >
              <div className={'notification-card clearfix'}>
                <Typography
                  variant='h6'
                  component='h6'
                  className='event-detail-heading'
                  style={{
                    color: 'inherit',
                    borderBottom: '1px solid ' +props.themecolors&&props.themecolors.themeLightColor,
                    marginBottom: 10
                  }}
                >
                {props.data&&props.data.deviceName ?props.data.deviceName
                  :props.data&&props.data.driverName ?props.data.driverName
                  :props.data&&props.data.vehicleName ?props.data.vehicleName
                  :"-"
                }
                </Typography>

                <CloseIcon
                    className='feature-close-button'
                    onClick={props.hideDetails}
                  />


                <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    <strong>{props.data?.name || ''}</strong>
                    <br />
                    {props.translate(
                      'notification.' +props.data.type
                    )}
                    <br />
                    {props.data.attributes&&props.data.attributes.input ? (props.data.attributes&&props.data.attributes.input === "mileage" ? setAttributeFormat('mileage',props.data.attributes&&props.data.attributes.value):
                   props.data.attributes&&props.data.attributes.input === "hours" ? ((props.data.attributes&&props.data.attributes.value)/(1000*3600)).toFixed(2)+ ' hours':
                   props.data.attributes&&props.data.attributes.input === "date" ? moment(props.data.attributes&&props.data.attributes.value).tz(props.serverTimeZone).format(getDateFormat()): null)
                    :null}
                    {/* drawUnit && drawUnit.length ? drawUnit : null */}
                  </span>
                  <div style={{ margin: 'auto' }} />
                  <span
                    style={{
                      fontSize: 12,
                      color: 'inherit',
                      whiteSpace: 'nowrap'
                    }}
                    className='event-detail-date'
                  >
                    {moment(props.data&&props.data.eventTime)
                      .tz(props.serverTimeZone)
                      .format(getDateFormat())}
                    <br />
                    {moment(props.data&&props.data.eventTime)
                      .tz(props.serverTimeZone)
                      .format(getTimeFormat())}
                  </span>
                </div>

                {(value === 'digital' ||props.data?.type ==='workMode') ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>{props.translate('status')}{' : '}</strong>
                      {props.data&&props.data.attributes&&props.data.attributes.status}
                      <br />
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'analog' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>{props.translate('range')}{' : '}</strong>
                      {props.data.attributes&&props.data.attributes.minLimit + ' - ' +props.data.attributes&&props.data.attributes.maxLimit}<br />
                    <strong style={{ paddingRight: 10 }}>{props.translate('sharedValue')}{' : '}</strong>
                      {props.data.attributes&&props.data.attributes.value}<br />
                    <strong style={{ paddingRight: 10 }}>{props.translate('status')}{' : '}</strong>
                      {props.data.attributes.logic + '-Range'}<br />
                    {props.data&&props.data.geofenceName?<><strong style={{ paddingRight: 10 }}>{props.translate('geofence')}{' : '}</strong>
                      {props.data&&props.data.geofenceName}<br /></>:null}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}

                {value === 'ecodriving' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>{props.translate('range')}{' : '}</strong>
                    {props.data.attributes&&props.data.attributes.minLimit}{props.translate('g')} {' - '} {props.data.attributes&&props.data.attributes.maxLimit}{props.translate('g')}<br />
                    <strong style={{ paddingRight: 10 }}>{props.translate('sharedValue')}{' : '}</strong>
                    {props.data.attributes&&props.data.attributes.value}{props.translate('g')}<br />
                    <strong style={{ paddingRight: 10 }}>{props.translate('tolerance')}{' : '}</strong>
                    {props.data.attributes&&props.data.attributes.tolerance}{props.translate('g')}<br />
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'movement' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    {props.data.attributes.maxLimit && <><strong style={{ paddingRight: 10 }}>{props.translate('range')}{' : '}</strong>
                    {setAttributeFormat ('speed',props.data.attributes.minLimit)} {' - '} {setAttributeFormat ('speed',props.data.attributes.maxLimit)}
                      <br /></>}
                    {props.data.attributes.value && <><strong style={{ paddingRight: 10 }}>{props.translate('sharedValue')}{' : '}</strong>
                      {setAttributeFormat ('speed',props.data.attributes.value)}
                      <br /></>}
                    {props.data.attributes.tolerance && <><strong style={{ paddingRight: 10 }}>{props.translate('tolerance')}{' : '}</strong>
                    {setAttributeFormat ('speed',props.data.attributes.tolerance)}
                      <br /></>}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'other' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    {(props.data.attributes.newValue !== null) && <><strong style={{ paddingRight: 10 }}>{props.translate('newValue')}{' : '}</strong>
                      {props.data.attributes.newValue}<br /></>}
                    {(props.data.attributes.oldValue !== null) && <><strong style={{ paddingRight: 10 }}>{props.translate('oldValue')}{' : '}</strong>
                      {props.data.attributes.oldValue}<br /></>}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'fuel' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    {(props.data?.attributes?.newValue !== null) && <><strong style={{ paddingRight: 10 }}>{props.translate('newValue')}{' : '}</strong>
                      {setAttributeFormat ('fuel',props.data?.attributes?.newValue)}<br /></>}
                    {(props.data?.attributes?.oldValue !== null) && <><strong style={{ paddingRight: 10 }}>{props.translate('oldValue')}{' : '}</strong>
                      {setAttributeFormat ('fuel',props.data?.attributes?.oldValue)}<br /></>}
                    {/* {(props.data?.attributes?.tolerance !== null) && <><strong style={{ paddingRight: 10 }}>{props.translate('tolerance')}{' : '}</strong>
                      {props.data?.attributes?.tolerance}{props.translate('g')}<br /></>} */}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'customAlert' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    {(props.data?.attributes?.ps_trafficFileNumber) && <><strong style={{ paddingRight: 10 }}>{props.translate('ps_trafficFileNumber')}{' : '}</strong>
                      {props.data?.attributes?.ps_trafficFileNumber}<br /></>}
                    {(props.data?.attributes?.ps_plateNumber) && <><strong style={{ paddingRight: 10 }}>{props.translate('ps_plateNumber')}{' : '}</strong>
                      {props.data?.attributes?.ps_plateNumber}<br /></>}
                    {(props.data?.attributes?.ps_brand) && <><strong style={{ paddingRight: 10 }}>{props.translate('ps_brand')}{' : '}</strong>
                      {props.data?.attributes?.ps_brand}<br /></>}
                    {(props.data?.attributes?.ps_model) && <><strong style={{ paddingRight: 10 }}>{props.translate('ps_model')}{' : '}</strong>
                      {props.data?.attributes?.ps_model}<br /></>}
                    {(props.data?.attributes?.ps_state) && <><strong style={{ paddingRight: 10 }}>{props.translate('ps_state')}{' : '}</strong>
                      {props.data?.attributes?.ps_state}<br /></>}
                    {(props.data?.attributes?.ps_color) && <><strong style={{ paddingRight: 10 }}>{props.translate('ps_color')}{' : '}</strong>
                      {props.data?.attributes?.ps_color}<br /></>}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {props.data?.address && 
                (<Typography
                  style={{
                    fontSize: 12,
                    color: 'inherit',
                    marginBottom: 10,
                    display: 'flex'
                  }}
                  className='event-detail-address'
                >
                  <strong style={{ paddingRight: 6 }}>
                    <svg fill='currentColor' width={14} height={14}>
                      <use xlinkHref={`${PinIcon}#icon`} />
                    </svg>
                  </strong>
                  {props.data?.address ? <span>{ReactHtmlParser(`${props.data?.address}`)}</span> : 'N/A'}
                </Typography>)
                }
                {props.data.coordinate && 
                (<Typography
                  className='detail-right-section'
                  style={{
                    fontSize: 12,
                    color: 'inherit',
                    marginBottom: 10,
                    display: 'flex'
                  }}
                >
                  <strong style={{ paddingRight: 6 }}>
                    <svg fill='currentColor' width={14} height={14}>
                      <use xlinkHref={`${CoordinatesIcon}#icon`} />
                    </svg>
                  </strong>
                  {props.data.coordinate ? <span>
                    <span>
                      {setAttributeFormat(
                        'latitude',
                       props.data.coordinate ?props.data.coordinate.split(',')[0] : ""
                      )}
                      ,
                    </span>
                    <span>
                      {setAttributeFormat(
                        'longitude',
                       props.data.coordinate ?props.data.coordinate.split(',')[1] : ""
                      )}
                    </span>
                  </span> : 'N/A'}
                </Typography>)}
                {props.data&&props.data.driverName &&props.data.driverName!=="N/A" ?
                  (<Typography
                    style={{
                      fontSize: 12,
                      color: 'inherit',
                      marginBottom: 10,
                      display: 'flex'
                    }}
                    className='event-detail-driver'
                  >
                    <strong style={{ paddingRight: 6 }}>
                      <svg fill='currentColor' width={14} height={14}>
                        <use xlinkHref={`${UserIcon}#icon`} />
                      </svg>
                    </strong>
                    <span>{props.data.driverName || 'Unknow Driver'}</span>
                  </Typography>):null}
              </div>
            </CardContent>
          </Card>
         /*  : (
          <Card
            style={{
              borderRadius: 10,
              boxShadow: 'none',
              background:props.themecolors.backgroundColor,
              color:props.themecolors.textColor
            }}
          >
            <CardContent className='card-content'>
              <div className={'notification-card event-detail-empty-heading'}>
                <Typography
                  variant='h6'
                  component='h6'
                  style={{ color: 'inherit', textAlign: 'center' }}
                >
                  {props.translate('notFound')}
                </Typography>
              </div>
            </CardContent>
          </Card>
        ) */}
      </div>
    )
  // }
}

const mapStateToProps = state => ({
  notificationCount: state.notificationCount,
  themecolors: state.themeColors,
  logInUser: state.logInUsers
})

export const UserEventDetails = connect(mapStateToProps)(UserEventDetails2)
export const UserWiseEvents = connect(mapStateToProps)(UserEvents)
