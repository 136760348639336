import React from 'react'
import { FormControlLabel, Grid } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '../../Components/common/Checkbox'
import Table from '../../Components/common/tableWithBackEndPagination'
import MenuItem from '../../Components/common/MenuItem'
import Menu from '../../Components/Menu/CommonMenu'
import Button from '../../Components/common/Button'
import { checkPrivileges } from '../../Helpers'
import Loader from '../../Layout/Loader'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ExportImportMenu from '../../Components/Maps/ExportImportMenu';

export const MaintenanceHistoryForm = ({
    data,
    translate,
    setPagination,
    isDataRecieved,
    // histFormVisible,
    // openCreateFrom,
    imagePopup,
    handleChangeRowsPerPage,
    handleChangePage,
    SearchItem,
    SearchVehicle,
    handleChangeFilter,
    handleClickFilter,
    handleCloseFilter,
    countChecked,
    statusFilter,
    anchorEl,
    onRemove,
    mode,
    vehicles,
    // areas,
    switchMode,
    themecolors,
    editHistoryFrom,
    deviceRelatedData,
    dueActive,
    createExpense,
    logInUser,
    expenseForm,
    searchVehicleId,
    // searchAreaId,
    // SearchArea,
    importFunc
  }) => {
    return (
      <div>
     {
    //  !histFormVisible &&(
     <Grid container style={{ height: '100%' }}>
        <Grid item sm={12} xs={12}>
            {isDataRecieved ?(<Table
              rows={data && data.data || []}
              pagination={setPagination(data)}
              handleChangeRowsPerPage={(n)=>handleChangeRowsPerPage(n,'maintenanceHist')}
              handleChangePage={(n)=>handleChangePage(n,'maintenanceHist')}
              SearchItem={(n)=>SearchItem(n,'maintenanceHist')}
              SearchVehicle={SearchVehicle}
              // SearchArea={SearchArea}
              onEdit={(n,chk)=>editHistoryFrom(n,chk)}
              imagePopup={imagePopup}
              vehicles={vehicles}
              // areas={areas.data}
              expenseForm={expenseForm}
              searchVehicleId={searchVehicleId}
              // searchAreaId={searchAreaId}
              // showInvoice={mode ==='history'?true:false} only for ntsc
              createButton={
                checkPrivileges('serviceCreate') && (
                  <div style={{ marginLeft: 10 }}>
                    <Button onClick={createExpense}>
                      {translate('Create')}
                    </Button>
                  </div>
                )
              }
              onDelete={(n)=>onRemove(n)}
              hasAccessOfDelete={checkPrivileges('serviceDelete')}
              logInUser={logInUser}
              switchMode={
                <>
                <AppBar
                position='static'
                color='inherit'
                className='custom-tabs-root-border'
                style={{ background: 'none', boxShadow: 'none' }}
              >
                <Tabs
                  value={mode}
                  onChange={switchMode}
                  TabIndicatorProps={{
                    style: { background: themecolors.backgroundColor }
                  }}
                  scrollButtons='on'
                  classes={{
                    root: 'custom-tabs-root',
                    flexContainer: 'custom-tabs-maintenance',
                    scrollable: 'custom-tabs-scrollable',
                    indicator: 'custom-indicator',
                    scrollButtons: 'scrollable-buttons'
                  }}
                >
                    {checkPrivileges('service') && (
                    <Tab
                      value={'due'}
                      classes={{
                        root: 'custom-tab-button custom-tab-button-1',
                        selected: 'custom-tab-button-selected'
                      }}
                      label={translate('maintenanceRequest')}
                      style={{ padding: '6px 12px' }}
                    />
                  )}
                   {checkPrivileges('service') &&( 
                    <Tab
                      value={'history'}
                      classes={{
                        root: 'custom-tab-button custom-tab-button-1',
                        selected: 'custom-tab-button-selected'
                      }}
                      label={translate('maintenanceHistory')}
                      style={{ padding: '6px 12px' }}
                    />
                  )}                      
                  {checkPrivileges('maintenance') &&(
                    <Tab
                      value={'showMaintenance'}
                      classes={{
                        root: 'custom-tab-button custom-tab-button-1',
                        selected: 'custom-tab-button-selected'
                      }}
                      label={translate('maintenanceReminder')}
                      style={{ padding: '6px 12px' }}
                    />
                  )}
                </Tabs>
              </AppBar>
                </>
              }
              isDownload={
                (<ExportImportMenu
                  fileName = 'services'
                  checkPrivilege = 'serviceCreate'
                  isMaintenance
                  data = {data && data.data || []}
                  themecolors = {themecolors}
                  importFunc={importFunc}
                  
                />)
              }
              isFilter={
                !dueActive ?
                (<Filter
                countChecked={countChecked}
                anchorEl={anchorEl}
                handleChangeFilter={handleChangeFilter}
                handleClickFilter={handleClickFilter}
                handleCloseFilter={handleCloseFilter}
                translate={translate}
                statusFilter={statusFilter}
                />) : null
              }
              // onDelete={this.removeUser}----
              isEditable={true}
              deviceRelatedData={deviceRelatedData}
              completeIcon={mode ==='due'? true : false}
              maintenanceRequestEdit={mode ==='due'? true : false}
              themecolors={themecolors}
              translate={translate}
              searchable
              isSearchVehicle
              // isSearchArea
              hasAccessOfUpdate={checkPrivileges('serviceUpdate')}
              // hasAccessOfDelete={checkPrivileges('userDelete')}----
              // title={this.props.translate('users')}----------
              rowDefinition={[
                  {
                    id: 'id',
                    numeric: false,
                    disablePadding: false,
                    label: translate('serviceId')
                  },
                  {
                    id: 'vehicleName',
                    numeric: false,
                    disablePadding: false,
                    label: translate('sharedVehicle')
                  },
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: translate('serviceMaintenance')
                  },
                  {
                    id: 'status',//attribute.status
                    numeric: false,
                    disablePadding: false,
                    label: translate('status'),
                    hide: mode==='due'? false : true
                  },
                  {
                    id: 'odometer',//'attributes.cDate',
                    numeric: false,
                    disablePadding: false,
                    label: translate('totalDistance')
                  },
                  {
                    id: 'engineHours',//'attributes.cDate',
                    numeric: false,
                    disablePadding: false,
                    label: translate('reportEngineHours')
                  },
                  {
                    id: 'serviceTime',//'attributes.cDate',
                    numeric: false,
                    disablePadding: false,
                    label: translate('date')
                  },
                  {
                    id: 'files',
                    numeric: false,
                    disablePadding: false,
                    label: translate('sharedFile'),
                    hide: mode==='due'? true : false
                  },
                  // {
                  //   id: 'viewInvoice',
                  //   numeric: false,
                  //   disablePadding: false,
                  //   label: translate('invoice'),
                  //   hide: mode==='due'? true : false
                  // },
                  {
                    id: 'cost',//attribute.cost
                    numeric: false,
                    disablePadding: false,
                    label: translate('cost')
                  }
                ]
            }
              />):<Loader/>}
              {/* {!isDataRecieved && <Loader/>} */}
        </Grid>
      </Grid>
      // )
      }
      </div>
    )
  }
  const Filter = (props) => {
    const html = <div>
    <a
    href={null}
    aria-owns={props.anchorEl ? 'simple-menu' : undefined}
    aria-haspopup='true'
    onClick={props.handleClickFilter}
  >
    <p className='v-tab-heading pull-left' style={{width: 100}}>{props.translate('selected')}{": "}{props.countChecked}</p>
    <ArrowDropDownIcon
    style={{color: '#FFFFFF'}}
    />
  </a>
    <Menu
    id='simple-menu'
    anchorEl={props.anchorEl}
    open={Boolean(props.anchorEl)}
    onClose={props.handleCloseFilter}
  >
    {/* <MenuItem component={FormControlLabel} style={{margin: 0, paddingTop: 0, paddingBottom: 0}}
        control={<Checkbox 
          name='Completed'
          checked={props.statusFilter.history}
          onChange={e=>props.handleChangeFilter('history',e)} 
          canAssign
          canRemove
        />}
        label={props.translate('completed')}
      /> */}
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Required' 
          checked={props.statusFilter.required}
          onChange={e=>props.handleChangeFilter('required',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('due')}</span>}
      />
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Scheduled' 
          checked={props.statusFilter.scheduled}
          onChange={e=>props.handleChangeFilter('scheduled',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('scheduled')}</span>}
      />
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Expired' 
          checked={props.statusFilter.expired}
          onChange={e=>props.handleChangeFilter('expired',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('overdue')}</span>}
      />
  </Menu></div>
  return <div className="v-filter-field">{html}</div>
  }