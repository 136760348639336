import React, { useEffect, useState } from 'react'
import {
  prepareDriverTags,
  applyDriversFilter,
  resetFilters
} from '../../../Actions/Devices'
import Dialog from './../../../Components/common/Dialog'
import MenuItem from './../../../Components/common/MenuItem'
import TextField from './../../../Components/common/TextField'
import Button from './../../../Components/common/Button'
import { connect } from 'react-redux'

// class DriverTagSelector extends React.PureComponent {
  export  const DriverTagSelector = (props) => {

    const [state, setState] = useState({
      tag_1: '',
      tag_2: '',
      tag_3: '',
      tag_4: '',
      tag_5: ''
    })


  useEffect(() => {
    props.dispatch(prepareDriverTags())

  }, [])

  const onChange = (name, value) => {
    setState({
      [name]: value
    })
  }

  const cancelFilter = () => {
    props.cancelFilter()
  }
  const saveFilter = () => {
    const filter = { ...state }

    if (!filter.tag_1) delete filter.tag_1
    if (!filter.tag_2) delete filter.tag_2
    if (!filter.tag_3) delete filter.tag_3
    if (!filter.tag_4) delete filter.tag_4
    if (!filter.tag_5) delete filter.tag_5

    const drivers = props.drivers.filter(item => {
      for (var key in filter) {
        if (
          item.attributes[key] === undefined ||
          item.attributes[key] !== filter[key]
        )
          return false
      }
      return true
    })

    props.dispatch(resetFilters())
    if (drivers.length) {
      const list = drivers.map(v => v.uniqueId)
      const data = []
      props.positions.map(p => {
        if (list.includes(p.attributes.driverUniqueId)) {
          data.push(p.deviceId)
        }
        return null
      })
      if (data.length) {
        props.dispatch(applyDriversFilter(data))
      } else {
        props.dispatch(applyDriversFilter(['lakdsjajlaksdjglkadsjg']))
      }
    } else {
      props.dispatch(applyDriversFilter(['sxskaasd']))
    }
    props.saveFilter()
  }

  // render () {
    const { st1, st2, st3, st4, st5 } = props.userTags
    const { tag_1, tag_2, tag_3, tag_4, tag_5 } = props.tags
    const { translate, driverModal } = props

    return driverModal ? (
      <Dialog
        visable
        onClose={cancelFilter}
        maxWidth='xs'
        title={props.translate('staffFilter')}
        isVisableBtn
        translate
        headerActions={<Button onClick={saveFilter}>{props.translate('sharedSave')}</Button>}
      >
        <div style={{ padding: 15 }}>
          <TextField
            fullWidth
            label={st1 || translate('tag_1')}
            select
            onChange={e => onChange('tag_1', e.target.value)}
            value={state.tag_1}
          >
            <MenuItem value={''}></MenuItem>
            {tag_1 &&
              tag_1.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label={st2 || translate('tag_2')}
            select
            onChange={e => onChange('tag_2', e.target.value)}
            value={state.tag_2}
          >
            <MenuItem value={''}></MenuItem>
            {tag_2 &&
              tag_2.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label={st3 || translate('tag_3')}
            select
            onChange={e => onChange('tag_3', e.target.value)}
            value={state.tag_3}
          >
            <MenuItem value={''}></MenuItem>
            {tag_3 &&
              tag_3.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label={st4 || translate('tag_4')}
            select
            onChange={e => onChange('tag_4', e.target.value)}
            value={state.tag_4}
          >
            <MenuItem value={''}></MenuItem>
            {tag_4 &&
              tag_4.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label={st5 || translate('tag_5')}
            select
            onChange={e => onChange('tag_5', e.target.value)}
            value={state.tag_5}
          >
            <MenuItem value={''}></MenuItem>
            {tag_5 &&
              tag_5.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </Dialog>
    ) : null
  // }
}
const mapStateToProps = state => ({
  positions: state.positions,
  drivers: state.drivers
})
export default connect(mapStateToProps)(DriverTagSelector)
