import React, { useState, useEffect,useRef } from 'react'
import { withLocalize } from 'react-localize-redux'
import { errorHandler } from '../../Helpers'
import Layout from './../../Layout'
import Button from '../../Components/common/Button'
import Notifications from 'react-notification-system-redux'
import { Grid, InputAdornment } from '@material-ui/core'
import Table from '../../Components/common/tableWithBackEndPagination'
import TextField from '../../Components/common/TextField'
import axios, { CancelToken } from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'react-fast-compare'

const DataFormat = (data, logInUser) => {
  return {
    id: data && data.id ? data.id : 0,
    updatedBy: data && data.updatedBy ? data.updatedBy : 0,
    startPoint: data && data.startPoint ? data.startPoint : '',
    startOutSkirts: data && data.startOutSkirts ? data.startOutSkirts : '',
    endPoint: data && data.endPoint ? data.endPoint : '',
    endOutSkirts: data && data.endOutSkirts ? data.endOutSkirts : '',
    mileage: data && data.mileage ? parseFloat(data.mileage) : '',
    perDiemCost: data && data.perDiemCost ? parseFloat(data.perDiemCost) : 0,
    foodCost: data && data.foodCost ? parseFloat(data.foodCost) : '',
    cost: data && data.cost ? parseInt(data.cost, 10) : '',
    parentId: data && data.parentId ? data.parentId : (logInUser && logInUser.id),
    modified: data && data.modified ? data.modified : '',
    created: data && data.created ? data.created : '',
    attributes: data && data.attributes ? data.attributes : {},
  };
};

const Commands = (props) => {
  const dispatch = useDispatch();
  const logInUser = useSelector(state => state.logInUsers); 
  const themecolors = useSelector(state => state.themeColors);
  
  const source = useRef(null); 

  const [state, setState] = useState({
    mode: '',
    initFetch: false,
    currentPage: 1,
    pagesize: 20,
    showTable: true,
    showForm: false,
    form: {},
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 1,
      hasNext: true,
      searchText: ''
    },
    isVisible: false,
    data:null,
    metrixData: [] 
  });

  useEffect(() => {
    if (logInUser && logInUser.id ) {

      fetchData(1, 20);
    }
  }, [logInUser]);


  useEffect(() => {
    if (state.searchText !== '') {
      fetchData(state.currentPage, state.pagesize, true); 
    } else if (state.searchText === '' || undefined) { 
      fetchData(state.currentPage, state.pagesize, false); 
    }
  }, [state.searchText,state.currentPage, state.pagesize,]);


  
  const fetchMoreItems = () => {
    fetchData(state.currentPage, state.pagesize, false);
  };

  const searchItems = (e) => {
    if (source.current) {
      source.current.cancel();
    }
    setState(prevState => ({
      ...prevState,
      searchText: e
    }));
  };

  const fetchData = (page, perPage, reset) => {
    source.current = CancelToken.source();

    const query = 'all=true';
    let searchText = state.searchText ? `&search=${state.searchText}` : '';

    if (reset) {
      setState(prevState => ({
        ...prevState,
        itemPagination: { ...prevState.itemPagination, items: [] }
      }));
    }

    axios({
      url: `/api/workflows/matrix/get?${query}&page=${page}&limit=${perPage}${searchText}`,
      method: 'GET',
      cancelToken: source.current.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      setState(prevState => ({
        ...prevState,
        metrixData: response.data
      }));
    }).catch(error => {
      if (!axios.isCancel(error)) {
        console.error('Error fetching data:', error);
      }
    });
  };

  const handleChange = (name, value) => {
    const updatedForm = {
      ...state.form,
      [name]: value
    };
    setState(prev=>({
      ...prev,
      form: updatedForm
    }))
    checkRequiredFields(updatedForm);
  };

  const checkRequiredFields = (updatedForm) => {
    const { startPoint, endPoint, mileage, cost } = updatedForm;
    if (startPoint && endPoint && mileage && cost) {
      if (state.mode === 'edit') {
        const value = isEqual(state.form,updatedForm);
        setState(prev=>({
          ...prev,
          isVisible: value
        }))
      } else {
        setState(prev=>({
          ...prev,
          isVisible: false
        }))
      }
    } else {
      setState(prev=>({
        ...prev,
        isVisible: true
      }))
    }
  };


  const handleChangeRowsPerPage = (value) => {
    if (source.current) {
      source.current.cancel();
    }
    setState(prevState => ({
      ...prevState,
      pagesize: value,
      currentPage: 1
    }));
  };

  const handleChangePage = (value) => {
    if (source.current) {
      source.current.cancel();
    }
    setState(prevState => ({
      ...prevState,
      currentPage: value
    }));
  };

  const setPagination = user => {
    const { metrixData } = state;
    if (metrixData && metrixData.data && metrixData?.data?.data?.length) {
      let nUser = { ...user };
      let lastPage = nUser.total / state.pagesize;
      let IsFloate = checkFloteNumber(lastPage);
      delete nUser.data
      nUser.pageSize = state.pagesize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  const checkFloteNumber = n => {
    return Number(n) === n && n % 1 !== 0;
  };

  const editMetricsForm = (item) => {
    setState((prev)=>({
      ...prev,
      form: item,
      form2: item,
      mode: 'edit',
      showTable: false,
      showForm: true,
      isVisible: true
    }));
  };

  const openCreateFrom = () => {
    setState((prev)=>({
      ...prev,
      mode: 'create',
      showTable: false,
      showForm: true,
      isVisible: true,
      form: {},
    }))
  };

  const closeCreateFrom = () => {
    setState((prev)=>({
      ...prev,
      showForm: false,
      showTable: true,
      form: {},
      isVisible:false
    }));
  };

  const isSubmit = () => {
    const obj = DataFormat(state.form, logInUser);
    setState((prev)=>({
      ...prev,
      form: {},
      showTable: true,
      showForm: false,
      isVisible:false
    }))
    axios({
      url: state.mode === 'edit' ? `/api/workflows/matrix/${state.form.id}` : `/api/workflows/matrix`,
      method: state.mode === 'edit' ? 'PUT' : 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        dispatch(
          Notifications.success({
            message: state.mode === 'edit' ? props.translate('metricsUpdated') : props.translate('metricsCreated'),
            autoDismiss: 10
          })
        );
        setState((prev)=>({
          ...prev,
          form: {},
          showTable: true,
          showForm: false,
          isVisible:false
        }))
        fetchData(state.currentPage,state.pagesize)
      })
      .catch(error => { errorHandler(error, dispatch) });
  };

  const endMessage =
    state.itemPagination && state.itemPagination.total > 0 ? (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        -- {props.translate('end')} --
      </p>
    ) : (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        {props.translate('notFound')}
      </p>
    );

  return (
    <div>
      <Layout
        {...props}
        endMessage={endMessage}
        openCreateFrom={openCreateFrom}
        classFromChildren='has-padding'
        closeCreateFrom={closeCreateFrom}
        editMetricsForm={editMetricsForm}
        searchItems={searchItems}
        fetchMoreItems={fetchMoreItems}
        itemPagination={{ ...state.itemPagination }}
        {...state}
      >
        <div className='main-content-page'>
          <div
            style={{
              background: themecolors.backgroundColor,
              color: themecolors.textColor,
              borderRadius: 6,
              padding: 16
            }}
          >
            {state.showTable ? (
              <Table
                rows={state.metrixData?.data?.data || []}
                pagination={setPagination(state.metrixData.data || {})}
                handleChangeRowsPerPage={(n) => handleChangeRowsPerPage(n)}
                handleChangePage={(n) => handleChangePage(n)}
                logInUser={logInUser}
                isEditable={false}
                status={false}
                completeMark={false}
                themecolors={themecolors}
                translate={props.translate}
                hasAccessOfCreate={false}
                hasAccessOfUpdate={false}
                hasAccessOfDelete={false}
                metrics
                rowDefinition={[
                  {
                    id: 'startPoint',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('startPoint')
                  },
                  {
                    id: 'startOutSkirts',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('startOutSkirts')
                  },
                  {
                    id: 'endPoint',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('endPoint')
                  },
                  {
                    id: 'endOutSkirts',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('endOutSkirts')
                  },
                  {
                    id: 'mileage',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('Mileage (km)')
                  },
                  {
                    id: 'perDiemCost',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('perDiemCost')
                  },
                  {
                    id: 'foodCost',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('foodCost')
                  },
                  {
                    id: 'cost',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('cost km')
                  },
                  {
                    id: 'created',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('createdTime')
                  }
                ]}
              />
            ) : null}
            {state.showForm ? (
              <CreateMetixForm
                closeCreateFrom={closeCreateFrom}
                form={state.form}
                handleChange={handleChange}
                translate={props.translate}
                isVisible={state.isVisible}
                isSubmit={isSubmit}
                mode={state.mode}
                logInUser={logInUser}
              />
            ) : null}
          </div>
        </div>
      </Layout>
    </div>
  );
};

const CreateMetixForm = ({
  form,
  handleChange,
  translate,
  isSubmit,
  isVisible,
  mode,
  logInUser,
  closeCreateFrom
}) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 className='page-title'>{translate('Metrics')}</h4>
        <Button onClick={closeCreateFrom}>{translate('sharedBack')}</Button>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='startPoint'
            required
            margin='dense'
            label={translate('startPoint')}
            variant='outlined'
            fullWidth
            value={form?.startPoint || ''}
            onChange={e => handleChange('startPoint', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='startOutSkirts'
            required
            margin='dense'
            label={translate('startOutSkirts')}
            variant='outlined'
            fullWidth
            value={form?.startOutSkirts || ''}
            onChange={e => handleChange('startOutSkirts', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='endPoint'
            required
            margin='dense'
            label={translate('endPoint')}
            variant='outlined'
            fullWidth
            value={form?.endPoint || ''}
            onChange={e => handleChange('endPoint', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='endOutSkirts'
            required
            margin='dense'
            label={translate('endOutSkirts')}
            variant='outlined'
            fullWidth
            value={form?.endOutSkirts || ''}
            onChange={e => handleChange('endOutSkirts', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='mileage'
            required
            margin='dense'
            label={translate('Mileage km')}
            variant='outlined'
            fullWidth
            value={form?.mileage || ''}
            type='number'
            onChange={e => handleChange('mileage', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='perDiemCost'
            required
            margin='dense'
            label={translate('perDiemCost')}
            variant='outlined'
            fullWidth
            type='number'
            value={form?.perDiemCost || ''}
            onChange={e => handleChange('perDiemCost', e.target.value)}
            InputProps={{
              style: { padding: 0 },
              endAdornment: (
                <InputAdornment
                  position='start'
                  disableTypography
                >
                  {logInUser?.attributes && logInUser?.attributes?.currencyLabel || 'AED'}
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='foodCost'
            required
            margin='dense'
            label={translate('foodCost')}
            variant='outlined'
            fullWidth
            type='number'
            value={form?.foodCost || ''}
            onChange={e => handleChange('foodCost', e.target.value)}
            InputProps={{
              style: { padding: 0 },
              endAdornment: (
                <InputAdornment
                  position='start'
                  disableTypography
                >
                  {logInUser?.attributes && logInUser?.attributes?.currencyLabel || 'AED'}
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='cost'
            required
            margin='dense'
            label={translate('cost km')}
            variant='outlined'
            fullWidth
            type='number'
            value={form?.cost || ''}
            onChange={e => handleChange('cost', e.target.value)}
            InputProps={{
              style: { padding: 0 },
              endAdornment: (
                <InputAdornment
                  position='start'
                  disableTypography
                >
                  {logInUser?.attributes && logInUser?.attributes?.currencyLabel || 'AED'}
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ margin: '5px 15px 0 0' }}>
        <Grid item sm={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size='small'
            onClick={() => isSubmit()}
            disabled={isVisible}
          >
            {mode === 'edit' ? translate('Update') : translate('Create')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default (withLocalize(Commands));
