import React, { useState } from 'react';
import SimpleModalWrapped from '../common/Modal';
import TextField from '../common/TextField';
import Attributes from '../Attributes/attributesModal';
import Grid from '@mui/material/Grid';
// import { timeZonesTypes } from './../Attributes/timezone';
import { useSelector } from 'react-redux';

const AddUserModal = (props) => {
  const [state, setState] = useState({
    at_modal_visable: false
  });

  const addAttributes = () => {
    setState(prevState => ({
      ...prevState,
      at_modal_visable: !prevState.at_modal_visable
    }));
  };

  const groups = useSelector((state) => state.groups);

  // const searchFromZoneList = async (inputValue) => {
  //   if (!inputValue) {
  //     return [];
  //   }
  //   let result = timeZonesTypes?.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  //   return result.map(item => {
  //     return { id: item.key, key: item.key, label: item.label, value: item.key };
  //   });
  // };

  // const defaultZones = timeZonesTypes.slice(0, 10).map(item => ({
  //   id: item.key,
  //   key: item.key,
  //   label: item.label,
  //   value: item.key
  // }));

  let name = null;
  if (props.form.groupId && groups && groups.length) {
    const g = groups.find(e => e.id === props.form.groupId);
    if (g) {
      name = g.name;
    }
    console.warn(name);
  }

  return (
    <div>
      <SimpleModalWrapped
        visable={true}
        isButtonVisable={true}
        title={'resources'}
        formSubmit={props.formSubmit}
        modalControle={props.modalControle}
        buttonText={props.buttonText}
        btnCloseText='sharedBack'
        btnCancelNotShow
        isVisableBtn={props.isVisableUserBtn}
        notShowAttributesBtn
        elevation={props.viewScreen === 'edit' ? 0 : 2}
        isLoading={props.isLoading}
        content={
          <div>
            <Grid container spacing={2}>
              <Grid item xl={3} md={4} sm={6} xs={6}>
                <TextField
                  id='name'
                  label={props.translate('sharedName')}
                  type='text'
                  required
                  placeholder=''
                  value={props.form.name || ''}
                  onChange={props.handleChange('name')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        }
      />
      {state.at_modal_visable && (
        <Attributes
          onCloseModal={addAttributes}
          itemAttributes={props.itemAttributes}
          changeAttribute={props.changeAttribute}
          formSubmit={props.formSubmit}
          componentType={'group'}
          isHaveAccess={props.isHaveAccess}
        />
      )}
    </div>
  );
};

export default AddUserModal;
