import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import axios from 'axios'
import Layout from './../../Layout'
import Grid from '@mui/material/Grid'
import instance from '../../axios'
import Table from '../../Components/common/tableWithBackEndPagination'
import Loader from '../../Layout/Loader'
const CancelToken = axios.CancelToken
let source=''
class Invoice extends Component {
  constructor (props) {
    super(props)
    this.state = {
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      showStats:false,
      selectedAreaId:'',
      selectedvehicleId:'',
      to:'',
      from:'',
      loader:false,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
    }
  }

  componentWillUnmount () {
    this.setState({
      assignMode: '',
      currentPage: 1,
      pagesize: 50,
      selectedvehicleId:'',
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    })
  }

  componentWillMount () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
        }
      )
    }
  }

  componentWillReceiveProps(n){
    if (
        n.logInUser &&
        n.logInUser.id &&
        this.state.initFetch === false
      ) {
        this.setState(
          {
            initFetch: true
          },
          () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage,
              this.state.pagesize
            )
          }
        )
      }
  }

  fetchMoreItems = () => {
    let pagination=true
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize,
      pagination
    )
  }

  searchItems = text => {
    this.setState(
      {
        searchText: text
      },
      () => {
        this.fetchData(this.props.logInUser, 1, this.state.pagesize)
      }
    )
  }
  // fetchInvoices = (text, allInvoices) => {
  //   const u = allInvoices&&allInvoices.filter(u =>
  //     (u.id + u.name).toLowerCase().includes(text.toLowerCase())
  //   )
  //   this.setState(
  //     {
  //       invoices:u
  //     }
  //   )
  // }

  fetchData = (logInUser, page, perPage, pagi,reset) => {
    source = CancelToken.source()
    let url =''
    let searchText = this.state.searchText
    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }

    let items = this.state.itemPagination.items
    if (reset) {
      items = []
    }
    // fetch(`/api/commands/get?userId=${logInUser.id}&all=true&page=${page}&limit=${perPage}${searchText}`)
    //http://localhost:8090/api/services/invoice/get?page=1&limit=20&search=&vehicleId=1&vehicleId=2&from=2020-06-14T00:00:00.000Z&to=2022-06-14T00:00:00.000Z
    url = this.state.itemSearch?`/api/services/invoice/get?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`:`/api/services/invoice/get?page=${page}&limit=${perPage}${this.state.to}${this.state.from}${this.state.selectedAreaId}${this.state.selectedvehicleId}`
    
   instance({
      url: url,
      method: 'GET',
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

        .then(res => {
          this.setState(
            { loader:false,
              invoices:pagi?items.concat(res.data):res.data,
              isDataRecieved:true,
              itemPagination: {
                ...res,
                items:pagi?items.concat(res.data):res.data
              },
              currentPage: res.hasNext ? res.page + 1 : res.page
            }
          )
    }).catch(() => {
      // errorHandler(error, this.props.dispatch)
    })
  }
  setPagination = user => {
    
    const { itemPagination } = this.state
    if (itemPagination && itemPagination.items && itemPagination.items.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / this.state.pagesize
      let IsFloate = this.checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pageSize = this.state.pagesize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }

  handleChangeRowsPerPage =(value,mode) => {
    if(source){
      source.cancel()
    }
    if(mode==='invoice'){
      this.setState(
        {
          pagesize: value,
          currentPage: 1
        },
        () => {
            this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize)
          })
    }
  }

  handleChangePage = (value,mode) => {
    if(source){
      source.cancel()
    }
    if(mode==='invoice'){
      this.setState(
        {
          currentPage: value
        },
        () => {
            this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            )
          })
    }
  }

  SearchItem = (searchValue,mode) => {
    if(source){
      source.cancel()
    }
    this.setState(
      {
        itemSearch: searchValue,
        currentPage: 1,
      },
      () => {
      if(mode==='invoice'){
        this.fetchData(this.props.logInUser,this.state.currentPage, this.state.pagesize) 
      }}
    )
  }

  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }

  toggleFilterForm = ()=>{
      this.setState({
        showStats:!this.state.showStats
      })
  }

  submitSideBarSearch= (data)=>{
      this.setState({
          loader:true,
          to:`&to=${data.to}`,
          from:`&from=${data.from}`,
          selectedAreaId:data.selectedAreaId,
          selectedvehicleId:data.selectedvehicleId
      }, ()=>{
            this.fetchData(this.props.logInUser,this.state.currentPage,this.state.pagesize)
      })
  }
  render () {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          -- {this.props.translate('end')} --
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {this.props.translate('notFound')}
        </p>
      )
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            classFromChildren='has-padding'
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            modeEmpty={this.modeEmpty}
            toggleFilterForm={this.toggleFilterForm}
            submitSideBarSearch={this.submitSideBarSearch}
            {...this.state}
          >
            <div className='main-content-page'>
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                }}
              >
             {<Grid container style={{ height: '100%' }}>
        
        <Grid item sm={12} xs={12}>
            {this.state.isDataRecieved ? (<Table
              rows={this.state.itemPagination&&this.state.itemPagination.items || []}
              pagination={this.setPagination(this.state.itemPagination&&this.state.itemPagination)}
              handleChangeRowsPerPage={(n)=>this.handleChangeRowsPerPage(n,'invoice')}
              handleChangePage={(n)=>this.handleChangePage(n,'invoice')}
              SearchItem={(n)=>this.SearchItem(n,'invoice')}
              logInUser={this.props.logInUser}
              themecolors={this.props.themecolors}
              translate={this.props.translate}
              viewInvoice
              invoiceLabel
              searchable
              invoiceModule
              rowDefinition={[
                {
                  id: 'invoiceNumber',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('invoiceNumber')
                },
                {
                  id: 'bookingId',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('bookingId')
                },
                {
                  id: 'stationName',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('stationName')
                },
                {
                  id: 'stationCode',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('stationCode')
                },
                {
                  id: 'stationCity',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('stationCity')
                },
                {
                  id: 'serviceDate',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('serviceDate')
                },
                {
                    id: 'viewInvoice',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('invoice'),
                    hide: false
                  },
              ]}
              />):
              <Loader/>}
            </Grid>
        </Grid>}
              </div>
            </div>
          </Layout>
        </div>
      )
   
  }
}


const mapStateToProps = state => ({
  themecolors: state.themeColors,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)((withLocalize(Invoice)))
