import React, { useEffect, useState } from "react";
import instance from "../axios";

export const RemoteItems = React.memo(({ type, children }) => {
    
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);

    const getData = async () => {
      setIsLoaded(false);
      const response = await instance({
        method: 'GET',
        url: `/api/itemgroups/get?itemType=${type}&limit=-1`,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        }
      })
      setData(response.data);
      setIsLoaded(true)
    }

    useEffect(() => {
      getData();
    }, [type]);

  return children({data, isLoaded});
});

RemoteItems.displayName = 'RemoteItems'