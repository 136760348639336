import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '../../../Components/common/Button'
import Tooltip from '../../../Components/common/Tooltip'

const IconForm = (props) => {
  const [state, setState] = useState({
    category: {
      name: props.selectedDevice.category || 'default',
      id: props.selectedDevice.category || 'default'
    },
    images: [],
    applied: false,
    currentId: 0,
  })

  const onChange =(categoryp)=> {
    setState((prevState)=>({
      ...prevState,
      category:categoryp
    }))
    props.logo_handelChanges(categoryp)
  }

  const onSubmit = ()=> {
    props.advenceSectionOneformSubmit({
      category: state.category.name
    })
  }
  const handleUpdate = (device) => {
    setState((prevState)=>({
      ...prevState,
      currentId:device?.id,
      category:{
        name:device?.category,
        id:device?.category
      }
    }))

  };
  useEffect(() => {
    if (props.selectedDevice.id && props.selectedDevice.id !== state.currentId) {
      handleUpdate(props.selectedDevice);
    }
  }, [props.selectedDevice]);

  const {images}=props

  return (
    <div>
      <div
        style={{ textAlign: 'right', padding: '16px' }}
        className='clearfix'
      >
         {/* <div style={{float: 'right'}}>{this.props.isVisablBtn && (
            <Button
              disabled={!this.props.isVisableASOneBtn}
              onClick={this.onSubmit}
              size='small'
            >
              {this.props.translate('sharedUpdate')}
            </Button>
          )}</div> */}
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('icon')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        <Grid container spacing={1}>
          {images?.map((item, i) => {
            return (
              <Tooltip key={i} title={props.translate(item.name)}>
                <Grid
                  style={{ cursor: 'pointer' }}
                  item
                  onClick={() => onChange(item)}
                >
                  <Paper
                    style={{
                      textAlign: 'center',
                      alignItems: 'center'
                    }}
                    className={
                      item.name === (state.category.name || 'default')
                        ? 'unit-image-active'
                        : 'unit-image'
                    }
                  >
                    <Avatar
                      alt=''
                      src={'/assets/category/default/' + item.name + '.svg'}
                      style={{
                        padding: 10,
                        margin: 'auto',
                        height: 80,
                        width: 80
                      }}
                    />
                  </Paper>
                </Grid>
              </Tooltip>
            )
          })}
          {/* <Tooltip title={"Upload"}>
                <Grid
                  style={{ cursor: 'pointer',padding:4}}
                  onClick={this.triggerInputClick} // Trigger file input click when Grid is clicked
                >
                  <Paper
                    style={{
                      textAlign: 'center',
                      alignItems: 'center'
                    }}
                    className={'unit-image'}
                  >
                  <PublishIcon style={{
                        padding: 10,
                        margin: 'auto',
                        height: 80,
                        width: 80,
                        fill: '#fff' 
                      }}/>
                  </Paper>
                <input
                    type="file"
                    accept="image/svg+xml"
                    ref={this.uploadInputRef} // Reference to the file input
                    style={{ display: 'none' }} // Hide the file input
                    onChange={this.handleImageChange}
                  />
                </Grid>
              </Tooltip> */}
        </Grid>
          <Grid
            style={{ cursor: 'pointer', textAlign: 'right', marginTop: 10 }}
            item
            md
          >
            {props.isVisablBtn && (
              <Button
                disabled={!props.isVisableASOneBtn||props.isLoading}
                onClick={onSubmit}
                size='small'
              >
                {props.isLoading? "loading":props.translate('sharedUpdate')}
              </Button>
            )}
          </Grid>
      </div>
    </div>
  )

}
export default IconForm