import React from 'react'
import AsyncSelect from '../common/asyncSelect'
import { connect } from 'react-redux'
import axios from 'axios'
import instance from '../../axios'

const CancelToken = axios.CancelToken
let source

let u = {
  users: '/api/users/list',
  devices: '/api/devices/list',
  groups: '/api/groups/get',
  calendars: '/api/calendars/get',
  maintenance: '/api/maintenance/get'
}

class SearchItems extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      resultMessage: '',
      defaultOptions: [],
      defaultOptionsAllList: [],
      value: [],
      fristFetch: props.api === 'devices' ? false : true
    }
  }
  componentWillMount () {
    if (this.props.api === 'devices' && this.props.devices2.length) {
       this.setState(
        {
          resultMessage: '',
          defaultOptions: this.props.devices2.slice(0, 100),
          defaultOptionsAllList: this.props.devices2.slice(0, 100),
          value: this.props.value || [],
          fristFetch: false
        },
        () => {
          let defaultOptions
          if (this.props.value && this.props.value.length) {
            this.props.value.forEach(item => {
              defaultOptions = this.state.defaultOptions.filter(df => {
                return df.id !== item.id
              })
            })
            this.setState({
              defaultOptions
            })
          }
        }
      )
    } else {
      this.setState({
        resultMessage: '',
        defaultOptions: [],
        defaultOptionsAllList: [],
        value: this.props.value || [],
        fristFetch: true
      })
    }
  }
  // componentWillReceiveProps(){
  //   this.fetchList()
  // }
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }

    this.setState({
      resultMessage: '',
      defaultOptions: [],
      defaultOptionsAllList: [],
      value: [],
      fristFetch: ''
    })
  }
  onChange = (name, value, option) => {
    let defaultOpt
    let v = value
    if (this.props.isMulti === false) {
      if (value) {
        v = { ...value, value: value.value || value.id }
        if (
          this.state.defaultOptionsAllList &&
          this.state.defaultOptionsAllList.length &&
          value
        ) {
          defaultOpt = this.state.defaultOptionsAllList.filter(
            df => df.id !== value.id
          )
        }
      }
    }
    if (option.action === 'clear') {
      defaultOpt = this.state.defaultOptionsAllList
    }
    if (option.action === 'select-option') {
      if (
        this.state.defaultOptions &&
        this.state.defaultOptions.length &&
        value &&
        value.length
      ) {
        value.map(item => {
          defaultOpt = this.state.defaultOptions.filter(df => df.id !== item.id)
          return null
        })
      }
    }
    if (option.action === 'remove-value') {
      let { removedValue } = option
      if (removedValue) {
        let newValue = {
          id: removedValue.id || removedValue.value,
          label: removedValue.label
        }
        defaultOpt = this.state.defaultOptions
        defaultOpt.push(newValue)
      }
    }
    this.setState({ value: v, defaultOptions: defaultOpt }, () => {
      this.props.onChange(v, option)
    })
  }

  fetchList = () => {
    if (source) {
      source.cancel()
    }
    source = CancelToken.source()

    this.setState(
      {
        fristFetch: false
      },
      () => {
        //employe call for default select option
        instance({
          method: 'GET',
          url: `${
            u[this.props.api || 'devices']
          }?limit=50&search=${''}&userId=${this.props.logInUser.id}`,
          cancelToken: source.token,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => {
          let { data } = response
          let defaultOptions = this.state.value
          let result = []
          data.map(item => {
            if(!((this.props.exclude || []).includes(item.id))){
              result.push(
                {
                  ...item,
                  label: item.label || item.name,
                  value: item.id
                }
              )
            }
            return null
          })
          if (response.hasNext) {
            result.push({
              value: null,
              isDisabled: true,
              label: 'Search for more.'
            })
          }
          if (defaultOptions && defaultOptions.length) {
            defaultOptions.map(item => {
              result.map(campItem => {
                if (item.id === campItem.value || campItem.id) {
                  result.splice(result.indexOf(campItem), 1)
                }
                return null
              })
              return null
            })
          }
          this.setState({
            defaultOptions: result,
            defaultOptionsAllList: result
          })
        }).catch(() => {
          // errorHandler(error, this.props.dispatch)
        })
      }
    )
  }
  searchList = async inputValue => {
      if (!inputValue) {
      if (
        this.state.defaultOptions &&
        this.state.defaultOptions.length &&
        this.state.value &&
        this.state.value.length
      ) {
        let options = [...this.state.defaultOptions]
        for (let item in this.state.value) {
          let defaultOpt = this.state.defaultOptions.filter(
            df => df.id === item.id
          )
          if (defaultOpt) {
            delete options[item]
          }
        }
        return options
      }
      return this.state.defaultOptionsAllList
    }
    if (source) {
      source.cancel()
    }

    source = CancelToken.source()

    let response = await instance({
      method: 'GET',
      url: `${
        u[this.props.api || 'devices']
      }?limit=50&search=${inputValue}&all=true&userId=${
        this.props.logInUser.id
      }`,
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    // let json = await response
    let defaultOptions = this.state.value
    let resultFinal= []
    let result = (await response.data)

          result.map(item => {
            if(!((this.props.exclude || []).includes(item.id))){
              resultFinal.push(
                {
                  ...item,
                  label: item.label || item.name,
                  value: item.id
                }
              )
            }
            return null
          })
    if (resultFinal.length === 0) {
      this.setState({
        resultMessage: 'No option found'
      })
    } else {
      this.setState({
        resultMessage: ''
      })
    }

    if (response.hasNext) {
      resultFinal.push({
        value: null,
        isDisabled: true,
        label: 'Search for more.'
      })
    }
    if (defaultOptions && defaultOptions.length) {
      defaultOptions.map(item => {
        resultFinal.map(campItem => {
          if (item.id === campItem.value || campItem.id) {
            resultFinal.splice(resultFinal.indexOf(campItem), 1)
          }
          return null
        })
        return null
      })
    }
    return await resultFinal
  }

  render () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.fristFetch
    ) {
      this.fetchList()
    }
    return (
      <AsyncSelect
        value={this.state.value}
        {...this.props}
        placeholder={this.props.placeholder || 'Search Units'}
        resultMessage={this.state.resultMessage}
        defaultOptions={this.state.defaultOptions}
        handleChange={this.onChange}
        loadOptions={this.searchList.bind()}
      />
    )
  }
}
const mapStateToProps = state => ({
  devices2: state.devices2,
  logInUser: state.logInUsers,
  themecolors: state.themeColors
})
export default connect(mapStateToProps)(SearchItems)
