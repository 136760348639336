import React, {  Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import isEqual from 'react-fast-compare'
import RefreshIcon from '@mui/icons-material/Refresh'
import Tooltip from '../common/Tooltip'
import Button from '../common/Button'
import { checkPrivileges, errorHandler } from '../../Helpers'
import Style from 'style-it'
import { logInUserInfo } from './../../Actions/Users'
import Notifications from 'react-notification-system-redux'
// import axios from 'axios'
import instance from '../../axios'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
// const CancelToken = axios.CancelToken
// let source

// const mapStateToProps = (state) => {
//   return {
//     themecolors: state.themeColors
//   }
// }
const Permissions = (props) => {
  const [state, setState] = useState({
    isVisableAddBtn: false,
    isLoadUnit: true,
    isLoadUser: true,
    deviceCount: {
      limit: 0,
      remaningLimit: 0,
    },
    userCount: {
      limit: 0,
      remaningLimit: 0,
    },
    form: {
      readonly: '',
      deviceReadonly: '',
      limitCommands: '',
      deviceLimit: '',
      userLimit: '',
      token: '',
      expirationTime: '',
    },
  });


  useEffect(() => {
    // source = instance.CancelToken.source();

    if (props.logInUser) {
      instance({
        method: 'GET',
        url: `/api/devices/count?userId=${props.logInUser.id}`,
        // cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            deviceCount: response,
            isLoadUnit: false,
          }));
        })
        .catch(() => {
          setState((prevState) => ({
            ...prevState,
            isLoadUnit: false,
          }));
        });

      instance({
        method: 'GET',
        url: `/api/users/count?userId=${props.logInUser.id}`,
        // cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            userCount: response,
            isLoadUser: false,
          }));
        })
        .catch(() => {
          setState((prevState) => ({
            ...prevState,
            isLoadUser: false,
          }));
        });
    }
    setState((prevState) => ({
      ...prevState,
      form: { ...props.logInUser },
    }))

    
    // return () => {
    //   if (source) {
    //     source.cancel();
    //   }
    // };
  }, [props.logInUser]);

  const formReset = () => {
    setState((prevState) => ({
      ...prevState,
      form: { ...props.logInUser },
      isVisableAddBtn: false,
    }));
  };

  const formSubmit = () => {
    let { logInUser } = props;
    const { form } = state;
    logInUser = { ...form };
    setState((prevState) => ({
      ...prevState,
      isVisableAddBtn: false,
    }));
    instance({
      url: `api/users/${logInUser.id}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...logInUser,
      },
    })
      .then((user) => {
        props.dispatch(logInUserInfo(user));
        props.dispatch(
          Notifications.success({
            message: props.translate('permissionsInfoIsUpdated'),
            autoDismiss: 10,
          })
        );
        setState((prevState) => ({
          ...prevState,
          isVisableAddBtn: false,
        }));
      })
      .catch((error) => {
        errorHandler(error, props.dispatch);
      });
  };

  const handleChange = (name) => (event) => {
    if (name === 'expirationTime') {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: event.toISOString(),
        },
      }));
      checkRequiredFields();
    } else {
      const { target } = event;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      if (target.type === 'number') {
        value = parseInt(value);
        if (value === 0) {
          value = '';
        }
      }
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: value,
        },
      }));
      checkRequiredFields();
    }
  };

  const copyTokenToClipboard = () => {
    const { form } = state;
    const hostUrl = props.ServerSetting?.attributes?.hostUrl || '';
    const token = form.token;

    const tokenUrl = `${hostUrl}/loginasuser?&${btoa(token)}`;

    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = tokenUrl;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);

    props.dispatch(
      Notifications.success({
        message: props.translate('Token copied to clipboard'),
        autoDismiss: 10,
      })
    );
  };

  const generateToken = () => {
    let symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let newToken = '';
    for (let i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        token: newToken,
      },
    }));
    // checkRequiredFields();  
  };

  const setExpirationTime = (e) => {
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        expirationTime: null,
      },
    }));
    checkRequiredFields();
  };

  useEffect(() => {
    checkRequiredFields()
  },[state.form.token])

  const checkRequiredFields = () => {
    let value = !isEqual(state.form, props.logInUser);
    setState((prevState) => ({
      ...prevState,
      isVisableAddBtn: value,
    }));
  };

  // {console.error("logInUser======", props.logInUser)}
  const { logInUser } = props;
  return (
    <Style>
      {`
        .row-value {
          font-size: 14px;
        }
        .row-label {
          font-size: 10px;
          font-weight: bold;
          min-width: 100px;
          display: inline-block;
        }
      `}
      <div>
        <Grid container spacing={2}>
          {logInUser.userType === -1 &&
            renderOwnerField(
              state,
              props,
              handleChange,
              setExpirationTime,
              generateToken,
              copyTokenToClipboard
            )}
          {logInUser.userType !== -1 &&
            renderAdminField(
              state,
              props,
              handleChange,
              generateToken,
              copyTokenToClipboard
            )}
        </Grid>
        <br />
        {checkPrivileges('userUpdate') && (
          <Fragment>
            <Button
              variant='contained'
              onClick={formReset}
              style={{ marginRight: 8 }}
            >
              {props.translate('resetButton')}
            </Button>
            <Button
              variant='contained'
              disabled={!state.isVisableAddBtn}
              onClick={formSubmit}
            >
              {props.translate('sharedSave')}
            </Button>
          </Fragment>
        )}
      </div>
    </Style>
  );
};

const mapStateToProps = (state) => {
  return {
    // logInUser: state.logInUser,
    ServerSetting: state.ServerSetting,
  };
};

export default connect(mapStateToProps)(Permissions);


function renderOwnerField (
  state,
  props,
  handleChange,
  setExpirationTime,
  generateToken,
  copyTokenToClipboard
) {

  const selectMe = element => {
    var doc = document,
      text = element.target,
      range,
      selection
    if (doc.body.createTextRange) {
      range = document.body.createTextRange()
      range.moveToElementText(text)
      range.select()
    } else if (window.getSelection) {
      selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(text)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  return (
    <Fragment>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span className='row-label'>
              {props.translate('userExpirationTime')}
            </span>
            <span className='row-value'>
              {state.form.expirationTime
                ? new Date(`${state.form.expirationTime}`).toString()
                : null}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className='row-label'>
              {props.translate('userDeviceLimit')}
            </span>
            {state.isLoadUnit && <span className='row-value'>...</span>}
            {!state.isLoadUnit && <span className='row-value'>
              { state.form.deviceLimit === -1
                ? props.translate('unlimited')
                : state.form.deviceLimit}
            </span>}
          </Grid>
          <Grid item xs={12}>
            <span className='row-label'>
              {props.translate('userUserLimit')}
            </span>
            {state.isLoadUser && <span className='row-value'>...</span>}
            {!state.isLoadUser && <span className='row-value'>
              {state.form.userLimit === -1
                ? props.translate('unlimited')
                : state.form.userLimit}
            </span>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className='license-card clearfix'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label style={{ fontSize: 10 }}>
                {props.translate('userToken')}
              </label>
              <Tooltip title={props.translate('getToken')}>
                <Button
                  className='pull-right button-white'
                  onClick={generateToken}
                >
                  {<RefreshIcon />}
                </Button>
              </Tooltip>
              <Tooltip title={props.translate('copyLink')}>
                <Button
                style={{marginRight:"5px"}}
                  className='pull-right button-white'
                  onClick={copyTokenToClipboard}
                >
                  {<FileCopyOutlinedIcon />}
                </Button>
              </Tooltip>
              <p onClick={e => selectMe(e)}>
                <code style={{ wordBreak: 'break-all' }}>
                  {state.form.token &&
                    props.ServerSetting &&
                    props.ServerSetting.attributes &&
                    (props.ServerSetting.attributes.hostUrl +
                      '/loginasuser?&' +
                      btoa(state.form.token) ||
                      '')}
                </code>
              </p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Fragment>
  )
}

function renderAdminField (state, props, handleChange, generateToken, copyTokenToClipboard) {
  // const { classes } = props
  const selectMe = element => {
    var doc = document,
      text = element.target,
      range,
      selection
    if (doc.body.createTextRange) {
      range = document.body.createTextRange()
      range.moveToElementText(text)
      range.select()
    } else if (window.getSelection) {
      selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(text)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
      <Grid item xs={12} >
        <div style={{display:'flex', flexWrap:'wrap'}}>     
          <div style={{padding:15, borderRadius:10}}>
                <strong>{props.translate('units')}</strong><br />
                <div style={{marginTop:4}}>
                   <p style={{fontSize:11}}> {props.translate('childLimit')}</p>
                </div>
                <div style={{display:'flex',marginTop:8}} >
                <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.reserveLimit: <>...</>}</p>
                      <span>{props.translate('childReserveLimit')}{'  '}</span>
                  </div>
                <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.reserveLimit - state.deviceCount.childUnitCount: <>...</>}</p>
                      <span>{props.translate('childRemainigLimit')}{'  '}</span>
                  </div>
                </div>
                <div>
                   <p style={{fontSize:11}}>{props.translate('created')}</p>
                </div>
              <div style={{display:'flex', marginTop:8}}>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.unitCount: <>...</>}</p>
                      <span>{props.translate('totalOwnCreatedUnits')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.childUnitCount: <>...</>}</p>
                      <span>{props.translate('totalChildUnitsCreated')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                    <p>{!state.isLoadUnit ?state.deviceCount.totalCount : <>...</>}</p>
                    <span>{props.translate('usersUnitsTotal')}{'  '}</span>
                  </div>
              </div>
               <div>
                  <p style={{fontSize:11}}>{props.translate('totalLimit')}</p>
                </div>
              <div style={{display:'flex',marginTop:8}} >
                <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.limit: <>...</>}</p>
                      <span>{props.translate('usersUnitsLimit')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.reserveLimit: <>...</> }</p>
                      <span>{props.translate('reserveUnitsLimit')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.remaningLimit: <>...</>}</p>
                      <span>{props.translate('remainingUnitsLimit')}{'  '}</span>
                  </div>
                </div>
            </div>

           <div style={{padding:15, borderRadius:10}}>
                <strong>{props.translate('Users')}</strong>
                <div style={{marginTop:4}}>
                   <p style={{fontSize:11}}> {props.translate('childLimit')}</p>
                </div>
                <div style={{display:'flex'}} >
                  <div className='stat-block' >
                      <p>{!state.isLoadUser ? state.userCount.reserveLimit: <>...</>}</p>
                      <span>{props.translate('childReserveLimit')}{'  '}</span>
                  </div>
                </div>
                <div>
                   <p style={{fontSize:11}}>{props.translate('created')}</p>
                </div>
              <div style={{display:'flex' ,marginTop:8}}> 
                  <div className='stat-block' >
                      <p>{!state.isLoadUser  ? state.userCount.childCount : <>...</>}</p>
                      <span>{props.translate('childCount')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUser  ? state.userCount.grandChildCount : <>...</>}</p>
                      <span>{props.translate('grandChildCount')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                    <p>{!state.isLoadUser ? state.userCount.totalCount : <>...</> }</p>
                    <span>{props.translate('usersAccountTotal')}{'  '}</span>
                  </div>
              </div>
              <div>
                   <p style={{fontSize:11}}>{props.translate('totalLimit')}</p>
                </div>
              <div style={{display:'flex'}} >
                <div className='stat-block' >
                      <p>{!state.isLoadUser ? state.userCount.limit : <>...</>}</p>
                      <span>{props.translate('usersAccountLimit')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUser ? state.userCount.reserveLimit+state.userCount.childCount  : <>...</>}</p>
                      <span>{props.translate('reserveLimit')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUser ? state.userCount.remaningLimit: <>...</>}</p>
                      <span>{props.translate('remainingLimit')}{'  '}</span>
                  </div>
                </div>
            </div>   
            </div>
        </Grid>
       </Grid>
      
      <Grid item xs={12} md={6}>
        <div
          className='license-card clearfix'
          style={{
            padding: 16,
            color: 'inherit',
            borderRadius: 0,
            boxShadow: 'none'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label style={{ fontSize: 10 }}>
                {props.translate('userToken')}
              </label>
              <Tooltip title={props.translate('getToken')}>
                <Button
                  className='pull-right button-white'
                  onClick={generateToken}
                >
                  {<RefreshIcon />}
                </Button>
              </Tooltip>
              <Tooltip title={props.translate('copyLink')}>
                <Button
                     style={{marginRight:"5px"}}
                  className='pull-right button-white'
                  onClick={copyTokenToClipboard}
                >
                  {<FileCopyOutlinedIcon />}
                </Button>
              </Tooltip>
              <p onClick={e => selectMe(e)}>
                <code style={{ wordBreak: 'break-all' }}>
                  {state.form.token &&
                    props.ServerSetting &&
                    props.ServerSetting.attributes &&
                    (props.ServerSetting.attributes.hostUrl +
                      '/loginasuser?&' +
                      btoa(state.form.token) ||
                      '')}
                </code>
              </p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Fragment>
  )
}

