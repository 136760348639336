import React from 'react';
// import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
// import Button from './Button';
import Button from '../common/Button'
import { Adjust, CheckCircle, FiberManualRecord, Info, Email, GetApp } from '@mui/icons-material';
import { StepConnector } from '@mui/material';

const useStyles =  ((theme) => ({
  
  button: {
    marginTop: theme&&theme.spacing&&theme.spacing(1),
    marginRight: theme&&theme.spacing&&theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme&&theme.spacing&&theme.spacing(2),
    textAlign: 'right'
  },
  resetContainer: {
    padding: theme&&theme.spacing&&theme.spacing(3),
  },
}));

function CircleStepIcon(props) {
const { active, completed, error, ...rest } = props;

return (
    <div {...rest}>
    {completed ? <CheckCircle /> : active ? <Adjust /> : error ? <Info /> : <FiberManualRecord />}
    </div>
);
}


export default function VerticalLinearStepper(props) {
  const styl = useStyles();
  const steps = props.steps || [];
  return (
    <div className="stepper-wrapper">
      <Stepper activeStep={props.activeStep} orientation="vertical" elevation={0} classes={{root: 'stepper'}} connector={<StepConnector classes={{root: 'step-connector', active: 'step-connector-active'}} />}>
        {steps.map((step, index) => (
          <Step key={step.id} >
            <StepLabel StepIconComponent={CircleStepIcon} classes={{root: 'step-label-root', label: 'step-label', active: 'step-label-active', completed: 'step-label-completed', color:"#ffffff"}}>{props.translate(step.title)}</StepLabel>
            <StepContent>
              {props.getStepContent(step.id)}
              {step.errorMsg ? step.errorMsg : null}
              <div 
              // className={classes.actionsContainer}
              style={styl.actionsContainer}
              >
                <div>
                  <div style={props.activeStep === steps.length - 1 && props.reportType && props.download && props.emails ? {display: 'flex', justifyContent: 'center'} : {display:"flex",justifyContent:"flex-end",marginTop:10} }>
                    <Button
                      disabled={props.activeStep === 0}
                      onClick={()=> props.handleBack(step.id)}
                      style={{ ...styl?.button, margin: 5 }}
                    >
                      {props.translate('sharedBack')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={()=> props.handleNext(step.id, '', index)}
                      style={{ ...styl?.button, margin: 5 }}

                    >
                      {(props.activeStep === steps.length - 1 && props.reportType) || (props.activeStep===2 && props.isSubmitBtn) ? props.translate('submit') : props.translate('next')}
                    </Button>
                  </div>
                  {props.activeStep === steps.length - 1 && props.reportType && props.download && props.emails ? <div>
                    <div className='report-sidebar-switch' style={{marginTop: 15}}></div>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={()=> props.handleNext(step.id, 'downloadExcel', index)}
                          // className={classes.button}
                          style={{ ...styl?.button, margin: 4 }}

                        >
                          <GetApp/>
                          {props.translate('Excel')}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={()=> props.handleNext(step.id, 'downloadPdf', index)}
                          // className={classes.button}
                          style={{ ...styl?.button, margin: 4 }}
                          
                        >
                          <GetApp/>
                          {props.translate('PDF')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={()=> props.handleNext(step.id, 'email', index)}
                            // className={classes.button}
                            style={{ ...styl?.button, margin: 4 }}
                          >
                            <Email style={{marginRight: 5}}/>
                            {props.translate('commandSend')}
                          </Button>
                      </div>
                      
                  </div> : null}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
