import React, { useState,useCallback,Fragment } from 'react'
import SimpleModalWrapped from '../common/Modal'
import TextField from '../common/TextField'
import MenuItem from '../common/MenuItem'
//import mapLayerType from '../../lang/en.json'
import InputAdornment from '@mui/material/InputAdornment'
import RefreshIcon from '@mui/icons-material/Refresh'
import EditIcon from '@mui/icons-material/Place'
import CrossIcons from '@mui/icons-material/Remove'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Tooltip from '../common/Tooltip'
//import Attributes from '../Attributes/attributesModal'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import Grid from '@mui/material/Grid'
import { FormHelperText } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


//import Collapse from '@material-ui/core/Collapse';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import {
  coordinateFormatTypes,
  ownerUserType,
  dealerUserType,
  adminUserType,
  serviceProviderUserType,
  adminAccountUserType,
  measurementStandard,
  currencies
} from './userDataTypes'
import './usersModal.scss'
import { checkPrivileges } from '../../Helpers'
import SingleSelect from '../common/SingleSelect'
import { timeZonesTypes } from '../Attributes/timezone'
// import instance from '../../axios'
import Checkbox from '../common/Checkbox'
import { useSelector } from 'react-redux'

const addUserModal=(props)=>{
  const [atModalVisible, setAtModalVisible] = useState(false);
  // const [open, setOpen] = useState(props.viewScreen === 'add');
  // const [resultMessage, setResultMessage] = useState('');
  const [showButton, setShowButton] = useState(props.editOption ? true : false);
  const themecolors=useSelector((state)=>state.themeColors)
  // const logInUsers=useSelector((state)=>state.logInUsers)

  const roleTypes = useSelector((state) =>
    props.viewScreen === 'add' ? state.roles.filter((item) => item.id > 7) : state.roles
  );


  const toggleRoles = useCallback((e) => {
    setShowButton(!showButton);
    return props.handleChange('roleId')(e);
  }, [showButton, props]);

  const addAttributes = useCallback(() => {
    setAtModalVisible(!atModalVisible);
  }, [atModalVisible]);

  const selectMe = useCallback((element) => {
    const doc = document;
    const text = element.target;
    let range, selection;
    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []);

  const searchFromZoneList = useCallback(async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    const result = timeZonesTypes.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    return result.map(item => ({ id: item.key, key: item.key, label: item.label, value: item.key }));
  }, []);

  // const searchAccountsList = useCallback(async (inputValue) => {
  //   if (!inputValue) {
  //     return [];
  //   }
  //   const params = {
  //     accountId: props.logInUser.accountId,
  //     search: inputValue,
  //     limit: 100,
  //     ...(props.logInUser.userType === -1 && { all: true })
  //   };
  //   const response = await instance({
  //     url: `/api/accounts/all`,
  //     method: 'GET',
  //     params
  //   });
  //   const result = response.data.map(item => ({
  //     ...item,
  //     label: item.label || item.name,
  //     value: item.id
  //   }));
  //   if (result.length === 0) {
  //     setResultMessage('No option found');
  //   } else {
  //     setResultMessage('');
  //   }
  //   if (response.hasNext) {
  //     result.push({
  //       value: null,
  //       isDisabled: true,
  //       label: 'Search for more.'
  //     });
  //   }
  //   return result;
  // }, [props.logInUser]);

  // const searchUsersList = useCallback(async (inputValue) => {
  //   if (!inputValue) {
  //     return [];
  //   }
  //   const response = await instance({
  //     url: `/api/users/list`,
  //     method: 'GET',
  //     params: {
  //       accountId: props.form.accountId,
  //       search: inputValue,
  //       limit: 100
  //     }
  //   });
  //   const result = response.data.map(item => ({
  //     ...item,
  //     label: item.label || item.name,
  //     value: item.id
  //   }));
  //   if (result.length === 0) {
  //     setResultMessage('No option found');
  //   } else {
  //     setResultMessage('');
  //   }
  //   if (response.hasNext) {
  //     result.push({
  //       value: null,
  //       isDisabled: true,
  //       label: 'Search for more.'
  //     });
  //   }
  //   return result;
  // }, [props.form.accountId]);

  let defaultZones = []
  timeZonesTypes.map((item) => {
    // if (i <= 9) {
      defaultZones.push({
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key
      })
    // }
    return null
  })
  let userTypeArray = []
  if (props.userType) {
    let { userType } = props
    if (userType === -1) {
      userTypeArray = ownerUserType
    } else if (userType === 2) {
      userTypeArray = adminUserType
    } else if (userType === 3) {
      userTypeArray = adminAccountUserType
    }else if (userType === 4) {
      userTypeArray = serviceProviderUserType
    } else if (userType === 5) {
      userTypeArray = dealerUserType
    } else {
      userTypeArray = []
    }
  }
  const empty = null

  return (
    <div>
      {(props.activeOperation === 'add' || props.activeOperation === 'edit') ?
      (<SimpleModalWrapped
        visable={true}
        title={'sharedRequired'}
        isButtonVisable={true}
        notShowAttributesBtn
        formSubmit={props.formSubmit}
        addAttributes={addAttributes}
        modalControle={props.modalControle}
        buttonText={props.buttonText}
        btnCloseText='sharedBack'
        // isVisableBtn={props.isVisableUserBtn && props.isValidPassword && props.confirmPasswordCheck}
        isVisableBtn={props.form.zoom > 18 || props.form.zoom < 3 || (typeof props.form.zoom !== 'number') || isNaN(props.form.zoom) ? false :props.isVisableUserBtn}
        hasPremissions
        canUpdate={props.canUpdate}
        attributeChangesMessage={props.attributeChangesMessage}
        btnCancelNotShow={props.btnCancelNotShow}
        isLoading={props.isLoading}
        content={
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='name'
                  label={props.translate('sharedName')}
                  type='text'
                  required
                  placeholder=''
                  value={props.form.name || ''}
                  onChange={props.handleChange('name')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='email'
                  error={props.duplicateEntry}
                  label={props.translate('userEmail')}
                  type='text'
                  required
                  placeholder=''
                  value={props.form.email || ''}
                  onChange={props.handleChange('email')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              {props.viewScreen !== 'edit' && (
                <>
                  <Grid item xl={3} sm={6} md={4} xs={12}>
                  
                    <TextField
                      id='password'
                      label={props.translate('userPassword')}
                      type='password'
                      required
                      placeholder=''
                      value={props.form?.password || ''}
                      onChange={props.handleChange('password')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                        <FormHelperText  style={{color:props.themecolors.textColor,display:"flex",flexDirection:'column',gap:"4px"}} >
             <div style={{display:"flex", alignItems:"center"}}>
              {props.lengthCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>{props.translate('Length must be atleast 8 characters')}</span>
              </div> 
             <div style={{display:"flex", alignItems:"center"}}>
             {props.numberCheck?
              <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
              <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
              <span style={{marginLeft:"5px"}}>{props.translate('Must contain atleast one Number')}</span>
              </div> 
             <div style={{display:"flex", alignItems:"center"}}>
             {props.upperCaseCheck?
              <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
              <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
              <span style={{marginLeft:"5px"}}>{props.translate('Must contain atleast one Uppercase letter')}</span>
              </div> 
             <div style={{display:"flex", alignItems:"center"}}>
             {props.lowerCaseCheck?
              <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
              <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
              <span style={{marginLeft:"5px"}}>{props.translate('Must contain atleast one Lowercase letter')}</span>
              </div> 
             <div style={{display:"flex", alignItems:"center"}}>
             {props.characterCheck?
              <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
              <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
              <span style={{marginLeft:"5px"}}>{props.translate('Must contain atleast one Special character (!@#$%^&*(),.?:{}|<></>)')}</span>
              </div> 
          </FormHelperText>
                  </Grid>
                  <Grid item xl={4} lg={4} sm={6} md={4} xs={12}>
                    <TextField
                      id='confirmPassword'
                      label={props.translate('confirmPassword')}
                      type='password'
                      error={
                        props.form.password ===
                        props.form.confirmPassword
                          ? false
                          : true
                      }
                      required
                      placeholder=''
                      value={props.form.confirmPassword}
                      onChange={props.handleChange('confirmPassword')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                     <FormHelperText  style={{color:themecolors.textColor}} >
                  <div style={{display:"flex", alignItems:"center"}}>
                    {props.confirmPasswordCheck?
                      <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                      <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                      <span style={{marginLeft:"5px"}}>{props.translate('Both Passwords must be Equal')}</span>
                    </div> 
              </FormHelperText>
                  </Grid>
                  
                  <Grid item xl={4} lg={4} sm={6} md={4} xs={12}>
                    <TextField
                      id='userType'
                      select
                      required
                      label={props.translate('userType')}
                      value={props.form.userType || ''}
                      onChange={props.handleChange('userType')}
                      // helperText={props.roleTypes.length  ? <span onClick={toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                      margin='dense'
                      fullWidth
                    >
                      <MenuItem value=''>
                        <em>{props.translate('none')}</em>
                      </MenuItem>
                      {userTypeArray.length &&
                        userTypeArray.map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </>
              )}
              {!showButton && roleTypes?.length ?(
                <Grid item xl={4} lg={4}  md={3} sm={6} xs={12} style={{display:'flex', alignItems:'center'}}>
                    <span onClick={(e)=>toggleRoles(e)} style={{cursor: 'pointer'}}>
                     <b> {props.translate('selectCustomRole')}</b></span>
                </Grid>
              ): '' }

              {(checkPrivileges('role'))  && showButton  && (
                   <Grid item xl={4} lg={4} sm={6} md={4} xs={12}>
                      <TextField
                          id='role'
                          select
                          label={props.translate('role')}
                          value={props.form.roleId || ''}
                          onChange={props.handleChange('roleId')}
                          margin='dense'
                          fullWidth
                        >
                          {/* {!props.editOption && 
                            (<MenuItem key={'defaultRole'} value={'defaultRole'} >
                              <em>{props.translate('default')}</em>
                            </MenuItem>
                            )}
                          {!props.editOption && <Divider style={{background:'black'}}/> } */}
                          
                        {roleTypes.length ?
                          roleTypes.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                            {props.translate(option.name)}
                            </MenuItem>
                          )) : <MenuItem value=''>
                          <em>{props.translate('none')}</em>
                          </MenuItem>}
                    </TextField>
                    {!props.editOption ? (<span onClick={(e)=>toggleRoles(e) } style={{cursor: 'pointer'}}><b>{props.translate('useDefaultRole')}</b></span>): ''}
                </Grid>
              )}
              {/* {(checkPrivileges('userCreate') || checkPrivileges('userUpdate')) && (
                <Grid item xl={3} sm={6} md={4} xs={12}>
                  <TextField
                    id='role'
                    select
                    label={props.translate('roleName')}
                    value={props.form.roleId || ''}
                    onChange={props.handleChange('roleId')}
                    // disabled={props.viewScreen === 'edit'}
                    margin='dense'
                    fullWidth
                  >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem> 
                    {props.roleTypes &&
                      props.roleTypes.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {props.translate(option.name)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              )} */}
            </Grid>         
            <h3 className='with-border' style={{marginTop:"10px"}}>
              {props.translate('sharedPreferences')}
            </h3>
            <Grid container spacing={2}>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='phone'
                  label={props.translate('sharedPhone')}
                  type='text'
                  placeholder=''
                  value={props.form.phone || ''}
                  onChange={props.handleChange('phone')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              {props.viewScreen === 'edit' && (
                <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                  <TextField
                    id='map'
                    select
                    label={props.translate('mapTitle')}
                    value={props.form.map || ''}
                    onChange={props.handleChange('map')}                
                    margin='dense'
                    fullWidth
                  >
                    <MenuItem value=''>
                      <em>{props.translate('none')}</em>
                    </MenuItem>
                    {props.form &&
                      props.form.attributes.maps &&
                      props.form.attributes.maps.map(option => (
                        <MenuItem key={option} value={option}>
                          {props.translate(option)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              )}

              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='coordinateFormat'
                  select
                  label={props.translate('settingsCoordinateFormat')}
                  value={props.form.coordinateFormat || ''}
                  onChange={props.handleChange('coordinateFormat')} 
                  margin='dense'
                  fullWidth
                >
                  <MenuItem value=''>
                    <em>{props.translate('none')}</em>
                  </MenuItem>
                  {coordinateFormatTypes.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {props.translate(option.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* <Grid item xl={3} sm={6} md={4} xs={12}>
                <TextField
                  id='poiLayer'
                  label={props.translate('mapPoiLayer')}
                  type='text'
                  placeholder=''
                  value={props.form.poiLayer || ''}
                  onChange={props.handleChange('poiLayer')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid> */}
            </Grid>
            <Grid container spacing={2}>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='latitude'
                  type='number'
                  label={props.translate('positionLatitude')}
                  placeholder=''
                  value={props.form.latitude || ''}
                  onChange={props.handleChange('latitude')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='longitude'
                  type='number'
                  label={props.translate('positionLongitude')}
                  value={props.form.longitude || ''}
                  onChange={props.handleChange('longitude')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='zoom'
                  label={props.translate('serverZoom')}
                  type='number'
                  required
                  placeholder=''
                  error={
                    props.form.zoom > 19 || props.form.zoom < 3
                      ? true
                      : false
                  }
                 helperText={props.translate('Enter Value 3 to 18')}
                  value={props.form.zoom || ''}
                  onChange={props.handleChange('zoom')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <Tooltip title={props.translate('addLocation')}>
                  <IconButton onClick={props.handleClickShowPostion}>
                    {<EditIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {props.form.userType && props.form.userType !== 1 && (
              <>
                {' '}
                <h3 className='with-border'>
                  {props.translate('sharedPermissions')}
                </h3>
                <Grid container spacing={2}>
                  <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        error={false}
                        margin='dense'
                        label={props.translate('userExpirationTime')}
                        variant='outlined'
                        fullWidth
                        minDate={new Date()}
                        value={
                          props.form.expirationTime
                            ? new Date(
                                `${props.form.expirationTime}`
                              ).toString()
                            : null
                        }
                        onChange={props.handleChange('expirationTime')}
                        InputProps={{
                          classes: {
                            root: 'theme-cssOutlinedInput',
                            input: 'theme-input',
                            focused: 'theme-cssFocused',
                            notchedOutline: 'theme-notchedOutline'
                          },
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Tooltip
                                title={props.translate('noLimit')}
                              >
                                <IconButton
                                  onClick={e =>
                                    props.setExpirationTime(e)
                                  }
                                >
                                  {<CrossIcons />}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{
                          classes: {
                            root: 'theme-label',
                            focused: 'theme-label-focused',
                            shrink: 'theme-label-shrink'
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {props.form.expirationTime && (
                      <Tooltip title={props.translate('noLimit')}>
                        <a
                          href={empty}
                          onClick={e => props.setExpirationTime(e)}
                        >
                          no limit
                        </a>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                    <TextField
                      id='deviceLimit'
                      label={props.translate('userDeviceLimit')}
                      type='number'
                      placeholder=''
                      value={props.form.deviceLimit}
                      onChange={props.handleChange('deviceLimit')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                    <TextField
                      id='userLimit'
                      label={props.translate('userUserLimit')}
                      type='number'
                      placeholder=''
                      value={props.form.userLimit}
                      onChange={props.handleChange('userLimit')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <h3 className='with-border'>
              {props.translate('sharedAttributes')}
            </h3>
            <Grid container spacing={2}>
              {/* <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='web.liveRouteLength'
                  label={props.translate(
                    'attributeWebLiveRouteLength'
                  )}
                  placeholder=''
                  type='number'
                  value={
                    (props.form.attributes &&
                      props.form.attributes['web.liveRouteLength']) ||
                    ''
                  }
                  onChange={e =>
                    props.changeAttribute2('web.liveRouteLength', e)
                  }
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid> */}
              {/* <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='web.maxZoom'
                  type='number'
                  label={props.translate('attributeWebMaxZoom')}
                  placeholder=''
                  value={
                    (props.form.attributes &&
                      props.form.attributes['web.maxZoom']) ||
                    ''
                  }
                  onChange={e =>
                    props.changeAttribute2('web.maxZoom', e)
                  }
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='web.selectZoom'
                  type='number'
                  label={props.translate('attributeWebSelectZoom')}
                  placeholder=''
                  value={
                    (props.form.attributes &&
                      props.form.attributes['web.selectZoom']) ||
                    ''
                  }
                  onChange={e =>
                    props.changeAttribute2('web.selectZoom', e)
                  }
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid> */}
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <SingleSelect
                  array={[]}
                  async
                  selectName='timezone'
                  isClearable={false}
                  defaultOptions={defaultZones}
                  loadOptions={searchFromZoneList}
                  label={props.translate('sharedTimezone')}
                  value={
                    props.form.attributes &&
                    props.form.attributes['timezone']
                      ? timeZonesTypes.find(t => t.key === props.form.attributes['timezone'])
                      : ''
                  }
                  handleChange={props.timezone_handleChange}
                  canAssign={true}
                />
              </Grid>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='measurementStandard'
                  select
                  label={props.translate('measurementStandard')}
                  value={
                    (props.form.attributes &&
                      props.form.attributes.measurementStandard) ||
                    ''
                  }
                  onChange={e =>
                    props.changeAttribute2('measurementStandard', e)
                  }                 
                  margin='dense'
                  fullWidth
                >
                  {measurementStandard.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {props.translate(option.value)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
                <TextField
                  id='currencyLabel'
                  select
                  label={props.translate('currencyLabel')}
                  value={
                    (props.form.attributes &&
                      props.form.attributes.currencyLabel) ||
                    'AED'
                  }
                  onChange={e =>
                    props.changeAttribute2('currencyLabel', e)
                  }
                  margin='dense'
                  fullWidth
                >
                  {currencies&&currencies.map(option => (
                    <MenuItem key={option.key} value={option.value}>
                      {props.translate(option.value)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
               <Checkbox
                  checked={props.form.attributes &&  props.form.attributes.viewModel}
                  onChange={e =>props.changeAttribute2('viewModel', e)}
                  canAssign
                  canRemove
                  label={props.translate('viewModel')}
                />
              </Grid>
              <Grid item  xl={4} lg={4} sm={6} md={4} xs={12}>
               <Checkbox
                  checked={props.form.attributes &&  props.form.attributes.viewIMEI}
                  onChange={e =>props.changeAttribute2('viewIMEI', e)}
                  canAssign
                  canRemove
                  label={props.translate('viewIMEI')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <label
                style={{
                  fontSize: 10,
                  color: themecolors.textColor
                }}
              >
                {props.translate('userToken')}
              </label>
              <Tooltip title={props.translate('getToken')}>
                <Button
                  className='pull-right'
                  onClick={props.generateToken}
                >
                  {
                    <RefreshIcon
                      style={{
                        color: themecolors.textColor
                      }}
                    />
                  }
                </Button>
              </Tooltip>
              <Tooltip title={props.translate('copyLink')}>
              <Button
                   style={{marginRight:"5px"}}
                   className='pull-right'
                onClick={props.copyTokenToClipboard}
              >
                {<FileCopyOutlinedIcon style={{color:"black"}} />}
              </Button>
            </Tooltip>
              <p onClick={e => selectMe(e)}>
                <code style={{ wordBreak: 'break-all' }}>
                  {props.viewScreen === 'edit'
                    ? props.form.token &&
                      props.ServerSetting &&
                      props.ServerSetting.attributes &&
                      (props.ServerSetting.attributes.hostUrl +
                        '/loginasuser?&' +
                        btoa(props.form.token) ||
                        '')
                    : props.form.token || ''}
                </code>{' '}
                &nbsp;
              </p>
            </Grid>
          </Fragment>
        }
      />):(
        <SimpleModalWrapped
          visable={true}
          title={'sharedRequired'}
          isButtonVisable={true}
          notShowAttributesBtn
          formSubmit={props.formSubmit}
          addAttributes={props.addAttributes}
          modalControle={props.modalControle}
          buttonText={props.buttonText}
          btnCloseText='sharedBack'
          isVisableBtn={props.isValidPassword && props.confirmPasswordCheck}
          hasPremissions
          canUpdate={props.canUpdate}
          attributeChangesMessage={props.attributeChangesMessage}
          btnCancelNotShow={props.btnCancelNotShow}
          content={
            <Fragment>
              <Grid container spacing={2}>
                <Grid item xl={3} sm={6} md={4} xs={12}>
                  <TextField
                    id='password'
                    label={props.translate('userPassword')}
                    type='password'
                    required
                    placeholder=''
                    value={props.form?.password || ''}
                    onChange={props.handleChange('password')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                                          <FormHelperText  style={{color:props.themecolors.textColor,display:"flex",flexDirection:'column',gap:"4px"}} >

             <div style={{display:"flex", alignItems:"center"}}>
              {props.lengthCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14,marginBottom:'2px' }}/> :
                <CancelIcon style={{ color: "red",fontSize: 14,marginBottom:'2px' }}/>}
                <span style={{marginLeft:"5px"}}>{props.translate('Length must be atleast 8 characters')}</span>
              </div> 
             <div style={{display:"flex", alignItems:"center"}}>
             {props.numberCheck?
              <CheckCircleIcon style={{ color: "green", fontSize: 14,marginBottom:'2px' }}/> :
              <CancelIcon style={{ color: "red",fontSize: 14,marginBottom:'2px' }}/>}
              <span style={{marginLeft:"5px"}}>{props.translate('Must contain atleast one Number')}</span>
              </div> 
             <div style={{display:"flex", alignItems:"start"}}>
             {props.upperCaseCheck?
              <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
              <CancelIcon style={{ color: "red",fontSize: 14}}/>}
              <span style={{marginLeft:"5px",lineHeight:"1rem"}}>{props.translate('Must contain atleast one Uppercase letter')}</span>
              </div> 
             <div style={{display:"flex", alignItems:"start"}}>
             {props.lowerCaseCheck?
              <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
              <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
              <span style={{marginLeft:"5px",lineHeight:'1rem'}}>{props.translate('Must contain atleast one Lowercase letter')}</span>
              </div> 
             <div style={{display:"flex", alignItems:"start"}}>
             {props.characterCheck?
              <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
              <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
              <span style={{marginLeft:"5px",lineHeight:"1rem"}}>{props.translate('Must contain atleast one Special character (!@#$%^&*(),.?:{}|<></>)')}</span>
              </div> 
          </FormHelperText>
                </Grid>
                <Grid item xl={3} sm={6} md={4} xs={12}>
                  
                  <TextField
                    id='confirmPassword'
                    label={props.translate('confirmPassword')}
                    type='password'
                    // error={
                    //   props.form.password ===
                    //   props.form.confirmPassword
                    //     ? false
                    //     : true
                    // }
                    required
                    placeholder=''
                    value={props.form.confirmPassword}
                    onChange={props.handleChange('confirmPassword')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                  <FormHelperText  style={{color:themecolors.textColor}} >
             <div style={{display:"flex", alignItems:"center"}}>
              {props.confirmPasswordCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14,marginBottom:'2px' }}/> :
                <CancelIcon style={{ color: "red",fontSize: 14,marginBottom:'2px' }}/>}
                <span style={{marginLeft:"5px"}}>{props.translate('Both Passwords must be Equal')}</span>
              </div> 
              </FormHelperText>
                </Grid>
                
              </Grid>
            </Fragment>
          }
        />
        )
      }
      {/* {state.at_modal_visable ?
                  <Attributes
                      onCloseModal={addAttributes}
                      itemAttributes={props.itemAttributes}
                      changeAttribute={props.changeAttribute}
                      formSubmit={ud_submit}
                      componentType={'user'}
                      isHaveAccess={props.isHaveAccess}
                  />
                  : null} */}
    </div>
  )
}

export default addUserModal