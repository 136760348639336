import React, { useState, useCallback } from 'react';
import TextField from '../../../Components/common/TextField';
import MenuItem from '../../../Components/common/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '../../../Components/common/Button';
import SingleSelect from '../../../Components/common/SingleSelect';
import Autocomplete from '../../../Components/common/Autocomplete';
import Table from '../../../Components/common/TableMultiCheckbox';
import CustomDialog from '../../../Components/common/Dialog';
import Checkbox from '../../../Components/common/CheckboxPermissions';
import ReactHtmlParser from 'react-html-parser';
import {
  checkPrivileges,
  errorHandler,
  postionAttributes
} from '../../../Helpers';
import { Tooltip } from '@mui/material';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import instance from '../../../axios';
import { useSelector } from 'react-redux';

const ComputedAttributes1 = (props) => {
  const [openDevices, setOpenDevices] = useState(false);
  const [selected, setSelected] = useState(null);
  const [result, setResult] = useState('');
  const devices=useSelector((state)=>state.device3)

  const C_Att_handleChange = useCallback((name, value) => {
    if (props.attributeHandle) {
      props.attributeHandle(name, value);
    }
  }, [props]);

  const runTest = () => {
    let device =devices?.find(
      d => parseInt(d.id) === parseInt(props.id)
    );
    if (device && device.id) {
      setSelected({
        id: props.id,
        label: device.name,
        key: props.id,
        value: props.id
      });
      setOpenDevices(true);
    }
  };

  const closeDevices = () => {
    setOpenDevices(false);
    setSelected('');
    setResult('');
  };

  const selectUnit = (e, r) => {
    setSelected({ ...r, value: r.id });
  };

  const executeTest = () => {
    let body = { ...props.computedAttForm };
    delete body.check;
    let id = (selected && selected.id) || props.id;

    instance({
      url: `/api/attributes/computed/test`,
      method: 'POST',
      params: {
        deviceId: id
      },
      data: {
        ...body
      }
    })
      .then(result => {
        setResult('Result: ' + result);
      })
      .catch(error => {
        errorHandler(error, props.dispatch, errorCallBack);
      });
  };

  const errorCallBack = (data) => {
    setResult('Result: ' + data?.message);
  };

  const datafilter = (data) => {
    let rows = [];
    if (data?.length) {
      data.map(item => {
        if (item.category === 0) {
          rows.push(item);
        }
        return null;
      });
      return rows;
    }
    return rows;
  };

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item sm={props.visableAttribute ? 8 : 12} xs={12}>
        <Table
          createButton={
            checkPrivileges('attributeCreate') && (
              <div style={{ marginLeft: 10 }}>
                <Button onClick={props.onCreateNewAttributes}>
                  {props.translate('Create')}
                </Button>
              </div>
            )}
          title={props.translate('sharedComputedAttributes')}
          insideDialog
          disableHead
          showCheckbox
          checkHandleChange={props.computedAttributes_Selection}
          rows={datafilter(props.computedAttributesData)}
          rowsPerPage={props.rowsPerPage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          isEditable
          canUpdate={checkPrivileges('attributeUpdate')}
          canDelete={checkPrivileges('attributeDelete')}
          canRemove={props.canAssign}
          canAssign={props.canRemove}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          themecolors={props.themecolors}
          selectAll={props.selectAll}
          searchable
          showGroupAccess={props.showGroupAccess}
          className='custom-table2'
          rowDefinition={[
            {
              id: 'description',
              numeric: false,
              disablePadding: false,
              label: props.translate('sharedDescription')
            },
            {
              id: 'attribute',
              numeric: false,
              disablePadding: false,
              label: props.translate('sharedComputedAttribute'),
              translate: true
            }
          ]}
        />
      </Grid>
      {props.visableAttribute && (
        <Grid item sm={4} xs={12}>
          <div
            style={{
              padding: '1px 20px',
              borderLeft:
                '1px solid ' + props.themecolors && props.themecolors.themeLightColor,
              height: '100%'
            }}
          >
            <h4>{props.translate('sharedComputedAttribute')}</h4>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  id='description'
                  label={props.translate('sharedDescription')}
                  type='text'
                  value={props.computedAttForm.description}
                  onChange={props.C_Att_handleChange('description')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  suggestions={postionAttributes}
                  fieldName='attribute'
                  translate={props.translate}
                  label={props.translate('sharedAttribute')}
                  value={
                    props.computedAttForm.attribute
                      ? props.computedAttForm.attribute
                      : ''
                  }
                  handleChange={C_Att_handleChange}
                  canRemove={
                    checkPrivileges('attributeCreate') ||
                    checkPrivileges('attributeUpdate')
                  }
                  canAssign={
                    checkPrivileges('attributeCreate') ||
                    checkPrivileges('attributeUpdate')
                  }
                />
              </Grid>
              {props.type === 'units' ? (
                <Grid item xs={12} style={{ marginTop: 8 }}>
                  <Tooltip
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    placement='bottom-start'
                    title={
                      <>
                        {props.translate('InputStartingWith')}
                        distance = speed*time.
                      </>
                    }
                  >
                    <Grid xs={12}>
                      <ReactTextareaAutocomplete
                        style={{ maxWidth: '100%' }}
                        onChange={props.C_Att_handleChange('expression')}
                        loadingComponent={() => <span>Loading</span>}
                        minChar={0}
                        rows={4}
                        placeholder={props.translate(
                          'sharedExpressionLabel'
                        )}
                        className='custom-theme-input theme-notchedOutline'
                        value={props.computedAttForm.expression}
                        trigger={{
                          ':': {
                            dataProvider: token => {
                              return props.suggestions
                                .filter(r => r.includes(token))
                                .map(row => ({
                                  name: row
                                }));
                            },
                            component: ({ entity: { name } }) => {
                              return <div>{`${name}`}</div>;
                            },
                            output: (item) => item.name
                          }
                        }}
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <TextField
                    id='expression'
                    label={props.translate('sharedExpressionLabel')}
                    multiline
                    rowsMax='8'
                    rows='4'
                    value={props.computedAttForm.expression}
                    onChange={props.C_Att_handleChange('expression')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  id='type'
                  select
                  label={props.translate('sharedType')}
                  value={props.computedAttForm.type}
                  onChange={props?.C_Att_handleChange('type')}
                  margin='dense'
                  fullWidth
                >
                  {att_valueType.map(option => (
                    <MenuItem key={option.Type} value={option.Type}>
                      {props.translate(option.Type)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 5 }}>
                <Checkbox
                  canAssign
                  canRemove
                  translate={props.translate}
                  checked={props.computedAttForm?.attributes?.copyFromLast || false}
                  onChange={(e)=>props?. attributeHandle("copyFromLast",e.target.checked)}

                  
                  label={props.translate('copyFromLast')}
                />
              </Grid>
            </Grid>
            <div style={{ textAlign: 'right', marginTop: 10 }}>
              <Button
                size='small'
                style={{ marginRight: 15 }}
                onClick={runTest}
              >
                {' '}
                {props.translate('runTest')}
              </Button>
              {checkPrivileges(props.isHaveAccess) && (
                <Button
                  disabled={!props.isAddVisableBtn||props.isLoading}
                  size='small'
                  onClick={props.addComputedAttributes}
                >
                  {' '}
                  {props.isLoading?"loading":props.translate(props.addButtonStatus)}
                </Button>
              )}
            </div>
          </div>
        </Grid>
      )}
      {openDevices && (
        <CustomDialog
          themecolors={props.themecolors}
          visable={false}
          title={props.translate('units')}
          onClose={closeDevices}
          isButtonVisable={false}
          draggable
          dialogHeight={240}
          isVisableBtn={
            checkPrivileges('attributeCreate') ||
            checkPrivileges('attributeUpdate')
          }
          headerActions={
            <Button
              size='small'
              style={{
                background: props.themecolors.backgroundColor,
                color: props.themecolors.textColor
              }}
              variant='contained'
              color='secondary'
              onClick={executeTest}
            >
              {' '}
              {props.translate('runTest')}
            </Button>
          }
          bodyPadding={20}
        >
          <SingleSelect
            array={devices.map(d => {
              return { ...d, key: d.id };
            })}
            selectName='unit'
            label={props.translate('pleaseSelectedUnit')}
            value={selected || { key: '', label: '', id: '' }}
            handleChange={selectUnit}
            canRemove={
              checkPrivileges('attributeCreate') ||
              checkPrivileges('attributeUpdate')
            }
            canAssign={
              checkPrivileges('attributeCreate') ||
              checkPrivileges('attributeUpdate')
            }
          />
          {result && (
            <div
              style={{
                color: props.themecolors['error'],
                fontWeight: 700,
                fontStyle: 'italic'
              }}
            >
              {ReactHtmlParser(result)}
            </div>
          )}
        </CustomDialog>
      )}
    </Grid>
  );
};

// const mapState = state => ({
//   devices: state.devices3
// });

export const ComputedAttributes=ComputedAttributes1;

const att_valueType = [
  {
    Type: 'string'
  },
  { Type: 'number' },
  { Type: 'boolean' }
];
