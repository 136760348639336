import React, {useState, useEffect } from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import Button from './../../../Components/common/Button'
import Loader from './../../Loader'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Grid from '@mui/material/Grid'
import DateRangePicker from './../../../Components/common/DateRangeHistoryPicker'
import moment from 'moment'
import Collapse from '@mui/material/Collapse'
import { CompactPicker } from 'react-color'
import Style from 'style-it'
import { checkPrivileges, errorHandler, getTimeFormat, setAttributeFormat } from '../../../Helpers'
import 'moment-timezone'
import Scrollbar from 'react-scrollbars-custom'
import PublishIcon from '@mui/icons-material/Publish';
import { Tooltip } from '@material-ui/core'
import { CheckIcon } from '../../../Components/common/CheckIcon'
import DeviceSelector from '../../../Components/Devices/DeviceSelector'
import DownloadIcon from '@mui/icons-material/GetApp'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as LoaderIcon } from './../../../assets/tracks-loader.svg'
//  import ListItemIcon from '@mui/material/ListItemIcon'
// import EventIcon from '@mui/icons-material/Event'
import instance from '../../../axios';
// import MenuItem from '../../../Components/common/MenuItem';
import TracksMenu from '../../../Components/Menu/TracksMenu';
import Notifications from 'react-notification-system-redux'
import { addGeoFence } from '../../../Actions/Devices';

const DrawTrips = (props) => {

  const devices = props?.trips


  const toggleAllGroup = (groupId, check) => {
    props.toggleAllGroup(groupId, check)
  }
  return (
    <Devices
      groups={devices}
      toggleAllGroup={toggleAllGroup}
      {...props}
    />
  )
}




const Tracks = (props) => {

  const [state, setState] = useState({
    kmlFile: {}
  })

  const createReport = type => {
    //props.collapseSidebar();
    props.createReport(type)
  }

  const goBack = () => {
    props.openForm()
  }

  const uploadTrigger = () => {
    const el = document.getElementById('uploadTrigger')
    el.click()
  }

  const kmlFileUpload = event => {
    if (event && event.target && event.target.files && event.target.files.length) {
      const kmlFile = props.kmlFileUpload(event)
      if (kmlFile) {
        setState((prevState) => ({ ...prevState, kmlFile }))
      }
    }
  }

  const clearSelection = () => {
    setState((prevState) => ({ ...prevState, kmlFile: {} }))
    props.clearSelection()
  }

  let viewScreen
  const empty = null
  if (props.view === 'reportsData') {
    viewScreen = (
      <div>
        <p
          style={{
            textAlign: 'right',
            padding: '0 10px',
            margin: '16px 0 12px'
          }}
        >
          <a
            href={empty}
            style={{
              textAlign: 'center',
              display: 'inline-block',
              width: 48,
              cursor: 'pointer',
              color: props.themecolors.textColor
            }}
            onClick={() => goBack('displayForm')}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: 30 }} />
          </a>
        </p>
        {props.mainView === 'tracks' && (
          <ul className='list-view with-padding-right'>
            {props &&
              props.reportsData &&
              props.reportsData.length ? (
              <DrawTrips
                logInUser={props.logInUser}
                changeCheckbox={props.changeCheckbox}
                routesLoading={props.routesLoading}
                updateColor={props.updateColor}
                downloadAllTracks={props.downloadAllTracks}
                pageLoading={props.pageLoading}
                trips={props.trips}
                drawRoute={props.drawRoute}
                toggleAllGroup={props.toggleAllGroup}
                toggleDaywiseGroup={props.toggleDaywiseGroup}
                cancelRoute={props.cancelRoute}
                collapseSidebar={props.collapseSidebar}
                translate={props.translate}
                themecolors={props.themecolors}
                dispatch={props.dispatch}
              />
            ) : (
              <div style={{ padding: 50, textAlign: 'center', fontSize: 20 }}>
                {props.translate('noRecordFound')}
              </div>
            )}
          </ul>
        )}
      </div>
    )
  } else if (props.view === 'displayForm') {
    viewScreen = (
      <form style={{ padding: 20 }}>
        <h3 className='page-title'>
          {props.translate('trackForm')}{' '}
          {!state.kmlFile.name && (
            <label style={{ float: 'right', marginTop: -10 }}>
              <Tooltip title={props.translate('uploadkml')}>
                <Button onClick={uploadTrigger}>
                  <PublishIcon style={{ fontSize: 18 }} />
                </Button>
              </Tooltip>
              <input
                id='uploadTrigger'
                style={{ display: 'none' }}
                type='file'
                onChange={kmlFileUpload}
              />
            </label>
          )}
        </h3>
        {!state.kmlFile.name ? (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DateRangePicker
                 reportsRanges
                  fill
                  selectedDate={props.selectedDate}
                  label={
                    props.translate('reportFrom') +
                    ' ' +
                    props.translate('reportTo')
                  }
                  onEvent={props.showDates}
                />
              </Grid>
            </Grid>
            <DeviceSelector
              value={props.deviceId}
              onChange={props.selectedDevices}
              rows={10}
            />
            <Button
              disabled={!props.validatedForm}
              style={{ marginBottom: 10, marginTop: 15 }}
              variant='contained'
              onClick={createReport}
            >
              {props.translate('submit')}
            </Button>
          </>
        ) : (
          <div>
            <div>File Name: {state.kmlFile.name}</div>
            <p style={{ marginTop: 0 }}>Size: {state.kmlFile.size}</p>
            <Button onClick={clearSelection}>Clear</Button>
          </div>
        )}
      </form>
    )
  }

  if (viewScreen) {
    return (
      <Style>
        {`
            .material-icons {vertical-align: middle; color: inherit}
          `}
        <aside className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('track') && (
            <Scrollbar disableTracksWidthCompensation={true}>
              {viewScreen}
            </Scrollbar>
          )}
        </aside>
      </Style>
    )
  } else {
    return props.trackLoading ? (
      <div className='sidebar-bg-layer'>
        <Loader />
      </div>
    ) : null
  }
}
export default Tracks

const Devices = (props) => {
  return <DrawGroups {...props} />
}

export const DrawGroups = (props) => {
  return (
    <div className='group-view list-row-condest tracks-view'>
      <ul className='list-view-group'>
        {Object.keys(props.groups).map((key, index) => {
          return (
            <DrawGroupRow
              key={index}
              groupId={key}
              group={props.groups[key]}
              {...props}
            />
          )
        })}
      </ul>
      {/*<ul className="list-view">
          <ListRow motion="play" signals="online" unit="car" status="online" />
          <ListRow motion="play" signals="online" unit="car" status="online" />
          <ListRow motion="pause" signals="online" unit="car" status="offline" />
          <ListRow motion="pause" signals="online" unit="car" status="offline" />
        </ul>*/}
    </div>
  )
}
export const DrawGroupRow = (props) => {
  const [state, setState] = useState({
    isChecked: false,
    open: true
  })

  const groupCollapse = (event) => {
    event.preventDefault()
    setState((prevState) => ({
      ...prevState,
      open: !prevState.open
    }))
  }

  useEffect(() => {
    const devices = { ...props.group };

    if (Object.keys(devices['data']).length) {
      setState((prevState) => ({
        ...prevState,
        isChecked: true,
      }))

      Object.keys(devices['data']).map((key) => {
        devices['data'][key].map((row) => {
          if (row.checked !== true) {
            setState((prevState) => ({
              ...prevState,
              isChecked: false,
            }))
          }
        });
      });
    }
  }, [props.group]);


  const toggleGroupDevices = (event) => {
    //props.dispatch(toggleGroupDevices({checked: event.target.checked, id: props.group.id}));
    props.toggleAllGroup(props.groupId, event.target.checked)
    setState((prevState) => ({
      ...prevState,
      isChecked: event.target.checked
    }))
  }

  /*shouldComponentUpdate(nextProps, nextState){
      return !isEqual(nextProps, props) || !isEqual(nextState, state); // equals() is your implementation
  }*/

  const emptyLinks = null

  let mileage = 0
  if (
    props.group &&
    props.group.data &&
    Object.keys(props.group.data) &&
    Object.keys(props.group.data).length
  ) {
    Object.keys(props.group.data).map(date => {
      if (props.group.data[date]) {
        props.group.data[date].map(row => {
          if (row.checked) {
            mileage += row.distance
          }
          return null
        })
      }
      return null
    })
  }
  
  return (
    <li key={props.group.id}>
      <div className='clearfix group-list-row list-row sticky'>
        <label className='checkbox'>
          <input
            type='checkbox'
            checked={state.isChecked}
            onChange={toggleGroupDevices}
          />
          <CheckIcon className='theme-input-checkbox' />
          <span className='unit-name'>
            {props.group?.detail?.deviceName}
          </span>
        </label>
        <div className='pull-right'>
          <strong className='action-item'>
            <small>
              {mileage ? (mileage / 1000).toFixed(2) + ' km' : null}
            </small>
          </strong>
          <span className='action-item action-additional-menu'>
            <a
              href={emptyLinks}
              onClick={e => props.downloadAllTracks(e)}
              title='Download all tracks'
            >
              <DownloadIcon />
            </a>
          </span>
          <span className='action-item '>
            <a
              href={emptyLinks}
              onClick={e => groupCollapse(e)}
              title='Additional Menu'
            >
              <KeyboardArrowDownIcon style={{ fontSize: 24 }} />
            </a>
          </span>
          {/*<span className="action-item action-clear-list">
                  <a href={emptyLinks} title="Clear List"><MaterialIcon size="18" icon='close'/></a>
                </span>
                <span className="action-item" href={emptyLinks} title=""><MaterialIcon size="22" icon='add_box'/></span>*/}
          {/*<span className="action-item action-ellipsis">
                  <a href={emptyLinks} title="Monitoring Panel Customizer"><MaterialIcon size="18" icon='more_vert'/></a>
                </span>*/}
        </div>
      </div>
      <Collapse in={state.open} timeout='auto' unmountOnExit>
        <ListRows {...props} isChecked={state.isChecked} dispatch={props.dispatch} />
      </Collapse>
    </li>
  )
}
export const ListRows = (props) => {

  const [state, setState] = useState({
    open: false,
    selectedDate: "",
    date:{}
  })
  useEffect(() => {
    Object.keys(props.group.data).map((key) => {
      return (
        setState((prevState) => ({
          ...prevState,
          [key]: true,
          date: { ...prevState.date, [key]: false },
          selectedDate: key
        }))
      )
    })
  }, [])

  useEffect(() => {
    let devices = Object.assign({ ...props.group })
    if (Object.keys(devices['data']).length) {
      setState((prevState) => ({ ...prevState, date: { ...prevState.date, [state.selectedDate]: true } }))
      devices['data'][state.selectedDate]?.map(row => {
        if (row.checked !== true) {
          setState((prevState) => ({ ...prevState, date: { ...prevState.date, [state.selectedDate]: false } }))
        }
        return null
      })
    }
  }, [props.group,state.selectedDate])


  const groupCollapse = (id) => {
    let open = state[id] || false
    setState((prevState) => ({
      ...prevState,
      [id]: !open
    }))
    //const { checked }  = event.target;
    //props.dispatch(updateDeviceVisible({checked, id}));
  }
  const toggleDaywiseGroup = (groupId, event, key) => {
    let check = event.target.checked
    if (check) {
      setState((prevState) => ({
        ...prevState,
        selectedDate: key,
        date: {
          ...prevState.date,
          [key]: true
        },
      }))
      props.toggleDaywiseGroup(groupId, check, key)
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedDate: key,
        date: {
          ...prevState.date,
          [key]: false
        },
      }))
      props.toggleDaywiseGroup(groupId, check, key)
    }

  }

  return (
    <ul className='tracks-list-view'>
      {Object.keys(props.group.data).map((key) => {
        let duration = 0
        let distance = 0
        props.group.data[key].map(row => {
          distance += row.distance
          duration += row.duration
          return null
        })

        return (
          <React.Fragment key={key}>
            <li
              className='trip-date-row with-border'
              style={{ cursor: 'pointer' }}
              onClick={() => groupCollapse(key)}
            >
              <label className='checkbox'>
                <input
                  type='checkbox'
                  checked={props.isChecked || state.date[key]}
                  onChange={(e) => toggleDaywiseGroup(props.groupId, e, key)}
                />
                <CheckIcon className='theme-input-checkbox' />
              </label>
              {' '}{key}
              <span className='pull-right'>
                {props.translate('reportTrips')}:{' '}
                {props.group.data[key].length}
                <KeyboardArrowDownIcon style={{ fontSize: 24, verticalAlign: 'middle' }} />
              </span>
            </li>

            <Collapse in={state[key]} timeout='auto' unmountOnExit>
              <li>
                <div
                  className={'clearfix tracks-list-row tracks-list-row-head'}
                >
                  <label className='checkbox'></label>
                  <span className='trip-entity trip-distance'>
                    {props.translate('distance')}
                  </span>
                  <span className='trip-entity trip-time'>
                    {props.translate('maintenanceStart')}
                    <span style={{ margin: '0 5px' }}>-</span>
                    {props.translate('end')}
                  </span>
                  <span className='trip-entity trip-duration'>
                    {props.translate('reportDuration')}
                  </span>
                </div>
              </li>
              <ListRow1
                groupDate={key}
                rows={props.group.data[key]}
                {...props}
                dispatch={props.dispatch}
              />
              <li>
                <div
                  className={'clearfix tracks-list-row tracks-list-row-head'}
                >
                  <label className='checkbox'></label>
                  <span className='trip-entity trip-distance'>
                    {setAttributeFormat('distance', distance)}
                  </span>
                  <span className='trip-entity trip-time'>--:--</span>
                  <span className='trip-entity trip-duration'>
                    {moment
                      .duration(duration, 'milliseconds')
                      .format('H[h]m[m]')}
                  </span>
                </div>
              </li>
            </Collapse>
          </React.Fragment>
        )
      })}
    </ul>
  )
}
export const ListRow1 = (props) => {
  return props.rows.map((position) => (
    <ListRow key={position.startPositionId} data={position} {...props} dispatch={props.dispatch} />
  ))
}

const ListRow = (props) => {
  const [state, setState] = useState({
    trackId: '',
    deviceId: '',
    anchorEl: null,
    anchorE2: null,
    selectedDevice: '',
    editOption: false,
    removeOption: false,
    d_attriubtes: '',
    play: 'offline',
    colorPicker: false,
    emptyScreen: false,
    playicon: { label: 'Pause', value: 'pause' },
    openGeofence: false
  })

  // const editDevice = (selectedDevice) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     editOption: true,
  //     removeOption: false,
  //     selectedDevice: selectedDevice
  //   }))
  // }

  // const onCloseConfirm = () => {
  //   setState((prevState) => ({
  //     ...prevState, removeOption: false, editOption: false
  //   }))
  // }

  /*removeDevice(selectedDevice, event) {

    setState({ removeOption: true,
      editOption: false,
      selectedDevice: selectedDevice
    });     
  }*/

  // const removeDevice = (selectedDevice) => {
  //   if (window.confirm('Are you Sure to Delete')) {
  //     // fetch(`/api/devices/${selectedDevice.id}`, {
  //     //   method: 'DELETE',
  //     //   headers: {
  //     //     Accept: 'application/json',
  //     //     'Content-Type': 'application/json'
  //     //   }
  //     // })
  //     instance({
  //       url: `/api/devices/${selectedDevice.id}`,
  //       method: 'DELETE',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //       .then(response => {// eslint-disable-line no-unused-vars
  //         // if (response.status === 204) {
  //         //props.dispatch(removeDevice(selectedDevice.id));
  //         alert('Unit is Deleted')
  //         // } else {
  //         //   throw response
  //         // }
  //       }).catch(error => { errorHandler(error, props.dispatch) })
  //   }
  // }

  const handleChange = (event) => {
    const { target } = event

    //props.dispatch(updateDeviceVisible({checked: target.checked, id: props.data.id}));

    if (target.checked === true) {
      viewOnMap()
    } else {
      props.changeCheckbox(
        props.groupDate,
        props.groupId,
        props.data.startPositionId,
        false
      )
      props.cancelRoute(props.data)
      //props.collapseSidebar();
    }
  }

  const viewOnMap = () => {
    props.changeCheckbox(
      props.groupDate,
      props.groupId,
      props.data.startPositionId,
      true
    )
    props.drawRoute(props.data, true, 'single')
    props.collapseSidebar()
  }

  const openMenu = () => {
    setState((prevState) => ({
      ...prevState, colorPicker: true
    }))
  }
  const hideColorPicker = () => {
    setState((prevState) => ({
      ...prevState, colorPicker: false
    }))
  }
  useEffect(() => {
    if (props.data) {
      props.pageLoading(false)
    }
  }, [props.data])

  /*componentWillReceiveProps(NextProps) {

    var checked = true;

      NextProps.devices.map((item, index) => {
          if(item.visible === false) {          
            checked = false;
          }
          return '';
      });

      props.isChecked(checked);

      if(NextProps.trackId === props.data.id) {
        setState({trackId: 'online'});
      }
      else {
        setState({trackId: ''});       
      }

      if(NextProps.deviceId === props.data.id) {
        setState({deviceId: 'online'});
      }
      else {
        setState({deviceId: ''});        
      }


      if(NextProps.trackId) {
        if(NextProps.positions.length) {
        NextProps.positions.map((pos) => {
          if(pos.deviceId === NextProps.trackId) {
            if(!isEqual(NextProps.bounds, [[pos.latitude, pos.longitude]])) {
              //props.dispatch(setBounds([[pos.latitude, pos.longitude]]));
            }
          }
          return '';
        })        
      }       
      }


      makeIcons(NextProps);


    }*/

  // onClose () {
  //   setState({ anchorEl: null })
  // }

  /*    shouldComponentUpdate(nextProps, nextState){
      return !isEqual(nextProps, props) || !isEqual(nextState, state); // equals() is your implementation
  }*/

  const checkLastResponseTime = date => {
    return moment(date).isBefore(moment().subtract(1, 'day'))
  }

  const updateColor = (e) => {
    props.updateColor(
      props.groupDate,
      props.groupId,
      props.data.startPositionId,
      e.hex
    )
  }

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openGeofence: false, acnhorE2: null
    }))

  }

  const createGeofence = () => {
    let getLatLong = []
    instance({
      url: `/api/positions/${props.data?.deviceId}`,
      method: 'GET',
      params: {
        startPositionId: props.data.startPositionId,
        endPositionId: props.data.endPositionId,
        from: moment.utc(props.data.startTime).toISOString(),
        to: moment.utc(props.data.endTime).toISOString(),
      }
    })
      .then(positions => {
        const newArray = positions && positions.map(item => ({
          lat: item.latitude,
          lng: item.longitude
        }));
        positions && positions.map((item) => {
          getLatLong.push(`${item.latitude}${" "}${item.longitude}`)
        })
        let makeData = {
          name: props.data.deviceName,
          calendarId: 0,
          description: '',
          area: 'LINESTRING (' + getLatLong.join(', ') + ')',
          attributes: {
            type: 'polyline',
            color: props.data.color,
            latlng: newArray,
          }
        }
        if (makeData) {
          instance({
            url: `/api/geofences`,
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data: {
              ...makeData
            }
          }).then(geofence => {
            props.dispatch(addGeoFence([{ ...geofence, visible: true }]))
            props.dispatch(
              Notifications.success({
                message: props.translate('geofenceCreated'),
                autoDismiss: 10
              })
            )
          }).catch(err => {
            if (err.message.includes("Data too long for column")) {
              props.dispatch(
                Notifications.error({
                  message: props.translate('dataColumnsError'),
                  autoDismiss: 10
                })
              )
            }
            else {
              errorHandler(err, props.dispatch)
            }
          })
        }

      }).catch(() => {
      })

    setState((prevState) => ({
      ...prevState,
      openGeofence: false,
      anchorE2: null
    }))
  }

  const openMenuGeofence = event => {
    setState((prevState) => ({
      ...prevState,
      openGeofence: true,
      anchorE2: event.currentTarget
    }))
  }

  // const onClose = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     anchorE2: null,
  //     anchorEl:null
  //   }))
  // }

  const emptyLinks = null

  let distanceUnit =
    props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.distanceUnit
      ? props.logInUser.attributes.distanceUnit
      : ''

  let tz = 'Asia/Dubai'

  if (
    props.logInUser &&
    props.logInUser.attributes &&
    props.logInUser.attributes.timezone
  ) {
    tz = props.logInUser.attributes.timezone
  }
  moment.locale('en');
  const formattedDuration = moment.duration(props.data.duration, 'milliseconds').format('H[h]m[m]');
  let timeFormat = getTimeFormat()

  if (!props) {
    return <h2>No props</h2>
  }
  return (
    <li className='tracks-list-row-wrap'>
      <div
        className={
          'clearfix tracks-list-row ' +
          (props.data.checked ? 'active ' : '') +
          (checkLastResponseTime(props.data.lastUpdate) &&
            'disabled-row')
        }
      >
        <label className='checkbox'>
          <input
            type='checkbox'
            onChange={handleChange}
            checked={props.data.checked}
          />
          <CheckIcon className='theme-input-checkbox' />
        </label>
        <a
          href={emptyLinks}
          onClick={viewOnMap}
          className='unit-name unit-name-full'
          style={{ textDecoration: 'none' }}
        >
          {' '}
          <span className='trip-entity trip-distance'>
            {setAttributeFormat(
              'distance',
              props.data.distance,
              distanceUnit
            )}
            <small>
              <em>{ }</em>
            </small>
          </span>{' '}
          <span className='trip-entity trip-time'>
            {moment(props.data.startTime).tz(tz).format(timeFormat)}<span style={{ margin: '0 5px' }}>-</span>
            {moment(props.data.endTime).tz(tz).format(timeFormat)}
          </span>{' '}
          <span className='trip-entity trip-duration'>
            {formattedDuration}
          </span>
        </a>
        <div className='pull-right' style={{}}>
          <span className=''>
            <a
              href={emptyLinks}
              onClick={openMenu}
              title='Monitoring Panel Customizer'
            >
              <div className="color-box" style={{ background: props.data.color }} />
            </a>
          </span>
          <span className='has-menu' style={{ display: "flex", marginTop: 5 }}>
            <a
              href={emptyLinks}
              onClick={openMenuGeofence}
              title={props.translate('openGeofence')}
            >
              <MoreVertIcon style={{ fontSize: 16 }} />
            </a>

            {state.openGeofence ? (
              <TracksMenu
                openGeofence={state.openGeofence}
                handleClose={handleClose}
                createGeofence={createGeofence}
                openMenuGeofence={openMenuGeofence}
                themecolors={props.themecolors}
                translate={props.translate}
                anchorE2={state.anchorE2}
              />
            ) : null}


          </span>
          {props.routesLoading && props.routesLoading[props.data.startPositionId] ? (
            <span style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              borderRadius: 6,
              background: props.themecolors && props.themecolors.themeLightColor + '80',
              color: props.themecolors && props.themecolors.themeInverse,
              textAlign: 'center'
            }}
            >
              <LoaderIcon style={{ width: 30, height: 30 }} />
            </span>
          ) : null}
        </div>
      </div>
      {state.colorPicker ? (
        <div style={{ position: 'absolute', bottom: '100%', right: 0 }} onMouseLeave={hideColorPicker}>
          {<CompactPicker
            color={props.data.color}
            onChangeComplete={updateColor}
          />
          }
        </div>
      ) : null}

    </li>
  )
}

