export const groups = (state = [], action) => {

  switch (action.type) {
case 'RESET_APP':
		return []

	case 'GET_GROUPS':

		return action.groups;

	case 'UPDATE_GROUPS':

		var getUpdatedData = state.map((content) => {

			var group = action.groups.map((d) => {
				if(content['id'] === d['id']) {            
					return {...content, d};
				}
				else {
					return null;
				}
			});

			return group[0] ? group[0] : content;

		});

		return getUpdatedData.length ? getUpdatedData : [...action.groups];

	case 'ADD_GROUP':

		return state.concat(action.group);

	case 'UPDATE_GROUP':

		const grp = state.map(gr => {
			if(gr.id === action.group.id) {
				return action.group
			}
			else {
				return gr;
			}
		})

		return grp

	case 'REMOVE_GROUP':
		
		const groups=state.filter(gr => gr.id !== action.group.id)
	
		return groups

    default:
    return state
  }
}


export const deviceIdsList = (state = [], action) => {
	switch(action.type) {
		case 'SAVE_GROUP_WISE_DEVICES': {
			return action.payload;
		}
		default: {
			return state;
		}
	}
}
