import React, { } from 'react'
import { connect } from 'react-redux'
import Style from 'style-it'
import InvoiceFilter from '../../../Components/common/InvoiceFilter'

const invoices = (props) => {

//  const search = (e) => {
//     props.searchItems(e.target.value)
//   }


    return (
      <Style>
        {`.material-icons {vertical-align: middle; color: inherit}`}
        <div className='fms-sidebar sidebar-bg-layer'>
        <div style={{ padding: 13 }}>
       <h3
          style={{ display: 'flex', alignItems: 'center', margin: 0 }}
        >
          <span>{props.translate('sharedInvoice')}</span>
          </h3>
        </div>
        {
            <InvoiceFilter
              {...props}
              formSubmit={props.submitSideBarSearch}
              sidebar
              invoice
              loader={props.loader}
            />
        }
        </div>
      </Style>
    )
  }

const mapState = state => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export default mapStateToProps(invoices)
