import React from 'react'
// import Icon from '@mui/material/Icon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
import { ReactComponent as IntegratedWasl } from '../../assets/monitoring/wasl.svg'
import { ReactComponent as NotIntegratedWasl } from '../../assets/monitoring/wasl-cross.svg'
import Style from 'style-it'
library.add(faSatelliteDish)

export const DriversShortList=(props)=>{
  
  return (
    <Style>
      {`
        .material-icons {vertical-align: middle; color: inherit}
      `}
      <div>
        {props.items?.length > 0 && (
          <div className='individual-view'>
            <ul className='list-view with-padding-right'>
              {props.items.map((driver) => (
                <ListRow
                  key={driver?.id}
                  item={driver}
                  {...props}
                  removedItem={props.removedItem}
                  editItem={props.editItem}
                  selecteItem={props.selecteItem}
                  translate={props.translate}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </Style>
  );
}
export const ListRow=(props)=>{
  const handleFetchIds=(childId,parentId)=>{
    if(childId){
      props.removedItem(childId)
    }
    if(parentId){
      props.getParentIdFromListRow(parentId)
    }
  }
  const device = props.allDriverLocation && props.allDriverLocation[props.item?.id] || null;
  const { waslEnabled } = props?.ServerSetting?.attributes || {};
  return (
    <li>
      <Link
        className={
          'clearfix list-row ' +
          (parseInt(props.match.params?.id) ===
          parseInt(props.item?.id)
            ? 'active'
            : '')
        }
        to={'/drivers/' + props?.item?.id}
      >
        <label
          className='checkbox'
          onClick={() => props.selecteItem(props?.item)}
        >
          <span
            className='unit-name'
            style={{ minWidth: 160, maxWidth: 160, marginLeft: 0 }}
          >
            <span
              style={{
                marginRight: '5px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}
            >
              <AccountCircleIcon style={{fontSize: 16, verticalAlign: 'middle'}}/>
            </span>
            {props.item?.name}
          </span>
        </label>
        <div className='pull-right'>
          {waslEnabled ? (props.item?.attributes?.WASLKey ? <IntegratedWasl width={32} style={{fill: 'currentColor'}} /> : <NotIntegratedWasl width={32} style={{fill: 'currentColor'}} />) : ''}
          {checkPrivileges('driverDelete') && (
            <span
              className='action-item hovered'
              title={props.translate('delete')}
              onClick={() =>handleFetchIds(props.item,props?.collapseIds)}
            >
              <DeleteIcon style={{fontSize: 16}}/>
            </span>
          )}            
          {checkPrivileges('driverUpdate') && (
            <span
              className='action-item hovered'
              title={props.translate('edit')}
              onClick={() => props.editItem(props?.item)}
            >
              <EditIcon style={{fontSize: 16}}/>
            </span>
          )}
          {device ? (
            <span>
              <img
                  width={24}
                  height={24}
                  src={
                    '/assets/category/default/'+(device.category ||
                      'default')+'.svg'}
                  
                  alt=''
                />
            </span>
          ) : <span
          className='action-item'
        >
          <LinkOffIcon style={{fontSize: 16}}/>
        </span>}
        </div>
      </Link>
    </li>
  )
}