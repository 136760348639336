import React, {useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import MenuItem from '../../../Components/common/MenuItem'
import TextField from '../../../Components/common/TextField'
import Button from '../../../Components/common/Button'
import Loader from '../../../Layout/Loader'
import { EcoDrivingTable } from './drawEcoDriving'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import Notifications from 'react-notification-system-redux'
import CustomDialog from '../../../Components/common/Dialog'
import Table from '../../../Components/common/TableMultiCheckbox'
import instance from '../../../axios'


const EcoDriving = (props) => {
  const [state, setState] = useState({
    isRecived: false,
    selecteditem: '',
    currentId: 0,
    editOption: false,
    isVisibleBtn: false,
    showDialg: false,
    displayList: [],
    listData: false,
    form: {
      preset: '',
      id: '',
      attributes: {
        mode: "",
      }
    }
  })

  const themecolors=useSelector((state)=>state.themeColors)
  const dispatch=useDispatch()

  const fetchEcoData = id => {

    instance({
      url: `/api/ecodriving/`,
      method: 'GET',
      params: {
        deviceId: id
      }
    })
      .then(comp => {
        if (comp.length) {
          let res = comp[0].attributes
          let speedExtreme2 = []
          // let speedMedium =  res.speedMedium ? res.speedMedium : []


          if (res.speedExtreme) {
            speedExtreme2 = res.speedExtreme;
            speedExtreme2[0] = parseFloat(speedExtreme2[0]) * 1.852;
            speedExtreme2[1] = parseFloat(speedExtreme2[1]) * 1.852;
          }


          // speedExtreme[0] = parseFloat(speedExtreme[0]) * 1.852;
          // speedExtreme[1] = parseFloat(speedExtreme[1]) * 1.852;

          let speedMedium2 = []


          if (res.speedMedium) {
            speedMedium2 = res.speedMedium;
            speedMedium2[0] = parseFloat(speedMedium2[0]) * 1.852;
            speedMedium2[1] = parseFloat(speedMedium2[1]) * 1.852;
          }

          setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              attentance: true,
              preset: comp[0].attributes.preset,
              id: comp[0].id,
              attributes: {
                ...comp[0].attributes,
                mode: comp[0]?.attributes?.mode || "",
                ...(speedExtreme2.length > 0 ? { speedExtreme: speedExtreme2 } : {}),
                ...(speedMedium2.length > 0 ? { speedMedium: speedMedium2 } : {}),
              }
            },
            editOption: true
          }))

        } else {
          setState((prevState) => ({
            ...prevState,
            editOption: false,
            form: {
              preset: '',
              mode: '',
              id: '',
              attributes: {}
            }
          }))
        }
        setState((prevState) => ({
          ...prevState,
          isRecived: true
        }))
      })
      .catch(error => {
        errorHandler(error, dispatch)
        // props.enqueueSnackbar(error.message, { autoHideDuration: 1000 })
      })
  }

  useEffect(() => {
    if (props.selectItemId !== state.currentId) {
      setState((prevState) => ({
        ...prevState,
        currentId: props.selectItemId
      }))
      fetchEcoData(props.selectItemId);
    }
  }, [props.selectItemId, state.currentId]);

  const replaceNullWithZero = (data) => {
    if (Array.isArray(data)) {
      return data.map(item => replaceNullWithZero(item));
    } else if (typeof data === 'object' && data !== null) {
      return Object.keys(data).reduce((acc, key) => {
        acc[key] = replaceNullWithZero(data[key]);
        return acc;
      }, {});
    } else {
      return data === null || Number.isNaN(data) ? 0 : data;

      // return data === null || (typeof data === 'number' && isNaN(data)) ? 0 : data;
    }
  };

  const Submit = () => {
    setState((prevState) => ({
      ...prevState,
      isRecived: false
    }))

    let { editOption, form } = state;
    let speedExtremeUpdate = [];
    let speedMediumUpdate = [];
    let idleExtremeUpdate = [];
    let idleMediumUpdate = [];
    let option = 'POST';
    let callSufax = '';
    if (editOption) {
      option = 'PUT';
      callSufax = `/${props.selectItemId}`;
    }

    if (form && form.attributes) {
      if (form.attributes.speedExtreme) {
        speedExtremeUpdate = [...form.attributes.speedExtreme];
        speedExtremeUpdate[0] = parseFloat(speedExtremeUpdate[0]) / 1.852 || 0;
        speedExtremeUpdate[1] = parseFloat(speedExtremeUpdate[1]) / 1.852 || 0;
      }

      if (form.attributes.speedMedium) {
        speedMediumUpdate = [...form.attributes.speedMedium];
        speedMediumUpdate[0] = parseFloat(speedMediumUpdate[0]) / 1.852 || 0;
        speedMediumUpdate[1] = parseFloat(speedMediumUpdate[1]) / 1.852 || 0;
      }

      if (form.attributes.idleExtreme) {
        idleExtremeUpdate = [...form.attributes.idleExtreme];
        idleExtremeUpdate[0] = parseFloat(idleExtremeUpdate[0]) || 0;
        idleExtremeUpdate[1] = parseFloat(idleExtremeUpdate[1]) || 0;
      }

      if (form.attributes.idleMedium) {
        idleMediumUpdate = [...form.attributes.idleMedium];
        idleMediumUpdate[0] = parseFloat(idleMediumUpdate[0]) || 0;
        idleMediumUpdate[1] = parseFloat(idleMediumUpdate[1]) || 0;
      }

    }

    // Replace null values with 0 directly in the attributes
    const updatedAttributes = replaceNullWithZero(form.attributes);

    let obj = {
      id: editOption ? form.id : '',
      attributes: {
        ...updatedAttributes, // Use the updatedAttributes directly
        preset: form.preset,
        ...(speedExtremeUpdate.length > 0 ? { speedExtreme: speedExtremeUpdate } : {}),
        ...(speedMediumUpdate.length > 0 ? { speedMedium: speedMediumUpdate } : {}),
        ...(idleExtremeUpdate.length > 0 ? { idleExtreme: idleExtremeUpdate } : {}),
        ...(idleMediumUpdate.length > 0 ? { idleMedium: idleMediumUpdate } : {}),
      },
      entityId: props.selectItemId,
      property: 'ecoDriving',
      description: ''
    };

    instance({
      url: `/api/ecodriving${callSufax}`,
      method: `${option}`,
      data: {
        ...obj
      }
    })
      .then(comp => {
        let res = comp.attributes

        let speedExtreme2 = [];

        if (res.speedExtreme) {
          speedExtreme2 = res.speedExtreme;
          speedExtreme2[0] = parseFloat(speedExtreme2[0]) * 1.852;
          speedExtreme2[1] = parseFloat(speedExtreme2[1]) * 1.852;
        }

        let speedMedium2 = []

        // speedExtreme2[0] = parseFloat(speedExtreme2[0]) * 1.852;
        // speedExtreme2[1] = parseFloat(speedExtreme2[1]) * 1.852;

        if (res.speedMedium) {
          speedMedium2 = res.speedMedium;
          speedMedium2[0] = parseFloat(speedMedium2[0]) * 1.852;
          speedMedium2[1] = parseFloat(speedMedium2[1]) * 1.852;
        }

        setState((prevState) => ({
          ...prevState,
          form: {
            id: comp.id,
            preset: comp.attributes.preset,
            attributes: {
              ...comp.attributes,
              ...(speedExtreme2.length > 0 ? { speedExtreme: speedExtreme2 } : {}),
              ...(speedMedium2.length > 0 ? { speedMedium: speedMedium2 } : {}),
              // speedMedium: speedMedium2
            }
          },
          editOption: true,
          isRecived: true
        }))

        if (option === 'POST') {
          dispatch(
            Notifications.success({
              message: props.translate('createdSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          if (Object.keys(comp.attributes).length > 1) {
            dispatch(
              Notifications.success({
                message: props.translate('updatedSuccessfully'),
                autoDismiss: 10
              })
            )
          }
          else {
            DeleteSubmit();
          }
        }
      })
      .catch(error => {
        errorHandler(error, dispatch)
        setState((prevState) => ({
          ...prevState,
          isRecived: true
        }))

      })
  }

  const DeleteSubmit = () => {
    let { form } = state
    instance({
      url: `/api/ecodriving/${form.id}`,
      method: `DELETE`
    })
      .then(response => {// eslint-disable-line no-unused-vars
        // if (response.ok) {
        setState((prevState) => ({
          ...prevState,
          form: {
            mode: "",
            preset: '',
            attributes: ''
          },
          editOption: false,
          isRecived: true,
          isVisibleBtn: false
        }))

        // props.enqueueSnackbar(
        //   props.translate('attributeIsDelete'),
        //   { autoHideDuration: 1000 }
        // )
        dispatch(
          Notifications.success({
            message: props.translate('attributeIsDelete'),
            autoDismiss: 10
          })
        )
      })
      .catch(error => { errorHandler(error, dispatch) })
  }

  const handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if ((value === 'none' || value === '') && state.editOption) {
      setState((prevState) => ({
        ...prevState,
        isRecived: false
      }))
      DeleteSubmit()
    }
    if (name === 'mode') {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          attributes: {
            ...prevState.form.attributes,
            [name]: value
          }
        }
      }))
    }
    else {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: value
        }
      }))
    }
  }
  useEffect(()=>{
checkReqFields()
  },[state.form])

  const checkReqFields = () => {
    const { attributes } = state.form;
    if (attributes.mode && state.form.preset) {
      setState((prevState) => ({
        ...prevState,
        isVisibleBtn: true
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisibleBtn: false
      }));
    }
  };

  const handleChangeAttributesValue = (itemKey, index, event) => {
    let { attributes } = { ...state.form }
    if (event) {
      const { target } = event
      let value = target.value
      attributes[itemKey][index] = value ? (value) : null
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          attributes: {
            ...prevState.form.attributes,
            attributes
          }
        }
      }))
    }
  }
  const onDelete = Itemkey => {
    let { attributes } = { ...state.form }
    delete attributes[Itemkey]
    setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        attributes: {
          ...prevState.form.attributes,
          attributes
        }
      },
      isRecived: false
    }))
    Submit()

  }
  const onUpdate = () => {
    setState((prevState) => ({
      ...prevState,
      isRecived: false
    }))
    Submit()
  }
  // const copyEcoDriving = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     showDialg: true
  //   }))
  // }

  const handleModelSelect = (name, event) => {
    // Update the selectedModel in state when a model is selected
    const val = event.target.value;
    setState((prevState) => ({
      ...prevState,
      listData: val ? true : false,
      selectedModel: val
    }))
    if (val) {
      getModelBasedDevices(event.target.value)
    }
  }

  const getModelBasedDevices = (id) => {
    instance({
      url: `/api/devices/list?userId=${props?.logInUser?.id}&all=true`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        limit: -1,
        model: id
      }
    })
      .then(res => {
        setState((prevState) => ({
          ...prevState,
          displayList: res.data
        }))
      }).catch(() => {
        // errorHandler(error, dispatch)
      })
  }
  const selectAllFilteredUnits = (e) => {
    let allData = state.displayList
    let sensorDeviceids = [];

    allData.map(dt => {
      dt.check = e ? true : false;
      // Push the ID into the sensorDeviceids array
      if (e) {
        sensorDeviceids.push(dt.id);
      }
    });
    if (e) {
      setState((prevState) => ({
        ...prevState,
        allDevices: allData
      }))
    }
    else {
      setState((prevState) => ({
        ...prevState,
        allDevices: allData
      }))
    }

    props.getIdsfromChild(sensorDeviceids)

  }

  useEffect(() => {
    const allDevices = props?.allDevices || [];

    // Create an array to store unique models
    const uniqueModels = [];

    // Create a Set to keep track of seen models
    const seenModels = new Set();

    // Iterate through each device object
    allDevices.forEach(device => {
      const model = device.model;

      // Check if the model is not in the seenModels Set
      if (!seenModels.has(model)) {
        // Add the model to the uniqueModels array
        uniqueModels.push({ model });

        // Add the model to the seenModels Set to mark it as seen
        seenModels.add(model);
      }
    });

    // Now, uniqueModels contains an array of unique models
    const options = uniqueModels.map(modelObj => ({
      value: modelObj.model,
      label: modelObj.model,
    }));

    setState((prevState) => ({
      ...prevState,
      uniqueModels: options
    }))
  }, [props?.allDevices]);

  const { classes } = props
  const { form, editOption, isRecived } = state
  return (
    <div style={{ padding: 16 }}>
      {!isRecived ? (
        <Loader defaultStyle />
      ) : (
        <>
          <div className={classes?.paper + ' clearfix'}>
            <Grid container spacing={1}>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id='mode'
                  select
                  label={props.translate('mode')}
                  value={form.attributes?.mode || ''}
                  onChange={handleChange('mode')}
                  margin='dense'
                  fullWidth
                >
                  <MenuItem value=''>
                    <em> {props.translate('none')}</em>
                  </MenuItem>
                  {modeList.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {props.translate(option.key)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id='preset'
                  select
                  label={props.translate('preset')}
                  value={form.preset || ''}
                  onChange={handleChange('preset')}
                  margin='dense'
                  fullWidth
                >
                  <MenuItem value=''>
                    <em> {props.translate('none')}</em>
                  </MenuItem>
                  {preset.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {props.translate(option.key)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {checkPrivileges('settingCreate') && !state.editOption ?
                (<Button
                  variant='contained'
                  className={classes && classes.button}
                  style={{
                    float: 'right',
                    height: "30px",
                    width: "50px",
                    marginTop: "15px",
                    marginLeft: "10px"
                  }}
                  onClick={() => Submit()}
                  disabled={!state.isVisibleBtn}
                >
                  {' '}
                  {props.translate('sharedAdd')}
                </Button>
                ) : (
                  <Button
                    variant='contained'
                    className={classes?.button}
                    style={{
                      float: 'right',
                      height: "30px",
                      marginTop: "15px",
                      marginLeft: "10px"
                    }}
                    onClick={() => DeleteSubmit()}
                  >
                    {' '}
                    {props.translate('sharedRemove')}
                  </Button>)
              }

              {state.editOption && state.form.attributes && Object.keys(state.form && state.form.attributes).length > 0 ?
                <Button
                  variant='contained'
                  className={classes?.button}
                  style={{
                    float: 'right',
                    height: "30px",
                    marginTop: "15px",
                    marginLeft: "10px"
                  }}
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, showDialg: true }))}
                >
                  {' '}
                  {props.translate('copyEcoDriving')}
                </Button>
                : null
              }
            </Grid>
          </div>
          {editOption && (
            <EcoDrivingTable
              row={state.form.attributes}
              translate={props.translate}
              themecolors={themecolors}
              onUpdate={onUpdate}
              onDelete={onDelete}
              handleChange={handleChangeAttributesValue}
            />
          )}
        </>
      )}
      {state.showDialg && (
        <CustomDialog
          title='Devices'
          themecolors={themecolors}
          visable={true}
          onClose={() => setState((prevState) => ({ ...prevState, showDialg: false }))}
          bodyPadding={10}
          isVisableBtn
          noFullScreen
          headerActions={
            <Button
              disabled={!(props.sensorDeviceids && props.sensorDeviceids.length)}
              onClick={props.submitCopyEcoDriving}
            >
              {props.translate('copyEcoDriving')}
            </Button>
          }
        >
          <div>
            <Table
              onSelect={handleModelSelect}
              insideDialog
              disableHead
              showCheckbox={true}
              showGroupAccess={props.showGroupAccess}
              checkHandleChange={props.Devices_Selection || ''}
              rows={state.listData ? state.displayList : props?.allDevices || []}
              componentData={state.listData ? state.displayList : props?.allDevices || []}
              isEditable
              canRemove={true}
              canAssign={true}
              className='custom-table2'
              rowsPerPage={props.rowsPerPage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
              selectAll={state.listData ? selectAllFilteredUnits : props.selectAllCopySensors}
              copySensor
              searchable
              rowDefinition={[
                {
                  id: 'label',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedName')
                },
                {
                  id: 'category',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('category')
                },
                {
                  id: 'model',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('model')
                }
              ]}
            />
          </div>
        </CustomDialog>
      )}

      {props.openResponseDialg && (
        <CustomDialog
          title='Devices'
          themecolors={themecolors}
          visable={true}
          onClose={props.closeDialog}
          bodyPadding={10}
          isVisableBtn
          noFullScreen
        >
          <div>
            <Table
              insideDialog
              disableHead
              // showCheckbox= {true}
              showGroupAccess={props.showGroupAccess}
              rows={props?.copiedData || []}
              componentData={props?.copiedData || []}
              isEditable
              // canRemove={true}
              // canAssign={true}
              className='custom-table2'
              rowsPerPage={props.rowsPerPage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
              // selectAll={props.selectAll}
              rowDefinition={[
                {
                  id: 'label',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedName')
                },
                {
                  id: 'message',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('message')
                }
              ]}
            />
          </div>
        </CustomDialog>
      )}
    </div>
  )



}
export default EcoDriving

const preset = [
  {
    key: 'bus'
  },
  {
    key: 'truck'
  },
  {
    key: 'automobile'
  }
]
const modeList = [
  {
    key: 'default'
  },
  {
    key: 'device'
  } 
]