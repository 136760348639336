import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import isEqual from 'react-fast-compare'
import { withLocalize } from 'react-localize-redux'
import Layout from '../../Layout'
import MainUnitModal from './MainUnitModal'
import { removeUser } from './../../Actions/Users'
import Notifications from 'react-notification-system-redux'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { getGroups } from '../../Actions/Groups'
import EmptyState from '../../Components/common/EmptyState'
import { GroupsModal } from './../../Components/Groups/groupsModal'
import { checkPrivileges, errorHandler} from '../../Helpers/index'
import './Style.scss'
import instance from '../../axios'
import axios from 'axios'
let cancelTokenSource = null;

const groupDataFormat = {
  id: '',
  attributes: '',
  name: '',
  groupId: ''
}
const genteralOneFormFormat = {
  name: '',
  uniqueId: '',
  groupId: '',
  phone: '',
  devicePassword: ''
}

const advenceOneFormFormat = {
  category: '',
  positionId: ''
}
const ComputedAttributesDataFormat = {
  description: '',
  attribute: '',
  expression: '',
  type: ''
}
const NotificationDataFormat = {
  always: '',
  attributes: {},
  calendarId: '',
  id: '',
  notificators: '',
  type: ''
}
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: '',
  id: '',
  description: '',
  type: '',
  textChannel: ''
}
const maintenanceDataFormat = {
  attributes: {},
  name: '',
  type: '',
  start: '',
  period: ''
}

const Units = (props) => {

  // const { id } = useParams();

  const [state, setState] = useState({
    groups: [],
    page: 1,
    limit: 50,
    loading: false,
    hasMore: true,
    viewScreen: null,
    groupProcess: false,
    deviceProcess: false,
    dirverProcess: false,
    geofencesProcess: false,
    notificationsProcess: false,
    maintenanceProcess: false,
    computedAttributesProcess: false,
    commandsProcess: false,
    isVisableGSOneBtn: false,
    isVisableGSTwoBtn: false,
    isVisableASOneBtn: false,
    mulitDriversSelect: '',
    mulitTrailersSelect: '',
    mulitVehiclesSelect: '',
    multiGeofencesSelect: '',
    mulitNotificationSelect: '',
    multiComputedAttributesSelect: '',
    mulitSavedCommandsSelect: '',
    mulitMaintenanceSelect: '',
    multiAccumlatorsSelect: '',
    isDeviceRecived: false,
    isGroupSelected: false,
    isDriverRecived: false,
    isTrailerRecived: false,
    isVehicleRecived: false,
    isGroRecived: false,
    isNotificationRecived: false,
    isComputedAttributesRecived: false,
    isSavedCommandsRecived: false,
    isMaintenanceRecived: false,
    isAccumlatorsRecived: false,
    attributeData: '',
    componentType: '',
    isNewAddEnable: false,
    activeOperation: '',
    addButtonStatus: 'sharedAdd',
    isAddVisableBtn: false,
    isAddAttributes: false,
    itemAttributes: '',
    defaultAttributes: {},
    selecteItem: '',
    computedAttForm: { ...ComputedAttributesDataFormat },
    visableAttribute: false,
    visableListAttribute: false,
    attributeCallStatus: '',
    notificationForm: { ...NotificationDataFormat },
    visableNotification: false,
    visableListNotification: false,
    notificationCallStatus: '',
    maintenanceForm: { ...maintenanceDataFormat },
    visableMaintenance: false,
    visableListMaintenance: false,
    maintenanceCallStatus: '',
    savedCommandsForm: { ...savedCommandsDataFormat },
    visableSavedCommands: false,
    visableListSavedCommands: false,
    commandsCallStatus: '',
    gOneform: { ...genteralOneFormFormat },
    aOneform: { ...advenceOneFormFormat },
    resFormData: { ...groupDataFormat },
    isVisable: false,
    selecteditem: '',
    unassignedDevices: [],
    mulitGroupSelect: [],
    initFetch: false,
    currentPage: 1,
    pagesize: 50,
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 0,
      currentDevice: props.deviceId,
      hasNext: true,
      searchText: ''
    },
    currentPage2: 1,
    pageSize2: 50,
    itemPagination2: {
      items: [],
      total: 0,
      currentPage: 0,
      currentDevice: props.deviceId,
      hasNext: true,
      searchText2: ''
    }
  })

  useEffect(() => {
    if (state.groups) {
      props.dispatch(getGroups(state.groups));
    }
  }, [state.groups, props.dispatch]);



  useEffect(() => {
    if (props.match.params.id) {
      setState((prevState) => ({ ...prevState, viewScreen: 'edit' }));
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (props.match.params.id) {
      setState((prevState) => ({ ...prevState, viewScreen: 'edit' }));
    }

    if (
      checkPrivileges('group') &&
      props.logInUsers.id !== state.logInUsersId
    ) {
      setState((prevState) => ({
        ...prevState,
        page: 1,
        groups: [],
        hasMore: true,
        logInUsersId: props.logInUsers.id,
      }));
    }
  }, [props.logInUsers]);

  // useEffect(()=>{

  //   fetchGroups(state.page, state.limit, props.logInUsers);
    
  // },[state.page,state.limit, props.logInUsers])

  useEffect(()=>{
    fetchGroups(1, 50, props.logInUsers,true);
  },[state.searchText2])


    useEffect(() => {
    return () => {
      setState({
        ...state,
        viewScreen: null,
        groupProcess: false,
        deviceProcess: false,
        dirverProcess: false,
        geofencesProcess: false,
        notificationsProcess: false,
        maintenanceProcess: false,
        computedAttributesProcess: false,
        commandsProcess: false,
        isVisableGSOneBtn: false,
        isVisableGSTwoBtn: false,
        isVisableASOneBtn: false,
        mulitDriversSelect: '',
        mulitTrailersSelect: '',
        mulitVehiclesSelect: '',
        multiGeofencesSelect: '',
        mulitNotificationSelect: '',
        multiComputedAttributesSelect: '',
        mulitSavedCommandsSelect: '',
        mulitMaintenanceSelect: '',
        multiAccumlatorsSelect: '',
        isDeviceRecived: false,
        isGroupSelected: false,
        isDriverRecived: false,
        isTrailerRecived: false,
        isVehicleRecived: false,
        isGroRecived: false,
        isNotificationRecived: false,
        isComputedAttributesRecived: false,
        isSavedCommandsRecived: false,
        isMaintenanceRecived: false,
        isAccumlatorsRecived: false,
        attributeData: '',
        componentType: '',
        isNewAddEnable: false,
        activeOperation: '',
        addButtonStatus: 'sharedAdd',
        isAddVisableBtn: false,
        isAddAttributes: false,
        itemAttributes: '',
        defaultAttributes: {},
        selecteItem: '',
        computedAttForm: { ...ComputedAttributesDataFormat },
        visableAttribute: false,
        visableListAttribute: false,
        attributeCallStatus: '',
        notificationForm: { ...NotificationDataFormat },
        visableNotification: false,
        visableListNotification: false,
        notificationCallStatus: '',
        maintenanceForm: { ...maintenanceDataFormat },
        visableMaintenance: false,
        visableListMaintenance: false,
        maintenanceCallStatus: '',
        savedCommandsForm: { ...savedCommandsDataFormat },
        visableSavedCommands: false,
        visableListSavedCommands: false,
        commandsCallStatus: '',
        gOneform: { ...genteralOneFormFormat },
        aOneform: { ...advenceOneFormFormat },
        resFormData: { ...groupDataFormat },
        isVisable: false,
        selecteditem: '',
        unassignedDevices: [],
        mulitGroupSelect: [],
        initFetch: false,
        currentPage2: 1,
        pageSize2: 50,
        itemPagination2: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: props.deviceId,
          hasNext: true,
          searchText2: '',
        },
      });
    };
  }, []);


  const fetchGroups = async(page, limit, logInUsers,reset=false) => {
    if (checkPrivileges('group') && logInUsers && logInUsers.id) {
      if(cancelTokenSource){
        cancelTokenSource.cancel("")
      }

      cancelTokenSource=axios.CancelToken.source()
      let searchText=state.searchText2 || ""
      if(reset||searchText){
        setState((prevState) => ({
          ...prevState,
          loading: true,
          groupsFetched: true,
          itemPagination2:{...prevState.itemPagination2,items:[]}
        }));
      }

      await instance({
        method: 'GET',
        url: `/api/groups/get?userId=${logInUsers.id}&page=${page || state.page}&limit=${limit|| state.limit}&search=${searchText}`,
        cancelToken: cancelTokenSource.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          const totalGroupsData = response?.total;
          const getGroupsData = response?.data;
          if (getGroupsData && getGroupsData.length > 0) {
            const newGroups = getGroupsData?.map((item) => ({
              ...item,
              check: false,
            }));
            setState((prevState) => {
              const combinedGroups =reset || searchText
              ? newGroups: [...prevState.groups, ...newGroups];
              const uniqueGroups = combinedGroups.filter(
                (group, index, self) =>
                  index === self.findIndex((g) => g.id === group.id)
              );
              return {
                ...prevState,
                groups: uniqueGroups,
                loading: false,
                hasMore: uniqueGroups.length < totalGroupsData,
                page:response.page
              };
            });
          } else {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              hasMore: false,
            }));
          }
        })
        .catch(() => {
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        });
    }
  };

  const handleScroll = (values) => {
    const { scrollTop, clientHeight, scrollHeight,zoomLevel } = values;
    const {  hasMore } = state;
    let threshold;
    if (zoomLevel === 1) {
      threshold = 26; 
    } else if (zoomLevel < 1) {
      threshold = 26 -  zoomLevel
    } else {
      threshold = 26 +  zoomLevel; 
    }
    if (hasMore) {
      if (Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight)-threshold) {
    fetchGroups(state.page+1, state.limit, props.logInUsers);

  }}}


  const fetchMoreItems2 = () => {
    setState(prevState => ({
        ...prevState,
        searchText2: ''
      }))
      // fetchData2(
      //   props.logInUsers.id,
      //   state.currentPage2,
      //   state.pageSize2,
      //   true
      // )

      
  }

  const searchItems2 = (text) => {
    setState(prevState => ({
      ...prevState,
      searchText2: text,
    }));
  };

  // const fetchData2 = (userId, page, perPage, reset) => {
  //   // let searchText = state.searchText2 ? `&search=${state.searchText2}` : '';

  //   let items = state.itemPagination2.items;

  //   if (reset) {
  //     setState(prevState => ({
  //       ...prevState,
  //       itemPagination2: { ...prevState.itemPagination2, items: [] }
  //     }));
  //     items = [];
  //   }

  //   instance({
  //     url: `/api/groups/get`,
  //     method: `GET`,
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     params: {
  //       page: page,
  //       limit: perPage,
  //       search: state.searchText2 || ''
  //     }
  //   })
  //     .then(res => {
  //       removeDuplicates(res, items);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching data", error);
  //     });
  // };

  // const removeDuplicates = (n, o) => {
  //   let items = [];
  //   o.forEach(j => {
  //     let found = n.data.find(e => e.id === j.id);
  //     if (found) {
  //       items.push(found);
  //     }
  //   });

  //   if (!items.length) {
  //     setState({
  //       itemPagination2: {
  //         ...n,
  //         items: o.concat(n.data)
  //       },
  //       currentPage2: n.hasNext ? n.page + 1 : n.page
  //     });
  //   }
  // };

  const addNewGroup = () => {
    props.history.push('/resources');
    setState((prevState) => ({ ...prevState, viewScreen: 'add' }));
  };

  const closeScreen = () => {
    setState((prevState) => ({ ...prevState, viewScreen: '' }));
  };

  const removedItem = () => {
    const { selectedItem } = state;

    if (selectedItem.id) {
      instance({
        url: `/api/groups/${selectedItem.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: selectedItem
      })
        .then(() => {
          dispatch(removeUser(selectedItem));
          dispatch(
            Notifications.success({
              message: props.translate('groupDeleted'),
              autoDismiss: 10
            })
          );
          onCancel();
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
    }
  };

  const onCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      isNewAddEnable: false,
      activeOperation: '',
      isVisable: false
    }), () => {
      closeScreen();
    });
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmation: false,
      selectedItem: '',
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationCommand: false,
      viewScreen: null
    }), () => {
      props.history.push('/resources');
    });
  };

  const onRemovedGroup = (item) => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmation: true,
      selectedItem: item
    }));
  };

  const checkedDeviceLimitBackCall = () => {
    setState((prevState) => ({
      ...prevState,
      checkeDeviceLimit: false,
    }));
  };

  if (checkPrivileges('group')) {

    const endMessage =
      props.itemPagination && props.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {props.translate('notFound')}{' '}
        </p>
      )

    return (
      <Layout
        {...props}
        goAddGroup={addNewGroup}
        removedItem={onRemovedGroup}
        checkedDeviceLimit={checkedDeviceLimitBackCall}
        classFromChildren='has-padding'
        // fetchMoreItems={fetchMoreItems}
        itemPagination={{ ...state.itemPagination, endMessage }}
        // searchItems={searchItems}
        fetchMoreItems2={fetchMoreItems2}
        itemPagination2={{ ...state.itemPagination2 }}
        closeScreen={closeScreen}
        searchItems2={searchItems2}
        handleScrollResource={handleScroll}
      >
        <div
          className='main-content-page'
          style={{
            background: props.themecolors.backgroundColor,
            color: props.themecolors.textColor,
            borderRadius: 6
          }}
        >
          {state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={onCancel}
              onOk={removedItem}
              title={props.translate('areYouWantToDelete')}
              children={state.selectedItem.name}
            />
          )}

          {state.viewScreen === 'add' ? (
            <GroupsModal
              {...props}
              viewScreen={state.viewScreen}
              onCloseModal={onCloseModal}
              activeOperation={state.activeOperation}
              selecteditem={state.resFormData}
            />
          ) : (
            <div>
              {state.viewScreen === 'edit' ? (
                <MainUnitModal
                  id={props.match.params.id}
                  history={props.history}
                  onCloseModal={onCloseModal}
                />
              ) : (
                <EmptyState text={props.translate('noResourceSelected')} />
              )}
            </div>
          )}
        </div>
      </Layout>
    )
  } else {
    return null
  }
}

const mapState = state => ({
  users: state.users,
  groups: state.groups,
  drivers: state.drivers,
  trailers: state.trailers,
  vehicles: state.vehicles,
  devices: state.devices,
  geofences: state.geoFence,
  themecolors: state.themeColors,
  notification: state.notification,
  selectednotification: state.selectednotification,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  calendars: state.calendars,
  logInUsers: state.logInUsers,
  category: state.category,
  ServerSetting: state.ServerSetting
})

const mapStateToProps = connect(mapState)
export default mapStateToProps((withLocalize(Units)))
