import React,{useEffect, useState} from 'react';
import { Collapse, FormControl, FormControlLabel, Grid, IconButton, Paper, RadioGroup, Tooltip } from '@mui/material'
import Switch from '../../Components/common/Switch'
import DeleteIcon from '@mui/icons-material/Delete'
import { ReactComponent as AddNewIcon } from '../../assets/add-new.svg'
import Radio from '../../Components/common/Radio'
import TextField from '../../Components/common/TextField'
import { validEmailPattern } from '../../Helpers'
import Notifications from 'react-notification-system-redux'

const SettingForm1 = (props) => {

  const[state,setState] = useState({
    email: [""],
    emails: "",
    mailTo: "user",
    notificators: "web,firebase",
    notifications: ['web', 'firebase']
  })

  
//   useEffect(()=>{
//     if(props.mode === 'create') {
//       saveToParent();
//     }
//     else {
//       setState((prevState)=>({
// ...prevState,
//         notificators: props.form.notificators || state.notificators,
//         // alertColor: props.form.alertColor || state.alertColor,
//         mailTo: props.form.mailTo || state.mailTo,
//         emails: props.form.emails || state.emails,
//         email: props.form.emails ? props.form.emails.split(',') : state.emails,
//         notifications: props.form.notificators ? props.form.notificators.split(',') : state.notifications,
//       }))
//       saveToParent()
//     }
//   },[])


  useEffect(()=>{

      if(props.mode === 'create') {
        saveToParent();
      }
      else {
        setState((prevState)=>({
          ...prevState,
          notificators: props.form.notificators || state.notificators,
          // alertColor: props.form.alertColor || state.alertColor,
          mailTo: props.form.mailTo || state.mailTo,
          emails: props.form.emails || state.emails,
          email: props.form.emails ? props.form.emails.split(',') : state.emails,
          notifications: props.form.notificators ? props.form.notificators.split(',') : state.notifications,
        }))
        saveToParent()
      }
  },[props.mode])


  useEffect(()=>{
    saveToParent()
  },[state.notifications])


   const saveToParent = () => {
      const { email, mailTo, notifications/* , alertColor */ }  = state;
      props.saveToParent({ emails: email.toString(), mailTo/* , alertColor */, notificators: notifications.toString() })
    } 
    
   const handleClick = (name, event) => {
      
      setState(prevState=>({
        ...prevState,
        notifications: event.target.checked ? prevState.notifications.concat(name) :prevState.notifications.filter(r => r !== name)
      }))
    }
  
   const handleChange = (name, event, index) => {
      if(name === 'email') {
        const email = state.email.map((e, i) => {
          if(i === index) {
            return event.target.value;
          }
          else {
            return e
          }
        })
        setState(prevState=>({
          ...prevState,
          email
        }))
      } else {
        if(name === 'mailTo') {
          setState(prevState=>({
            ...prevState,email: [""]}))
        }
        setState(prevState=>({...prevState,[name]: event.target.value}))
      }
    }

    useEffect(() => {
      saveToParent()
    }, [state.email]);

  
   const onAddAnotherMail = (type) => {
      let { email } = state
      let formateValidation = true
      if (type === 'email') {
        email.map(itam => {
          if (!validEmailPattern.test(itam)) {
            formateValidation = false
          }
          return null
        })
        if (formateValidation) {
          email.push('')
          setState(prevState=>({
            ...prevState,
            email: email
          }))
        } else {
          props.dispatch(
            Notifications.error({
              message:props.translate('enterEmail'),
              autoDismiss: 10
            })
          )
        }
      }
    }
  
   const deleteMail = (type, id) => {
      let data = state[type]
      if (data.length === 1 && id === 1) {
        setState(prevState=>({
          ...prevState,
            [type]: ['']
        }))
      } else {
        let newList = data.filter((itm, i) => i !== id)
        setState(prevState=>({
          ...prevState,
            [type]: newList
        }))
      }
    }
  
  
      const paperStyle = {
        background: 'none',
        marginBottom: 5,
        paddingTop: 0,
        paddingBottom: 0
      }
      return (<div>
        <h3>{props.translate('notificators')}</h3>
        <Grid container>
          <Grid sm={props.fullWidth ? 12 : 4} xs={12} >
            <Paper
              className={' ' + (state.notifications.includes('mail') ? 'opened ': '')}
              style={paperStyle}
              elevation={0}
            >
              <h4
                className='page-title-notification'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent:"space-between",
                  margin: 0,
                  padding: 0
                }}
              >
                <span>{props.translate('emails')}</span>
                <FormControlLabel
                  style={{ marginLeft: 'auto' }}
                  control={
                    <Switch
                      checked={state.notifications.includes('mail')}
                      onChange={e => handleClick('mail', e)}
                      name='mail'
                    />
                  }
                  label={
                    state.notifications.includes('mail')
                      ? props.translate('enable')
                      : props.translate('disable')
                  }
                  labelPlacement='start'
                  classes={{ label: 'color-inherit' }}
                />
              </h4>
              <Collapse in={state.notifications.includes('mail')}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl
                      component='fieldset'
                      style={{ width: 'max-content' }}
                    >
                      <RadioGroup
                        row
                        aria-label='position'
                        name='position'
                        value={state.mailTo}
                        onChange={e => handleChange('mailTo', e)}
                      >
                        <FormControlLabel
                          classes={{
                            label: 'form-contol-label'
                          }}
                          value='user'
                          control={<Radio style={{color:"white"}} />}
                          label={props.translate('userEmail')}
                          labelPlacement='end'
                          disabled={!state.notifications.includes('mail')}
                        />
                        <FormControlLabel
                          classes={{
                            label: 'form-contol-label'
                          }}
                          value='custom'
                          control={<Radio style={{color:"white"}} />}
                          label={props.translate('customEmails')}
                          labelPlacement='end'
                          disabled={!state.notifications.includes('mail')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {(state.mailTo !== 'user' && state.email.length) ? (
                    <Grid item xs={12}>
                      {state.email && state.email.map((item, index) => {
                        return (
                          <div style={{ display: 'flex',alignItems:"center" }} key={index}>
                            <TextField
                              id='email'
                              label={props.translate('userEmail')}
                              placeholder=''
                              value={item || ''}
                              onChange={e =>
                                handleChange('email', e, index)
                              }
                              disabled={!state.notifications.includes('mail')}
                              variant='outlined'
                              margin='dense'
                              fullWidth
                            />
                            {(index + 1)  === state.email.length && (
                              <Tooltip title={props.translate('addAnother')}>
                                <span>
                                  <IconButton
                                    color='inherit'
                                    aria-label='directions'
                                    onClick={() => onAddAnotherMail('email')}
                                    disabled={!state.notifications.includes('mail')}
                                  >
                                    <AddNewIcon
                                      width={18}
                                      height={18}
                                      style={{ fill: 'currentColor' }}
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                            {1 < state.email.length && (
                              <Tooltip title={props.translate('delete')}>
                                <span>
                                  <IconButton
                                    color='inherit'
                                    aria-label='directions'
                                    onClick={() =>
                                      deleteMail('email', index)
                                    }
                                    disabled={!state.notifications.includes('mail')}
                                  >
                                    <DeleteIcon
                                      width={18}
                                      height={18}
                                      style={{ fill: 'currentColor' }}
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        )
                      })}
                    </Grid>
                  ) : null }
                </Grid>
              </Collapse>
            </Paper>
            <Paper
              className={' ' + (state.notifications.includes('web') ? 'opened' : '')}
              style={paperStyle}
              elevation={0}
            >
              <h4
                className='page-title-notification'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent:"space-between",
                  margin: 0,
                  padding: 0
                }}
              >
                <span>{props.translate('webPopUp')}</span>
                <FormControlLabel
                  style={{ marginLeft: 'auto' }}
                  control={
                    <Switch
                      checked={state.notifications.includes('web')}
                      onChange={e => handleClick('web', e)}
                      name='checkedA'
                    />
                  }
                  label={
                    state.notifications.includes('web')
                      ? props.translate('enable')
                      : props.translate('disable')
                  }
                  labelPlacement='start'
                  classes={{ label: 'color-inherit' }}
                />
              </h4>
              
            </Paper>
            <Paper className='' style={paperStyle} elevation={0}>
              <h4
                className='page-title-notification'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent:"space-between",
                  margin: 0,
                  padding: 0
                }}
              >
                <span>{props.translate('mobileNotifications')}</span>
                <FormControlLabel
                  style={{ marginLeft: 'auto' }}
                  control={
                    <Switch
                      checked={state.notifications.includes('firebase')}
                      onChange={e => handleClick('firebase', e)}
                      name='checkedA'
                    />
                  }
                  label={
                    state.notifications.includes('firebase')
                      ? props.translate('enable')
                      : props.translate('disable')
                  }
                  labelPlacement='start'
                  classes={{ label: 'color-inherit' }}
                />
              </h4>
            </Paper>
            {props.smsNotification && (<Paper className='' style={paperStyle} elevation={0}>
              <h4
                className='page-title-notification'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: 0,
                  padding: 0
                }}
              >
                <span>{props.translate('sendSMStoDriver')}</span>
                <FormControlLabel
                  style={{ marginLeft: 'auto' }}
                  control={
                    <Switch
                      checked={state.notifications.includes('sms')}
                      onChange={e => handleClick('sms', e)}
                      name='checkedA'
                    />
                  }
                  label={
                    state.notifications.includes('sms')
                      ? props.translate('enable')
                      : props.translate('disable')
                  }
                  labelPlacement='start'
                  classes={{ label: 'color-inherit' }}
                />
              </h4>
            </Paper>)}
          </Grid>
        </Grid>
      </div>)
      }
  
    export const SettingForm = SettingForm1