import React, { Component } from 'react'
import './../Login/Style.scss'
import { connect } from 'react-redux'
import Loader from '../../Layout/Loader';
import Style from 'style-it'
import instance from '../../axios'
import Notifications from 'react-notification-system-redux'

class ValidateEmail extends Component {
  constructor (props) {
    super(props)
    this.state = {
        loading: true,
    }
  }

  componentWillMount () {
    const currentURL = window.location.href;  
    const urlData = currentURL.split("?")[1].split("&");
    let urlEmail = urlData[0].split("email=")
    let urlToken = urlData[1].split("dG9rZW5Gb3JQYXNzd29yZFJlc2V0=")
 
         instance({
            url: `/api/users/validateemail`,
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:new URLSearchParams({
                email: urlEmail[1],
                dG9rZW5Gb3JQYXNzd29yZFJlc2V0: urlToken[1]
            })
           })
          .then(response => {
            if(response){
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('emailVerified'),
                  autoDismiss: 10
                })
              )
             this.setState({
              loading:false
             },()=>{this.props.history.push('/login') }) 
            }
         }).catch(error => {
          this.props.dispatch(
            Notifications.success({
              message:this.props.translate(error.message),
              autoDismiss: 10
            })
          )
        })
  }

  


  render () {
    return (
    <div className="loginWrapper">
        <Style>
          {`
          a {
            color: ${this.props.themecolors.textColor}
          }
          .loginWrapperInner {
            background: ${this.props.themecolors.backgroundColor}
          }
          .login-footer {
            border-top: 1px solid ${this.props.themecolors.themeLightColor};
          }
          `}
          
        </Style>
          {this.state.loading ? 
                <Loader />
            :null
          }
         <div className="white-label-bg" style={{backgroundImage: `url(${this.props.whiteLabling.logInPageImage})`}}></div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
     return {
      themecolors: state.themeColors
    }
  }
// export const ValidateEmail = mapStateToProps(withLocalize(withSnackbar(ValidateMd)))
// export default connect(mapStateToProps)(withSnackbar(withLocalize(Reports)))



export default connect(mapStateToProps)((ValidateEmail))