import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { checkPrivileges } from '../../Helpers';
import {
  ChangedPasword,
  ServicePreferences,
  UserDefault,
  Permissions,
  ReleaseHistory
} from './index'

const CustomTab = styled(Tab)(() => ({
  opacity: 0.7,
  transition: 'opacity 0.3s ease-in-out',
  minWidth:"160px",
  fontWeight:700,
  '&.Mui-selected': {
    opacity: 1,
  },
}));

const accountManagements = (props) => {
  const [value, setValue] = useState('tab1');

  const logInUser = useSelector(state => state.logInUsers);
  const themeColors = useSelector(state => state.themeColors);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (logInUser.id) {
    return (
      <div style={{ paddingTop: 17}}>
        <AppBar
          position='static'
          color='inherit'
          style={{ background: 'none', boxShadow: 'none' }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: { background: themeColors.backgroundColor }
            }}
            scrollButtons='on'
            classes={{
              root: 'custom-tabs-root',
              flexContainer: 'custom-tabs',
              scrollable: 'custom-tabs-scrollable',
              indicator: 'custom-indicator',
              scrollButtons: 'scrollable-buttons'
            }}
          >
            <CustomTab
              value='tab1'
              classes={{
                root: 'custom-tab-button',
                selected: 'custom-tab-button-selected'
              }}
              label={props.translate('sharedPreferences')}
            />
            {logInUser && logInUser.userType !== 1 && (
              <CustomTab
                value='tab2'
                classes={{
                  root: 'custom-tab-button',
                  selected: 'custom-tab-button-selected'
                }}
                label={props.translate('sharedPermissions')}
              />
            )}
            <CustomTab
              value='tab3'
              classes={{
                root: 'custom-tab-button',
                selected: 'custom-tab-button-selected'
              }}
              label={props.translate('sharedUserDefault')}
            />
     
            {checkPrivileges("userChangePassword") && (
              <CustomTab
                value='tab4'
                classes={{
                  root: 'custom-tab-button',
                  selected: 'custom-tab-button-selected'
                }}
                label={props.translate('sharedChangedPassword')}
              />
            )}
             {/* <Tab
              value='tab5'
              classes={{
                root: 'custom-tab-button',
                selected: 'custom-tab-button-selected'
              }}
              label={props.translate('ReleaseHistory')}
            /> */}
            
          </Tabs>
        </AppBar>
        <Paper
          style={{ padding: 16, minHeight: 270, backgroundColor: themeColors.backgroundColor,paddingTop:0,zIndex:99,position:"relative" }}
          className='custom-menu-paper'
          square={true}
        >
          {value === 'tab1' && <ServicePreferences translate={props.translate} {...props} />}
          {logInUser && logInUser.userType !== 1 && value === 'tab2' && <Permissions {...props} logInUser={props.logInUser} />}
          {value === 'tab3' && <UserDefault {...props} translat={props.translate} />}
          {value === 'tab4' && checkPrivileges("userChangePassword") && <ChangedPasword {...props} />}
          {/* {value === 'tab5' && <VerifiedEmails {...props} />} */}
          {value === 'tab5' && <ReleaseHistory {...props} />}        
        </Paper>
      </div>
    );
  } else {
    return null;
  }
};

const mapState = state => ({
  users: state.users,
  devices: state.devices.data,
  groups: state.groups,
  logInUser: state.logInUsers,
  themecolors: state.themeColors
});

const mapStateToProps = connect(mapState);

export const AcManagements = mapStateToProps(accountManagements);
