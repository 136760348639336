import React from 'react';
import { connect } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Link } from 'react-router-dom';
import { checkPrivileges } from '../../Helpers';
import { ReactComponent as Vehicle } from '../../assets/nav/commute.svg';
import { ReactComponent as IntegratedWasl } from '../../assets/monitoring/wasl.svg';
import { ReactComponent as NotIntegratedWasl } from '../../assets/monitoring/wasl-cross.svg';
import InfoIcon from './../../assets/monitoring/info.svg';

// Define ListRow component
const ListRow = (props) => {
  const { waslEnabled } = props?.ServerSetting?.attributes || {};
  const { id, label, deviceId, attributes: { WASLKey } } = props.item;
  const device = props.allVehicleLocation && props.allVehicleLocation[deviceId] || null;


const handleFetchIds=(childId,parentId)=>{
  if(childId){
    props.removedItem(childId)
  }
  if(parentId){
    props.getParentIdFromListRow(parentId)
  }
}
  return (
    <li className={
      'clearfix list-row ' +
      (parseInt(props.match.params.id) === parseInt(id) ? 'active' : '')
    }>
      <Link to={'/vehicle/' +props.item.id}>
        <label className='checkbox' onClick={() =>props.selecteItem(props.item.id)}>
          <span className='unit-name' style={{ minWidth: 140, maxWidth: 140, marginLeft: 0 }}>
            <span style={{ marginRight: '5px', display: 'inline-block', verticalAlign: 'middle' }}>
              <Vehicle width={24} height={24} style={{ display: 'block', fill: 'currentColor' }} />
            </span>
            {label}
          </span>
        </label>
      </Link>
      <div className='pull-right'>
        {waslEnabled ? (WASLKey ? <IntegratedWasl width={32} style={{ fill: 'currentColor' }} /> : <NotIntegratedWasl width={32} style={{ fill: 'currentColor' }} />) : ''}
        {waslEnabled && (
          <span className='action-item hovered' title={props.translate('waslDetails')} onClick={() =>  props.removedItem(props.item)}>
            <svg fill='currentColor' width={16} height={16}>
              <use xlinkHref={`${InfoIcon}#icon`} />
            </svg>
          </span>
        )}
        {checkPrivileges('vehicleDelete') && (
          <span className='action-item hovered' title={props.translate('delete')} onClick={() =>handleFetchIds(props.item,props?.collapseIds)}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </span>
        )}
        {device ? (
          <span>
            <img width={24} height={24} style={{ display: 'block' }} src={'/assets/category/default/' + (device.category || 'default') + '.svg'} alt='' />
          </span>
        ) : (
          <span className='action-item'>
            <LinkOffIcon style={{ fontSize: 16 }} />
          </span>
        )}
      </div>
    </li>
  );
};

// Define ListRows component
const ListRows = (props) => {
  if (props.children.length) {
    return (
      <ul className='list-view'>
        {props.children.map(data => (
          <ListRow key={data.id} item={data} {...props} />
        ))}
      </ul>
    );
  } else {
    return '';
  }
};

// Define vehicle component
const vehicle = (props) => {
  // const list = props.vehicles && props.itemPagination && props.itemPagination.total >= 0
  //   ? props.vehicles.filter(d => {
  //     return (d.id + d.label + d.model + d.deviceId)
  //       .toLowerCase()
  //       .includes((props.search || '').toLowerCase());
  //   })
  //   : [];
const list=props.userVehicles?props.userVehicles:[]
  let data = '';
  const loading = [];
  if (list.length) {
    data = list.map(vehicle => (
      <ListRow
        key={vehicle.id}
        item={vehicle}
        {...props}
        removedItem={props.removedItem}
        editItem={props.editItem}
        showWaslDetails={props.showWaslDetails}
        selecteItem={props.selecteItem}
        selectedItemId={props.selectedItemId}
      />
    ));
  } else {
    return (
      <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold' }}>
        {props.translate('notFound')}
      </p>
    );
  }
  return (
    <>
      {data && (
        <div className='individual-view'>
          <ul className='list-view with-padding-right'>{data}</ul>
        </div>
      )}
      {loading}
    </>
  );
};

const mapState = (state) => {
  return {
    vehicles: state.vehicles
  };
};

const mapStateToProps = connect(mapState);
const VehicleShortList = mapStateToProps(vehicle);

export { VehicleShortList, ListRows, ListRow };
