import React, { useState ,useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import UncheckIcon from './../../assets/nav/uncheck-box.svg'
import CheckIcon from './../../assets/nav/check-box.svg'

const mapStateToProps = (state) => ({
  themecolors: state.themeColors,
});

const CheckboxLabels = (props) => {

    const[state,setState] = useState ({
      checked: props.checked,
      indeterminate: props.indeterminate || false,
    })


    useEffect(()=>{
      setState(prevState=>({
        ...prevState,
        checked: props.checked,
        indeterminate: props.indeterminate || false,
      }))
    },[props.checked,props.indeterminate])
  

 const handleChange = (name) => (event) => {
    if (event.target.checked === true && !props.canAssign) {
      props.dispatch(
        Notifications.error({
          message: 'No Access for assign',
          autoDismiss: 5,
        })
      );
    }
    if (event.target.checked === false && !props.canRemove) {
      props.dispatch(
        Notifications.error({
          message: 'No Access for unassign',
          autoDismiss: 5,
        })
      );
    }
    if (event.target.checked === true && props.canAssign) {
      setState(prevState=>({
        ...prevState,checked: event.target.checked }));
      props.onChange(event, name);
    } else if (event.target.checked === false && props.canRemove) {
      setState(prevState=>({
        ...prevState,checked: event.target.checked }));
      props.onChange(event, name);
    }
  };

    const { checked, indeterminate } = state;
    return (
      <div style={{ margin: '5px' }}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                icon={
                  <svg  stroke="white" width={18} height={18}>
                    {/* Add your UncheckIcon rendering logic here */}
                    <use color='white' xlinkHref={`${UncheckIcon}#icon`} />
                  </svg>
                }
                checkedIcon={
                  <svg stroke="white" width={18} height={18}>
                    {/* Add your CheckIcon rendering logic here */}
                    <use color='white' xlinkHref={`${CheckIcon}#icon`} />
                  </svg>
                }
                disableRipple
                checked={checked ? true : false}
                indeterminate={indeterminate}
                readOnly={props.readonly}
                onChange={handleChange(
                  props.value || props.id
                )}
                style={{ margin: '3px', color: '#fff' }}
                color="default"
              />
            }
            label={props.label || ''}
            style={{ marginLeft: '5px', fontSize: '0.875rem', color: 'white' }}
          />
        </div>
      </div>
    );
  }

export default connect(mapStateToProps)(CheckboxLabels);
