import React, { PureComponent } from 'react'
import Menu from '@mui/material/Menu'

export default class CommonMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.state = {
      anchorEl: null
    }
  }

  handleClose () {
    if(this.props.onClose) {
        this.props.onClose()
    }
  }

  componentWillReceiveProps (NextProps) {
    this.setState({ anchorEl: NextProps.anchorEl })
  }

  render () {
    return (<Menu
        {...this.props}
        open={Boolean(this.props.anchorEl)}
        onClose={this.handleClose}
        classes={{paper: 'custom-menu-paper'}}
      >
       {this.props.children}
      </Menu>)
  }
}
