import React from 'react'
import { Grid } from '@mui/material'
import { checkPrivileges } from '../../Helpers'
import Loader from '../../Layout/Loader'
import Button from '../../Components/common/Button'
import Table from '../../Components/common/tableWithBackEndPagination'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
export const MaintenanceForm = ({
    data,
    isDataRecieved,
    translate,
    setPagination,
    handleChangeRowsPerPage,
    handleChangePage,
    SearchItem,
    themecolors,
    openCreateFrom,
    onRemove,
    onStatusChange,
    onMarkComplete,
    switchMode,
    mode,
    editMaintenanceForm,
    // dueActive,
    logInUser,
    vehicles
  }) => {
    return (
      <div>
     {<Grid container style={{ height: '100%' }}>
        <Grid item sm={12} xs={12}>
            {isDataRecieved ? (<Table
              rows={data&&data.data || []}
              pagination={setPagination(data)}
              handleChangeRowsPerPage={(n)=>handleChangeRowsPerPage(n,'maintenance')}
              handleChangePage={(n)=>handleChangePage(n,'maintenance')}
              SearchItem={(n)=>SearchItem(n,'maintenance')}
              onEdit={(n)=>editMaintenanceForm(n)}
              vehicles={vehicles}
              createButton={
                checkPrivileges('maintenanceCreate') && (
                  <div style={{ marginLeft: 10 }}>
                    <Button onClick={openCreateFrom}>
                      {translate('Create')}
                    </Button>
                  </div>
                )
              }
              logInUser={logInUser}
              // switchMode={<>
              //     <div style={{ marginLeft: 0 }} >
              //     <Button onClick={()=>switchMode('showMaintenance')}
              //       className={'button-active'}>
              //       {translate('Maintenance')}</Button></div>
              //     <div style={{ marginLeft: 5 }} >
              //     <Button onClick={()=>switchMode('showHist','due')}
              //       className={dueActive ? 'button-active' : 'button-inactive'}>
              //       {/* className={'button-active'}> */}
              //       {translate('work')+' '+translate('due')}</Button></div>
              //     <div style={{ marginLeft: 5 }} >
              //       <Button onClick={()=>switchMode('showHist','history')}
              //       className={'button-inactive'}>
              //       {/* className={'button-active'}> */}
              //     {translate('maintenanceHistory')}</Button></div></>}
              switchMode={
                <>
                <AppBar
                position='static'
                color='inherit'
                className='custom-tabs-root-border'
                style={{ background: 'none', boxShadow: 'none' }}
              >
                <Tabs
                  value={mode}
                  onChange={switchMode}
                  TabIndicatorProps={{
                    style: { background: themecolors.backgroundColor }
                  }}
                  scrollButtons='on'
                  classes={{
                    root: 'custom-tabs-root',
                    flexContainer: 'custom-tabs-maintenance',
                    scrollable: 'custom-tabs-scrollable',
                    indicator: 'custom-indicator',
                    scrollButtons: 'scrollable-buttons'
                  }}
                >
                    {checkPrivileges('service') && (
                    <Tab
                      value={'due'}
                      classes={{
                        root: 'custom-tab-button custom-tab-button-1',
                        selected: 'custom-tab-button-selected'
                      }}
                      label={translate('maintenanceRequest')}
                      style={{ padding: '6px 12px' }}
                    />
                  )}
                   {checkPrivileges('service') &&( 
                    <Tab
                      value={'history'}
                      classes={{
                        root: 'custom-tab-button custom-tab-button-1',
                        selected: 'custom-tab-button-selected'
                      }}
                      label={translate('maintenanceHistory')}
                      style={{ padding: '6px 12px' }}
                    />
                  )}                       
                  {checkPrivileges('maintenance') &&(
                    <Tab
                      value={'showMaintenance'}
                      classes={{
                        root: 'custom-tab-button custom-tab-button-1',
                        selected: 'custom-tab-button-selected'
                      }}
                      label={translate('maintenanceReminder')}
                      style={{ padding: '6px 12px' }}
                    />
                  )}
                  
                 
                </Tabs>
              </AppBar>
                </>
              }
              onDelete={(n)=>onRemove(n)}
              onStatusChange = {(n)=> onStatusChange(n)}
              onMarkComplete = {(n)=> onMarkComplete(n)}
              isEditable={true}
              status={true}
              completeMark={checkPrivileges("maintenanceUpdate") ? true : false}
              themecolors={themecolors}
              translate={translate}
              searchable
              hasAccessOfCreate={checkPrivileges('serviceCreate')}
              hasAccessOfUpdate={checkPrivileges('maintenanceUpdate')}
              hasAccessOfDelete={checkPrivileges('maintenanceDelete')}
              // title={this.props.translate('users')}----------
              rowDefinition={[
                {
                    id: 'vehicleName',
                    numeric: false,
                    disablePadding: false,
                    label: translate('sharedVehicle')
                },
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: translate('sharedName')
                },
                {
                  id: 'attributes.mileage',
                  numeric: false,
                  disablePadding: false,
                  label: translate('targetValue'),
                  colspan: 3
                },
                {
                  id: 'attributes.repeat',
                  numeric: false,
                  disablePadding: false,
                  label: translate('repeat')
                },
                {
                  id: 'cost',
                  numeric: false,
                  disablePadding: false,
                  label: translate('cost')
                }
              ]}
              />):
              <Loader/>}
        </Grid>
      </Grid>}
      </div>
    )
  }
