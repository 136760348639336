import React, {  } from 'react'
import Tooltip from '@mui/material/Tooltip'

//import { NavLink } from 'react-router-dom';
// import Style from 'style-it'
import { NavLink } from 'react-router-dom'
import DashboardMenu from '../../Components/Maps/DashboardMenu'
import { checkPrivileges } from '../../Helpers'
// import Chip from '@material-ui/core/Chip';
// import { makeStyles } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    backgroundColor: 'black',
  },
});
// export default class Navbar extends React.Component {
  const Navbar = (props) => {


    // const[value,setValue] = useState('/monitoring')



// const handleChange = (event, newValue) => {
//     setValue({ value: newValue })
//     props.history.push(newValue)
//     return false
//   }


// useEffect(()=>{
//   if (props.location.pathname !== state.value) {
//     setValue({ value: props.location.pathname })
//   }
// },[])


 const collapseMenu = () => {
    if (document.body.offsetWidth < 959) {
      props.collapseMenu()
    }
  }


    // const { classes } = props;
    const analytic = checkPrivileges('dashboard')&&(<DashboardMenu history={props.history} />) || null

    return (
    <div className='navbar-menu'>
        {Object.keys(props.items).map(name => {
          let item = props.items[name]
          const { icon: ItemIcon } = item;
          let navlink = null;
          if (item.checked) {
            
            navlink = (
              <Tooltip
                key={name}
                // title={item.title==="Trackers" ? {heading} : item.title}
                title={
                  <div style={{
                      display:"flex", justifyContent:"space-between", alignItems:"center",
                      fontSize:item.title === "Trackers"? "11px":"",fontWeight:item.title === "400px"? "11px":"",
                      height:item.title === "Trackers"? "22px":""
                    }}>
                    {/* {item.title === "Trackers" &&
                     (<Chip label="New"
                            style={{ backgroundColor:"green", color:"white", marginRight:"5px",fontSize:"11px", height:"18px" }}
                            size="small" />)} */}
                    {/* {item.title === "Trackers" ? "Units renamed to Trackers" : item.title} */}
                    {item.title}

                  </div>
                }
                placement='right'
                // classes={{ tooltip: classes.noMaxWidth }}
                classes={{
                  popper: 'menu-popper',
                  tooltip:'menu-popper-tooltip'

                  // tooltip:item.title === "Trackers"?  classes.noMaxWidth : 'menu-popper-tooltip'
                }}
                // classes={{
                //   popper: 'menu-popper', // Assuming 'menu-popper' is your popper class
                //   tooltip: `${classes.customTooltip} menu-popper-tooltip`, // Combine custom and existing class
                // }}
              >
                <NavLink
                  className='navbar-menu-item'
                  onClick={collapseMenu}
                  to={item.href}
                  style={{position: 'relative'}}
                >
                  <ItemIcon fill='currentColor' width={20} height={20} />
                  {item.count !== undefined ? <span className="counter">{(item.count>99)?'99+':item.count}</span> : null}
                  <span className='nav-link-text'>{item.title}</span>
                </NavLink>
              </Tooltip>
            )
          }
          return name === 'dashboard' ? <div key={name}>
            {navlink}
            {analytic}
          </div> : 
          <div key={name}>{navlink}</div>
        })}
      </div>)
  }

export default withStyles(styles)(Navbar);