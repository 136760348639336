import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Checkbox from './Checkbox'
import Style from 'style-it'
import SearchField from './SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'

const AreaSelector = (props) => {

  const [state, setState] = useState({
    list: {},
    search: '',
    isChecked: false
  })


  // useEffect(()=>{
  //   let list = {}
  //   if (props.value && props.value.length) {
  //     props.value.map(d => (list[d] = true))
  //     setState(prevState=>({...prevState, list }))
  //   }
  // },[])
  useEffect(() => {
    const updatedList = {};
    if (props.value && props.value.length) {
      props.value.forEach(d => (updatedList[d] = true));
      setState(prevState=>({...prevState, list:updatedList }))
    }
  }, []);

  useEffect(()=>{
    return () =>{
      setState(prevState=>({...prevState,
        list: {},
        search: '',
        isChecked: false
      }))
    }
  },[])


  // const sendToParent = () => {
  //   if (props.onChange) {
  //     props.onChange(Object.keys(state.list), 'area')
  //   }
  // }

  const sendToParent = (updatedList) => {
    if (props.onChange) {
      props.onChange(Object.keys(updatedList), 'area');
    }
  };

  // const onChange = (e, v) => {
  //   let list = { ...state.list }
  //   if (e.target.checked) {
  //     list[v.id] = true
  //   } else {
  //     delete list[v.id]
  //   }
  //   setState(prevState =>
  //   ({
  //     ...prevState,
  //     list,
  //     isChecked: props.areas && props.areas.length === Object.keys(list).length
  //   })
  //   )
  //   sendToParent()
  // }


  const onChange = (e, v) => {
    const updatedList = { ...state.list };
    if (e.target.checked) {
      updatedList[v.id] = true;
    } else {
      delete updatedList[v.id];
    }
    setState(prevState =>
    ({
      ...prevState,
      list:updatedList,
      isChecked: props.areas && props.areas.length === Object.keys(updatedList).length
    })
    )
    sendToParent(updatedList);
  };


  const searchItem = text => {
    setState(prevState =>
    ({
      ...prevState, search: text.target.value
    }))
  }
useEffect(()=>{

const isCheckedAllareas=props.areas?.length === Object.keys(state.list)?.length
setState((prevState)=>({
  ...prevState,
  isChecked:isCheckedAllareas
}))
},[state.list,props.areas])
//  const toggleItems = event => {
//     if (event.target.checked) {
//       let list = {}
//      props.areas&&props.areas.map(d => (list[d.id] = true))
//      setState({ list, isChecked: true }, () => {
//        sendToParent()
//       })
//     } else {
//      setState({ list: {}, isChecked: false }, () => {
//        sendToParent()
//       })
//     }
//   }

  const toggleItems = (event) => {
    const updatedList = {};
    if (event.target.checked) {
      props.areas && props.areas.forEach(d => (updatedList[d.id] = true));
       setState(prevState=>({
        ...prevState,
        list:updatedList,
        isChecked:true
       }))
    } else {
       setState(prevState=>({
        ...prevState,
        list:{},
        isChecked:false
       }))
    }
    sendToParent(updatedList);
  };

    // let list = props.areas && props.areas.filter(
    //   item => item.name && item.name.toLowerCase().indexOf(state.search.toLowerCase()) !== -1
    // )

    const filteredList = props.areas && props.areas.filter(
      item => item.name && item.name.toLowerCase().includes(state.search.toLowerCase())
    );

    return (
      <Style>
        {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
          background: ${props.themecolors && props.themecolors.themeLightColor};
          color: ${props.themecolors && props.themecolors.themeLightInverse};
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            background: ${props.themecolors.themeListingColor};
        }
        .selector-list {
            padding: 0;
            margin: 0;
            color: ${props.themecolors.themeListingInverse};
            list-style: none
        }
        .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            height: 26px;
            display: inline-flex;
            align-items: center;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px
        }`}
        <div>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className='filter-row pull-left'>
              <li>
                <SearchField
                  label={props.translate('searchAreas')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={state.search}
                  onChange={searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              <li style={{ paddingRight: 0, paddingTop: 3 }}>
                <Checkbox
                  checked={state.isChecked}
                  onChange={toggleItems}
                  label={props.translate('selectAll')}
                />
              </li>
            </ul>
          </div>
          <div className='selector-count'>
            {' '}
            {Object.keys(state.list).length}{' '}
            {props.translate('areasSelected')}
          </div>
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
            height:
              (props.minHeight ? parseInt(props.minHeight) : props.sidebar ? 15 : 15) *
              (props.rows || 10)
          }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div {...restProps} ref={elementRef} id='scrollableDiv' />
                )
              }
            }}
          >
            <ul className='selector-list'>
              {filteredList && filteredList.map(row => (
                <li key={row.id}>
                  <label className={state.list[row.id] ? 'active' : null}>
                    <span className='selector-check'>
                      <Checkbox
                        checked={state.list[row.id]}
                        value={row}
                        onChange={onChange}
                      />
                    </span>
                    <span className='selector-label'>{row.name}</span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    )
  }

const mapState = state => ({
  areas: state.areas.data,
  themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(AreaSelector))
