import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
// import { SnackbarProvider, } from 'notistack'
import { withLocalize } from 'react-localize-redux'
import Modal from './modal'
import Button from '../../../common/Button'
import Tooltip from '../../../common/Tooltip'
import { validEmailPattern, validNumberPattern } from '../../../../Helpers'
import instance from '../../../../axios'
import Axios from 'axios'
import Notifications from 'react-notification-system-redux'



// const dummyMails = ['dummy1@example.com', 'dummy2@example.com', 'dummy3@example.com'];

const screen = (props) => {

 

  const [state, setState] = useState({
    validMail: [],
    emailLists: [],
    originalLists: [],
    colorPicker: false,
    form: {
      alertColor: '',
      commandId: null,
      commandName: '',
      email: [],
    }
  })

  useEffect(() => {
    checkRequiredFields() 
  }, [
    state.form,
    props.wizardData?.form3,
    state.isOpenCommandSection,
    state.isOpenEmailSection,
    state.isOpenMobileSection
  ])


  // componentDidMount() {
  //   const verifiedEmailsString = props.logInUser && props.logInUser.verifiedEmails;
  //   const emailArray = verifiedEmailsString && verifiedEmailsString.split(',');
  //   setState({
  //     emailLists: emailArray ? emailArray : [],
  //     originalLists: emailArray ? emailArray : []
  //   })
  //   if (props.mode === 'update') {

  //     const isValidMails = state.form?.email.map((item) => emailArray?.includes(item.text));
  //     setState(
  //       {
  //         validMail: isValidMails,
  //       }
  //     )
  //   }

  // }


  useEffect(()=>{
    const verifiedEmailsString = props.logInUser && props.logInUser.verifiedEmails;
    const emailArray = verifiedEmailsString && verifiedEmailsString.split(',');
    setState(prevState=>({
      ...prevState,
      emailLists: emailArray ? emailArray : [],
      originalLists: emailArray ? emailArray : []
    }))
    if (props.mode === 'update') {

      const isValidMails = state?.form?.email?.map((item) => emailArray?.includes(item.text));
      setState(prevState=>({
        ...prevState,
          validMail: isValidMails,
        }
      ))
    }
  },[props.mode, props.logInUser, state.form.email])

  // componentWillMount() {
  //   let { wizardData } = props
  //   if (wizardData && wizardData.form3) {
  //     setState(
  //       {
  //         ...wizardData.form3
  //       },
  //       () => checkRequiredFields()
  //     )
  //   }
  // }

  useEffect(()=>{
    if (props.wizardData && props.wizardData.form3) {
      setState(prevState=>({
        ...prevState,
        ...props.wizardData.form3
        }))
    }
  },[props.wizardData.form3])


  const hideColorPicker = () => {
    setState(prevState => ({
      ...prevState,
      colorPicker: false
    }))
  }

  const showColorPicker = () => {
    setState(prevState => ({
      ...prevState,
      colorPicker: true
    }))
  }

  const updateColor = e => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        alertColor: e.hex
      }
    })
    )
  }

  const handleChange = (name, event, id) => {
    if (name === 'type') {
      setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          commandId: event.id,
          commandName: event.name
        }
      }))
    }
    else {
      if (
        name === 'number' ||
        name === 'web' ||
        name === 'email' ||
        name === 'mailTo' ||
        name === 'command'
      ) {
        if (name === 'email') {


          let { email } = state.form
          email.map(item => {
            if (item.id === id) {
              item.text = event.target.value
            }
            return null
          })

          const isValidMails = email.map((item) => state.originalLists?.includes(item.text));

          setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              email: email,

            },
            validMail: isValidMails,
          })
          )
        } else if (name === 'mailTo') {
          setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              [name]: event.target.value,
              email: [{ id: 1, text: '' }]
            }
          })
          )
        }
        else if (name === 'command') {
          setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              [name]: event.target.value
            }
          })
          )
        } else if (name === 'number') {
          let { number } = state.form
          number.map(item => {
            if (item.id === id) {
              item.text = event.target.value
            }
            return null
          })
          setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              number: number
            }
          })
          )
        } else {
          let { notificators } = state.form
          if (event.target.checked) {
            if (notificators.length) {
              notificators = notificators + ',' + name
            } else {
              notificators = name
            }
          } else {
            notificators = notificators.replace(name, '')
            notificators = notificators.replace(',', '')
          }
          setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              notificators
            }
          })
          )
        }
      } else {
        if (
          event.target.value === '' ||
          event.target.value === null ||
          event.target.value === undefined
        ) {
          delete state.form[name]
          setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form
            }
          })
          )
        } else {
          setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              [name]: event.target.value
            }
          })
          )
        }
      }
    }
  }

  // onChangedUsers = item => {
  //   if (item) {
  //     setState(
  //       {
  //         form: {
  //           ...state.form,
  //           usersId: item.value
  //         }
  //       },
  //       () => checkRequiredFields()
  //     )
  //   } else {
  //     setState(
  //       {
  //         form: {
  //           ...state.form,
  //           usersId: ''
  //         }
  //       },
  //       () => checkRequiredFields()
  //     )
  //   }
  // }
 const checkRequiredFields =()=> {
    let {
      isOpenEmailSection,
      isOpenCommandSection,
      isOpenSmsSection,
      isOpenWebSection,
      isOpenFirebaseSection,
      isOpenMobileSection
    } = state
    let { email, number, mailTo, commandType, commandData, commandName } = state.form

    let value = true
    if (
      (isOpenEmailSection ||
        isOpenCommandSection ||
        isOpenSmsSection ||
        isOpenWebSection ||
        isOpenFirebaseSection ||
        isOpenMobileSection) &&
      value
    ) {
      let vald = true
      if (isOpenEmailSection) {
        if (mailTo === 'user' || email[0].text) {
          vald = true
        } else {
          vald = false
        }
      }
      if (isOpenMobileSection) {
        if (number[0].text) {
          vald = true
        } else {
          vald = false
        }
      }
      if (isOpenCommandSection) {
        if (((commandType === 'command' && commandName) || (commandType === 'custom' && commandData))) {
          if (isOpenEmailSection) {
            if (mailTo === 'user' || email[0].text) {
              vald = true
            } else {
              vald = false
            }
          } else {
            vald = true
          }
        } else {
          vald = false
        }
      }
      setState(prevState=>({
        ...prevState,
        validatedForm: vald
      }))
    } else {
      setState(prevState=>({
        ...prevState,
        validatedForm: false
      }))
    }
  }

 const deleteMail = (type, id) => {
    let data = state.form[type]
    if (data.length === 1 && id === 1) {
      data[0].text = ''
      setState(prevState=>({
        ...prevState,
        form: {
          ...prevState.form,
          [type]: data
        }
      }))
    } else {
      let newList = data.filter(itm => itm.id !== id)
      newList.map((item, ind) => {
        item.id = ind + 1
        return null
      })
      setState(prevState=>({
        ...prevState,
        form: {
          ...prevState.form,
          [type]: newList
        }
      }))
    }
  }

 const onChangedUsers = item => {
    let ids = []
    if (item.length) {
      item.map(item => {
        ids.push(item.id)
        return null
      })
    }
    setState(prevState=>({
      ...prevState,
        form: {
          ...prevState.form,
          usersIds: ids
        }
      })
    )
  }

 const handleClick = item => {
  setState(prevState=>({
    ...prevState,
        [item]: !state[item]
      }))
      checkRequiredFields()
  }

 const next = () => {
    let data = { ...state, prv: true }
    props.combineAllData('form3', data)
  }

 const prv = () => {
    if (props.handleChangeTab) {
      props.handleChangeTab('', props.tabPostion - 1)
    }
  }

 const onAddAnotherMail = (type, index) => {
    let { email, number } = state.form
    let formateValidation = true
    if (type === 'email') {
      email.map(itam => {
        if (!validEmailPattern.test(itam.text)) {
          formateValidation = false
        }
        else {
          let makeData = { "email": email[index].text }
          const axiosConfig = {
            'Content-Type': 'application/json',
            'Authorization': '86a38acce978afe5072b3b3ab4872eb1:be2d7d72c966c3c92003843243753fcaf740e4eac16760f0c908c09ededf8a44'
          }
          Axios.post('https://api.clearout.io/v2/email_verify/instant', makeData, axiosConfig)
            .then(res => {
              if (res && res.data && res.data.status === 'invalid') {
                formateValidation = false
                props.dispatch(
                  Notifications.error({
                    message: props.translate('inValidEmail'),
                    autoDismiss: 10
                  })
                )
              }
              else {
                return null
              }
            }).catch(err => {
              console.error('err===', err)
              // formateValidation=false
              //   props.dispatch(
              //     Notifications.error({
              //       message: props.translate('inValidEmail'),
              //       autoDismiss: 10
              //     })
              //   )
            })
          // return null
        }
      })
      if (formateValidation) {
        email.push({ id: email.length + 1, text: '' })
        setState(prevState=>({
          ...prevState,
          form: {
            ...prevState.form,
            email: email
          }
        }))
      } else {
        props.dispatch(
          Notifications.error({
            message: props.translate('Enter your Email/Valid Email'),
            autoDismiss: 10
          })
        )
   
      }
    } else if (type === 'number') {
      number.map(itam => {
        if (!validNumberPattern.test(itam.text)) {
          formateValidation = false
        }
        return null
      })
      if (formateValidation) {
        number.push({ id: number.length + 1, text: '' })
        setState(prevState=>({
          ...prevState,
          form: {
            ...prevState.form,
            number: number
          }
        }))
      } else {
        props.dispatch(
          Notifications.error({
            message: props.translate('previousNumberInvalid'),
            autoDismiss: 10
          })
        )
 
      }
    }
  }

 const validateSingleEmail = (email) => {
    const isValid = /^\S+@\S+\.\S+$/.test(email);
    return isValid
  }

 const submitValidEmail = (value) => {
    instance({
      url: `api/users/send/verificationemail`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: new URLSearchParams({
        email: value
      })
    })
      .then(() => {
        props.dispatch(
          Notifications.success({
            message: props.translate('linkSent'),
            autoDismiss: 10
          })
        )

      }).catch(() => {
        props.dispatch(
          Notifications.error({
            message: props.translate('error'),
            autoDismiss: 10
          })
        )
       
      })
  }
    return (  
      <>
        <Modal
          {...props}
          {...state}
          validateSingleEmail={validateSingleEmail}
          // validateEmail={validateEmail}
          submitValidEmail={submitValidEmail}
          validMail={state.validMail}
          handleClick={handleClick}
          handleChange={handleChange}
          onAddAnotherMail={onAddAnotherMail}
          onChangedUsers={onChangedUsers}
          hideColorPicker={hideColorPicker}
          showColorPicker={showColorPicker}
          updateColor={updateColor}
          deleteMail={deleteMail}
          expirationCommandCheck={props.wizardData?.form2?.form?.functionalType === 'expiration' ? true : false}
        />
        <div
          className='clearfix'
          style={{
            padding: '12px 20px 15px',
            borderTop: `1px solid ${props.themecolors && props.themecolors.themeLightColor}`
          }}
        >
    

              <Button
                disabled={!state.validatedForm}
                variant='contained'
                onClick={next}
                style={{ float: 'right' }}
                >
                <Tooltip
            title={props.translate('saveCurrentTabDataAndGotoNext')}
          ><span>

                {props.translate('next')}
          </span>
          </Tooltip>
              </Button>
              
          <Button variant='contained' onClick={prv}>
            {props.translate('previous')}
          </Button>
        </div>
      </>
    )
  
}

export const ScreenTwo = connect(null, null)((withLocalize(screen)))
