import React, { Component } from 'react'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import L from 'leaflet'
import { connect } from 'react-redux'

import './DrawMap.scss'

class MarkerMap extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lat: 0,
      lng: 0,
      zoom: 3,
      pointer: null,
      onFullScreenState: false,
    }
    this.handleMapEvent = this.handleMapEvent.bind(this)
    this.myRef = this.myRef.bind(this)
  }

  handleMapEvent (e) {
    if (this.props.getPostion) {
      this.props.getPostion(e.latlng, this.map.getZoom())
      this.setState({
        pointer: (
          <Marker
            position={[e.latlng.lat, e.latlng.lng]}
            icon={L.icon({
              iconUrl: '/assets/images/location-pin.svg',
              iconSize: [79, 64],
              iconAnchor: [20, 64]
            })}
          />
        )
      })
    }
  }

  myRef (el) {
    if (el) {
      this.map = el
      this.map.on('click', (e)=>{
        this.handleMapEvent(e)
      })

      L.Control.geocoder({
        position: 'topleft',
        placeholder: 'Search location...',
        defaultMarkGeocode: false
      })
        .on('markgeocode', e => {
          this.setState({
            lat: e.geocode.center.lat,
            zoom: 15,
            lng: e.geocode.center.lng,
            pointer: (
              <Marker
                position={[e.geocode.center.lat, e.geocode.center.lng]}
                icon={L.icon({
                  iconUrl: '/assets/images/location-pin.svg',
                  iconSize: [79, 64],
                  iconAnchor: [20, 64]
                })}
              />
            )
          })

          if (this.props.getPostion) {
            this.props.getPostion(e.geocode.center, this.map.getZoom())
          }
        })
        .addTo(this.map)
    }
  }

  componentWillMount () {
    const { lat, lng, zoom } = this.props
    this.setState({
      lat: lat || 0,
      lng: lng || 0,
      zoom: zoom || 3
    })
    if (lat && lng) {
      this.setState({
        pointer: (
          <Marker
            position={[lat, lng]}
            icon={L.icon({
              iconUrl: '/assets/images/location-pin.svg',
              iconSize: [79, 64],
              iconAnchor: [20, 64]
            })}
          />
        )
      })
    }
  }

  componentDidMount() {
    if (this.map) {
      this.map.on('click', this.handleMapEvent);
      this.setState(
        (prevState) => ({
          onFullScreenState: !prevState.onFullScreenState,
        }),
        this.handleSizeUpdate
      );
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevState.onFullScreenState !== this.state.onFullScreenState) {
      this.handleSizeUpdate();
    }
  }
  
  componentWillUnmount() {
    if (this.map) {
      this.map.off('click', this.handleMapEvent); 
    }
  
    if (this.sizeUpdateTimeout) {
      clearTimeout(this.sizeUpdateTimeout);
    }
  }
  
  handleSizeUpdate() {
    if (this.map) {
      this.sizeUpdateTimeout = setTimeout(() => {
        if (this.map) {
          this.map.invalidateSize();
        }
      }, 20);
    }
  }

  render () {
    const position =
      this.state.lat && this.state.lng
        ? [this.state.lat, this.state.lng]
        : [0, 0]

    let crs = {}
    if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 }
    }

    const thisMap = [
      <MapContainer
        key={1}
        ref={this.myRef}
        bounds={
          this.props.bounds && this.props.bounds.length
            ? this.props.bounds
            : this.state.bounds && this.state.bounds.length
            ? this.state.bounds
            : null
        }
        style={{
          height: this.props.height || '100%',
          width:  this.props.width || '100%',
          minHeight: this.props.minHeight || '100vh'
        }}
        center={position}
        zoom={this.state.zoom}
        zoomControl={false}
        // onClick={(e)=>this.handleMapEvent(e)}
        maxZoom={this.props.mapLayer.maxZoom || 16}
        //onContextMenu={this.mapContextMenu}
        {...crs}
      >
        <TileLayer
          {...this.props.mapLayer}
          maxNativeZoom={this.props.mapLayer.maxZoom}
          maxZoom={this.props.mapLayer.maxZoom}
          minZoom={this.state.minZoom}
        />

        {this.state.pointer}
      </MapContainer>
    ]
    return (
      <div style={{ position: 'relative' }}>
        {['osm', ''].includes(this.props.mapLayer.id) && thisMap}
        {['carto'].includes(this.props.mapLayer.id) && thisMap}
        {['gccStreet'].includes(this.props.mapLayer.id) && thisMap}
        {['googleTerrain'].includes(this.props.mapLayer.id) && thisMap}
        {['googleSatellite'].includes(this.props.mapLayer.id) && thisMap}
        {['googleHybrid'].includes(this.props.mapLayer.id) && thisMap}
        {['googleRoad'].includes(this.props.mapLayer.id) && thisMap}
        {['baidu'].includes(this.props.mapLayer.id) && thisMap}
        {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id) && thisMap}
      </div>
    )
  }
}


const mapStateToProps = state => ({
  themecolors: state.themeColors,
  mapLayer: state.mapLayer,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(MarkerMap)
