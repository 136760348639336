import React, { Component } from 'react';


import "./../Login/Style.scss";

import { UserLocationShareComponent } from './Component';

export default class UserLocationShare extends Component {

  render() {

    return (
    <div>
        <div className="loginWrapperInner">
          <UserLocationShareComponent {...this.props}  />
        </div>
        <div className="white-label-bg" style={{background: 'url(/assets/images/login_wallpaper.png)'}}></div>
        </div>
    );
  }
}
