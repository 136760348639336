import React, { useState } from 'react'
// import { EditForm } from '../../../Components/Geofence/editForm'
import Button from '../../../Components/common/Button'
import SearchField from '../../../Components/common/SearchField'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
// import { addcalendars } from '../../../Actions/Devices'
// import isEqual from 'react-fast-compare'
// import Icon from '@mui/material/Icon'
import { checkPrivileges } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
// import { CheckIcon } from '../../../Components/common/CheckIcon'
// import { removedGarages } from '../../../Actions/Garages'
// import Notifications from 'react-notification-system-redux'
import DeleteIcon from '@mui/icons-material/Delete';
// import instance from '../../../axios'


const Areas = (props) => {

  // const [state, setState] = useState({
  //   searchRecently: ''
  // })

 const search = (e) => {
    props.searchItems(e.target.value)
  }

    const { deleteItem, allAreas } = props
    const Areas1 = allAreas && allAreas.data && allAreas.data.map(g => (
      <ListRow
        key={g.id}
        item={g}
        deleteItem={deleteItem}
        editAreaForm={props.editAreaForm}
      />
    ))

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar sidebar-bg-layer'>
          <div>
            <div className='section-head clearfix section-head-filter'>
              <ul className='filter-row clearfix pull-right'>
                <li>
                  <SearchField
                    label={props.translate('searchArea')}
                    fullWidth
                    onChange={e => search(e)}
                  />
                </li>
                {
                  (checkPrivileges('areaCreate')) && (
                    <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                      <Button
                        size='small'
                        onClick={() => props.openCreateFrom()}
                      >
                        {props.translate('Create')}
                      </Button>
                    </li>
                  )
                }

              </ul>
            </div>
            <ul className='list-view with-padding-right geofence-sidebar-list'>
              <Scrollbar
                disableTracksWidthCompensation={true}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return (
                      <div
                        {...restProps}
                        ref={elementRef}
                        id='scrollableDiv'
                      />
                    )
                  }
                }}
              >

                {props.itemPagination ? (
                  <InfiniteScroll
                    dataLength={props.itemPagination.items.length}
                    next={props.fetchMoreItems}
                    hasMore={props.itemPagination.hasNext}
                    loader={<Loader defaultStyle={true} imgSize={50} />}
                    scrollableTarget='scrollableDiv'
                    endMessage={props.endMessage}
                  >
                    {Areas1}
                  </InfiniteScroll>
                ) : null}
              </Scrollbar>
            </ul>
          </div>
        </div>
      </Style>
    )
  }

const ListRow1 = (props) => {

  const [state,setState] = useState({
    onDeleteConfirmation: false
  })
  

 const onDelete = () => {
    props.deleteItem(props.item.id)
  }


 const onRemovedItem = () => {
    setState(prevState=>({
      ...prevState,
      onDeleteConfirmation: true
    }))
  }

 const onCancel = () => {
  setState(prevState=>({
    ...prevState,
      onDeleteConfirmation: false
    }))
  }

    return (
      <li className={'list-row clearfix'} key={props.item.id}>
        <label
          className='checkbox'
          onClick={() => {
            if (checkPrivileges('areaUpdate')) {
              props.editAreaForm(props.item)
            }
          }}
        >
          <span className='unit-name' style={{ fontSize: 12 }}>
            {props.item.name}
          </span>
        </label>
        <div className='callout-actions'>
          {(checkPrivileges('areaDelete')) && (
            <span className='action' onClick={onRemovedItem}>
              <DeleteIcon className='material-icons' />
            </span>
          )}
        </div>

        {state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={onDelete}
            title={props.translate('areYouWantToDelete')}
            children={props.item.description}
          />
        )}
      </li>
    )
  }

const mapState = state => ({
  garages: state.garages,
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withLocalize(ListRow1))
export default mapStateToProps(Areas)
