import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
export const Box = function Box({ name, type, isDropped, themecolors }) {
    const [{ opacity }, drag] = useDrag({
        type: type || ItemTypes.CUSTOM,
        item: {name,type},
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
    });
    return (<div className="dnd-box" ref={drag} role="Box" style={{ opacity, color: themecolors.themeInverse, backgroundColor: themecolors.themeDarkColor }}>
   
			{isDropped ? <s>{name}</s> : name}
		</div>);
}