import React from 'react';
import CustomDialog from '../common/Dialog'
import Table from '../common/tableWithBackEndPagination'

const DevicePortList=(props)=> {
        return (
            <CustomDialog
                title={props.translate('deviceModel')}
                themecolors={props.themecolors}
                visable={true}
                isVisableBtn
                noFullScreen
                onClose={props.onCloseModal}
                isButtonVisable={false}
                draggable={true}
                CancelText={props.translate('sharedCancel')}
                bodyPadding={0}>
                <Table insideDialog={true}
                    rows={props.unitList.data}
                    rowsPerPage={props.page}
                    searchable={true}
                    themecolors={props.themecolors}
                    translate={props.translate}
                    pagination={props.setPagination(props.unitList )}
                    handleChangeRowsPerPage={(n)=>props.handleChangeRowsPerPage(n)}
                    handleChangePage={()=>props.handleChangePage(props.page+1)}
                    SearchItem={(n)=>props.SearchItem(n)}
                    rowDefinition={[
                        { id: 'name', numeric: false, disablePadding: false, label: props.translate('device') },
                        { id: 'protocol',numeric: false, disablePadding: false, label: props.translate('protocol') },
                        { id: 'port',numeric: false, disablePadding: false, label: props.translate('port') },
                    ]} />
            </CustomDialog>
        ) 
    
}
export default DevicePortList
