import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import TextField from '../common/TextField';
import Checkbox from '../common/Checkbox';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Place';
import IconButton from '@mui/material/IconButton';
import Tooltip from '../common/Tooltip';
import MenuItem from '../common/MenuItem';
import MarkerMap from '../common/Maps';
import Button from '../common/Button';
import { logInUserInfo } from './../../Actions/Users';
import CustomDialog from '../common/Dialog';
import { checkPrivileges, errorHandler } from '../../Helpers';
import isEqual from 'react-fast-compare';
import Notifications from 'react-notification-system-redux';
import { updateLayer } from '../../Actions/Maps';
import instance from '../../axios';

const coordinateFormatTypes = [
  {
    key: 'dd',
    name: 'sharedDecimalDegrees'
  },
  {
    key: 'ddm',
    name: 'sharedDegreesDecimalMinutes'
  },
  {
    key: 'dms',
    name: 'sharedDegreesMinutesSeconds'
  }
];

const unitViewTypes = [
  {
    key: 'default',
    name: 'default'
  },
  {
    key: 'unitStatus',
    name: 'unitStatus'
  },
  {
    key: 'motionStatus',
    name: 'motionStatus'
  }
];

const ServicePreferences = (props) => {
  const [state, setState] = useState({
    form: {
      name: '',
      map: 'osm',
      latitude: '',
      longitude: '',
      zoom: '',
      twelveHourFormat: '',
      coordinateFormat: '',
      poiLayer: '',
      customerNumber: '',
      attributes: { notificationEnabled: true }
    },
    isMapModal: false,
    isVisableAddlocBtn: false,
    localPostionObj: '',
    isVisableAddBtn: false,
    maps: []
  });

  useEffect(()=>{
    checkRequiredFields()
  },[state.form])

  useEffect(() => {
    let mapsArr = [];
    props.logInUser && props.logInUser.attributes && props.logInUser.attributes.maps && props.logInUser.attributes.maps.map((item) => {
      if (item !== 'baidu') {
        mapsArr.push(item);
      }
    });
    setState(prevState => ({
      ...prevState,
      form: {
        ...props.logInUser,
        attributes: {
          ...prevState.form.attributes,
          ...props.logInUser.attributes
        },
      },
      maps: mapsArr
    }));
  }, [props.logInUser]);

  const handleClickShowPostion = () => {
    setState(prevState => ({
      ...prevState,
      isMapModal: true,
      isVisableAddlocBtn: false
    }));
  };

  const getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      setState(prevState => ({
        ...prevState,
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true
      }));
    }
  };

  const setSelectedLocation = () => {
    const { localPostionObj } = state;
    if (localPostionObj) {
      setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          latitude: localPostionObj.latlng.lat,
          longitude: localPostionObj.latlng.lng,
          zoom: localPostionObj.zoom
        },
        isMapModal: false
      }));
    }
  };

  const checkRequiredFields = () => {
    let { zoom } = state.form;
    let value = !isEqual(state.form, props.logInUser);
    setState(prevState => ({
      ...prevState,
      isVisableAddBtn: value && zoom && zoom > 2 && zoom < 19
    }));
  };

  const formReset = () => {
    setState(prevState => ({
      ...prevState,
      form: { ...props.logInUser },
      isVisableAddBtn: false
    }));
  };

  const formSubmit = () => {
    let { logInUser } = props;
    const { form } = state;
    logInUser = { ...form };
    setState(prevState => ({
      ...prevState,
      isVisableAddBtn: false
    }));
    instance({
      url: `api/users/${logInUser.id}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...logInUser
      }
    })
      .then(user => {
        props.dispatch(logInUserInfo(user));
        props.dispatch(updateLayer({ id: user.map }));
        props.dispatch(
          Notifications.success({
            message: props.translate('servicePerferenceInfoIsUpdated'),
            autoDismiss: 10
          })
        );
        setState(prevState => ({
          ...prevState,
          isVisableAddBtn: false
        }));
      })
      .catch(error => { errorHandler(error, props.dispatch) });
  };

  const handleChange = name => event => {
    const { target } = event;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'number') {
      value = parseFloat(value);
    }
    if (name === 'notificationEnabled' || name === 'soundAlert' || name === 'unitsViewType') {
      setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          attributes: { ...prevState.form.attributes, [name]: value }
        }
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: value
        }
      }));
    }
  };

  const onCloseModal = () => {
    setState(prevState => ({
      ...prevState,
      isMapModal: false
    }));
  };

  return (
    <div>
      {state.isMapModal && (
        <CustomDialog
          title={props.translate('setLocation')}
          themecolors={props.themecolors}
          visable={true}
          onClose={onCloseModal}
          isVisableBtn={true}
          draggable={true}
          CancelText={props.translate('sharedBack')}
          headerActions={
            <Button
              size='small'
              variant='contained'
              color='secondary'
              disabled={!state.isVisableAddlocBtn}
              onClick={() => setSelectedLocation()}
            >
              {props.translate('sharedAdd')}
            </Button>
          }
          bodyPadding={0}
        >
          <MarkerMap
            getPostion={getPostion}
            minHeight={500}
            zoom={state.form.zoom || 0}
            lat={state.form.latitude || 0}
            lng={state.form.longitude || 0}
            height={'100%'}
          />
        </CustomDialog>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id='name'
            label={props.translate('sharedName')}
            type='text'
            value={state.form.name}
            onChange={handleChange('name')}
            variant='outlined'
            margin='dense'
            fullWidth
          />
        </Grid>
        {checkPrivileges('usercustomerNumberUpdate') && (
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='customerNumber'
              label={props.translate('customerNumber')}
              type='text'
              value={state.form.customerNumber}
              onChange={handleChange('customerNumber')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id='map'
            select
            label={props.translate('mapTitle')}
            value={state.form.map}
            onChange={handleChange('map')}
            margin='dense'
            fullWidth
          >
            {state.maps && state.maps.map(option => (
              <MenuItem key={option} value={option}>
                {props.translate(option)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id='coordinateFormat'
            select
            label={props.translate('settingsCoordinateFormat')}
            value={state.form.coordinateFormat}
            onChange={handleChange('coordinateFormat')}
            margin='dense'
            fullWidth
          >
            <MenuItem value=''>
              <em>{props.translate('none')}</em>
            </MenuItem>
            {coordinateFormatTypes.map(option => (
              <MenuItem key={option.key} value={option.key}>
                {props.translate(option.name)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='zoom'
            label={props.translate('serverZoom')}
            type='number'
            placeholder=''
            error={
              state.form.zoom > 18 || state.form.zoom < 3
                ? true
                : false
            }
            helperText={props.translate('Enter Value 3 to 18')}
            value={state.form.zoom}
            onChange={handleChange('zoom')}
            variant='outlined'
            margin='dense'
            fullWidth
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='latitude'
            type='number'
            label={props.translate('positionLatitude')}
            placeholder=''
            value={state.form.latitude}
            onChange={handleChange('latitude')}
            variant='outlined'
            margin='dense'
            fullWidth
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='longitude'
            type='number'
            label={props.translate('positionLongitude')}
            value={state.form.longitude}
            onChange={handleChange('longitude')}
            variant='outlined'
            margin='dense'
            fullWidth
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id='unitsViewType'
            select
            label={props.translate('unitsViewType')}
            value={state.form.attributes.unitsViewType?state.form.attributes.unitsViewType:"default"}
            onChange={handleChange('unitsViewType')}
            margin='dense'
            fullWidth
          >
            {unitViewTypes.map(option => (
              <MenuItem key={option.key} value={option.key}>
                {props.translate(option.name)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Tooltip title={props.translate('addLocation')}>
            <IconButton onClick={handleClickShowPostion}>
              {<EditIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div style={{ display: 'inline-flex' }}>
            <div style={{ marginRight: 20 }}>
              <Checkbox
                checked={state.form.twelveHourFormat}
                onChange={handleChange('twelveHourFormat')}
                value='twelveHourFormat'
                canAssign
                canRemove
                label={props.translate('settingsTwelveHourFormat')}
              />
            </div>
            <div style={{ marginRight: 20 }}>
              <Checkbox
                checked={state.form.attributes.notificationEnabled}
                onChange={handleChange('notificationEnabled')}
                value='twelveHourFormat'
                canAssign
                canRemove
                label={props.translate('settingsNotificationEnabled')}
              />
            </div>
            <div style={{ marginRight: 20 }}>
              <Checkbox
                checked={state.form.attributes?.soundAlert}
                onChange={handleChange('soundAlert')}
                canAssign
                canRemove
                label={props.translate('soundAlert')}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <br />
      {checkPrivileges('userUpdate') && (
        <Fragment>
          <Button
            variant='contained'
            onClick={formReset}
            style={{ marginRight: 8, backgroundColor: props.themecolors.menuActiveBackground, color: props.themecolors.textColor }}
          >
            {props.translate('resetButton')}
          </Button>
          <Button
            variant='contained'
            disabled={!state.isVisableAddBtn}
            onClick={formSubmit}
          >
            {props.translate('sharedSave')}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

const enhance = connect();

export default enhance(ServicePreferences);
