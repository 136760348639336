import React, { Fragment } from 'react'
import SimpleModalWrapped from './../common/Modal'
import TextField from './../common/TextField'
// import { makeStyles } from '@mui/styles'
import Tooltip from './../common/Tooltip'
import MenuItem from './../common/MenuItem'
import Grid from '@mui/material/Grid'
import { checkPrivileges } from '../../Helpers'
import SingleSelect from '../common/SingleSelect'
import { unitsList } from './unitsPortList'
import { timeZonesTypes } from './../Attributes/timezone'
import instance from '../../axios'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AsyncSelect from '../../Components/common/asyncSelect'

import DateFnsUtils from '@date-io/date-fns'
import ClearIcon from '@mui/icons-material/Clear';

const addDevice = (props) => {
  // const [visable, setVisible] = useState(true)
  // const [atModalVisable, setAtModalVisable] = useState(false)
  // const [isVisableDeviceBtn, setIsVisableDeviceBtn] = useState(false)
  // const [form, setForm] = useState({
  //   attributes: {}
  // })
  // const [open, setOpen] = useState(true)
  // const [isAdvSettingEnable, setIsAdvSettingEnable] = useState(false)
  const {unitList,classes}=props

  // const closeModal = () => {
  //   setVisible(false)
  //   console.error(visable)
  // }

  // const toggleCollapse = () => {
  //   setOpen((prev) => !prev)
  // }

  // const addAttributes = () => {
  //   setAtModalVisable((prev) => !prev)
  // }

  const searchFromList = async (inputValue) => {
    if (!inputValue) {
      return []
    }

    try {
      const response = await instance({
        method: 'GET',
        url: `/api/devices/models?search=${inputValue}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        return response.data.map(item => {
          return{
            id: item.name,
            name: item.name,
            key: item.name,
            port: item.port,
            protocol: item.protocol,
            label: item.name,
            value: item.name
          }
        });
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
      // Handle error if needed
    }
  }

  const searchFromZoneList=async (inputValue)=>{
    if(!inputValue){
      return []
    }
    const result = timeZonesTypes.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    return await result.map(item => {
      return { id: item.key, key: item.key, label: item.label, value: item.key }
    })
  }

  const defaultOptions = unitList.data && unitList.data.slice(0, 10).map(item => ({
    id: item.name,
    name: item.name,
    key: item.name,
    port: item.port,
    protocol: item.protocol,
    label: item.name,
    value: item.name
  }));

  if (defaultOptions && defaultOptions.length < unitsList.length && unitsList) {
    defaultOptions.push({
      id: '',
      name: 'Search for more items...',
      key: '',
      port: '',
      label: 'Search for more items...',
      value: '',
      isDisabled: true
    });
  }

  const defaultZones = timeZonesTypes.map(item => ({
    id: item.key,
    key: item.key,
    label: item.label,
    value: item.key
  }));
  
  return(
    <Fragment>
    <SimpleModalWrapped
      visable
      isButtonVisable
      title='sharedRequired'
      formSubmit={props.formSubmit}
      notShowAttributesBtn
      notShowGroupBtn={props.notShowGroupBtn}
      modalControle={props.modalControle}
      buttonText={props.buttonText}
      isVisableBtn={props.isVisableDeviceBtn}
      btnCancelNotShow={props.btnCancelNotShow}
      canUpdate={checkPrivileges(props.isHaveAccess)}
      hasPremissions
      HaveGroupCreateAccess={checkPrivileges('groupCreate')}
      elevation={props.editOption !== undefined ? 0 : 2}
      showPortList={props.showPortList}
      showPort={props.showPort}
      isLoading={props.isLoading}
      content={
        <form autoComplete='off'>
          <div>
            <Grid container spacing={2}>
              <Grid item xl={3} md={4} xs={12}>
                <TextField
                  id='name'
                  label={props.translate('sharedName')}
                  type='text'
                  placeholder=''
                  required
                  value={props.form.name || ''}
                  onChange={props.handleChange('name')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              {((props.editOption) && (props.logInUser.attributes&&props.logInUser.attributes.viewModel)) ? 
                 checkPrivileges('deviceModel') ? ( 
                  <Grid item md={3} sm={6} xs={12} style={{position: 'relative', zIndex: 9}}>
                      <AsyncSelect
                        cacheOptions
                        selectName='model'
                        isClearable
                        defaultOptions={defaultOptions}
                        loadOptions={searchFromList}
                        placeholder={props.translate('deviceModel')}
                        value={
                          typeof props.form.model === 'object'
                            ? props.form.model
                            : props.form.model
                            ? {
                                id: props.form.model,
                                key: props.form.model,
                                label: props.form.model,
                                value: props.form.model
                              }
                            : ''
                        }
                        handleChange={props.port_handleChange}
                        canAssign
                        isMulti={false}
                      />
                    {/* <SingleSelect
                      array={[]}
                      async
                      selectName='model'
                      isClearable
                      defaultOptions={defaultOptions}
                      loadOptions={this.searchFromList}
                      label={props.translate('deviceModel')}
                      value={
                        typeof props.form.model === 'object'
                          ? props.form.model
                          : props.form.model
                          ? {
                              id: props.form.model,
                              key: props.form.model,
                              label: props.form.model,
                              value: props.form.model
                            }
                          : ''
                      }
                      handleChange={props.port_handleChange}
                      canAssign
                    /> */}
                  </Grid>
                  ) : null
                : (props.logInUser.userType ===-1 || props.addOption) ?
                (<Grid item md={3} sm={6} xs={12} style={{position: 'relative', zIndex: 9}}>
                      <AsyncSelect
                        cacheOptions
                        selectName='model'
                        isClearable
                        defaultOptions={defaultOptions}
                        loadOptions={searchFromList}
                        placeholder={props.translate('deviceModel')}
                        value={
                          typeof props.form.model === 'object'
                            ? props.form.model
                            : props.form.model
                            ? {
                                id: props.form.model,
                                key: props.form.model,
                                label: props.form.model,
                                value: props.form.model
                              }
                            : ''
                        }
                        handleChange={props.port_handleChange}
                        canAssign
                        isMulti={false}
                      />
                      {/* <SingleSelect
                        array={[]}
                        async
                        selectName='model'
                        isClearable
                        defaultOptions={defaultOptions}
                        loadOptions={this.searchFromList}
                        label={props.translate('deviceModel')}
                        value={
                          typeof props.form.model === 'object'
                            ? props.form.model
                            : props.form.model
                            ? {
                                id: props.form.model,
                                key: props.form.model,
                                label: props.form.model,
                                value: props.form.model
                              }
                            : ''
                        }
                        handleChange={props.port_handleChange}
                        canAssign
                      /> */}
                  </Grid>):null
             } 
            {   /*   identifier Imei */}
            {(props.editOption && props.logInUser.attributes&&props.logInUser.attributes.viewIMEI) ? 
               (<Grid item md={3} sm={6} xs={12}>
              {props.form.model !== 'FX Tracker' ? (
                <TextField
                  id='uniqueId'
                  error={props.duplicateIdentifier}
                  label={props.translate('deviceIdentifier')}
                  InputProps={ props.editOption ? {readOnly: !checkPrivileges('deviceImei')}: ''  }
                  // disabled={
                  //   props.editOption &&
                  //   props.logInUser &&
                  //   props.logInUser.userType !== -1
                  // }
                  type='text'
                  placeholder=''
                  value={props.form.uniqueId}
                  onChange={props.handleChange('uniqueId')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                />
              ) : (
                <Tooltip title={props.translate('autoGeneratedId')}>
                  <TextField
                    id='uniqueId'
                    label={props.translate('deviceIdentifier')}
                    type='text'
                    placeholder=''
                    value={props.form.uniqueId}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                  />
                </Tooltip>
              )}
            </Grid>)
             :(props.logInUser.userType ===-1 || props.addOption) ? 
             (<Grid item md={3} sm={6} xs={12}>
              {props.form.model !== 'FX Tracker' ? (
                <TextField
                  id='uniqueId'
                  error={props.duplicateIdentifier}
                  label={props.translate('deviceIdentifier')}
                  InputProps={ props.editOption ? {readOnly: !checkPrivileges('deviceImei')}: ''  }
                  // disabled={
                  //   props.editOption &&
                  //   props.logInUser &&
                  //   props.logInUser.userType !== -1
                  // }
                  type='text'
                  placeholder=''
                  value={props.form.uniqueId}
                  onChange={props.handleChange('uniqueId')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                />
              ) : (
                <Tooltip title={props.translate('autoGeneratedId')}>
                  <TextField
                    id='uniqueId'
                    label={props.translate('deviceIdentifier')}
                    type='text'
                    placeholder=''
                    value={props.form.uniqueId}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                  />
                </Tooltip>
              )}
            </Grid>)
             :null}
              {props.addOption && (
                <Grid item md={4} xs={12}>
                  <p
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontSize: '16',
                      background: props.themecolors&&props.themecolors.themeLightColor,
                      color: props.themecolors.textColor,
                      padding: '12px 33px',
                      marginTop: 0,
                      marginLeft: '25'
                    }}
                  >
                    <span>
                      {props.translate(
                        'toConfigureYourDeviceManuallyUseConnectionParameters'
                      )}
                    </span>
                    <span style={{ display: 'block' }}>
                      <label
                        style={{ width: '40%', display: 'inline-block' }}
                      >
                        {props.translate('serverIP')}:
                      </label>
                      <span>{props.serverIp}</span>
                    </span>
                    <span style={{ display: 'block' }}>
                      <label
                        style={{ width: '40%', display: 'inline-block' }}
                      >
                        {props.translate('Port')}:
                      </label>
                      <span>{props.form.device}</span>
                    </span>
                  </p>
                </Grid>
              )}
            </Grid>
          </div>

          <h4 style={{ margin: '10px 0' }} className='with-border'>
            {props.translate('sharedExtra')}
          </h4>
          <Grid container spacing={2}>
            <Grid item xl={3} md={4} xs={12}>
              <TextField
                id='phone'
                label={props.translate('sharedPhone')}
                type='text'
                placeholder=''
                value={props.form.phone || ''}
                onChange={props.handleChange('phone')}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
           
            {/* <---------- temprary comment --------------> */}
            {/* <Grid
              item
              xl={3}
              md={4}
              xs={12}
              style={{ position: 'relative', zIndex: 3 }}
            >
              <SearchGroups
                isMulti={false}
                api='groups'
                name='groupId'
                onChange={props.handleChange2}
                value={
                  props.form.groupId
                    ? {
                        value: props.form.groupId,
                        label: props.group.find(
                          a => props.form.groupId === a.id
                        )
                          ? props.group.find(
                              a => props.form.groupId === a.id
                            )['name']
                          : ''
                      }
                    : null
                }
                placeholder={props.translate('searchGroup')}
              />
            </Grid> */}
            <Grid item xl={3} md={4} xs={12}>
              <TextField
                id='contact'
                label={props.translate('deviceContact')}
                placeholder=''
                value={props.form.contact || ''}
                onChange={props.handleChange('contact')}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            <Grid item xl={3} md={4} xs={12}>
              <TextField
                id='onlineTime'
                // label={props.translate('onlineTime') + ' (min)'}
                label={`${props.translate('onlineTime')} ${'  '}${props.translate('min')}`}

                type='number'
                placeholder=''
                value={props.form?.attributes?.onlineTime || ''}
                onChange={e=>props.changeAttribute2('onlineTime',e)}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            {props.addOption && (
              <Grid item xl={3} md={4} xs={12}>
                <TextField
                  id='category'
                  select
                  label={props.translate('deviceCategory')}
                  value={props.form.category || ''}
                  onChange={props.handleChange('category')}
                  SelectProps={{
                    MenuProps: {
                      className: classes&&classes.menu
                    }
                  }}
                  margin='dense'
                  fullWidth
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  {props.categoriesList.map(option => (
                    <MenuItem key={option} value={option}>
                      {props.translate(option)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>

          <h4 style={{ margin: '10px 0' }} className='with-border'>
            {props.translate('sharedAttributes')}
          </h4>
          <Grid container spacing={2}>
            <Grid item xl={3} md={4} xs={12}>
              <TextField
                id='attributeDevicePassword'
                label={props.translate('attributeDevicePassword')}
                placeholder=''
                value={
                  props.form.attributes &&
                  props.form.attributes.devicePassword
                    ? props.form.attributes.devicePassword
                    : ''
                }
                onChange={e =>
                  props.changeAttribute2('devicePassword', e)
                }
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            <Grid item xl={3} md={4} xs={12}>
              <SingleSelect
                array={[]}
                async
                selectName='decoder.timezone'
                isClearable={false}
                defaultOptions={defaultZones}
                loadOptions={searchFromZoneList}
                label={props.translate('sharedTimezone')}
                value={
                  props.form.attributes &&
                  props.form.attributes['decoder.timezone']
                    ? timeZonesTypes.find(
                        t =>
                          t.key ===
                          props.form.attributes['decoder.timezone']
                      )
                    : ''
                }
                handleChange={props.timezone_handleChange}
                canAssign
                menuPlacement="auto"
                menuPortalTarget={document.body}
                styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                 }}
              />
            </Grid>
            <Grid item xl={3} md={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd/MM/yyyy"
                  margin='dense'
                  label={props.translate('installDate')}
                  variant='outlined'
                  fullWidth
                  value={
                    props.form.attributes?.installDate
                      ? new Date(
                          `${props.form.attributes.installDate}`
                        ).toString()
                      : null
                  }
                  onChange={(e)=>props.handleChangeLicenseExpDate('installDate',e)}
                  InputProps={{
                    classes: {
                      root: 'theme-cssOutlinedInput',
                      input: 'theme-input',
                      focused: 'theme-cssFocused',
                      notchedOutline: 'theme-notchedOutline'
                    },
                    endAdornment:// Show clear button if date selected
                      <ClearIcon
                        onClick={() => props.handleChangeLicenseExpDate('installDate', null)}
                        style={{ cursor: 'pointer' }}
                      />
                  }}
                  InputLabelProps={{
                    classes: {
                      root: 'theme-label',
                      focused: 'theme-label-focused',
                      shrink: 'theme-label-shrink'
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xl={3} md={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd/MM/yyyy"
                  margin='dense'
                  label={props.translate('expireDate')}
                  variant='outlined'
                  fullWidth
                  minDate={new Date()}
                  value={
                    props.form?.expirationTime
                      ? new Date(
                          `${props.form?.expirationTime}`
                        ).toString()
                      : null
                  }
                  onChange={(e)=>props.handleChangeLicenseExpDate('expirationTime',e)}
                  InputProps={{
                    classes: {
                      root: 'theme-cssOutlinedInput',
                      input: 'theme-input',
                      focused: 'theme-cssFocused',
                      notchedOutline: 'theme-notchedOutline'
                    },
                    endAdornment:// Show clear button if date selected
                      <ClearIcon
                        onClick={() => props.handleChangeLicenseExpDate('expirationTime', null)}
                        style={{ cursor: 'pointer' }}
                      />
                  }}
                  InputLabelProps={{
                    classes: {
                      root: 'theme-label',
                      focused: 'theme-label-focused',
                      shrink: 'theme-label-shrink'
                    }
                  }}
                />
                </MuiPickersUtilsProvider>
              </Grid>
            <Grid item xl={3} md={4} xs={12}>
              <TextField
                id='attributeSpeedLimit'
                label={
                  props.translate('attributeSpeedLimit') + '(Km/h)'
                }
                // required
                placeholder=''
                value={
                  (props.form.attributes &&
                    props.form.attributes.speedLimit) ||
                  ''
                }
                onChange={e => props.changeAttribute2('speedLimit', e)}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
          </Grid>
          <h4 style={{ margin: '10px 0' }} className='with-border'>
                {props.translate('tags')}
              </h4>
            <Grid container spacing={1}>
              <Grid item xl={3} md={4} xs={12}>
                <TextField
                  id='tag_1'
                  label={
                    props.logInUser.attributes &&
                    props.logInUser.attributes.ut1
                      ? props.logInUser.attributes.ut1
                      : props.translate('tag_1')
                  }
                  type='text'
                  placeholder=''
                  value={
                    (props.form.tag_1 !== undefined
                      ? props.form.tag_1
                      : props.form.attributes.tag_1) || ''
                  }
                  onChange={e => props.changeAttribute2('tag_1', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xl={3} md={4} xs={12}>
                <TextField
                  id='tag_2'
                  label={
                    props.logInUser.attributes &&
                    props.logInUser.attributes.ut2
                      ? props.logInUser.attributes.ut2
                      : props.translate('tag_2')
                  }
                  type='text'
                  placeholder=''
                  value={
                    (props.form.tag_2 !== undefined
                      ? props.form.tag_2
                      : props.form.attributes.tag_2) || ''
                  }
                  onChange={e => props.changeAttribute2('tag_2', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xl={3} md={4} xs={12}>
                <TextField
                  id='tag_3'
                  label={
                    props.logInUser.attributes &&
                    props.logInUser.attributes.ut3
                      ? props.logInUser.attributes.ut3
                      : props.translate('tag_3')
                  }
                  type='text'
                  placeholder=''
                  value={
                    (props.form.tag_3 !== undefined
                      ? props.form.tag_3
                      : props.form.attributes.tag_3) || ''
                  }
                  onChange={e => props.changeAttribute2('tag_3', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xl={3} md={4} xs={12}>
                <TextField
                  id='tag_4'
                  label={
                    props.logInUser.attributes &&
                    props.logInUser.attributes.ut4
                      ? props.logInUser.attributes.ut4
                      : props.translate('tag_4')
                  }
                  type='text'
                  placeholder=''
                  value={
                    (props.form.tag_4 !== undefined
                      ? props.form.tag_4
                      : props.form.attributes.tag_4) || ''
                  }
                  onChange={e => props.changeAttribute2('tag_4', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xl={3} md={4} xs={12}>
                <TextField
                  id='tag_5'
                  label={
                    props.logInUser.attributes &&
                    props.logInUser.attributes.ut5
                      ? props.logInUser.attributes.ut5
                      : props.translate('tag_5')
                  }
                  type='text'
                  placeholder=''
                  value={
                    (props.form.tag_5 !== undefined
                      ? props.form.tag_5
                      : props.form.attributes.tag_5) || ''
                  }
                  onChange={e => props.changeAttribute2('tag_5', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            </Grid>
        </form>
      }
    />
  </Fragment>
  )
}
export default addDevice