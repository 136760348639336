import React,{useEffect,useState} from 'react'
import { connect } from 'react-redux'
// import { SnackbarProvider, } from 'notistack'
import { withLocalize } from 'react-localize-redux'
import Modal from './modal'
import {isEmpty} from "lodash"

  const screen2 =(props)=>{

    const initialState = {
      form: {
        type: '',
        functionalType: '',
        tolerance: 0,
        minLimit: 0,
        maxLimit: 10,
        geoFences: [],
        logic: '',
        threshold: 5,
        input: '',
        endTime: '',
        startTime: '',
        fenceRadius: 0,
        valueType: '',
        value: ''
      },
      formFirstData: null,
      formFirstChanges: false,
      validatedForm: false,
      showLogicFields: false,
      maintenanceId: '',
      maintenanceData: null,
      Types: {} // This might come from props or another source
    };
  
    const [state, setState] = useState(initialState);
    

  // componentWillMount () {
  //   let { wizardData } = props
  //   if (wizardData && wizardData.form2) {
  //     let geoLenght = checkForLogic(wizardData.form2.form.geoFences)
  //     setState({
  //       ...wizardData.form2,
  //       showLogicFields: geoLenght.length > 1 ? true : false
  //     })
  //   }
  // }

  useEffect(() => {
    let { wizardData } = props
    if (wizardData && wizardData.form2) {
      let geoLenght = checkForLogic(wizardData.form2.form.geoFences)
      setState((prevState) => ({ ...prevState, 
        ...wizardData.form2,
        showLogicFields: geoLenght.length > 1 ? true : false
      }))
    }
  }, [])
  useEffect(() => {
    checkRequiredFields()
  }, [state.form,state.formFirstData,state.formFirstChanges,props.form1,props.form2])
  

  const handleChange = (name, event, subField, inputType) => {
    if (name === 'type' || name === 'input') {
      if (subField) {
        if (subField === 'ecodriving') {
          setState((prevState) => ({ ...prevState, 
              form: {
                ...prevState.form,
                [name]: event,
                input: inputType
              }
            }))
        } else {
          setState((prevState) => ({ ...prevState, 
              form: {
                ...prevState.form,
                [name]: inputType+state.form.index,
                type: event
              }}))
        }
      } else {
        setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              [name]: event
            } }))

            if (event === 'deviceStopped') {
              setState((prevState) => ({ ...prevState, 
                  form: {
                    ...prevState.form,
                    threshold: 5
                  }
                }))
            } else if (event === 'deviceIdling') {
              setState((prevState) => ({ ...prevState, 
                  form: {
                    ...prevState.form,
                    threshold: 10
                  }
                }))
            } else if (event === 'deviceParking') {
              setState((prevState) => ({ ...prevState,
                  form: {
                    ...prevState.form,
                    threshold: 24
                  }
                }
              ))
            } else if (event === 'deviceOverspeed') {
              setState((prevState) => ({ ...prevState,
                  form: {
                    ...prevState.form,
                    tolerance: 0.1,
                    minLimit: 60,
                    maxLimit: 300
                  }
                }))
            } else if (
              event === 'deviceEnterGeofence' ||
              event === 'deviceExitGeofence'
            ) {
              setState((prevState) => ({ ...prevState,
                  form: {
                    ...prevState.form,
                    logic: ''
                  }
                }
              ))
            } 
      }
    } 
    else if(name === 'logic'){
      setState((prevState) => ({ ...prevState,
          form: {
            ...prevState.form,
            logic: event.target.value,
          }
        } ))
    }
    else if (inputType === 'exactValue'){
      setState((prevState) => ({ ...prevState,
          form: {
            ...prevState.form,
            type:inputType
          }
        }))
    }
    else if(name === 'valueType'){
      setState((prevState) => ({ ...prevState,
          form: {
            ...prevState.form,
            valueType:event,
            value:'' 

          }
        }))
    }
    else if (inputType === 'parkingSecurity' || name === "startTime" ||name === "endTime" || name === 'fenceRadius'
      || name === 'ps_plateNumber'
      || name === 'ps_trafficFileNumber'
      || name === 'ps_brand'
      || name === 'ps_model'
      || name === 'ps_color'
      || name === 'ps_state'){
     
      if (name === 'startTime' || name === 'endTime' || name ==='fenceRadius' || name === 'ps_plateNumber'
        || name === 'ps_trafficFileNumber'
        || name === 'ps_brand'
        || name === 'ps_model'
        || name === 'ps_color'
        || name === 'ps_state') {
          let val = ''
          if(name === 'endTime' || name === 'startTime'){
              val = event.target.value
          }
          else{
            val = name ==='fenceRadius'? parseInt(event) : event
          }
          setState((prevState) => ({ ...prevState,
            form: {
              ...prevState.form,
              [name]:val
            }
          }
        ))
      } 
      if(event === 'parkingSecurity') {
        setState((prevState) => ({ ...prevState,
                form: {
                  ...prevState.form,
                  type:event,
                }
              }
            ))
        }
    }
    else if(name === 'speedRange') {
      if(event && event.length) {
        setState((prevState) => ({ ...prevState,
            form: {
              ...prevState.form,
              minLimit: event[0],
              maxLimit: event[1]
            }
          }
        ))
      }
    }
    else {
      const { target } = event
      if (target && target.type === 'checkbox') {
        setState((prevState) => ({ ...prevState,
            form: {
              ...prevState.form,
              [name]: target.checked
            }
          }))
      } 
      else if (name === 'expiration'){
        setState((prevState) => ({ ...prevState,
            form:{
              ...prevState.form,
              type:event
            }
          }))
      }
      else if(name === 'threshold' || name==='minLimit' || name === 'maxLimit' || name==='tolerance'){
        setState((prevState) => ({ ...prevState,
            form: {
              ...prevState.form,
              [name]: event.target.value
            }
          }
        ))
      }
      else if (name === 'functionalType') {
        if (name === 'functionalType') {
          if (event !== state.form[name]) {
            let value = event
            setState((prevState) => ({ ...prevState,
                form: {
                  ...prevState.form,
                  [name]: value,
                  index: ""
                }
              } ))
              
                if (value === 'digital') {
                  setState((prevState) => ({ ...prevState,
                      form: {
                        ...prevState.form,
                        type: '',
                        logic: '',
                        input: ''
                      }
                    }))
                } else if (value === 'analog') {
                  setState((prevState) => ({ ...prevState,
                      form: {
                        ...prevState.form,
                        type: '',
                        minLimit: 0,
                        maxLimit: 10,
                        input: '',
                        logic: ''
                      }
                    }))
                } else if (value === 'ecodriving') {
                  setState((prevState) => ({ ...prevState,
                      form: {
                        ...prevState.form,
                        type: '',
                        minLimit: 0.1,
                        maxLimit: 0.1,
                        tolerance: 0.1,
                        input: '',
                        logic: ''
                      }
                    }))
                } else if (value === 'fuel') {
                  setState((prevState) => ({ ...prevState,
                      form: {
                        ...prevState.form,
                        input: '',
                        type: ''
                      }
                    }))
                } 
                // else if (value === 'maintenanceReminder') {
                //   setState(
                //     {
                //       form: {
                //         ...prevState.form,
                //         type: 'deviceMaintenanceReminder',
                //         minLimit: '',
                //         maxLimit: ''
                //       }
                //     },
                //     () => checkRequiredFields()
                //   )
                // } 
                else if (value === 'output') {
                  setState((prevState) => ({ ...prevState,
                      form: {
                        ...prevState.form,
                        type: '',
                        input: '',
                        logic: ''
                      }
                    }))
                } else {
                  setState((prevState) => ({ ...prevState,
                      form: {
                        ...prevState.form,
                        type: '',
                        input: ''
                      }
                    }))
                }
          }
        }
      } else if(name === 'index') {
        if(Types && state.form && state.form.functionalType && state.form.type) {
          const currentRow = Types[state.form.functionalType].find(row => row.value === state.form.type)
          
          setState((prevState) => ({ ...prevState,
              form: {
                ...prevState.form,
                input: ((currentRow && currentRow.input) || state.form.type) +(target.value || ""),
                [name]: target.value
              }
            }))
        }
      } else {
        if(state.form.type ==="exactValue" && state.form.valueType){
          let {valueType} = state.form
          let findBool = false
          if(valueType==='boolean'){
              findBool = (event.toLowerCase() === 'true'); // true
            }
            setState((prevState) => ({ ...prevState,
              form: {
                ...prevState.form,
                value:valueType==='number'?parseInt(event):valueType==='boolean'? findBool : event.toLocaleString() 
              }
            }))
        }
        else{
          setState((prevState) => ({ ...prevState,
              form: {
                ...prevState.form,
                [name]: event
              }
            }))
        }
      }
    }
  }

  const onChangedMaintence = item => {
    setState((prevState) => ({ ...prevState,
        form: {
          ...prevState.form,
          maintenanceId: item && item.id ? item.id : '',
          maintenanceData: item || ''
        }
      }))
  }

  const geofencesHandelChange = groFeneList => {
    let geoLenght = checkForLogic(groFeneList)
    let showFiels = false,
      logicdata = ''
    if (geoLenght.length > 1) {
      showFiels = true
      logicdata = state.form.logic
    } else {
      showFiels = false
      logicdata = ''
    }
    setState((prevState) => ({ ...prevState,
        form: {
          ...prevState.form,
          geoFences: groFeneList,
          logic: logicdata,
        },
        showLogicFields: showFiels
      }))
  }

  const checkRequiredFieldsForFristForm = (data, fristFormChanges) => {
    setState((prevState) => ({ ...prevState,
        formFirstData: data,
        formFirstChanges: fristFormChanges
      }))
  }
  const [timeError,setTimeError] = useState(false)
  
  const checkRequiredFields = () =>{
    let { formFirstData, formFirstChanges } = state
    let { form1, form2 } = props.wizardData
    let {
      type,
      functionalType,
      tolerance,
      minLimit,
      maxLimit,
      geoFences,
      logic,
      threshold,
      input,
      endTime,
      startTime,
      fenceRadius,
      valueType,
    } = state.form
    let value = true,
      finalVailadtion = false

      if (endTime && startTime && new Date(endTime) < new Date(startTime)) {
        setState((prevState) => ({ ...prevState, validatedForm: false }));
        setTimeError(true)
        return;
      }
      setTimeError(false)

    if (functionalType) {
      if (functionalType === 'deviceStatus' || functionalType === 'fuel') {
        if (type) {
          finalVailadtion = true
        } else {
          finalVailadtion = false
        }
      } else if (functionalType === 'ecodriving') {
        if (
          type &&
          tolerance &&
          (minLimit || minLimit === 0) &&
          (maxLimit || maxLimit === 0)
        ) {
          finalVailadtion = true
        } else {
          finalVailadtion = false
        }
      } else if (functionalType === 'digital' && input && logic) {
        finalVailadtion = true
      } else if (
        functionalType === 'analog' &&
        input &&
        logic &&
        (minLimit || minLimit === 0) &&
        maxLimit
      ) {
        finalVailadtion = true
      } else if (functionalType === 'other' && input) {
        finalVailadtion = true
      } else if (functionalType === 'output' && input && logic) {
        finalVailadtion = true
      } 
      // else if (
      //   functionalType === 'maintenanceReminder' &&
      //   maintenanceId &&
      //   (minLimit || minLimit === 0) &&
      //   maxLimit
      // ) {
      //   finalVailadtion = true
      // } 
      else if (type) {
        if (type === 'deviceOverspeed') {
          if (tolerance && (minLimit || minLimit === 0)) {
            finalVailadtion = true
          } else {
            finalVailadtion = false
          }
        } else if (type === 'deviceMoving' || type === 'deviceTowing') {
          finalVailadtion = true
        } else if (
          type === 'deviceStopped' ||
          type === 'deviceParking' ||
          type === 'deviceIdling'
        ) {
          if (threshold) {
            finalVailadtion = true
          } else {
            finalVailadtion = false
          }
        } else if (
          type === 'deviceEnterGeofence' ||
          type === 'deviceExitGeofence'
        ) {
          if (geoFences.length) {
            let chdata = checkForLogic(geoFences)
            if (chdata.length > 1) {
              if (logic) {
                finalVailadtion = true
              } else {
                finalVailadtion = false
              }
            } else if (chdata.length === 0) {
              finalVailadtion = false
            } else {
              finalVailadtion = true
            }
          } else {
            finalVailadtion = false
          }
        }
      } else {
        finalVailadtion = false
      }
    }
    // if (functionalType && functionalType === 'maintenanceReminder') {
    //   if (value && finalVailadtion) {
    //     setState({
    //       validatedForm: true
    //     })
    //   } else {
    //     setState({
    //       validatedForm: false
    //     })
    //   }
    // } else {
      if (
        value &&
        finalVailadtion &&
        ((formFirstData && formFirstData.validatedForm) || !formFirstChanges)
      ) {
         setState((prevState) => ({ ...prevState,
          validatedForm:  true
        }))
      } else {
        if (functionalType === "expiration") {
          if(formFirstData?.validatedForm && formFirstData?.form?.deviceIds?.length >0  ){
               setState((prevState) => ({ ...prevState,
                validatedForm: true
              }))
          }else{
            setState((prevState) => ({ ...prevState,
              validatedForm: false
            }))
          }
        }
      else if (functionalType === "customAlert") {
          let deviceIdsTye = typeof (formFirstData?.form?.deviceIds)
          let chck = form1?.form?.deviceIds !== formFirstData?.form?.deviceIds
          let chck2 = form2&&form2.form !== state.form
          if((props.editOption) && (chck || chck2)){
             setState((prevState) => ({ ...prevState,
              validatedForm: true
            }))
          } 
           else if(!props.editOption && formFirstData?.form?.deviceIds && fenceRadius && startTime && endTime){
             if(deviceIdsTye === 'object'){
                if(!isEmpty (formFirstData?.form?.deviceIds)){
                  if(formFirstData?.form?.deviceIds?.value === ''){
                    setState((prevState) => ({ ...prevState,
                      validatedForm: false
                    }))
                    // finalVailadtion = false
                  }else{
                    setState((prevState) => ({ ...prevState,
                      validatedForm: true
                    }))
                  }

                }
             }
          }
      }
      // else{
      //   setState({
      //     validatedForm: false
      //   })
      // }
      
      else if (functionalType=== "otherAlert"){
           let deviceIdsCheck = formFirstData&&formFirstData.form&&formFirstData.form.deviceIds&&formFirstData.form.deviceIds.length
          if(type && valueType && (state.form&&state.form.value) && (deviceIdsCheck>0) ){
               setState((prevState) => ({ ...prevState,validatedForm:true}))
          }
          else  setState((prevState) => ({ ...prevState,validatedForm:false}))
         }
         else{
          if(formFirstData?.form?.deviceIds?.length < 1 || (geoFences && geoFences.length<1)){
            setState((prevState) => ({ ...prevState,
              validatedForm: false
            }))
          }else if(formFirstData?.form?.deviceIds?.length > 0 || (geoFences && geoFences.length >0)){
            setState((prevState) => ({ ...prevState,
              validatedForm: true
            }))
          }
         }
      }
  }
  const checkForLogic = list => {
    let array = []
    if (list.length) {
      list.map(item => {
        if (item.visible) {
          array.push(item.id)
        }
        return null
      })
    }
    return array
  }
  const next = () => {
    let FnData = []
    FnData.push({ ...state, prv: true })
    FnData.push({ ...state.formFirstData })
    props.combineAllData('form2', FnData, '', true)
  }

  // render () {
    let assignData = ''
    let { mode, itemData } = props
    if (mode && mode === 'update') {
      assignData = {
        id: itemData.id,
        name: itemData.name
      }
    }
    return (
      <>
        <Modal
          {...props}
          {...state}
          timeError={timeError}
          next={next}
          assignData={assignData}
          handleChange={handleChange}
          // onAddAnotherMail={onAddAnotherMail}
          geofencesHandelChange={geofencesHandelChange}
          onChangedMaintence={onChangedMaintence}
          checkRequiredFieldsForFristForm={checkRequiredFieldsForFristForm}
        />
      </>
    )
  // }
}

const mapState = state => {
  return {
    notificationType: state.notificationType
  }
}
const mapStateToProps = connect(mapState)
export const Screen2 = mapStateToProps((withLocalize(screen2)))

export const notificationFunctionTypes = [
  {
    key: 'movement',
    value: 'movement',
    field: 'type'
  },
  // {
  //   key: 'maintenanceReminder',
  //   value: 'maintenanceReminder'
  // },
  {
    key: 'digital',
    value: 'digital',
    field: 'input',
    subField: 'digital'
  },
  {
    key: 'analog',
    field: 'input',
    subField: 'analog'
  },
  {
    key: 'other',
    value: 'other',
    field: 'input',
    subField: 'other'
  },
  // {
  //   key: 'output'
  // },
  { key: 'deviceStatus', value: 'deviceStatus', field: 'type' },
  {
    key: 'ecodriving',
    value: 'ecodriving',
    field: 'type',
    subField: 'ecodriving'
  },
  {
    value: 'fuel',
    key: 'fuel',
    field: 'type'
  },
  {
    value: 'expiration',
    key: 'expiration',
    field: 'expiration'
  },
  {
    value: 'customAlert',
    key: 'customAlert',
    field: 'customAlert'
  },
  {
    value: 'otherAlert',
    key: 'otherAlert',
    field: 'otherAlert'
  },
]

export const digitalAdvTypes = [
  {
    key: 'ON'
  },
  {
    key: 'OFF'
  },
  {
    key: 'BOTH'
  }
]
export const Types = {
  movement: [
    {
      label: 'notification.deviceEnterGeofence',
      value: 'deviceEnterGeofence',
      input: 'deviceEnterGeofence'
    },
    {
      label: 'notification.deviceExitGeofence',
      value: 'deviceExitGeofence',
      input: 'deviceExitGeofence'
    },
    {
      label: 'notification.deviceOverspeed',
      value: 'deviceOverspeed',
      input: 'deviceOverspeed'
    },
    {
      label: 'notification.deviceStopped',
      value: 'deviceStopped',
      input: 'deviceStopped'
    },
    {
      label: 'notification.deviceMoving',
      value: 'deviceMoving',
      input: 'deviceMoving'
    },
    {
      label: 'notification.deviceTowing',
      value: 'deviceTowing',
      input: 'deviceTowing'
    },
    {
      label: 'notification.deviceIdling',
      value: 'deviceIdling',
      input: 'deviceIdling'
    },
    {
      label: 'notification.deviceParking',
      value: 'deviceParking',
      input: 'deviceParking'
    }
  ],
  deviceStatus: [
    {
      label: 'notification.deviceOnline',
      value: 'deviceOnline',
      input: 'deviceOnline'
    },
    {
      label: 'notification.deviceUnknown',
      value: 'deviceUnknown',
      input: 'deviceUnknown'
    },
    {
      label: 'notification.deviceOffline',
      value: 'deviceOffline',
      input: 'deviceOffline'
    }
  ],
  digital: [
    {
      label: 'notification.deviceIgnition',
      value: 'deviceIgnition',
      input: 'ignition'
    },
    {
      label: 'notification.devicePanicButton',
      value: 'devicePanicButton',
      input: 'panicButton'
    },
    {
      label: 'notification.deviceExternalPower',
      value: 'deviceExternalPower',
      input: 'externalPower'
    },
    {
      label: 'notification.deviceEngineStatus',
      value: 'deviceEngineStatus',
      input: 'enginStatus'
    },
    {
      label: 'notification.deviceCarAlarm',
      value: 'deviceCarAlarm',
      input: 'carAlarm'
    },
    {
      label: 'notification.deviceDoors',
      value: 'deviceDoors',
      input: 'doors'
    },
    {
      label: 'custom',
      value: 'digitalCustom',
      input: ''
    }
  ],
  analog: [
    {
      label: 'notification.deviceFuel',
      value: 'deviceFuel',
      input: 'fuel'
    },
    {
      label: 'notification.deviceTemperature',
      value: 'deviceTemperature',
      input: 'temperature'
    },
    { label: 'notification.deviceRpm', value: 'deviceRpm', input: 'rpm' },
    {
      label: 'notification.deviceBoardPower',
      value: 'deviceBoardPower',
      input: 'boardPower'
    },
    {
      label: 'notification.deviceMileage',
      value: 'deviceMileage',
      input: 'mileage'
    },
    {
      label: 'notification.deviceCounter',
      value: 'deviceCounter',
      input: 'counter'
    },
    {
      label: 'notification.deviceAverageFuelConsumption',
      value: 'deviceAverageFuelConsumption',
      input: 'averagFuelConsumption'
    },
    {
      label: 'notification.deviceInstantFuelConsumption',
      value: 'deviceInstantFuelConsumption',
      input: 'instantFuelConsumption'
    },
    {
      label: 'notification.deviceFlowMeter',
      value: 'deviceFlowMeter',
      input: 'flowMeter'
    },
    {
      label: 'sensorsTranslation.humidity',
      value: 'humidity',
      input: 'humidity'
    },
    {
      label: 'sensorsTranslation.weight',
      value: 'weight',
      input: 'weight'
    },
    {
      label: 'custom',
      value: 'analogCustom',
      input: ''
    }
  ],
  other: [
    {
      label: 'notification.driverChanged',
      value: 'driverChanged',
      input: 'driverUniqueId'
    },
    {
      label: 'notification.passengerChanged',
      value: 'passengerChanged',
      input: 'passengerUniqueId'
    },
    {
      label: 'notification.trailerChanged',
      value: 'trailerChanged',
      input: 'trailerUniqueId'
    },
    {
      label: 'notification.binChanged',
      value: 'binChanged',
      input: 'binUniqueId'
    }
  ],
  fuel: [
    {
      label: 'notification.deviceFuelFilling',
      value: 'deviceFuelFilling',
      input: 'deviceFuelFilling'
    },
    {
      label: 'notification.deviceFuelDrain',
      value: 'deviceFuelDrain',
      input: 'deviceFuelDrain'
    }
  ],
  ecodriving: [
    {
      label: 'notification.harshBrake',
      value: 'harshBrake',
      input: 'accelBrake'
    },
    { label: 'notification.harshTurn', value: 'harshTurn', input: 'accelTurn' },
    {
      label: 'notification.harshAcceleration',
      value: 'harshAcceleration',
      input: 'accel'
    },
    { label: 'notification.harshBump', value: 'harshBump', input: 'accelBump' }
  ],
  expiration: [
    {
      label: 'notification.deviceExpire',
      value: 'deviceExpire',
      input: 'deviceExpire'
    }],
  customAlert: [
    {
      label: 'notification.parkingSecurity',
      value: 'parkingSecurity',
      input: 'parkingSecurity'
    },
    // {
    //   label: 'notification.driverExpire',
    //   value: 'driverExpire',
    //   input: 'driverExpire'
    // },
    // {
    //   label: 'notification.vehicleExpire',
    //   value: 'vehicleExpire',
    //   input: 'vehicleExpire'
    // },
    // {
    //   label: 'notification.binderExpire',
    //   value: 'binderExpire',
    //   input: 'binderExpire'
    // },
    // {
    //   label: 'notification.userExpire',
    //   value: 'userExpire',
    //   input: 'userExpire'
    // }
  ],
  otherAlert: [
    {
      label: 'exactValue',
      value: 'exactValue',
      input: 'exactValue'
    }
  ],
}
