import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select';
// import AsyncSelect from "react-select/async";
// import { makeStyles } from '@mui/styles'
// import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import SearchField from './SearchField'
// import Paper from '@mui/material/Paper'
// import MenuItem from './MenuItem'
import '@mui/system'
import { connect } from 'react-redux'
import { selectStyles } from '../../Helpers'

// const styles = theme => ({
//   root: {
//     flexGrow: 1
//   },
//   input: {
//     display: 'flex'
//     //padding: '8px 14px'
//   },
//   valueContainer: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     flex: 1,
//     alignItems: 'center',
//     overflow: 'hidden',
//     paddingLeft: theme.spacing(1)
//   },
//   noOptionsMessage: {
//     padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`
//   },
//   singleValue: {
//     fontSize: 'inherit',
//     color: 'inherit'
//   },
//   paper: {
//     position: 'absolute',
//     marginTop: theme.spacing(1),
//     left: 0,
//     right: 0
//   },
//   divider: {
//     height: theme.spacing(1) * 2
//   }
// })

// function NoOptionsMessage (props) {
//   return (
//     <Typography
//       color='textSecondary'
//       className={props.selectProps.classes.noOptionsMessage}
//       {...props.innerProps}
//     >
//       {props.children}
//     </Typography>
//   )
// }

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  
  if (props.selectProps.fill) {
    return (
      <SearchField
        fullWidth
        disabled={props.selectProps.isDisabled}
        InputProps={{
          inputComponent,
          inputProps: {
            readOnly: props.selectProps.readOnly,
            className: ('theme-input-select '+props.selectProps.isDisabled ? 'disabled' : ''),
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    )
  } else {
    return (
      <TextField
        fullWidth
        variant='outlined'
        disabled={props.selectProps.isDisabled}
        InputProps={{
          inputComponent,
          classes: {
            root: 'theme-input-select',
            focused: 'theme-cssFocused',
            notchedOutline: 'theme-notchedOutline'
          },
          inputProps: {
            readOnly: props.selectProps.readOnly,
            className: 'theme-input-select',
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    )
  }
}

// function Option (props) {
//   return (
//     <MenuItem
//       buttonRef={props.innerRef}
//       selected={props.isFocused}
//       component='div'
//       style={{
//         fontWeight: props.isSelected ? 500 : 400
//       }}
//       {...props.innerProps}
//     >
//       {props.children}
//     </MenuItem>
//   )
// }

// function SingleValue (props) {
//   return (
//     <Typography
//       className={props.selectProps.classes.singleValue}
//       {...props.innerProps}
//     >
//       {props.children}
//     </Typography>
//   )
// }

// function ValueContainer (props) {
//   return (
//     <div className={props.selectProps.classes.valueContainer}>
//       {props.children}
//     </div>
//   )
// }

// function Menu (props) {
//   return (
//     <Paper
//       square
//       className={props.selectProps.classes.paper}
//       style={{ zIndex: 2 }}
//       {...props.innerProps}
//     >
//       {props.children}
//     </Paper>
//   )
// }

// const components = {
//   Control,
//   Menu,
//   NoOptionsMessage,
//   Option,
//   SingleValue,
//   ValueContainer
// }

class SingleSelect extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (value) {
    let index = this.props.index || 0
    let name = this.props.selectName || 'single'
    if (value) {
      if (value.id && this.props.canAssign) {
        this.setState(
          {
            [name]: value
          },
          () => {
            if (this.props.handleChange) {
              this.props.handleChange(name, value, index)
            }
            if (this.props.returnSelected) {
              this.props.returnSelected(value, 'POST')
            }
          }
        )
      }else if(name ==='trasmissionType'){
        this.setState(
          {
            [name]: value.name
          },
          () => {
            if (this.props.handleChange) {
              this.props.handleChange(name, value.name)
            }
          }
        )  
      }
       else {
        if (
          name === 'timezone' ||
          name === 'decoder.timezone' ||
          name === 'lang'
        ) {
          this.setState(
            {
              [name]: value.id
            },
            () => {
              if (this.props.handleChange) {
                this.props.handleChange(name, value,index)
              }
            }
          )
        }
      }
    }else {
      this.props.handleChange(name, '',index)
    }
  }
  componentWillReceiveProps (n) {
    this.setState({ [n.selectName]: n.value })
  }

  render () {
    const { array, themecolors,mileageCounter } = this.props
    const suggessions = array&&array.map(v => {
      if(mileageCounter){
        return { ...v, id: v.key || v.id, label: v.name + `      Total: ${(v?.mileage || '')}  km,   ` + 
              `In : ${v.attributes &&v.attributes&&v.attributes.cityMileage ? (v.attributes.cityMileage/1000).toFixed(2) : 0} km,   `  +
               `      Out : ${v?.attributes?.interCityMileage/1000 || 0} km   `
        }
      }
      else{
        return { ...v, id: v.key || v.id, label: v.name || v.label || v.key }
      }
    })

    if (
      this.state[this.props.selectName] === undefined &&
      this.props.value !== undefined
    ) {
      this.setState({ [this.props.selectName]: this.props.value })
    }
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
      readOnly
    } = this.props
    return (
      <div style={{ zIndex: 2, position: 'relative' }}>
        {!this.props.async && (
          <Select
            {...this.props}
            styles={selectStyles(themecolors)}
            readOnly={readOnly}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            value={this.state[this.props.selectName] || ''}
            components={{ Control }}
            isSearchable={isSearchable}
            options={suggessions}
            onChange={e => this.handleChange(e)}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral0: themecolors.backgroundColor,
                neutral5: themecolors.backgroundColor,
                neutral10: themecolors.backgroundColor,
                neutral20: themecolors.backgroundColor,
                neutral30: themecolors.textColor,
                neutral40: themecolors.textColor,
                neutral50: themecolors.textColor,
                neutral60: themecolors.textColor,
                neutral70: themecolors.textColor,
                neutral80: themecolors.textColor,
                neutral90: themecolors.textColor,
                dangerLight: themecolors.themeLightColor,
                danger: themecolors.themeLightInverse,
                primary75: themecolors.themeLightColor,
                primary50: themecolors.themeLightColor,
                primary25: themecolors.themeLightColor,
                primary: themecolors.themeLightColor
              }
            })}
            placeholder={this.props.label || 'Select'}
          />
        )}
        {this.props.async && (
          <Select
            {...this.props}
            styles={selectStyles(themecolors)}
            readOnly={readOnly}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            value={this.state[this.props.selectName] || ''}
            //components={components}
            components={{ Control }}
            isSearchable={isSearchable}
            onChange={this.handleChange}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral0: themecolors.backgroundColor,
                neutral5: themecolors.backgroundColor,
                neutral10: themecolors.backgroundColor,
                neutral20: themecolors.backgroundColor,
                neutral30: themecolors.textColor,
                neutral40: themecolors.textColor,
                neutral50: themecolors.textColor,
                neutral60: themecolors.textColor,
                neutral70: themecolors.textColor,
                neutral80: themecolors.textColor,
                neutral90: themecolors.textColor,
                dangerLight: themecolors.themeLightColor,
                danger: themecolors.themeLightInverse,
                primary75: themecolors.themeLightColor,
                primary50: themecolors.themeLightColor,
                primary25: themecolors.themeLightColor,
                primary: themecolors.themeLightColor
              }
            })}
            options={this.props.defaultOptions || []}
            placeholder={this.props.label || 'Select'}
          />
        )}
      </div>
    )
  }
}

SingleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    themecolors: state.themeColors,
  }
}

export default connect(mapStateToProps)(SingleSelect)
