import React, { useState, useEffect } from 'react';
// import isEqual from 'react-fast-compare';
import Summery from './SummeryView';
import Details from './DetailView';
import { ReportColumns } from '../../Helpers';
import moment from 'moment';
import { getDateTimeFormat } from '../../Helpers';
import { filterGreaterThan, SliderColumnFilter } from '../common/ReactBaseTable';

const GenericReport = (props) => {
  const [state, setState] = useState({
    reportDialog: false,
    reportColumns: {},
    columns: [],
    hiddenColumns: []
  });
const [isChanged,setisChanged]=useState(false)
  // const handleClick = (event, name) => {
  //   event.stopPropagation();
  //   setState(prevState => ({
  //     ...prevState,
  //     [name]: true
  //   }));
  // };

  const onChange = (event, name) => {
    const isChecked = event.target.checked;
    const updatedHiddenColumns = isChecked
      ? state.hiddenColumns.filter(f => f !== name)
      : [...state.hiddenColumns, name];

    setState(prevState => ({
      ...prevState,
      reportColumns: {
        ...prevState.reportColumns,
        [name]: {
          ...prevState.reportColumns[name],
          checked: isChecked
        }
      },
      hiddenColumns: updatedHiddenColumns
    }));
    setisChanged(true)
  };

  const toggleFilter = (event) => {
    props.toggleFilter(event, drawColumns, props.table);
  };

  // const onCloseModal = (e, name) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     [name]: false
  //   }));
  // };

  const drawColumns = (table) => {
    const updatedColumns = [];
    let checkedList = {};
    let alreadyAssigned = false;

    if (Object.keys(state.reportColumns).length) {
      alreadyAssigned = true;
      checkedList = state.reportColumns;
    }

    if (table && table.columns) {
      table.columns.forEach(c => {
        if (!alreadyAssigned) {
          switch (c.f) {
            case 'label':
            case 'vehicleLicensePlate':
              checkedList[c.f] = {
                checked: props.reportUnits > 14,
                label: c.h
              };
              break;
            default:
              if (!Object.keys(state.reportColumns).length) {
                checkedList[c.f] = { checked: true, label: c.h };
              }
              break;
          }
        }

        let width = 150;

        if (ReportColumns[c.f]) {
          width = ReportColumns[c.f].width;
        }

        const filter = {};

        if (["duration", "engineHours", "movementDuration", "idleDuration", "startOdometer", "endOdometer", "averageSpeed", "maxSpeed", "tripCount", "stopCount", "fillCount", "drainCount", "distance", "idleDurationPercentage", "movementDurationPercentage", "fillVolume", "drainVolume", "startFuelLevel", "endFuelLevel", "fuelConsumption", "spentFuel", "penalty", "periodCount", "eventCount", "visitCount", "violationCount", "count"].includes(c.f)) {
          filter.Filter = SliderColumnFilter;
          filter.filter = filterGreaterThan;
        }

        updatedColumns.push({
          Header: c.h,
          id: c.f,
          accessor: d => {
            if (d && d.length) {
              let data = "";
              d.forEach(r => {
                if (r.f === c.f) {
                  data = r;
                  switch (c.f) {
                    case 'distance':
                      data = Math.round(r.v * 100) / 100;
                      break;
                    case 'entranceTime':
                    case 'exitTime':
                    case 'startTime':
                    case 'time':
                    case 'endTime':
                    case 'eventTime':
                    case 'serverTime':
                      data = r.r ? moment(r.r).format(getDateTimeFormat()) : null;
                      break;
                    case 'completedValue':
                    case 'targetValue':
                      data = r.t === 'date' ? moment(r.r).format(getDateTimeFormat()) : r.v;
                      break;
                    case 'attributes':
                      data = typeof r.v === 'object' ? JSON.stringify(r.v) : r.v;
                      break;
                    case 'sensorValue':
                      data = typeof r.v === 'boolean' ? (r.v ? 'ON' : 'OFF') : r.v;
                      break;
                    default:
                      break;
                  }
                }
              });
              return typeof data !== 'object' ? data : data?.v || "";
            }
            return null;
          },
          width,
          ...filter,
          show: checkedList[c.f].checked,
          Footer: c.f
        });
      });
    }

    setState(prevState => ({
      ...prevState,
      reportColumns: checkedList,
      columns: updatedColumns
    }));
  };



  useEffect(() => {
    if (isChanged || isChanged === false) {
      drawColumns(props.table);
      if (isChanged) {
        setisChanged(false);
      }
    }
  }, [isChanged, props.table]);

  const { table } = props;
  const report = props.detail === true && table.type === 'nestedTable' ? (
    <Details
      title={table.head || props.title}
      onRender={props.onRender}
      table={table}
      serverTimeZone={props.serverTimeZone}
      columns={state.columns}
      handleChange={onChange}
      dialogColumns={state.reportColumns}
      hiddenColumns={state.hiddenColumns}
      toggleFilter={toggleFilter}
      disableFilter={props.disableFilter}
      translate={props.translate}
    />
  ) : (
    <Summery
      title={table.head || props.title}
      onRender={props.onRender}
      table={table}
      serverTimeZone={props.serverTimeZone}
      columns={state.columns}
      handleChange={onChange}
      dialogColumns={state.reportColumns}
      hiddenColumns={state.hiddenColumns}
      toggleFilter={toggleFilter}
      disableFilter={props.disableFilter}
      translate={props.translate}
    />
  );

  return report;
};

export default GenericReport;
