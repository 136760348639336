import React from 'react';
import RotatedMarker from './VehicleRotatedMarker';
import L from 'leaflet';
import { setIconOnMap2 } from '../../Helpers';

class UpdatedMarker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      position: props.position
    };
    this.animId = null;
    this.runningPosition = null;
  }

  setIcon = (course) => {
    return L.divIcon({
      iconUrl:
        '/assets/category/default/' +
        (this.props.category || 'default') +
        'top.svg',
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      tooltipAnchor: [0, -20],
      className: 'custom-marker',
      html: this.props?.logInUser?.attributes?.unitsViewType === 'default'
        ? `<img
      style="transform: rotate(${course}deg)"
        src=
          '/assets/category/default/${this.props.category ||
            'default'}top.svg'
        
        alt=''
      />
      `
        : `<p style="transform: rotate(${course}deg)">${setIconOnMap2(this.props.category, this.props.status, this.props?.motionStatus, this.props?.logInUser?.attributes?.unitsViewType, this.props.color)}</p>`
    });
  }

  angle = (cx, cy, ex, ey) => {
    var dy = ey - cy;
    var dx = ex - cx;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    return theta;
  }

  getHeading = (e) => {
    var r = 0,
        i = L.latLng(e[0]);
    return e.forEach(((e, o) => {
        var s = L.latLng(e);
        if (o > 0 && !i.equals(s)) {
            var a = this.angle(s.lat, s.lng, i.lat, i.lng);
            return r = a;
        }
    })), e && e.length ? r : null;
  }

  setPosition = (el, position, from, tail, tracking, bounding) => {
    el.setLatLng(position);
    this.runningPosition = position;
    tracking && tail && tail.setLatLngs([[from.lat, from.lng], [position.lat, position.lng]]);
    this.ref && this.ref?.editing?._marker.setIcon(this.setIcon(this.getHeading([[position.lat, position.lng], [from.lat, from.lng]])));
    if(tracking && bounding) {
      this.ref && this.ref?.editing?._marker?._map && this.ref?.editing?._marker?._map.setView(position,this.ref?.editing?._marker?._map.getZoom(), {
        animate: true,
        duration: 0.5
      });
    }
  }

  animationInterval = (e) => {
    var t = 16 - e;
    return t > 0 ? Math.max(100, 50 * Math.pow(t, 2)) : 0;
  }

  interpolatePosition = (e, t, n, r) => {
    if (0 === r) return e;
    var i = n / r;
    return {
      lat: e.lat + i * (t.lat - e.lat),
      lng: e.lng + i * (t.lng - e.lng),
      updated: e.updated.add(n)
    };
  }

  areCoordinatesDistantEnough = (coord_one, coord_two) => {
    return (
      JSON.stringify(coord_one.lat.toString().substring(8, 3)) !==
      JSON.stringify(coord_two.lat.toString().substring(8, 3))
    );
  }

  animateMarker = (map, leafletElement, coordFrom, coordTo, tail, tracking, bounding) => {
    this.runningPosition = coordFrom;
    const e = this;
    const t = function t() {
      if(e.animId === null) return e.animId = requestAnimationFrame(t);
      var n = e.runningPosition,
      r = e.animationInterval(map && map.getZoom && map.getZoom() || 15),
      i = coordTo,
      o = i.updated.diff(n.updated);
      if (0 >= o) return e.stop(e?.animId);
      
      var a = function() {
        var t = r || 17,
        i = coordTo,
        a = i.updated.diff(n.updated),
        s = o < 15e3 ? 1 : Math.pow(o / 15e3, 1.2);

        return e.interpolatePosition(n, i, t * s, a);
      },

      s = function() {
        return e.interpolatePosition(n, i, o - 1e4, o);
      };
      e.setPosition(leafletElement, o >= 0 && o > 3e4 ? s() : a(), coordFrom, tail, tracking, bounding);
      e.animId = setTimeout((function() {
        e.animId = requestAnimationFrame(t);
      }), r);
    };
    t();
  }

  stop = () => {
    return this.animId && (clearTimeout(this.animId), cancelAnimationFrame(this.animId), this.animId = null);
  }

  componentWillUnmount () {
    this.stop(this.animId);
  }

  componentWillReceiveProps (nextProps) {
    try {
      if (
        this.areCoordinatesDistantEnough(nextProps.position, this.props.position) &&
        this.ref &&
        this.ref?.editing?._marker
      ) {
        this.animateMarker(
          this.ref?._map,
          this.ref?.editing?._marker,
          this.props.position,
          nextProps.position,
          nextProps.polyHead,
          nextProps.tracking,
          nextProps.bounding
        );
        if(nextProps.tracking) {
          nextProps.polyHead && nextProps.polyHead.setStyle({color: nextProps.color});
        }
        return false;
      }
    } catch (ERR) {
      console.error(ERR);
      return false;
    }
  }

  makeRef = e => {
    this.ref = e;
    if (this.ref && this.ref?.editing?._marker) {
      this.ref?.editing?._marker?.setLatLng(this.props.position);
      this.ref?.editing?._marker?.setIcon(this.setIcon(this.props.course));
    }
  }

  render () {
    return (
      <RotatedMarker
        {...this.props}
        ref={this.makeRef}
        position={this.state.position}
      />
    );
  }
}

export default UpdatedMarker;
