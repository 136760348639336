import axios from "axios";
import React from "react";
import { saveGroupWiseDevices } from "../../Actions/Groups";
import { fetchMoreDevices } from "../../Actions/Devices";
import Notifications from "react-notification-system-redux";
import { Translate } from "react-localize-redux";
import instance from "../../axios";
import { errorHandler } from "../../Helpers";
const initalState = {
  page: 1,
  perPage: 50,
  total: 0,
  hasNext: false,
  data: [],
  loading: false,
};

const CancelToken = axios.CancelToken;
const CancelToken1 = axios.CancelToken;
let source;
let source1;

// This function takes a component...
export default function withResources(WrappedComponent, itemType) {
  // ...and returns another component...
  return class extends React.Component {// eslint-disable-line react/display-name
    constructor(props) {
      super(props);
      this.state = {
        data: { ...initalState },
        nested: { ...initalState },
        searchText: "",
        loadingItem: 0,
        limit: 50,
        page: 1,
        hasMore: true,
      };
    }

    changeResource = (body, callback) => {
      const method = body && body.id ? "PUT" : "POST";
      const url =
        body && body.id ? `/api/itemgroups/${body.id}` : `/api/itemgroups`;
      let dataFormat = {
        ...body,
        description: body.description || "",
      };
      instance({
        url,
        method,
        data: dataFormat,
      })
        .then(() => {
          // if(response.status === 200 && response.data && response.data.status === 'success') {
          this.props.dispatch(
            Notifications.success({
              message:
                method === "POST" ? (
                  <Translate id="groupAdded" />
                ) : (
                  <Translate id="groupUpdated" />
                ),
              autoDismiss: 5,
            })
          );
          // }
          this.setState({ data: { ...initalState } }, () => {
            this.fetchMore(1);
          });
        })
        .catch((error) => {
          this.setState({
            data: {
              ...this.state.data,
              loading: false,
            },
          });
          // errorHandler(error, this.props.dispatch, this.errorCallBack)
          if (callback) {
            callback(error);
          }
        });
    };
    // errorCallBack = (error) => {
    //     this.setState({
    //         data: {
    //             ...this.state.data,
    //             loading: false
    //         }
    //     })
    // }
    deleteResource = (id, callback) => {
      const method = "DELETE";
      const url = `/api/itemgroups/${id}`;
      instance({
        url,
        method,
      })
        .then((response) => {
          // if(response.status === 200) {
          this.props.dispatch(
            Notifications.success({
              message: <Translate id="groupDeleted" />,
              autoDismiss: 5,
            })
          );
          // }
          if (callback) {
            callback(response);
          }
          this.setState({ data: { ...initalState } }, () => {
            this.fetchMore(1);
          });
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch, this.errorCallBack);
          if (callback) {
            callback(error);
          }
        });
    };

    componentDidMount() {
      source = CancelToken.source();
      source1 = CancelToken1.source();
      this.fetchMore();
    }
    componentWillUnmount() {
      if (source) {
        source.cancel();
      }
      if (source1) {
        source1.cancel();
      }
      this.props.dispatch(saveGroupWiseDevices([]));
    }

    onSearchNested = (id, text) => {
      this.setState(
        {
          searchTextNested: text,
          searchText: text,
          nested: { ...initalState },
        },
        () => {
          this.fetchItems(id, 1);
        }
      );
    };

    onSearch = (text) => {
      this.setState(
        {
          searchText: text,
          data: { ...initalState },
          page: 1,
        },
        () => {
          this.fetchMore();
        }
      );
    };

    onNext = () => {
      this.fetchMore();
    };
    callOnMount = () => {
      this.setState(
        {
          searchText: "",
        },
        () => {
          this.fetchMore();
        }
      );
    };
    handleScrollGroups = (values) => {
      const { scrollTop, clientHeight, scrollHeight } = values;
      const { hasMore } = this.state;
      if (hasMore) {
        // if (scrollTop + clientHeight >= scrollHeight-MARGIN) {
        if (Math.round(scrollHeight - scrollTop) === Math.round(clientHeight)) {
          this.fetchMore(this.state.page);

          // this.setState(
          //   (prevState) => {
          //     console.log(prevState.page,"prevstate")
          //     // const nextPage = prevState.page + 1;
          //     return { page: this.state.page+1 };
          //   },
          //   () => {
          //     console.log("page",this.state.page)
          //     this.fetchMore();
          //   }
          // );
        }
      }
    };
    fetchMore = (page, type) => {
      if (type) {
        this.setState({ loading: true });
      }
      this.setState(
        {
          data: {
            ...this.state.data,
            // loading: true
          },
        },
        () => {
          if (source1) {
            source1.cancel();
          }
          source1 = CancelToken1.source();
          instance({
            method: "GET",
            cancelToken: source1.token,
            url: `/api/itemgroups/get?itemType=${itemType}&page=${page || this.state.data.page}&limit=${this.state.limit}&search=${this.state.searchText}`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              const totalGroups = response?.total;
              const getGroupsData = response?.data;
              if (getGroupsData) {
                this.setState((prevState) => {
                  const combinedGroups = this.state.searchText
                    ? getGroupsData
                    : [...prevState.data.data, ...getGroupsData];
                  const groupsMap = new Map();

                  prevState.data.data.forEach((group) => {
                    groupsMap.set(group.id, group);
                  });
                  combinedGroups.forEach((group) => {
                    groupsMap.set(group.id, group);
                  });
                  const uniqueGroups = Array.from(groupsMap?.values());
                  const hasMore = uniqueGroups.length < totalGroups;

                  return {
                    data: {
                      ...response,
                      data: uniqueGroups,
                      loading: false,
                    },
                    page: response.page + 1,
                    hasMore: hasMore,
                  };
                });
              }
            })
            .catch(() => {
              // errorHandler(error, this.props.dispatch, this.errorCallBack)
              this.setState({
                data: {
                  ...this.state.data,
                  loading: false,
                },
              });
            });
        }
      );
    };

    fetchItems = (id, page) => {
      this.setState(
        {
          nested: {
            ...this.state.nested,
            loading: true,
          },
          loadingItem: id,
        },
        () => {
          if (source) {
            source.cancel();
          }
          source = CancelToken.source();

          instance({
            method: "GET",
            cancelToken: source.token,
            url: `/api/itemgroups/items?itemgroupId=${id}&page=${page || this.state.data.page}&limit=-1`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              this.setState({
                nested: {
                  ...response,
                  page: response.page + 1,
                  data: [...response.data],
                  loading: false,
                },
              });
              this.fetchMore(1, true);

              if (itemType === "Device" && response.data) {
                this.props.dispatch(
                  saveGroupWiseDevices(
                    response.data.length ? response.data.map((e) => e.id) : []
                  )
                );
                this.props.dispatch(fetchMoreDevices(true, null, true));
              }
            })
            .catch(() => {
              // errorHandler(error, this.props.dispatch)
              this.setState({
                nested: { ...this.state.nested, loading: false },
              });
            });
        }
      );
    };

    assignItem = (itemgroupid, itemid, callback) => {
      instance({
        url: `/api/permissions`,
        method: "POST",
        data: { itemgroupid, itemid },
      })
        .then((response) => {
          if (response) {
            this.fetchItems(itemgroupid, 1);
            this.fetchMore(1);
            this.props.dispatch(
                Notifications.success({
                  message:<Translate id={`${itemType&&itemType.charAt(0).toLowerCase() + itemType?.slice(1)}AssignedSuccessfully`} />,
                  autoDismiss: 10
                })
              )
          }
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          if (callback) {
            callback(error);
          }
        });
    };

    unassignItem = (itemgroupid, itemid, callback) => {
      instance({
        url: `/api/permissions`,
        method: "DELETE",
        data: { itemgroupid, itemid },
      })
        .then((response) => {
          if (response) {
            this.props.dispatch(
                Notifications.error({
                  message:<Translate id={`${itemType&&itemType.charAt(0).toLowerCase() + itemType?.slice(1)}UnAssignedSuccessfully`} />,
                  autoDismiss: 10
                })
              )
            this.fetchItems(itemgroupid, 1);
          }
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          if (callback) {
            callback(error);
          }
        });
    };
    // resetToDefault = () => {
    //     this.setState({
    //         searchText:"",
    //         data: {...initalState},
    //         page:1,
    //         hasMore:true
    //     },()=>{
    //         this.fetchMore(1)
    //     })
    // }

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return (
        <WrappedComponent
          itemType={itemType}
          resources={this.state.data}
          nextResources={this.onNext}
          changeResource={this.changeResource}
          deleteResource={this.deleteResource}
          searchResources={this.onSearch}
          assignItem={this.assignItem}
          unassignItem={this.unassignItem}
          searchNestedResources={this.onSearchNested}
          nestedResources={this.state.nested}
          fetchNestedItems={this.fetchItems}
          loadingItem={this.state.loadingItem}
          {...this.props}
          callOnMount={this.callOnMount}
          handleScrollGroups={this.handleScrollGroups}
          resetToDefault={this.resetToDefault}
        />
      );
    }
  };
}
