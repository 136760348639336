import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { withLocalize } from 'react-localize-redux';
import Moment from 'moment';
import TextField from '../../Components/common/TextField';
import Loader from '../../Layout/Loader';
import Table from '../../Components/common/tableWithBackEndPagination';
import Style from 'style-it';
import instance from '../../axios';
import Layout from '../../Layout';
import {
  actionTypes, itemTypes, AccountsItem, AttributesItem, CalenderItem,
  CommandItem, CouponItem, DashboardItem, DevicesItem, DriverItem,
  EcoDrivingItem, EventsItem, GeoFenceItem, ResourcesItem, ItemGroups,
  JobsItem, MaintenanceItem, NotificationItem, OrdersItem, PasswordsItem,
  POIItem, PositionItem, RolesItem, ReportTemplatesItem, SensorsItem,
  ServicesItem, SessionsItem, TrailersItem, UsersItem, VehiclesItem,
  LinkUnlinkItems, GarageItem, AreaItem, ExpenseTypeItem, ExpenseSubTypeItem
} from './ActionLogsTypes';
import { MenuList } from '@mui/material';
import MenuItem from '../../Components/common/MenuItem';

const Logs = ({ translate, themecolors, logInUser, location }) => {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [itemSearch, setItemSearch] = useState('all');
  const [subType, setSubType] = useState('');
  const [typeSelection, setTypeSelection] = useState('');

  useEffect(() => {
    getActionLogs();
  }, [page, pageSize, itemSearch, subType, typeSelection]);

  const getActionLogs = () => {
    let url = `/api/actionlogs?page=${page}&limit=${pageSize}`;

    if (itemSearch) {
      url = `/api/actionlogs?page=${page}&limit=${pageSize}${typeSelection}`;
    }
    if (subType) {
      url = `/api/actionlogs?page=${page}&limit=${pageSize}&type=${subType}`;
    }
    console.warn("url", url)
    instance({
      method: 'GET',
      url: url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.data) {
          let logs = response.data.map(u => {
            if (u.actionTime) {
              return {
                ...u,
                actionTime: logInUser.twelveHourFormat
                  ? Moment(u.actionTime).format('YYYY-MM-DD hh:mm A')
                  : Moment(u.actionTime).format('YYYY-MM-DD HH:mm'),
                action: actionTypes.find(action => action.key === u.action),
                actionDetails: `${u.itemLabel} ${u.itemType}`
              };
            } else {
              return { ...u };
            }
          });
          let data = { ...response, data: logs }
          setTableData(data);
          setLoader(false);
        }
      })
      .catch(() => {
        // errorHandler(error,dispatch)
      });
  };

  const handleChangeRowsPerPage = value => {
    setPageSize(value);
  };

  const handleChangePage = value => {
    setPage(value);
  };

  const setPagination = user => {
    if (tableData.data.length) {
      let nUser = { ...user };
      let lastPage = nUser.total / pageSize;
      let IsFloate = checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pageSize = pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  const checkFloteNumber = n => {
    return Number(n) === n && n % 1 !== 0;
  };

  const handleChangeFilter = event => {
    if (event) {
      setSubType('');
      setPageSize(20);
      setPage(1);
    }
    let typeSelection = '';
    const value = event.target.value;
    const itemMappings = {
      1: AccountsItem,
      21: AttributesItem,
      31: CalenderItem,
      41: CommandItem,
      51: CouponItem,
      61: DashboardItem,
      71: DevicesItem,
      91: DriverItem,
      101: EcoDrivingItem,
      111: EventsItem,
      121: GeoFenceItem,
      131: ResourcesItem,
      141: ItemGroups,
      151: JobsItem,
      161: MaintenanceItem,
      171: NotificationItem,
      181: OrdersItem,
      191: PasswordsItem,
      201: POIItem,
      211: PositionItem,
      231: RolesItem,
      241: ReportTemplatesItem,
      251: SensorsItem,
      261: ServicesItem,
      271: SessionsItem,
      281: TrailersItem,
      291: UsersItem,
      311: VehiclesItem,
      321: LinkUnlinkItems,
      501: GarageItem,
      526: ExpenseTypeItem,
      541: ExpenseSubTypeItem,
      551: AreaItem
    };
    const selectedItems = itemMappings[value] || [];
    selectedItems.forEach(i => {
      typeSelection += `&type=${i.key}`;
    });
    setItemSearch(value);
    setLoader(true);
    setTypeSelection(typeSelection);
  };

  const handleChangeSubType = event => {
    setSubType(event.target.value);
    setLoader(true);
    setTypeSelection('');
    setPageSize(20);
    setPage(1);
  };

  return (
    <Layout
      classFromChildren='has-padding'
      noSidebar
      translate={translate}
      themecolors={themecolors}
      logInUser={logInUser}
      location={location}
    // {...props}
    >
      <div
        style={{
          background: themecolors.backgroundColor,
          color: themecolors.textColor,
          borderRadius: 6,
          paddingTop: 16,
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        <Fragment>
          <Style>{`
            .breadcrumb-row {
                > .MuiGrid-item {
                  height: 50px;
                  align-items: center;
                  display: flex;
                  > div {
                    width: 100%;
                  }
                }
              }
          `}</Style>
          <Grid container spacing={1} className='breadcrumb-row' style={{ paddingRight: "10px" }}>
            <Grid item xs={12} sm={2}>
              <h3 style={{ margin: '13px 0px 0px 15px' }}>{translate('actionLogs')}</h3>
            </Grid>
            <Grid item container spacing={1} xs={12} sm={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item xs={12} sm={2}>
                <TextField
                  id='name'
                  select
                  name="name"
                  value={itemSearch}
                  onChange={handleChangeFilter}
                  margin='dense'
                  fullWidth
                >
                  {itemTypes.map(option => (
                    <MenuItem
                      key={option.key}
                      value={option.key}
                      style={{
                        color: itemSearch === option.key ? "white" : "",
                        backgroundColor: itemSearch === option.key ? "#0a83a1" : "",
                        padding: '5px 10px'
                      }}
                    >
                      {translate(option.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {itemSearch && itemSearch !== 'all' && (
                <Grid item xs={12} sm={2}>
                  <TextField
                    id='name'
                    select
                    name="name"
                    value={subType || ''}
                    onChange={handleChangeSubType}
                    margin='dense'
                    fullWidth
                  >
                    {(() => {
                      const subTypeMappings = {
                        1: AccountsItem,
                        21: AttributesItem,
                        31: CalenderItem,
                        41: CommandItem,
                        51: CouponItem,
                        61: DashboardItem,
                        71: DevicesItem,
                        91: DriverItem,
                        101: EcoDrivingItem,
                        111: EventsItem,
                        121: GeoFenceItem,
                        131: ResourcesItem,
                        141: ItemGroups,
                        151: JobsItem,
                        161: MaintenanceItem,
                        171: NotificationItem,
                        181: OrdersItem,
                        191: PasswordsItem,
                        201: POIItem,
                        211: PositionItem,
                        231: RolesItem,
                        241: ReportTemplatesItem,
                        251: SensorsItem,
                        261: ServicesItem,
                        271: SessionsItem,
                        281: TrailersItem,
                        291: UsersItem,
                        311: VehiclesItem,
                        321: LinkUnlinkItems,
                        501: GarageItem,
                        526: ExpenseTypeItem,
                        541: ExpenseSubTypeItem,
                        551: AreaItem
                      };
                      const selectedSubItems = subTypeMappings[itemSearch] || [];
                      return selectedSubItems.map(option => (
                        <MenuList key={option.key} style={{ padding: '5px 10px', cursor: "pointer" }} value={option.key}>
                          {translate(option.name)}
                        </MenuList>
                      ));
                    })()}
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ padding: "15px 10px" }}>
            <Grid item xs={12}>
              {/* {console.error("re====", tableData)} */}

              {loader ? (
                <Loader
                  width={20}
                  height={20}
                  type="Oval"
                  color="#00BFFF"
                />
              ) : (
                <>
                  <Table
                    rows={tableData.data || []}
                    pagination={setPagination(tableData)}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangePage={handleChangePage}
                    rowsPerPage={20}
                    // ServerSetting={ServerSetting}
                    logInUser={logInUser}
                    // themecolors={props.themecolors}
                    isRowIcons={false}
                    isCursoPointer
                    setSerialNo
                    // actionlogsLabel
                    themecolors={themecolors}
                    translate={translate}
                    // logInUser={logInUser}
                    location={location}
                    rowDefinition={[
                      {
                        id: 'actionDetails',
                        numeric: false,
                        disablePadding: false,
                        label: translate('actionDetails')
                      },
                      {
                        id: 'actionTime',
                        numeric: false,
                        disablePadding: false,
                        label: translate('actionTime')
                      },
                      // {
                      // id: 'host',
                      // numeric: false,
                      // disablePadding: false,
                      // label: translate('host')
                      // },
                      // {
                      // id: 'ipAddress',
                      // numeric: false,
                      // disablePadding: false,
                      // label: translate('ipAddress')
                      // },
                    ]}
                  />
                  {/* <Table
                  tableData={tableData}
                  count={setPagination({ total: tableData.length })}
                  pageSize={pageSize}
                  page={page}
                  themecolors={themecolors}
                  translate={translate}
                  logInUser={logInUser}
                  location={location}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                /> */}
                </>
              )}
            </Grid>
          </Grid>
        </Fragment>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themeColors
})

export default withLocalize(connect(mapStateToProps, null)(Logs));
