import React, { useEffect, useState } from "react";
import Button from "./../../../Components/common/Button";
import TextField from "./../../../Components/common/SearchField";
import { GroupModal } from "./../../../Components/Groups/groupModal";
import {
  DevicesShortList,
  ListRow,
} from "./../../../Components/Devices/shortList";
import { checkPrivileges, defaultDevicesLength } from "../../../Helpers";
import Style from "style-it";
import Loader from "./../../Loader";
// import InfiniteScroll from 'react-infinite-scroll-component'
import Scrollbar from "react-scrollbars-custom";
import { fetchMoreDevices } from "../../../Actions/Devices";
import ExportImportMenu from "../../../Components/Maps/ExportImportMenu";
import RecourcesList from "./RecourcesList";
// import { useSelector } from 'react-redux'
import instance from "../../../axios";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("./../../../images", false, /\.(png|jpe?g|svg)$/)
);

const UnitsSidebar = (props) => {
  // const [isPopupVisable,setIsPopupVisable]=useState(false)
  // const [isGmVisible, setIsGmVisable] = useState(false)
  // const [isDeviceLimit, setIsDeviceLimit] = useState(false)
  // const [countCall, setIsCountCall] = useState(true)
  // const [isChecked,setIsChecked]=useState(true)
  // const [sort,setSort]=useState("ASC")
  const [state, setState] = useState({
    // switchView: "units",
    // isPopupVisable: false,
    is_gm_Visable: false,
    isDeviceLimit: false,
    countCall: true,
  });

  const checkedDeviceLimit = () => {
    // console.error(setSearch)
    if (
      props.logInUser.deviceLimit &&
      (state.countCall || props.checkeDeviceLimit)
    ) {
      if (props?.logInUser.deviceLimit !== -1) {
        // setIsCountCall(false)
        setState((prevState) => ({
          ...prevState,
          countCall: false,
        }));
        props.checkedDeviceLimit();
        instance({
          url: `/api/devices/count`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((count) => {
            if (count === props.logInUser.deviceLimit) {
              // setIsDeviceLimit(true);
              setState((prevState) => ({
                ...prevState,
               isDeviceLimit : true,
              }));
            } else {
              // setIsDeviceLimit(false);
              setState((prevState) => ({
                ...prevState,
               isDeviceLimit : false,
              }));
            }
          })
          .catch(() => {
            // errorHandler(error, props.dispatch)
          });
      }
    }
  };

  useEffect(() => {
    checkedDeviceLimit();
  }, [props.logInUser.deviceLimit, state.countCall, props.checkeDeviceLimit]);

  const handleScroll = (values) => {
    const { hasNext, total, data } = props.devices;
    const { isFetching } = props;

    if (!isFetching && hasNext && data.length < total) {
      const { scrollTop, clientHeight, scrollHeight } = values;
      const MARGIN = 26;
      if (scrollTop + clientHeight >= scrollHeight - MARGIN) {
        const newPageSize = Math.min(data.length + defaultDevicesLength, total);
        props.dispatch(fetchMoreDevices(true, newPageSize));
      }
    }
  };

  const setIsGmVisable = () =>{
    setState((prevState) => ({
      ...prevState,
     is_gm_Visable : false,
    }));
  }

  return (
    <Style>
      {`
    .material-icons {vertical-align: middle; color: inherit}
  `}
      <div>
        {state.is_gm_Visable && (
          <GroupModal onCloseModal={setIsGmVisable} />
        )}
        <div className="section-head clearfix section-head-filter">
          <ul className="filter-row clearfix pull-right">
            <li>
              <TextField
                label={
                  props.view === "units"
                    ? props.translate("searchUnits")
                    : props.view === "groups"
                      ? props.translate("searchGroups")
                      : props.translate("searchResources")
                }
                type="search"
                placeholder="exp: ID, Name"
                value={
                  props.view === "resource"
                    ? props.resourceSearch
                    : props.search
                }
                onChange={props.searchItem}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </li>
            {props.resourceList && props.view !== "resource" ? (
              <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                {/*, paddingRight: 0 }}> */}
                {checkPrivileges("itemgroupCreate") && (
                  <Button
                    size="medium"
                    style={{ marginRight: 5 }}
                    disabled={state.isDeviceLimit}
                    fullWidth
                    onClick={() => {
                      props.addResource();
                    }}
                  >
                    {" "}
                    {props.translate("sharedCreate")}
                  </Button>
                )}
              </li>
            ) : (
              <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                {/*, paddingRight: 0 }}> */}
                {checkPrivileges("deviceCreate") &&
                  props.view !== "resource" && (
                    <Button
                      size="medium"
                      style={{ marginRight: 5 }}
                      disabled={state.isDeviceLimit}
                      fullWidth
                      onClick={() => {
                        props.addDevice();
                      }}
                    >
                      {" "}
                      {props.translate("sharedCreate")}
                    </Button>
                  )}
              </li>
            )}
            <li style={{ paddingTop: 8, paddingBottom: 4, paddingLeft: 0 }}>
              <ExportImportMenu
                fileName="devices"
                checkPrivilege="deviceCreate"
                data={props.devices.data}
                themecolors={props.themecolors}
              />
            </li>
          </ul>
        </div>
        {props.resourceList ? (
          <RecourcesList
            ItemChild={ListRow}
            ItemChildProps={props}
            fromArray={(props.devices && props.devices.data) || []}
            {...props}
          />
        ) : (
          <div className="sidebar-devices-list with-tabs">
            <Scrollbar
              disableTracksWidthCompensation={true}
              onScroll={
                props.view === "units"
                  ? handleScroll
                  : props.handleScrollResources
              }
              scrollerProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return (
                    <div
                      {...restProps}
                      ref={elementRef}
                      id="scrollableDivDevices"
                    />
                  );
                },
              }}
            >
              {props.devices.isFetching ? (
                <Loader defaultStyle={true} />
              ) : props.devices ? (
                <DevicesShortList
                  {...state}
                  removeDevice={props.removeDevice}
                  resetFrom={props.resetFrom}
                  devicesIcons={images}
                  devices={props.devices.data}
                  match={props.match}
                  isChecked={props.isChecked}
                  collapseSidebar={props.collapseSidebar}
                  translate={props.translate}
                  switchView={props.switchView}
                  itemPagination2={props.itemPagination2}
                />
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    color: "#ccc",
                    fontWeight: "bold",
                  }}
                >
                  {props.devices.total && props.switchView === "units"
                    ? ""
                    : props.groups && props.switchView === "resource"
                      ? ""
                      : props.translate("notFound")}
                </p>
              )}
            </Scrollbar>
          </div>
        )}
      </div>
    </Style>
  );
};

export default UnitsSidebar;
