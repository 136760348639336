import React from 'react'
import SimpleModalWrapped from './../common/Modal'
import Grid from '@mui/material/Grid'
import TextField from './../common/TextField'
import DateFnsUtils from '@date-io/date-fns'
import Tooltip from './../common/Tooltip'
import InputAdornment from '@mui/material/InputAdornment'
import CrossIcons from '@mui/icons-material/Remove'
import IconButton from '@mui/material/IconButton'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
// import { makeStyles } from '@mui/styles'
import Attributes from './../Attributes/attributesModal'
import { useSelector } from 'react-redux'
import moment from 'moment-hijri'
import Button from '../common/Button'
// import { Api } from '../../Helpers'
import axios from 'axios'
import { useState } from 'react'


const AddDrivers = (props) => {
  const [attm_isVisable, setIsAttmVisable] = useState(false)
  const [errorResponse, setErrorResponse] = useState("")
  const [inquiryResponse, setInquiryResponse] = useState("")


  const logInUsers = useSelector((state) => state.logInUsers)


  const addAttributes = () => {
    setIsAttmVisable((prev) => !prev)
  }


  const updateWASL = () => {
    setErrorResponse("")
    setInquiryResponse("")
    const { waslCompanyRefKey } = props?.serversettings?.attributes || {};
    const { WASLKey, phone, identityNumber } = props.form.attributes;

    if (waslCompanyRefKey && WASLKey) {
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
        hook: 'updateDriver',
        account_id: props.serversettings.id,
        hook_prefix: waslCompanyRefKey,
        data: JSON.stringify({
          mobileNumber: phone,
          identityNumber,
          activity: 'DEFAULT'
        })
      }).then(response => {// eslint-disable-line no-unused-vars
        props.formSubmit();
      }).catch(error => {
        // console.log(error);
        if (error.response && error.response.data) {
          const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
          setErrorResponse(resultMsg || resultCode || errorMsg || errorCode)
        }
      })
    }
  }

  const unlinkWasl = () => {
    setErrorResponse("")
    setInquiryResponse("")
    const { waslCompanyRefKey } = props?.serversettings?.attributes || {};
    const { WASLKey } = props.form.attributes;

    if (waslCompanyRefKey && WASLKey) {
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
        hook: 'deleteDriver',
        account_id: props.serversettings.id,
        hook_prefix: waslCompanyRefKey,
        hook_postfix: WASLKey,
        data: {
          activity: 'DEFAULT'
        }
      }).then(response => {// eslint-disable-line no-unused-vars
        props.deleteWASLKey();
      }).catch(error => {
        // console.log(error);
        if (error.response && error.response.data) {
          const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
          setErrorResponse(resultMsg || resultCode || errorMsg || errorCode)
          // let errMsg = (error.response&&error.response.data&&error.response.data.data&&error.response.data.data.resultCode) || 'somethingWentWrong'
        }
      })
    }
  }

  const submitWasl = () => {
    setErrorResponse("")
    setInquiryResponse("")
    const { waslCompanyRefKey } = props?.serversettings?.attributes || {};
    if (waslCompanyRefKey) {
      const { identityNumber, phone, dateOfBirthGregorian } = props.form?.attributes || {};
      if (identityNumber && phone && dateOfBirthGregorian) {
        const formData = {
          identityNumber: 2510626860,
          mobileNumber: '+966591131620',
          dateOfBirthGregorian: '1987-09-26',
          activity: 'DEFAULT'
        }
        axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
          hook: props.form.id ? 'updateDriver' : 'drivers',
          account_id: props.serversettings.id,
          data: JSON.stringify(formData),
          hook_prefix: waslCompanyRefKey
        }).then(response => {
          if (response.status === 200 && response.data && response.data.success) {
            if (response.data.success) {
              if (response.data.result && response.data.result.referenceKey) {
                props.handleChange('WASLKey')({ target: { value: response.data.result.referenceKey } });
                props.formSubmit(props.form.id);
              }
            }
          }
        }).catch(error => {
          // console.log(error);
          if (error.response && error.response.data) {
            // console.log(error.response.data);
            const { data: { resultCode, result, resultMsg, errorMsg, errorCode } } = error.response.data;
            if (result && result.isValid && result.referenceKey) {
              props.handleChange('WASLKey')({ target: { value: result.referenceKey } });
              props.formSubmit(props.form.id);
            }
            setErrorResponse(resultMsg || resultCode || errorMsg || errorCode)
          }
        })
      }
      else {
        setErrorResponse('Incomplete Data')
      }
    }
  }

  const { selectedDrivers, classes, form: { attributes: { WASLKey } } } = props
  const { waslEnabled } = props?.serversettings?.attributes || {};

  return (
    <div style={{ height: '100%' }}>
      <SimpleModalWrapped
        visable
        isButtonVisable
        title='sharedDriver'
        formSubmit={props.formSubmit}
        addAttributes={addAttributes}
        modalControle={props.onClose}
        buttonText={props.buttonText}
        btnCloseText='sharedBack'
        isDrivers
        isImageUpload={props.isVisibleBtnImage}
            isVisableBtn={props.isVisableUserBtn}
        selectedItemId={selectedDrivers.uniqueId}
        attributeChangesMessage={props.attributeChangesMessage}
        waslFooter={
          waslEnabled ? (WASLKey ? <><Button onClick={updateWASL}>Update WASL</Button><Button style={{ marginLeft: 10 }} onClick={unlinkWasl} >Un-Link WASL</Button></> : <Button onClick={submitWasl}>Link WASL</Button>) : null
        }
        isLoading={props.isLoading}
        content={
          <form autoComplete='off'>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='name'
                  label={props.translate('sharedName')}
                  required
                  placeholder=''
                  value={props.form.name || ''}
                  onChange={props.handleChange('name')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='uniqueId'
                  label={props.translate('uniqueId')}
                  type='text'
                  required
                  placeholder=''
                  value={props.form.uniqueId || ''}
                  onChange={props.handleChange('uniqueId')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='department'
                  label={props.translate('department')}
                  type='text'
                  placeholder=''
                  value={props.form.department}
                  onChange={props.handleChange('department')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    error={
                      props.form.licenseExpDate &&
                      props.form.licenseExpDate <
                      moment().startOf('day').toISOString()
                    }
                    helperText={
                      props.form.licenseExpDate &&
                        props.form.licenseExpDate <
                        moment().startOf('day').toISOString()
                        ? props.translate('licenseExpired')
                        : ''
                    }
                    margin='dense'
                    label={props.translate('D/LExpirationTime')}
                    variant='outlined'
                    fullWidth
                    minDate={new Date()}
                    value={
                      props.form.licenseExpDate
                        ? new Date(
                          `${props.form.licenseExpDate}`
                        ).toString()
                        : null
                    }
                    onChange={props.handleChange('licenseExpDate')}
                    classes={classes}
                    InputProps={{
                      classes: {
                        root: 'theme-cssOutlinedInput',
                        input: 'theme-input',
                        focused: 'theme-cssFocused',
                        notchedOutline: 'theme-notchedOutline'
                      },
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Tooltip title={props.translate('noLimit')}>
                            <IconButton
                              onClick={e => props.setExpirationTime(e, 'licenseExpDate')}
                              style={{ color: 'inherit' }}
                            >
                              {<CrossIcons />}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      classes: {
                        root: 'theme-label',
                        focused: 'theme-label-focused',
                        shrink: 'theme-label-shrink'
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    error={
                      props.form.expirationTime &&
                      props.form.expirationTime <
                      moment().startOf('day').toISOString()
                    }
                    helperText={
                      props.form.expirationTime &&
                        props.form.expirationTime <
                        moment().startOf('day').toISOString()
                        ? props.translate('licenseExpired')
                        : ''
                    }
                    margin='dense'
                    label={props.translate('expirationTime')}
                    variant='outlined'
                    fullWidth
                    minDate={new Date()}
                    value={
                      props.form.expirationTime
                        ? new Date(
                          `${props.form.expirationTime}`
                        ).toString()
                        : null
                    }
                    onChange={props.handleChange('expirationTime')}
                    classes={classes}
                    InputProps={{
                      classes: {
                        root: 'theme-cssOutlinedInput',
                        input: 'theme-input',
                        focused: 'theme-cssFocused',
                        notchedOutline: 'theme-notchedOutline'
                      },
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Tooltip title={props.translate('noLimit')}>
                            <IconButton
                              onClick={e => props.setExpirationTime(e, 'expirationTime')}
                              style={{ color: 'inherit' }}
                            >
                              {<CrossIcons />}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      classes: {
                        root: 'theme-label',
                        focused: 'theme-label-focused',
                        shrink: 'theme-label-shrink'
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    margin='dense'
                    label={props.translate('dateOfBirthGregorian')}
                    variant='outlined'
                    fullWidth
                    maxDate={new Date()}
                    value={
                      props.form.dateOfBirthGregorian
                        ? new Date(
                          `${props.form.dateOfBirthGregorian}`
                        ).toString()
                        : null
                    }
                    onChange={props.handleChange('dateOfBirthGregorian')}
                    classes={classes}
                    InputProps={{
                      classes: {
                        root: 'theme-cssOutlinedInput',
                        input: 'theme-input',
                        focused: 'theme-cssFocused',
                        notchedOutline: 'theme-notchedOutline'
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: 'theme-label',
                        focused: 'theme-label-focused',
                        shrink: 'theme-label-shrink'
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='identityNumber'
                  label={props.translate('identityNumber')}
                  type='text'
                  placeholder=''
                  value={props.form.identityNumber}
                  onChange={props.handleChange('identityNumber')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='phone'
                  label={props.translate('sharedPhone')}
                  type='text'
                  placeholder=''
                  value={props.form.phone}
                  onChange={props.handleChange('phone')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='email'
                  label={props.translate('userEmail')}
                  type='text'
                  placeholder=''
                  value={props.form.email}
                  onChange={props.handleChange('email')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='licenseNumb'
                  label={props.translate('driverLicenseNumber')}
                  type='text'
                  placeholder=''
                  value={props.form.licenseNumb}
                  onChange={props.handleChange('licenseNumb')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='driverAddress'
                  multiline
                  rows={3}
                  label={props.translate('address')}
                  type='text'
                  placeholder=''
                  value={props.form.driverAddress}
                  onChange={props.handleChange('driverAddress')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <p style={{ marginTop: 0, marginBottom: 4 }}><TextField
                  id='licenseClass'
                  label={props.translate('licenseClass')}
                  type='text'
                  placeholder=''
                  value={props.form.licenseClass}
                  onChange={props.handleChange('licenseClass')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
                </p>
                {props.editOption ?
                  <>
                    <input
                      type='file'
                      name='pic'
                      accept='image/*'
                      onChange={e => props.uploadImage(e)}
                    />
                    {props.form.driverImg && (
                      <Tooltip
                        placement='right-end'
                        title={props.translate('clickForPreview')}
                      >
                        <img
                          style={{ width: 120, height: 'auto' }}
                          alt='driverImage'
                          src={props.uploadingImage ? props.imagesData : props.form.driverImg || ''}
                          onClick={props.imagePopup}
                        />
                      </Tooltip>
                    )}
                  </> : null}
              </Grid>

              <h3
                style={{ margin: 5, marginTop: 15 }}
                className='with-border'
              >
                {props.translate('tags')}
              </h3>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='tag_1'
                    label={
                      logInUsers.attributes &&
                        logInUsers.attributes.st1
                        ? logInUsers.attributes.st1
                        : props.translate('tag_1')
                    }
                    type='text'
                    placeholder=''
                    value={
                      (props.form.tag_1 !== undefined
                        ? props.form.tag_1
                        : props.form.attributes.tag_1) || ''
                    }
                    onChange={props.handleChange('tag_1')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='tag_2'
                    label={
                      logInUsers.attributes &&
                        logInUsers.attributes.st2
                        ? logInUsers.attributes.st2
                        : props.translate('tag_2')
                    }
                    type='text'
                    placeholder=''
                    value={
                      (props.form.tag_2 !== undefined
                        ? props.form.tag_2
                        : props.form.attributes.tag_2) || ''
                    }
                    onChange={props.handleChange('tag_2')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='tag_3'
                    label={
                      logInUsers.attributes &&
                        logInUsers.attributes.st3
                        ? logInUsers.attributes.st3
                        : props.translate('tag_3')
                    }
                    type='text'
                    placeholder=''
                    value={
                      (props.form.tag_3 !== undefined
                        ? props.form.tag_3
                        : props.form.attributes.tag_3) || ''
                    }
                    onChange={props.handleChange('tag_3')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='tag_4'
                    label={
                      logInUsers.attributes &&
                        logInUsers.attributes.st4
                        ? logInUsers.attributes.st4
                        : props.translate('tag_4')
                    }
                    type='text'
                    placeholder=''
                    value={
                      (props.form.tag_4 !== undefined
                        ? props.form.tag_4
                        : props.form.attributes.tag_4) || ''
                    }
                    onChange={props.handleChange('tag_4')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id='tag_5'
                    label={
                      logInUsers.attributes &&
                        logInUsers.attributes.st5
                        ? logInUsers.attributes.st5
                        : props.translate('tag_5')
                    }
                    type='text'
                    placeholder=''
                    value={
                      (props.form.tag_5 !== undefined
                        ? props.form.tag_5
                        : props.form.attributes.tag_5) || ''
                    }
                    onChange={props.handleChange('tag_5')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            {errorResponse ? <p>WASL ERROR: {errorResponse}</p> : null}
            {inquiryResponse ? <p>WASL ERROR: {inquiryResponse}</p> : null}

            {props.waslStatus ? <ul className="process-list">
              <li>{props.waslStatus ? 'WASL Status: ' : null} {props.waslStatus}</li>
              <li>{props.driverStatus ? 'Driver Status: ' : null} {props.driverStatus}</li>
            </ul> : null}
          </form>
        }
      />
      {attm_isVisable && (
        <Attributes
          onCloseModal={addAttributes}
          itemAttributes={props.itemAttributes}
          changeAttribute={props.changeAttribute}
          componentType={'driver'}
          isHaveAccess={props.isHaveAccess}
        />
      )}
    </div>
  )
}
export default AddDrivers