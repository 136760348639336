import React, { Component } from 'react'
// import { makeStyles } from '@mui/styles'
import Radio from '@mui/material/Radio'
import { connect } from 'react-redux'

// let themecolors
const mapStateToProps = (state) => {
  // themecolors = state.themeColors
  return {
    themecolors: state.themeColors
  }
}
// const styles2222 = theme => ({
//   root: {
//     color: themecolors.textColor,
//     '&$checked': {
//       color: themecolors.textColor
//     }
//   },
//   checked: {}
// })

class RadioLabels extends Component {
  render () {
    const { classes, ...others } = this.props
    return (
      <Radio
        {...others}
        classes={{
          root: classes&&classes.root,
          checked: classes&&classes.checked
        }}
      />
    )
  }
}

export default connect(mapStateToProps)(RadioLabels)
