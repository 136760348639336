import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { userAttributesTypes } from './../Attributes/AttributesArrays'
import PinIcon from './../../assets/nav/pin.svg'
import EmailIcon from './../../assets/nav/email.svg'
import CallIcon from './../../assets/nav/phone-call.svg'
// import { withLocalize } from 'react-localize-redux'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
// import Icon from '@mui/material/Icon'
import { setAttributeFormat } from '../../Helpers'
import TimeAgo from 'react-timeago'
import HtmlParser from 'react-html-parser'
import { ReactComponent as FileCopyIcon } from './../../assets/monitoring/copy.svg'
import Notifications from 'react-notification-system-redux'
import Tooltip from '../common/Tooltip'

export const EditItem=(props)=>{
const [selectedItem,setSelectedItem]=useState([])
const themecolors=useSelector((state)=>state.themeColors)
const logInUser=useSelector((state)=>state.logInUsers)
const dispatch=useDispatch()

useEffect(() => {
  setSelectedItem(formatObject(props));
}, [props, formatObject]);

const formatObject=(props)=>{
  if (props.selectedItem) {
    let normalValues = []
    let objValues = []
    Object.keys(props.selectedItem).map((itm) => {
      if (
        typeof props.selectedItem[itm] !== 'object' ||
        props.selectedItem[itm] === null
      ) {
        normalValues.push({ key: itm, value: props.selectedItem[itm] })
      } else {
        objValues.push({ key: itm, value: props.selectedItem[itm] })
      }
      return null
    })

    /*let newAtt = objValues[0].value
    Object.keys(newAtt).map((key, index) => {
      if (key === 'address' || key === 'email' || key === 'licenseClass' ||
        key === 'licenseNumb' || key === 'licenseExpDate' || key === 'phone' || key === 'department') {
        objLikeNormalValue.push({ key: key, value: newAtt[key] })
      }
    });*/

    //normalValues = normalValues.concat(objLikeNormalValue)
    return normalValues.concat(objValues)
  } else {
    return []
  }
}

// const setIcons = (data)=> {
//   let logo
//   if (data.category) {
//     props.devicesIcons.map(item => {
//       let res = item.includes(data.category)
//       if (res) {
//         logo = item
//       }
//       return null
//     })
//   } else {
//     props.devicesIcons.map(item => {
//       let res = item.includes('default')
//       if (res) {
//         logo = item
//       }
//       return null
//     })
//   }
//   return logo
// }
const drawValue =(array)=>{
  let cat = []
  let htmlObject = []
  let html = null
  let mainKey = ''

  if (array.length) {
    html = array.map((item, key) => {
      switch (item.key) {
        case 'name':
          mainKey = props.translate('sharedName')
          break
        case 'login':
          mainKey = props.translate('loginTitle')
          break
        case 'email':
          mainKey = props.translate('userEmail')
          break
        case 'phone':
          mainKey = props.translate('sharedPhone')
          break
        case 'readonly':
          mainKey = props.translate('serverReadonly')
          break
        case 'administrator':
          mainKey = props.translate('userAdmin')
          break
        case 'map':
          mainKey = props.translate('mapTitle')
          break
        case 'twelveHourFormat':
          mainKey = props.translate('settingsTwelveHourFormat')
          break
        case 'coordinateFormat':
          mainKey = props.translate('settingsCoordinateFormat')
          break
        case 'disabled':
          mainKey = props.translate('sharedDisabled')
          break
        case 'expirationTime':
          mainKey = props.translate('userExpirationTime')
          break
        case 'deviceReadonly':
          mainKey = props.translate('userDeviceReadonly')
          break
        case 'token':
          mainKey = props.translate('userToken')
          break
        case 'limitCommands':
          mainKey = props.translate('userLimitCommands')
          break
        case 'poiLayer':
          mainKey = props.translate('mapPoiLayer')
          break
        case 'password':
          mainKey = props.translate('attributeDevicePassword')
          break
        case 'attributes':
          mainKey = props.translate('sharedAttributes')
          break
        case 'trackers':
          mainKey = props.translate('deviceTitle')
          break
        default:
          mainKey = props.translate(item.key)
          break
      }

      if (item.key !== 'name') {
        if (typeof item.value === 'string' || item.value === null) {
          return (
            <Grid item xs={12} key={key}>
              <span className='row-label'>{mainKey}</span>
              <span className='row-value'>{item.value || 'null'}</span>
            </Grid>
          )
        }
        if (typeof item.value === 'boolean') {
          return (
            <Grid item xs={12} key={key}>
              <span className='row-label'>{mainKey}</span>
              <span className='row-value'>{item.value ? 'Yes' : 'No'}</span>
            </Grid>
          )
        } else if (typeof item.value === 'object') {
          if (item.key === 'trackers') {
            /* let h = null
            if (item.value.length) {
              h = item.value.map(k => {
                return (
                  <li key={k.id}>
                    {
                      <img
                        className='row-image'
                        src={
                          '/assets/category/default/' +
                          (k.category || 'default') +
                          '.svg'
                        }
                        alt={k.label}
                      />
                    }

                    <span className='row-label'>{k.label}</span>
                    <span className='row-value'>{k.uniqueId}</span>
                  </li>
                )
              })

              let head = (
                <div key={item.key}>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      margin: '0 0 15px'
                    }}
                    className='with-border'
                  >
                    {props.translate('deviceTitle')}
                  </p>
                  <ul className='trackers-list clearfix'>{h}</ul>
                </div>
              )
              cat.push(head)
              return null
            } */
          } else {
            let o = Object.keys(item.value)
            let h = null
            if (o.length) {
              let hasData = false
              h = o.map(k => {
                if (['driverAddress', 'driverLat', 'driverLon'].includes(k)) {
                  hasData = true
                  return (
                    <li key={k}>
                      <span className='row-label'>
                        {props.translate(k)}
                      </span>
                      <span className='row-value'>{HtmlParser(item.value[k])}</span>
                    </li>
                  )
                } else if (
                  k !== 'address' &&
                  k !== 'email' &&
                  k !== 'licenseClass' &&
                  k !== 'department' &&
                  k !== 'licenseNumb' &&
                  k !== 'licenseExpDate' &&
                  k !== 'phone' &&
                  k !== 'driverImg'
                ) {
                  hasData = true
                  const selectedTrans = userAttributesTypes.filter(
                    el => el.key === k
                  )
                  if (typeof item.value[k] === 'boolean') {
                    return (
                      <li key={k}>
                        <span className='row-label'>
                          {selectedTrans && selectedTrans[0]
                            ? props.translate(selectedTrans[0].name)
                            : k}
                        </span>
                        <span className='row-value'>
                          {item.value[k] ? 'Yes' : 'No'}
                        </span>
                      </li>
                    )
                  } else {
                    let kk = ''
                    if (
                      k === 'tag_1' &&
                      logInUser &&
                      logInUser.attributes &&
                      logInUser.attributes.st1
                    ) {
                      kk = logInUser.attributes.st1
                    }
                    if (
                      k === 'tag_2' &&
                      logInUser &&
                      logInUser.attributes &&
                      logInUser.attributes.st2
                    ) {
                      kk = logInUser.attributes.st2
                    }
                    if (
                      k === 'tag_3' &&
                      logInUser &&
                      logInUser.attributes &&
                      logInUser.attributes.st3
                    ) {
                      kk = logInUser.attributes.st3
                    }
                    if (
                      k === 'tag_4' &&
                      logInUser &&
                      logInUser.attributes &&
                      logInUser.attributes.st4
                    ) {
                      kk = logInUser.attributes.st4
                    }
                    if (
                      k === 'tag_5' &&
                      logInUser &&
                      logInUser.attributes &&
                      logInUser.attributes.st5
                    ) {
                      kk = logInUser.attributes.st5
                    }
                    return (
                      <li key={k}>
                        <span className='row-label'>
                          {kk
                            ? kk
                            : selectedTrans && selectedTrans[0]
                            ? props.translate(selectedTrans[0].name)
                            : props.translate(k)}
                        </span>
                        <span className='row-value'>
                          {k==='dateOfBirthGregorian'?
                            (item&&item.value&&
                              moment(item.value[k]).format(
                                'DD-MM-YYYY'
                              )):
                            item.value[k]
                          }
                        </span>
                      </li>
                    )
                  }
                }
                return null
              })
              let head = hasData ? (
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      margin: '0 0 15px'
                    }}
                    className='with-border'
                  >
                    {mainKey}
                  </p>
                  <ul className='attribute-list clearfix'>{h}</ul>
                </div>
              ) : null
              htmlObject.push(head)
              return null
            }
            return null
          }
        } else {
          return null
        }
      } else {
        return null
      }

      return null
    })
  }

  return { html, htmlObject, cat }
}



const copyToClipboard =(obj)=> {
  const el = document.createElement('textarea')
  el.value = `${obj.latitude},${obj.longitude}`
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  console.warn("hello")
  dispatch(
    Notifications.success({
      message: 'Copied to clipboard!',
      autoDismiss: 10
    })
  )
}

const { classes } = props

const newSelected = {}
selectedItem.map(e => (newSelected[e.key] = e.value))

let device;
if (props.currentLocation && props.currentLocation.driver) {
 device =  props.allDriverLocation && props.allDriverLocation[props.currentLocation.driver.id] || null;
 }
 return (
  <>
  <style>
    {`
    .feature-close-button {
      position: absolute;
      margin-top: 6px;
      color: inherit;
      right: 15px;
      top: 3px;
      cursor: pointer;
    }
  .material-icons {vertical-align: middle; color: inherit}
  
  .license-card-img {
    background: ${themecolors.textColor};
  }

  .attribute-list .row-label {
    color: ${themecolors.textColor};
  }
  .attribute-list .row-value {
    color: ${themecolors.textColor};
  }
  
  .trackers-list li {
    background: ${themecolors.backgroundColor};
  }
 
  .license-card-detail {
    border-bottom: 1px solid ${themecolors&&themecolors.themeLightColor} 
  }
  `}
  </style>
    <div className={classes&&classes.root +  ' driver-modal-fixed'} style={{
          background: themecolors.backgroundColor,
          color: themecolors.textColor,
          bottom:"57px", right:"60px", top:"53px",width:"306px",borderRadius:"4px", 
          boxShadow:"0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
          position:"fixed",
          // minHeight:"80%",
          // height:"400px", 
        }}>
      <Paper
        style={{
          height: '100%',
          background: themecolors.backgroundColor,
          color: themecolors.textColor,
        }}
      >
      <Scrollbar disableTracksWidthCompensation contentProps={{style: {padding: 16}}}>
            {
              <div
                style={{
                  margin: '5px',
                  marginBottom: '15px',
                  display: 'flex',
                  width: '100%'
                }}
              >
                {newSelected?.attributes?.driverImg ? (
                  <img
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      verticalAlign: 'middle'
                    }}
                    alt='driverImage'
                    src={newSelected?.attributes?.driverImg}
                  />
                ) : (
                  <AccountCircleIcon style={{fontSize:40}}/>
                )}
                <div style={{ paddingLeft: 15, flex: 1, minWidth: 0 }}>
                  <h3 style={{ margin: '0 0 5px' }}>{newSelected.name} </h3>
                  <Tooltip 
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                      title={newSelected.uniqueId}
                    >
                    <span style={{display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                      {props.translate(
                        'sensorsTranslation.driverUniqueId'
                      )}{' : '}
                      {newSelected.uniqueId}
                    </span>
                  </Tooltip>
                  <CloseIcon
                    className={'feature-close-button'}
                    onClick={props.handleClose}
                  />
                </div>
              </div>
            }
            {newSelected.address ||
            newSelected.email ||
            newSelected.phone ? (
                  <div
                    style={{
                      paddingBottom: 4,
                      borderBottom:
                        '1px solid ' + themecolors&&themecolors.themeLightColor,
                      marginBottom: 30
                    }}
                  >
                    {newSelected.address && (
                      <div style={{ display: 'flex', paddingBottom: 16 }}>
                        <strong>
                          <svg fill='currentColor' width={14} height={14}>
                            <use xlinkHref={`${PinIcon}#icon`} />
                          </svg>
                        </strong>{' '}
                        &nbsp;
                        <span style={{ marginLeft: 10 }}>
                          {HtmlParser(newSelected.address)}
                        </span>
                      </div>
                    )}
                    {newSelected.email && (
                      <div style={{ display: 'flex', paddingBottom: 16 }}>
                        <strong>
                          <svg fill='currentColor' width={14} height={14}>
                            <use xlinkHref={`${EmailIcon}#icon`} />
                          </svg>
                        </strong>{' '}
                        &nbsp;
                        <span style={{ marginLeft: 10 }}>
                          {newSelected.email}
                        </span>
                      </div>
                    )}
                    {newSelected.phone && (
                      <div style={{ display: 'flex', paddingBottom: 16 }}>
                        <strong>
                          <svg fill='currentColor' width={14} height={14}>
                            <use xlinkHref={`${CallIcon}#icon`} />
                          </svg>
                        </strong>{' '}
                        &nbsp;
                        <span style={{ marginLeft: 10 }}>
                          {newSelected.phone}
                        </span>
                      </div>
                    )}
                  </div>
            ) : null}
            {selectedItem &&
              drawValue(selectedItem, classes).htmlObject}
            
            <p
              style={{
                fontSize: 14,
                fontWeight: 700,
                margin: '0 0 15px'
              }}
              className='with-border'
            >
              {props.translate('licenseInfo')}
            </p>
              
            <div className='license-card-detail'>
              <p>
                <span className='row-label'>
                  {props.translate('licenseClass')}
                </span>
                <span className='row-value'>
                {console.warn('License',newSelected)}
                  {newSelected?.attributes?.licenseClass}
                </span>
              </p>
              <p>
                  <span className='row-label'>
                    {props.translate('driverLicenseNumber')}
                  </span>
                  <span className='row-value'>
                    {newSelected?.attributes?.licenseNumb}
                  </span>
              </p>
              <p>
                  <span className='row-label'>
                    {props.translate('department')}
                  </span>
                  <span className='row-value'>
                    {newSelected?.attributes?.department}
                  </span>
              </p>
              <p>
                  <span className='row-label'>
                    {props.translate('D/LExpirationTime')}
                  </span>
                  <span className='row-value'>
                    {(
                      moment(newSelected?.attributes?.licenseExpDate).format(
                        'DD-MM-YYYY'
                      )) ||
                      null}
                  </span>
                </p>
                <p>
                  <span className='row-label'>
                    {props.translate('expirationTime')}
                  </span>
                  <span className='row-value'>
                    {(
                      moment(newSelected?.attributes?.expirationTime).format(
                        'DD-MM-YYYY'
                      )) ||
                      null}
                  </span>
                </p>
            </div>
            {selectedItem &&
              drawValue(selectedItem, classes).cat}

            {props.currentLocation && <>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  margin: '0 0 15px'
                }}
                className='with-border'
              >
                {props.translate('currentLocation')}
              </p>
              <p>
                  <span className='row-label'>
                    {props.translate('settingsCoordinates')}
                  </span>
                  <span className='row-value'>
                    {setAttributeFormat('latitude', props.currentLocation.latitude)},
                    {setAttributeFormat('longitude', props.currentLocation.longitude)}
                    {
                      <button
                        onClick={() => copyToClipboard(props.currentLocation)}
                        className='button-to-normal'
                      >
                        <FileCopyIcon
                          fill='currentColor'
                          width='14'
                          height='14'
                        />
                      </button>
                    }
                  </span>
              </p>
              <p>
                  <span className='row-label'>
                    {props.translate('driverAddress')}
                  </span>
                  <span className='row-value'>
                    {HtmlParser(props.currentLocation.address)}
                  </span>
              </p>
              <p>
                  <span className='row-label'>
                    {props.translate('gpsUpdated')}
                  </span>
                  <span className='row-value'>
                    <TimeAgo date={props.currentLocation.serverTime} minPeriod={60} />
                  </span>
              </p>
              </> }
          
              {device &&  <>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  margin: '30px 0 15px'
                  // marginBottom: 30
                }}
                className='with-border'
              >
                {props.translate('unitInfo')}
              </p>
              <p>
                  <span className='row-label'>
                    {props.translate('sharedName')}
                  </span>
                  <span className='row-value'>
                    {device.name}
                  </span>
              </p>
              <p>
                  <span className='row-label'>
                    {props.translate('deviceModel')}
                  </span>
                  <span className='row-value'>
                    {device.model}
                  </span>
              </p>
              </> }
      </Scrollbar>
      </Paper>
    </div>
  </>
)

}

EditItem.propTypes = {
  classes: PropTypes.object.isRequired
}

