import React, { useState, useEffect } from 'react';
import TextField from './../../Components/common/TextFieldLg';
import Button from './../../Components/common/Button';
import Loader from './../../Layout/Loader';
import { logInUserInfo, getRoles } from './../../Actions/Users';
import { ServerInfo } from './../../Actions/serverSetting';
import { getLayer } from './../../Actions/Maps';
import { withLocalize } from 'react-localize-redux';
import { fetchDevicesList, fetchDevices } from './../../Actions/Devices';
import {
  displayErrorMessages,
  checkedDevelopmentType,
  errorHandler,
  checkPrivileges,
} from '../../Helpers/index';
import Checkbox from '../../Components/common/Checkbox';
import SocketController from '../../SocketController';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import InfoIcon from './../../assets/monitoring/info.svg';
import { themes } from '../../Reducers/Theme';
import instance from '../../axios';
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip } from '@material-ui/core';



// const mapState = (state) => {
//   return {
//     // themecolors: themes[2],
//     // devices: state.devices.data,
//     // ServerSetting: state.ServerSetting,
//     // logInUser: state.logInUsers,
//   };
// };
const generateCaptcha = () => {
  let allCharacters = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
    'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd',
    'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
    't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  ];

  return Array.from({ length: 6 }, () => {
    return allCharacters[Math.floor(Math.random() * allCharacters.length)];
  }).join('');
};
const LoginComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState('');
  const [isVisableUserBtn, setIsVisableUserBtn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const themecolors = themes[2]
  const { logInUsers } = useSelector((state) => state)
  const dispatch = useDispatch()


  useEffect(() => {
    if (props?.location?.state) {
      setLoginErrorMessage(props?.location.state);
    }
    setEmail(getCookie('userName'));
    setPassword(getCookie('pass'));
  }, [props?.location]);



  const handleRegenerateCaptcha = () => {
    setCaptcha(generateCaptcha());
    setUserInput('');
    setIsVisableUserBtn(false);
  };

  const handleCaptchaChange = (event) => {
    setUserInput(event.target.value);
    checkRequiredFields();
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === 'email') setEmail(value);
    if (id === 'password') setPassword(value);
    // checkRequiredFields();
  };

  const checkRequiredFields = () => {
    if (email) {
      if (process.env.REACT_APP_CAPTCHA_ENABLE === true) {
        if (userInput && captcha === userInput) {
          setIsVisableUserBtn(true);
        } else {
          setIsVisableUserBtn(false);
        }
      } else {
        setIsVisableUserBtn(true);
      }
    } else {
      setIsVisableUserBtn(false);
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    setLoginErrorMessage('');

    let fetchUrl = checkedDevelopmentType();
    instance({
      url: `/api/session`,
      method: 'POST',
      data: new URLSearchParams({
        email: email,
        password: password,
        app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
        host: fetchUrl,
      }),
    })
      .then((userInfoFront) => {
        let lang = userInfoFront.attributes.lang;
        props?.setActiveLanguage(lang || 'en');
        instance({
          url: `/api/privileges`,
          method: 'GET',
          params: {
            roleId: userInfoFront.roleId,
          },
        })
          .then((privileges) => {
            let privilegeKeys = [];
            privileges.map((item) => {
              privilegeKeys.push(item.key);
              return null;
            });
            localStorage.setItem('frontPrivileges', JSON.stringify({ ...privilegeKeys }));
            if (privileges.length) {
              dispatch(logInUserInfo(userInfoFront));
              if (checkPrivileges('device')) {
                dispatch(fetchDevicesList(userInfoFront));
                dispatch(fetchDevices(userInfoFront));
              }

              if (userInfoFront.userType === -1) {
                instance({
                  url: `/api/roles`,
                  method: 'GET',
                  params: {
                    all: true,
                  },
                })
                  .then((role) => {
                    dispatch(getRoles(role));
                  })
                  .catch(() => { });
              } else if (userInfoFront.userType !== 1 && checkPrivileges('role')) {
                instance({
                  url: `/api/roles`,
                  method: 'GET',
                  params: {
                    userId: userInfoFront.id,
                  },
                })
                  .then((role) => {
                    dispatch(getRoles(role));
                  })
                  .catch(() => { });
              }

              instance({
                url: `/api/accounts`,
                method: 'GET',
                params: {
                  accountId: userInfoFront.accountId,
                },
              })
                .then((server) => {
                  if (server[0]) {
                    dispatch(ServerInfo(server[0]));
                    dispatch(
                      getLayer({
                        id: userInfoFront.map || (server && server[0].map) || 'osm',
                      })
                    );
                    if (props?.location?.state) {
                      props?.history.push(props?.location.state);
                    } else {
                      props?.history.push('/');
                    }
                  } else {
                    someThingWendWrong('Account Data Not Found');
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  setLoginErrorMessage(displayErrorMessages(error.message));
                });
            } else {
              setLoading(false);
              setLoginErrorMessage('Lack Of Privileges');
            }
          })
          .catch((error) => {
            setLoading(false);
            setLoginErrorMessage(displayErrorMessages(error.message));
          });
      })
      .catch((error) => {
        errorHandler(error, dispatch, errorCallBack);
      });
  };

  const errorCallBack = (data) => {
    setLoading(false);
    setLoginErrorMessage(displayErrorMessages(data.message));
  };

  const handleClick = (e) => {
    if (e.target.checked && email && password) {
      setCookie('userName', email);
      setCookie('pass', password);
    } else {
      setCookie('userName', '');
      setCookie('pass', '');
    }
  };

  const getCookie = (cname) => {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  const setCookie = (name, value, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = name + '= ' + encodeURIComponent(value) + ';' + expires + ';path=/login;';
  };
  //   const generateRandomAlpha=(length)=> {
  //     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  //     let result = '';
  //     for (let i = 0; i < length; i++) {
  //       result += characters.charAt(Math.floor(Math.random() * characters.length));
  //     }
  //     return result;
  //   }
  const someThingWendWrong = (msg) => {
    setLoading(false);
    setLoginErrorMessage(props?.translate(msg));
  };

  if (loading) {
    return (
      <div className='loginLoaderWrapper'>
        <Loader />
      </div>
    )
  } else {
    return (
      <form
        className='loginForm'
        onSubmit={handleLogin}
        autoComplete='off'
      >
        <div className='login-logo-wrapper'>
          <img
            className='login-logo'
            src={props?.whiteLabling.logo}
            alt='logo'
          />
        </div>
        {loginErrorMessage && (
          <div
            className='alert alert-danger'
            style={{
              color: themecolors['error'],
            }}
          >
            <svg fill='currentColor' width={16} height={16}>
              <use xlinkHref={`${InfoIcon}#icon`} />
            </svg>
            {props?.translate(loginErrorMessage)}
          </div>
        )}
        <div className='form-group'>
          <TextField
            themecolors={themecolors}
            id='email'
            variant='outlined'
            label={props?.translate('userEmail') + ' *'}
            type='text'
            value={email || ''}
            onChange={(e) => handleChange(e)}
            margin='dense'
            fullWidth
            InputLabelprops={{
              shrink: true
            }}
          />
        </div>
        <div className='form-group'>
          <TextField
            themecolors={themecolors}
            id='password'
            variant='outlined'
            label={props?.translate('userPassword') + ' *'}
            type='password'
            value={password || ''}
            onChange={(e) => handleChange(e)}
            margin='dense'
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        {process.env.REACT_APP_CAPTCHA_ENABLE === true && <>
          <p style={{ margin: "10px 0px 0px 0px", fontSize: 12, color: themecolors?.textColor }}>Captcha Code:</p>
          <div
            className='form-group'
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "28px",
                padding: "2px  5px",
                fontFamily: "Henny Penny , Bradley Hand, cursive",
                backgroundColor: "grey",
                borderRadius: "2px",
                color: "dimgray",
                width: "85%",
                textDecoration: "line-through",
                minHeight: "42px",
                userSelect: "none",
              }}
            >{captcha || ""}</p>
            <p style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "28px",
              padding: "2px  5px",
              backgroundColor: themecolors?.themeListingColor,
              borderRadius: "2px",
              width: "14%",
              alignItems: "center",
              userSelect: "none",

              minHeight: "42px"
            }}>
              <Tooltip title="Regenerate Captcha" style={{ color: themecolors?.textColor }}>
                <ReplayIcon onClick={handleRegenerateCaptcha} className="actionButton" />
              </Tooltip>
            </p>
          </div>
          <div className='form-group'>

            <TextField
              id='captcha'
              label='Enter the CAPTCHA below:'
              type='text'
              value={userInput || ''}
              onChange={(e) => handleCaptchaChange(e)}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  borderColor: userInput === captcha ? 'green' : 'red',
                },
                classes: {
                  root: 'theme-cssOutlinedInput',
                  error: 'theme-input-error',
                  input: 'theme-input',
                  focused: 'theme-cssFocused',
                  notchedOutline: 'theme-notchedOutline',
                },
              }}
            />
          </div>
        </>}
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px'
          }}
        >
          <div
            style={{
              color: themecolors?.textColor,
              marginRight: 'auto',
              marginLeft: -12
            }}
          >
            <Checkbox
              disableRipple
              onChange={event => handleClick(event)}
              label={props?.translate('rememberme')}
            />
          </div>
          <NavLink
            to={'/login'}
            onClick={() => props?.switchView('forgot')}
          >
            {props?.translate('forgotPassword')}
          </NavLink>
        </div>
        <p style={{ textAlign: 'center' }}>
          <Button
            //  disabled={!this.state.isVisableUserBtn}  
            disabled={process.env.REACT_APP_CAPTCHA_ENABLE === true ? !isVisableUserBtn : false}
            themecolors={themecolors} type='submit' className='btn-submit'>
            {props?.translate('loginLogin')}
          </Button>
        </p>

        {logInUsers && logInUsers?.id ? (
          <SocketController />
        ) : null}
        <div className='login-footer'>
          <a
            href={
              props?.whiteLabling && props?.whiteLabling.termsLink
            }
          >
            {props?.translate('about')}
          </a>
          <a
            href={
              props?.whiteLabling && props?.whiteLabling.termsLink
            }
          >
            {props?.translate('termsAndConditions')}
          </a>
          <a
            href={
              props?.whiteLabling &&
              props?.whiteLabling.privacyPolicyLink
            }
          >
            {props?.translate('privacy')}
          </a>
        </div>
      </form>
    )
  }
};

export default withLocalize(LoginComponent);
