import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { ReactComponent as TrailerIcon } from './../../assets/nav/trailer.svg';
import { ReactComponent as BinIcon } from './../../assets/nav/bin.svg';
import { ReactComponent as PassengerIcon } from './../../assets/nav/passenger.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { checkPrivileges } from '../../Helpers';
import { withLocalize } from 'react-localize-redux';
import Style from 'style-it';

library.add(faSatelliteDish);

const Trailer = (props) => {
  let data = '';
  if (props.items.length) {
    data = props.items.map(trailer => (
      <ListRow
        key={trailer.id}
        item={trailer}
        {...props}
        removedItem={props.removedItem}
        editItem={props.editItem}
        selecteItem={props.selecteItem}
      />
    ));
  }
  return (
    <Style>
      {`
  .material-icons {vertical-align: middle; color: inherit}
`}
      <div>
        {data && (
          <div className='individual-view'>
            <ul className='list-view with-padding-right'>{data}</ul>
          </div>
        )}
      </div>
    </Style>
  );
};

const ListRow = (props) => {
  const device = props.allTrailerLocation && props.allTrailerLocation[props.item.uniqueId] || null;
  const handleFetchIds=(childId,parentId)=>{
    if(childId){
      props.removedItem(childId)
    }
    if(parentId){
      props.getParentIdFromListRow(parentId)
    }
  }
  return (
    <li>
      <Link
        // onClick={handleClick}
        className={
          'clearfix list-row ' +
          (parseInt(props.match.params.id) ===
          parseInt(props.item.id)
            ? 'active'
            : '')
        }
        to={'/binders/' + props.item?.id}
      >
        <label
          className='checkbox'
          onClick={() => {props.selecteItem(props.item.id)}}
        >
          <span
            className='unit-name'
            style={{ minWidth: 190, maxWidth: 190, marginLeft: 0 }}
          >
            <span
              style={{
                marginRight: '5px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}
            >
              {
                props.item?.type === "trailer" ?
                  <TrailerIcon style={{
                    verticalAlign: 'middle',
                    marginRight: 5,
                    marginTop: -2,
                    height: 20,
                    width: 20,
                    fill: 'currentColor',
                    display: 'inline-block'
                  }} />
                : props.item?.type === "bin" ?
                  <BinIcon style={{
                    verticalAlign: 'middle',
                    marginRight: 5,
                    marginTop: -2,
                    height: 20,
                    width: 20,
                    fill: 'currentColor',
                    display: 'inline-block'
                  }} />
                : 
                  <PassengerIcon style={{
                    verticalAlign: 'middle',
                    marginRight: 5,
                    marginTop: -2,
                    height: 20,
                    width: 20,
                    fill: 'currentColor',
                    display: 'inline-block'
                  }} />
              }
            </span>
            {props.item?.name}
          </span>
        </label>
        <div className='pull-right'>
          {checkPrivileges('binderDelete') && (
            <span
              className='action-item hovered'
              title={props.translate('delete')}
              onClick={() => handleFetchIds(props.item,props?.collapseIds)}
            >
              <DeleteIcon style={{fontSize: 16}}/>
            </span>
          )}
          {checkPrivileges('binderUpdate') && (
            <span
              className='action-item hovered'
              title={props.translate('edit')}
              onClick={() => props.editItem(props.item)}
            >
              <EditIcon style={{fontSize: 16}}/>
            </span>
          )}
          {device ? (
            <span>
              <img
                  width={24}
                  height={24}
                  src={
                    '/assets/category/default/'+(device.category ||
                      'default')+'.svg'}
                  alt=''
                />
            </span>
          ) : <span
          className='action-item'
        >
          <LinkOffIcon style={{fontSize: 16}}/>
        </span>}
        </div>
      </Link>
    </li>
  );
};

const TrailerShortList = withLocalize(Trailer);

export { TrailerShortList, ListRow };
