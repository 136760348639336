import React, { useEffect, useState,useMemo } from 'react'
import {useSelector } from 'react-redux'
// import { toggleGroupDevices } from './../../Actions/Devices'
// import isEqual from 'react-fast-compare'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete';
import Collapse from '@mui/material/Collapse'
import './index.scss'
import instance from '../../axios'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ChevronRight"

library.add(faSatelliteDish)

export const DevicesShortList = (props) => {
  // const [groupsCall, setGroupsCall] = useState(false)
  // const [calendersCall, setCalendersCall] = useState(false)
  // const [driversCall, setDriversCall] = useState(false)
  const devices = useSelector((state) => state.devices.data)

  useEffect(() => {
    props.isChecked(devices)
  }, [])

  const data = useMemo(() => {
    if (props.switchView === 'units') {
      if (props.devices.length) {
        return props.devices?.map((device, index) => (
          <ListRow
            key={device.id + '__' + index}
            item={device}
            {...props}
            devicesIcons={props.devicesIcons}
          />
        ));
      }
    } else {
      if (props.itemPagination2.items && props.itemPagination2.items.length) {
        props.itemPagination2.items.forEach((group) => {
          group.children = [];

          props.itemPagination2.items.forEach(device => {
            if (device.groupId === group.id) {
              group.children.push(device);
            }
          });
        });
      }
    }
    return null;
  }, [props]);
  
  return (
    <div>
      {props.switchView === 'units' ? (
        props.devices.length ? (
          <div className='individual-view list-row-condest'>
            <ul className='list-view'>{data}</ul>
          </div>
        ) : (
          <div />
        )
      ) : (
        <DrawGroups groupsProp={props.itemPagination2.items} {...props} />
      )}
    </div>
  );
}

export const DrawGroups = (props) => {
  return (
    <div className='group-view list-row-condest'>
      <ul className='list-view-group'>
        {props.groupsProp?.map((group, index) => (
          <DrawGroupRow key={index} group={group} {...props} />
        ))}
      </ul>
    </div>
  );
}

const MemoizedDrawGroupRow = (props) => {
  // const [isChecked, setIsChecked] = useState(true)
  const [checked, setChecked] = useState(true)
  const [childUnits,setChildUnits]=useState([])


  useEffect(() => {
    // setIsChecked(true);
    if (props.group && props.group.children) {
      props.group.children.forEach(item => {
        if (item.visible === false) {
          // setIsChecked(false);
        }
      });
    }
  }, [props.group]);


  // const toggleGroupDevices = (event) => {
  //   dispatch(toggleGroupDevices({
  //     checked: event.target.checked,
  //     id: props.group.id
  //   }));
  //   setIsChecked(event.target.checked);
  // };

  const findResourceUnits = (e, id) => {
    if (e) {
      instance({
        url: `/api/devices`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          groupId: id,
        }
      })
        .then(res => {
          setChildUnits(res); // Assuming response data is what you need
          setChecked(true);
        })
        .catch(err => { console.warn('err ===', err) });
    } else {
      setChecked(false);
    }
  }

  const emptyLinks = null
  return (
    props.group ? (
      <li key={props.group.id}>
        <div className='clearfix group-list-row list-row'>
          <label
            onClick={() => findResourceUnits(!checked, props.group.id)}
            className='checkbox'
          >
            <span
              className='unit-name'
              style={{ minWidth: 200, maxWidth: 200, marginLeft: 0 }}
            >
              <a href={emptyLinks} title='Additional Menu'>
                {checked ? (
                  <ExpandMoreIcon style={{ width: 20, height: 20, verticalAlign: 'middle', marginRight: 5 }} />
                ) : (
                  <ExpandLessIcon style={{ width: 20, height: 20, verticalAlign: 'middle', marginRight: 5 }} />
                )}
              </a>
              {props.group.name}
            </span>
          </label>
        </div>
        <Collapse in={checked} timeout='auto' unmountOnExit>
          <ListRows children={childUnits} {...props} />
        </Collapse>
      </li>
    ) : null
  );

}

export const DrawGroupRow = React.memo(MemoizedDrawGroupRow);

export const ListRows = (props) => {
  if (props.children && props.children.length) {
    return (
      <ul className='list-view'>
        {props.children.map(data => (
          <ListRow key={data.id} item={data} {...props} />
        ))}
      </ul>
    );
  } else {
    return null;
  }
};

export const ListRow = (props) => {
  // const handleClick = () => {
  //   props.collapseSidebar();
  // };

  if (props.item && props.item.id) {
    return (
      <li>
        <Link
          onClick={props.resetFrom}
          className={
            'clearfix list-row ' +
            (parseInt(props.match && props.match.params && props.match.params.id) ===
              parseInt(props.item && props.item.id)
              ? 'active'
              : '')
          }
          to={'/units/' + props.item.id}
        >
          <Tooltip
            title={props.item.name}
            placement="right-start" // Adjust tooltip placement as needed
            classes={{
              popper: 'menu-popper',
              tooltip: 'menu-popper-tooltip'
            }}
          >
            <label className='checkbox'>
              <span className='unit-name-track'>
                <img
                  src={
                    '/assets/category/default/' +
                    (props.item.category || 'default') +
                    '.svg'
                  }
                  alt={props.item.name}
                />{' '}
                {props.item.name}
              </span>
            </label>
          </Tooltip>
          <div className='pull-right'>
            {checkPrivileges('deviceDelete') && (
              <span
                className='action-item hovered'
                title={props.translate('delete')}
                onClick={e => props.removeDevice(e, props.item)}
              >
                <DeleteIcon style={{ fontSize: 16 }} />
              </span>
            )}
          </div>
        </Link>
      </li>
    );
  } else {
    return null;
  }
};