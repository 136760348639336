import React, {  } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as LoaderIcon } from './../assets/tracks-loader.svg'
const Loader = (props) => {

    const style = {
      pageLoader: {
        maxHeight: '100%',
        height: '100vh',
        minHeight: 300,
        background: props.monitoringTracks ? null : props.themecolors.backgroundColor,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      },
      defaultPageLoader: {
        color: 'inherit'
      }
    }
    return (
      <div
        className='page-loader'
        style={
          props.defaultStyle ? style.defaultPageLoader : style.pageLoader
        }
      >
        <p style={{ textAlign: 'center', color: props.themecolors.textColor }}>
          <LoaderIcon
            style={{width: props.imgSize || 50, height: props.imgSize || 50}}
          />
        </p>
      </div>
    )
  }

const mapState = state => ({
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(Loader)
