import React, { useState,useEffect } from 'react'
import { withLocalize } from 'react-localize-redux'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import Notifications from 'react-notification-system-redux'
import { ScreenLast } from './ScreenLast'
import { Screen2 } from './Screen2'
import { ScreenTwo } from './ScreenTwo'
import Loader from '../../../Layout/Loader'
import { convertSpeedUnits, errorHandler, prepareCalendar } from '../../../Helpers'
import {
  addNotification,
  updateNotification
} from '../../../Actions/Notifications'
import axios from 'axios'
import instance from '../../../axios'
import moment from 'moment'
import { Buffer } from 'buffer'
const CancelToken = axios.CancelToken
let source

const notification = (props) => {

  const[state,setState]=useState({
    tabPostion: 0,
    loader: false,
    responseGeofenceCall: false,
    responseListCall: false,
    wizardData: {
      form1: {
        validatedForm: false,
        device: true,
        driver: false,
        passenger: false,
        byGroup: false,
        prv: false,
        type: 'device',
        vehicle:false,
        binder:false,
        user:false,
        form: {
          deviceIds: [],
          driverIds: [],
          passengerIds: [],
          groupIds: [],
          vehicleIds:[],
          binderIds:[],
          userIds:[],
          all: false
        }
      },
      form2: {
        validatedForm: false,
        prv: false,
        form: {
          functionalType: '',
          type: '',
          tolerance: 5,
          minLimit: '',
          maxLimit: '',
          logic: '',
          considerNull: false,
          threshold: '',
          useIgnition: false,
          analogType: '',
          digitalType: '',
          maintenanceId: '',
          maintenanceData: '',
          geoFences: [],
          input: '',
          startTime:'',
          endTime:'',
          fenceRadius:'',
          ps_plateNumber:'',
          ps_trafficFileNumber:'',
          ps_brand:'',
          ps_model:'',
          ps_color:'',
          ps_state:''
        }
      },
      form3: {
        validatedForm: false,
        isOpenEmailSection: false,
        isOpenCommandSection: false,
        isOpenSmsSection: false,
        isOpenWebSection: true,
        isOpenMobileSection: false,
        prv: false,
        form: {
          email: [{ id: 1, text: '' }],
          number: [{ id: 1, text: '' }],
          mailTo: 'user',
          commandType: 'command',
          commandData: '',
          commandName: '',
          sendCommand: false,
          commandId: 0
        },
        deviceFullData: '',
        alertColor: '',
        alertSound: ''
      },
      form4: {
        validatedForm: false,
        prv: false,
        form: {
          name: '',
          description: '',
          alarmState: 10,
          interval: 5,
          initialTrigger: 0,
          schedul: '',
          geoFences: [],
          alertPopupTime:10,
          checkOutsideGeofence:false,
          repeat:false
        },
        scheduleModal: false
      }
    }
  })
const [isSaveNotification,setIsSaveNotification]=useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const { mode, itemData, devices, logInUser } = props;
      let parkingAlertDevice = {};

      if (itemData.type === 'parkingSecurity') {
        const deviceItem = itemData.attributes?.deviceIds;
        parkingAlertDevice = devices.find(element => element && element.id === deviceItem);
        parkingAlertDevice = { ...parkingAlertDevice, value: parkingAlertDevice?.id };
      }

      if (mode === 'update') {
        const unit = logInUser.attributes?.speedUnit || 'Km/h';
        setState(prevState => ({ ...prevState, loader: true }));

        const { attributes } = itemData;

        try {
          await Promise.all([
            fetchGeoFences(attributes.geofenceId, true, 'form4'),
            fetchGeoFences(attributes.geofenceIds, false, 'form2'),
            attributes.maintenanceId && fetchListData(attributes.maintenanceId, 'maintenance', true)
          ]);

          setState(prevState => ({
            ...prevState,
            wizardData: {
              form1: {
                validatedForm: false,
                device: attributes.type === 'device',
                driver: attributes.type === 'driver',
                passenger: attributes.type === 'passenger',
                byGroup: attributes.groupIds && attributes.groupIds.length > 0,
                prv: true,
                type: attributes.type,
                form: {
                  passengerIds: dataConvertingTostring(attributes.passengerIds),
                  groupIds: dataConvertingTostring(attributes.groupIds),
                  vehicleIds: itemData.type === 'vehicleExpire' ? dataConvertingTostring(attributes.itemIds) : [],
                  binderIds: itemData.type === 'binderExpire' ? dataConvertingTostring(attributes.itemIds) : [],
                  userIds: itemData.type === 'userExpire' ? dataConvertingTostring(attributes.itemIds) : [],
                  driverIds: itemData.type === 'driverExpire' && attributes.functionalType === "expiration" ? dataConvertingTostring(attributes.itemIds) : dataConvertingTostring(attributes.driverIds),
                  deviceIds: itemData.type === 'deviceExpire' && attributes.functionalType === "expiration" ? dataConvertingTostring(attributes.itemIds) : itemData.type === "parkingSecurity" ? parkingAlertDevice : dataConvertingTostring(attributes.deviceIds),
                  all: itemData.always
                }
              },
              form2: {
                validatedForm: false,
                prv: true,
                form: {
                  ...state.wizardData.form2.form,
                  functionalType: attributes.functionalType,
                  index: attributes.index,
                  startTime: moment(attributes?.startTime).format("YYYY-MM-DDThh:mm") || '',
                  endTime: moment(attributes?.endTime).format("YYYY-MM-DDThh:mm") || '',
                  fenceRadius: attributes?.fenceRadius || '',
                  ps_plateNumber: attributes?.ps_plateNumber || '',
                  ps_trafficFileNumber: attributes?.ps_trafficFileNumber || '',
                  ps_brand: attributes?.ps_brand || '',
                  ps_model: attributes?.ps_model || '',
                  ps_color: attributes?.ps_color || '',
                  ps_state: attributes?.ps_state || '',
                  valueType: attributes?.valueType || '',
                  value: attributes?.value === false ? 'false' : attributes?.value || '',
                  type: itemData.type,
                  tolerance: attributes.tolerance && itemData.type === 'deviceOverspeed'
                    ? convertSpeedUnits(attributes.tolerance || 0, 'kn', unit)
                    : attributes.tolerance || 5,
                  minLimit: attributes.minLimit && itemData.type === 'deviceOverspeed'
                    ? convertSpeedUnits(attributes.minLimit || 0, 'kn', unit)
                    : attributes.minLimit || 0,
                  maxLimit: attributes.maxLimit && itemData.type === 'deviceOverspeed'
                    ? convertSpeedUnits(attributes.maxLimit || 0, 'kn', unit)
                    : attributes.maxLimit || 180,
                  logic: attributes.logic,
                  input: attributes.input,
                  considerNull: attributes.considerNull,
                  threshold: attributes.threshold
                    ? itemData.type === 'deviceParking'
                      ? attributes.threshold / (60 * 60000)
                      : attributes.threshold / 60000
                    : 0,
                  useIgnition: attributes.useIgnition || false,
                  analogType: attributes.analogType || '',
                  digitalType: attributes.digitalType || '',
                  maintenanceId: attributes.maintenanceId || '',
                  maintenanceData: attributes.maintenanceId ? fetchListData(attributes.maintenanceId, 'maintenance', true) : ''
                }
              },
              form3: {
                validatedForm: false,
                isOpenEmailSection: itemData.notificators.includes('mail'),
                isOpenSmsSection: itemData.notificators.includes('sms'),
                isOpenWebSection: itemData.notificators.includes('web'),
                isOpenCommandSection: itemData.attributes?.sendCommand,
                isOpenFirebaseSection: itemData.notificators.includes('firebase'),
                isOpenMobileSection: false,
                prv: true,
                form: {
                  mailTo: attributes.mailTo || 'user',
                  alertColor: attributes.alertColor || '',
                  alertSound: attributes.alertSound || '',
                  email: attributes.emails ? spiltItem(attributes.emails) : [{ id: 1, text: '' }],
                  number: attributes.numbers ? spiltItem(attributes.numbers) : [{ id: 1, text: '' }],
                  commandType: attributes.commandType || 'command',
                  commandData: attributes.commandData || '',
                  commandName: attributes.commandName || '',
                  commandId: attributes.commandId || 0
                }
              },
              form4: {
                validatedForm: false,
                prv: true,
                form: {
                  ...state.wizardData.form4.form,
                  name: itemData.name,
                  description: itemData.description,
                  alarmState: attributes.alarmState || 0,
                  initialTrigger: attributes.initialTrigger ? attributes.initialTrigger / 1000 : 0,
                  interval: attributes.interval ? attributes.interval / 60000 : '',
                  schedul: attributes.calendar && attributes.eventRawList ? attributes.eventRawList : '',
                  alertPopupTime: attributes.alertPopupTime ? attributes.alertPopupTime / 1000 : 10,
                  checkOutsideGeofence: attributes.checkOutsideGeofence || false,
                  repeat:attributes.repeat||false
                },
                scheduleModal: false
              }
            },
            loader: false
          }));
        } catch (error) {
          // Handle errors here
          console.error('Error fetching data:', error);
          setState(prevState => ({ ...prevState, loader: false }));
        }
      }
    };

    fetchData();

  }, [props.mode, props.itemData, props.devices, props.logInUser]);
 
  useEffect(() => {
    return () => {
      if (source) {
        source.cancel();
      }
    };
  }, []);


 const dataConvertingTostring = data => {
    let result = []
    if (data && data.length) {
      data.map(item => {
        result.push(item.toString())
        return null
      })
    }
    return result
  }


//  const checkIncludesSting = (item, data) => {
//     if (data.includes(item)) {
//       return true
//     } else {
//       return false
//     }
//   }


 const spiltItem = item => {
    let result = []
    let res = item.split(',')
    if (res.length) {
      res.map((subitem, ind) => {
        if (subitem) {
          result.push({ id: ind + 1, text: subitem })
        }
        return null
      })
    }
    return result
  }



 const fetchListData = (list, type, single) => {
    if (source) {
      source.cancel()
    }
    source = CancelToken.source()
    let result = [],
      fetchUrl = '/api/devices/list?'
    if (type === 'device') {
      list.map((it, i) => {
        if (i === 0) {
          fetchUrl = fetchUrl + 'id=' + it
        } else {
          fetchUrl = fetchUrl + '&id=' + it
        }
        return null
      })
    } else if (type === 'group') {
      fetchUrl = `/api/groups/get?userId=${props.logInUser.id}&limit=-1`
    } else if (type === 'maintenance') {
      fetchUrl = `/api/maintenance/get?userId=${props.logInUser.id}&limit=-1`
    }
    setState(prevState=>({...prevState, responseListCall: true }))
      instance({
        method: 'GET',
        url: `${fetchUrl}`,
        cancelToken: source.token
      }).then(response => {
          const res = response
          let { data } = res
          if (data.length) {
            if (type === 'device') {
              data.map(item => {
                result.push(item)
                return null
              })
            } else {
              if (single) {
                data.map(item => {
                  if (list === item.id) {
                    result.push({
                      ...item,
                      label: item.label || item.name,
                      value: item.id
                    })
                  }
                  return null
                })
              } else {
                data.map(item => {
                  let found = list.find(element => element === item.id)
                  if (found) {
                    result.push(item)
                  }
                  return null
                })
              }
            }
          }
         setState(prevState=>({
          ...prevState,
            responseListCall: false
          }))
        // }
      }).catch(() => {
        // errorHandler(error, props.dispatch)
      })

    return result
  }



 const fetchGeoFences = (list, single, value) => {
    if (source) {
      source.cancel()
    }
    source = CancelToken.source()
    let result = []
    if (list) {
      setState(prevState=>({...prevState, responseGeofenceCall: true }))
        instance({
          method: 'GET',
          url: `/api/geofences/get?userId=${props.logInUser.id}&all=true`,
          cancelToken: source.token
        }).then(response => {
            const res = response
            let { data } = res
            if (data.length) {
              if (single) {
                data.map(item => {
                  if (item.id === list) {
                    item['visible'] = true
                  } else {
                    item['visible'] = false
                  }
                  result.push(item)
                  return null
                })
              } else {
                data.map(item => {
                  let found = list.find(element => element === item.id)
                  if (found) {
                    item['visible'] = true
                  } else {
                    item['visible'] = false
                  }
                  result.push(item)
                  return null
                })
              }
            }
            setState(prevState=>({
              ...prevState,
              wizardData: {
                 ...prevState.wizardData,
                [value]: {
                   ...prevState.wizardData[value],
                  form: {
                     ...prevState.wizardData[value].form,
                    geoFences: result
                  }
                }
              },
              responseGeofenceCall: false
            }))
          // }
        }).catch(() => {
          // errorHandler(error, props.dispatch)
        })

    } else {
      setState(prevState=>({
        ...prevState,
        wizardData: {
          ...prevState.wizardData,
          [value]: {
            ...prevState.wizardData[value],
            form: { ...prevState.wizardData[value].form, geoFences: '' }
          }
        }
      }))
    }
  }
  //~end~


 const handleChangeTab = (event, tab) => {
    let { wizardData, tabPostion } = state
    if (
      (wizardData['form' + [tab]] && wizardData['form' + [tab]].prv) ||
      tab < tabPostion
    ) {
      setState(prevState=>({
        ...prevState, tabPostion: tab }))
    }
  }
useEffect(()=>{
  if(isSaveNotification){
    saveNotification()
  }

},[isSaveNotification])
 const combineAllData = (name, data, type, parturalUpdat) => {
    if (parturalUpdat) {
      setState(prevState=>({
        ...prevState,
        wizardData: {
          ...prevState.wizardData,
          [name]: data[0],
          form1: data[1]
        },
        tabPostion: state.tabPostion + 1
      }))
    } else {
      if (type) {
        setState(prevState=>({
          ...prevState,
            wizardData: {
              ...prevState.wizardData,
              [name]: data
            },
            loader: true
          }))
          setIsSaveNotification(true)
      } else {
        setState(prevState=>({
          ...prevState,
          wizardData: {
            ...prevState.wizardData,
            [name]: data
          },
          tabPostion: state.tabPostion + 1
        }))
      }
    }
  }

 const checkedDisableTab = type => {
    let { wizardData } = state
    if (wizardData[type] && wizardData[type].prv) {
      return false
    } else {
      return true
    }
  }

 const saveNotification = () => {
    let { mode, itemData } = props
    let { wizardData } = state
    let { form1, form2, form3, form4 } = wizardData
    let vailidation = true
    if (mode && mode === 'update') {
      if (
        form1.validatedForm ||
        form2.validatedForm ||
        form3.validatedForm ||
        form4.validatedForm
      ) {
        vailidation = true
      } else {
        vailidation = false
      }
    } else {
      if (form2.validatedForm && form3.validatedForm && form4.validatedForm) {
        vailidation = true
      } else {
        vailidation = false
      }
    }
    if (vailidation) {
      let dData = checkForAtt(wizardData)
      let updatedAttributes={...dData.attributes}
      if (updatedAttributes["repeat"] === false) {
        delete updatedAttributes["interval"];
      }
      let obj = {
        id: (mode && mode === 'update' && itemData.id) || '',
        parentId: itemData.parentId || 0,
        name: form4.form.name,
        description: form4.form.description,
        type: checkType(form2.form),
        //always: form1.form.all || false, //this will be chabged
        attributes:updatedAttributes,
        notificators: dData.notificators,
        calendarId: 0
      }
      if (form4.form && form4.form.schedul) {
        obj.attributes = {
          ...obj.attributes,
          calendar: form4.form.schedul.ical
            ? Buffer.from(form4.form.schedul.ical).toString('base64')
            : Buffer.from(prepareCalendar(form4.form.schedul)).toString(
                'base64'
              ),
          eventRawList: form4.form.schedul.form
            ? form4.form.schedul.form
            : form4.form.schedul
        }
      }
      if(form2.form.functionalType ==='expiration'){
        obj = {
          id: (mode && mode === 'update' && itemData.id) || '',
          parentId: itemData.parentId || 0,
          name: form4.form.name,
          description: form4.form.description,
          type: form2.form.type,
          //always: form1.form.all || false, //this will be chabged
          attributes: dData.attributes,
          notificators: dData.notificators,
          calendarId: 0
        }
        obj.attributes = {
          ...obj.attributes,
          itemIds:form2.form.type==="binderExpire"?dData&&dData.attributes&&dData.attributes.itemIds:
          form2.form.type==="vehicleExpire"?dData&&dData.attributes&&dData.attributes.itemIds:
          form2.form.type==="deviceExpire"?dData&&dData.attributes&&dData.attributes.itemIds:
          form2.form.type==="driverExpire"?dData&&dData.attributes&&dData.attributes.itemIds:
          form2.form.type==="userExpire"?dData&&dData.attributes&&dData.attributes.itemIds:[]
        }
      }
      if(form2.form.functionalType ==="customAlert"){
        obj = {
          id: (mode && mode === 'update' && itemData.id) || '',
          parentId: itemData.parentId || 0,
          name: form4.form.name,
          description: form4.form.description,
          type: form2.form.type,
          //always: form1.form.all || false, //this will be chabged
          attributes: dData.attributes,
          notificators: dData.notificators,
          calendarId: 0
        }
        obj.attributes = {
          ...obj.attributes,
          startTime:new Date(form2.form&&form2.form.startTime).toISOString(),
          endTime:new Date(form2.form&&form2.form.endTime).toISOString(),
          fenceRadius:form2.form.fenceRadius,
          ps_plateNumber:form2.form&&form2.form.ps_plateNumber,
          ps_trafficFileNumber:form2.form&&form2.form.ps_trafficFileNumber,
          ps_brand:form2.form&&form2.form.ps_brand,
          ps_model:form2.form&&form2.form.ps_model,
          ps_color:form2.form&&form2.form.ps_color,
          ps_state:form2.form&&form2.form.ps_state,
        }
      }
      if(form2.form.functionalType ==="otherAlert"){
        obj = {
          id: (mode && mode === 'update' && itemData.id) || '',
          parentId: itemData.parentId || 0,
          name: form4.form.name,
          description: form4.form.description,
          type: form2.form.type,
          //always: form1.form.all || false, //this will be chabged
          attributes: dData.attributes,
          notificators: dData.notificators,
          calendarId: 0
        }
        obj.attributes = {
          ...obj.attributes,
          fenceRadius:form2.form.fenceRadius,
          input:form2.form.input,
          valueType:form2.form.valueType,
          value:form2.form.value,
        }
      }

     
      // function to submit value 
      ac_submit(obj)
    } else {
      //triger error message of nothing changed
      setState(prevState=>({
        ...prevState,
        loader: false
      }))
      props.dispatch(
        Notifications.error({
          message: props.translate(
            'Nothing was changed or required fields error'
          ),
          autoDismiss: 10
        })
      )
    }
  }


 const checkForLogicGeo = (list, single) => {
    let array = [],
      singleItem = ''
    if (single) {
      list.map(item => {
        if (item.visible) {
          singleItem = parseInt(item.id)
        }
        return null
      })
      return singleItem
    } else {
      list.map(item => {
        if (item.visible) {
          array.push(item.id)
        }
        return null
      })
      return array
    }
  }

 const checkForAtt = data => {
    let fnData = { attributes: {}, notificators: '' }
    let { attributes, notificators } = fnData
    if (data.form1) {
      attributes = { type: data.form1.type }
      if (data.form2.form.functionalType !== 'maintenanceReminder') {
        if (data.form1.device) {
          if (data.form1.form.deviceIds.length && data.form2.form.functionalType!=="expiration") {
            let devarray = []
            data.form1.form.deviceIds.map(dev => {
              devarray.push(parseInt(dev))
              return null
            })
            attributes = { ...attributes, deviceIds: devarray }
          }
          if (data.form1.form.groupIds.length) {
            let grparray = []
            data.form1.form.groupIds.map(dev => {
              grparray.push(parseInt(dev))
              return null
            })
            attributes = { ...attributes, groupIds: grparray }
          }
          if (data.form1.form.deviceIds.length && data.form2.form.functionalType==="expiration") {
            let devarray = []
            data.form1.form.deviceIds.map(dev => {
              devarray.push(parseInt(dev))
              return null
            })
            attributes = { ...attributes, itemIds: devarray }
          }
          if (data.form2.form.functionalType==="customAlert") {
            let devarray = []
            let id = parseInt(data?.form1?.form?.deviceIds?.value)
            devarray = [id]
            attributes = { ...attributes, deviceIds: devarray }
          }
          if (data.form1.form.driverIds.length) {
            let driArray = []
            data.form1.form.driverIds.map(dev => {
              driArray.push(parseInt(dev))
              return null
            })
            attributes = { ...attributes, itemIds: driArray }
          }
          if (data.form1.form.userIds.length) {
            let userarray = []
            data.form1.form.userIds.map(dev => {
              userarray.push(parseInt(dev))
              return null
            })
            attributes = { ...attributes, itemIds: userarray }
          }
          if (data.form1.form.vehicleIds.length) {
            let vehiclearray = []
            data.form1.form.vehicleIds.map(dev => {
              vehiclearray.push(parseInt(dev))
              return null
            })
            attributes = { ...attributes, itemIds: vehiclearray }
          }
          if (data.form1.form.binderIds.length) {
            let binderarray = []
            data.form1.form.binderIds.map(dev => {
              binderarray.push(parseInt(dev))
              return null
            })
            attributes = { ...attributes, itemIds: binderarray }
          }
        } else if (data.form1.driver) {
          attributes = { ...attributes, driverIds: data.form1.form.driverIds }
        } else if (data.form1.passenger) {
          attributes = {
            ...attributes,
            passengerIds: data.form1.form.passengerIds
          }
        }
      }
    }
    if (data.form2) {
      attributes = {
        ...attributes,
        functionalType: data.form2.form.functionalType,
        index: data.form2.form.index
      }
      if (data.form2.form.functionalType === 'movement') {
        if (
          data.form2.form.type === 'deviceEnterGeofence' ||
          data.form2.form.type === 'deviceExitGeofence'
        ) {
          let arrayGeo = checkForLogicGeo(data.form2.form.geoFences)
          if (arrayGeo.length > 1) {
            attributes = {
              ...attributes,
              geofenceIds: arrayGeo,
              logic: data.form2.form.logic
            }
          } else {
            attributes = { ...attributes, geofenceIds: arrayGeo }
          }
        } else if (data.form2.form.type === 'deviceOverspeed') {
          let unit =
            props.logInUser.attributes &&
            props.logInUser.attributes.speedUnit
              ? props.logInUser.attributes.speedUnit
              : 'Km/h'
          attributes = {
            ...attributes,
            maxLimit: parseFloat(
              convertSpeedUnits(data.form2.form.maxLimit || 0, unit, 'kn', true)
            ),
            minLimit: parseFloat(
              convertSpeedUnits(data.form2.form.minLimit || 0, unit, 'kn', true)
            ),
            tolerance: parseFloat(
              convertSpeedUnits(
                data.form2.form.tolerance || 0,
                unit,
                'kn',
                true
              )
            )
          }
        } else if (data.form2.form.type === 'deviceStopped') {
          attributes = {
            ...attributes,
            threshold: parseInt(data.form2.form.threshold * 60000),
            useIgnition: data.form2.form.useIgnition
          }
        } else if (data.form2.form.type === 'deviceIdling') {
          attributes = {
            ...attributes,
            threshold: parseInt(data.form2.form.threshold * 60000)
          }
        } else if (data.form2.form.type === 'deviceParking') {
          attributes = {
            ...attributes,
            threshold: parseInt(data.form2.form.threshold * 60 * 60000)
          }
        }
      } else if (
        data.form2.form.functionalType === 'maintenanceReminder'
      ) {
        attributes = {
          ...attributes,
          maintenanceId: data.form2.form.maintenanceId,
          minLimit: data.form2.form.minLimit
            ? parseInt(data.form2.form.minLimit)
            : 0,
          maxLimit: data.form2.form.maxLimit
            ? parseInt(data.form2.form.maxLimit)
            : 0
        }
      } else if (data.form2.form.functionalType === 'digital') {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          logic: data.form2.form.logic
        }
      } else if (data.form2.form.functionalType === 'analog') {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          logic: data.form2.form.logic,
          minLimit: data.form2.form.minLimit
            ? parseInt(data.form2.form.minLimit)
            : 0,
          maxLimit: data.form2.form.maxLimit
            ? parseInt(data.form2.form.maxLimit)
            : 0
        }
      } else if (data.form2.form.functionalType === 'other') {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          considerNull: data.form2.form.considerNull
        }
      } else if (data.form2.form.functionalType === 'output') {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          logic: data.form2.form.logic
        }
      } else if (data.form2.form.functionalType === 'deviceStatus') {
        attributes = {
          ...attributes
        }
      } else if (data.form2.form.functionalType === 'ecodriving') {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          maxLimit: parseFloat(data.form2.form.maxLimit),
          minLimit: parseFloat(data.form2.form.minLimit),
          tolerance: parseFloat(data.form2.form.tolerance)
        }
      } else if (data.form2.form.functionalType === 'fuel') {
        attributes = {
          ...attributes,
          input: data.form2.form.functionalType
        }
      }
      //out of moments
    }
    if (data.form3) {
      let notificatorsArray = []
      attributes = { ...attributes, mailTo: data.form3.form.mailTo, 
        commandType: data.form3.form.commandType,
        sendCommand: data.form3.isOpenCommandSection }
        if(data.form3.isOpenCommandSection){
          data.form3.form.commandType==='command' ? 
          attributes = { ...attributes,commandName: data.form3.form.commandName, 
            commandId: data.form3.form.commandId,commandType: ''  } :
            attributes = { ...attributes, commandData: data.form3.form.commandData}
        }
      if (data.form3.isOpenEmailSection) {
        notificatorsArray.push('mail')
        if (
          data.form3.form.mailTo !== 'user' &&
          data.form3.form.email[0].text
        ) {
          let emailsarray = []
          data.form3.form.email.map(em => {
            if (em.text) {
              emailsarray.push(em.text)
            }
            return null
          })
          let emails = emailsarray.join(',')
          attributes = { ...attributes, emails }
        }
      }

      if (data.form3.isOpenSmsSection && data.form3.form.number[0].text) {
        notificatorsArray.push('sms')
        let numbersarray = []
        data.form3.form.number.map(em => {
          if (em.text) {
            numbersarray.push(em.text)
          }
          return null
        })
        let numbers = numbersarray.join(',')
        attributes = { ...attributes, numbers }
      }
      if (data.form3.isOpenWebSection) {
        notificatorsArray.push('web')
        attributes = {
          ...attributes,
          alertColor: data.form3.form.alertColor,
          alertSound: data.form3.form.alertSound
        }
      }
      if (data.form3.isOpenFirebaseSection) {
        notificatorsArray.push('firebase')
      }
      notificators = notificatorsArray.join(',')
    }
    if (data.form4) {
      attributes = {
        ...attributes,
        alarmState: data.form4.form.alarmState
          ? parseInt(data.form4.form.alarmState)
          : 0,
        interval: parseInt(data.form4.form.interval * 60000),
        initialTrigger: parseInt((data.form4.form.initialTrigger || 0) * 1000),
        suspend: data.form4.form.suspend,
        checkOutsideGeofence:data.form4.form.checkOutsideGeofence || false,
        repeat:data.form4.form.repeat||false,
        alertPopupTime: parseInt((data.form4.form.alertPopupTime || 10) * 1000)
      }
      if (data.form2.form.type !== 'geofence') {
        let arrayGeo = checkForLogicGeo(data.form4.form.geoFences, true)
        attributes = { ...attributes, geofenceId: arrayGeo || 0 }
      }
    }
    return { attributes, notificators }
  }


 const checkType = data => {
    let result = ''
    if (
      data.functionalType === 'movement' ||
      data.functionalType === 'deviceStatus' ||
      data.functionalType === 'maintenanceReminder' ||
      data.functionalType === 'ecodriving' ||
      data.functionalType === 'digital' ||
      data.functionalType === 'analog' ||
      data.functionalType === 'other' ||
      data.functionalType === 'fuel'
    ) {
      result = data.type
    } else {
      result = data.functionalType
    }
    return result
  }

  // submit function
 const ac_submit = prepObj => {
    let { itemData, mode } = props
    let obj = { ...prepObj }
    let fetchApiUrl = `/api/notifications/wizard`,
      callType = 'POST'
    if (mode && mode === 'update' && itemData && itemData.id) {
      fetchApiUrl = `/api/notifications/wizard/${itemData.id}`
      callType = 'PUT'
    }
    instance({
      url: fetchApiUrl,
      method: callType,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...obj
      }
      })
          .then(notifications => {
              if (mode && mode === 'update') {
                props.dispatch(updateNotification(notifications))
                props.dispatch(
                  Notifications.success({
                    message: props.translate('notificationIsUpdated'),
                    autoDismiss: 10
                  })
                )
              } else {
                props.dispatch(addNotification(notifications))
                props.dispatch(
                  Notifications.success({
                    message: props.translate('notificationIsCreated'),
                    autoDismiss: 10
                  })
                )
              }
              setState(prevState=>({
                ...prevState,
                loader: false
              }))
              props.onBack()
          })
      .catch(error => {errorHandler(error, props.dispatch, errorCallBack)
      })
  }
  // end~...
 const errorCallBack = () => {
  setState(prevState=>({
    ...prevState,
      loader: false
    }))
}

    let {
      tabPostion,
      loader,
      responseGeofenceCall,
      responseListCall
    } = state
    let { mode } = props
    let checkForcond = true
    if (mode && mode === 'update') {
      if (!responseGeofenceCall && !responseListCall) {
        checkForcond = true
      } else {
        checkForcond = false
      }
    }
    return (
      <>
        {loader || !checkForcond ? (
          <Loader />
        ) : (
          <>
            <AppBar
              position='static'
              color='inherit'
              className='custom-tabs-root-border'
              style={{ background: 'none', boxShadow: 'none' }}
            >
              <Tabs
                value={tabPostion}
                onChange={handleChangeTab}
                TabIndicatorProps={{
                  style: { background: props.themecolors.backgroundColor }
                }}
                scrollButtons='on'
                classes={{
                  root: 'custom-tabs-root',
                  flexContainer: 'custom-tabs',
                  scrollable: 'custom-tabs-scrollable',
                  indicator: 'custom-indicator',
                  scrollButtons: 'scrollable-buttons'
                }}
              >
                <Tab
                  classes={{
                    root: 'custom-tab-button custom-tab-button-1',
                    selected: 'custom-tab-button-selected'
                  }}
                  label={props.translate('functionalType')}
                  style={{ height:"35px",width:"160px",fontWeight:"bold"}}
                />
                {/* <Tab
                  classes={{
                    root: 'custom-tab-button custom-tab-button-1',
                    selected: 'custom-tab-button-selected'
                  }}
                  label={props.translate('notificationFor')}
                  disabled={checkedDisableTab('form2')}
                /> */}
                <Tab
                  classes={{
                    root: 'custom-tab-button custom-tab-button-1',
                    selected: 'custom-tab-button-selected'
                  }}
                  label={props.translate('notificators')}
                  disabled={checkedDisableTab('form2')}
                  style={{
                    color: checkedDisableTab('form2') ? '#75A1AC' : 'white',
                    height:"35px",width:"160px",fontWeight:"bold"
                  }}
                />
                <Tab
                  classes={{
                    root: 'custom-tab-button custom-tab-button-1',
                    selected: 'custom-tab-button-selected'
                  }}
                  label={props.translate('properities')}
                  disabled={checkedDisableTab('form3')}
                  style={{
                    color: checkedDisableTab('form3') ? '#75A1AC' : 'white',
                    height:"35px",width:"160px",fontWeight:"bold"
                  }}
                />
              </Tabs>
            </AppBar>
            <Paper
              style={{ minHeight: 270, background: 'transparent' }}
              className='custom-menu-paper'
              square={true}
              elevation={0}
            >
              <>
                {tabPostion === 0 && (
                  <Screen2
                    handleChangeTab={handleChangeTab}
                    combineAllData={combineAllData}
                    editOption={props.mode ==='update'?true:false}
                    {...state}
                    {...props}
                  />
                )}
                {/* {tabPostion === 1 && (
                  <div>
                    <Screen1
                      handleChangeTab={handleChangeTab}
                      combineAllData={combineAllData}
                      {...state}
                      {...props}
                    />
                  </div>
                )} */}
                {tabPostion === 1 && (
                  <ScreenTwo
                    handleChangeTab={handleChangeTab}
                    combineAllData={combineAllData}
                    {...state}
                    {...props}
                  />
                )}
                {tabPostion === 2 && (
                  <ScreenLast
                    itemData={props.itemData}
                    fetchGeoFences={fetchGeoFences}
                    handleChangeTab={handleChangeTab}
                    combineAllData={combineAllData}
                    {...state}
                    {...props}
                  />
                )}
              </>
            </Paper>
          </>
        )}
      </>
    )
  
}

export const NotificationModal = withLocalize(notification)
