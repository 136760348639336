import React, { useState } from 'react'
import Button1 from './Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

const ConfirmDialoag = (props) => {
  const [open, setOpen] = useState(true)


 const handleCancel = () => {
    if (props.onCancel) {
    props.onCancel()
      setOpen(false)
    }
  }


 const handleOk = () => {
    if (props.onOk) {
      props.onOk()
      setOpen(false)
    }
  }

  return(
<Dialog
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby='confirmation-dialog-title'
        open={open}
      >
        {props.title && (
          <DialogTitle id='confirmation-dialog-title'>
            {props.title}
          </DialogTitle>
        )}
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          <Button1 onClick={handleCancel} className="button-white">
            {props.CancelText || 'Cancel'}
          </Button1>
          <Button1
            onClick={handleOk}
            disabled={props.loading}
            color='primary'
            style={{ minWidth: 60 }}
          >
            {props.loading?"loading":props.OkText || 'Ok'}
          </Button1>
        </DialogActions>
      </Dialog>
  )
}

export default ConfirmDialoag;