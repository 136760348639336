import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { checkPrivileges, errorHandler } from '../../../Helpers';
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag';
import Style from 'style-it';
import Scrollbar from 'react-scrollbars-custom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Notifications from 'react-notification-system-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import instance from '../../../axios';
import SearchField from '../../../Components/common/SearchField';
import Button from '../../../Components/common/Button';
import { useDispatch } from 'react-redux';
import { ReactComponent as WorkFlow } from './../../../assets/workflow/road-arrow.svg';

const Metrics = (props) => {

  const search = (e) => {
    props.searchItems(e.target.value);
  };

  const metrixData = props.metrixData && props.metrixData.data?.data;

  const metrix1 = metrixData && metrixData?.map((g) => (
    <ListRow
      key={g.id}
      item={g}
      translate={props.translate}
      editMetricsForm={props.editMetricsForm}
      fetchMoreItems={props.fetchMoreItems}
    />
  ));

  return (
    <Style>
      {`
        .material-icons {vertical-align: middle; color: inherit}
      `}
      <div className='fms-sidebar sidebar-bg-layer'>
        {checkPrivileges('workflowmatrix') && (
          <div>
            <div className='section-head clearfix section-head-filter'>
              <ul className='filter-row clearfix pull-right'>
                <li>
                  <SearchField
                    label={props.translate('Search Metrics')}
                    fullWidth
                    onChange={(e) => search(e)}
                  />
                </li>
                <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                  {checkPrivileges('workflowmatrixCreate') && (
                    <Button size='small' onClick={() => props.openCreateFrom()}>
                      {props.translate('Create')}
                    </Button>
                  )}
                </li>
              </ul>
            </div>
            <ul className='list-view with-padding-right geofence-sidebar-list'>
              <Scrollbar
                disableTracksWidthCompensation={true}
                scrollerProps={{
                  renderer: (props) => {
                    const { elementRef, ...restProps } = props;
                    return <div {...restProps} ref={elementRef} id='scrollableDiv' />;
                  },
                }}
              >
                {props.itemPagination && props.itemPagination.items ? (
                  <InfiniteScroll
                    dataLength={props.itemPagination.items.length}
                    next={props.fetchMoreItems}
                    hasMore={props.itemPagination.hasNext}
                    scrollableTarget='scrollableDiv'
                    endMessage={props.endMessage}
                  >
                    {metrix1}
                  </InfiniteScroll>
                ) : null}
              </Scrollbar>
            </ul>
          </div>
        )}
      </div>
    </Style>
  );
};

const ListRow = (props) => {
  const [onDeleteConfirmation, setOnDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();

  const onDelete = () => {
    if (props?.item?.id) {
      setOnDeleteConfirmation(false);
      instance({
        url: `/api/workflows/matrix/${props?.item?.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          dispatch(
            Notifications.success({
              message: props.translate('savedMetrixIsDeleted'),
              autoDismiss: 10,
            })
          );
          setOnDeleteConfirmation(false);
          props.fetchMoreItems();
        })
        .catch((error) => {
          errorHandler(error,dispatch);
        });
    }
  };

  const onRemovedItem = () => {
    setOnDeleteConfirmation(true);
  };

  const onCancel = () => {
    setOnDeleteConfirmation(false);
  };

  return (
    <li className={'list-row clearfix'} key={props.item.id}>
      <label className='checkbox' onClick={() => props.editMetricsForm(props.item)}>
        <span className='unit-name' style={{ fontSize: 12 }}>
          {props.item.startPoint}
          <span>
            <WorkFlow
              style={{
                verticalAlign: 'middle',
                width: 50,
                height: 16,
                fill: '#00000',
                display: 'inline-block',
                marginTop: 2,
              }}
            />
          </span>
          {props.item.endPoint}
        </span>
      </label>
      <div className='callout-actions'>
        {checkPrivileges('workflowmatrixDelete') && (
          <span className='action' onClick={onRemovedItem}>
            <DeleteIcon className='material-icons' />
          </span>
        )}
      </div>
      <div className='callout-actions'>
        {checkPrivileges('workflowmatrixUpdate') && (
          <span className='action' onClick={() => props.editMetricsForm(props.item)}>
            <EditIcon className='material-icons' />
          </span>
        )}
      </div>

      {onDeleteConfirmation && (
        <ConfirmDialoag
          onCancel={onCancel}
          onOk={onDelete}
          title= {props.translate('areYouWantToDelete')}
          children={props.item.description}
        />
      )}
    </li>
  );
};



export default (withLocalize(Metrics));