import React, { useState} from 'react'
import SimpleModalWrapped from '../common/Modal'
import { withLocalize } from 'react-localize-redux'
import Grid from '@mui/material/Grid'
import TextField from '../common/TextField'
import Tooltip from '../common/Tooltip'
// import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'

const mapStateToProps = (state) => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themeColors
  }
}
export const userTypeArray = [
  {
    key: 'trailer',
    name: 'trailer'
  },
  {
    key: 'bin',
    name: 'Bin'
  },
  {
    key: 'passenger',
    name: 'Passenger'
  }
]

// const styles = theme => ({})

// class addTrailer extends Component {
  const addTrailer = (props) => {
  // constructor () {
  //   super()
  //   state = {
  //     attm_isVisable: false
  //   }
  //   addAttributes = addAttributes.bind(this)
  // }
  const [state, setState] = useState({
    attm_isVisable: false
  })

  const addAttributes =()=> {
    setState((prevState)=>({
      ...prevState,
      attm_isVisable: !state.attm_isVisable
    }))
  }

  // render () {
    const { selectedTrailers } = props
    const { form } = props
    return (
      <div style={{ height: '100%' }}>
        <SimpleModalWrapped
          visable
          isButtonVisable
          title='binder'
          formSubmit={props.formSubmit}
          addAttributes={addAttributes}
          modalControle={props.onClose}
          buttonText={props.buttonText}
          btnCloseText='sharedBack'
          isVisableBtn={props.isVisibleBtn}
          selectedItemId={selectedTrailers.uniqueId}
          attributeChangesMessage={props.attributeChangesMessage}
          isLoading={props.isLoading}
          notShowAttributesBtn
          content={
            <form autoComplete='off'>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                 <TextField
                    id='type'
                    select
                    label={props.translate('type')}
                    value={props.form.type || ''}
                    onChange={props.handleChange('type')}
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    required
                  >
                    {userTypeArray.length &&
                      userTypeArray.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                          {props.translate(option.name)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {props.form.type ?  
                  (<> 
                    <Grid container spacing={1}>
                      <Grid item sm={6} xs={12}>
                          <TextField
                            id='name'
                            label={form.type==='trailer'? props.translate('trailerName') :form.type==='bin' ? props.translate('binName') :props.translate('passengerName')}
                            required
                            placeholder=''
                            value={props.form.name}
                            onChange={props.handleChange('name')}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            id='uniqueId'
                            label={form.type==='trailer'? props.translate('trailerUniqueId') :form.type==='bin' ? props.translate('binUniqueId') :props.translate('passengerUniqueId')}
                            type='text'
                            required
                            placeholder=''
                            value={props.form.uniqueId}
                            onChange={props.handleChange('uniqueId')}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          id='department'
                          label={props.translate('sharedDescription')}
                          type='text'
                          placeholder=''
                          value={props.form.department}
                          onChange={props.handleChange('department')}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      
                      {props.editOption ?
                      <Grid item sm={6} xs={12}>
                        <input
                          type='file'
                          name='pic'
                          accept='image/*'
                          onChange={e => props.uploadImage(e)}
                        />
                        {props.form.trailerImg && (
                          <Tooltip
                            placement='right-end'
                            title={props.translate('clickForPreview')}
                          >
                            <img
                              style={{ width: 120, height: 'auto' }}
                              alt='trailerImage'
                              // src={props.form.trailerImg}
                              src={props.uploadingImage ? props.imagesData :  props.form.trailerImg || ''}

                              onClick={props.imagePopup}
                            />
                          </Tooltip>
                        )}
                      </Grid>:null}
                    </Grid>
                          
                    <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            format="dd/MM/yyyy"
                            margin='dense'
                            label={props.translate('expireDate')}
                            variant='outlined'
                            fullWidth
                            minDate={new Date()}
                            value={
                              props.form?.expirationTime
                                ? new Date(
                                    `${props.form.expirationTime}`
                                  ).toString()
                                : null
                            }
                            onChange={props.handleChange('expirationTime')}
                            InputProps={{
                              classes: {
                                root: 'theme-cssOutlinedInput',
                                input: 'theme-input',
                                focused: 'theme-cssFocused',
                                notchedOutline: 'theme-notchedOutline'
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: 'theme-label',
                                focused: 'theme-label-focused',
                                shrink: 'theme-label-shrink'
                              }
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      </Grid>


                    <h4 style={{ margin: '10px 0' }} className='with-border'>
                        {props.translate('tags')}
                   </h4>
                    <Grid container spacing={1}>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_1'
                          label={
                            props.logInUser.attributes &&
                            props.logInUser.attributes.bt1
                              ? props.logInUser.attributes.bt1
                              : props.translate('tag_1')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (props.form.tag_1 !== undefined
                              ? props.form.tag_1
                              : props.form.attributes.tag_1) || ''
                          }
                          onChange={e => props.changeAttribute2('tag_1', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_2'
                          label={
                            props.logInUser.attributes &&
                            props.logInUser.attributes.bt2
                              ? props.logInUser.attributes.bt2
                              : props.translate('tag_2')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (props.form.tag_2 !== undefined
                              ? props.form.tag_2
                              : props.form.attributes.tag_2) || ''
                          }
                          onChange={e => props.changeAttribute2('tag_2', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_3'
                          label={
                            props.logInUser.attributes &&
                            props.logInUser.attributes.bt3
                              ? props.logInUser.attributes.bt3
                              : props.translate('tag_3')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (props.form.tag_3 !== undefined
                              ? props.form.tag_3
                              : props.form.attributes.tag_3) || ''
                          }
                          onChange={e => props.changeAttribute2('tag_3', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_4'
                          label={
                            props.logInUser.attributes &&
                            props.logInUser.attributes.bt4
                              ? props.logInUser.attributes.bt4
                              : props.translate('tag_4')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (props.form.tag_4 !== undefined
                              ? props.form.tag_4
                              : props.form.attributes.tag_4) || ''
                          }
                          onChange={e => props.changeAttribute2('tag_4', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_5'
                          label={
                            props.logInUser.attributes &&
                            props.logInUser.attributes.bt5
                              ? props.logInUser.attributes.bt5
                              : props.translate('tag_5')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (props.form.tag_5 !== undefined
                              ? props.form.tag_5
                              : props.form.attributes.tag_5) || ''
                          }
                          onChange={e => props.changeAttribute2('tag_5', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                    </Grid>


                  </>): null}
              </Grid>
            </form>
          }
        />
        {/* {state.attm_isVisable && (
          <Attributes
            onCloseModal={addAttributes}
            itemAttributes={props.itemAttributes}
            changeAttribute={props.changeAttribute}
            formSubmit={ud_submit}
            componentType='trailer'
            isHaveAccess={props.isHaveAccess}
          />
        )} */}
      </div>
    )
  // }
}

export default connect(mapStateToProps)(
  withLocalize(addTrailer)
)

// const AddTrailer =  (styles)
// export default connect(mapStateToProps)(AddTrailer(withLocalize(addTrailer)))
