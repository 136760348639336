import React, { Component } from 'react';


import "./../Login/Style.scss";

import { LoginAsUser } from './Component';

export default class Loginasuser extends Component {

  render() {
    return (
      <div>
        <div className="loginWrapperInner">
          <LoginAsUser {...this.props} />
        </div>
        <div className="white-label-bg" style={{background: 'url(/assets/images/login_wallpaper.png)'}}></div>
      </div>
    );
  }
}
