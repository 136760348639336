import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import {
    // checkedDevelopmentType,
     getCookie, setCookie } from '../../Helpers'
import { connect } from 'react-redux'
// import './Style.scss'
import instance from '../../axios'
import ShareMap from '../../Components/Maps/ShareMap'
import { logInUserInfo } from '../../Actions/Users'
import Loader from '../../Layout/Loader'
import { getLayer } from '../../Actions/Maps'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'


class MonitoringShareLocation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      deviceData:null,
      userInfoFront: {},
      userFetch: false,
      userId: props.logInUser && props.logInUser.id ? props.logInUser.id : 0,
      initFetch: false,
      prepareTags: false,
      vehicleModal: false,
      driverModal: false,
      resourceList: false,
      linkResource: false,
      selecteditem: '',
      showAll:false,
      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        isFetching: true,
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: ''
      },
      boxes: [],
      sensorsDesc:[],
      showMonitoringTracks : false,
      routes : {},
      listType:"geofences",
      center:null,
      lastDeviceId:null,
      expirationTime: null,


    }
    this.map = null
  }
  determineAndDispatchLayer(userInfo) {
    const maps = userInfo.attributes?.maps || [];
    const mapLayer = maps.includes('googleRoad') ? 'googleRoad' : 'osm';
    this.props.dispatch(getLayer({ id: mapLayer}));
  }
  componentWillMount () {
    this.checkUserSession();
if(this.props.logInUser){

  const expirationTime = this.props.logInUser?.expirationTime;
  if (expirationTime) {
    this.setState({ expirationTime });
  }

}
  }

  checkUserSession = () => {
    const token = localStorage.getItem("backtoAdmin");

    if (!this.props.logInUser.id) {
      if (!token) {
        this.props.history.push('/login');
        return;
      }
      // const fetchUrl = checkedDevelopmentType();

      instance({
        url: `/api/session`,
        method: 'GET',
        headers: {
          UIP: getCookie('UIP'),
        },
        params: {
          token: token,
          app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
        },
      })
        .then(userInfoFront => {
          if (userInfoFront) {
            this.determineAndDispatchLayer(userInfoFront)
            this.props.dispatch(logInUserInfo(userInfoFront));
            const expirationTime = userInfoFront?.expirationTime;
            if (expirationTime) {
              this.setState({ expirationTime });
            }
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(() => {
          setCookie('JSESSIONID', 0);
          this.props.history.push('/logout', {
            from: this.props.history.location.pathname,
          });
        });
    } else {
      this.setState({ loading: false });
    }
  };







  setMapRef = ref => {
    this.map = ref
  }



componentDidMount() {
  const initialId = this.props.positions[0]?.deviceId;
  if (initialId) {
    this.fetchData(initialId);
  }
  this.startExpirationCheck();

}

componentDidUpdate(prevProps) {
  const prevDeviceId = prevProps.positions[0]?.deviceId;
  const currentDeviceId = this.props.positions[0]?.deviceId;

  if (currentDeviceId && currentDeviceId !== prevDeviceId) {
    this.fetchData(currentDeviceId);
  }

}
startExpirationCheck = () => {
  this.expirationInterval = setInterval(() => {
      const currentTime = moment(); // Get current time using moment
      const { expirationTime } = this.state;
      if (expirationTime) {
          const expirationDate = moment(expirationTime); // Parse expiration time with moment
          if (currentTime.isSameOrAfter(expirationDate)) {
              this.props.dispatch(
                  Notifications.error({
                      message: this.props.translate('The link you accessed is expired.'),
                      autoDismiss: 10
                  })
              );
              // this.props.dispatch(logoutUser());
              this.props.history.push('/logout');
          }
      }
  }, 1000);
};

componentWillUnmount() {
  clearInterval(this.expirationInterval); // Clear interval on unmount
}

fetchData = async (id) => {
  try {
    const response = await instance({
      method: 'GET',
      url: `/api/devices/${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.setState({ deviceData: response, lastDeviceId: id });

  } catch (error) {
    this.setState({ error });
  }
};


render () {

  // const {devices3, ...resetProps}  = this.props;
if(!this.state.loading){
  return(
    <>
    <ShareMap setMapRef={this.setMapRef} center={this.state.center} {...this.props} deviceData={this.state.deviceData}/>
    </>

    )
}else{
  return <Loader/>
}
  }
}


const mapState = state => ({
  driverList: state.drivers,
  themecolors: state.themeColors,
  positions: state.positions,
  vehicles:state.vehicles,
  allComputedAttributes: state.allComputedAttributes,
  mapLayer: state.mapLayer,
  logInUser: state.logInUsers,
  devicesFetched: state.devices.isFetching,
  devices: state.devices,
  devices3: state.devices3,
  trailers: state.trailers,
  tags: state.tags,
  driverTags: state.driverTags,
  allNotifications: state.allNotifications,
  deviceId: state.deviceId,
  trackId: state.trackId,
  drivers: state.drivers,
  ServerSetting: state.ServerSetting,
  filterTagsList: state.filterTagsList,
  filterDriversList: state.filterDriversList,
  filterList:
    state.filterList && state.filterList.filters
      ? state.filterList.filters
      : []

})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(MonitoringShareLocation))
