import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import L from 'leaflet'
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet'
import { connect } from 'react-redux'
import { updateLayer } from '../../Actions/Maps'
import CustomMarker from '../../Components/Maps/UpdatedMarker'
import { MapTileLayers } from '../../Reducers/Maps'
import Style from 'style-it'
import moment from 'moment'
import { setIconOnMap2 } from '../../Helpers'

const defaultSecond = 1;
class MarkerMap extends Component {
  constructor (props) {
    super(props)
    this.state = {
      zoom: 16,
      minZoom: 3,
      animationTime: defaultSecond,
      currentLayer: null,
      MarkerDOM: null
    }
    this.setBoundOptions = this.setBoundOptions.bind(this)
  }

  setBoundOptions () {
    return {}
  }

  componentWillReceiveProps(n) {
      if(n.lat && n.lng && this.map) {
        this.map.fitBounds([[n.lat, n.lng]]) 
        // this.setState({animationTime: n.animationTime})
        // this.map.setView([n.lat, n.lng], 16, {animate: true, duration: 1, easeLinearity: 1 });
      }
  }

  componentDidMount () {
    
    const userInfo = localStorage.getItem('userInfoFront');
    if(userInfo) {
      const u = JSON.parse(userInfo).map;
      this.props.dispatch(updateLayer({id: u}))
    }

    if(this.props.lat && this.props.lng && this.map) {
        this.map.setView([this.props.lat, this.props.lng], 16, {animate: true, duration: defaultSecond, easeLinearity: 1 });
    }
  }


  changeBaseLayer = event => {
    
    if(this.map) {
      const center = this.map.getCenter(); 
      this.map.options.crs = ['yandexMap', 'yandexSat'].includes(event.layer.options && event.layer.options.id) ? L.CRS.EPSG3395 : L.CRS.EPSG3857;
      this.map.setView(center);
    }
    
    this.setState({currentLayer: event.layer.options});
    
  }

  myRef = el => {
    if (el) {
      this.map = el
    }
  }
componentWillUnmount(){
  if(this.map){
    this.map=null
  }
}
  render () {
    let {deviceData}=this.props
    const thisMap = [
      <MapContainer
        ref={this.myRef}
        className={`${this.props.mapClass}`}
        key={2}
        center={[this.props.lat, this.props.lng]}
        zoom={this.state.zoom}
        zoomControl={false}
        maxZoom={16}
        onBaselayerchange={this.changeBaseLayer}
      >
        <LayersControl position="topleft">
          {MapTileLayers.map(layer => <LayersControl.BaseLayer id={layer.id} checked={layer.id === this.props.mapLayer.id} key={layer.id} name={layer.name}>
            <TileLayer {...layer} maxZoom={16} minZoom={this.state.minZoom} />    
          </LayersControl.BaseLayer>)}
        </LayersControl> 
        <CustomMarker
          position={{ lat: this.props.lat || this.props.latitude, lng: this.props.lng || this.props.longitude, updated: moment(this.props.serverTime) }}
          rotationAngle={0}
          rotationOrigin='center'
          color={this.props.color}
          animationTime={this.state.animationTime}
          tracking={this.props.trackId===this.deviceData?.id}
          trackId={this.props.trackId}
          speed={this.props.speed}
          motionStatus={this.props.motionStatus}
          logInUser={this.props.logInUser}
          eventHandlers={{click:this.props.onClick}}
          category={this.props.category || 'default'}
          status={this.props.motionStatus?.label}
          course={this.props.course}
          mapRef={this.map&&this.map}
          icon={L.divIcon({
            iconUrl:
              '/assets/category/default/' +
              (this.props.category || 'default') +
              'top.svg',
            iconSize: [50, 50],
            iconAnchor: [25, 25],
            tooltipAnchor: [0, -20],
            className: 'custom-marker',
            // html: setIconOnMap2(deviceData.category, status),
            html: this.props?.logInUser?.attributes?.unitsViewType === 'default' 
            ? `<img
              style="transform: rotate(${this.props.course}deg)"
                src=
                  '/assets/category/default/${this.props.category ||
                    'default'}top.svg'
                
                alt=''
              />` : `<p style="transform: rotate(${this.props.course}deg)">${setIconOnMap2(deviceData?.category,this.props.status,this.props.motionStatus,this.props?.logInUser?.attributes?.unitsViewType, this.props.color)}`,
          })}
          iconSize={[50, 50]}
            // html: `<img
            //   style="transform: rotate(${position.course}deg)"
            //     src=
            //       '/assets/category/default/${deviceData.category ||
            //         'default'}top.svg'
                
            //     alt=''
            //   />`
        >
        </CustomMarker>
      </MapContainer>
    ]

    


    return (
      <div style={{ position: 'relative', height: '100%' }}>
      <Style>{`
        .leaflet-control-layers-toggle {
            background: url('../../assets/maps/layers.png') no-repeat center;
        } 
      `}
      </Style>
        {['osm', ''].includes(this.props.mapLayer.id) && thisMap}
        {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id) &&
          thisMap}
        {['carto'].includes(this.props.mapLayer.id) && thisMap}
        {[
          'googleTerrain',
          'googleHybrid',
          'googleSatellite',
          'googleRoad',
          'gccStreet'
        ].includes(this.props.mapLayer.id) && thisMap}
        {['baidu'].includes(this.props.mapLayer.id) && thisMap}        
      </div>
    )
  }
}

const mapStateToProps = state => ({
  mapLayer: state.mapLayer
})

export default connect(mapStateToProps)(withLocalize(MarkerMap))
