import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '../../../Components/common/Button'
import SingleSelect from '../../../Components/common/SingleSelect';
import TextField from '../../../Components/common/TextField';
import { Translate } from 'react-localize-redux'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '../../../Components/common/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CrossIcons from '@mui/icons-material/Remove'
import { checkPrivileges } from '../../../Helpers';
import MenuItem from '../../../Components/common/MenuItem';

const trasmissionList=[
  {name:'Manual'},
  {name:'Automatic'},
  {name:'Semi-Automatic'},
  {name:'CVT'},
  {name:'Other'} 
]

const fuelTypeList=[
    {
      name:"Gasoline",
      value:"1"
    },
    {
      name:'Diesel',
      value:"2"
    },
    {
      name:'Ethanol',
      value:"3"
    },
    {
      name:'Hybrid',
      value:'4'
    },
    {
      name:'Electric',
      value:"5"
    },
    {
      name:'Biodiesel',
      value:'6'
    },
    {
      name:'Compressed Natural Gas (CNG)',
      value:'7'
    },
    {
      name:'Liquefied Petroleum Gas (LPG)',
      value:'8'
    },
    {
      name:'Hydrogen',
      value:'9'
    }
]
export default function UnitSelectionForm (props) {
  return (
    <div>
      <div style={{ textAlign: 'right', padding: '16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('Assign Vehicle')}
        </h4>
      </div>
      <Grid container spacing={2} style={{padding:"0px 10px"}}>
          {/* <div style={{ padding: '0 16px' }}>
             <Grid item xs={12} md={3} sm={6}>
                 <SingleSelect
                    array={props.vehicles || []}
                    selectName='selectVehicle'
                    label={props.translate('selectVehicle')}
                    isClearable
                    value={props.selectedvehicle ? 
                      {
                        id: props.selectedvehicle.id,
                        label: props.selectedvehicle.label,
                        value: props.selectedvehicle.id,
                      }
                      : ''
                    }
                    handleChange={props.handleChangeVehicle}
                    canAssign
                  />
            </Grid></div>

            <div style={{ padding: '0 16px' }}>
               <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    disabled={!props.enableAssingBtn}
                    onClick={props.onSubmit}
                  >
                    {props.translate('sharedSave')}
                  </Button>
              </Grid>
            </div> */}

          <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='label'
                    label={<Translate id='label' />}
                    required
                    placeholder=''
                    value={props?.form?.label || ''}
                    onChange={e => props.handleChange('label', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vehicleLicensePlate'
                    label={<Translate id='vehicleLicensePlate' />}
                    required
                    placeholder=''
                    value={props.form?.vehicleLicensePlate || ''}
                    onChange={e =>
                      props.handleChange(
                        'vehicleLicensePlate',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='country'
                    label={<Translate id='country' />}
                    placeholder=''
                    value={props.form?.attributes?.country || ''}
                    onChange={e => props.handleChangeForAttributesValues('country', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='registrationNumber'
                    label={<Translate id='registrationNumber' />}
                    placeholder=''
                    value={props.form?.attributes?.registrationNumber || ''}
                    onChange={e => props.handleChangeForAttributesValues('registrationNumber', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='variant'
                    label={<Translate id='variant' />}
                    placeholder=''
                    value={props.form?.attributes?.variant || ''}
                    onChange={e => props.handleChangeForAttributesValues('variant', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='wheels'
                    label={<Translate id='wheels' />}
                    placeholder=''
                    value={props.form?.attributes?.wheels || ''}
                    onChange={e => props.handleChangeForAttributesValues('wheels', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                   <SingleSelect
                      array={trasmissionList || []}
                      selectName='trasmissionType'
                      label={<Translate id='trasmissionType' />}
                        value={props.form&&props.form.attributes&&props.form.attributes.trasmissionType
                          ? { id:props.form.attributes.trasmissionType,
                              label:props.form.attributes.trasmissionType,
                              value: props.form.attributes.trasmissionType,
                            }
                          : ''
                        }
                      handleChange={props.handleChangeForAttributesValues}
                      canRemove={true}
                      canAssign={true}
                      />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='make'
                    label={<Translate id='make' />}
                    placeholder=''
                    value={props.form?.attributes?.make || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'make',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='model'
                    label={<Translate id='model' />}
                    placeholder=''
                    value={props?.form?.model || ''}
                    onChange={e => props.handleChange('model', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='year'
                    label={<Translate id='year' />}
                    placeholder=''
                    value={props.form?.attributes?.year || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'year',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='color'
                    label={<Translate id='attributeColor' />}
                    placeholder=''
                    value={props.form?.attributes?.color || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'color',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <TextField
                id='fuelType'
                label={<Translate id='fuel_type' />}
                type='text'
                value={
                  (props.form?.fuelType &&
                    props.form?.fuelType) ||
                  ''
                }
                onChange={(e)=>props.handleChange('fuelType',e)}
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                {
                  fuelTypeList?.map((fuel)=>(
                      <MenuItem key={fuel.value} value={fuel.value}>{fuel.name}</MenuItem>
                    )
                  )
                }

              </TextField>
              </Grid>
                {/*
                 <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='fuel_type'
                    label={<Translate id='fuel_type' />}
                    placeholder=''
                    value={props.form?.attributes?.fuel_type || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'fuel_type',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='capacity'
                    label={<Translate id='capacity' />}
                    placeholder=''
                    value={props.form?.attributes?.capacity || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'capacity',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='garage'
                    label={<Translate id='garage' />}
                    placeholder=''
                    value={props?.form?.garage || ''}
                    onChange={e => props.handleChange('garage', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                {/* <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  style={{ postion: 'relative', zIndex: 2 }}
                >
                  <SearchDevices
                    isMulti={false}
                    api='devices'
                    isClearable
                    onChange={props.onChangedDevice}
                    value={props?.vehicleTracker}
                    placeholder={<Translate id='searchUnits' />}
                  />
                </Grid> */}

                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vehicleType'
                    label={<Translate id='sharedType' />}
                    placeholder=''
                    value={props?.form?.vehicleType || ''}
                    onChange={e => props.handleChange('vehicleType', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vin'
                    label={<Translate id='vin' />}
                    placeholder=''
                    value={props?.form?.vin || ''}
                    onChange={e => props.handleChange('vin', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='chassisNumber'
                    label={<Translate id='chassisNumber' />}
                    placeholder=''
                    value={props.form?.attributes?.chassisNumber || ''}
                    onChange={e =>
                      props.handleChangeForAttributesValues(
                        'chassisNumber',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>

                {checkPrivileges('area') ?
                <Grid item md={4} sm={6} xs={12}>
                   <SingleSelect
                      array={props?.areaList || []}
                      selectName='areaId'
                      label={<Translate id='sharedArea' />}
                        value={props.form&&props.form.areaId&&props.form.areaId.key
                          ? {
                              id: props.form.areaId.key,
                              value: props.form.areaId.key,
                              label: props.form.areaId.name,
                              uniqueId:props.form.areaId.uniqueId,
                            }
                          : ''
                        }
                        // value={props.form.vehicleType || ''}
                      // onChange={e => props.handleChange('vehicleType', e)}
                      handleChange={props.handleChange}
                      canRemove={true}
                      canAssign={true}
                      />
                </Grid>
                :null}
                {/* start          -----------pending task from backEnd--------- Expiration Date*/}

                <Grid item md={4} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      error={
                        props?.form?.expirationTime &&
                        props?.form?.expirationTime <
                          moment().startOf('day').toISOString()
                      }
                      helperText={
                        props?.form?.expirationTime &&
                        props?.form?.expirationTime <
                          moment().startOf('day').toISOString()
                          ? <Translate id='licenseExpired' />
                          : ''
                      }
                      margin='dense'
                      label={<Translate id='V/LExpirationTime' />}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        props?.form?.expirationTime
                          ? new Date(
                              `${props.form.expirationTime}`
                            ).toString()
                          : null
                      }
                      onChange={props.handleChangeLicenseExpDate('expirationTime')}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title={<Translate id='noLimit' />}>
                              <IconButton
                                onClick={e => props.setExpirationTime(e)}
                                style={{ color: 'inherit' }}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                
                  {/*---end--- */}

                  {/* ------state-------------upload pdf file-----pending task from backEnd
                <Grid item md={4} sm={6} xs={12}>
                  <Tooltip title='Upload pdf'>
                    <Button
                      style={{ float: 'right', maxWidth: '40px',minWidth: '40px' }}
                      variant='contained'
                      margin='dense'
                      component="label"
                      onChange={e => uploadFile(e)}
                    >
                      <PublishIcon  style={{ color: '#fff'}}/>
                      <input
                        type="file"
                        hidden
                      />
                    </Button>
                  </Tooltip>
                </Grid>
                ----end---- */}
                {/* <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='permittedSpeed'
                    type='number'
                    label={<Translate id='permittedSpeed' />}
                    placeholder=''
                    value={props.form.attributes.permittedSpeed || ''}
                    onChange={e =>
                      props.handleChangeForAttributesValues(
                        'permittedSpeed',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                  <Grid item xs={12}>
                  <h3 style={{ margin: 0 }} className='with-border'>
                    {<Translate id='tags' />}
                  </h3>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  {props?.form?.attributes?.tags}
                  <TextField
                    id='tag_1'
                    label={
                      props?.logInUsers?.attributes &&
                      props?.logInUsers?.attributes.vt1
                        ? props.logInUsers.attributes.vt1
                        : <Translate id='tag_1' />
                    }
                    placeholder=''
                    value={props?.form?.attributes?.tag_1 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_1',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_2'
                    label={
                      props?.logInUsers?.attributes &&
                      props?.logInUsers?.attributes.vt2
                        ? props.logInUsers.attributes.vt2
                        : <Translate id='tag_2' />
                    }
                    placeholder=''
                    value={props?.form?.attributes?.tag_2 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_2',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_3'
                    label={
                      props?.logInUsers?.attributes &&
                      props?.logInUsers?.attributes.vt3
                        ? props.logInUsers.attributes.vt3
                        : <Translate id='tag_3' />
                    }
                    placeholder=''
                    value={props?.form?.attributes?.tag_3 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_3',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_4'
                    label={
                      props?.logInUsers?.attributes &&
                      props?.logInUsers?.attributes.vt4
                        ? props.logInUsers.attributes.vt4
                        : <Translate id='tag_4' />
                    }
                    placeholder=''
                    value={props?.form?.attributes?.tag_4 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_4',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_5'
                    label={
                      props?.logInUsers?.attributes &&
                      props?.logInUsers?.attributes.vt5
                        ? props.logInUsers.attributes.vt5
                        : <Translate id='tag_5'/>
                    }
                    placeholder=''
                    value={props?.form?.attributes?.tag_5 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_5',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div style={{ padding: '0 16px' }}>
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                      <Button
                        disabled={!props.enableAssingBtn}
                        onClick={props.onSubmit}
                      >
                        {props.translate('sharedSave')}
                      </Button>
                  </Grid>
                </div>
             </Grid>

             </Grid>

    </div>
  )
}
