import React, { Component } from 'react'
// import axios from 'axios';
// import Tooltip from '@mui/material/Tooltip'
// import MenuItem from '../common/MenuItem'
// import Checkbox from '../common/Checkbox'
// import { errorHandler } from '../../Helpers';
// import CustomDialog from './Dialog';
import Stepper from './Stepper'
import moment from 'moment';
import DateRangePicker from './DateRangePicker';
// import TextField from './TextField';
import { Grid } from '@mui/material';
import Loader from '../../Layout/Loader';
// import Button from './Button';
import VehicleSelector from '../Devices/VehicleSelector'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Menu from '../Menu/CommonMenu'
import AreaSelector from './AreaSelector';
import Scrollbar from 'react-scrollbars-custom'

export default  class MaintenanceFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openFilterModal:false,
      from: '',
      to: '',
      selectedDate: '',
      vehicleId:[],
      areaId:[],
      loader:false,
      countChecked: 0,
      anchorEl: null,
      selectedvehicleId:[],
      selectedAreaId:[],
      steps: [{id: 1, title: 'selectServiceTimeControl'}, {id: 2, title: 'selectVehicles'},{id:3, title:'selectAreaIds'}],
      activeStep: 0,
      reportType:0,
      isSubmitBtn:false
    }
  }


  onCloseDueMaintenanceModel = () =>{
    this.setState({
      openFilterModal:false,
      to:'',
      from:'',
      isSubmitBtn:false,
      selectedDate:'',
      // from: '',
      // to: '',
      vehicleId:[],
      areaId:[],
      countChecked: 0,
      anchorEl: null,
      activeStep:0,
    })
  }

  showDates =(e, date)=> {
    // const duration = moment.duration((moment(this.state.to)).diff(this.state.from));
    // const hours = duration.asHours();
    let timezone = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }

    let timeFormat = 'HH:mm'

    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = 'hh:mm A'
    }

    this.setState(
      {
        selectedDate:
          moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
          ' - ' +
          moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
        ...formatedDate
      })
  }

  handleChange =(name, e)=>{
      if(e ==='area'){
          this.setState(
            {
              areaId: name
            })
            this.setState({areaId: name },()=>{  
              let filter = '';
                Object.entries(this.state.areaId).map(([key,value])=>{// eslint-disable-line no-unused-vars
                  if(value){
                    filter += '&areaId=' + value
                    this.setState({
                    })
                  }
                })
                this.setState({
                  selectedAreaId: filter
                })
            })
      }
      else{
        this.setState(
          {
              vehicleId: name
          })
          this.setState({vehicleId: name },()=>{  
            let filter = '';
              Object.entries(this.state.vehicleId).map(([key,value])=>{// eslint-disable-line no-unused-vars
                if(value){
                  filter += '&vehicleId=' + value
                  this.setState({
                  })
                }
              })
              this.setState({
                selectedvehicleId: filter
              })
          })
      }
  }

  goToNextStep = (step, type, index) => {
    if(step === 3){ 
        this.setState({
          openFilterModal:false,
        }, ()=>{
          this.props.formSubmit(this.state)
          // this.onCloseDueMaintenanceModel()
      })
    }
    else{
      this.validatedCurrentStep(step, type, index);
    }
  }
  goToBackStep = () => {
    const currentStep = this.state.activeStep;
      this.setState({activeStep: currentStep-1}) 
  }

  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1) {
      if(this.state.to && this.state.from){
          validated = true
          errorMsg= null
        }
        else {
          validated = false
          errorMsg= <span className="text-warning">Please select &apos;Date and Maintenance Status&apos; from above</span>
        }
    }
    else if(step === 2) {
          validated = true
    }

    if(validated === true) {
      this.setState({activeStep: index + 1}, ()=>{
        if(this.state.activeStep===2){
          this.setState({isSubmitBtn:true})
        }})
    }

    this.state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else steps.push(s)
      
      return null
    })

    this.setState({steps})

  }

  getStepContent = (id) => {
    switch (id) {
      case 1:
        return (<Grid container className='form-group sidebar-bg-layer'>
        <Grid item xs={12}>
          <DateRangePicker
            fill
            error={ this.props.timeError || false}
            selectedDate={this.state.selectedDate}
            label={
              this.props.translate('reportFrom') +
              ' ' +
              this.props.translate('reportTo')
            }
            onEvent={this.showDates}
          />
        </Grid>
      </Grid>)
    case 2: 
    return (<Grid container className='form-group'>
      <Grid item  xs={12}>
          <VehicleSelector
            api='vehicle'
            fill
            hideIcons
            isClearable
            placeholder={this.props.translate('searchVehicle')}
            onChange={this.handleChange}
            value={this.state?.vehicleId||[]}
          />
      </Grid>
    </Grid>)
    case 3: 
      return (<Grid container className='form-group'>
       <Grid item  xs={12}>
            <AreaSelector
                api='area'
                fill
                hideIcons
                isClearable
                placeholder={this.props.translate('searchArea')}
                onChange={this.handleChange}
                value={this.state?.areaId||[]}
              /></Grid>
    </Grid>)
    default:
        return null
    }
  }
  

  render () {
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {<>
            <Stepper 
                translate={this.props.translate}
                activeStep={this.state.activeStep} 
                reportType={this.state.reportType} 
                getStepContent={this.getStepContent} 
                steps={this.state.steps}
                handleNext={this.goToNextStep} 
                handleBack={this.goToBackStep} 
                isSubmitBtn={this.state.isSubmitBtn}  
                />
          </>
        }
      </form>
    )

    return (
      <div className='geofence-sidebar-list'>
        {
          !this.props.loader ?(
            <Scrollbar>
            {viewScreen}
          </Scrollbar>
          ):<Loader/>
        }
      </div>
  )
  }
}