import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Style from "style-it";
import { useSelector } from 'react-redux';

const CustomButton = (props) => {

	const themecolors = useSelector(state => state.themeColors)
	const { normal, className, ...restProps } = props;

	return <Style>
	{`
		.theme-button {
			background: ${themecolors&&themecolors.themeLightColor};
			color: ${themecolors&&themecolors.themeLightInverse};
			min-width: 50px;
			padding: 3px 5px;
			border-radius: 5px
		}
		.button-inactive {
			opacity: 0.6
		}
		.theme-button.active {
			opacity: 1
		}
		.theme-button:hover {
			background: ${themecolors&&themecolors.themeLightColor};
		}
		.theme-button.active {
			color: ${themecolors&&themecolors.themeLightInverse};
			background: ${themecolors&&themecolors.themeLightColor};
		}
		.theme-button.active:hover{
			color: ${themecolors&&themecolors.themeLightInverse};
			background: ${themecolors&&themecolors.themeLightColor};
		}
		.button-dark {
			background: ${themecolors&&themecolors.themeDarkColor};
			color: ${themecolors&&themecolors.themeInverse};
		}
		.theme-button.text-left {
			justify-content: start;
		}
		.button-white {
			background: #fff;
			color: #333;
		}
		
		.button-grey {
			background: #f5f5f5;
			color: #333;
		}
		
		.button-grey:hover {
			background: #f5f5f5;
			color: #333;
		}
		
		.button-white:hover {
			background: #fafafa;
		}
		.theme-button.alter {
			background: #f0ad4e;
			color: #fff;
		}
		.theme-button:disabled {
			background: #ebebeb;
			color: #acacac;
		}
		
	`}
		{normal ? (
				<Button {...restProps} className={`${className} theme-button`} size="small" disabled={props.disabled}/>
			) : (
				<Button {...restProps} className={`${className} theme-button`} classes={{ sizeSmall: 'button-small' }} size="small" disabled={props.disabled} />
			)}
		</Style>
}


CustomButton.propTypes = {
	className: PropTypes.string,
	normal: PropTypes.bool,
};
 
 export default CustomButton;