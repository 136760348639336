import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
import { fetchMoreDevices, searchDevices } from '../../Actions/Devices'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Layout/Loader'
import { setAttributeFormat } from '../../Helpers'

const DeviceSelector = (props) => {

  const [state, setState] = useState({
    list: {},
    others: [],
    search: '',
    isChecked: false
  })

  useEffect(() => {
    props.dispatch(fetchMoreDevices(true, props.rows || 10))

    const list = {}
    const others = []
    if (props.value && props.value.length && props.devices3 && props.devices3.length) {
      props.value.map(d => {
        const found = props.devices3.find(dev => dev.id === parseInt(d));
        if (found) {
          list[d] = true
        }
        else {
          others.push(d)
        }
        return null
      })
    }
    setState((prevState) => ({ ...prevState, list, others }))
  }, [])

  // useEffect(() => {
  //   const newList = {}
  //   const newOthers = []
  //   if (props.value && props.value.length && props.devices3 && props.devices3.length) {
  //     props.value.forEach(d => {
  //       const found = props.devices3.find(dev => dev.id === parseInt(d));
  //       if (found) {
  //         newList[d] = true;
  //       } else {
  //         newOthers.push(d);
  //       }
  //     });
  //   }
  //   setState((prevState) => ({ ...prevState, list:newList, others:newOthers }))
  // }, [])

  useEffect(() => {
    return () => {
      setState((prevState) => ({
        ...prevState,
        list: {},
        search: "",
        isChecked: false
      }))
    }
  }, [])


  const sendToParent = () => {
    if (props.onChange) {
      props.onChange([...Object.keys(state.list), ...state.others])
    }
  }
  useEffect(() => {
    sendToParent()
  }, [state.isChecked,state.list])

  useEffect(() => {
    const displayedDevices = state.search ? props.devicesList?.data : props.devices3;
    const displayedDeviceIds = displayedDevices?.map(device => device.id) || [];
    const selectedDeviceIds = Object.keys(state.list)?.map(id => Number(id));
    const areAllDisplayedDevicesSelected = displayedDeviceIds?.every(id => selectedDeviceIds.includes(parseInt(id)));
    const isAllChecked = displayedDeviceIds?.length > 0 && areAllDisplayedDevicesSelected;
    if (state.isChecked !== isAllChecked) {
        setState(prevState => ({ ...prevState, isChecked: isAllChecked }));
    }
}, [props.devicesList, props.devices3, state.list, state.search]);




  const onChange = (e, v) => {
    let list = { ...state.list }
    if (e.target.checked) {
      list[v.id] = true
    } else {
      delete list[v.id]
    }
    setState((prevState) => ({
      ...prevState,
      list,
      isChecked: props.devicesList.length === Object.keys(list).length
    }))
  }

  const searchItem = text => {
    setState((prevState) => ({ ...prevState, search: text.target.value }))
  }

  useEffect(() => {
    props.dispatch(searchDevices({ device: state.search }))
  }, [state.search])

  const toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      let items = []
      if (state.search) {
        items = props.devicesList.data
      } else {
        items = props.devices3
      }
      items.map(d => (list[d.id] = true))
      setState((prevState) => ({
        ...prevState,
        list,
        isChecked: true
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        list: {},
        isChecked: false
      }))
    }
  }
  
  
  // const millisecondsToHMS=(milliseconds)=> {
  //   let totalSeconds = Math.floor(milliseconds / 1000);
  //   let hours = Math.floor(totalSeconds / 3600);
  //   let minutes = Math.floor((totalSeconds % 3600) / 60);
  //   let seconds = totalSeconds % 60;

  //   // Pad the values to ensure two digits
  //   hours = String(hours).padStart(2, '0');
  //   minutes = String(minutes).padStart(2, '0');
  //   seconds = String(seconds).padStart(2, '0');

  //   return `${hours}:${minutes}:${seconds}`;
  // }

    const endMessage =
      props.devicesList && props.devicesList.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {props.translate('notFound')}{' '}
        </p>
      )
    return (
      <Style>
        {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
            background: ${props.themecolors.themeLightColor};
            color: ${props.themecolors.themeLightInverse};
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            background: ${props.themecolors.themeListingColor};
          }
          .selector-list {
            padding: 0;
            margin: 0;
            color: ${props.themecolors.themeListingInverse};
            list-style: none
          }
          .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            padding: 2px 4px;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px;
            display: flex;
            justify-content: space-between
        }`}
        <div style={{ width: '100%' }}>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className='filter-row pull-left'>
              <li>
                <SearchField
                  label={props.translate('searchUnits')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={state.search}
                  onChange={searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {!props.readOnly && !props.singleSelect ? (
                <li style={{ paddingRight: 0, paddingTop: 3 }}>
                  <Checkbox
                    checked={state.isChecked}
                    onChange={toggleItems}
                    label={props.translate('selectAll')}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!props.readOnly && !props.singleSelect ? (
            <div className='selector-count'>
              <span><span style={{ marginRight: 3 }}>{Object.keys(state.list).length}</span>
                {props.translate('UnitsSelected')}</span>

              <span><span style={{ marginRight: 3 }}>{Object.keys(state.others).length}</span>
                {props.translate('more')}</span>
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              height: 32 * (props.rows || 10)
            }}
            scrollerProps={{
              renderer: propss => {
                const { elementRef, ...restProps } = propss
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id='scrollableDeviceSelector'
                  />
                )
              }
            }}
          >
            {props.devicesList.total ? (
              <InfiniteScroll
                dataLength={props.devicesList.data.length}
                next={() => props.dispatch(fetchMoreDevices())}
                hasMore={props.devicesList.hasNext}
                loader={<Loader defaultStyle={true} />}
                scrollableTarget='scrollableDeviceSelector'
                endMessage={endMessage}
              >
                <ul className='selector-list'>

                  {props.devicesList.data.map(row => {

                    const currentTime = new Date(); // Get the current time
                    const lastUpdateTime = new Date(row.lastUpdate); // Convert lastUpdate to a Date object
                    // Calculate the time difference in milliseconds
                    const timeDifference = currentTime.getTime() - lastUpdateTime.getTime();

                    const getVehicle = props.positions?.find(item => item.deviceId === row.id)

                    return (
                      <li key={row.id}>
                        <label className={state.list[row.id] ? 'active' : null}>
                          {!props.readOnly ? (
                            <span className='selector-check'>
                              <Checkbox
                                checked={state.list[row.id]}
                                value={row}
                                onChange={onChange}
                              />
                            </span>
                          ) : null}
                          {props.hideIcons ? null : (
                            <span className='selector-image'>
                              <img
                                src={'/assets/category/default/' + (row.category || 'default') + '.svg'}
                              // alt={row.name}
                              />
                            </span>
                          )}
                          <span className='selector-label' style={{ padding: 0, marginLeft: 6 }}>{row.name}</span>
                          {props.dashboradDetails && (<span style={{ marginLeft: "5px" }}> | {props.motionSelectorName === "Moving" ?
                            setAttributeFormat('speed', getVehicle?.speed) :
                            props.motionSelectorName === "Idling" ||
                              props.motionSelectorName === "Towing" ?
                              setAttributeFormat('hours', timeDifference) :
                              props.motionSelectorName === "statusParking" ||
                                props.motionSelectorName === "stop" ?
                                setAttributeFormat("hours", getVehicle?.attributes?.stopTime) :
                                props.motionSelectorName === "statusOffline" ||
                                  props.motionSelectorName === "notRegistered" ||
                                  props.motionSelectorName === "powerCut" ||
                                  props.motionSelectorName === "gpsNotUpdated" ?
                                  <>  </> :
                                  ""
                          }
                          </span>)}
                        </label>
                      </li>
                    );
                  })}
                  {/* {props.devicesList.data.map(row => (
                    <li key={row.id}>
                      <label
                        className={state.list[row.id] ? 'active' : null}
                      >
                        {!props.readOnly ? (
                          <span className='selector-check'>
                            <Checkbox
                              checked={state.list[row.id]}
                              value={row}
                              onChange={onChange}
                            />
                          </span>
                        ) : null}
                        {props.hideIcons ? null : (
                          <span className='selector-image'>
                            <img
                              src={
                                '/assets/category/default/' +
                                (row.category || 'default') +
                                '.svg'
                              }
                              // alt={row.name}
                            />
                          </span>
                        )}
                        <span className='selector-label'>{row.name}</span>
                        <span className='selector-label'>{row.lastUpdate}</span>
                      </label>
                    </li>
                  ))} */}
                </ul>
              </InfiniteScroll>
            ) : props.devicesList.hasNext ||
              props.devicesList.isFetching ? (
              <Loader defaultStyle={true} />
            ) : null}
          </Scrollbar>
        </div>
      </Style>
    )
  }

const mapState = state => ({
  devices3: state.devices3,
  devicesList: state.devices,
  themecolors: state.themeColors,
  positions: state.positions
})

const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(DeviceSelector))
