import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import instance from '../../axios';
import {
  logInUserInfo
} from './../../Actions/Users';
import { getLayer } from './../../Actions/Maps';
import Loader from './../../Layout/Loader';
import {
  // checkedDevelopmentType,
  getCookie,
} from '../../Helpers/index';

import { themes } from '../../Reducers/Theme'
import SocketController from '../../SocketController';
import Notifications from 'react-notification-system-redux';

class UserLocationShareComponents extends Component {
    constructor (props) {
        super(props)
        this.state = { loading: false, loginErrorMessage: '' }
    }

    componentDidMount() {
        this.setState({ loading: true, loginErrorMessage: '' });
        this.loginAPiCall();
    }


    determineAndDispatchLayer(userInfo) {
      const maps = userInfo.attributes?.maps || [];
      const mapLayer = maps.includes('googleRoad') ? 'googleRoad' : 'osm';
      this.props.dispatch(getLayer({ id: mapLayer}));
    }
    
    goToLogoutpage = () => {
      localStorage.removeItem('backtoAdmin')
      localStorage.removeItem("userInfoFront")
      this.props.history.push('/logout')
    }

    loginAPiCall = () => {
        const { location, history, dispatch } = this.props;
        if (location.search) {
            let query = location.search.toString().split("&")
            const token = query[1];
            // const fetchUrl = checkedDevelopmentType();

            if (token) localStorage.setItem('backtoAdmin', token);

            instance({
                url: `/api/session`,
                method: 'GET',
                headers: {
                    UIP: getCookie('UIP')
                },
                params: {
                    token: token,
                    app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
                    // host: fetchUrl
                }
            })
            .then(userInfoFront => {
                if (userInfoFront) {
                        dispatch(logInUserInfo(userInfoFront))
                        this.determineAndDispatchLayer(userInfoFront)
                        history.push('/monitoringnew');
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
              this.goToLogoutpage()
              dispatch(Notifications.error({message:"The link has expired", autoDismiss: 5}))
                this.setState({ loading: false, loginErrorMessage: "error"});
            });
        }
    }

    render() {
        const { loading } = this.state;
        const { logInUser } = this.props;
        if (loading) {
          return (
            <div className='loginLoaderWrapper'>
             <Loader/>
            </div>
          );
        } else {
          return (logInUser && logInUser.id) ? (
            <SocketController/>
          ) : null;
        }
      }
    }

const mapState = state => {
    return {
        themecolors: themes[2],
        devices: state.devices.data,
        ServerSetting: state.ServerSetting,
        logInUser: state.logInUsers
    }
}

const mapStateToProps = connect(mapState)

export const UserLocationShareComponent = mapStateToProps(withLocalize(UserLocationShareComponents));


