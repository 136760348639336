import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Style from 'style-it'
import { useSelector } from 'react-redux';


const CustomTextField = (props) => {
  const themecolors = useSelector(state => state.themeColors)

  const { classes, InputProps, InputLabelProps, multiline, modalPad, ...restProps } = props;
    return (
      <Style>
        {`
        .theme-label {
          margin-top: -9px;
          font-size: 12px;
          color: ${themecolors.textColor}
        }
        
        .theme-label-shrink {
          margin-top: 0
        }
        .theme-label-focused {
          margin-top: 0;
        }
        .theme-textarea {
          font-size: 14px;
          padding: 0
        }
        .theme-label-error {
          color: ${themecolors.error}
        }
        .theme-notchedOutline {
          border-color:  ${themecolors&&themecolors.themeLightColor} !important;
          border-radius: 4px;
        }
        .theme-input-error .theme-notchedOutline {
          border-color:  ${themecolors.error} !important;
        }
        .theme-cssFocused .theme-notchedOutline {
          border-color:  ${themecolors&&themecolors.themeLightColor} !important;
        }
        .theme-input-select {
          border-color: ${themecolors&&themecolors.themeLightColor};
          color: ${themecolors.textColor};
          padding: 0;
          display: flex;
          height: auto
        }
        .theme-input {
          border-color: ${themecolors&&themecolors.themeLightColor};
          color: ${themecolors.textColor};
          font-size: 12px;
          padding: ${modalPad ? "5px 29px 5px 8px" : "5px 14px"};
          min-height: 18px
        }
        .menu-list-icon {
          color: ${themecolors.textColor};
        }
        .custom-paper {
          background-color: ${themecolors&&themecolors.themeLightColor};
        }
      `}
        <div>
          <TextField
            margin='dense'
            {...restProps}
            variant='outlined'
            classes={classes}
            InputProps={{
              ...InputProps,
              classes: {
                root: 'theme-cssOutlinedInput',
                error: 'theme-input-error',
                input: multiline ? 'theme-textarea' : 'theme-input',
                focused: 'theme-cssFocused',
                notchedOutline: 'theme-notchedOutline'
              }
            }}
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: 'custom-menu-paper'
                }
              },
              classes: {
                icon: 'menu-list-icon'
              }
            }}
            InputLabelProps={{
              ...InputLabelProps,
              classes: {
                root: 'theme-label',
                focused: 'theme-label-focused',
                shrink: 'theme-label-shrink'
              }
            }}
          />
        </div>
      </Style>
    )
  }

  CustomTextField.propTypes = {
    classes: PropTypes.object,
    InputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    multiline: PropTypes.bool,
    modalPad: PropTypes.bool,
  };

export default CustomTextField;
