import React, { useState, useEffect } from 'react'
import {
  prepareVehicleTags,
  applyTagsFilter,
  resetFilters
} from '../../../Actions/Devices'
import Dialog from './../../../Components/common/Dialog'
import MenuItem from './../../../Components/common/MenuItem'
import TextField from './../../../Components/common/TextField'
import Button from './../../../Components/common/Button'
import { connect } from 'react-redux'

// class VehicleTagSelector extends React.PureComponent {
  const VehicleTagSelector = (props) => {
    const [state, setState] = useState({
      tag_1: '',
      tag_2: '',
      tag_3: '',
      tag_4: '',
      tag_5: ''
    })

  // componentDidMount () {
  //   props.dispatch(prepareVehicleTags())
  // }

  useEffect(() => {
    props.dispatch(prepareVehicleTags())
  }, [])
  

  const onChange = (name, value) => {
    setState({
      [name]: value
    })
  }

  const cancelFilter = () => {
    props.cancelFilter()
  }
  const saveFilter = () => {
    const filter = { ...state }

    if (!filter.tag_1) delete filter.tag_1
    if (!filter.tag_2) delete filter.tag_2
    if (!filter.tag_3) delete filter.tag_3
    if (!filter.tag_4) delete filter.tag_4
    if (!filter.tag_5) delete filter.tag_5
    const vehicles = props.vehicles && props.vehicles.filter(item => {
      for (var key in filter) {
        if (
          item.attributes[key] === undefined ||
          item.attributes[key] !== filter[key]
        )
          return false
      }
      return true
    })
    props.dispatch(resetFilters())
    if (vehicles && vehicles.length) {
      const list = vehicles.map(v => v.deviceId)
      props.dispatch(applyTagsFilter(list))
    } else {
      props.dispatch(applyTagsFilter(['sxskaasd']))
    }
    props.saveFilter()
  }

  // render () {
    const { vt1, vt2, vt3, vt4, vt5 } = props.userTags
    const { tag_1, tag_2, tag_3, tag_4, tag_5 } = props.tags
    const { translate, vehicleModal } = props

    return vehicleModal ? (
      <Dialog
        visable
        onClose={cancelFilter}
        maxWidth='xs'
        title={props.translate('vehicleFilter')}
        isVisableBtn
        headerActions={<Button onClick={saveFilter}>Save</Button>}
      >
        <div style={{ padding: 15 }}>
          <TextField
            fullWidth
            label={vt1 || translate('tag_1')}
            select
            onChange={e => onChange('tag_1', e.target.value)}
            value={state.tag_1}
          >
            <MenuItem value={''}></MenuItem>
            {tag_1 &&
              tag_1.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label={vt2 || translate('tag_2')}
            select
            onChange={e => onChange('tag_2', e.target.value)}
            value={state.tag_2}
          >
            <MenuItem value={''}></MenuItem>
            {tag_2 &&
              tag_2.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label={vt3 || translate('tag_3')}
            select
            onChange={e => onChange('tag_3', e.target.value)}
            value={state.tag_3}
          >
            <MenuItem value={''}></MenuItem>
            {tag_3 &&
              tag_3.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label={vt4 || translate('tag_4')}
            select
            onChange={e => onChange('tag_4', e.target.value)}
            value={state.tag_4}
          >
            <MenuItem value={''}></MenuItem>
            {tag_4 &&
              tag_4.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label={vt5 || translate('tag_5')}
            select
            onChange={e => onChange('tag_5', e.target.value)}
            value={state.tag_5}
          >
            <MenuItem value={''}></MenuItem>
            {tag_5 &&
              tag_5.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </Dialog>
    ) : null
  // }
}
const mapStateToProps = state => ({
  vehicles: state.vehicles
})
export default connect(mapStateToProps)(VehicleTagSelector)
