import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '../../../Components/common/TextField'
import Button from '../../../Components/common/Button'
import Dialog from '../../../Components/common/ConfirmDialoag'
import Notifications from 'react-notification-system-redux'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import axios from 'axios'
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: 0,
  id: 0,
  description: 'New',
  type: 'custom',
  textChannel: false
}

class SendCommand extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      sendCommandsForm: { ...savedCommandsDataFormat },
      currentForm: null,
      modal: false,
      enableSubmit : false
    }
  }

  sendCommandSubmit = () => {
    let obj = {
      id: 0,
      ...this.state.currentForm,
    }

    // fetch(`/api/commands/send`, {
    //   method: `POST`,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    axios({
      url: `/api/commands/send`,
      method: 'POST',
      data: {
        ...obj
      }
    })
    .then(response => {
      this.setState({
        currentForm: null,
        modal: false
      })
      if (response.data.statusCode === '200') {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate('commandSent'),
            autoDismiss: 10
          })
        )
      } else if (response.data&&response.data.statusCode === '202') {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate('commandQueued'),
            autoDismiss: 10
          })
        )
      } else {
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate('error'),
            autoDismiss: 10
          })
        )
      }
    })
    .catch(error => {errorHandler(error,this.props.dispatch)}) 
  }

  onChange = event => {
    this.setState({
      sendCommandsForm: {
        ...this.state.sendCommandsForm,
        attributes: {
          ...this.state.sendCommandsForm.attributes,
          data: event.target.value
        }
      }
    },()=>{
      this.checkReqFields()
    })
  }
  
  checkReqFields=()=>{
    const {data}=this.state.sendCommandsForm.attributes;
    if(data){
      this.setState({enableSubmit : true})
    }else{
      this.setState({enableSubmit : false})
    }
  }
  openModal = row => {
    this.setState({
      currentForm: { ...row, deviceId: parseInt(this.props.id) },
      modal: checkPrivileges('commandSend') ? true : false
    })
  }

  hideModal = () => {
    this.setState({
      currentForm: null,
      modal: false
    })
  }

  render () {
    const { props } = this
    return (
      <>
        <Grid container style={{ height: '100%', padding: 20 }}>
        {checkPrivileges('commandCreate') ? (
          <><Grid item xs={12}>
            <strong
              style={{ paddingBottom: 5, paddingTop: 5, display: 'block' }}
            >
              {props.translate('newCommand')}
            </strong>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs>
                <TextField
                  id='data'
                  name='data'
                  margin='dense'
                  // value={this.state?.currentForm?.attributes?.dareta || ''}
                  label={props.translate('commandData')}
                  variant='outlined'
                  fullWidth
                  onChange={e => this.onChange(e)}
                />
              </Grid>
              <Grid
                item
                style={{
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  display: 'flex',
                  paddingBottom: 5,
                  paddingLeft: 10
                }}
              >
                <Button
                  disabled={!this.state?.enableSubmit}
                  onClick={() => this.openModal(this.state.sendCommandsForm)}
                >
                  {this.props.translate('commandSend')}
                </Button>
              </Grid>
            </Grid>
          </Grid></>):null}

          <Grid item xs={12}>
            <strong
              style={{ paddingBottom: 5, paddingTop: 10, display: 'block' }}
            >
              {props.translate('availableCommands')}
            </strong>
            <ul
              style={{
                listStyle: 'none',
                padding: 0,
                paddingTop: 8,
                margin: 0
              }}
            >
              {props.descriptionList && props.descriptionList.length
                ? props.descriptionList.map(e => (
                    <li
                      style={{
                        background: `${props.themecolors.themeLightColor}44`,
                        padding: '6px 12px',
                        borderRadius: 4,
                        cursor: 'pointer',
                        marginBottom: 6
                      }}
                      key={e.key}
                      onClick={() => this.openModal(e)}
                    >
                      {e.name}
                      {checkPrivileges('commandSend') && (
                      <span
                        style={{
                          float: 'right',
                          fontSize: '0.8125rem',
                          padding: 1,
                          textTransform: 'uppercase',
                          fontWeight: 500
                        }}
                      >
                        {this.props.translate('commandSend')}
                      </span>)}
                    </li>
                  ))
                : props.translate('commandNotAvailable')}
            </ul>
          </Grid>
        </Grid>
        {this.state.modal ? (
          <Dialog
            title={props.translate('areYouSureToSendThisCommand')}
            onCancel={this.hideModal}
            onOk={this.sendCommandSubmit}
          />
        ) : null}
      </>
    )
  }
}
// const frequencyType = [
//   {
//     type: 's'
//   },
//   { type: 'm' },
//   { type: 'h' }
// ]

export { SendCommand }
