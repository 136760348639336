import React, { useState, useEffect } from 'react'
import { NavLink,withRouter } from 'react-router-dom'
import './index.scss'
import { Icon, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { checkPrivileges } from '../../Helpers'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import Style from 'style-it'
import MenuBar from './navbar'
// import moment from "moment"
import { ReactComponent as MonitorIcon } from './../../assets/nav/monitor.svg'
import { ReactComponent as DashboardIcon } from './../../assets/nav/dashboard.svg'
import { ReactComponent as EmployeeIcon } from './../../assets/nav/employees-one.svg'
import { ReactComponent as BinderIcon  } from './../../assets/nav/binders.svg'
import { ReactComponent as TrashIcon  } from './../../assets/nav/trash.svg'
import { ReactComponent as GeofenceIcon } from './../../assets/nav/geofence.svg'
import { ReactComponent as MessageIcon } from './../../assets/nav/message.svg'
import { ReactComponent as MailIcon } from './../../assets/nav/mail.svg'
import { ReactComponent as TracksIcon } from './../../assets/nav/tracks.svg'
import { ReactComponent as UnitsIcon } from './../../assets/nav/units.svg'
import { ReactComponent as VehicleIcon } from './../../assets/nav/vehicle.svg'
import { ReactComponent as StaffIcon } from './../../assets/nav/staff.svg'
import { ReactComponent as TimeMachineIcon } from './../../assets/nav/time-machine.svg'
import { ReactComponent as ReportIcon } from './../../assets/nav/reports.svg'
import { ReactComponent as ReportHistoryIcon } from './../../assets/nav/report-history.svg'
import { ReactComponent as CalendarIcon } from './../../assets/nav/calendar.svg'
import { ReactComponent as NotificationIcon } from './../../assets/nav/notifications.svg'
import { ReactComponent as UsersIcon } from './../../assets/nav/user-two.svg'
import { ReactComponent as CommandIcon } from './../../assets/nav/command.svg'
import { ReactComponent as MaintenanceIcon } from './../../assets/nav/spanner.svg'
// import { ReactComponent as InvoiceIcon } from './../../assets/nav/invoice.svg'
import { ReactComponent as ParameterIcon } from './../../assets/nav/algorithm.svg'
import { ReactComponent as actionLog } from './../../assets/nav/actionLog.svg'
// import { ReactComponent as Garage } from './../../assets/nav/garage-icon.svg'
// import { ReactComponent as Area } from './../../assets/nav/area-icon.svg'
import { ReactComponent as Services } from './../../assets/nav/car-expence.svg'
import { ReactComponent as WorkFlow } from './../../assets/nav/workflow.svg'
import { ReactComponent as Metrix } from './../../assets/nav/metrix.svg'
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
// import instance from '../../axios';


const emptyLink = '#'
const mapStateToProps = state => ({
  notificationCount: state.notificationCount,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  dashboards: state.dashboards?.data?.map(item => ({
    ...item,
    icon: DashboardIcon,
  }))||[]
})

const Header = (props) =>  {

  const [currentLang,setCurrentLang] = useState('en')
  
  useEffect(() => {
    if (
      props.activeLanguage &&
      props.activeLanguage.code &&
      props.activeLanguage.code !== currentLang
    ) {
      setCurrentLang(props.activeLanguage.code);
    }
  }, [props.activeLanguage, currentLang]);

    return props.logInUser &&
      props.logInUser.id &&
      localStorage.getItem('frontPrivileges') &&
      currentLang ? (
      <HeaderInner {...props} />
    ) : null
}



const HeaderInner = (props) => {


  const[state,setState] = useState({
    collapseMenu: true,
    privilagesProcess: false,
    navigation: {
      dashboard: {
        checked: true,
        href: '/dashboard',
        icon: DashboardIcon,
        title: props.translate('dashboard')
      },
      monitoring: {
        checked: checkPrivileges('device'),
        href: '/monitoring',
        icon: MonitorIcon,
        title: props.translate('Monitoring')
      },
      units: {
        checked: checkPrivileges('device'),
        href: '/units',
        icon: UnitsIcon,
        title: props.translate('trackers')
      },
      resources: {
        checked: checkPrivileges('group'),
        href: '/resources',
        icon: DeviceHubIcon,
        title: props.translate('resources')
      },
      users: {
        checked: checkPrivileges('user'),
        href: '/users',
        icon: UsersIcon,
        title: props.translate('Users')
      },
      drivers: {
        checked: checkPrivileges('driver'),
        href: '/drivers',
        icon: EmployeeIcon,
        title: props.translate('Staff')
      },
      trailer: {
        checked: checkPrivileges('binder'),
        href: '/binders',
        icon: BinderIcon,
        title: props.translate('binder')
      },
      staff: {
        checked: false,
        href: '/staff',
        icon: StaffIcon,
        title: 'Staff'
      },
      vehicles: {
        checked: checkPrivileges('vehicle'),
        href: '/vehicles',
        icon: VehicleIcon,
        title: props.translate('sharedVehicle')
      },
      services: {
        checked: checkPrivileges('expensetypeTab'),
        href: '/services',
        icon: Services,
        title: props.translate('Services')
      },
      workFlow: {
        checked: checkPrivileges('workflow'),
        // checked: true,
        href: '/workFlow',
        icon: WorkFlow,
        title: props.translate('worKFLow')
      },
      metrics: {
        checked: checkPrivileges('workflowmatrix'),
        // checked: true,
        href: '/metrics',
        icon: Metrix,
        title: props.translate('Metrics')
      },
      geofences: {
        checked: checkPrivileges('geofence'),
        href: '/geofences',
        icon: GeofenceIcon,
        title: props.translate('Geofences')
      },
      calendars: {
        checked: false, //checkPrivileges('calendar'),
        href: '/calendars',
        icon: CalendarIcon,
        title: props.translate('sharedCalendar')
      },
      events: {
        checked: checkPrivileges('notification'),
        href: '/events',
        icon: NotificationIcon, // EventsIcon,
        title: props.translate('reportEvents')
      },
      notifications: {
        checked: false, //checkPrivileges('device'),
        href: '/notifications',
        icon: NotificationIcon,
        title: props.translate('Notifications')
      },
      reports: {
        checked: checkPrivileges('report'),
        href: '/reports',
        icon: ReportIcon,
        title: props.translate('Reports')
      },
      reportsHistory: {
        checked: checkPrivileges('reportHistory'),
        href: '/reportsHistory',
        icon: ReportHistoryIcon,
        title: props.translate('reportHistory')
      },
      messages: {
        checked: checkPrivileges('position'),
        href: '/Dlogs',
        icon: MessageIcon,
        title: props.translate('Dlogs')
      },
      tracks: {
        checked: checkPrivileges('track'),
        href: '/tracks',
        icon: TracksIcon,
        title: props.translate('Tracks')
      },
      timemachine: {
        checked: checkPrivileges('timeLapse'),
        href: '/timemachine',
        icon: TimeMachineIcon,
        title: props.translate('timeMachine')
      },
      jobs: {
        checked: checkPrivileges('job'),
        href: '/schedules',
        icon: MailIcon,
        title: props.translate('sharedSchedule')
      },

      task: {
        checked: false,
        href: '/task',
        icon: DashboardIcon,
        title: 'Task'
      },
      commands: {
        checked: checkPrivileges('command'),
        href: '/commands',
        icon: CommandIcon,
        title: props.translate('sharedSavedCommand')
      },
      maintenance: {
        checked: checkPrivileges('maintenance'),
        href: '/maintenance',
        icon: MaintenanceIcon,
        title: props.translate('sharedMaintenance')
      },
      parameters: {
        checked: checkPrivileges('attribute'),
        href: '/parameters',
        icon: ParameterIcon,
        title: props.translate('sharedComputedAttributes')
      }, 
      trash: {
        checked: true,
        href: '/trash',
        icon: TrashIcon,
        title: props.translate('trash')
      },
      actionlogs: {
        checked: true,
        href: '/actionlogs',
        icon: actionLog,
        title: props.translate('actionLogs')
      },
      
    },
    rightNav: {}
  })


  useEffect(() => {
    if (props.notificationCount !== undefined) {
      setState(prevState => ({
        ...prevState,
        navigation: {
          ...prevState.navigation,
          events: {
            ...prevState.navigation.events,
            count: props.notificationCount
          }
        }
      }));
    }
  }, [props.notificationCount]);


  useEffect(()=>{
    setState(prevState=>({
       ...prevState,
       privilagesProcess:false
    }))
  },[])


 const handleUserSetting = () => {
    props.history.push('/accountManagements')
  }


//  const handleUserChangedPassword = () => {
//     let changedPass = 4
//      props.history.push('/accountManagements', [changedPass])
//   }

 const collapseMenu = () => {
    document.body.classList.toggle('header-open')
  }

 const VisibleLeftMenuItem = (items) => {
    return (
      <MenuBar {...props} items={items} collapseMenu={collapseMenu} />
    )
  }

//  const AllMenuItem = (items) => {
//     return Object.keys(items).map((userData, i) => {
//       return (
//         <li key={i}>
//           <label href={items[userData].href} title={items[userData].title}>
//             <input
//               type='checkbox'
//               name={userData}
//               onChange={showHideMenuItems(userData)}
//               checked
//             />
//             {items[userData].title}
//           </label>
//         </li>
//       )
//     })
//   }

  // const refreshPrivileges = () => {
  //   setState(prevState => ({
  //     ...prevState,
  //     privilagesProcess: true
  //   }));

  //   instance({
  //     url: '/api/privileges',
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     params: {
  //       roleId: props.logInUser.roleId
  //     }
  //   })
  //   .then(response => {
  //     const privileges = response.data;
  //     const privilegeKeys = privileges.map(item => item.key);

  //     localStorage.setItem('frontPrivileges', JSON.stringify(privilegeKeys));

  //     setState(prevState => ({
  //       ...prevState,
  //       frontPrivileges: privilegeKeys 
  //     }));
  //   })
  //   .catch(error => {
  //     console.error('Error fetching privileges:', error);
  //   })
  //   .finally(() => {
  //     setState(prevState => ({
  //       ...prevState,
  //       privilagesProcess: false 
  //     }));
  //   });
  // };

 const goToLogoutpage = () => {
    localStorage.removeItem('backtoAdmin')
      props.history && props.history.push('/logout')
  }
 const switchUserAccount = () => {
    props.history && props.history.push(
      '/logout',
      props.ServerSetting.attributes.adminUrl
    )
  }

//  const collapseSidebar = () => {
//     props.collapseSidebar()
//   }


    if (props.ServerSetting) {
      const { attributes } = props.ServerSetting
     
      return (
        <Style>
          {`
				.nav .active {
					color:  ${props.themecolors&&props.themecolors.themeDarkColor};
        }
        .left-nav {
          background-color: ${props.themecolors.menuBackgroundColor};
          color:  ${props.themecolors.menuTextColor};
        }
        .nav .user-avatar {
          background-color:  ${props.themecolors&&props.themecolors.themeDarkColor};
        }
				.sub-menu label:hover, .sub-menu .active a, .sub-menu a:hover {
					color:  ${props.themecolors&&props.themecolors.themeDarkColor}
        }
        .navbar-menu .navbar-menu-item:hover,
        .navbar-menu .navbar-menu-item.active {
          color:  ${props.themecolors.menuActiveColor}; 
          background-color:  ${props.themecolors.menuActiveBackground}; 
        }
        .navbar-menu-item .counter {
          background: ${props.themecolors&&props.themecolors.themeLightInverse};
          color: ${props.themecolors&&props.themecolors.themeLightColor}
        }

			`}
          <div>
            <div className='left-nav'>
              <ul className='nav'>
                {VisibleLeftMenuItem(state.navigation)}
              </ul>
            </div>

            <header className='fms-header'>
              <nav className='nav-container'>
                <div className='logo-section'>
                  <MenuIcon style={{display: 'none'}}>menu</MenuIcon>
                  <button
                    style={{
                      color: props.themecolors&&props.themecolors.themeInverse,
                      background: props.themecolors&&props.themecolors.themeDarkColor
                    }}
                    className='collapse-header'
                    onClick={collapseMenu}
                  >
                    <MenuIcon style={{width: 26, height: 26}}>menu</MenuIcon>
                  </button>
                  <NavLink to='/'>
                    <img
                      className='logo'
                      src={
                        props.whiteLabling.logo
                          ? props.whiteLabling.logo
                          : ''
                      }
                      alt=''
                    />
                  </NavLink>
                </div>
                <div className='right-nav'>
                  <ul className='nav'>
                    {localStorage.getItem('backtoAdmin') ? (
                      <li>
                        <Tooltip
                          title={
                            props.translate(
                              'youAreTemporarilyLoggedInAsAnotherUser'
                            ) +
                            ' ' +
                            props.translate('switch') +
                            ' ' +
                            props.translate('backToYourAccount')
                          }
                          classes={{
                            popper: 'menu-popper',
                            tooltip: 'menu-popper-tooltip'
                          }}
                        >
                          <a
                            href={emptyLink}
                            onClick={switchUserAccount}
                            title=''
                          >
                            <Icon>logout</Icon>
                          </a>
                        </Tooltip>
                      </li>
                    ) : null}
                     <li>
                        <Tooltip
                            title={ props.notificationCount +" "+props.translate('Notifications') }
                            classes={{
                              popper: 'menu-popper',
                              tooltip: 'menu-popper-tooltip'
                            }}
                          >
                          
                          <NavLink
                            to='/events'
                            style={{position: 'relative'}}
                          >
                            {props.notificationCount !== undefined ? <span className="counter"></span> : null}
                            <NotificationIcon fill="currentColor" width={18} height={18} />
                          </NavLink>
                          </Tooltip>
                      </li>
                    <li className='has-menu'>
                      <a href={emptyLink} title={props.logInUser.name}>
                        <span className='user-menu-name'>
                          {props.logInUser.name}
                        </span>
                      </a>
                      <ul className='sub-menu right-menu'>
                        <li>
                          <a href={emptyLink} onClick={handleUserSetting}>
                            {props.translate('settingsUserSetting')}
                          </a>
                        </li>

                        {attributes.privacyPolicyLink && (
                          <li>
                            <a href={attributes.privacyPolicyLink}>
                              {props.translate(
                                'settingsPrivacyPolicyLink'
                              )}
                            </a>
                          </li>
                        )}
                        {attributes.termsLink && (
                          <li>
                            <a href={attributes.termsLink}>
                              {props.translate('settingsTermsLink')}
                            </a>
                          </li>
                        )}
                        <li className='divider'></li>
                        <li>
                          <a href={emptyLink} onClick={goToLogoutpage}>
                            {props.translate('loginLogout')}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          </div>
        </Style>
      )
    } else {
      return null
    }
  }

export default connect(mapStateToProps)(withLocalize(withRouter(Header)))
