import React, { useEffect, useState ,useCallback } from 'react'
import { useSelector,useDispatch  } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { checkPrivileges, errorHandler, selectAll } from '../../Helpers'
import Layout from './../../Layout'
import TextField from '../../Components/common/TextField'
import Checkbox from '../../Components/common/Checkbox'
import { ReactComponent as CommandIcon } from './../../assets/nav/command.svg'
import { timeZonesTypes } from '../../Components/Attributes/timezone'
import MenuItem from '../../Components/common/MenuItem'
import Button from '../../Components/common/Button'
import { addCommands, getCommands } from '../../Actions/Commands'
import Notifications from 'react-notification-system-redux'
import Grid from '@mui/material/Grid'
import EmptyState from '../../Components/common/EmptyState'
import Dialog from '../../Components/common/Dialog'
import ItemsSelector from '../Units/Components/itemsSelector'
import { removedUserDeviceWiseSelectedList, userDeviceWiseSelectedList } from '../../Actions/Notifications'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { removeUser } from '../../Actions/Users'
import { removeDevice } from '../../Actions/Devices'
import { removeGroup } from '../../Actions/Groups'
import instance from '../../axios'


const Commands = (props) => {
  const [state, setState] = useState({
    commandForm: {},
    commandType: {},
    mode: '',
    isVisableComdBtn: true,
    model: false,
    assignMode: '',
    currentCommand: '',
    entityId: 0,
    initFetch: false,
    currentPage: 1,
    pagesize: 50,
    deleteForm: {},
    uniqueModels: [],
    selectedModel: {},
    listData:false,
    displayList: [],
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 0,
      currentDevice:props.deviceId,
      hasNext: true,
      searchText: ''
    }
  })

  const dispatch = useDispatch();

  const devices = useSelector(state => state.devices.data);
  const devices3 = useSelector(state => state.devices3);
  const logInUser = useSelector(state => state.logInUsers);
  const themecolors = useSelector(state => state.themeColors);
  const selecteduser = useSelector(state => state.selecteduser);
  const group = useSelector(state => state.groups);
  const user = useSelector(state => state.users);

  useEffect(() => {
    if (state.mode === ''  && Object.keys(state.commandForm).length === 0) {
      checkRequiredFields();
    }
  }, [state.mode, state.commandForm]);


  useEffect(() => {
    const fetchUniqueModels = () => {
      const allDevices = devices || [];
      const uniqueModels = [];
      const seenModels = new Set();

      allDevices.forEach(device => {
        const model = device.model;
        if (!seenModels.has(model)) {
          uniqueModels.push({ model });
          seenModels.add(model);
        }
      });

      const options = uniqueModels.map(modelObj => ({
        value: modelObj.model,
        label: modelObj.model,
      }));

      setState(prevState => ({ ...prevState, uniqueModels: options }));
    };
    fetchUniqueModels();
  }, [devices]);

  useEffect(() => {
    if (logInUser?.id && !state.initFetch) {
      setState(prevState => ({ ...prevState, initFetch: true }));
      fetchData(logInUser, state.currentPage, state.pagesize,true);
    }
  }, [logInUser]);

  useEffect(() => {
      fetchData(logInUser, state.currentPage, state.pagesize,true);
  }, [state.searchText]);

  // useEffect(() => {
  //   return () => {
  //     setState({
  //       commandForm: {},
  //       mode: '',
  //       model: false,
  //       assignMode: '',
  //       currentCommand: '',
  //       entityId: 0,
  //       initFetch: false,
  //       currentPage: 1,
  //       pagesize: 50,
  //       itemPagination: {
  //         items: [],
  //         total: 0,
  //         currentPage: 0,
  //         currentDevice: props.deviceId,
  //         hasNext: true,
  //         searchText: ''
  //       }
  //     });
  //   };
  // }, []);

  const fetchData = (logInUser, page, perPage, reset = false) => {
     state.searchText ? `&search=${state.searchText}` : '';
    let items = reset ? [] : state.itemPagination.items;

    instance({
      url: `/api/commands/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        userId: logInUser.id,
        all: true,
        page: page,
        limit: perPage,
        search: state.searchText || ''
      }
    })
    .then(res => {
      const newItems = items.concat(res.data);
      setState(prevState => ({
        ...prevState,
        itemPagination: {
          ...prevState.itemPagination,
          items: newItems,
          total: res.total,
          currentPage: res.page,
          hasNext: res.hasNext
        },
        currentPage: res.hasNext ? res.page + 1 : res.page
      }));
      dispatch(getCommands(newItems));
    })
    .catch(() => {
      // errorHandler(error,props.dispatch)
    });
  };

  const [updateGet,setUpdateGet]= useState(false)

 const handleModelSelect = (name,event) => {
    const val = event.target.value;
    setState(prevState=>({
      ...prevState,
        listData: val ? true : false,
        selectedModel: val
    }))
    setUpdateGet(true)

};

useEffect(()=>{
  if(updateGet)
 {getModelBasedDevices(state.selectedModel)
  setUpdateGet(false)
 }
},[updateGet])

const getModelBasedDevices = (id) => {
  console.warn("Id",id)
  instance({
      url: `/api/devices/list?userId=${logInUser?.id}&all=true`,
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      params: {
          limit: -1,
          model: id
      }
  })
      .then(res => {
          const filteredArray = res && res.data?.map(item => ({
              ...item,
              name: item.label,
              uniqueId: item.id,
          }))

          const updatedChildData = filteredArray.map((childItem) => {
            const parentItem = devices3.find((parent) => parent.id === childItem.id);
          
            if (parentItem) {
              return {
                ...childItem,
                check: parentItem.check
              };
            }
            return childItem;
          });
          
          setState(prevState => (
              {
                ...prevState,
                  displayList: updatedChildData || []
              }
          ))
          assignModal('unit',state.currentCommand)
      }).catch(() => {
          // errorHandler(error, this.props.dispatch)
      })
}

  const fetchMoreItems = () => {
    fetchData(logInUser, state.currentPage, state.pagesize);
  };

  const searchItems = (text) => {
    setState(prevState => ({
      ...prevState,
      searchText: text
    })) 
  };

  const assignModal = (assignMode, currentCommand) => {
    console.warn("AssginModal",assignMode)
    console.warn("currentCommand",currentCommand)
    dispatch(userDeviceWiseSelectedList([]));
    setState(prevState => ({
      ...prevState,
      assignMode,
      currentCommand,
      selectEntity: '',
      model: true
    }))
      if (checkPrivileges('user') && assignMode === 'user') {
        instance({
          url: `/api/users/access`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            commandId: currentCommand.id
          }
        })
        .then(users => {
          let allData = user || [];
          if (users.length) {
            allData = allData.map(item => {
              item.check = false;
              users.map(main => {
                if (item.id && main.userId && item.id === main.userId) {
                  item.check = true;
                }
              });
              return item;
            });
          } else {
            allData.map(n => (n.check = false))
          }
          dispatch(userDeviceWiseSelectedList(allData));
        })
        .catch(() => {
          // errorHandler(error,props.dispatch)
        });
      }
      if (checkPrivileges('device') && assignMode === 'unit') {
        instance({
          url: `/api/devices/access`,
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          },
          params: {
              commandId: currentCommand.id
          }
      })
        .then(devices => {
          let allData = devices3 || [];
          if (devices.length) {
            allData = allData.map(item => {
              item.check = false;
              devices.map(main => {
                if (item.id && main.deviceId && item.id === main.deviceId) {
                  item.check = true;
                }
              });
              return item;
            });
          } else {
            allData.map(n => (n.check = false))
          }
          dispatch(userDeviceWiseSelectedList(allData));
        })
        .catch(() => {
          // errorHandler(error,props.dispatch)
        });
      }
      if (checkPrivileges('group') && assignMode === 'group') {
        instance({
          url: `/api/groups/access`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            commandId: currentCommand.id
          }
        })
        .then(groups => {
          let allData = group || [];
          if (groups.length) {
            allData = allData.map(item => {
              item.check = false;
              groups.forEach(main => {
                if (item.id && main.groupId && item.id === main.groupId) {
                  item.check = true;
                }
              });
              return item;
            });
          } else {
            allData = allData.map(n => ({ ...n, check: false }));
          }
          dispatch(userDeviceWiseSelectedList(allData));
        })
        .catch(() => {
          // errorHandler(error,props.dispatch)
        });
      }
  };


  const closeAssignModal = () => {
    setState(prevState => ({
      ...prevState,
      model: false,
      assignMode: '',
      commandTitle: '',
      currentCommand: '',
      listData: false,
      selectedModel: {}
    }));
  };

  const openCreateFrom = () => {
    setState(prevState => ({
      ...prevState,
      mode: 'create',
      commandForm: {
        type: 'custom',
        textChannel: false,
        attributes: {}
      }
    }));
  };

  const editCommandForm = (commandForm) => {
    setState(prevState => ({
      ...prevState,
      mode: 'update',
      commandType: commandForm,
      commandForm: {
        ...commandForm,
        attributes: { ...commandForm.attributes },
        textChannel: commandForm.textChannel && commandForm.textChannel !== 'No' ? true : false
      }
    }));
  };

  const closeCreateFrom = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      mode: '',
      commandForm: ''
    }));
  }, []);

  const modeEmpty = () => {
    setState(prevState => ({
      ...prevState,
      mode: ''
    }));
  };


const handleChange = (name, value) => {
    if (state.commandType.type === value.value) {
        setState(prevState=>({
          ...prevState,
            commandForm: {
                ...state.commandForm,
                attributes: { ...state.commandType.attributes },
                [name]: value
            }
        }),
           )
    } else {
        setState(prevState=>({
          ...prevState,
            commandForm: {
                ...state.commandForm,
                attributes: {},
                [name]: value
            }
        }),
        )
    }
    checkRequiredFields()
}

  const handleChangeAttributes = (name, value) => {
    if (value) {
        setState(prevState => ({
          ...prevState,
            commandForm: {
                ...state.commandForm,
                attributes: {
                    ...state.commandForm.attributes,
                    [name]: value
                }
            }
        }),
          checkRequiredFields())
    } else {
        if (state.commandForm && state.commandForm.attributes) {
            let attributes = { ...state.commandForm.attributes }
            delete attributes[name]
            setState(prevState=>({
              ...prevState,
                commandForm: {
                    ...state.commandForm,
                    attributes: {
                        ...attributes
                    }
                }
            }),
               checkRequiredFields())
        }
    }
}

  const checkRequiredFields = () => {
    let { description, type } = state.commandForm
    if (description && type) {
      setState(prevState=>({
        ...prevState,
            isVisableComdBtn: false
        }))
    } else {
        setState(prevState=>({
          ...prevState,
            isVisableComdBtn: true
        }))
    }
}
  const submitForm = () => {
    const {
      attributes,
      id,
      description,
      type,
      textChannel,
      deviceId
    } = state.commandForm;

    const obj = {
      attributes: attributes || {},
      id: id || 0,
      description,
      type,
      deviceId: deviceId || 0,
      textChannel
    };

    let params = '';
    if (state.mode === 'update') {
      params = id;
    }

    setState(prevState => ({
      ...prevState,
      isVisableComdBtn: true
    }));

    if (obj) {
      instance({
        url: `/api/commands/${params}`,
        method: `${state.mode === 'update' ? 'PUT' : 'POST'}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(commands => {
          if (state.mode === 'update') {
           dispatch(addCommands(commands));
           dispatch(
              Notifications.success({
                message: props.translate('savedCommandsIsUpdated'),
                autoDismiss: 10
              })
            );
            closeCreateFrom();
          } else {
            dispatch(addCommands(commands));
            dispatch(
              Notifications.success({
                message: props.translate('savedCommandsIsCreated'),
                autoDismiss: 10
              })
            );
            editCommandForm(commands);
          }
        })
        .catch(error => {
          errorHandler(error, dispatch);
        });
    }
  };

  const item_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...selecteduser];
    let obj = {};

    if (state.assignMode === 'user') {
      obj.userId = item.id;
    }
    if (state.assignMode === 'unit') {
      obj.deviceId = item.id;
    }
    if (state.assignMode === 'group') {
      obj.groupId = item.id;
    }
    obj.commandId = parseInt(state.currentCommand.id);

    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData = allData.map(dt => {
            if (dt.id === item.userId) {
              dt.check = false;
            }
            return dt;
          });
          console.error('No',allData)
         dispatch(
            Notifications.success({
              message: props.translate(
                state.assignMode === 'user'
                  ? 'userUnAssignedSuccessfully'
                  : state.assignMode === 'unit'
                  ? 'trackerIsUnAssignedSuccessfully'
                  : 'groupUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData = allData.map(dt => {
            if (dt.id === item.userId) {
              dt.check = true;
            }
            return dt;
          });
          dispatch(
            Notifications.success({
              message: props.translate(
                state.assignMode === 'user'
                  ? 'userAssignedSuccessfully'
                  : state.assignMode === 'unit'
                  ? 'trackerIsAssignedSuccessfully'
                  : 'groupAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          );
        }
       dispatch(userDeviceWiseSelectedList(allData));
      })
      .catch(error => {
        errorHandler(error,dispatch);
      });
  };

  const onRemovedItem = item => {
    setState(prevState => ({
      ...prevState,
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    }));
  };

  const removeItem = () => {
    if (state.assignMode === 'unit' || state.assignMode === 'user' || state.assignMode === 'group') {
      removeFromDatabase();
    }
  };

  const removeFromDatabase = () => {
    const obj = state.deleteForm;
    const params = obj.id;

    if (obj) {
      let call;
      if (state.assignMode === 'user') {
        call = `/api/users/${params}`;
      } else if (state.assignMode === 'unit') {
        call = `/api/devices/${params}`;
      } else if (state.assignMode === 'group') {
        call = `/api/groups/${params}`;
      }

      if (call) {
        instance({
          url: `${call}`,
          method: `DELETE`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(() => {
            dispatch(removedUserDeviceWiseSelectedList(obj));
            let translationKey;
            if (state.assignMode === 'user') {
              translationKey = `userIsRemoved`;
              dispatch(removeUser(obj));
            } else if (state.assignMode === 'unit') {
              translationKey = `trackersIsDeleted`;
              dispatch(removeDevice(obj.id));
            } else if (state.assignMode === 'group') {
              translationKey = `groupDeleted`;
              dispatch(removeGroup(obj));
            }
            dispatch(
              Notifications.success({
                message: props.translate(translationKey),
                autoDismiss: 10
              })
            );
            setState(prevState => ({
              ...prevState,
              onDeleteConfirmation: false
            }));
          })
          .catch(error => {
            errorHandler(error, dispatch);
          });
      }
    }
  };

  const onCancel = () => {
    setState(prevState => ({
      ...prevState,
      onDeleteConfirmation: false
    }));
  };
    const endMessage =
     state.itemPagination &&state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          -- {props.translate('end')} --
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {props.translate('notFound')}
        </p>
      )

    if (checkPrivileges('command')) {
      return (
        <div>
          <Layout
            {...props}
            endMessage={endMessage}
            openCreateFrom={openCreateFrom}
            classFromChildren='has-padding'
            editCommandForm={editCommandForm}
            searchItems={searchItems}
            fetchMoreItems={fetchMoreItems}
            modeEmpty={modeEmpty}
            {...state}
          >
            <div className='main-content-page'>
              {state.onDeleteConfirmation && (
                <ConfirmDialoag
                  onCancel={onCancel}
                  onOk={removeItem}
                  title={props.translate('areYouWantToDelete')}
                  children={state.deleteForm.name || state.deleteForm.description}
                />
              )}
              <div
                style={{
                  background: themecolors.backgroundColor,
                  color: themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                }}
              >
                {state.mode === 'create' ||
                  state.mode === 'update' ? (
                  <CreateCommandForm
                    assignModal={assignModal}
                    submitForm={submitForm}
                    mode={state.mode}
                    closeCreateFrom={closeCreateFrom}
                    handleChangeAttributes={handleChangeAttributes}
                    form={state.commandForm}
                    handleChange={handleChange}
                    isVisableComdBtn={state.isVisableComdBtn}
                    translate={props.translate}
                  />
                ) : null}
                {state.model ? (
                  <Dialog
                    isVisableBtn={true}
                    open={state.model}
                    onClose={closeAssignModal}
                    title={props.translate('assignCommand')}
                    disableFooter
                  >
                    <>
                      <div style={{ padding: 16, fontWeight: 700, paddingBottom: 0 }}><CommandIcon fill="currentColor" width={20} height={20} style={{ verticalAlign: 'middle', marginRight: 5 }} /> {state.currentCommand &&
                        state.currentCommand.description}
                        </div>
                        
                      {state.assignMode === 'unit' ?
                       (
                        <ItemsSelector
                          // handleChangeRowsPerPage={handleChangeRowsPerPage}
                          rowsPerPage={state.rowsPerPage}
                          showDropDown={true}
                          selectedModel={state.selectedModel}
                          onSelect={handleModelSelect}
                          options={state.uniqueModels || []}

                          selectAll={e =>
                            selectAll(
                              e,
                              'deviceId',
                              'commandId',
                              state.currentCommand.id,
                              state.listData ? state.displayList :selecteduser,
                              state.listData ? state.displayList :devices3,
                             dispatch,
                              'access',
                              props.translate
                            )
                          }
                          parent={0}
                          item_Selection={item_Selection}
                          themecolors={themecolors}
                          data={state.listData ? state.displayList : selecteduser}
                          translate={props.translate}
                          onDelete={onRemovedItem}
                          canDelete={checkPrivileges('deviceDelete')}
                          canRemove={checkPrivileges('deviceLinkCommand')}
                          canAssign={checkPrivileges('deviceUnlinkCommand')}
                        />
                      ) : null}

                      {state.assignMode === 'user' ? (
                        <ItemsSelector
                          // handleChangeRowsPerPage={handleChangeRowsPerPage}
                          rowsPerPage={state.rowsPerPage}

                          selectAll={e =>
                            selectAll(
                              e,
                              'userId',
                              'commandId',
                              state.currentCommand.id,
                              selecteduser,
                              user,
                              dispatch,
                              'access',
                              props.translate
                            )
                          }
                          parent={0}
                          item_Selection={item_Selection}
                          themecolors={themecolors}
                          data={selecteduser}
                          translate={props.translate}
                          onDelete={onRemovedItem}
                          canDelete={checkPrivileges('userDelete')}
                          canRemove={checkPrivileges('userLinkCommand')}
                          canAssign={checkPrivileges('userUnlinkCommand')}
                          rowDefinition={[
                            {
                              id: 'name',
                              numeric: false,
                              disablePadding: false,
                              label: props.translate('sharedName')
                            },
                            {
                              id: 'email',
                              numeric: false,
                              disablePadding: false,
                              label: props.translate('userEmail')
                            },
                            {
                              id: 'roleName',
                              numeric: false,
                              disablePadding: false,
                              label: props.translate('roleName')
                            },
                            {
                              id: 'parentName',
                              numeric: false,
                              disablePadding: false,
                              label: props.translate('parentName')
                            }
                          ]}
                        />
                      ) : null}

                      {state.assignMode === 'group' ? (
                        <ItemsSelector
                          // handleChangeRowsPerPage={handleChangeRowsPerPage}
                          rowsPerPage={state.rowsPerPage}
                          selectAll={e =>
                            selectAll(
                              e,
                              'groupId',
                              'commandId',
                              state.currentCommand.id,
                             selecteduser,
                              group,
                              dispatch,
                              'access',
                              props.translate
                            )
                          }
                          parent={0}
                          item_Selection={item_Selection}
                          themecolors={themecolors}
                          data={selecteduser}
                          translate={props.translate}
                          onDelete={onRemovedItem}
                          canDelete={checkPrivileges('groupDelete')}
                          canRemove={checkPrivileges('groupLinkCommand')}
                          canAssign={checkPrivileges('groupUnlinkCommand')}
                          rowDefination={[
                            {
                              id: 'name',
                              numeric: false,
                              disablePadding: false,
                              label: props.translate('sharedName')
                            },
                            {
                              id: 'uniqueId',
                              numeric: false,
                              disablePadding: false,
                              label: props.translate('uniqueId')
                            }
                          ]}
                        />
                      ) : null}
                    </>
                  </Dialog>
                ) : null}
                {state.mode === '' ? (
                  <EmptyState
                    text={props.translate('noCommandSelected')}
                  />
                ) : null}
              </div>
            </div>
          </Layout>
        </div>
      )
    } else {
      return null
    }
  }


const CreateCommandForm = ({
  form,
  handleChange,
  translate,
  handleChangeAttributes,
  submitForm,
  closeCreateFrom,
  mode,
  assignModal,
  isVisableComdBtn
}) => {
  return (
    <div>
      <h4 className='page-title'>{translate('sharedSavedCommand')}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='description'
            required
            margin='dense'
            label={translate('sharedDescription')}
            variant='outlined'
            fullWidth
            value={form.description||""}
            onChange={e => handleChange('description', e.target.value)}
          />
        </Grid>
        {[
          'custom',
          'silenceTime',
          'setPhonebook',
          'voiceMessage',
          'outputControl',
          'setIndicator',
          'configuration',
          'setOdometer',
          'alarmClock',
          'alarmSpeed',
          'alarmVibration',
          'setSilenceTime'
        ].includes(form.type) ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='data'
              name='data'
              margin='dense'
              label={translate('commandData')}
              variant='outlined'
              fullWidth
              value={form.attributes.data || ''}
              onChange={e => handleChangeAttributes('data', e.target.value)}
            />
          </Grid>
        ) : null}

        {form.type.value === "periodicReporting" ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='frequency'
                name='frequency'
                type='number'
                margin='dense'
                label={translate('commandFrequency')}
                variant='outlined'
                fullWidth
                value={form.attributes.frequency || ''}
                onChange={e =>
                  handleChangeAttributes('frequency', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='frequencyType'
                name='frequencyType'
                select
                value={form.attributes.frequencyType || ''}
                onChange={e =>
                  handleChangeAttributes('frequencyType', e.target.value)
                }
                fullWidth
              >
                {frequencyType.map(option => (
                  <MenuItem key={option.type} value={option.type}>
                    {option.type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        ) : null}
        {form.type.value === 'setTimezone' ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='timezone'
              name='timezone'
              select
              label={translate('commandTimezone')}
              value={form.attributes.timezone || ''}
              onChange={e => handleChangeAttributes('timezone', e.target.value)}
              margin='dense'
              fullWidth
            >
              {timeZonesTypes.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : null}
        {[
          'sendSms',
          'sendUssd',
          'sosNumber',
          'outputControl',
          'message'
        ].includes(form.type.value) ? (
          <>
            {['sosNumber', 'outputControl'].includes(form.type.value) ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='index'
                  name='index'
                  type='number'
                  margin='dense'
                  label={translate('commandIndex')}
                  variant='outlined'
                  fullWidth
                  value={form.attributes.index || ''}
                  onChange={e =>
                    handleChangeAttributes('index', e.target.value)
                  }
                />
              </Grid>
            ) : null}
            {['sendSms', 'sendUssd', 'sosNumber'].includes(form.type.value) ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='phoneNumber'
                  margin='dense'
                  name='phoneNumber'
                  label={translate('commandPhone')}
                  variant='outlined'
                  fullWidth
                  value={form.attributes.phoneNumber || ''}
                  onChange={e =>
                    handleChangeAttributes('phoneNumber', e.target.value)
                  }
                />
              </Grid>
            ) : null}
            {['sendSms', 'message'].includes(form.type.value) ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='messages'
                  name='messages'
                  margin='dense'
                  label={translate('Messages')}
                  variant='outlined'
                  fullWidth
                  value={form.attributes.messages || ''}
                  onChange={e =>
                    handleChangeAttributes('messages', e.target.value)
                  }
                />
              </Grid>
            ) : null}
          </>
        ) : null}
        {[
          'alarmFall',
          'alarmRemove',
          'alarmBattery',
          'modeDeepSleep',
          'modePowerSaving',
          'setAgps',
          'voiceMonitoring',
          'modifyPowerSaving',
          'modifyDeepSleep',
          'alarmSos'
        ].includes(form.type.value) ? (
          <Grid item xs={12}>
            <Checkbox
              checked={form.attributes.enable}
              onChange={e => handleChangeAttributes('enable', e.target.checked)}
              color='primary'
              label={translate('commandEnable')}
              name='enable'
            />
          </Grid>
        ) : null}
        {form.type.value === 'setConnection' ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='server'
                name='server'
                margin='dense'
                label={translate('commandServer')}
                variant='outlined'
                fullWidth
                value={form.attributes.server || ''}
                onChange={e => handleChangeAttributes('server', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='port'
                name='port'
                type='number'
                margin='dense'
                label={translate('commandPort')}
                variant='outlined'
                fullWidth
                value={form.attributes.port || ''}
                onChange={e => handleChangeAttributes('port', e.target.value)}
              />
            </Grid>
          </>
        ) : null}
        {form.type.value === 'movementAlarm' ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='radius'
              name='radius'
              type='number'
              margin='dense'
              label={translate('commandRadius')}
              variant='outlined'
              fullWidth
              value={form.attributes.radius || ''}
              onChange={e => handleChangeAttributes('radius', e.target.value)}
            />
          </Grid>
        ) : null}
      </Grid>

      <div style={{ display: 'flex', marginTop: 10 }}>
        {mode !== 'create' ? (
          <>
            <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={() => assignModal('unit', form)}
            >
              {translate('assignUnit')}
            </Button>
            <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={() => assignModal('user', form)}
            >
              {translate('assignUser')}
            </Button>
          </>
        ) : null}
        <div style={{ marginLeft: 'auto' }} />
        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeCreateFrom}
        >
          {translate('sharedCancel')}{' '}
        </Button>
        {checkPrivileges('commandCreate') && (
          <Button
            size='small'
            onClick={submitForm}
            disabled={isVisableComdBtn}
          >
            {translate(mode === 'create' ? 'sharedCreate' : 'update')}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  commands: state.commands,
})

export default connect(mapStateToProps)((withLocalize(Commands)))

const frequencyType = [
  { type: 's' },
  { type: 'm' },
  { type: 'h' }
]
