import React from 'react'
import TextField from '../../../Components/common/TextField'
import MenuItem from '../../../Components/common/MenuItem'
import Grid from '@mui/material/Grid'
import Button from '../../../Components/common/Button'
import Checkbox from '../../../Components/common/Checkbox'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Tooltip} from '@mui/material';
import SingleSelect from '../../../Components/common/SingleSelect'

export default function AdvanceForm (props) {
  const { unitParkingAttributesform } = props
  let selectedlang = props.languages?.find(id => id.code===unitParkingAttributesform.lang)
  let selectedDriver = props.drivers?.find(id => id.id===unitParkingAttributesform.fixDriverId)
  let selectedTrailer = props.trailers?.find(id => id.id===unitParkingAttributesform.fixTrailerId)
  return (
    <div>
      <div style={{ textAlign: 'right', padding: '16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('tripDetection')}
        </h4>
      </div>

      <div style={{ padding: '0 16px' }}>
        <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        >
          <Grid container spacing={2}>
            <Grid item sm={6} lg={4} md={4} xl={4} xs={12}>
              <TextField
                id='useIgnition'
                label={props.translate('movementDetection')}
                type='text'
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.useIgnition) ||
                  '2'
                }
                onChange={props.handleChange('useIgnition')}
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                <MenuItem value='2'>{props.translate('gpsSpeed')}</MenuItem>
                <MenuItem value='1'>
                  {props.translate('engineIgnitionSensor')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={6} lg={4} md={4} xl={4} xs={12}>
              <TextField
                type='number'
                label={props.translate('minimalNoDataDuration')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.minimalNoDataDuration !== undefined
                    ? unitParkingAttributesform.minimalNoDataDuration
                    : ''
                }
                onChange={props.handleChange('minimalNoDataDuration')}
                id='minimalNoDataDuration'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} md={4} xl={4} xs={12}>
              <TextField
                type='number'
                label={props.translate('minimalParkingDuration')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.minimalParkingDuration !== undefined
                    ? unitParkingAttributesform.minimalParkingDuration
                    : ''
                }
                onChange={props.handleChange('minimalParkingDuration')}
                id='minimalParkingDuration'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} md={4} xl={4} xs={12}>
              <TextField
                type='number'
                label={props.translate('minimalTripDuration')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.minimalTripDuration !== undefined
                    ? unitParkingAttributesform.minimalTripDuration
                    : ''
                }
                onChange={props.handleChange('minimalTripDuration')}
                id='minimalTripDuration'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} md={4} xl={4} xs={12}>
              <TextField
                type='number'
                label={
                  props.translate('minimalTripDistance') + ' (meter)'
                }
                id='minimalTripDistance'
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.minimalTripDistance) !== undefined
                    ? unitParkingAttributesform.minimalTripDistance
                    : ''
                }
                onChange={props.handleChange('minimalTripDistance')}
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} md={4} xl={4} xs={12}>
              <TextField
                type='number'
                label={
                  props.translate('speedThreshold') +
                  '(' +
                  ((localStorage.getItem('userInfoFront') &&
                    JSON.parse(localStorage.getItem('userInfoFront')).attributes
                      .speedUnit) ||
                    'kn') +
                  ')'
                }
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.speedThreshold) ||
                  ''
                }
                onChange={props.handleChange('speedThreshold')}
                id='speedThreshold'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} md={4} xl={4} xs={12}>
              <Checkbox
                id='processInvalidPositions'
                onChange={props.handleChange('processInvalidPositions')}
                checked={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.processInvalidPositions) ||
                  false
                }
                label={props.translate('processInvalidPositions')}
              />
            </Grid>
          </Grid>
        </div>
        {/* <Grid item xs={12} style={{ textAlign: 'right' }}>
          {props.isVisablBtn && (
            <Button
              disabled={!props.isVisableParkingAttBtn}
              onClick={props.onSubmit}
            >
              {props.translate('sharedSave')}
            </Button>
          )}
        </Grid> */}
      </div>

      {/* <div style={{ textAlign: 'right', padding: '16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('fuelFillingdrainDetection')}
        </h4>
      </div>

      <div style={{ padding: '0 16px' }}>
        <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        >
          <Grid container spacing={2}>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                id='minimalFuelFillingVolume'
                label={props.translate('minimalFuelFillingVolume')}
                helperText={props.errorInFuelFilling? "Value Should be Greater than 1":null}
                type='number'
                error={props.errorInFuelFilling}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.minimalFuelFillingVolume) ||
                  ''
                }
                onChange={props.handleChange('minimalFuelFillingVolume')}
                variant='outlined'
                margin='dense'
                fullWidth
              >
              </TextField>
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('minimalFuelDrainVolume')}
                error={props.errorInFuelDrain}
                helperText={props.errorInFuelDrain?"Value Should be Greater than 1":null}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.minimalFuelDrainVolume !== undefined
                    ? unitParkingAttributesform.minimalFuelDrainVolume
                    : ''
                }
                onChange={props.handleChange('minimalFuelDrainVolume')}
                id='minimalFuelDrainVolume'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('consecutiveFuelFillingTimeout')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.consecutiveFuelFillingTimeout !== undefined
                    ? unitParkingAttributesform.consecutiveFuelFillingTimeout
                    : '120'
                }
                onChange={props.handleChange('consecutiveFuelFillingTimeout')}
                id='consecutiveFuelFillingTimeout'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('consecutiveFuelDrainTimeout')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.consecutiveFuelDrainTimeout !== undefined
                    ? unitParkingAttributesform.consecutiveFuelDrainTimeout
                    : '120'
                }
                onChange={props.handleChange('consecutiveFuelDrainTimeout')}
                id='consecutiveFuelDrainTimeout'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12} style={{marginTop:'10px'}}>
              <Checkbox
                id='detectionInMotion'
                onChange={props.handleChange('detectionInMotion')}
                checked={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.detectionInMotion) ||
                  false
                }
                label={props.translate('detectionInMotion')}
              />
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12} style={{marginTop:'10px'}}>
              <Checkbox
                id='useFuelAccuracy'
                onChange={props.handleChange('useFuelAccuracy')}
                checked={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.useFuelAccuracy) ||
                  false
                }
                label={props.translate('useFuelAccuracy')}
              />
            </Grid>
            {(unitParkingAttributesform&&unitParkingAttributesform.useFuelAccuracy) &&
            (<Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('fuelAccuracy')+ ' (%)'}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.fuelAccuracy) ||
                  '5'
                }
                onChange={props.handleChange('fuelAccuracy')}
                id='fuelAccuracy'
                fullWidth
              />
            </Grid>)}
          </Grid>
        </div>
  
      </div> */}

      {/* <div style={{ textAlign: 'right', padding: '16px 16px 0px 16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('fuelCounter')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        >
          <Grid container spacing={2}>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                id='fuelCounter'
                label={props.translate('fuelCounter')}
                type='text'
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.fuelCounter) ||
                  'basic'
                }
                onChange={props.handleChange('fuelCounter')}
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                <MenuItem value='basic'>{props.translate('basic')}</MenuItem>
                <MenuItem value='standard'>{props.translate('standard')}</MenuItem>
                <MenuItem value='advance'> {props.translate('advance')} </MenuItem>
              </TextField>
            </Grid>
            {(unitParkingAttributesform&&unitParkingAttributesform.fuelCounter ==="basic") ?
            (<Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('basicL100Consumption') + ' (10L/100KM)'}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.basicL100Consumption) ||
                  '10'
                }
                onChange={props.handleChange('basicL100Consumption')}
                id='fuelAccuracy'
                fullWidth
              />
            </Grid>)
            : unitParkingAttributesform&&unitParkingAttributesform.fuelCounter ==="advance" ?
            (<><Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('advL100CityConsumption') + " (12L/100KM)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advL100CityConsumption) ||
                  '5'
                }
                onChange={props.handleChange('advL100CityConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
            </Grid>
            
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advL100HighwayConsumption')+ " (14L/100KM)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advL100HighwayConsumption) ||
                  '5'
                }
                onChange={props.handleChange('advL100HighwayConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('advL100AverageConsumption') + " (10L/100KM)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advL100AverageConsumption) ||
                  '5'
                }
                onChange={props.handleChange('advL100AverageConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
        
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advCitySpeed')+ " (kn)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advCitySpeed) ||
                  '5'
                }
                onChange={props.handleChange('advCitySpeed')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advHighwaySpeed')+ " (kn)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advHighwaySpeed) ||
                  '5'
                }
                onChange={props.handleChange('advHighwaySpeed')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advAverageSpeed')+ " (kn)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advAverageSpeed) ||
                  '5'
                }
                onChange={props.handleChange('advAverageSpeed')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advCorrectionCoefficient')}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advCorrectionCoefficient) ||
                  '1'
                }
                onChange={props.handleChange('advCorrectionCoefficient')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advIdlLHFuelConsumption') + " (L/H)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advIdlLHFuelConsumption) ||
                  '5'
                }
                onChange={props.handleChange('advIdlLHFuelConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advHighSpeedAddonPercent') + "(%)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advHighSpeedAddonPercent) ||
                  '20'
                }
                onChange={props.handleChange('advHighSpeedAddonPercent')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advEveryKmhHighwayConsumption') + " (kn)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advEveryKmhHighwayConsumption) ||
                  '5'
                }
                onChange={props.handleChange('advEveryKmhHighwayConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>

            </>)

            : null }
          </Grid>

        </div>
      </div> */}


      <div style={{ textAlign: 'right', padding: '16px 16px 0px 16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('geoCoding')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        {/* <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        > */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sm={6} style={{zIndex:5}}>
              <SingleSelect
                    array={props.languages?.map(l => ({...l, id: l.code, key: l.code, value: l.code, name: l.name}))}
                    selectName='lang'
                    label={props.translate('loginLanguage')}
                    value={selectedlang ? 
                      {active:false,
                      code:selectedlang.code,
                      id:selectedlang.code,
                      key:selectedlang.code,
                      label:selectedlang.name,
                      name:selectedlang.name}
                      : ''}
                    handleChange={props.handleChange2}
                    canAssign
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                     }}
                  />
            </Grid>
            <Grid item xs={1} md={1} sm={1} style={{display: 'flex', alignItems: 'center'}}>
              <Tooltip 
                classes={{
                  popper: 'menu-popper',
                  tooltip: 'menu-popper-tooltip'
                }}
                title={props.translate(`Geo-coding address stored in ${selectedlang ? selectedlang.name:'English'} language`)}>
                <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
              </Tooltip>
            </Grid>
          </Grid>
        {/* </div> */}
      </div>
      <div style={{ textAlign: 'right', padding: '16px 16px 0px 16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('sharedDriver')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        {/* <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        > */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sm={6} style={{zIndex:3}}>
              <SingleSelect
                    array={props.drivers?.map(l => ({...l, id: l.id, key: l.id, value: l.id, name: l.name}))}
                    selectName='fixDriverId'
                    label={props.translate('sharedDriver')}
                    isClearable
                    value={selectedDriver ? 
                      {active:false,
                      code:selectedDriver.code,
                      id:selectedDriver.code,
                      key:selectedDriver.code,
                      label:selectedDriver.name,
                      name:selectedDriver.name}
                      : ''}
                    handleChange={props.handleChange2}
                    canAssign
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                     }}
                  />
            </Grid>
            
            {/* <Grid item xs={1} md={1} sm={1} style={{display: 'flex', alignItems: 'center'}}>
              <Tooltip 
                classes={{
                  popper: 'menu-popper',
                  tooltip: 'menu-popper-tooltip'
                }}
                title={props.translate(`Geo-coding address stored in ${selectedlang ? selectedlang.name:'English'} language`)}>
                <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
              </Tooltip>
            </Grid> */}
          </Grid>
        {/* </div> */}
      </div>
          <div style={{ textAlign: 'right', padding: '16px 16px 0px 16px' }} className='clearfix'>
            <h4 className='v-tab-heading pull-left with-border'>
              {props.translate('binder')}
            </h4>
          </div>
          <div style={{ padding: '0 16px',width:"26.5%" }}>
             <Grid item xs={12} md={3} sm={6}>
              <SingleSelect
                    array={props.trailers?.map(l => ({...l, id: l.id, key: l.id, value: l.id, name: l.name}))}
                    selectName='fixTrailerId'
                    label={props.translate('sharedBinder')}
                    isClearable
                    value={selectedTrailer ? 
                      {active:false,
                      code:selectedTrailer.code,
                      id:selectedTrailer.code,
                      key:selectedTrailer.code,
                      label:selectedTrailer.name,
                      name:selectedTrailer.name}
                      : ''}
                    handleChange={props.handleChange2}
                    canAssign
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                     }}
                  />
            </Grid></div>
      <div style={{ padding: '0 16px' }}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
            {props.isVisablBtn && (
              <Button
                disabled={!props.isVisableParkingAttBtn||props.isLoading}
                onClick={props.onSubmit}
              >
                {props.isLoading?"loading":props.translate('sharedSave')}
              </Button>
            )}
          </Grid>
        </div>
      {/* <div style={{ textAlign: 'right', padding: '16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('movementDetection')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        <Grid container spacing={2}>
          <Grid item xl={3} md={4} xs={12}>
            <TextField
              id='storeTime'
              label={props.translate('deviceNoDataDuration')}
              placeholder=''
              value={
                unitParkingAttributesform && unitParkingAttributesform.storeTime
                  ? unitParkingAttributesform.storeTime
                  : ''
              }
              onChange={props.handleChange('storeTime')}
              variant='outlined'
              margin='dense'
              type='number'
              fullWidth
            />
          </Grid>
        </Grid>
      </div> */}
    </div>
  )
}
