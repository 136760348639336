
import React, { useState,useEffect } from 'react'
import './index.scss'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import UnitsSidebar from '../Sidebar/Components/Units'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
// import SortMenu from '../Sidebar/Components/SortMenu'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GeofenceSidebar from '../Sidebar/Components/Geofences'
import TemplateGeneratorSidebar from '../Sidebar/Components/TemplateGenerator'
import ReportsSidebar from '../Sidebar/Components/Reports'
import {
  UsersShortList,
  UsersShortList2
} from '../../Components/Users/shortList'
import GroupsShortList from '../../Components/Groups/shortList'
import Button from '../../Components/common/Button'
import TextField from '../../Components/common/TextField'
import { DevicesList, ListRowAlter as MonitoringListRow } from '../../Components/Devices'
import { ListRow as DriverListRow, DriversShortList } from '../../Components/Drivers/shortList'
import { ListRow as TrailerListRow, TrailerShortList } from '../../Components/Trailer/shortList'
import { JobsShortList } from '../../Components/Jobs/shortList'
import { VehicleShortList, ListRow as VehicleListRow } from '../../Components/Vehicles/shortList';
import ReportsHistorySidebar from '../Sidebar/Components/ReportsHistory'
import MessagesSidebar from '../Sidebar/Components/Messages'
import CommandsSidebar from '../Sidebar/Components/Commands'
import InvoicesSidebar from '../Sidebar/Components/Invoices'
import GarageSidebar from '../Sidebar/Components/Garage'
import AreaSidebar from '../Sidebar/Components/Areas'
import ServicesSidebar from '../Sidebar/Components/Services'
import MaintenanceSidebar from '../Sidebar/Components/Maintenance'
import ParametersSidebar from '../Sidebar/Components/Parameters'
import TracksSidebar from '../Sidebar/Components/Tracks'
import TimemachineSidebar from '../Sidebar/Components/Timemachine'
import EventsSidebar from '../Sidebar/Components/Events'
import NotificationSidebar from '../Sidebar/Components/Notification'
import InfiniteScroll from 'react-infinite-scroll-component'
import AppsIcon from '@mui/icons-material/Apps'
import { checkPrivileges,defaultDevicesLength } from '../../Helpers'
import WorkFlow from '../Sidebar/Components/WorkFlow'
import {ReactComponent as WorkOff} from '../../assets/monitoring/work-off.svg'
import { ReactComponent as PowerCutIcon } from '../../assets/monitoring/powerCut.svg'
import { ReactComponent as VehicleIcon } from '../../assets/monitoring/vehicle.svg'
import { ReactComponent as DriverIcon } from '../../assets/monitoring/driver.svg'
import { ReactComponent as IgnitionIcon } from '../../assets/monitoring/idling.svg'
import {ReactComponent as ResetIcon} from '../../assets/monitoring/reset.svg' 
import RestoreIcon from '@mui/icons-material/Restore'
import SearchField from '../../Components/common/SearchField'
import PositionMenu from '../../Components/Maps/PositionMenu'
import { Scrollbar } from 'react-scrollbars-custom'
import ExportImportMenu from '../../Components/Maps/ExportImportMenu'
import RecourcesList from '../Sidebar/Components/RecourcesList'
import Metrics from '../Sidebar/Components/Metrics'
import {
  toggleAllDevices,
  sortDevices,
  searchDevices,
  sortcalendars,
  fetchMoreDevices,
  resetFilters,
  // sortDeviceTypes
} from '../../Actions/Devices'
import { sortDrivers } from '../../Actions/Drivers'
import { sortJobs } from '../../Actions/Jobs'
import { sortUsers } from '../../Actions/Users'
import { Link } from 'react-router-dom'
import Loader from '../Loader'


const SidebarInner = (props) => {

  const[state,setState] = useState ({
    switchView: 'units',
    isPopupVisable: false,
    is_gm_Visable: false,
    newPage: 0,
    isChecked: true,
    menuSettings: [],
    selection:"units",
    items: Array.from({ length: 20 }),
    hasMore: true,
    resourceSearch:""
  })
const [isAllShow,setIsAllShow]=useState(true)
  useEffect(()=>{
    if (props.viewScreen) {
      setState(prevState=>({
        ...prevState, newPage: props.viewScreen === 'groups' ? 1 : 0 }))
    }
    setState(prevState=>({
      ...prevState,
      menuSettings: [
        {
          label: props.translate('moving'),
          value: 'Moving',
          icon: (
            <span className='action-item online'>
              <PlayArrowIcon style={{ fontSize: 18 }}/>
            </span>
          )
        },
        {
          label: props.translate('idling'),
          value: 'Idling',
          icon: (
            <span className='action-item unknown'>
              <IgnitionIcon style={{ width: 18, height: 18 }} fill="currentColor" />
            </span>
          )
        },
        {
          label: props.translate('stop'),
          value: 'stop',
          icon: (
            <span className='action-item online'>
              <PauseIcon style={{ fontSize: 18 }}/>
            </span>
          )
        },
        {
          label: props.translate('towing'),
          value: 'Towing',
          icon: (
            <span className='action-item online'>
              <RvHookupIcon style={{ fontSize: 18 }}/>
            </span>
          )
        },
        {
          label: props.translate('parking'),
          value: 'statusParking',
          icon: (
            <span className='action-item online'>
              <LocalParkingIcon style={{ fontSize: 18 }}/>
            </span>
          )
        },
        {
          label: props.translate('tracker.online'),
          value: 'statusOnline',
          icon: (
            <span className='action-item online'>
              <i className='circle'></i>
            </span>
          )
        },
        {
          label: props.translate('tracker.offline'),
          value: 'statusOffline',
          icon: (
            <span
              className='action-item offline'
              style={{ display: 'block', margin: '0 auto' }}
            >
              <i className='circle'></i>
            </span>
          )
        },
        {
          label: props.translate('tracker.gpsNotUpdated'),
          value: 'gpsNotUpdated',
          icon: (
            <span className='action-item not-updated'>
              <i className='circle'></i>
            </span>
          )
        },
        {
          label: props.translate('tracker.notRegisteredYet'),
          value: 'notRegistered',
          icon: (
            <span className='action-item not-registered'>
              <i className='circle'></i>
            </span>
          )
        },
        {
          label: props.translate('notification.powerCut'),
          value: 'powerCut',
          icon: (
            <span className='action-item not-registered'>
              <PowerCutIcon fill="#28a745" width={16} height={16} />
            </span>
          )
        },
        {
          label: props.translate('workFLowOff'),
          value: 'workFlowOff',
          icon: (
            <span className='action-item not-registered'>
               <WorkOff fill="gray" width={16} height={16} />
            </span>
          )
        },
        {
          label: props.translate('workFLowOn'),
          value: 'workFlowOn',
          icon: (
            <span className='action-item not-registered'>
              <WorkOff fill="#28a745" width={16} height={16} />
            </span>
          )
        }
      ]
    }))
  },[])
  
   const onCloseModal = () => {
      setState(prevState=>({
        ...prevState,
        is_gm_Visable: false
      }))
    }
  
 const resourceForSearch =(text)=>{
  setState(prevState=>({
    ...prevState,
      resourceSearch:text
    }))
  }

  useEffect(()=>{
    if (state.selection === "resource"){
    props.searchItems2(state.resourceSearch)
    }
  },[state.resourceSearch,state.selection])
  
   const searchItem = (event)=> {
  
       if(props.resourceList) {
        if(state.selection === "groups"){
          props.searchResources(event.target.value)
          setState(prevState=>({
            ...prevState,
            search: event.target.value
          }))
        }
        else{
          props.searchNestedResources(props.loadingItem,event.target.value)
          setState(prevState=>({
            ...prevState,
            search: event.target.value
          }))
        }
      }
      else if (props.location.pathname.indexOf('/drivers') !== -1) { 
        props.searchItems(event.target.value)
      }else if (props.location.pathname.indexOf('/binders') !== -1) {
        props.searchItems(event.target.value)
      } else if (props.location.pathname.indexOf('/schedules') !== -1) {
        props.searchItems(event.target.value)
      } else if (props.location.pathname.indexOf('/units') !== -1) {
        if (state.newPage === 0) {
        if (state.selection === "resource"){
          setState(prevState=>({
            ...prevState,
            resourceSearch:event.target.value
          }))
        }
        else if ( state.selection === "units")
        {
          props.dispatch(searchDevices({ device: event.target.value }))
          setState(prevState=>({
            ...prevState,
            search: event.target.value
          }))
        }
          
        } else {
  
          props.searchItems2(event.target.value)
        }
      } else if (
        props.location.pathname.indexOf('/calendars') !== -1 ||
        props.location.pathname.indexOf('/users') !== -1 ||
        props.location.pathname.indexOf('/users2') !== -1 ||
        props.location.pathname.indexOf('/vehicles') !== -1 ||
        props.location.pathname.indexOf('/vehicle') !== -1
      ) {
        props.searchItems(event.target.value)
        setState(prevState=>({
          ...prevState,
          search: event.target.value
        }))
        //props.dispatch(searchUser({ user: event.target.value }));
      } else if (props.location.pathname.indexOf('/monitoring') !== -1) {
        if (state.switchView === 'groups') {
          props.searchItems2(event.target.value, state.switchView)
        } 
        else if (state.switchView === 'resource') {
          props.searchItems2(event.target.value, state.switchView)
        } else {
          props.dispatch(searchDevices({ device: event.target.value }))
        }
        setState(prevState=>({
          ...prevState,
          search: event.target.value
        }))
      } else {
        props.dispatch(searchDevices({ device: event.target.value }))
        setState(prevState=>({
          ...prevState,
          search: event.target.value
        }))
      }
    }

    const searchResourceItem=(event)=>{
      props.searchItems2(event.target.value)
    }
  
   const switchView = type => {
    setState(prevState=>({...prevState,search:""})) 
       if(type === "units"){
        props.showResources(false)
       }
       else if (type === 'groups'){
        props.showResources(true)
       }
       else{
          props.showResources(false)
          props.switchToResource()
          props.dispatch(searchDevices({ device: '' }))
          // if (type === 'groups') {
            props.dispatch(fetchMoreDevices(true, null, 'abc'))
            props.fetchMoreItems2()
          // } else {
            // props.dispatch(fetchMoreDevices(true, null))
          // }
       }
       setState(prevState=>({
        ...prevState,
        switchView: type,
        isChecked: true,
        sort: 'ASC',
        search: '',
        selection:type
      }))
    }

  //  const switchMode=(e)=>{
  //   setState(prevState=>({...prevState,selection:e?"groups":"units"}))
  //     if(e){
  //       props.showResources(true)
  //     }
  //     else{
  //       props.showResources(false)
  //     }
  //   }

  
  
  //  const switchPageView = (e, newValue) => {
  //     if (newValue === 1) {
  //       props.fetchMoreItems2(1, '')
  //     }
  //     setState(prevState=>({
  //       ...prevState,
        
  //         newPage: newValue,
  //         search: ''
  //       }))
  //       props.dispatch(searchDevices({ device: '' }))
  //   }
  
   const isChecked = (value) => {
    setState(prevState=>({
      ...prevState,
        isChecked: value
      }))
    }

  useEffect(()=>{
    props.dispatch(toggleAllDevices({ checked:isAllShow }))
  },[isAllShow])

  const toggleAllDevicess =(event)=> {
      setState(prevState=>({
        ...prevState,
        isChecked: event.target.checked
      }))
      setIsAllShow(event.target.checked)
    }
  
  //  const sortByDeviceType = () => {
  //     props.dispatch(sortDeviceTypes({ sort: state.sort || 'DESC' }))
  //     setState(prevState=>({
  //       ...prevState,
  //       sort: state.sort === 'ASC' ? 'DESC' : 'ASC'
  //     }))
  //   }
  
  const sortByName = () => {
      if (props.location.pathname === '/drivers') {
        props.dispatch(sortDrivers({ sort: state.sort || 'DESC' }))
        setState(prevState=>({
          ...prevState,
          sort: state.sort === 'ASC' ? 'DESC' : 'ASC'
        }))
      } else if (props.location.pathname === '/schedules') {
        props.dispatch(sortJobs({ sort: state.sort || 'DESC' }))
        setState(prevState=>({
          ...prevState,
          sort: state.sort === 'ASC' ? 'DESC' : 'ASC'
        }))
      } else if (props.location.pathname === '/calendars') {
        props.dispatch(sortcalendars({ sort: state.sort || 'DESC' }))
        setState(prevState=>({
          ...prevState,
          sort: state.sort === 'ASC' ? 'DESC' : 'ASC'
        }))
      } else if (
        props.location.pathname === '/users' ||
        props.location.pathname === '/addUser'
      ) {
        props.dispatch(sortUsers({ sort: state.sort || 'DESC' }))
        setState(prevState=>({
          ...prevState,
          sort: state.sort === 'ASC' ? 'DESC' : 'ASC'
        }))
      } else {
        props.dispatch(sortDevices({ sort: state.sort || 'DESC' }))
        resetFilterss()
        setState(prevState=>({
          ...prevState,
          sort: state.sort === 'ASC' ? 'DESC' : 'ASC'
        }))
      }
    }
  
    
  // const  addDevices= ()=> {
  //     setState(prevState=>({
  //       ...prevState,
  //       isPopupVisable: !state.isPopupVisable
  //     }))
  //   }
  
    // shouldComponentUpdate (nextProps, nextState) {
    //   return !isEqual(nextProps, props) || !isEqual(nextState, state) // equals() is your implementation
    // }
  
   const selecteItem = data => {
      props.collapseSidebar()
      props.selecteItem(data)
    }
  
  const  editItem = data => {
      // console.log("da=====", data);
      props.collapseSidebar()
      props.editItem(data)
    }
  
  const  showWaslDetails =(data)=>{
      props.showWaslDetails(data)
    }
  
  //  const onlyUnique = (value, index, self) => {
  //     return self.indexOf(value) === index
  //   }
  
   const resetFilterss = () => {
      props.dispatch(resetFilters())
      setState(prevState=>({
        ...prevState,
        search: ''
      }))
    }
  
   const handleScroll = (values) => {
      const { hasNext, total, data } = props.devices;
      const { isFetching } = props;
       
      if (!isFetching && hasNext && data.length < total) {
        const { scrollTop, clientHeight, scrollHeight } = values;
        if (scrollTop + clientHeight >= scrollHeight-26) {
          const newPageSize = Math.min(data.length + defaultDevicesLength, total);
          props.dispatch(fetchMoreDevices(true, newPageSize));
        }
      }
    };
    
    const isCallingScrollForMonitoring=state.selection==="units"?handleScroll:state.selection==="resource"?props.handleScrollResources:props.handleScrollGroups
      const emptyLinks = null
      if (props.location.pathname === '/geofences') {
        return (
          <GeofenceSidebar
            {...props}
            collapseSidebar={props.collapseSidebar}
          />
        )
      }
      if (props.location.pathname === '/templateGenerator') {
        return (
          <TemplateGeneratorSidebar
            {...props}
            collapseSidebar={props.collapseSidebar}
          />
        )
      } else if (props.location.pathname === '/monitoring') {
        // const endMessage =
        //   props.devices && props.devices.total > 0 ? (
        //     <p style={{ textAlign: 'center', color: '#ccc' }}>
        //       -- {props.translate('end')} --
        //     </p>
        //   ) : (
        //     <p style={{ textAlign: 'center', color: '#ccc' }}>
        //       {props.translate('notFound')}
        //     </p>
        //   )
  
        return (
          <aside className='fms-sidebar scrollable-sidebar'>
            {checkPrivileges('device') && (
              <div className='fms-sidebar-monitoring'>
                <ul className='filter-row clearfix'>
                  <li className='filter-nav'>
                    <a
                      className={
                        'filter-nav-link ' +
                        (state.switchView === 'units' ? 'active' : '')
                      }
                      href={emptyLinks}
                      onClick={() => switchView("units")}
                    >
                      {props.translate('trackers')}
                    </a>
                    <a
                      className={
                        'filter-nav-link ' +
                        (state.switchView === 'groups' ? 'active' : '')
                      }
                      href={emptyLinks}
                      onClick={() => switchView("groups")}
                    >
                      {props.translate('groups')}
                    </a>
                    {checkPrivileges('device') && (
                      <a
                        className={
                          'filter-nav-link ' +
                          (state.switchView === 'resource' ? 'active' : '')
                        }
                        href={emptyLinks}
                        onClick={() => switchView('resource')}
                      >
                        {props.translate('resource')}
                      </a>
                    )}
                  </li>
                </ul>
                <div className='sidebar-bg-layer'>
                  <div
                    className='section-head clearfix section-head-filter'
                    style={{ padding: 5 }}
                  >
                    <ul className='filter-row pull-left'>
                    {state.switchView !== "resource" ? (
                      <li
                        style={{
                          paddingRight: 0,
                          paddingTop: 3,
                          paddingLeft: 0
                        }}
                      >
                        <label
                          className='checkbox'
                          style={{ display: 'inline-block' }}
                        >
                          <input
                            type='checkbox'
                            name='isChecked'
                            checked={state.isChecked}
                            onChange={toggleAllDevicess}
                          />
                          <span className='theme-input-checkbox'>
                            <CheckBoxIcon
                              className='on-active material-icons'
                              style={{fontSize:22, color:'inherit'}}
                            />
                            <CheckBoxOutlineBlankIcon
                              className='on-inactive material-icons'
                              style={{fontSize:22, color:'inherit'}}
                            />
                          </span>
                        </label>
                      </li>):null}
                      
                        <li>
                        {/* <TextField
                    label={props.translate('searchUnits')}
                    type='search'
                    placeholder='exp: ID, Name'
                    value={state.search}
                    onChange={props.searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  /> */}
                        <TextField
                          label={
                            state.selection === 'units' ? props.translate('searchUnits')  
                            : state.selection === 'groups' ?  props.translate('searchGroups')
                            : props.translate('searchResources') 
                          }
                          type='search'
                          placeholder='exp: ID, Name'
                          value={state.search}
                          onChange={searchItem}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                          InputLabelProps={{
                            style: {
                              maxWidth: state.selection === 'units' ? '80px' :'',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }
                          }}
                        />
                      </li> 
  
                      {props.filterList.length ||
                      props.filterTagsList.length ||
                      props.filterDriversList.length ? (
                        <li style={{ padding: '8px 0 5px' }}>
                          <Button onClick={resetFilterss}>
                            <ResetIcon
                              width='14'
                              height='14'
                              fill='currentColor'
                              style={{ marginRight: 5 }}
                            />
                            {props.translate('resetButton')}
                          </Button>
                        </li>
                      ) : (
                        <>
                          <li style={{ paddingLeft: 0, paddingTop: 3 }}>
                            <Link
                              to={'/monitoring'}
                              title=''
                              onClick={e => props.openVehicleTagsModal(e)}
                            >
                              <VehicleIcon
                                style={{ width: 20, height: 20, marginTop: 5 }}
                              />
                            </Link>
                          </li>
                          <li style={{ paddingTop:12, cursor:"pointer"}} onClick={()=>{props.showAllTooltTip()}}>
                              <RestoreIcon 
                                  className='actionButton' 
                                  fontSize='small' 
                                />
                          </li>
                          <li style={{ paddingLeft: 0, paddingTop: 3 }}>
                            <Link
                              to={'/monitoring'}
                              title=''
                              onClick={e => props.openDriverTagsModal(e)}
                            >
                              <DriverIcon
                                style={{ width: 20, height: 20, marginTop: 5 }}
                              />
                            </Link>
                          </li>
                          {/* {state.switchView === 'units' && (
                            <li style={{ padding: 0 }}>
                              <SortMenu
                                sortByName={sortByName}
                                sortByDeviceType={sortByDeviceType}
                                translate={props.translate}
                              />
                            </li>
                          )} */}
  
                          <li
                            style={{
                              paddingLeft: 0,
                              paddingTop: 0,
                              paddingRight: 0
                            }}
                          >
                            <a
                              href={emptyLinks}
                              onClick={() => props.toggleListView()}
                            >
                              {props.ListViewSwitch ? (
                                <AppsIcon className='sidebar-thumbs-icon' />
                              ) : (
                                <FormatListBulletedIcon className='sidebar-thumbs-icon' />
                              )}
                            </a>
                          </li>
                        </>
                      )}
                      <li style={{ paddingLeft: 0, paddingTop: 3 }}>
                        <PositionMenu
                          themecolors={props.themecolors}
                          menuSettings={state.menuSettings}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className='sidebar-devices-list'>
                    <Scrollbar
                      disableTracksWidthCompensation={true}
                      onScroll={isCallingScrollForMonitoring}
                      scrollerProps={{
                        renderer: props => {
                          const { elementRef, ...restProps } = props
                          return (
                            <div
                              {...restProps}
                              ref={elementRef}
                              id='scrollableDivDevices'
                            />
                          )
                        }
                      }}
                    >
               
                    {checkPrivileges('device') && props.resourceList ? 
                    <>
                    
                        <RecourcesList
                          monitoring
                          getSensorsData={props.getSensorsData}
                          disableEdit 
                          ItemChild={MonitoringListRow}
                          ItemChildProps={{collapseSidebar: props.collapseSidebar, 
                          deviceId: props.deviceId, 
                          monitoringMap: props.monitoringMap, 
                          openDeviceSettings: props.openDeviceSettings, 
                          showBox: props.showBox, 
                          themecolors: props.themecolors, 
                          trackId: props.trackId, 
                          dispatch: props.dispatch,
                          translate: props.translate }}
                          exact
                          fromArray={props.devices && props.devices.data || []}
                          {...{fetchNestedItems: props.fetchNestedItems, 
                          resources: props.resources, 
                          nestedResources: props.nestedResources, 
                          onLinkResource: props.onLinkResource, 
                          deleteResource: props.deleteResource, 
                          itemType: props.itemType, 
                          nextResources: props.nextResources, 
                          dispatch: props.dispatch, 
                          translate: props.translate
                          }}
                          /></> : checkPrivileges('device') &&
                        !props.resourceList ? (
                        props.devices.isFetching ? (
                          <Loader defaultStyle />
                        ) : (
                          <> 
                            <DevicesList
                                   fromArray={props.devices && props.devices.data || []}
                            nestedResources= {props.nestedResources} 
                            ItemChildProps={{collapseSidebar: props.collapseSidebar, 
                              deviceId: props.deviceId, 
                              monitoringMap: props.monitoringMap, 
                              openDeviceSettings: props.openDeviceSettings, 
                              showBox: props.showBox, 
                              themecolors: props.themecolors, 
                              trackId: props.trackId, 
                              dispatch: props.dispatch,
                              translate: props.translate }}
                              exact
                              copyToClipboard={props.copyToClipboard}
                            getSensorsData={props.getSensorsData}
                            {...state}
                            openDeviceSettings={props.openDeviceSettings}
                            isCheckedFn={isChecked}
                            collapseSidebar={props.collapseSidebar}
                            trackId={props.trackId}
                            deviceId={props.deviceId}
                            showBox={props.showBox}
                            monitoringMap={props.monitoringMap}
                            devices={props.devices.data}
                            translate={props.translate}
                            dispatch={props.dispatch}
                            themecolors={props.themecolors}
                            />
                            </>
                         )
                      ) : null }
                    </Scrollbar>
                  </div>
                </div>
              </div>
            )}
          </aside>
        )
      } else if (props.location.pathname === '/reports') {
        return <ReportsSidebar {...props} />
      }  else if (props.location.pathname === '/reportsHistory') {
        return <ReportsHistorySidebar {...props} />
      } else if (props.location.pathname === '/Dlogs') {
        return <MessagesSidebar {...props} />
      }  else if (props.location.pathname === '/commands') {
        return <CommandsSidebar {...props} />
      }  else if (props.location.pathname === '/invoice') {
        return <InvoicesSidebar {...props} />
      }  else if (props.location.pathname === '/garage') {
        return <GarageSidebar {...props} />
      }  else if (props.location.pathname === '/area') {
        return <AreaSidebar {...props} />
      }
       else if (props.location.pathname === '/services') {
        return <ServicesSidebar {...props} />
      } 
      else if (props.location.pathname === '/workFlow') {
        return <WorkFlow {...props} translate={props.translate}/>
      } 
      else if (props.location.pathname === '/metrics') {
        return <Metrics {...props} />
      } 
      else if (props.location.pathname === '/maintenance' || 
        props.location.pathname === '/maintenanceCreate' ||
        props.location.pathname.indexOf('/maintenanceUpdate') !== -1) {
        return <MaintenanceSidebar {...props} />
      } else if (
        props.location.pathname === '/notifications' ||
        props.location.pathname === '/notificationCreate' ||
        props.location.pathname.indexOf('/notificationUpdate') !== -1
      ) {
        return <NotificationSidebar {...props} />
      } else if (props.location.pathname === '/parameters') {
        return <ParametersSidebar {...props} />
      } else if (props.location.pathname.indexOf('/tracks') !== -1) {
        return state.trackLoading ? (
          <Loader />
        ) : (
          <TracksSidebar {...props} />
        )
      } else if (props.location.pathname === '/timemachine') {
        return state.trackLoading ? (
          <Loader />
        ) : (
          <TimemachineSidebar {...props} />
        )
      } else if (
        props.location.pathname.indexOf('/users') !== -1 ||
        props.location.pathname.indexOf('/addUser') !== -1
      ) {
        return (
          <aside className='fms-sidebar sidebar-bg-layer'>
            <div className='section-head clearfix section-head-filter'>
              <ul className='filter-row clearfix pull-right'>
                <li>
                  <SearchField
                    label={props.translate('searchUsers')}
                    type='search'
                    placeholder='exp: ID, Name'
                    value={state.search}
                    onChange={searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </li>
                {props.resourceList ?
                <li style={{ paddingTop: 8, paddingBottom: 4, paddingRight: 0 }}>
                  {props.logInUser.administrator &&
                    checkPrivileges('itemgroupCreate') && (
                      <Button
                        size='small'
                        disabled={!props.userLimit}
                        onClick={() => props.addResource()}
                      >
                        {props.translate('Create')}
                      </Button>
                    )}
                </li>:
                <li style={{ paddingTop: 8, paddingBottom: 4, paddingRight: 0 }}>
                {props.logInUser.administrator &&
                  checkPrivileges('userCreate') && (
                    <Button
                      size='small'
                      disabled={!props.userLimit}
                      onClick={() => props.addUser()}
                    >
                      {props.translate('Create')}
                    </Button>
                  )}
              </li>}
                {/* <li style={{ paddingTop: 8, paddingBottom: 4, paddingRight: 0 }}>
                  {props.logInUser.administrator &&
                    checkPrivileges('userCreate') && (
                      <Button
                        size='small'
                        disabled={!props.userLimit}
                        onClick={() => props.showResources()}
                      >
                        {props.translate('Resources')}
                      </Button>
                    )}
                </li> */}
                <li style={{ paddingTop: 8, paddingBottom: 4, paddingLeft: 0 }}>
                  <ExportImportMenu
                      fileName = 'users'
                      checkPrivilege = 'userCreate'
                      data = {props.users}
                      themecolors = {props.themecolors}
                    />
                </li>
              </ul>
            </div>
            {props.resourceList ? 
            <RecourcesList {...props}/> :
            <div className='sidebar-users-list'>
              <Scrollbar
                disableTracksWidthCompensation={true}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return (
                      <div {...restProps} ref={elementRef} id='scrollableDiv' />
                    )
                  }
                }}
              >
                <UsersShortList2
                  {...state}
                  match={props.match}
                  editItem={editItem}
                  removedItem={props.removedItem}
                  //selecteItem={selecteItem}
                  collapseSidebar={props.collapseSidebar}
                  logInUser={props.logInUser}
                  //selectedItemId={props.selectedItemId}
                  users={props.users}
                  translate={props.translate}
                />
              </Scrollbar>
            </div>}
          </aside>
        )
      } else if (
        props.location.pathname.indexOf('/resources') !== -1 
      ) {
        // const endMessage =
        //   props.itemPagination2 && props.itemPagination2.total > 0 ? (
        //     <p style={{ textAlign: 'center', color: '#ccc' }}>
        //       -- {props.translate('end')} --
        //     </p>
        //   ) : (
        //     <p style={{ textAlign: 'center', color: '#ccc' }}>
        //       {props.translate('notFound')}
        //     </p>
        //   )
        return (
          <aside className='fms-sidebar sidebar-bg-layer'>
            <div className='fms-sidebar-monitoring'>
              <div className='section-head clearfix section-head-filter' style={{ padding: 5 }}>
                <ul className='filter-row pull-left'>
                  <li
                  >
                  <TextField
                    label={props.translate('sharedSearch')}
                    type='search'
                    placeholder='exp: ID, Name'
                    value={state.search2}
                    onChange={(e)=>searchResourceItem(e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </li>
                {checkPrivileges('groupCreate') && ( <li 
                    style={{
                      paddingRight: 0,
                      paddingTop: 3,
                      paddingLeft: 0
                    }}>
                  <Button
                    size='small'
                    onClick={() => props.goAddGroup()}
                  >
                    {props.translate('Create')}
                  </Button>
                </li>)}
              </ul>
              </div>
              <div className='sidebar-users-list' id='scrollableDiv2'>
                {props.itemPagination2 ? (
                  <Scrollbar disableTracksWidthCompensation={true} 
                  onScroll={props.handleScrollResource}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return (
                      <div {...restProps} ref={elementRef} id='scrollableDiv2' />
                    )
                  }
                }}>
                    <GroupsShortList
                      {...state}
                      {...props}
                      editItem={editItem}
                      removedItem={props.removedItem}
                      collapseSidebar={props.collapseSidebar}
                      translate={props.translate}
                    />
                    </Scrollbar>
                ) : null}
              </div>
            </div>
          </aside>
        )
      } else if (props.location.pathname.indexOf('/units') !== -1) {
        return (
          <aside className='fms-sidebar'>
            <div className='fms-sidebar-monitoring'>
            {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
                <li className='filter-nav'>
                    <a
                      className={
                        'filter-nav-link ' +
                        (state.switchView === 'units' ? 'active' : '')
                      }
                      href={emptyLinks}
                      onClick={() => switchView("units")}
                    >
                      {props.translate('trackers')}
                    </a>
                    <a
                      className={
                        'filter-nav-link ' +
                        (state.switchView === 'groups' ? 'active' : '')
                      }
                      href={emptyLinks}
                      onClick={() => switchView("groups")}
                    >
                      {props.translate('groups')}
                    </a>
                    {checkPrivileges('device') && (
                      <a
                        className={
                          'filter-nav-link ' +
                          (state.switchView === 'resource' ? 'active' : '')
                        }
                        href={emptyLinks}
                        onClick={() => switchView('resource')}
                      >
                        {props.translate('resource')}
                      </a>
                    )}
                </li>
              </ul> : null}
              <div className='sidebar-bg-layer'>
                {checkPrivileges('device') ? (
                  <UnitsSidebar
                    {...props}
                    view={state.switchView}
                    search={state.search}
                    devices={props.devices}
                    searchItem={searchItem}
                    sortByName={sortByName}
                    isChecked={isChecked}
                    switchView={state.switchView}
                    resourceForSearch={resourceForSearch}
                    resourceSearch={state.resourceSearch}
                  />
                ) : null}
  
                {/* checkPrivileges('group') && state.newPage === 1 && (
                  <GroupsSidebar
                    {...props}
                    searchItem={searchItem}
                    sortByName={sortByName}
                    isChecked={isChecked}
                  />
                ) */}
              </div>
            </div>
          </aside>
        )
      } else if (props.location.pathname.indexOf('/drivers') !== -1) {
        
        const drivers =
          props.itemPagination && props.itemPagination.items
            ? props.itemPagination.items.map(row => {
                const attr = Object.keys(row.attributes)
                const others = {}
                attr.map(key => {
                  others[key] = row.attributes[key]
                  return null
                })
                return {
                  name: row.name,
                  uniqueId: row.uniqueId,
                  ...others
                }
              })
            : []
  
        const fields = []
  
        drivers.map(veh => {
          fields.push(...Object.keys(veh))
          return null
        })
  
        return (
          <aside className='fms-sidebar'>
          <div className='fms-sidebar-monitoring'>
          {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
            <li className='filter-nav'>
              <a
                className={
                  'filter-nav-link ' +
                  (!props.resourceList ? 'active' : '')
                }
                href={emptyLinks}
                onClick={() => props.showResources(false)}
              >
                {props.translate('sharedDrivers')}
              </a>
              <a
                className={
                  'filter-nav-link ' +
                  (props.resourceList ? 'active' : '')
                }
                href={emptyLinks}
                onClick={() => props.showResources(true)}
              >
                {props.translate('groups')}
              </a>
            </li>
          </ul> : null}
  
  
  
          <aside className='sidebar-bg-layer'>
            {checkPrivileges('driver') && (
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <SearchField
                      label={props.translate('searchDrivers')}
                      type='search'
                      placeholder='exp: ID, Name'
                      value={state.search}
                      onChange={searchItem}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </li>
                  {props.resourceList ?
                  <li style={{ paddingTop: 8, paddingBottom: 4}}>{/*, paddingRight: 0 }}> */}
                    {checkPrivileges('itemgroupCreate') && (
                      <Button size='small' fullWidth onClick={() => props.addResource()}>
                        {props.translate('sharedCreate')}
                      </Button>
                    )}
                  </li>:
                  <li style={{ paddingTop: 8, paddingBottom: 4}}>{/*, paddingRight: 0 }}> */}
                  {checkPrivileges('driverCreate') && (
                    <Button size='small' fullWidth onClick={() => props.addDriver()}>
                      {props.translate('sharedCreate')}
                    </Button>
                  )}
                  </li>}
                  <li style={{ paddingTop: 8, paddingBottom: 4, paddingLeft: 0 }}>
                    <ExportImportMenu
                      fileName = 'drivers'
                      checkPrivilege = 'driverCreate'
                      data = {drivers}
                      themecolors = {props.themecolors}
                    />
                  </li>
                </ul>
              </div>
            )}
            {props.resourceList ? 
            <RecourcesList 
              ItemChild={DriverListRow}
              ItemChildProps={props}
              fromArray={props.itemPagination.items}
              {...props} /> :
            <div className='sidebar-drivers-list'>
              <Scrollbar
                disableTracksWidthCompensation={true}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return (
                      <div {...restProps} ref={elementRef} id='scrollableDiv2' />
                    )
                  }
                }}
              >
                {!props.isDriverLoad ? checkPrivileges('driver') && props.itemPagination 
                  && props.itemPagination.items && props.itemPagination.items.length ? (
                  <DriversShortList
                    items={props.itemPagination.items}
                    allDriverLocation={props.allDriverLocation}
                    match={props.match}
                    editItem={editItem}
                    ServerSetting={props.ServerSetting}
                    removedItem={props.removedItem}
                    selecteItem={selecteItem}
                    translate={props.translate}
                  />
                ) : <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold'}}>
                      {props.translate('notFound')}
                    </p>:
                    <Loader/>}
              </Scrollbar>
            </div>}
          </aside>
          </div>
        </aside>
        )
      }//start binder
      else if (props.location.pathname.indexOf('/binders') !== -1) {
        const trailers =
          props.itemPagination && props.itemPagination.items
            ? props.itemPagination.items.map(row => {
                const attr = Object.keys(row.attributes)
                const others = {}
                attr.map(key => {
                  others[key] = row.attributes[key]
                  return null
                })
                return {
                  name: row.name,
                  uniqueId: row.uniqueId,
                  ...others
                }
              })
            : []
  
        const fields = []
  
        trailers.map(veh => {
          fields.push(...Object.keys(veh))
          return null
        })
  
  
        return (
          <aside className='fms-sidebar'>
          <div className='fms-sidebar-monitoring'>
          {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
            <li className='filter-nav'>
              <a
                className={
                  'filter-nav-link ' +
                  (!props.resourceList ? 'active' : '')
                }
                href={emptyLinks}
                onClick={() => props.showResources(false)}
              >
                {props.translate('sharedBinder')}
              </a>
              <a
                className={
                  'filter-nav-link ' +
                  (props.resourceList ? 'active' : '')
                }
                href={emptyLinks}
                onClick={() => props.showResources(true)}
              >
                {props.translate('groups')}
              </a>
            </li>
          </ul> : null}
          <aside className='sidebar-bg-layer'>
            {checkPrivileges('binder') && (
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <SearchField
                      label={props.translate('searchBinders')}
                      type='search'
                      placeholder='exp: ID, Name'
                      value={state.search}
                      onChange={searchItem}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </li>
                  {props.resourceList ? 
                  <li style={{ paddingTop: 8, paddingBottom: 4}}> {/*, paddingRight: 0 }}> */}
                    {checkPrivileges('itemgroupCreate') && (
                      <Button size='small' fullWidth onClick={() => props.addResource()}>
                        {props.translate('sharedCreate')}
                      </Button>
                    )}
                  </li>:
                  <li style={{ paddingTop: 8, paddingBottom: 4}}> {/*, paddingRight: 0 }}> */}
                    {checkPrivileges('binderCreate') && (
                      <Button size='small' fullWidth onClick={() => props.addTrailer()}>
                        {props.translate('sharedCreate')}
                      </Button>
                    )}
                  </li>}
                  <li style={{ paddingTop: 8, paddingBottom: 4, paddingLeft: 0 }}>
                    <ExportImportMenu
                      fileName = 'binders'
                      checkPrivilege = 'binderCreate'
                      data = {trailers}
                      themecolors = {props.themecolors}
                    />
                  </li>
                </ul>
              </div>
            )}
            {props.resourceList ? 
            <RecourcesList 
              ItemChild={TrailerListRow}
              ItemChildProps={props}
              fromArray={props.itemPagination.items}
            {...props} /> :
            <div className='sidebar-drivers-list'>
              <Scrollbar
                disableTracksWidthCompensation={true}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return (
                      <div {...restProps} ref={elementRef} id='scrollableDiv2' />
                    )
                  }
                }}
                
              >{!props.isTrailerLoad ? 
                checkPrivileges('binder') && props.itemPagination 
                && props.itemPagination.items && props.itemPagination.items.length? (
                <TrailerShortList
                  items={props.itemPagination.items}
                  allTrailerLocation={props.allTrailerLocation}
                  match={props.match}
                  editItem={editItem}
                  removedItem={props.removedItem}
                  selecteItem={selecteItem}
                  translate={props.translate}
                />
              ) : <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold' }}>
                    {props.translate('notFound')}
                  </p>
               : <Loader/>}
              </Scrollbar>
            </div>}
          </aside>
          </div>
          </aside>
        )
      } //end triler
      else if (props.location.pathname.indexOf('/schedules') !== -1) {
        const endMessage =
          props.itemPagination && props.itemPagination.total > 0 ? (
            <p style={{ textAlign: 'center', color: '#ccc' }}>
              -- {props.translate('end')} --
            </p>
          ) : (
            <p style={{ textAlign: 'center', color: '#ccc' }}>
              {props.translate('notFound')}
            </p>
          )
        return (
          <aside className='fms-sidebar sidebar-bg-layer'>
            {checkPrivileges('job') && (
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <SearchField
                      label={props.translate('searchSchedule')}
                      type='search'
                      placeholder='exp: ID, Name'
                      value={state.search}
                      onChange={searchItem}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </li>
                  <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                    {checkPrivileges('jobCreate') && (
                      <Button size='small' onClick={() => props.addJob()} style={{backgroundColor:props.themecolors.menuActiveBackground, color:props.themecolors.textColor}}>
                        {props.translate('Create')}
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
            )}
            <div className='sidebar-drivers-list'>
              <Scrollbar
                disableTracksWidthCompensation={true}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return (
                      <div {...restProps} ref={elementRef} id='scrollableJob' />
                    )
                  }
                }}
              >
                {checkPrivileges('job') && props.itemPagination ? (
                  <InfiniteScroll
                    dataLength={props.itemPagination.items.length}
                    next={props.fetchMoreItems}
                    hasMore={props.itemPagination.hasNext}
                    loader={<Loader defaultStyle={true} />}
                    scrollableTarget='scrollableJob'
                    endMessage={endMessage}
                  >
                    <JobsShortList
                      match={props.match}
                      editItem={editItem}
                      removedItem={props.removedItem}
                      selecteItem={selecteItem}
                      translate={props.translate}
                    />
                  </InfiniteScroll>
                ) : null}
              </Scrollbar>
            </div>
          </aside>
        )
      } else if (props.location.pathname.indexOf('/users') !== -1) {// eslint-disable-line no-dupe-else-if
        return (
          <aside className='fms-sidebar sidebar-bg-layer'>
            <div className='fms-sidebar-monitoring'>
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <SearchField
                      label={props.translate('searchUsers')}
                      type='search'
                      placeholder='exp: ID, Name'
                      value={state.search}
                      onChange={searchItem}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </li>
                  <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                    {props.logInUser.administrator && (
                      <Button size='small' onClick={() => props.addUser()}>
                        {props.translate('Create')}
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
              <div className='sidebar-devices-list'>
                <UsersShortList
                  {...state}
                  match={props.match}
                  editItem={editItem}
                  removedItem={props.removedItem}
                  selecteItem={selecteItem}
                  users={props.users}
                  collapseSidebar={props.collapseSidebar}
                  translate={props.translate}
                />
              </div>
            </div>
          </aside>
        )
      } else if (props.location.pathname.indexOf('/events') !== -1) {
        return (
          <EventsSidebar
            userEvents={props.userEvents}
            showNotification={props.showNotification}
            showFilterForm={props.showFilterForm}
            toggleFilterForm={props.toggleFilterForm}
            addRules={props.addRules}
            selectedItem={props.selectedItem}
            searchItem={searchItem}
            sortByName={sortByName}
            selecteItem={selecteItem}
            onCloseModal={onCloseModal}
            collapseSidebar={props.collapseSidebar}
            {...state}
            match={props.match}
            history={props.history}
          />
        )
      } else if (props.location.pathname.indexOf('/vehicles') !== -1 ||
                  props.location.pathname.indexOf('/vehicle') !== -1) {
                    const endMessage =
                    props.hasMore ===  false ? (
                        <p style={{ textAlign: 'center', color: '#ccc' }}>
                          -- {props.translate('end')} --
                        </p>
                      ) : (
                        <p style={{ textAlign: 'center', color: '#ccc' }}>
                          {props.translate('notFound')}
                        </p>
                      )
          
        const vehicles =
        props.vehicles
            ? props.vehicles.map(row => {
                const attr = Object.keys(row.attributes)
                const others = {}
                attr.map(key => {
                  others[key] = row.attributes[key]
                  return null
                })
                return {
                  label: row.label,
                  model: row.model,
                  vehicleType: row.vehicleType,
                  ...others,
                  garage: row.garage
                }
              })
            : []
  
        const fields = []
  
        vehicles.map(veh => {
          fields.push(...Object.keys(veh))
          return null
        })
        return (
        <aside className='fms-sidebar'>
          <div className='fms-sidebar-monitoring'>
          {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
            <li className='filter-nav'>
              <a
                className={
                  'filter-nav-link ' +
                  (!props.resourceList ? 'active' : '')
                }
                href={emptyLinks}
                onClick={() => props.showResources(false)}
              >
                {props.translate('sharedVehicle')}
              </a>
              <a
                className={
                  'filter-nav-link ' +
                  (props.resourceList ? 'active' : '')
                }
                href={emptyLinks}
                onClick={() => props.showResources(true)}
              >
                {props.translate('groups')}
              </a>
            </li>
          </ul> : null}
          <aside className='sidebar-bg-layer'>
            <div className='section-head clearfix section-head-filter'>
              <ul className='filter-row clearfix pull-right'>
                <li>
                  <SearchField
                    label={props.translate('searchVehicles')}
                    type='search'
                    placeholder='exp: ID, Name'
                    value={state.search}
                    onChange={searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </li>
                {props.resourceList ? 
                <li style={{ paddingTop: 8, paddingBottom: 4}}> {/*, paddingRight: 0 }}> */}
                  {checkPrivileges('itemgroupCreate') && (
                    <Button
                      fullWidth
                      size='small'
                      onClick={() => props.addResource()}
                    >
                      {props.translate('sharedCreate')}
                    </Button>
                  )}
                </li>: 
                <li style={{ paddingTop: 8, paddingBottom: 4}}> {/*, paddingRight: 0 }}> */}
                {/* {checkPrivileges('vehicleCreate') && (
                  <Button
                    fullWidth
                    size='small'
                    onClick={() => props.addItem()}
                  >
                    {props.translate('sharedCreate')}
                  </Button>
                )} */}
              </li>}
                <li style={{ paddingTop: 8, paddingBottom: 4, paddingLeft: 0 }}>
                    <ExportImportMenu
                      fileName = 'vehicles'
                      checkPrivilege = 'vehicleCreate'
                      data = {vehicles}
                      themecolors = {props.themecolors}
                    />
                </li>
              </ul>
            </div>
            {props.resourceList ? 
            <RecourcesList
            ItemChild={VehicleListRow}
            ItemChildProps={props}
            fromArray={props.vehicles}
             {...props} /> : 
            <div className='sidebar-users-list'>
              <Scrollbar
                disableTracksWidthCompensation={true}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return (
                      <div {...restProps} ref={elementRef} id='scrollableVehicle' />
                    )
                  }
                }}
              >
                {checkPrivileges('vehicle') && props.itemPagination ? (
                 <InfiniteScroll
                 dataLength={props?.userVehicles?.length}
                 next={props.getUserVehicles}
                 hasMore={props.hasMore}
                 // loader={<Loader defaultStyle={false} />}
                 scrollableTarget='scrollableVehicle'
                 endMessage={endMessage}
               >
                    <VehicleShortList
                      {...state}
                    userVehicles={props.userVehicles}

                      itemPagination={props.itemPagination}
                      allVehicleLocation={props.allVehicleLocation}
                      match={props.match}
                      editItem={editItem}
                      showWaslDetails={showWaslDetails}
                      removedItem={props.removedItem}
                      selecteItem={selecteItem}
                      ServerSetting={props.ServerSetting}
                      collapseSidebar={props.collapseSidebar}
                      translate={props.translate}
                    />
                </InfiniteScroll>
                 ) : null}
              </Scrollbar>
            </div>}
          </aside>
          </div>
        </aside>
        )
      } else {
        return ''
      }
    
  }
  export default SidebarInner