import React from 'react';
import { connect } from 'react-redux';
import Layout from './../../Layout';
import { AcManagements } from '../../Components/AccountManagements/accountManagements';
import { withLocalize } from 'react-localize-redux';

const AccountManage = (props) => {
  // const [userInfoFront, setUserInfoFront] = useState({});
  // const [userFetch, setUserFetch] = useState(false);

  return (
    <Layout
      {...props}
      classFromChildren={'has-padding no-sidebar'}
      noSidebar
    >
      <AcManagements {...props} />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting
});

const AccountManagements = connect(mapStateToProps, {})(withLocalize(AccountManage));
export default AccountManagements;
