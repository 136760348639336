import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import TextField from './TextField';
import MenuItem from './MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@mui/material/Paper';

const renderInputComponent = (inputProps) => {
  const { inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        }
      }}
      {...other}
    />
  );
};

const Example = (props) => {
  const [state, setState] = useState({
    value: '',
    suggestions: [],
    array: []
  });

  useEffect(() => {
    let { suggestions, translate, prefix, value } = props;
    if (suggestions) {
      if (translate) {
        setState(prevState => ({
          ...prevState,
          value,
          array: suggestions.map(s => {
            return {
              name: translate(prefix ? prefix + s : s),
              value: s
            };
          })
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          value,
          array: suggestions.map(s => {
            return { name: s, value: s };
          })
        }));
      }
    }
  }, [props.suggestions, props.translate, props.prefix, props.value]);

  useEffect(() => {
    if (props.value !== state.value) {
      setState(prevState => ({ ...prevState, value: props.value }));
    }
  }, [props.value]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return state.array.filter(
      lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  const onChange = (event, { newValue }) => {
    if (newValue.name) {
      setState(prevState => ({
        ...prevState,
        value: newValue.name
      }));
      if (props.handleChange) {
        props.handleChange(props.fieldName, newValue.value);
      }
    } else {
      setState(prevState => ({
        ...prevState,
        value: newValue
      }));
      if (props.handleChange) {
        props.handleChange(props.fieldName, newValue);
      }
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setState(prevState => ({
      ...prevState,
      suggestions: getSuggestions(value)
    }));
  };

  const onSuggestionsClearRequested = () => {
    setState(prevState => ({
      ...prevState,
      suggestions: []
    }));
  };

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    return (
      <MenuItem
        buttonRef={suggestion.innerRef}
        selected={isHighlighted}
        component='div'
      >
        {parts.map(part => (
          <span
            key={part.text}
            style={{ fontWeight: part.highlight ? 700 : 400 }}
          >
            {part.text}
          </span>
        ))}
      </MenuItem>
    );
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion;
  };

  const drawValue = () => {
    return true;
  };

  const { value, suggestions } = state;

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: props.placeholder || 'Type here...',
    value,
    label: props.label || 'Label',
    onChange: onChange
  };

  // Finally, render it!
  return (
    <Autosuggest
      renderInputComponent={renderInputComponent}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={drawValue}
      inputProps={inputProps}
      theme={{
        container: {
          position: 'relative'
        },
        suggestionsContainerOpen: {
          position: 'absolute',
          zIndex: 99,
          marginTop: 0,
          left: 0,
          right: 0,
          // maxHeight: 300,
          // overflow: 'auto'
        },
        suggestionsList: {
          margin: 0,
          padding: 0,
          listStyleType: 'none'
        },
        suggestion: {
          display: 'block'
        }
      }}
      renderSuggestionsContainer={options => (
        <Paper {...options.containerProps} square style={{maxHeight:100,overflowY:"auto"}}>
          {options.children}
        </Paper>
      )}
    />
  );
};

export default Example;
