import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../../../Components/common/Checkbox'
import Style from 'style-it'
import { ReactComponent as MaintenanceIcon } from './../../../assets/nav/spanner.svg'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
import instance from '../../../axios'

const MaintenanceFilterModal = (props) => {

  const [state, setState] = useState({
    list: {},
    others: [],
    search: '',
    isFetch: true,
    isLoader: true,
    isChecked: false,
    currentPage: 20,
    page: 1,
    itemsPagination: {
      items: [],
      total: 0,
      currentPage: 0,
      hasNext: true,
    }
  })


  useEffect(() => {
    if (state.page && state.currentPage && props.logInUser && state.isFetch !== undefined) {
      fetchMaintenanceHistory(state.page, state.currentPage, props.logInUser);
    }
  }, [state.page, state.currentPage, props.logInUser, state.isFetch]);
  


 const fetchMaintenanceHistory = (page, perPage, logInUser) => {
    let items = state.itemsPagination.items
    if (props.filterType === 'reminder') {
      if (logInUser && logInUser.id) {
        instance({
          method: 'GET',
          url: `/api/maintenance/get?userId=${logInUser.id}&page=${page}&limit=${perPage}`,
        })
          .then(response => {
            setState(prevState=>({
              ...prevState,
              itemsPagination: {
                ...response,
                items: items.concat(response.data),
              },
              isLoader: false,
              devicesList: response,
              currentPageHist: response.hasNext ? response.page + 1 : response.page,
            }))
          }).catch(() => {
            // errorHandler(error, props.dispatch)
          })
          setState(prevState=>({
            ...prevState, isFetch: false }))
      }
    } else {
      instance({
        method: 'GET',
        url: `/api/services/${props.filterType}?&page=${page}&limit=${perPage}&status=${props.statusType}`,
      })
        .then(response => {
          setState(prevState=>({
            ...prevState,
            itemsPagination: {
              ...response,
              items: items.concat(response.data),
            },
            isLoader: false,
            devicesList: response,
            currentPageHist: response.hasNext ? response.page + 1 : response.page,
          }))
        }).catch(() => {
          // errorHandler(error, props.dispatch)
        })
        setState(prevState=>({
          ...prevState, isFetch: false }))
    }
  }

  const fetchMoreDevices = () => {
    if (state.devicesList) {
      setState(prevState => ({
        ...prevState,
        page: state.devicesList.hasNext ? state.devicesList.page + 1 : state.devicesList.page
      }));
    }
  };

    const endMessage =
      state.devicesList && state.devicesList.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {props.translate('notFound')}{' '}
        </p>
      )
    return (
      <Style>
        {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
            background: ${props.themecolors && props.themecolors.themeLightColor};
            color: ${props.themecolors && props.themecolors.themeLightInverse};
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            background: ${props.themecolors.themeListingColor};
          }
          .selector-list {
            padding: 0;
            margin: 0;
            color: ${props.themecolors.themeListingInverse};
            list-style: none
          }
          .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px;
            display: flex;
            justify-content: space-between
        }`}
        <div style={{ width: '100%' }}>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className='filter-row pull-left'>
              {!props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3 }}>
                  <Checkbox
                    checked={state.isChecked}
                    onChange={toggleItems}
                    label={props.translate('selectAll')}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!props.readOnly ? (
            <div className='selector-count'>
              <span><span style={{ marginRight: 3 }}>{Object.keys(state.list).length}</span>
                {props.translate('UnitsSelected')}</span>

              <span><span style={{ marginRight: 3 }}>{Object.keys(state.others).length}</span>
                {props.translate('more')}</span>
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              height: 23 * (props.rows || 10)
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id='scrollableDeviceSelector'
                  />
                )
              }
            }}
          >
            {!state.isLoader && state.itemsPagination && state.itemsPagination.items ? (
              <InfiniteScroll
                dataLength={state.itemsPagination.items.length}
                next={() => fetchMoreDevices()}
                hasMore={state.itemsPagination.hasNext}
                loader={<Loader defaultStyle={true} />}
                scrollableTarget='scrollableDeviceSelector'
                endMessage={endMessage}
              >
                <ul className='selector-list'>
                  {state.itemsPagination.items && state.itemsPagination.items.map(row => (
                    <li key={row.id}>
                      <label
                        className={state.list[row.id] ? 'active' : null}
                      >
                        {!props.readOnly ? (
                          <span className='selector-check'>
                            <Checkbox
                              checked={state.list[row.id]}
                              value={row}
                              onChange={onChange}
                            />
                          </span>
                        ) : null}
                        {props.hideIcons ? null : (
                          <span className='selector-image'>
                            <MaintenanceIcon
                              fill="currentColor"
                              style={{ width: 18, marginTop: 2 }}
                            />
                          </span>
                        )}
                        <span className='selector-label'>{row.name}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </InfiniteScroll>
            ) :
              <Loader defaultStyle={true} />
            }
          </Scrollbar>
        </div>
      </Style>
    )
  }

const mapState = state => ({
  logInUser: state.logInUsers,
  devices3: state.devices3,
  devicesList: state.devices,
  themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(MaintenanceFilterModal))
