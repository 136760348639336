import React, {  useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
// import ReactTable from 'react-table'
import Dialog from './../common/Dialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from './../common/Switch'
import Button from './../common/Button'
// import isEqual from 'react-fast-compare'
import Panel from './../common/Panel'
import styled from 'styled-components'
// import moment from 'moment'
// import { getDateTimeFormat, PrepareAddress } from '../../Helpers'
import { ReactBaseTable } from '../common/ReactBaseTable'

// const TheadThStyle = {
//   fontSize: 13,
//   fontWeight: 700,
//   padding: 5
// }

// function DrawTableDateCell (props) {
//   const { children } = props

//   if (children && children.f) {
//     let v = null
//     if (Object.keys(children).length) {
//       v = children.v
//       const c = children.f

//       switch (children.f) {
//         case 'distance':
//           v = Math.round(children.v * 100) / 100
//         break
//         case 'entranceTime':
//         case 'exitTime':
//         case 'startTime':
//         case 'endTime':
//         case 'eventTime':
//         case 'serverTime':
//           v = children.r
//             ? moment(children.r).format(getDateTimeFormat())
//             : null
//           break

//         case 'address':
//           if (v) {
//             v = <PrepareAddress {...{...props, columnName: c, start: 'latitude', end: 'longitude'}} />
//           }
//         break

//         case 'entranceAddress':
//           if (v) {
//             v = <PrepareAddress {...{...props, columnName: c, start: 'entranceLat', end: 'entranceLon'}} />
//           }
//         break

//         case 'exitAddress':
//           if (v) {
//             v = <PrepareAddress {...{...props, columnName: c, start: 'exitLat', end: 'exitLon'}} />
//           }
//         break

//         case 'startAddress': 
//           if (v) {
//             v = <PrepareAddress {...{...props, columnName: c, start: 'startLat', end: 'startLon'}} />
//           }
//         break

//         case 'endAddress':
//           if (v) {
//             v = <PrepareAddress {...{...props, columnName: c, start: 'endLat', end: 'endLon'}} />
//           }
//         break

//         case 'attributes':
//           v =
//             typeof children.v === 'object'
//               ? JSON.stringify(children.v)
//               : children.v
//           break
//         default:
//           break
//       }
//     }

//     return (
//       <div
//         style={{ ...props.style, fontSize: 13, padding: 5 }}
//         className='rt-td'
//       >
//         {v}
//       </div>
//     )
//   } else {
//     if (props && props.children && Object.keys(props.children).length) {
//       return (
//         <div
//           style={{
//             ...props.style,
//             alignItems: 'center',
//             justifyContent: 'center',
//             display: 'flex'
//           }}
//         />
//       )
//     } else {
//       return (
//         <div
//           style={{ ...props.style, fontSize: 13, padding: 5 }}
//           className='rt-td'
//         ></div>
//       )
//     }
//   }
// }

// function DrawTFootCell (props) {
//   const { children, total } = props
//   const c = children
//   let v = total ? total.v : ''

//   switch (c) {
//     case 'distance':
//       v = Math.round(v * 100) / 100
//       break
//     default:
//       break
//   }
//   return (
//     <div
//       {...props}
//       style={{
//         ...props.style,
//         fontSize: 13,
//         padding: '7px 14px',
//         fontWeight: 700
//       }}
//       className='rt-td'
//     >
//       {v}
//     </div>
//   )
// }
// 
// export default class SummeryView extends Component {
const SummeryView = (props) => {

  const [state, setState] = useState({
      eventsDialog: false,
      dialogColumns: {},
      columns: []
    })

  const handleClick = (event) => {
    event.stopPropagation()
    setState((prevState) => ({ ...prevState, eventsDialog: true }))
  }

  const handleChange = (event, name) => {
    props.handleChange(event, name)
  }

  const onCloseModal = () => {
    setState((prevState) => ({ ...prevState, eventsDialog: false }))
  }

  useEffect(() => {
    props.onRender()
  }, [])
  
  // const shouldComponentUpdate (nextProps, nextState) {
  //   return !isEqual(nextProps, props) || !isEqual(nextState, state)
  // }

  // componentDidUpdate () {
  //   props.onRender()
  // }

  // render () {
    const ColumnsButton = styled.div`
      margin-left: auto;
      color: #fff;
    `

    const { table, hiddenColumns/* , disableFilter, toggleFilter */ } = props

    return (
      <div>
        <Panel
          title={props.title}
          bodyPadding={0}
          open={true}
          headerActions={
            <ColumnsButton>
              {/* <Button
              size='small'
              color='inherit'
              style={{marginLeft: 'auto' }}
              className="button-white"
              onClick={e => toggleFilter(e)}
              >
                {disableFilter ? 'Show Filter' : 'Hide Filter'}
                
              </Button>  |  */}
              <Button
                size='small'
                color='inherit'
                style={{marginLeft: 'auto' }}
                onClick={e => handleClick(e, 'eventsDialog')}
              >
                Show/Hide Columns
              </Button>
            </ColumnsButton>
          }
        >
          <ReactBaseTable
            hiddenColumns={hiddenColumns}
            columns={props.columns}
            data={table.data.rows}
            total={table.data.total}
          />
        </Panel>
        {state.eventsDialog && (
          <Dialog
            key='eventsDialog'
            visable={true}
            title='Show/Hide Columns'
            onClose={onCloseModal}
            isButtonVisable={true}
            draggable={true}
            cancelText='Close'
            bodyPadding={20}
          >
            <Grid container direction='row'>
              {Object.keys(props.dialogColumns).map(k => (
                <Grid item lg={6} key={k}>
                  <FormControlLabel
                    key={k}
                    classes={{ label: 'form-control-label' }}
                    control={
                      <Switch
                        key={k}
                        checked={props.dialogColumns[k].checked}
                        onChange={e => handleChange(e, k)}
                        value={k}
                      />
                    }
                    label={props.dialogColumns[k].label}
                  />
                </Grid>
              ))}
            </Grid>
          </Dialog>
        )}
      </div>
    )
  // }
}
export default SummeryView