import React from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { setIconOnMap2 } from '../../Helpers';

class CustomMarkerNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: props.position,
      rotation: 0,
    };
    this.animId = null;
    this.runningPosition = null;
    this.startTime = null;
    this.startPosition = null;
    this.targetPosition = null;
    this.startRotation = null;
    this.targetRotation = null;
  }

  setIcon = (course) => {
    return L.divIcon({
      iconUrl:
        '/assets/category/default/' +
        (this.props.category || 'default') +
        'top.svg',
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      tooltipAnchor: [0, -20],
      className: 'custom-marker',
      html: this.props?.logInUser?.attributes?.unitsViewType === 'default' 
      ? `<img
      style="transform: rotate(${course}deg)"
        src=
          '/assets/category/default/${this.props.category ||
            'default'}top.svg'
        
        alt=''
      />
      ` :
       `<p style="transform: rotate(${course}deg)">${setIconOnMap2(this.props.category,this.props.status,this.props?.motionStatus,this.props?.logInUser?.attributes?.unitsViewType, this.props.color)}</p>`
      // html: `<img
      //   style="transform: rotate(${course}deg)"
      //     src=
      //       '/assets/category/default/${this.props.category ||
      //         'default'}top.svg'
          
      //     alt=''
      //   />`
    })
  }


  angle = (cx, cy, ex, ey) => {
    var dy = ey - cy;
    var dx = ex - cx;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // radians to degrees
    return theta;
  };

  getDistance = (startPosition, targetPosition) => {
    const R = 6371e3; // metres
    const φ1 = startPosition.lat * Math.PI / 180;
    const φ2 = targetPosition.lat * Math.PI / 180;
    const Δφ = (targetPosition.lat - startPosition.lat) * Math.PI / 180;
    const Δλ = (targetPosition.lng - startPosition.lng) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // in metres
    return distance;
  };

  startAnimation = (startPosition, targetPosition, startRotation, targetRotation) => {
    const distance = this.getDistance(startPosition, targetPosition);
    this.duration = this.calculateDuration(distance);
    this.startTime = Date.now();
    this.startPosition = startPosition;
    this.targetPosition = targetPosition;
    this.startRotation = startRotation;
    this.targetRotation = targetRotation;
    this.animId = requestAnimationFrame(this.updateMarkerPosition);
  };

  calculateDuration = (distance) => {
    const speed = this.props.speed*1000/3600; 
    return distance / speed * 1000; 
  };

  updateMarkerPosition = () => {
    const elapsedTime = Date.now() - this.startTime;
     this.duration = this.calculateDuration(this.getDistance(this.startPosition, this.targetPosition));
    let newPosition = this.state.position;
    let newRotation = this.state.rotation;

    if (elapsedTime < this.duration) {
      const progress = elapsedTime / this.duration;
      newPosition = this.interpolatePosition(this.startPosition, this.targetPosition, progress);
      newRotation = this.interpolateRotation(this.startRotation, this.targetRotation, progress);
      this.setState({ position: newPosition, rotation: newRotation },()=>{
        if(this.markerRef){
          this.markerRef.leafletElement?.setIcon(this.setIcon(newRotation));
        }
      });
      this.animId = requestAnimationFrame(this.updateMarkerPosition);
    } else {
      newPosition = this.targetPosition;
      newRotation = this.targetRotation;
      this.setState({ position: newPosition, rotation: newRotation },()=>{
        if(this.markerRef){
          this.markerRef.leafletElement?.setIcon(this.setIcon(newRotation));
        }
      });
      cancelAnimationFrame(this.animId);
      this.animId = null;
    }
    if (this.props.tracking&&this.props.status==="moving") {
      console.error("true")
      this.updateMapCenter(newPosition.lat, newPosition.lng);
    }
  };

  interpolatePosition = (startPosition, targetPosition, progress) => {
    const lat = startPosition.lat + progress * (targetPosition.lat - startPosition.lat);
    const lng = startPosition.lng + progress * (targetPosition.lng - startPosition.lng);
    return { lat, lng };
  };

  interpolateRotation = (startRotation, targetRotation, progress) => {
    return startRotation + progress * (targetRotation - startRotation);
  };
calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // Earth's radius in meters
  const φ1 = lat1 * Math.PI / 180;
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lon2 - lon1) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // in meters
  return distance;
};
  componentDidUpdate(prevProps) {

    if (this.props.position !== prevProps.position) {
      const { lat: prevLat, lng: prevLng } = prevProps.position || {};
      const { lat: newLat, lng: newLng } = this.props.position || {};

      if ((newLat !== prevLat || newLng !== prevLng) && (newLat !== null && newLng !== null)) {
        const newRotation = this.angle(prevLng, prevLat, newLng, newLat);
        this.startAnimation(this.state.position, this.props.position, this.state.rotation, newRotation);
      } else {
        this.setState({ position: this.props.position });
      }
    }

    if (this.markerRef) {
      const heading = this.angle(this.state.position.lng, this.state.position.lat, this.props.position.lng, this.props.position.lat);
      this.markerRef.leafletElement?.setIcon(this.setIcon(heading));
    }
if(this.props.trackId!==prevProps.trackId&&this.props.status==="moving"){
  this.updateMapCenter = (lat, lng) => {
    if (this.props.mapRef) {
      this.props.mapRef.setView([lat, lng], this.props.zoom || this.props.mapRef.getZoom());
    }
  }
}
}

  updateMapCenter = (lat, lng) => {
    if (this.props.mapRef) {
      this.props.mapRef.setView([lat, lng], this.props.zoom || this.props.mapRef.getZoom());
    }
  };



  setMarkerRef = (ref) => {
    this.markerRef = ref;
    if (ref) {
      const heading = this.angle(this.state.position.lng, this.state.position.lat, this.props.position.lng, this.props.position.lat);
      ref.leafletElement?.setIcon(this.setIcon(heading));
    }
  };
  stop = () => {
    return this.animId && cancelAnimationFrame(this.animId), this.animId = null;
  }
  componentWillUnmount () {
    this.stop(this.animId);
  }

  render() {
    const { position } = this.state;
    return <Marker {...this.props} position={position} ref={this.setMarkerRef} />;
  }
}

export default CustomMarkerNew;
