import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { withLocalize } from 'react-localize-redux'
// import { SnackbarProvider, } from 'notistack'
import { connect } from 'react-redux'
import { checkPrivileges, errorHandler, selectAll, setAttributeFormat, getDateTimeFormat } from '../../Helpers'
import Layout from './../../Layout'
import Button from '../../Components/common/Button'
import Notifications from 'react-notification-system-redux'
import EmptyState from '../../Components/common/EmptyState'
import Dialog from '../../Components/common/Dialog'
import { getMaintenance, addMaintenance, removedMaintenance } from '../../Actions/Maintenance'
// import Strings from './../../lang/en.json'
import ItemsSelector from '../Units/Components/itemsSelector'
import { ReactComponent as MaintenanceIcon } from './../../assets/nav/spanner.svg'
import { removedUserDeviceWiseSelectedList, userDeviceWiseSelectedList } from '../../Actions/Notifications'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { removeUser } from '../../Actions/Users'
import moment from 'moment'
import { removeDevice } from '../../Actions/Devices'
import CustomDialog from '../../Components/common/Dialog'
import { CreateMaintenanceForm } from './CreateMaintenanceForm'
import { MaintenanceForm } from './MaintenanceForm'
// import { UpdateMaintenanceHistoryForm } from './UpdateMaintenanceHistoryForm'
import { MaintenanceComp } from './MaintenanceComp'
import { MaintenanceHistoryForm } from './MaintenanceHistoryForm'
import { CreateExpense } from './CreateExpense'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Tooltip, Grid } from '@mui/material'
import { SingleItem } from '../../Hooks/useSingleItem'
import { store } from '../../index'
import { Provider } from 'react-redux'
import instance from '../../axios'
import { addDays } from 'date-fns'
// import { ServerSetting } from '../../Reducers/ServerSetting'
import Table from '../../Components/common/TableWithColors'
import Axios from 'axios'

const CancelToken = axios.CancelToken
let source
let tempFile = [];
const servicesAttributes = []
let driversList = []
// let garageList = []
let vehiclesList = []
let subTypes = []
// let allSubTypes = []
// let emptyLink = null

let expenseType = []
let allExpenseTypes = []
// let areaList = []
const statusTypes = [
  {
    key: 'completed',
    name: 'completed',
  },
  {
    key: 'required',
    name: 'required',
  },
]
const date = new Date()
const defaultExpenseForm = {
  name: '',
  description: '',
  odometer: '',
  serviceTime: new Date(),
  expireTime: addDays(date, 7),
  quantity: '',
  cost: '',
  address: '',
  coordinates: '',
  service: 'Service',
  type: '',
  driver: 'Driver',
  vehicles: '',
  device: '',
  status: 'required',
  files: '',
  engineHours: '',
  selectedDateTime: '',
  currentEngineHours: 0,
  currentOdometer: 0,
  showStats: false,
  vehicleId: [],
  selectedvehicleId: [],
  // areaId:[],
  // selectedareaId:[],
  fromDate: '',
  loadingSidebar: false,
  toDate: '',
  attributes: {
    updateMaintenance: false,
    mileage: 0,
    hours: 0,
    date: 0,
    status: true,
    mailTo: "",
    email: []
  }

}
const DataFormat = (data, totalCost, totalQuantity, serviceData, updateCase) => {
  return {
    service: {
      id: data && data.id ? data.id : 0,
      updatedBy: data && data.updatedBy ? data.updatedBy : '',
      status: data && data.status ? data.status : 'completed',
      name: data && data.name ? data.name : '',
      description: data && data.description ? data.description : null,
      odometer: data && data.odometer ? parseFloat(data.odometer) * 1000 : '',
      // typeId: data && data.type && data.type.id ? data.type.id : '3',
      quantity: totalQuantity ? totalQuantity : '',
      cost: totalCost ? totalCost : '',
      address: data && data.address ? data.address : null,
      coordinate: data && data.coordinates ? data.coordinates : '30.00,50.00',
      vehicleId: data && data.vehicles && data.vehicles.id ? parseInt(data.vehicles.id) : '',
      // deviceId: data && data.device && data.device.id ? parseInt(data.device.id) : '',
      deviceId: data && data.deviceId || '',
      driverUniqueId: data && data.driver && data.driver.uniqueId ? data.driver.uniqueId : null,
      // garageId:data && data.garage && data.garage.id ? data.garage.id : null,
      maintenanceId: data && data.maintenanceId ? data.maintenanceId : '',
      files: data.files,
      createdTime: data && data.createdTime ? data.createdTime : '',
      serviceTime: data && data.serviceTime ? data.serviceTime : '',
      expireTime: data && data.expireTime ? data.expireTime : '',
      userId: data && data.userId ? data.userId : 1,
      engineHours: data && data.engineHours ? parseInt(data.engineHours) * (1000 * 3600) : 0,
      tag1: data && data.tag1 ? data.tag1 : '',
      tag2: data && data.tag2 ? data.tag2 : '',
      tag3: data && data.tag3 ? data.tag3 : '',
      refNum: data && data.refNum ? data.refNum : '',
      refdate: data && data.refdate ? data.refdate : '',
      // areaId: data && data.areaId && data.areaId.id ? data.areaId.id : data.areaId && data.areaId.key ? data.areaId.key: '',
      attributes: data && data.attributes ? { ...data.attributes } : {}
    },
    serviceExpenseCollection: updateCase ? [] : [...serviceData]
  }
}


// class Maintenance extends Component {
const Maintenance = (props) => {
  const [isCallVehicle,setIsCallVehicle]=useState(false)

  const [state, setState] = useState({
    maintenanceForm: { attributes: {} },
    maintenanceHistForm: {},
    historyId: '',
    totalCount: 0,
    mode: 'due',
    tab: 'due',
    isVisible: true,
    // histFormVisible: false,
    onDeleteConfirm: false,
    onDeleteExpense: false,
    analyticData: '',
    anchorEl: null,
    countChecked: 0,
    // countChecked: 2,
    statusFilter: { required: true, scheduled: true },
    filterStatusType: [],
    filterType: 'due',
    imageFile: '',
    enableImagePopup: false,
    selectMaintenanceId: '',
    itemSearch: '',
    isDataRecieved: false,
    loader: false,
    model: false,
    assignMode: '',
    currentMaintenance: '',
    entityId: 0,
    importedData: [],
    image64Type: [],
    showColorTable: false,
    initFetch: false,
    currentPage: 1,
    currentPageHist: 1,
    pagesize: 20,
    pagesizeHist: 20,
    searchVehicleId: { id: '', label: props.translate('searchVehicles') },
    // searchAreaId: {id:'', label: props.translate('searchAreas')},
    selectedVehicleId: '',
    // selectedAreaId: '',
    selectedDate: '',
    statusMode: false,
    completeMode: false,
    showDueDialog: false,
    showDialogBtn: false,
    vehicleDueMaintenance: [],
    historyEdit: false,
    disabledSidebarBtn: true,
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 0,
      currentDevice: props.deviceId,
      hasNext: true,
    },
    itemsPagination: {
      items: [],
      total: 0,
      currentPage: 0,
      hasNext: true,
    },
    deleteForm: {},
    expenseForm: {
      ...defaultExpenseForm
    },
    serviceExpenseCollection: [{ quantity: '1', cost: '1', expenseSubTypeId: '', expenseTypeId: '', selectedSubTypes: [] }],
    resultMessage: 'No Items',
    showDueMaintenancePopUp: false,
    sideBarStatus: '',
    passDate: ''
  }
  )
  const [callUpdate, setCallUpdate] = useState(false)

  useEffect(() => {
    if (props.location.pathname === '/maintenanceCreate') {
      setState((prevState) => ({ ...prevState, mode: 'create' }))
    }
    if (props.location.pathname.indexOf('/maintenanceUpdate') !== -1) {
      openEditMaintenanceForm()
      getImage()
    }

    if (
      props.logInUser &&
      props.logInUser.id &&
      state.initFetch === false
    ) {
      setState((prevState) => ({
        ...prevState,
        initFetch: true
      }
      ))
      // getGarageList()
    }
    maintenanceAnalytic()
    getExpenseTypes()
  }, [])



  // const getAreaList =() =>{
  //   instance({
  //     url: `/api/areas/get?&page=1&limit=10&search=`,
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //   })
  //       .then(() => {
  //         // areaList = (res.data).map(item =>{ 
  //         //   return (
  //         //      {
  //         //       key:item.id,
  //         //       name:item.name,
  //         //       label:item.name,
  //         //       uniqueId:item.id,
  //         //       valueType:'string'
  //         //     }
  //         //    )
  //         // })

  //   }).catch(() => {
  //     // errorHandler(error, props.dispatch)
  //   })
  // }



  // componentWillReceiveProps (n) {
  //   if (n.logInUser && n.logInUser.id && state.initFetch === false) {
  //     setState(
  //       {
  //         initFetch: true
  //       },
  //       () => {
  //         fetchMaintenanceHistory(
  //           state.currentPageHist,
  //           state.pagesizeHist
  //         )
  //       }
  //     )
  //   }
  // }

  const openEditMaintenanceForm = () => {
    let { maintenance } = props
    maintenance.map(item => {
      if (parseInt(props.match.params.id) === item.id) {
        editMaintenanceForm([item])
      }
    })
  }

  const fetchMoreItems = () => {
    fetchData(
      props.logInUser,
      state.currentPage,
      state.pagesize
    )
  }
  const fetchMoreItemsHist = () => {
    fetchMaintenanceHistory(
      state.currentPageHist,
      state.pagesizeHist
    )
  }
  const getImage = async (id) => {
    try {

      const response = await Axios.get(`/api/media/maintenance/${id}`, {
        responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
      });
      const filename = id;
      const parts = filename.split('.');
      const extension = parts[parts.length - 1];


      if (response.status === 200) {
        // Convert the array buffer to base64
        console.error("response.data=======", response);
        const base64Image = Buffer.from(response.data, 'binary').toString('base64');

        return `data:image/${extension};base64,${base64Image}`;
      } else {
        console.error('Failed to fetch image:', response.status, response.statusText);
        return '';
      }
    } catch (error) {
      console.error('Error in getImage:', error);
      return '';
    }
  };

  const fetchData = (logInUser, page, perPage, reset) => {
    source = CancelToken.source()
    let items = state.itemPagination && state.itemPagination.items
    if (reset) {
      items = []
    }
    instance({
      method: 'GET',
      url: `/api/maintenance/get?userId=${logInUser.id}&page=${page}&limit=${perPage}&all=${true}&search=${state.itemSearch}`,
      cancelToken: source.token
    })
      .then(response => {
        // console.error("Respo=====", response);
        // if(response && response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          itemPagination: {
            ...response,
            items: items.concat(response && response.data)
          },
          // currentPage: response.hasNext ? response.page + 1 : response.page,
          isDataRecieved: true
        }
        ))
        props.dispatch(getMaintenance(items.concat(response && response.data)))
        if (state.itemSearch === '') {
          setState((prevState) => ({
            ...prevState,
            savedData: {
              ...response,
              items: items.concat(response && response.data)
            }
          }))
        }
        // }
      }).catch(() => {
        // errorHandler(error,props.dispatch)
      })
  }

  const fetchMaintenanceHistory = (page, perPage) => {
    let searchVehicleid = state.searchVehicleId && state.searchVehicleId.id ? `&vehicleId=${state.searchVehicleId.id}` : ''

    let url = state.vehicleId && state.vehicleId.length ? `/api/services/${state.filterType}?page=${page}&limit=${perPage}&search=${state.itemSearch}${state.selectedVehicleId}${state.filterStatusType}&from=${state?.fromDate || ''}&to=${state?.toDate || ''}`
      : (state.searchVehicleId && state.searchVehicleId.id) ? `/api/services/${state.filterType}?page=${page}&limit=${perPage}&search=${state.itemSearch}${searchVehicleid}${state.filterStatusType}`
        : `/api/services/${state.filterType}?page=${page}&limit=${perPage}&search=${state.itemSearch}${state.filterStatusType} `
    source = CancelToken.source()
    let items = state.itemsPagination.items
    instance({
      method: 'GET',
      url: url,
      cancelToken: source.token
    })
      .then(response => {
        setState((prevState) => ({
          ...prevState,
          itemsPagination: {
            ...response,
            items: items.concat(response && response.data)
          },
          // currentPageHist: response.hasNext ? response.page + 1 : response.page,
          isDataRecieved: true,
          loadingSidebar: false
        }))
        //   }
        // }
      }).catch(() => {
        // errorHandler(error,props.dispatch)
      })
  }

  const fetchMaintenanceHistory2 = (page, perPage) => {
    let url = state.vehicleId && state.vehicleId.length ?  `/api/services/${state.filterType}?page=${page}&limit=${perPage}&search=${state.itemSearch}${state.selectedVehicleId}${state.filterStatusType}&from=${state?.fromDate ||''}&to=${state?.toDate||''}`
    : `/api/services/${state.filterType}?page=${page}&limit=${perPage}&search=${state.itemSearch}${state.filterStatusType}&from=${state?.fromDate ||''}&to=${state?.toDate||''}`
    source = CancelToken.source()
    let items = state.itemsPagination.items
    instance({
      method: 'GET',
      url: url,
      cancelToken: source.token
    })
      .then(response => {
        console.warn("Response",response)
        setState((prevState) => ({
          ...prevState,
          itemsPagination: {
            ...response,
            items: items.concat(response && response.data)
          },
          currentPageHist: response.hasNext ? response.page + 1 : response.page,
          isDataRecieved: true,
          loadingSidebar: false
        }))
        //   }
        // }
      }).catch(() => {
        // errorHandler(error,props.dispatch)
      })
  }
  const assignModal = (assignMode, currentMaintenance) => {
    props.dispatch(userDeviceWiseSelectedList([]))
    setState((prevState) => ({
      ...prevState,
      assignMode,
      currentMaintenance,
      selectEntity: '',
      model: true
    }))
    if (checkPrivileges('user') && assignMode === 'user') {
      instance({
        url: `/api/users/access`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          maintenanceId: currentMaintenance.id
        }
      })
        .then(users => {
          let allData = props.users
          if (users.length) {
            allData.map(item => {
              item.check = false;
              users.map(main => {

                if (item.id && main.userId && item.id === main.userId) {
                  // for parent
                  item.check = true

                }
                return null
              })
              return null
            })
          } else {
            allData.map(n => (n.check = false))
          }
          props.dispatch(userDeviceWiseSelectedList(allData))
        }).catch(() => {
          // errorHandler(error,props.dispatch)
        })
    }
    if (checkPrivileges('device') && assignMode === 'unit') {
      instance({
        url: `/api/devices/access`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          maintenanceId: currentMaintenance.id
        }
      })
        .then(devices => {
          let allData = props.devices3
          if (devices.length) {
            allData.map(item => {
              item.check = false;
              devices.map(main => {
                if (item.id && main.deviceId && item.id === main.deviceId) {
                  item.check = true
                }
                return null
              })
              return null
            })
          } else {
            allData.map(n => (n.check = false))
          }
          props.dispatch(userDeviceWiseSelectedList(allData))
        }).catch(() => {
          // errorHandler(error,props.dispatch)
        })
    }
  }

  // const selectEntity = e => {
  //   setState((prevState) => ({ ...prevState,
  //     selectEntity: e
  //   }))
  // }

  const submitAssignModel = () => {
    if (state.assignMode && state.assignMode === 'unit') {
      let obj = {
        deviceId: state.selectEntity.id,
        maintenanceId: state.currentMaintenance.id
      }
      assignMaintenance(obj)
    } else if (state.assignMode && state.assignMode === 'user') {
      let obj = {
        userId: state.selectEntity.id,
        maintenanceId: state.currentMaintenance.id
      }
      assignMaintenance(obj)
    } /* else if (state.assignMode && state.assignMode === 'group') {
      let obj = {
        groupId: state.selectEntity.id,
        maintenanceId: state.currentMaintenance.id
      }
      assignMaintenance(obj)
    } */
  }

  const assignMaintenance = obj => {
    instance({
      url: `/api/permissions/`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        props.dispatch(
          Notifications.success({
            message: props.translate('maintenanceAssignedSuccessfully'),
            autoDismiss: 10
          })
        )
      })
      .catch(error => { errorHandler(error, props.dispatch) })
  }

  const closeAssignModal = () => {
    setState((prevState) => ({
      ...prevState,
      model: false,
      assignMode: '',
      commandTitle: '',
      currentMaintenance: ''
    }))
  }

  const openCreateFrom = () => {
    setState((prevState) => ({ ...prevState, mode: '', maintenanceForm: {} }))

    setState((prevState) => ({
      ...prevState,
      mode: 'create',
      maintenanceForm: {
        attributes: {
          mileage: 0,
          hours: 0,
          date: 0,
          status: true,
          mailTo: "",
          email: []
        }
      },
      selectedUnit: null
    }))
  }

  const getExpenseTypes = () => {
    instance({
      method: 'GET',
      url: props.logInUser && props.logInUser.id ? `/api/expensetypes?userId=${props.logInUser.id}&all=${true}` : `/api/expensetypes`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        response && response.map(type => {
          servicesAttributes.push(
            {
              key: type.id,
              id: type.id,
              type: type.type,
              name: type.subType,
              subType: type.subType
            }
          )
        })
        let expenseTypes = response.map(item => {
          return (
            {
              key: item.id,
              id: item.id,
              name: item.name,
              description: item.description,
              attributes: item.attributes,
              parentId: item.parentId,
              valueType: 'string'
            }
          )
        })
        expenseType = expenseTypes
      })
      .catch(() => {
        // errorHandler(error,props.dispatch)
      })

  }

  const createExpense = () => {
    getData('vehicles', 'id', 0, 'multi', (data) => {
      vehiclesList = (data).map(item => {
        return (
          {
            id: item.id,
            label: item.label,
            value: item.id,
            vehicleLP: item.vehicleLicensePlate,
            valueType: 'string'
          }
        )
      })
    })
    getData('drivers', 'id', 0, 'multi', (data) => {
      driversList = (data).map(item => {
        return (
          {
            key: item.id,
            name: item.name,
            uniqueId: item.uniqueId,
            valueType: 'string'
          }
        )
      })
    })
    setState((prevState) => ({
      ...prevState,
      mode: 'createExpense',
      image64Type: [],
      loader: false,
      selectedVehicleId: '',
      // selectedAreaId:'',
      selectedDate: '',
      expenseForm: {
        ...defaultExpenseForm,
        status: state.mode === "due" ? "required" : "completed"
      },
      serviceExpenseCollection: [{ quantity: '1', cost: '1', expenseSubTypeId: '', expenseTypeId: '', selectedSubTypes: [] }],
    }))
  }

  const closeExpense = () => {
    setState((prevState) => ({
      ...prevState,
      mode: 'showHist',
      historyEdit: false
    }))
  }

  const getSubTypes = (id) => {
    let res = []
    instance({
      method: 'GET',
      url: `api/expensetypes/subtype?expenseTypeId=${id}&search=`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        response && response.map(item => {
          res.push(
            {
              key: item.id,
              id: item.id,
              expenseTypeId: item.expenseTypeId,
              name: item.label,
              label: item.label,
              parentId: item.parentId
            }
          )
        })
        subTypes = res
        setState((prevState) => ({ ...prevState, subTypes2: res }))
        // }
      })
      .catch(() => {
        // errorHandler(error,props.dispatch)
      })

  }
  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
useEffect(()=>{
  if(isCallVehicle){

    getVehiclesData(state.selectedVehicleId, state.mode, state.selectedDate)
    showDueMaintenance(state.selectedVehicleId)
    getDriverByVehicleId(state.selectedVehicleId)
    setIsCallVehicle(false)
  }
},[isCallVehicle])

  const handleChangeExpense = (name, value) => {
    if (name === 'driver') {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          driver: value
        }
      }))
    }
    else if (value && value.name === 'devices') {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          device: name
        }
      }))
    }
    else if (name === 'vehicles') {
      // let findArea = props.vehicles.find(item => item.id === value.id);
      // let resArea = areaList.find(item => item.key === findArea.areaId)
      setState((prevState) => ({
        ...prevState,
        selectedVehicleId: value.id,
        expenseForm: {
          ...prevState.expenseForm,
          vehicles: value,
          // areaId:resArea
        }
      }))
     setIsCallVehicle(true)
    }

    else if (name === 'type') {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          [name]: value
        },
      }))
    }
    else if (name === 'service') {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          [name]: value.name
        }
      }))
    }
    else if (name === 'status') {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          [name]: value.target.value
        }
      }))
    }
    else if (name === 'files') {
      // file.push(state.expenseForm.files)
      tempFile.push(value)
      setState((prevState) => ({
        ...prevState,
        image64Type: tempFile
      }))
    }
    else if (name === 'serviceTime') {
      let exDate = addDays(value, 7);
      let expireTimeCal = new Date(exDate.setUTCHours(0, 0, 0, 0));
      let today = new Date()
      var calTodayTime = new Date(today.setUTCHours(0, 0, 0, 0));
      let calTime = new Date(value.setUTCHours(0, 0, 0, 0));
      let time = calTime.toISOString();
      var todayTime = calTodayTime.toISOString();
      var expireTimeConv = expireTimeCal.toISOString();
      let settime = false
      if (todayTime < time) {
        settime = true
      }
      let passDate = settime ? todayTime : time
      setState((prevState) => ({
        ...prevState,
        selectedDate: time,
        expenseForm: {
          ...prevState.expenseForm,
          expireTime: expireTimeConv,
          [name]: value,
          passDate
        }
      }));

      getVehiclesData(state.selectedVehicleId, state.mode, passDate);

    }
    else if (name === 'expireTime') {
      let calTime = new Date(value.setUTCHours(0, 0, 0, 0));
      let time = calTime.toISOString();
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          [name]: time,
        }
      }));
    }
    else if (name === 'refdate') {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          [name]: value
        }
      }));
    }
    else if (name === 'updateMaintenance') {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          attributes: {
            ...prevState.expenseForm.attributes,
            [name]: value
          }
        }
      }));
    }
    else if (name === 'sendAlert') {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          attributes: {
            ...prevState.expenseForm.attributes,  // Copy the previous attributes object
            [name]: value  // Update or add the new key-value pair in attributes
          }
        }
      }));
    }
    // else if (name === 'areaId'){
    //   setState({
    //     expenseForm: {
    //       ...prevState.expenseForm,
    //       areaId: value
    //     }
    //   },()=>{checkRequiredFields3()})
    // }
    else {
      setState((prevState) => ({
        ...prevState,
        expenseForm: {
          ...prevState.expenseForm,
          [name]: value
        }
      }))
    }
  }

  const handleChangeServices = (name, value, index) => {
    let rows = [...state.serviceExpenseCollection]
    if (name === 'expenseTypeId') {
      // getSubTypes(value&&value.id)
      let res = []
      instance({
        method: 'GET',
        url: `/api/expensetypes/subtype?expenseTypeId=${value && value.id}&search=`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          response && response.map(item => {
            res.push(
              {
                key: item.id,
                id: item.id,
                expenseTypeId: item.expenseTypeId,
                name: item.label,
                label: item.label,
                parentId: item.parentId
              }
            )
          })
          rows[index].selectedSubTypes = res
          rows[index].expenseTypeId = value
          setState((prevState) => ({
            ...prevState,
            serviceExpenseCollection: rows
          }))
        })

    }
    else {
      let keys = []
      rows[index][name] = value
      rows.map(i => {
        keys.push(i.x)
        return null
      })
      let sorted_arr = keys.slice().sort()
      let results = []
      for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
          results.push(sorted_arr[i])
        }
      }
      let a = []
      keys.map((item, index) => {
        results.map(w => {
          if (item === w) {
            a.push(index)
          }
          return null
        })
        return null
      })
      a.map(val => {
        rows[val] = { ...rows[val] }
        return null
      })
      setState((prevState) => ({
        ...prevState,
        serviceExpenseCollection: rows,
        error: a.length > 0 ? true : false
      }))

    }
  }

  const getVehiclesData = (id, check, date) => {
    let url = date ? `/api/vehicles/${id}/accumulator?date=${date}` : `/api/vehicles/${id}/accumulator`
    if (id) {
      instance({
        method: 'GET',
        url: url,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (check === 'updateHist') {
            setState((prevState) => ({
              ...prevState,
              expenseForm: {
                ...prevState.expenseForm,
                currentEngineHours: response.hours ? (response.hours / (1000 * 3600)).toFixed(2) + ' h' : 0,
                currentOdometer: response.totalDistance ? setAttributeFormat('mileage', (response.totalDistance)) : 0,
              }
              // mode: check
            }))
          }
          else {
            setState((prevState) => ({
              ...prevState,
              expenseForm: {
                ...prevState.expenseForm,
                engineHours: response.hours ? (response.hours / (1000 * 3600)).toFixed(2) + ' h' : 0,
                odometer: response.totalDistance ? setAttributeFormat('mileage', (response.totalDistance)) : 0,
              }
            }))
          }
          // }
          // }
        })
        .catch(() => {
          // errorHandler(error,props.dispatch)
        })
      // .catch(e => {
      //     props.dispatch(
      //       Notifications.error({
      //         message: props.translate('invalidVehicle'),
      //         autoDismiss: 10
      //       })
      //     )
      // })
    }

  }


  const checkRequiredFields3 = () => {
    let { name, odometer, serviceTime, vehicles, status } = state.expenseForm;
    let quantity = state.serviceExpenseCollection.length > 0 ? state.serviceExpenseCollection[0].quantity : null;
    if (name && odometer && serviceTime && vehicles && state.serviceExpenseCollection[0]?.expenseSubTypeId && quantity && status) {
      setState((prevState) => ({
        ...prevState,
        isVisible: false
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisible: true
      }));
    }
  }
  

  const deleteRow = (row, index) => {
    state.serviceExpenseCollection.splice(index, 1)
    setState((prevState) => ({
      ...prevState, serviceExpenseCollection: state.serviceExpenseCollection,
      isVisible: (state.serviceExpenseCollection[0].expenseSubTypeId && state.serviceExpenseCollection[0].cost && state.serviceExpenseCollection[0].quantity) ? false : true
    }
    ))

    state.serviceExpenseCollection.map((row, index) => {
      handleChart2('quantity', row['quantity'], index)
      handleChart2('cost', row['cost'], index)
      handleChart2('expenseSubTypeId', row['expenseSubTypeId'], index)
      handleChart2('selectedSubTypes', row['selectedSubTypes'], index)
      return null
    })
  }

  const handleChart2 = (name, value, index) => {
    let rows = [...state.serviceExpenseCollection]
    let keys = []
    rows[index][name] = value
    rows.map(i => {
      keys.push(i.x)
      return null
    })
    let sorted_arr = keys.slice().sort()
    let results = []
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i])
      }
    }
    let a = []
    keys.map((item, index) => {
      results.map(w => {
        if (item === w) {
          a.push(index)
        }
        return null
      })
      return null
    })
    rows.map(sal => {
      sal.e = false
      return null
    })
    a.map(val => {
      rows[val] = { ...rows[val], e: true }
      return null
    })
    setState((prevState) => ({
      ...prevState,
      serviceExpenseCollection: rows, error: a.length > 0 ? true : false
    }))
  }

  const submitExpense = () => {
    let totalQuantity = 0, totalCost = 0;
    let newFile1 = [];

    const newFile = (state.serviceExpenseCollection || []).map((item) => {
      if (item.cost && item.quantity && item.expenseSubTypeId.id) {
        totalCost += parseInt(item.cost);
        totalQuantity += parseInt(item.quantity);
        const expenseSubTypeId = item.expenseSubTypeId.id || '';
        const expenseType = item.expenseTypeId.id || '';

        // Create a new item without the unnecessary properties
        const newItem = {
          ...item,
          serviceId: 0,
          expenseSubTypeId: expenseSubTypeId,
          expenseTypeId: expenseType
        };

        delete newItem.type;
        delete newItem.e;
        delete newItem.selectedSubTypes;
        delete newItem.allExpenseTypes;

        return newItem;
      }
    });

    newFile.forEach(item => {
      if (item) {
        newFile1.push(item);
      }
    });

    setState((prevState) => ({
      ...prevState,
      serviceExpenseCollection: newFile1
    }));

    const obj = DataFormat(state.expenseForm, totalCost, totalQuantity, newFile1);

    // Assuming `instance` is defined and configured elsewhere in your code
    instance({
      url: `/api/services`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        props.dispatch(
          Notifications.success({
            message: props.translate('expenseCreated'),
            autoDismiss: 10
          })
        );
        setState((prevState) => ({
          ...prevState,
          mode: state.dueActive ? 'history' : state.tab === 'showMaintenance' ? 'showMaintenance' : 'due',
          completeMode: false,
          itemSearch: '',
          currentPageHist: 1,
          expenseForm: { ...defaultExpenseForm },
          serviceExpenseCollection: [{ quantity: '', cost: '', expenseSubTypeId: '' }]
        }));

        fetchMaintenanceHistory(state.currentPageHist, state.pagesizeHist);
        fetchData(props.logInUser, state.currentPage, state.pagesize);
      })
      .catch(error => {
        errorHandler(error, props.dispatch);
        setState((prevState) => ({
          ...prevState,
          mode: state.dueActive ? 'due' : 'history',
          itemSearch: '',
          expenseForm: { ...defaultExpenseForm },
          serviceExpenseCollection: [{ quantity: '', cost: '', expenseSubTypeId: '', expenseTypeId: '' }]
        }));
      });

    closeAllDueMaintenance();
  };


  const ud_expenseSubmit = () => {
    let totalQuantity = 0, totalCost = 0;
    let newFile1 = [];

    const newFile = (state.serviceExpenseCollection || []).map((item) => {
      if (item.cost && item.quantity && item.expenseSubTypeId?.id) {
        totalCost += parseInt(item.cost, 10);
        totalQuantity += parseInt(item.quantity, 10);
        delete item.selectedSubTypes
        delete item.allExpenseTypes
        delete item.type
        delete item.e

        return {
          ...item,
          serviceId: state.expenseForm.id,
          expenseSubTypeId: item.expenseSubTypeId.id,
          expenseTypeId: item.expenseTypeId.id,
        };
      }
      return null;
    }).filter(item => item !== null);  // Remove null values

    newFile1 = newFile;  // Use the processed array directly

    setState((prevState) => ({ ...prevState, serviceExpenseCollection: newFile1 }));

    const obj = DataFormat(state.expenseForm, totalCost, totalQuantity, newFile1);

    instance({
      url: `/api/services/${parseInt(state.expenseForm.id, 10)}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: obj,  // Use the processed `obj` here
    })
      .then(() => {
        props.dispatch(
          Notifications.success({
            message: props.translate('expenseUpdated'),
            autoDismiss: 10,
          })
        );
        setState((prevState) => ({
          ...prevState,
          mode: state.tab,
          itemSearch: '',
          loader: false,
          currentPageHist: 1,
          expenseForm: { ...defaultExpenseForm },
          serviceExpenseCollection: [{ quantity: '', cost: '', expenseSubTypeId: '', expenseTypeId: '' }],
        }));
        fetchMaintenanceHistory(state.currentPageHist, state.pagesizeHist);
      })
      .catch((error) => {
        errorHandler(error, props.dispatch);
        setState((prevState) => ({
          ...prevState,
          mode: state.dueActive ? 'due' : 'history',
          itemSearch: '',
          loader: false,
          expenseForm: { ...defaultExpenseForm },
          serviceExpenseCollection: [{ quantity: '', cost: '', expenseSubTypeId: '', expenseTypeId: '' }],
        }));
      });

    getExpenseTypes();
    closeAllDueMaintenance();
  };


  const closeAllDueMaintenance = () => {
    setState((prevState) => ({
      ...prevState,
      showDueDialog: false,
      showDialogBtn: false,
      vehicleDueMaintenance: []
    }))
  }

  const addMore = () => {
    let rows = []
    rows = [...state.serviceExpenseCollection]

    if (rows.length) {
      // let last = { ...rows[rows.length - 1] }
      rows.push({ expenseSubTypeId: '', quantity: '1', cost: '1', expenseTypeId: '', selectedSubTypes: [] })
    } else {
      let last = { ...rows[rows.length - 1] }
      rows.push(last)
    }
    setState((prevState) => ({ ...prevState, serviceExpenseCollection: rows }))
  }

  const imagePopup = (files) => {
    setState((prevState) => ({
      ...prevState,
      maintenanceHistForm: {
        ...prevState.maintenanceHistForm,
        files
      },
      enableImagePopup: true
    }))
  }

  const deleteImage = (e, image, type) => {
    // let image = state.maintenanceHistForm.files && state.maintenanceHistForm.files.split(',')
    const s = image.filter((item, index) => index !== e);
    if (type === 'url') {
      setState((prevState) => ({
        ...prevState,
        maintenanceHistForm: {
          ...prevState.maintenanceHistForm,
          files: s.toString()
        },
        expenseForm: {
          ...prevState.expenseForm,
          files: s.toString()
        }
      }))
    } else if (type === 'base64') {
      setState((prevState) => ({
        ...prevState,
        maintenanceHistForm: {
          ...prevState.maintenanceHistForm,
          files: s.toString()
        },
        image64Type: s
      }))
      tempFile = s
    }

    //  tempFile = s
  }


  const onCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      enableImagePopup: false
    }))
  }

  const handleClickFilter = event => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget
    }))
  }

  const handleCloseFilter = () => {
    setState((prevState) => ({ ...prevState, anchorEl: null }))
  }
  const handleChangeFilter = (name, event) => {
    setState((prevState) => ({
      ...prevState,
      statusFilter: {
        ...prevState.statusFilter,
        [name]: event.target.checked
      },
      countChecked: 0,
      currentPageHist: 1
    }
    ))

  }

  useEffect(() => {
    let filter = '';

    Object.entries(state.statusFilter).map(([key, value]) => {
      if (value) {
        filter += '&status=' + key
        setState((prevState) => ({
          ...prevState,
          countChecked: ++state.countChecked
        }))
      }
    })
    setState((prevState) => ({
      ...prevState,
      filterStatusType: filter
    }))
  }, [state.statusFilter])






  const getVehiclesList = () => {
    vehiclesList = props.vehicles.map(item => {
      return (
        {
          id: item.id,
          label: item.label,
          value: item.id,
          vehicleLP: item.vehicleLicensePlate,
          valueType: 'string'
        }
      )
    }
    )
  }

  const editHistoryFrom = async (id, requestEdit) => {
    let status=requestEdit!==undefined?"required":"completed"
    id?.forEach(e => {
      setState((prevState) => ({
        ...prevState,
        // image64Type:imageData,
        expenseForm: {
          ...e,
          status,
          //  status: requestEdit !== undefined ? "required" : "completed",

          //  files:imageData,
          vehicles: {
            name: e.vehicleName,
            key: e.vehicleId,
            id: e.vehicleId,
            label: e.vehicleName
          },

        },
        // isVisible: false,
        loader: true,
        historyEdit: requestEdit ? false : true,
        historyId: e.id,
        selectedVehicleId: e.vehicleId,
      }))
      getExpense(e.id,status)
      getVehiclesData(e.vehicleId, 'updateHist', false)
    })
    getData('vehicles', 'id', 0, 'multi', (data) => {
      vehiclesList = (data).map(item => {
        return (
          {
            id: item.id,
            label: item.label,
            value: item.id,
            vehicleLP: item.vehicleLicensePlate,
            valueType: 'string'
          }
        )
      })
    })
    getData('drivers', 'id', 0, 'multi', (data) => {
      driversList = (data).map(item => {
        return (
          {
            key: item.id,
            name: item.name,
            uniqueId: item.uniqueId,
            valueType: 'string'
          }
        )
      })
    })
  }

  const getExpense = (id,status) => {
    let allExpenseTypes=expenseType
    expenseType=[]
    let serverTimeZoneName = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = props.ServerSetting.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = props.logInUser.attributes.timezone
    }
    instance({
      method: 'GET',
      url: `/api/services/expenses/${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      var tempData = []
      getData('devices3', 'id', state.expenseForm.deviceId, 'single',(data)=>{
      let device = data;
      // let vehicles = props.vehicles.find(item => item.id === state.expenseForm.vehicleId);
      // let driver = props.drivers.find(item => item.uniqueId === state.expenseForm.driverUniqueId);
      // let garage = garageList.find(item => item.key === state.expenseForm.garageId);
      // let areaId = areaList&&areaList.find(item => item.key === state.expenseForm.areaId);
      if (response.length === 0) {
        setState((prevState) => ({
          ...prevState,
          mode: "updateHist",
          loader: false,
          image64Type: [],
          // serviceExpenseCollection: tempData,
          itemSearch: '',
        }))
      }
      let selectedStatus = statusTypes.find(item => item.name === status);
      // let serviceSubType= ''
      response.map((item) => {
        instance({
          method: 'GET',
          url: `/api/expensetypes/subtype?expenseTypeId=${item.expenseTypeId}&search=`,  
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then(resp => {
            let serviceSubType= resp.find(el => el.id === item.expenseSubTypeId)
            allExpenseTypes.map(z =>{
              if(item.expenseTypeId === z.id ){
                item.expenseSubTypeId=serviceSubType
                item.expenseTypeId=z
                item.selectedSubTypes=resp
                item.allExpenseTypes=allExpenseTypes
                tempData.push(item)
                setState(prevState=>({
                  ...prevState,
                  serviceExpenseCollection: tempData,
                  expenseForm:{
                    ...prevState.expenseForm,
                    serviceTime: moment(state.expenseForm.serviceTime).tz(serverTimeZoneName).format('YYYY-MM-DD'),
                    // type: 1,
                    device,
                    vehicles:props.vehicles.find(item => item.id === prevState.expenseForm.vehicleId),
                    status:selectedStatus&&selectedStatus.name,
                    // garage:garage,
                    // areaId:areaId,
                    driver:props.drivers.find(item => item.uniqueId === prevState.expenseForm.driverUniqueId),
                    engineHours: prevState.expenseForm.engineHours > 0 ?(prevState.expenseForm.engineHours/(1000*3600)).toFixed(2)+' h': '0',
                    odometer: prevState.expenseForm.odometer>0? setAttributeFormat('mileage', (prevState.expenseForm.odometer)) : '0'
                  },
                    image64Type:[],
                    // serviceExpenseCollection: tempData,
                    mode: 'updateHist',
                    loader:false,
                    itemSearch:'',
                  }))
                  getExpenseTypes()
              }
            })
        }).catch((error)=>{
          console.error(error)
        })
        });
        })
      })
      .catch(() => {
        // errorHandler(error,props.dispatch)
      })
  }
  
  const editMaintenanceForm = maintenanceForm => {
    let res = []
    let selectedVehicle = props.vehicles.find(item => item.id === maintenanceForm[0].vehicleId)
    // getSubTypes(maintenanceForm[0].attributes&&maintenanceForm[0].attributes.serviceTypeId)
    let subTypeId = maintenanceForm[0].attributes && maintenanceForm[0].attributes.serviceTypeId
    instance({
      method: 'GET',
      url: `api/expensetypes/subtype?expenseTypeId=${subTypeId}&search=`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        response && response.map(item => {
          res.push(
            {
              key: item.id,
              id: item.id,
              expenseTypeId: item.expenseTypeId,
              name: item.label,
              label: item.label,
              parentId: item.parentId
            }
          )
        })
        subTypes = res
        setState((prevState) => ({ ...prevState, subTypes2: res }))

        // ,()=>{
        let selectedServiceExpenseId = expenseType.find(item => item.id === maintenanceForm[0].attributes.serviceTypeId);
        let selectedUnit = { id: 0, label: '', value: 0 };
        if (subTypes.length) {
          Object.values(maintenanceForm).map(maintenanceForm1 => {
            setState((prevState) => ({ ...prevState, mode: '', maintenanceForm1: {} }))

            let obj = JSON.parse(JSON.stringify(maintenanceForm1))
            if (obj.attributes && obj.attributes.byMileage) {
              obj.attributes.mileage = parseFloat((obj.attributes.mileage / 1000).toFixed(3))
              obj.attributes.mileageInterval = parseFloat((obj.attributes.mileageInterval / 1000).toFixed(3))
              obj.attributes.reminderBeforeMileage = parseFloat((obj.attributes.reminderBeforeMileage / 1000).toFixed(3))
            } if (obj.attributes && obj.attributes.byHours) {
              obj.attributes.hours = parseFloat((obj.attributes.hours / (1000 * 3600)).toFixed(3))
              obj.attributes.hoursInterval = parseFloat((obj.attributes.hoursInterval / (1000 * 3600)).toFixed(3))
              obj.attributes.reminderBeforeHours = parseFloat((obj.attributes.reminderBeforeHours / (1000 * 3600).toFixed(3)))
            } if (obj.attributes && obj.attributes.byDays) {
              obj.attributes.date = obj.attributes.date // eslint-disable-line no-self-assign
              obj.attributes.daysInterval = parseFloat(obj.attributes && obj.attributes.daysInterval) || parseFloat(1)
              obj.attributes.reminderBeforeDays = parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1)
            }
            if (obj.attributes.serviceExpenseTypeId) {
              let serviceSubType = subTypes.find(el => el.id === maintenanceForm[0].attributes.serviceExpenseTypeId);
              obj.attributes.serviceTypeId = selectedServiceExpenseId
              obj.attributes.serviceExpenseTypeId = serviceSubType
            }
            selectedUnit.id = obj.deviceId
            selectedUnit.label = obj.deviceName
            selectedUnit.value = obj.deviceId

            delete obj.progress
            setState((prevState) => ({
              ...prevState,

              mode: 'update',
              itemSearch: '',
              selectedUnit,
              maintenanceForm: {
                ...obj,
                vehicleId: selectedVehicle
              }
            }))
            // }
          })
        }
        // }
        // }
      })
      .catch(() => {
        // errorHandler(error,props.dispatch)
      })






  }


  const setPaginationHistory = user => {
    const { itemsPagination } = state
    if (itemsPagination && itemsPagination.data && itemsPagination.data.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / state.pagesizeHist
      let IsFloate = checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pagesizeHist = state.pagesizeHist
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }
  const setPagination = user => {
    const { itemPagination } = state
    if (itemPagination && itemPagination.items && itemPagination.items.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / state.pagesize
      let IsFloate = checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pagesize = state.pagesize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }

  const setDuePagination = user => {
    const { vehicleDueMaintenance } = state
    if (vehicleDueMaintenance && vehicleDueMaintenance.data && vehicleDueMaintenance.data.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / user.pageSize
      let IsFloate = checkFloteNumber(lastPage)
      // delete nUser.data
      nUser.pageSize = user.pageSize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }

  const checkFloteNumber = (n) => {
    return Number(n) === n && n % 1 !== 0
  }

  useEffect(() => {
    if (state.mode === "due" || state.mode === "history") {
      if (state.pagesizeHist || state.currentPageHist) {
        if (state.showStats) { checkRequiredFieldsSidebar() }
        else { fetchMaintenanceHistory(state.currentPageHist, state.pagesizeHist) }
      }
    } else if (state.mode === "showMaintenance") {
      if (state.pagesize || state.currentPage) {
        fetchData(props.logInUser, state.currentPage, state.pagesize, true)
      }
    }
  }, [state.mode, state.pagesizeHist, state.currentPageHist, state.pagesize, state.currentPage, state.itemSearch, state.showStats,state.filterStatusType])


  useEffect(() => {
    if(state.pagesizeHist){
        fetchMaintenanceHistory(state.currentPageHist, state.pagesizeHist)
      }
    }, [state.pagesizeHist])

  const handleChangeRowsPerPage = (value, mode) => {
    if (!state.showStats) {
      if (source) {
        source.cancel()
      }
      if (mode === 'maintenanceHist') {
        setState((prevState) => ({
          ...prevState,
          pagesizeHist: value,
          currentPageHist: 1
        }))
        // fetchMaintenanceHistory(
        //   state.currentPageHist,
        //   state.pagesizeHist)
      } else if (mode === 'maintenance') {
        setState((prevState) => ({
          ...prevState,
          pagesize: value,
          currentPage: 1,
        })
        )
        // fetchData(
        //   props.logInUser,
        //   state.currentPage, 
        //   state.pagesize, 
        //   true)
      }
      else if (mode === 'dueMaintenance') {
        fetchDueMaintenance(state.selectedVehicleId, state.vehicleDueMaintenance.page, value)
        //  setState({
        //   vehicleDueMaintenance:{
        //     ...prevState.vehicleDueMaintenance,
        //     pageSize:value
        //   }
        //  })
      }
    }
    else {
      if (state.filterStatusType && state.toDate && state.fromDate) {
        setState((prevState) => ({
          ...prevState,
          pagesizeHist: value,
          currentPageHist: 1
        }))

        fetchMaintenanceHistory(
          state.currentPageHist,
          state.pagesizeHist)
      }
    }
  }
  const handleChangePage = (value, mode) => {
    if (source) {
      source.cancel()
    }
    if (mode === 'maintenanceHist') {
      setState((prevState) => ({
        ...prevState,
        currentPageHist: value,
        pagesizeHist: state.pagesizeHist
      }))

      // fetchMaintenanceHistory(
      //   state.currentPageHist,
      //   state.pagesizeHist)
    } else if (mode === 'maintenance') {
      setState((prevState) => ({
        ...prevState,
        currentPage: value,
        pagesize: state.pagesize
      }))
      // fetchData(
      //   props.logInUser,
      //   state.currentPage, 
      //   state.pagesize, 
      //   true)
    }
    else if (mode === 'dueMaintenance') {
      fetchDueMaintenance(state.selectedVehicleId, value, state.vehicleDueMaintenance.pageSize)
    }
  }



  const switchMode = (mode, filterType) => {
    if (filterType === 'due') {
      setState((prevState) => ({
        ...prevState,
        mode: filterType,
        itemSearch: '',
        tab: 'due',
        filterType,
        isDataRecieved: false,
        dueActive: false,
        currentPageHist: 1,
        pagesizeHist: 20,
        itemPagination: state.savedData ? state.savedData : state.itemPagination,
        searchVehicleId: { id: '', label: props.translate('searchVehicles') },
        // searchAreaId: {id:'', label: props.translate('searchAreas')}
      }))
      // fetchMaintenanceHistory(state.currentPageHist, state.pagesizeHist)
    } else if (filterType === 'history') {
      setState((prevState) => ({
        ...prevState,
        mode: filterType,
        itemSearch: '',
        filterType,
        tab: 'history',
        isDataRecieved: false,
        dueActive: true,
        currentPageHist: 1,
        pagesizeHist: 20,

        itemPagination: state.savedData ? state.savedData : state.itemPagination
      }))
      // ,()=>{
      // fetchMaintenanceHistory(
      //   state.currentPageHist,
      //   state.pagesizeHist)
      // }
    } else {
      getVehiclesList()
      // fetchData(props.logInUser,state.currentPage,state.pagesize, true)

      setState((prevState) => ({
        ...prevState,
        itemSearch: '', tab: 'showMaintenance',
        mode: filterType, dueActive: false
      }))
    }
  }
  const SearchItem = (searchValue) => {
    if (source) {
      source.cancel()
    }
    setState((prevState) => ({
      ...prevState,
      itemSearch: searchValue,
      currentPage: 1,
      currentPageHist: 1
    }))
    // if(mode==='maintenanceHist'){
    //   fetchMaintenanceHistory(
    //   state.currentPageHist,
    //   state.pagesizeHist)
    // }else if(mode==='maintenance'){
    //   fetchData(props.logInUser,state.currentPage, state.pagesize, true) 
    // }
  }
  const SearchVehicle = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      searchVehicleId: value,
      currentPage: 1,
      currentPageHist: 1
    }))
   
  }

  useEffect(()=>{
    if(state.searchVehicleId&&state.currentPage===1&&state.currentPageHist===1){
    fetchMaintenanceHistory(state.currentPageHist,state.pagesizeHist)
  }
  },[state.searchVehicleId,state.currentPage,state.currentPageHist])



  // const SearchArea = (name, value) => {
  //   setState(
  //     {
  //       searchAreaId: value,
  //       currentPage: 1,
  //       currentPageHist: 1
  //     },
  //     () => {
  //       fetchMaintenanceHistory(
  //       state.currentPageHist,
  //       state.pagesizeHist)}
  //   )
  // }

  const closeCreateFrom = (mode) => {
    getExpenseTypes()
    setState((prevState) => ({
      ...prevState,
      mode,
      itemSearch: '',
      // itemPagination:state&&state.savedData,
      maintenanceForm: '',
      isVisible: true,
      loader: false,
      completeMode: false,
      // histFormVisible: false,
      historyEdit: false,
      vehicleTracker: '',
      expenseForm: { ...defaultExpenseForm },
      serviceExpenseCollection: [{ quantity: '', cost: '', expenseSubTypeId: '', expenseTypeId: '', selectedSubTypes: [] }]
    }))
    tempFile = []
    closeAllDueMaintenance()
  }

  const onDeleteMaintenance = () => {
    if (state.itemDeleteId) {
      // fetch(`/api/maintenance/${state.itemDeleteId}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/maintenance/${state.itemDeleteId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {// eslint-disable-line no-unused-vars
          // if (response.ok) {
          props.dispatch(removedMaintenance({ id: state.itemDeleteId }))
          props.dispatch(
            Notifications.success({
              message: props.translate('maintenanceIsDeleted'),
              autoDismiss: 10
            })
          )
          setState((prevState) => ({
            ...prevState,
            onDeleteConfirm: false,
            currentPage: 1
          }))
          closeCreateFrom('showMaintenance')
          maintenanceAnalytic()
          fetchData(props.logInUser, state.currentPage, state.pagesize, true)
          // }
          // else{
          //   throw response
          // }
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }

  const onDeleteExpenseItem = () => {
    if (state.itemDeleteExpenseId) {
      // fetch(`/api/services/${state.itemDeleteExpenseId}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/services/${state.itemDeleteExpenseId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {// eslint-disable-line no-unused-vars
          // if (response.ok) {
          props.dispatch(
            Notifications.success({
              message: props.translate('expenseIsDeleted'),
              autoDismiss: 10
            })
          )
          setState((prevState) => ({
            ...prevState,
            onDeleteConfirm: false,
            currentPage: 1,
            currentPageHist: 1,
          }))

          fetchMaintenanceHistory(state.currentPageHist, state.pagesizeHist)
          onCancelExpense()

          // }
          // else{
          //   throw response
          // }
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }

  const onRemovedMaintenance = (item) => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirm: true,
      itemDeleteId: item[0].id,
      itemDeleteName: item[0].name
    }))
  }


  const onMarkComplete = (item) => {
    const typeExpense = expenseType.find(obj => { return parseInt(obj.key) === parseInt(item.attributes && item.attributes.serviceTypeId) })
    const searchedVehcile = props.vehicles.find(it => it.id === item.vehicleId)
    let type = ''
    if (item && item.attributes && item.attributes.serviceTypeId) {
      let res = []
      instance({
        method: 'GET',
        url: `api/expensetypes/subtype?expenseTypeId=${item && item.attributes && item.attributes.serviceTypeId}&search=`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          response && response.map(item => {
            res.push(
              {
                key: item.id,
                id: item.id,
                expenseTypeId: item.expenseTypeId,
                name: item.label,
                label: item.label,
                parentId: item.parentId
              }
            )
          })
          subTypes = res
          type = (subTypes && subTypes).find(i => i.key === parseInt(item.attributes && item.attributes.serviceExpenseTypeId))
          setState((prevState) => ({
            ...prevState,
            serviceExpenseCollection: [{
              attributes: {},
              cost: item.cost,
              expenseTypeId: typeExpense,
              expenseSubTypeId: type,
              id: item.id,
              quantity: 1,
              serviceId: item.id,
              allExpenseTypes: expenseType,
              selectedSubTypes: subTypes
            }]
          }))
        })
        .catch(() => {
          // errorHandler(error,props.dispatch)
        })
    }
    getData('vehicles', 'id', 0, 'multi', (data) => {
      vehiclesList = (data).map(item => {
        return (
          {
            id: item.id,
            label: item.label,
            deviceId: item.deviceId,
            value: item.id,
            vehicleLP: item.vehicleLicensePlate,
            valueType: 'string'
          }
        )
      })
    })
    getData('drivers', 'id', 0, 'multi', (data) => {
      driversList = (data).map(item => {
        return (
          {
            key: item.id,
            name: item.name,
            uniqueId: item.uniqueId,
            valueType: 'string'
          }
        )
      })
    })
    setState((prevState) => ({
      ...prevState,
      mode: 'createExpense',
      filterType: 'history',
      completeMode: true,
      expenseForm: {
        maintenanceId: item.id,
        attributes: {
          updateMaintenance: true
        },
        type: typeExpense,
        name: item.name,
        cost: item.cost,
        vehicles: searchedVehcile,
        address: "",
        serviceTime: moment().format('YYYY-MM-DD'),
        status: "completed",
        vehicleId: ''
      },
    }))

    getVehiclesData(item.vehicleId, 'createExpense', false)

  }

  const fetchDueMaintenance = (id, page, pageSize) => {
    let status = 'required'
    source = CancelToken.source()
    instance({
      method: 'GET',
      url: `/api/services/due?page=${page}&limit=${pageSize}&search=&vehicleId=${id}&status=${status}`,
      cancelToken: source.token
    })
      .then(response => {
        //  console.error('Response',response)
        setState((prevState) => ({
          ...prevState,
          showDialogBtn: true,
          vehicleDueMaintenance: response
        }))
      }).catch(() => {
        // errorHandler(error,props.dispatch)
      })
  }





  const onRemovedExpense = (item) => {
    setState((prevState) => ({
      ...prevState,
      onDeleteExpense: true,
      itemDeleteExpenseId: item[0].id,
      itemDeleteExpense: item[0].name,
    }))
  }

  const onCancelMaintenance = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirm: false
    }))
  }

  const onCancelStatus = () => {
    setState((prevState) => ({
      ...prevState,
      onStatusChange: false
    }))
  }
  const onCancelMark = () => {
    setState((prevState) => ({
      ...prevState,
      onMarkComplete: false
    }))
  }

  const onCancelExpense = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteExpense: false
    }))
  }
  const uploadFile = async () => {
    let updatedFiles = "";
    let { image64Type } = state;

    setState((prevState) => ({ ...prevState, loader: true }));
    if (process.env.REACT_APP_SERVER_LOCAL === "false") {
      if (image64Type && image64Type.length) {
        const uploadPromises = image64Type.map(async (imageData) => {
          const today = Math.round((new Date()).getTime() / 1000);
          const myExe = imageData.split(";base64", 1);
          const exe = myExe[0].replace("data:", "");
          const obj = {
            name: today,
            contentType: exe,
            data: imageData.split("base64")[1].substring(1),
          };
  
          try {
            const response = await instance({
              url: `/api/uploads`,
              method: "POST",
              data: { ...obj },
            });
            const publicUrl = response.publicUrl || response.localUrl;
            return publicUrl;
          } catch (error) {
            errorHandler(error, props.dispatch);
            return null;
          }
        });
  
        try {
          const allResponses = await Promise.all(uploadPromises);
          const successfulUploads = allResponses.filter((url) => url !== null);
          updatedFiles = state.expenseForm?.files
            ? `${state.expenseForm.files}, ${successfulUploads.join(", ")}`
            : successfulUploads.join(", ");
          setState((prevState) => ({
            ...prevState,
            expenseForm: {
              ...prevState.expenseForm,
              files: updatedFiles,
            },
          }));
          if (state.mode === "updateHist") {
            setCallUpdate(true);
          } else {
            submitExpense();
          }
        } catch (error) {
          errorHandler(error, props.dispatch);
        }
      } else {
        if (state.mode === "updateHist") {
          setCallUpdate(true);
        } else {
          submitExpense();
        }
      }
    } else {
      if (state.mode === "updateHist") {
        setCallUpdate(true);
      } else {
        submitExpense();
      }
    }
  };
  

  useEffect(() => {
    if (callUpdate) {
      ud_expenseSubmit()
      setCallUpdate(false)
    }
  }, [callUpdate])

  const uploadImage = async (event) => {
    const currentImages = state.expenseForm.files ? state.expenseForm.files.split(',') : [];
    const totalImages = currentImages.length + state.image64Type.length;
    let imgData = ''
    let file = event.target.files
    if (totalImages + event.target.files.length <= 5) {
      if (process.env.REACT_APP_SERVER_LOCAL === "true") {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          if (!['text/plain', 'image/png', 'image/jpeg', 'application/pdf'].includes(file.type)) {
            console.error(`File format not allowed for file ${file.name}`);
            continue;
          }
          const response = await fetch(`/api/uploads/maintenance/${state.expenseForm.id}`, {
            method: 'POST',
            body: file,
          });

          if (response.ok) {
          
            const responseData = await response.json();
            currentImages.push(responseData.data.image);
          } else {
      
            console.error(`Error uploading file ${file.name}`);
          }
        }

        // Update the state with the new list of images
        setState((prevState) => ({
          ...prevState,
          expenseForm: {
            ...prevState.expenseForm,
            files: currentImages.join(','),
          }
        }))
      } else {
        if ((file[0]?.size / 1000000) <= 5) {
          Object.values(file).map(item => {
            let reader = new FileReader()
            reader.readAsDataURL(item)
            reader.onload = e => {
              imgData = e.target.result
              const myExe = imgData.split(";base64", 1);
              const exe = myExe[0].replace("data:", '');
              if (exe === ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
                exe === ('application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
                exe === ('text/plain') ||
                exe === ('image/png') ||
                exe === ('image/jpeg') ||
                exe === ('application/pdf')) {
                let obj = {
                  name: `${item.name}`,
                  contentType: `${item.type}`,
                  // oldUrl: `${state.form.driverImg}`,
                  data: `${imgData.split('base64')[1].substring(1)}`
                }
                setState((prevState) => ({
                  ...prevState,
                  imageFile: obj,
                }
                ))
                handleChangeExpense('files', imgData)
              } else {
                props.dispatch(
                  Notifications.error({
                    message: props.translate('onlyAcceptImage'),
                    autoDismiss: 10
                  })
                )
              }
            }
          })
        } else {
          props.dispatch(
            Notifications.error({
              message: props.translate('fileSize5MB'),
              autoDismiss: 10
            })
          )
        }

      }
    } else {
      props.dispatch(
        Notifications.error({
          message: props.translate('fileLimit'),
          autoDismiss: 10
        })
      );
    }
  }


  //   const convertBase64 = (file) => {
  //     return new Promise((resolve, reject) => {
  //         const fileReader =new FileReader();
  //         fileReader.readAsDataURL(file);

  //         fileReader.onload = () => {
  //             resolve(fileReader.result);
  //         }
  //         fileReader.onerror = (error) => {
  //             reject(error)
  //         };
  //     });
  // }
  //   const handleChangeHist = (name, value) => {
  //     let v = value
  //     if (name === 'type') {
  //       v = value.id
  //       setState((prevState) => ({ ...prevState,
  //         maintenanceHistForm: {
  //           ...prevState.maintenanceHistForm,
  //           status: v
  //         }
  //       }))
  //     }else{
  //       setState((prevState) => ({ ...prevState,
  //           maintenanceHistForm: {
  //             ...prevState.maintenanceHistForm,
  //             [name]: v
  //           }
  //         }))
  //   }
  //   }
  const onChangedDevice = (name, item) => {
    if (item) {
      getData('positions', 'vehicleId', item.id, 'single', (data) => {
        const mileage = data && data.attributes && data.attributes.totalDistance ? (data.attributes.totalDistance / 1000).toFixed(2) : null;
        const hours = data && data.attributes && data.attributes.hours ? (data.attributes.hours / (3600 * 1000)).toFixed(2) : null;
        setState((prevState) => ({
          ...prevState,
          vehicleTracker: item,
          vehicleId: item,
          maintenanceForm: {
            ...prevState.maintenanceForm,
            deviceId: 0,
            vehicleId: item,
            attributes: {
              ...prevState.maintenanceForm.attributes,
              mileage,
              hours,
              byMileage: mileage ? true : false,
              byHours: hours ? true : false,
              byDays: true,
            }
          }
        }))
        // , ()=>{checkRequiredFields()}
      })
      return null;
    }
  }
  const checkRequiredFields2 = () => {
    const { maintenanceHistForm } = state
    if (maintenanceHistForm.cost + ''.trim() && maintenanceHistForm.status && maintenanceHistForm.cDate) {
      setState((prevState) => ({
        ...prevState,
        isVisible: false
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisible: true
      }))
    }
  }
  const handleChange = (name, value) => {
    if (name === 'vehicleId') {
      if (value && value.id) {
        instance({
          method: 'GET',
          url: `/api/vehicles/${value.id}/accumulator`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            const mileage = response && response.totalDistance ? (response.totalDistance / 1000).toFixed(2) : null;
            const hours = response && response.hours ? (response.hours / (3600 * 1000)).toFixed(2) : null;
            setState((prevState) => ({
              ...prevState,
              vehicleTracker: value,
              vehicleId: value,
              maintenanceForm: {
                ...prevState.maintenanceForm,
                deviceId: 0,
                vehicleId: value,
                attributes: {
                  ...prevState.maintenanceForm.attributes,
                  mileage,
                  hours,
                  byMileage: mileage ? true : false,
                  byHours: hours ? true : false,
                  byDays: true,
                }
              }
            }))
            // checkRequiredFields()
          })
          .catch(err => { console.error('err ===', err) })
      }
    }
    else {
      let v = value
      setState((prevState) => ({
        ...prevState,
        maintenanceForm: {
          ...prevState.maintenanceForm,
          [name]: v
        }
      }))
    }

  }
  const checkRequiredFields = () => {
    let { name } = state.maintenanceForm
    let { date, mileage, hours, byDays, byHours, byMileage, serviceExpenseTypeId, serviceTypeId, emails, mailTo, notificators } = state.maintenanceForm && state.maintenanceForm.attributes
    //  if ( name && (mileageInterval || hoursInterval || daysInterval) && (mileage || hours || date)) {
    if (name && serviceExpenseTypeId && serviceTypeId && ((byMileage && mileage) || (byHours && hours) || (byDays && date))) {
      if (notificators.includes('mail') && mailTo === 'custom' && !emails) {
        setState((prevState) => ({
          ...prevState,
          isVisible: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isVisible: false
        }))
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisible: true
      }))
    }
  }

  useEffect(() => {
    checkRequiredFields()
  }, [state.maintenanceForm])

  useEffect(() => {

    if (state.expenseForm) {
      checkRequiredFields3()
    }
    else if (state.passDate) {
      getVehiclesData(state.selectedVehicleId, state.mode, state.passDate);
    }
    else if (state.showStats) {
      checkRequiredFieldsSidebar()
    }
    else if (state.maintenanceHistForm) {

      checkRequiredFields()
    }
    else if (state.filterStatusType
    ) {
      fetchMaintenanceHistory(state.currentPageHist, state.pagesizeHist)
    }
    else if (state.vehicleTracker) {
      checkRequiredFields()
      checkRequiredFields2()
    }

  }, [state.expenseForm,
  state.serviceExpenseCollection, state.maintenanceHistForm,
  state.showStats, state.filterStatusType, state.vehicleTracker,
  ])

  const getData = (collection, field, value, item, cb) => {
    const HideMe = document.getElementById('HideMe');
    ReactDOM.render(<Provider store={store}><SingleItem collection={collection} field={field} value={value} item={item}>{
      (id) => {
        cb(id)
        return null;
      }
    }
    </SingleItem></Provider>,
      HideMe
    )
  }
  const handleChangeAttributes = (name, value, checked) => {
    if (value) {
      if (name === 'byMileage' || name === 'byHours' || name === 'byDays') {
        let maintenanceForm = JSON.parse(JSON.stringify(state.maintenanceForm))
        maintenanceForm.attributes[name] = checked
        setState((prevState) => ({ ...prevState, maintenanceForm }))
      } else if (name === 'serviceTypeId') {
        getSubTypes(value && value.id)
        setState((prevState) => ({
          ...prevState,
          maintenanceForm: {
            ...prevState.maintenanceForm,
            attributes: {
              ...prevState.maintenanceForm.attributes,
              serviceTypeId: value,
              expenseName: value.name
            }
          }
        }))
      } else if (name === 'serviceExpenseTypeId') {
        setState((prevState) => ({
          ...prevState,
          maintenanceForm: {
            ...prevState.maintenanceForm,
            attributes: {
              ...prevState.maintenanceForm.attributes,
              serviceExpenseTypeId: value,
            }
          }
        }))
      }
      else {
        setState((prevState) => ({
          ...prevState,
          maintenanceForm: {
            ...prevState.maintenanceForm,
            attributes: {
              ...prevState.maintenanceForm.attributes,
              [name]: value
            }
          }
        }))
      }
    } else {
      if (state.maintenanceForm && state.maintenanceForm.attributes) {
        let attributes = { ...state.maintenanceForm.attributes }
        delete attributes[name]
        setState((prevState) => ({
          ...prevState,
          maintenanceForm: {
            ...prevState.maintenanceForm,
            attributes: {
              ...attributes
            }
          }
        }))
      }
    }
  }


  const onCompleteMark = () => {
    let data = state.selectedMarkComplete
    if (data) {
      setState((prevState) => ({
        ...prevState,
        maintenanceForm: data,
        statusMode: true
      }))
      // submitForm('complete')
    }
  }
  useEffect(() => {
    if (state.statusMode) {
      if (state.onStatusChange) {
        submitForm()
      } else {
        submitForm('complete')
      }
    }
  }, [state.statusMode])

  const onStatusChange = (item) => {
    setState((prevState) => ({
      ...prevState,
      onStatusChange: true,
      selectedStatusChange: item,
      itemStatusChange: item.name
    }))
  }
  const onChangeStatus = () => {
    let data = state.selectedStatusChange

    if (data) {
      data.attributes.status = !data.attributes.status
      setState((prevState) => ({
        ...prevState,
        maintenanceForm: data,
        statusMode: true
      }))
      // submitForm()
    }
  }
  const submitForm = (checkTrue) => {
    let obj = JSON.parse(JSON.stringify(state.maintenanceForm))
    if (obj.vehicleId) {
      obj.vehicleId = state.statusMode ? obj.vehicleId : obj.vehicleId.id
      obj.deviceId = state.statusMode ? obj.deviceId : 0
    }
    if (obj.attributes.byMileage && !state.statusMode) {
      obj.attributes.mileage = parseFloat(obj.attributes.mileage * 1000)
      obj.attributes.mileageInterval = parseFloat(obj.attributes.mileageInterval * 1000)
      obj.attributes.reminderBeforeMileage = parseFloat(obj.attributes.reminderBeforeMileage * 1000)
    } if (obj.attributes.byHours && !state.statusMode) {
      obj.attributes.hours = parseFloat(obj.attributes.hours * (1000 * 3600))
      obj.attributes.hoursInterval = parseFloat(obj.attributes.hoursInterval * (1000 * 3600))
      obj.attributes.reminderBeforeHours = parseFloat(obj.attributes.reminderBeforeHours * (1000 * 3600))
    } if (obj.attributes.byDays && !state.statusMode) {
      obj.attributes.date = obj.attributes.date // eslint-disable-line no-self-assign
      obj.attributes.daysInterval = parseFloat(obj.attributes.daysInterval) || parseFloat(1)
      obj.attributes.reminderBeforeDays = parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1)
    } if (obj.attributes.serviceExpenseTypeId) {
      obj.attributes.serviceExpenseTypeId = state.statusMode ? obj?.attributes?.serviceExpenseTypeId : obj?.attributes?.serviceExpenseTypeId?.id || ''
      obj.attributes.serviceTypeId = state.statusMode ? obj?.attributes?.serviceTypeId : obj?.attributes?.serviceTypeId?.id || ''
    }

    obj = {
      ...obj,
      type: ''
    }
    obj.cost = parseFloat(obj.cost)
    delete obj.check
    delete obj.parent
    if (!obj.attributes.repeat) {
      delete obj.attributes.mileageInterval
      delete obj.attributes.hoursInterval
      delete obj.attributes.daysInterval
    }
    if (!obj.attributes.byMileage) {
      delete obj.attributes.mileage
      delete obj.attributes.mileageInterval
      delete obj.attributes.reminderBeforeMileage
      obj.attributes.reminderByMileage = false
    } if (!obj.attributes.byHours) {
      delete obj.attributes.hours
      delete obj.attributes.hoursInterval
      delete obj.attributes.reminderBeforeHours
      obj.attributes.reminderByHours = false
    } if (!obj.attributes.byDays) {
      delete obj.attributes.date
      delete obj.attributes.daysInterval
      delete obj.attributes.reminderBeforeDays
      obj.attributes.reminderByDays = false
    }

    let params = ''
    if (state.mode === 'update' || state.statusMode) {
      params = obj.id
    }
    // else if(state.mode === 'showMaintenance'){
    //   params = state.selectedStatusChange.id
    //   obj = state.selectedStatusChange
    // }
    setState((prevState) => ({
      ...prevState,
      isVisible: true
    }))
    if (obj) {
      let url = checkTrue === 'complete' ? `/api/maintenance/${params}/complete` : `/api/maintenance/${params}`
      instance({
        url: `${url}`,
        method: `${(state.statusMode) || state.mode === 'update' ? 'PUT' : 'POST'}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(maintenance => {
          // if (response.ok) {
          if (checkTrue === 'complete') {
            props.dispatch(
              Notifications.success({
                message: props.translate('maintenanceIsMarkedComplate'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onMarkComplete: false
            }))
          } else {
            // response.json().then(maintenance => {
            props.dispatch(addMaintenance(maintenance))
            if (state.mode === 'update' || state.statusMode) {
              props.dispatch(
                Notifications.success({
                  message: props.translate('maintenanceIsUpdated'),
                  autoDismiss: 10
                })
              )
            } else {
              props.dispatch(
                Notifications.success({
                  message: props.translate('MaintenanceIsCreated'),
                  autoDismiss: 10
                })
              )
            }
            setState((prevState) => ({
              ...prevState,
              selectMaintenanceId: maintenance && maintenance.id,
              itemSearch: '',
              onStatusChange: false,
              onMarkComplete: false,
              statusMode: false
            }))
            closeCreateFrom('showMaintenance')
            maintenanceAnalytic()
            fetchData(props.logInUser, 1, state.pagesize, true)

            // })
          }
          // }else{
          //   throw response
          // }
        })
        .catch(error => {
          errorHandler(error, props.dispatch)
          setState((prevState) => ({
            ...prevState,
            itemSearch: '',
            onStatusChange: false,
            onMarkComplete: false,
            statusMode: false
          }))
          fetchData(props.logInUser, 1, state.pagesize, true)
        }
        )
    }
  }

  //   const maintenanceSelection = () => {
  //     let obj = {
  //       deviceId: state.vehicleId,
  //       maintenanceId: state.selectMaintenanceId
  //     }
  //     // fetch(`/api/permissions/`, {
  //     //   method: 'POST',
  //     //   headers: {
  //     //     Accept: 'application/json',
  //     //     'Content-Type': 'application/json'
  //     //   },
  //     //   body: JSON.stringify({
  //     //     ...obj
  //     //   })
  //     // })
  //     instance({
  //       url: `/api/permissions/`,
  //       method: `POST`,
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       data: {
  //         ...obj
  //       }
  //     })
  //     .then(response => {// eslint-disable-line no-unused-vars
  //       // if (response.status === 204) {
  //           props.dispatch(
  //             Notifications.success({
  //               message: props.translate('maintenanceAssignedSuccessfully'),
  //               autoDismiss: 10
  //             })
  //           )
  //       // }
  //     }).catch(error => {errorHandler(error,props.dispatch)})
  //   }
  //  const  submitHistoryForm = () => {
  //     let obj = state.maintenanceHistForm
  //     let cDate = ''
  //     let items = state.itemsPagination.items.map(row => {
  //       if(state.historyId === row.id) {
  //         row.attributes.cost = parseInt(obj.cost)
  //         row.attributes.status = obj.status
  //         row.attributes.cValue = obj.cValue
  //         row.attributes.cDate = obj.cDate
  //         row.attributes.updateMaintenance = obj.updateMaintenance
  //         row.files = (obj.files==='undefined'?'':obj.files)
  //       }
  //       cDate = moment(obj.cDate).toISOString()
  //       return row
  //     })
  //     setState((prevState) => ({ ...prevState, isVisible: true }))
  //       instance({
  //           method: 'GET',
  //           url: `/api/maintenance/history/${state.historyId}?cost=${obj.cost}&status=${obj.status}&cValue=${obj.cValue}&cDate=${cDate}&files=${obj.files}&updateMaintenance=${obj.updateMaintenance}`,
  //           headers: {
  //             Accept: 'application/json',
  //             'Content-Type': 'application/json'
  //           },
  //           body: JSON.stringify({
  //             ...obj
  //           })
  //         })
  //         .then(response => {// eslint-disable-line no-unused-vars
  //           // if(response && response.status === 200) {
  //             // if(response.data && response.data.status === 'success') {
  //               props.dispatch(
  //                 Notifications.success({
  //                   message: props.translate('maintenancehistoryupdated'),
  //                   autoDismiss: 10
  //                 })
  //               )
  //               setState((prevState) => ({ ...prevState,
  //                 itemsPagination: {
  //                   ...prevState.itemsPagination,
  //                   items
  //                 }
  //               }))
  //                 closeCreateFrom('history')
  //                 maintenanceAnalytic() 
  //     }).catch(() => {
  //       // errorHandler(error,props.dispatch)
  //     })
  //     // .catch(e => {
  //     //     props.dispatch(
  //     //       Notifications.error({
  //     //         message: props.translate('somthingWentWrongMessage'),
  //     //         autoDismiss: 10
  //     //       })
  //     //     )
  //     // })
  //   }
  const maintenanceAnalytic = () => {
    // fetch(`/api/services/analytic`, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/services/analytic`,
      method: `GET`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then(data => {
        setState((prevState) => ({
          ...prevState,
          analyticData: data
        }))
        //   })
        // }
        // else{
        //   throw response
        // }
      }).catch(() => {
        // errorHandler(error,props.dispatch)
      })
  }
  const item_Selection = (checked, item) => {
    let selectedUnit = item
    let method = 'DELETE'
    let value = checked
    let allData = [...props.selecteduser]
    let obj = {}
    if (state.assignMode === 'user') {
      obj.userId = item.id
    }
    if (state.assignMode === 'unit') {
      obj.deviceId = item.id
    }
    /* if(state.assignMode === 'group') {
      obj.groupId = item.id
    } */
    obj.maintenanceId = parseInt(state.currentMaintenance.id)
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.userId) {
              dt.check = false
            }
            return null
          })

          props.dispatch(
            Notifications.success({
              message: props.translate(state.assignMode === 'user' ? 'userUnAssignedSuccessfully' : state.assignMode === 'unit' ? 'trackerIsUnAssignedSuccessfully' : 'groupUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
          if (item) {
            selectedUnit.label = item.name
            selectedUnit.value = item.value
            setState((prevState) => ({
              ...prevState,
              selectedUnit,
              vehicleTracker: {}
            }))
            onChangedDevice(state.vehicleTracker)
          }
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.userId) {
              dt.check = true
            }
            return null
          })

          props.dispatch(
            Notifications.success({
              message: props.translate(state.assignMode === 'user' ? 'userAssignedSuccessfully' : state.assignMode === 'unit' ? 'trackerIsAssignedSuccessfully' : 'groupAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
          if (item) {
            selectedUnit.label = item.name
            selectedUnit.value = item.value
            setState((prevState) => ({
              ...prevState,
              selectedUnit,
              vehicleTracker: {}
            }))
            onChangedDevice(state.selectedUnit)
          }
        }

        props.dispatch(userDeviceWiseSelectedList(allData))

        // } else {
        //   throw response
        // }
      }).catch(error => { errorHandler(error, props.dispatch) })
    // .catch(e => {
    //   if (e && e.text) {
    //     e.text().then(err => {
    //       let a = err.split('SecurityException').splice(0, 1)
    //       //alert(a[0])
    //       props.dispatch(
    //         Notifications.error({
    //           message: a[0],
    //           autoDismiss: 10
    //         })
    //       )
    //     })
    //   }
    // })
  }

  const onRemovedItem = item => {
    setState((prevState) => ({
      ...prevState,
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    }))
  }

  // const onRemovedExpenseItem= () => {
  //    setState((prevState) => ({ ...prevState,
  //     onDeleteExpenseConfirm: true
  //   }))
  // }

  const removeItem = () => {
    if (state.assignMode === 'unit') {

      removeFromDatabase();
    }
    else if (state.assignMode === 'user') {

      removeFromDatabase();

    }
    /* else if (state.assignMode === 'group') {

      removeFromDatabase();
    } */

  }

  const removeFromDatabase = () => {
    let obj = state.deleteForm
    let params = obj.id
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }

      let call;
      if (state.assignMode === 'user') {
        call = `/api/users/${params}`
      }
      else if (state.assignMode === 'unit') {
        call = `/api/devices/${params}`
      }
      /* else if(state.assignMode === 'group') {
        call = `/api/groups/${params}`
      } */

      if (call) {
        // fetch(`${call}`, {
        //   method: `DELETE`,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `${call}`,
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(response => {// eslint-disable-line no-unused-vars
            // if (response.ok) {
            props.dispatch(removedUserDeviceWiseSelectedList(obj))
            let translationKey;
            if (state.assignMode === 'user') {
              translationKey = `userIsRemoved`
              props.dispatch(removeUser(obj))
            }
            else if (state.assignMode === 'unit') {
              translationKey = `trackersIsDeleted`
              props.dispatch(removeDevice(obj.id))
            }
            /* else if(state.assignMode === 'group') {
              translationKey = `groupDeleted`
              props.dispatch(removeGroup(obj))
            } */
            props.dispatch(
              Notifications.success({
                message: props.translate(translationKey),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmation: false
            }))
            // }
            // else{
            //   throw response
            // }
          }).catch(error => { errorHandler(error, props.dispatch) })
      }
    }
  }

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmation: false,
      onDeleteExpenseConfirm: false,
    }))
  }

  const saveToParent = (obj) => {
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        attributes: { ...prevState.maintenanceForm.attributes, ...obj }
      }
    }));
    // , () => checkRequiredFields()
  }

  const saveToExpense = (obj) => {
    setState((prevState) => ({
      ...prevState,
      expenseForm: {
        ...prevState.expenseForm,  // Spread operator to copy existing expenseForm state
        attributes: {
          ...prevState.expenseForm.attributes,  // Spread operator to copy existing attributes
          ...obj  // Spread operator to merge obj into attributes
        }
      }
    }));
  }


  const getDriverByVehicleId = (id) => {
    instance({
      method: 'GET',
      url: `/api/drivers/vehicleid/${id}`,
      cancelToken: source.token
    })
      .then(response => {
        let driver = {
          key: response.id,
          id: response.id,
          label: response.name,
          name: response.name,
          uniqueId: response.uniqueId,
          valueType: "string"
        }
        if (driver) {
          setState((prevState) => ({
            ...prevState,
            expenseForm: {
              ...prevState.expenseForm,
              driver: driver
            }
          }))
        }
      }).catch(() => {
        // errorHandler(error,props.dispatch)
      })
  }

  const showDueMaintenance = (id) => {
    fetchDueMaintenance(id, 1, 10)
  }

  const showDueMaintenanceDialogFunc = () => {
    setState((prevState) => ({ ...prevState, showDueDialog: true }))
  }

  const onCloseDueMaintenanceModel = () => {
    setState((prevState) => ({ ...prevState, showDueDialog: false }))
  }

  const toggleFilterForm = () => {
    setState((prevState) => ({
      ...prevState,
      showStats: !state.showStats,
      statusFilter: [],
      countChecked: 0,
      itemsPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        hasNext: true,
      },
      vehicleId: [],
      // areaId:[],
      toDate: '',
      fromDate: '',
      filterStatusType: '',
      mode: 'due'
    }))
   
  }

  useEffect(()=>{
    if (!state.showStats) {fetchMaintenanceHistory(1, 20)}
  },[state.showStats])

  const showDates = (e, date) => {
    let timezone = 'Asia/Dubai'
    if (
      props.ServerSettings &&
      props.ServerSettings.attributes &&
      props.ServerSettings.attributes.timezone
    ) {
      timezone = props.ServerSettings.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }

    let timeFormat = getDateTimeFormat()

    setState(
      {
        fromDate: formatedDate.from,
        toDate: formatedDate.to,
        selectedDateTime:
          moment(date.startDate).format(timeFormat) +
          ' - ' +
          moment(date.endDate).format(timeFormat),
        ...formatedDate
      },)
  }

  // const handleChangeSidebar = (name, e) => {
  //   console.warn("Hellllllllllllllllllllllllllllllllllloooooooooooooooooooooooooooolo")
  //   if (name === 'sideBarStatus') {
  //     setState((prevState) => ({ ...prevState, sideBarStatus: e.target.value }))
  //     let filter = ''
  //     if (state.sideBarStatus) {
  //       filter += '&status=' + state.sideBarStatus
  //     }
  //     setState((prevState) => ({
  //       ...prevState,
  //       filterStatusType: filter
  //     }))

  //     fetchMaintenanceHistory(1, state.pagesizeHist)
  //   }

  //   else if (e === 'maintenance') {
  //     setState((prevState) => ({
  //       ...prevState,
  //       maintenanceId: name
  //     }))

  //     setState((prevState) => ({ ...prevState, maintenanceId: name }))
  //     let filter = '';
  //     Object.entries(state.maintenanceId).map(([key, value]) => {// eslint-disable-line no-unused-vars
  //       if (value) {
  //         filter += '&status=' + value

  //       }
  //     })
  //     setState((prevState) => ({
  //       ...prevState,
  //       selectedMaintenanceId: filter
  //     }))

  //   }
  //   else if (e === 'vehicle') {
  //     setState((prevState) => ({
  //       ...prevState,
  //       vehicleId: e.target.value
  //     }))

  //     let filter = '';
  //     Object.entries(state.vehicleId).map(([key, value]) => {// eslint-disable-line no-unused-vars

  //       if (value) {
  //         filter += '&vehicleId=' + value
  //       }
  //     })
  //     setState((prevState) => ({
  //       ...prevState,
  //       selectedVehicleId: filter
  //     }))
  //   }
  // }

  const checkRequiredFieldsSidebar = () => {
    let { toDate, fromDate } = state
    if (toDate && fromDate) setState((prevState) => ({ ...prevState, disabledSidebarBtn: false }))
    else setState((prevState) => ({ ...prevState, disabledSidebarBtn: true }))
  }

  useEffect(() => {
    if (state.loadingSidebar) {
      fetchMaintenanceHistory2(1, state.pagesizeHist)
    }
  }, [state.loadingSidebar])

  const submitSideBarSearch = (data) => {
    console.warn("data",data)
    setState((prevState) => ({
      ...prevState,
      loadingSidebar: true,
      areaId:data.areaId,
      vehicleId: data.vehicleId,
      selectedAreaId:data.selectedAreaId,
      selectedVehicleId: data.selectedvehicleId,
      filterStatusType: data.selectedMaintenanceId,
      toDate: data.to,
      fromDate: data.from
    }))
  }
  const importFunc = (res) => {
    // let arr = [];
    let data =
      res &&
      res.map((item) => {
        return {
          ...item.service,
          expenseCollection: [item.serviceExpenseCollection],
        };
      });
    setState((prevState) => ({
      ...prevState,
      importedData: data,
      importedReceivedData: res,
      showColorTable: true,
    }));
  };

  const onCloseColorModel = () => {
    setState((prevState) => ({ ...prevState, showColorTable: false }));
  };


  const SubmitImport = (e) => {
    e.preventDefault();
    const objFor = (
      state.importedReceivedData && state.importedReceivedData
    ).map((item) => {
      item.serviceExpenseCollection.map((it) => {
        delete it?.attributes?.invalid;
        return { ...it };
      });
      delete item?.service?.attributes?.invalid;
      return { ...item };
    });
    instance({
      url: `/api/services/bulk`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: objFor,
    })
      .then(() => {
        props.dispatch(
          Notifications.success({
            message: props.translate("importedSuccessfully"),
            autoDismiss: 10,
          })
        );
        onCloseColorModel();
      })
      .catch((err) => {
        console.error("err ===", err);
        props.dispatch(
          Notifications.error({
            message: props.translate(err),
            autoDismiss: 10,
          })
        );
      });
  };

  // render () { 
  const endMessage =
    state.itemPagination && state.itemPagination.total > 0 ? (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        {' '}
        -- {props.translate('end')} --{' '}
      </p>
    ) : (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        {' '}
        {props.translate('notFound')}{' '}
      </p>
    )
  let image = state.maintenanceHistForm.files && state.maintenanceHistForm.files.split(',')

  if (checkPrivileges('maintenance')) {
    return (
      <div>
        <Layout
          {...props}
          endMessage={endMessage}
          openCreateFrom={openCreateFrom}
          createExpense={createExpense}
          editHistoryFrom={editHistoryFrom}
          closeCreateFrom={closeCreateFrom}
          classFromChildren='has-padding'
          editMaintenanceForm={editMaintenanceForm}
          fetchMoreItems={fetchMoreItems}
          fetchMoreItemsHist={fetchMoreItemsHist}
          // searchItems={searchItems}
          toggleFilterForm={toggleFilterForm}
          showDates={showDates}
          // handleChangeSidebar={handleChangeSidebar}
          handleChangeFilter={handleChangeFilter}
          handleClickFilter={handleClickFilter}
          handleCloseFilter={handleCloseFilter}
          submitSideBarSearch={submitSideBarSearch}
          {...state}
        >
          <div className='main-content-page'>
            <div id="HideMe" />
            {/* <SingleItem collection="devices3" field="id" value={1536}>{
                (item) => {
                  return null;
                }
              }
              </SingleItem> */}
            {state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={onCancel}
                onOk={removeItem}
                title={props.translate('areYouWantToDelete')}
                children={state.deleteForm.name || state.deleteForm.description}
              />
            )}
            {state.onDeleteConfirm && (
              <ConfirmDialoag
                onCancel={onCancelMaintenance}
                onOk={onDeleteMaintenance}
                title={props.translate('areYouWantToDelete')}
                children={state.itemDeleteName}
              />
            )}
            {state.onStatusChange && (
              <ConfirmDialoag
                onCancel={onCancelStatus}
                onOk={onChangeStatus}
                OkText={'Yes'}
                CancelText={'No'}
                title={props.translate('areYouWantChangeStatus')}
                children={state.itemStatusChange}
              />
            )}
            {state.onMarkComplete && (
              <ConfirmDialoag
                onCancel={onCancelMark}
                onOk={onCompleteMark}
                OkText={'Yes'}
                CancelText={'No'}
                title={props.translate('Do you want to Mark complete this maintenance')}
                children={state.itemMarkComplete}
              />
            )}

            {state.onDeleteExpense && (
              <ConfirmDialoag
                onCancel={onCancelExpense}
                onOk={onDeleteExpenseItem}
                title={props.translate('areYouWantToDelete')}
                children={state.itemDeleteExpense}
              />
            )}
            {state.enableImagePopup && (
              <CustomDialog
                title={props.translate('Images')}
                themecolors={props.themecolors}
                visable={true}
                onClose={onCloseModal}
                bodyPadding={10}
                cancelText={props.translate('sharedCancel')}
                noFullScreen
              >
                {state.maintenanceHistForm && state.maintenanceHistForm.files ? (<div style={{ display: 'flex', flexWrap: 'wrap', }}>
                  <ImageList cols={2.5} style={{ flexWrap: 'nowrap' }}>
                    {image.map((item, i) =>
                      item && (<ImageListItem key={item} style={{ height: 'auto', width: 'auto' }}>
                        <a href={item} download target="_blank" rel="noopener noreferrer">
                          <Tooltip
                            classes={{
                              popper: 'menu-popper',
                              tooltip: 'menu-popper-tooltip'
                            }}
                            title={props.translate('DOWNLOAD')}>
                            <FileCopyIcon style={{ width: 80, height: 80, color: '#ffffff' }} />
                          </Tooltip>
                        </a>
                        <p style={{ textAlign: 'center', marginTop: 0 }}>File {i + 1}</p>
                      </ImageListItem>))}
                  </ImageList>
                </div>) : <h3 style={{ display: 'flex', justifyContent: 'center' }}>No Image Selected</h3>}
              </CustomDialog>
            )}
            {state.showColorTable && (
              <Grid container spacing={1}>
                <CustomDialog
                  title="Imported Services"
                  themecolors={props.themecolors}
                  visable={true}
                  onClose={onCloseColorModel}
                  bodyPadding={10}
                  isVisableBtn
                  noFullScreen
                  maxWidth={500}
                  importServiceTbl
                  headerActions={
                    <Button
                      disabled={state.importedData.length ? false : true}
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={(e) => SubmitImport(e)}
                    >
                      {" "}
                      {props.translate("sharedAdd")}
                    </Button>
                  }
                >
                  <div>
                    <Table
                      rows={state.importedData || []}
                      pagination={setDuePagination(state.importedData || [])}
                      handleChangeRowsPerPage={(n) => handleChangeRowsPerPage(n, "dueMaintenance")}
                      handleChangePage={(n) => handleChangePage(n, "dueMaintenance")}
                      logInUser={props.logInUser}
                      isEditable={false}
                      status={false}
                      completeMark={false}
                      themecolors={props.themecolors}
                      translate={props.translate}
                      hasAccessOfCreate={false}
                      hasAccessOfUpdate={false}
                      hasAccessOfDelete={false}
                      vehicles={props.vehicles}
                      rowDefinition={[
                        {
                          id: "name",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("service"),
                        },
                        {
                          id: "description",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("description"),
                        },
                        {
                          id: "refNum",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("refNum"),
                        },
                        {
                          id: "vehicleLicensePlate",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("vehicleLicensePlate"),
                        },
                        {
                          id: "driverUniqueId",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("driverUniqueId"),
                        },
                        {
                          id: "odometer",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("totalDistance"),
                        },
                        {
                          id: "engineHours",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("reportEngineHours"),
                        },
                        {
                          id: "serviceTime",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("serviceTime"),
                        },
                        {
                          id: "cost",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("cost"),
                        },
                        {
                          id: "status",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("status"),
                        },
                        {
                          id: "expenseCollection",
                          numeric: false,
                          disablePadding: false,
                          label: props.translate("expenseCollection"),
                        },
                      ]}
                    />
                  </div>
                </CustomDialog>
              </Grid>
            )}
            {state.mode === 'create' ||
              state.mode === 'update' ? (
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  color: props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                }}
              >

                <CreateMaintenanceForm
                  vehicleTracker={state.vehicleTracker}
                  selectedUnit={state.selectedUnit}
                  mode={state.mode}
                  form={state.maintenanceForm}
                  isVisible={state.isVisible}
                  servicesAttributes={expenseType}
                  subTypes={state.subTypes2 || []}
                  translate={props.translate}
                  themecolors={props.themecolors}
                  logInUser={props.logInUser}
                  dispatch={props.dispatch}
                  saveToParent={saveToParent}
                  assignModal={assignModal}
                  submitForm={submitForm}
                  closeCreateFrom={closeCreateFrom}
                  handleChangeAttributes={handleChangeAttributes}
                  handleChange={handleChange}
                  onChangedDevice={onChangedDevice}
                  uploadImage={uploadImage}
                  imagePopup={imagePopup}
                  vehicles={vehiclesList}
                /></div>
            ) : null}
            {state.mode === 'updateHist' ? (
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  color: props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                }}
              >

                <CreateExpense
                  editOption

                  expenseForm={state.expenseForm}
                  vehicleTracker={state.vehicleTracker}
                  selectedUnit={state.selectedUnit}
                  isVisible={state.isVisible}
                  serviceExpenseCollection={state.serviceExpenseCollection}
                  mode={state.dueActive}
                  image64Type={state.image64Type}
                  dueActive={state.dueActive}
                  loader={state.loader}
                  drivers={driversList}
                  // garageList = {garageList}
                  statusTypes={statusTypes}
                  notificationMode={state.mode === 'createExpense' ? "create" : "update"}
                  saveToParent={saveToExpense}
                  dispatch={props.dispatch}
                  vehicles={vehiclesList}
                  subTypes={subTypes}
                  expenseType={expenseType && expenseType.length ? expenseType : allExpenseTypes}
                  themecolors={props.themecolors}
                  translate={props.translate}
                  logInUser={props.logInUser}
                  handleChangeExpense={handleChangeExpense}
                  onChangedDevice={onChangedDevice}
                  closeExpense={closeExpense}
                  closeCreateFrom={closeCreateFrom}
                  submitExpense={ud_expenseSubmit}
                  addMore={addMore}
                  uploadImage={uploadImage}
                  handleChangeServices={handleChangeServices}
                  deleteRow={deleteRow}
                  imagePopup={imagePopup}
                  uploadFile={uploadFile}
                  deleteImage={deleteImage}
                  showDueMaintenance={showDueMaintenance}
                  showDialogBtn={state.showDialogBtn}
                  vehicleDueMaintenance={state.vehicleDueMaintenance}
                  showDueDialog={state.showDueDialog}
                  showDueMaintenanceDialogFunc={showDueMaintenanceDialogFunc}
                  onCloseDueMaintenanceModel={onCloseDueMaintenanceModel}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  setDuePagination={setDuePagination}
                  historyEdit={state.historyEdit}
                  // areaList={checkPrivileges('area') ?areaList : []}
                  tab={state.tab}
                  check
                />
              </div>
            ) : null}

            {state.mode === 'showMaintenance' && !state.showStats ? (
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  // background: props.themecolors.backgroundColor,
                  color: props.themecolors.textColor,
                  borderRadius: 6,
                  paddingLeft: 0,
                  paddingRight: 0
                }}
              >
                <MaintenanceForm
                  data={state.itemPagination}
                  dueActive={state.dueActive}
                  isDataRecieved={state.isDataRecieved}
                  mode={state.mode}
                  translate={props.translate}
                  themecolors={props.themecolors}
                  logInUser={props.logInUser}
                  setPagination={setPagination}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  SearchItem={SearchItem}
                  openCreateFrom={openCreateFrom}
                  onRemove={onRemovedMaintenance}
                  onStatusChange={onStatusChange}
                  onMarkComplete={onMarkComplete}
                  switchMode={switchMode}
                  editMaintenanceForm={editMaintenanceForm}
                  vehicles={props.vehicles}
                />
              </div>
            ) : null}

            {(state.mode === ('history') || state.mode === ('due')) && !state.showStats ? (
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  color: props.themecolors.textColor,
                  borderRadius: 6,
                  paddingLeft: 0,
                  paddingRight: 0
                }}
              >
                <MaintenanceHistoryForm
                  data={state.itemsPagination}
                  dueActive={state.dueActive}
                  isDataRecieved={state.isDataRecieved}
                  countChecked={state.countChecked}
                  statusFilter={state.statusFilter}
                  anchorEl={state.anchorEl}
                  mode={state.mode}
                  expenseForm={state.expenseForm}
                  searchVehicleId={state.searchVehicleId}
                  // searchAreaId={state.searchAreaId}
                  // vehicles={vehiclesList}
                  // histFormVisible={state.histFormVisible}
                  translate={props.translate}
                  vehicles={props.vehicles}
                  // areas={props.areas}
                  deviceRelatedData={props.deviceRelatedData}
                  themecolors={props.themecolors}
                  logInUser={props.logInUser}
                  setPagination={setPaginationHistory}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  SearchItem={SearchItem}
                  SearchVehicle={SearchVehicle}
                  // SearchArea={SearchArea}
                  openCreateFrom={openCreateFrom}
                  handleChangeFilter={handleChangeFilter}
                  handleClickFilter={handleClickFilter}
                  handleCloseFilter={handleCloseFilter}
                  imagePopup={imagePopup}
                  switchMode={switchMode}
                  onRemove={onRemovedExpense}
                  editHistoryFrom={editHistoryFrom}
                  createExpense={createExpense}
                  importFunc={importFunc}
                /></div>
            ) : null}

            {state.mode === 'createExpense' ? (
              <div style={{ height: '100%', position: 'relative', zIndex: 9999 }}>
                <CreateExpense
                  expenseForm={state.expenseForm}
                  vehicleTracker={state.vehicleTracker}
                  selectedUnit={state.selectedUnit}
                  isVisible={state.isVisible}
                  serviceExpenseCollection={state.serviceExpenseCollection}
                  mode={state.dueActive}
                  image64Type={state.image64Type}
                  loader={state.loader}
                  dueActive={state.dueActive}
                  drivers={driversList}
                  // garageList = {garageList}
                  statusTypes={statusTypes}
                  notificationMode={state.mode === 'createExpense' ? "create" : "update"}
                  saveToParent={saveToExpense}
                  dispatch={props.dispatch}
                  vehicles={vehiclesList}
                  subTypes={subTypes}
                  expenseType={expenseType && expenseType.length ? expenseType : allExpenseTypes}
                  themecolors={props.themecolors}
                  translate={props.translate}
                  logInUser={props.logInUser}
                  completeMode={state.completeMode}
                  handleChangeExpense={handleChangeExpense}
                  onChangedDevice={onChangedDevice}
                  closeExpense={closeExpense}
                  closeCreateFrom={closeCreateFrom}
                  submitExpense={submitExpense}
                  addMore={addMore}
                  uploadImage={uploadImage}
                  handleChangeServices={handleChangeServices}
                  deleteRow={deleteRow}
                  imagePopup={imagePopup}
                  uploadFile={uploadFile}
                  deleteImage={deleteImage}
                  showDueMaintenance={showDueMaintenance}
                  showDialogBtn={state.showDialogBtn}
                  vehicleDueMaintenance={state.vehicleDueMaintenance}
                  showDueDialog={state.showDueDialog}
                  showDueMaintenanceDialogFunc={showDueMaintenanceDialogFunc}
                  onCloseDueMaintenanceModel={onCloseDueMaintenanceModel}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  setDuePagination={setDuePagination}
                  check={false}
                  // areaList={checkPrivileges('area') ?areaList : []}
                  tab={state.tab}
                />
              </div>
            ) : null}
            {state.showStats ? (
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  color: props.themecolors.textColor,
                  borderRadius: 6,
                  paddingLeft: 0,
                  paddingRight: 0
                }}
              >
                <MaintenanceComp
                  data={state.itemsPagination}
                  dueActive={state.dueActive}
                  isDataRecieved={state.isDataRecieved}
                  countChecked={state.countChecked}
                  statusFilter={state.statusFilter}
                  anchorEl={state.anchorEl}
                  mode={state.mode}
                  expenseForm={state.expenseForm}
                  searchVehicleId={state.searchVehicleId}
                  // searchAreaId={state.searchAreaId}
                  // vehicles={vehiclesList}
                  // histFormVisible={state.histFormVisible}
                  translate={props.translate}
                  vehicles={props.vehicles}
                  // areas={props.areas}
                  deviceRelatedData={props.deviceRelatedData}
                  themecolors={props.themecolors}
                  logInUser={props.logInUser}
                  setPagination={setPaginationHistory}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  SearchItem={SearchItem}
                  SearchVehicle={SearchVehicle}
                  // SearchArea={SearchArea}
                  openCreateFrom={openCreateFrom}
                  handleChangeFilter={handleChangeFilter}
                  handleClickFilter={handleClickFilter}
                  handleCloseFilter={handleCloseFilter}
                  imagePopup={imagePopup}
                  switchMode={switchMode}
                  onRemove={onRemovedExpense}
                  editHistoryFrom={editHistoryFrom}
                  createExpense={createExpense}
                /></div>
            ) : null}

            {state.model ? (
              <Dialog
                isVisableBtn={true}
                headerActions={
                  <Button
                    onClick={submitAssignModel}
                    variant='outlined'
                    size='small'
                  >
                    {props.translate('assignMaintenance')}
                  </Button>
                }
                open={state.model}
                onClose={closeAssignModal}
                title={props.translate('assignMaintenance')}
                disableFooter
              >
                <>
                  <div style={{ padding: 16, fontWeight: 700, paddingBottom: 0 }}><MaintenanceIcon fill="currentColor" width={20} height={20} style={{ verticalAlign: 'middle', marginRight: 5 }} /> {state.currentMaintenance &&
                    state.currentMaintenance.name}</div>
                  {state.assignMode === 'unit' ? (
                    <ItemsSelector
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      rowsPerPage={state.rowsPerPage}
                      selectAll={e =>
                        selectAll(
                          e,
                          'deviceId',
                          'maintenanceId',
                          state.currentMaintenance.id,
                          props.selecteduser,
                          props.devices3,
                          props.dispatch,
                          'access',
                          props.translate
                        )
                      }
                      parent={0}
                      item_Selection={item_Selection}
                      themecolors={props.themecolors}
                      data={props.selecteduser}
                      translate={props.translate}
                      onDelete={onRemovedItem}
                      canDelete={checkPrivileges('deviceDelete')}
                      canRemove={checkPrivileges('deviceLinkMaintenance')}
                      canAssign={checkPrivileges('deviceUnlinkMaintenance')}
                    />
                  ) : null}

                  {state.assignMode === 'user' ? (
                    <ItemsSelector
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      rowsPerPage={state.rowsPerPage}
                      selectAll={e =>
                        selectAll(
                          e,
                          'userId',
                          'maintenanceId',
                          state.currentMaintenance.id,
                          props.selecteduser,
                          props.users,
                          props.dispatch,
                          'access',
                          props.translate
                        )
                      }
                      parent={0}
                      item_Selection={item_Selection}
                      themecolors={props.themecolors}
                      data={props.selecteduser}
                      translate={props.translate}
                      onDelete={onRemovedItem}
                      canDelete={checkPrivileges('userDelete')}
                      canRemove={checkPrivileges('userLinkMaintenance')}
                      canAssign={checkPrivileges('userUnlinkMaintenance')}
                      rowDefinition={[
                        {
                          id: 'name',
                          numeric: false,
                          disablePadding: false,
                          label: props.translate('sharedName')
                        },
                        {
                          id: 'email',
                          numeric: false,
                          disablePadding: false,
                          label: props.translate('userEmail')
                        },
                        {
                          id: 'roleName',
                          numeric: false,
                          disablePadding: false,
                          label: props.translate('roleName')
                        },
                        {
                          id: 'parentName',
                          numeric: false,
                          disablePadding: false,
                          label: props.translate('parentName')
                        }
                      ]}
                    />
                  ) : null}
                </>
              </Dialog>
            ) : null}
            {state.mode === '' ? (
              <EmptyState
                text={props.translate('noMaintenanceSelected')}
              />
            ) : null}
          </div>
        </Layout>
      </div>
    )
  } else {
    return null
  }
}
// }
const mapStateToProps = state => ({
  maintenance: state.maintenance,
  users: state.users,
  groups: state.groups,
  selecteduser: state.selecteduser,
  roles: state.roles,
  deviceRelatedData: state.deviceRelatedData,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  drivers: state.drivers,
  vehicles: state.vehicles,
  ServerSettings: state.ServerSetting,
  // areas:state.areas
})

export default connect(mapStateToProps)((withLocalize(Maintenance)))