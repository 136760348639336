import React, { useState , useEffect} from "react"
import Map from './../../Pages/MarkerMap/Map'
import { ReactComponent as PlayIcon } from '../../assets/monitoring/play.svg'
import { ReactComponent as StopIcon } from '../../assets/monitoring/stop.svg'
import { ReactComponent as IgnitionIcon } from '../../assets/monitoring/idling.svg'
import { ReactComponent as TowingIcon } from '../../assets/monitoring/towing.svg'
import { ReactComponent as ParkingIcon } from '../../assets/monitoring/parking.svg'
import { ReactComponent as QuestionIcon } from '../../assets/monitoring/question.svg'
import { FeatureSection } from '../Features/FeatureSection'
import CloseIcon from '@mui/icons-material/Close'
import MinimizeIcon from '@mui/icons-material/Remove'
import { connect } from "react-redux"
import Draggable from "react-draggable"
import Scrollbar from "react-scrollbars-custom"
import Button from "./Button"
// import isEqual from "react-fast-compare"

const maxVisible = 5

// export default class TrackingBox extends Component {
 export  const TrackingBox = (props) => {
  
  const [state, setState] = useState({
      toggle: {},
      mode: {}
  })

  // componentWillReceiveProps(n) {
  //   if(n.boxes && n.boxes.length && !isEqual(n.boxes, props.boxes)) {
  //     n.boxes.map((r, index) => {
  //       if(maxVisible > index) {
  //         if(!Object.keys(state.toggle).includes(r.id)) {
  //           setState({
  //             toggle: {...state.toggle, [r.id]: true}
  //           })
  //         }
  //         if(!Object.keys(state.mode).includes(r.id)) {
  //           setState({
  //             mode: {...state.mode, [r.id]: 'map'}
  //           })
  //         }
  //       }
  //       else {
  //         setState({
  //           toggle: {...state.toggle, [r.id]: false},
  //           mode: {...state.mode, [r.id]: 'map'},
  //         })
  //       }
  //       return null
  //     })
  //   }
  // }

  useEffect(() => {
    if(props.boxes && props.boxes.length) {
      props.boxes.map((r, index) => {
        if(maxVisible > index) {
          if(!Object.keys(state.toggle).includes(r.id)) {
            setState(prevState=>({
              ...prevState,
              toggle: {...prevState.toggle, [r.id]: true}
            }))
          }
          if(!Object.keys(state.mode).includes(r.id)) {
            setState(prevState=>({
              ...prevState,
              mode: {...prevState.mode, [r.id]: 'map'}
            }))
          }
        }
        else {
          setState(prevState=>({
            ...prevState,
            toggle: {...prevState.toggle, [r.id]: false},
            mode: {...prevState.mode, [r.id]: 'map'},
          }))
        }
        return null
      })
    }
  }, [props.boxes])
  

  const toggleBox = id => {
    setState((prevState) => ({ ...prevState, 
      toggle: {...prevState.toggle, [id]: !prevState.toggle[id]}
    }))
  }

  const toggleViewBox = id => {
    setState((prevState) => ({ ...prevState, 
      mode: {...prevState.mode, [id]: prevState.mode[id] === 'map' ? 'info' : 'map'}
    }))
  }
  
  // render() {
    const { boxes, closeBox, copyToClipboard, translate, logInUser} = props
    return boxes.map((person, index) => <Draggable key={index}><ChatBody copyToClipboard={copyToClipboard} translate={translate} logInUser={logInUser} toggleBox={toggleBox} toggleViewBox={toggleViewBox} closeBox={closeBox} toggle={state.toggle} mode={state.mode} unit={person} index={index} /></Draggable>)

  // }
}
const remainder = 5

const mapStateToProps = state => ({
    positions: state.positions,
    insideGeofence: state.insideGeofence,
    geofences: state.geoFence,
    deviceRelatedData: state.deviceRelatedData,
    trackId:state.trackId
  }
)



const ChatBody1 = ({deviceRelatedData, positions, insideGeofence, geofences, toggle, mode, unit, index, toggleViewBox, closeBox, toggleBox, copyToClipboard, translate, logInUser,trackId }) => {
  const row = Math.floor(index / remainder)
  const pos = positions.find(p => p.deviceId === unit.id)
  const device = (deviceRelatedData && deviceRelatedData[unit.id]) || {}
  const { icons } = device
  const icon = icons && icons.playicon && icons.playicon.icon ? icons.playicon.icon : ""
  let RelatedIcon = null
  if(icon === 'play') { RelatedIcon = PlayIcon }
  else if(icon === 'ignition') { RelatedIcon = IgnitionIcon }
  else if(icon === 'towing') { RelatedIcon = TowingIcon }
  else if(icon === 'parking') { RelatedIcon = ParkingIcon }
  else if(icon === 'stop') { RelatedIcon = StopIcon }
  else if(icon === 'unknown') { RelatedIcon = QuestionIcon }
  const onClick = () => {
    toggleViewBox(unit.id)
  }

  return (<>
    <div className="chat-wrapper" key={unit.id}>
     <Draggable handle=".boxhandle">
        <div className="msg_box" style={{ bottom: row === 0 ? 30 : row === 1 ? row * (unit.width + unit.margin + 30 + 30 + 10) : ((unit.width + unit.margin + 30) + (40 * row)), right: (unit.width * (index % remainder)) + (unit.margin * ((index % remainder) + 1)), width: unit.width  }}>
          <div className="msg_head boxhandle">
              <div className="msg-unit-icon"><img src={`/assets/category/default/${device && device.category ||
                        'default'}.svg`} alt=''/></div>
              <div style={{marginRight: 'auto'}}>{unit.name}</div>
              <div className={'action-item action-running '+icons.play} style={{margin: '0 10px'}}>{RelatedIcon && <RelatedIcon fill="currentColor" width={16} height={16} />}</div>
              <div className="closebox" onClick={() => toggleBox(unit.id)} ><MinimizeIcon /></div>
              <div className="closebox" onClick={(e) => closeBox(unit.id, e)} ><CloseIcon /></div>
          </div>
          {toggle[unit.id] ? <div className="msg_wrap">
              <div className="msg_body" style={{height: unit.width}}>
              {mode[unit.id] === 'map' ? 
              <Map mapClass="chat-map-container"
               onClick={onClick}
               motionStatus={device&&icons?.playicon}
               category={device && device.category || 'default'} 
               animationTime={device?.animationTime || 0} course={pos && pos.course || 0}
               lat={pos && pos.latitude || 1} lng={pos && pos.longitude || 1}
               logInUser={logInUser}
               color={device&&device.deviceAttributes?.color}
               speed={pos?.speed}
               deviceData={device&&device}
               status={pos?.status}
               trackId={trackId&&trackId}
                />
               : null}
              {mode[unit.id] === 'info' ? <Scrollbar><div>
                  <FeatureSection
                    key='basicDetails'
                    type='basicDetails'
                    device={device}
                    details={device}
                    translate={translate}
                    copyToClipboard={copyToClipboard}
                    smallBox
                  />
                  <FeatureSection
                    key='Location'
                    type='Location'
                    device={device}
                    details={device}
                    insideGeofence={insideGeofence}
                    geofences={geofences}
                    logInUser={logInUser}
                    translate={translate}
                    copyToClipboard={copyToClipboard}
                    smallBox
                  />
                  <div style={{padding: "0 10px", textAlign: 'center', marginTop:5}}><Button onClick={onClick}>Back to Map</Button></div>
                  </div></Scrollbar> : null}
              </div>
              <div className="boxhandle-icon boxhandle" />
            </div> : null}
      </div></Draggable>
    </div></>)
}

const ChatBody = connect(mapStateToProps)(ChatBody1)

