import React, { useState, useEffect } from 'react';
import moment from 'moment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import ProtocolDialog from '../../../Components/Features/ProtocolDialog';
import { getDateTimeFormat, setAttributeFormat } from '../../../Helpers';
import EmptyState from '../../../Components/common/EmptyState';
import {  useSelector } from 'react-redux';

const DrawAttributes = props => {

  if (props.data && props.data.attributes) {
    const keys = Object.keys(props.data.attributes)
    if (keys?.length) {
      return keys?.map(k => {
        let v = props.data.attributes[k]
        let t = k
        let statusText = null
        let inputText = null

        switch (k) {
          case 'status':
            if (props.data.protocol === 'galileo') {
              statusText = (
                <HelpOutlineIcon
                  style={{ verticalAlign: 'middle' }}
                  onClick={() =>
                    props.openProtocol(
                      props.data.attributes.status,
                      props.data.protocol,
                      k
                    )
                  }
                />
              )
            }
            break
          case 'input':
            if (props.data.protocol === 'bce') {
              inputText = (
                <HelpOutlineIcon
                  style={{ verticalAlign: 'middle' }}
                  onClick={() =>
                    props.openProtocol(
                      props.data.attributes.input,
                      props.data.protocol,
                      k
                    )
                  }
                />
              )
            }

            break
          case 'hours':
            v = v ? (v / (1000 * 3600)).toFixed(2)+" hrs" : 0+" hrs"
            break
         
          case 'unknown':
            return null
          default:
            v = setAttributeFormat(k, v)
            break
        }
        switch (k) {
          case 'odometer':
            return null
          case 'index':
          case 'hdop':
          case 'vdop':
          case 'pdop':
          case 'sat':
          case 'satVisible':
          case 'rssi':
          case 'gps':
          case 'roaming':
          case 'event':
          case 'alarm':
          case 'status':
          case 'serviceOdometer':
          case 'tripOdometer':
          case 'hours':
          case 'steps':
          case 'power':
          case 'input':
          case 'output':
          case 'battery':
          case 'batteryLevel':
          case 'fuel':
          case 'fuelConsumption':
          case 'approximate':
          case 'rpm':
          case 'throttle':
          case 'armed':
          case 'acceleration':
          case 'deviceTemp':
          case 'obdSpeed':
          case 'obdOdometer':
          case 'distance':
          case 'totalDistance':
          case 'commandResult':
          case 'dtcs':
          case 'blocked':
          case 'operator':
          case 'deviceCommand':
          case 'sharedGeofence':
          case 'motion':
          case 'vin':
          case 'deviceTotalDistance':
          case 'rfid':
          case 'versionFw':
          case 'versionHw':
          case 'ignition':
          case 'flags':
          case 'charge':
          case 'ip':
          case 'archive':
          case 'sharedType':
          case 'driverUniqueId':
          case 'trailerUniqueId':
          case 'stopTime':
          case 'raw':
          case 'mileageAccuracy':
          case 'parking':
          case 'custom':
          case 'boardPower':
          case 'lastUpdated':
          case 'devicePowerCut':
            t = props.translate(k)
            break
          default:
            break
        }
        return (
          <div className='all-attribute-item' key={k}>
            <Tooltip 
              title={props.translate('positionTooltip.' + k)}
              placement="right-start"     //hang issue fixed,
            >
              <span className='attr-label'>{t} </span>
            </Tooltip>{' '}
            <span className='attr-value'>
              {v} {inputText} {statusText}
            </span>
          </div>
        )
      })
    } else {
      return null
    }
  } else {
    return null
  }
}

const RawAttributes = ({
  devices,
  positions,
  selectedDeviceId,
  // groups,
  // logInUser,
  translate,
}) => {

  const logInUser=useSelector((state)=>state.logInUsers)
  const groups=useSelector((state)=>state.groups)


  

  const [state, setState] = useState({
    device: {},
    details: {},
    group: {},
    modal: false,
    input: '',
    protocol: '',
    inputName: '',
  });

  useEffect(() => {
    const list = {};
    devices?.forEach((d) => {
      list[d.id] = d;
    });
    const positionsMap = {};
    positions?.forEach((d) => {
      positionsMap[d.deviceId] = d;
    });

    if (list[selectedDeviceId]) {
      setState((prevState) => ({
        ...prevState,
        device: list[selectedDeviceId],
        details: positionsMap[selectedDeviceId] || {},
      }));
    }
  }, [devices, positions, selectedDeviceId]);

  useEffect(() => {
    const groupsMap = {};
    groups?.forEach((d) => {
      groupsMap[d.id] = d;
    });

    if (state.device && groupsMap[state.device.groupId]) {
      setState((prevState) => ({
        ...prevState,
        group: groupsMap[state.device.groupId],
      }));
    }
  }, [groups, state.device]);

  const openProtocol = (input, protocol, inputName) => {
    setState({
      ...state,
      modal: true,
      input,
      protocol,
      inputName,
    });
  };

  const closeProtocol = () => {
    setState({
      ...state,
      modal: false,
      input: '',
      protocol: '',
      inputName: '',
    });
  };

  let attr = null;
  let serverTime = null;
  let serverTimeString = null;
  const timeFormat = getDateTimeFormat();

  if (Object.keys(state.details)?.length) {
    if (logInUser.attributes && logInUser.attributes.timezone) {
      serverTimeString = moment(state.details.serverTime)
        .tz(logInUser.attributes.timezone)
        .format(timeFormat);
    }

    attr = (
      <DrawAttributes
        data={state.details}
        openProtocol={openProtocol}
        translate={translate}
      />
    );

    if (state.details.id) {
      serverTime = (
        <div className='all-attribute-item'>
          <Tooltip
            title={translate('positionTooltip.lastUpdated')}
            placement='right-start'
          >
            <span className='attr-label'>
              {translate('positionTooltip.lastUpdated')}
            </span>
          </Tooltip>{' '}
          <span className='attr-value'>{serverTimeString}</span>
        </div>
      );
    }

    return (
      <div>
        {attr}
        {serverTime}
        {state.modal && (
          <ProtocolDialog
            protocol={state.protocol}
            input={state.input}
            inputName={state.inputName}
            handleClose={closeProtocol}
          />
        )}
      </div>
    );
  } else {
    return <EmptyState text={translate('NotExistsAttribute')} />;
  }
};



export default RawAttributes;
