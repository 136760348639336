import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@mui/material';
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import {
  checkPrivileges,
  errorHandler,
  PaginationConfig,
  postionAttributesTypeComp,
  selectAll
} from '../../Helpers'
import isEqual from 'react-fast-compare'
import Notifications from 'react-notification-system-redux'
import ComputedAttributes from './Components/computedAttributes'
import ReactHtmlParser from 'react-html-parser'


/* ACTIONS */
import { addGeoFence, deleteGeofence, fetchDevices } from '../../Actions/Devices'
import {
  getDriverDeviceWise,
  addDriverDeviceWiseSelectedList,
  driverDeviceWiseSelectedList,
  RemoveDriverDeviceWise,
  updateDriverDeviceWiseSelectedList,
  addDriverDeviceWise,
  removeDriverDeviceWiseSelectedList,

  getTrailerDeviceWise,
  addTrailerDeviceWiseSelectedList,
  trailerDeviceWiseSelectedList,
  RemoveTrailerDeviceWise,
  updateTrailerDeviceWiseSelectedList,
  addTrailerDeviceWise,
  removeTrailerDeviceWiseSelectedList,


  getVehicleDeviceWise,
  addVehicleDeviceWiseSelectedList,
  vehicleDeviceWiseSelectedList,
  RemoveVehicleDeviceWise,
  updateVehicleDeviceWiseSelectedList,
  addVehicleDeviceWise,
  removeVehicleDeviceWiseSelectedList,


  addNotification,
  updateUserDeviceWiseSelectedList,
  addUserDeviceWiseSelectedList,
  addGeofenceDeviceWiseSelectedList,
  removeGeofenceDeviceWiseSelectedList,
  addNotificationDeviceWise,
  RemoveNotificationDeviceWise,
  removedNotification,
  removeNotificationDeviceWiseSelectedList,
  addNotificationDeviceWiseSelectedList,
  updateNotificationDeviceWiseSelectedList,
  getGeofenceDeviceWise,
  geofenceDeviceWiseSelectedList,
  updateGeofenceDeviceWiseSelectedList,
  RemoveGeofenceDeviceWise,
  addGeofenceDeviceWise,
  addDeviceUserWise,
  RemoveDeviceUserWise,
  getDeviceUserWise,
  deviceUserWiseSelectedList,
  updateDeviceUserWiseSelectedList,
  removeUserUsereWise,
  // removeDeviceUserWiseSelectedList
} from '../../Actions/Notifications'
import {
  addComputedAttribute,
  computedAttributeDeviceWiseSelectedList,
  getComputedAttributeDeviceWise,
  addComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise,
  removedComputedAttribute,
  removedComputedAttributeDeviceWiseSelectedList,
  addComputedAttributeDeviceWiseSelectedList,
  updateComputedAttributeDeviceWiseSelectedList
} from '../../Actions/ComputedAttributes'
import {
  addCommands,
  getCommandsDeviceWise,
  commandsDeviceWiseSelectedList,
  addCommandsDeviceWise,
  RemoveCommandsDeviceWise,
  removedCommands,
  addCommandsDeviceWiseSelectedList,
  updateCommandsDeviceWiseSelectedList,
  removedCommandsDeviceWiseSelectedList
} from '../../Actions/Commands'
import {
  addMaintenance,
  addMaintenanceDeviceWise,
  RemoveMaintenanceDeviceWise,
  getMaintenanceDeviceWise,
  maintenanceDeviceWiseSelectedList,
  removedMaintenance,
  addMaintenanceDeviceWiseSelectedList,
  updateMaintenanceDeviceWiseSelectedList,
  removedMaintenanceDeviceWiseSelectedList
} from '../../Actions/Maintenance'
import { removeDevice } from './../../Actions/Devices'
//import { removeGroup } from './../../Actions/Groups';
import { addDriver, removeDriver } from './../../Actions/Drivers'
import { addTrailer, removeTrailer } from './../../Actions/Trailer'
import { addvehicle, removedvehicle } from './../../Actions/Vehicles'
import { garageUsersWiseSelectedList, updategarageUsersWiseSelectedList, removedGarages, getGarages } from './../../Actions/Garages'
/* ACTIONS END */

// common sections
import Notification from './Components/notification'
import CommonTableSection from './Components/commonTableSection'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import SavedCommands from './Components/savedCommands'
import MaintenanceForm from './Components/maintenance'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { GroupsModal } from '../../Components/Groups/groupsModal'
import Loader from '../../Layout/Loader'
import instance from '../../axios'
import { areaUsersWiseSelectedList, updateAreaUsersWiseSelectedList } from '../../Actions/Areas'
import { serviceUsersWiseSelectedList, updateServiceUsersWiseSelectedList } from '../../Actions/Services'


const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ color: 'inherit', ...other.style }}
    >
      {children}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

// --> parking form

const unitParkingAttFormat = {
  'parking.minIdleTime': 0,
  'parking.maxIdleSpeed': 0
}

const NotificationDataFormat = {
  always: '',
  attributes: {},
  calendarId: '',
  id: '',
  notificators: '',
  type: ''
}

const ComputedAttributesDataFormat = {
  description: '',
  attribute: '',
  expression: '',
  type: ''
}
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: '',
  id: '',
  description: '',
  type: 'custom',
  textChannel: ''
}
const maintenanceDataFormat = {
  attributes: {},
  name: '',
  type: '',
  start: '',
  period: ''
}

const locations = [
  '#user',
  '#device',
  '#group',
  '#driver',
  '#trailer',
  '#vehicle',
  '#garage',
  '#geofence',
  '#notification',
  '#attribute',
  '#command',
  '#maintenance'
]

const MainUnitModal = (props) => {

  const [state, setState] = useState({
    viewScreen: null,
    currentTab: 'user',
    currentId: 0,
    selecteditem: null,
    activeOperation: '',
    unitParkingAttributesform: { ...unitParkingAttFormat },
    notificationForm: { ...NotificationDataFormat },
    computedAttForm: { ...ComputedAttributesDataFormat },
    savedCommandsForm: { ...savedCommandsDataFormat },
    maintenanceForm: { ...maintenanceDataFormat },

    visableAttribute: false,
    visableNotification: false,
    visableSavedCommands: false,
    visableMaintenance: false,
    visableGeofence: false,

    visableListNotification: true,
    visableListGeofence: true,
    visableListAttribute: true,
    visableListSavedCommands: true,
    visableListMaintenance: true,

    notificationCallStatus: '',
    attributeCallStatus: '',
    commandsCallStatus: '',
    maintenanceCallStatus: '',
    callAddComputedAttributes: '',
    paginationvehicle: { ...PaginationConfig, pagesize: 5 },
    isRecived: false,
    vehicleList: [],
    paginationdevice: { ...PaginationConfig, pagesize: 5 },
    deviceList: [],
    onDeleteConfirmationGarage: false,
    rowsPerPage: 5
  })

const [isLoading,setIsLoading]=useState(false)


  useEffect(() => {
    if (props.id) {
      setState((prevState) => ({ ...prevState, viewScreen: 'edit' }))
      if (
        props.id &&
        parseInt(state.currentId) !== parseInt(props.id) &&
        props.groups.length
      ) {
        setState((prevState) => ({
          ...prevState,
          currentId: parseInt(props.id)

        }))



        // getCurrentGroup(props.id)

        props.groups.map(group => {
          if (parseInt(group.id) === parseInt(state.currentId)) {
            setState((prevState) => ({ ...prevState, selecteditem: group }))
          }
          return null
        })
      }
    }
    if (props.hash) {
      let hash = locations.indexOf(props.hash)
      if (hash !== -1) {
        setState((prevState) => ({ ...prevState, currentTab: props.hash.substring(1, -1) }))
      }
    }
  }, [])

  useEffect(() => {
    if (props.id) {
      setState((prevState) => ({ ...prevState, viewScreen: 'edit' }))
      if (props.hash) {
        let hash = locations.indexOf(props.hash)
        if (hash !== -1) {
          setState((prevState) => ({ ...prevState, currentTab: props.hash.substring(1, -1) }))
        }
      }
      if (
        props.id && props.groups.length
      ) {
        const currentId = parseInt(props.id)
        if (currentId !== state.currentId) {
          setState((prevState) => ({
            ...prevState,
            currentId

          }))
          getCurrentGroup(currentId)
          const selecteditem = props.groups.find(group => parseInt(group.id) === currentId)
          if (selecteditem) {
            setState((prevState) => ({ ...prevState, selecteditem }))
          }
        }
      }
    }
    if (props.addUserScreen) {
      setState((prevState) => ({ ...prevState, viewScreen: 'add' }))
    }

  }, [props.id, props.hash, state.currentId, props.addUserScreen])

  useEffect(() => {
    if (state.savedCommandsForm) {
      checkRequiredFields('Commands')
    }
  }, [state.savedCommandsForm])

  useEffect(() => {
    if (state.computedAttForm) {
      checkRequiredFields('computedAtt')
    }
  }, [state.computedAttForm])
  useEffect(() => {
    if (state.notificationForm) {
      checkRequiredFields('notification')
    }
    else if (state.maintenanceForm) {
      checkRequiredFields('maintenance')
    }

  }, [
    state.notificationForm,
    state.maintenanceForm,
  ]);



  useEffect(() => {
    if (state.notificationCallStatus === "PUT" || state.notificationCallStatus === "DELETE") {
      addNotifications()
    }
  }, [state.notificationCallStatus])

  useEffect(() => {
    if (state.geofenceCallStatus === "PUT" || state.geofenceCallStatus === "DELETE") {
      addGeofences()
    }
  }, [state.geofenceCallStatus])

  useEffect(() => {
    if (state.driverCallStatus === "PUT" || state.driverCallStatus === "DELETE") {
      addDrivers()
    }
  }, [state.driverCallStatus])

  useEffect(() => {
    if (state.garageCallStatus === "PUT" || state.garageCallStatus === "DELETE") {
      deleteGarages()
    }
  }, [state.garageCallStatus])

  useEffect(() => {
    if (state.vehicleCallStatus === "PUT" || state.vehicleCallStatus === "DELETE") {
      addVehicles()
    }
  }, [state.vehicleCallStatus])

  useEffect(() => {
    if (state.userCallStatus === "PUT" || state.userCallStatus === "DELETE") {
      addUsers()
    }
  }, [state.userCallStatus])

  useEffect(() => {
    if (state.attributeCallStatus === "DELETE") {
      addComputedAttributes()
    }
  }, [state.attributeCallStatus])

  useEffect(() => {
    if (state.maintenanceCallStatus === "PUT" || state.maintenanceCallStatus === "DELETE") {
      addMaintenances()
    }
  }, [state.maintenanceCallStatus])

  useEffect(() => {
    if (state.commandsCallStatus === "DELETE") {
      addSavedCommands()
    }
  }, [state.commandsCallStatus])

  useEffect(() => {
    if (state.trailerCallStatus === "PUT" || state.trailerCallStatus === "DELETE") {
      addTrailers()
    }
  }, [state.trailerCallStatus])


  const tabChange = (event, currentTab) => {
    setState(prevState => ({ ...prevState, currentTab }));
  };

  // const onCloseModal = () => {
  //   setState(prevState => ({ ...prevState, activeOperation: '' }));
  // };

  // const addNewGroups = () => {
  //   setState(prevState => ({ ...prevState, isaddGroupVisable: !prevState.isaddGroupVisable }));
  // };

  // const logo_handelChanges = (item) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     aOneform: {
  //       category: item.name
  //     }
  //   }))
  //     isButtonVisable('aFormOne');
  // };

  const onListNotification = () => {
    setState(prevState => ({ ...prevState, visableListNotification: true }));
  };

  const onListComputedAttributes = () => {
    setState(prevState => ({ ...prevState, visableListAttribute: true }));
  };

  const onListSavedCommands = () => {
    setState(prevState => ({ ...prevState, visableListSavedCommands: true }));
  };

  const onListMaintenance = () => {
    setState(prevState => ({ ...prevState, visableListMaintenance: true }));
  };

  const onCreateNewNotification = () => {
    props.history.push('/notificationCreate');
    setState(prevState => ({
      ...prevState,
      isAddVisableBtn: false,
      notificationCallStatus: 'POST',
      visableNotification: true,
      notificationForm: { ...NotificationDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'notificationCreate'
    }));
  };

  const onCreateNewAttributes = () => {
    setState(prevState => ({
      ...prevState,
      isAddVisableBtn: false,
      attributeCallStatus: 'POST',
      visableAttribute: true,
      computedAttForm: { ...ComputedAttributesDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'attributeCreate'
    }));
  };

  const onCreateNewSavedCommands = () => {
    setState(prevState => ({
      ...prevState,
      isAddVisableBtn: false,
      commandsCallStatus: 'POST',
      visableSavedCommands: true,
      savedCommandsForm: { ...savedCommandsDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'commandCreate'
    }));
  };

  const onCreateNewMaintenance = () => {
    props.history.push('/maintenanceCreate');
  };

  const notification_Selection = (checked, item) => {
    let method = checked ? 'POST' : 'DELETE';
    let allData = [...props.selectednotification];
    let obj = {
      groupId: props.id,
      notificationId: item.id
    };

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: { ...obj }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
          });
          props.dispatch(updateNotificationDeviceWiseSelectedList(item));
          props.dispatch(RemoveNotificationDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('notificationUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
          });
          props.dispatch(updateNotificationDeviceWiseSelectedList(item));
          props.dispatch(addNotificationDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('notificationAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => {
        errorHandler(error, props.dispatch)
      });
  };

  const geofence_Selection = (checked, item) => {
    let method = checked ? 'POST' : 'DELETE';
    let allData = [...props.selectedgeofence];
    let obj = {
      groupId: props.id,
      geofenceId: item.id
    };

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: { ...obj }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
          });
          props.dispatch(updateGeofenceDeviceWiseSelectedList(item));
          props.dispatch(RemoveGeofenceDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('geofenceUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
          });
          props.dispatch(updateGeofenceDeviceWiseSelectedList(item));
          props.dispatch(addGeofenceDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('geofenceAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => errorHandler(error, props.dispatch));
  };

  const unit_Selection = (checked, item) => {
    let method = checked ? 'POST' : 'DELETE';
    let allData = [...props.selecteddevices];
    let obj = {
      groupId: props.id,
      deviceId: item.id
    };

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: { ...obj }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
          });
          props.dispatch(updateDeviceUserWiseSelectedList(item));
          props.dispatch(RemoveDeviceUserWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('trackerIsUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
          });
          props.dispatch(updateDeviceUserWiseSelectedList(item));
          props.dispatch(addDeviceUserWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('trackerIsAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => errorHandler(error, props.dispatch));
  };

  const driver_Selection = (checked, item) => {
    let method = checked ? 'POST' : 'DELETE';
    let allData = [...props.selecteddriver];
    let obj = {
      groupId: props.id,
      driverId: item.id
    };

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: { ...obj }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
          });
          props.dispatch(updateDriverDeviceWiseSelectedList(item));
          props.dispatch(RemoveDriverDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('driverUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
          });
          props.dispatch(updateDriverDeviceWiseSelectedList(item));
          props.dispatch(addDriverDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('driverAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => errorHandler(error, props.dispatch));
  };

  const trailer_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...props.selectedtrailer];
    let obj = {
      groupId: props.id,
      binderId: item.id,
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateTrailerDeviceWiseSelectedList(item));
          props.dispatch(RemoveTrailerDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('trailerUnAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateTrailerDeviceWiseSelectedList(item));
          props.dispatch(addTrailerDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('trailerAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        }
      })
      .catch((error) => {
        errorHandler(error, props.dispatch)
        console.warn(error)
      });
  };

  const vehicle_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...props.selectedvehicle];
    let obj = {
      groupId: props.id,
      vehicleId: item.id,
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateVehicleDeviceWiseSelectedList(item));
          props.dispatch(RemoveVehicleDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('vehicleUnAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateVehicleDeviceWiseSelectedList(item));
          props.dispatch(addVehicleDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('vehicleAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        }
      })
      .catch((error) => errorHandler(error, props.dispatch));
  };

  const garage_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = props.selectedGarages || [];
    let obj = {
      groupId: props.id,
      garageId: item.id,
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updategarageUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('garageUnAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updategarageUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('garageAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        }
      })
      .catch((error) => errorHandler(error, props.dispatch));
  };

  const area_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = props.selectedAreas || [];
    let obj = {
      groupId: props.id,
      areaId: item.id,
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateAreaUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('areaUnAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateAreaUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('areaAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        }
      })
      .catch((error) => errorHandler(error, props.dispatch));
  };

  const service_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = props.selectedServices || [];
    let obj = {
      groupId: props.id,
      expenseTypeId: item.id,
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateServiceUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('serviceUnAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateServiceUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('serviceAssignedSuccessfully'),
              autoDismiss: 10,
            })
          );
        }
      })
      .catch((error) => errorHandler(error, props.dispatch));
  };

  const computedAttributes_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = props.computedAttributes;
    let obj = {
      groupId: props.id,
      attributeId: item.id,
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateComputedAttributeDeviceWiseSelectedList(item));
          props.dispatch(RemoveComputedAttributeDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'computedAttributeUnAssignedSuccessfully'
              ),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateComputedAttributeDeviceWiseSelectedList(item));
          props.dispatch(addComputedAttributeDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'computedAttributeAssignedSuccessfully'
              ),
              autoDismiss: 10,
            })
          );
        }
      })
      .catch((error) => {
        errorHandler(error, props.dispatch, errorCallBack);
      });
  };

  const savedCommands_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = props.commands;
    let obj = {
      groupId: props.id,
      commandId: item.id,
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateCommandsDeviceWiseSelectedList(item));
          props.dispatch(RemoveCommandsDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'savedCommandsUnAssignedSuccessfully'
              ),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateCommandsDeviceWiseSelectedList(item));
          props.dispatch(addCommandsDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'savedCommandsAssignedSuccessfully'
              ),
              autoDismiss: 10,
            })
          );
        }
      })
      .catch((error) => {
        errorHandler(error, props.dispatch);
      });
  };

  const maintenance_Selection = (checked, item) => {
    console.warn('checked', checked, item)
    let method = 'DELETE';
    let value = checked;
    let allData = props.maintenance;
    let obj = {
      groupId: props.id,
      maintenanceId: item.id,
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          console.warn('item', item)
          props.dispatch(updateMaintenanceDeviceWiseSelectedList(item));
          props.dispatch(RemoveMaintenanceDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'maintenanceUnAssignedSuccessfully'
              ),
              autoDismiss: 10,
            })
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }

            return null;
          });
          console.warn('Item', item)
          props.dispatch(updateMaintenanceDeviceWiseSelectedList(item));
          props.dispatch(addMaintenanceDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'maintenanceAssignedSuccessfully'
              ),
              autoDismiss: 10,
            })
          );
        }
      })
      .catch((error) => {
        errorHandler(error, props.dispatch);
        console.warn('Mian Error', error)
      });
  };

  const errorCallBack = () => {
    setState(prevState => ({
      ...prevState,
      visableListAttribute: false
    }));
  };

  // 4th
  const notificationHandle = (name, value) => {
    setState(prevState => ({
      ...prevState,
      notificationForm: {
        ...prevState.notificationForm,
        [name]: value.id
      },
      shouldUpdate: true, // Trigger effect to check required fields
      updatedFormType: 'notification'
    }));


  };

  const attributeHandle = (name, value) => {
    const selectedValue = postionAttributesTypeComp
      .filter(m => m.key === value)
      .map(m => m.valueType);

    setState(prevState => ({
      ...prevState,
      computedAttForm: {
        ...prevState.computedAttForm,
        [name]: value,
        type: selectedValue[0] || 'string'
      },
      shouldUpdate: true, // Trigger effect to check required fields
      updatedFormType: 'computedAtt'
    }));

  };

  const savedCommandsHandle = (name, value) => {
    setState(prevState => ({
      ...prevState,
      savedCommandsForm: {
        ...prevState.savedCommandsForm,
        type: value.id
      },
    }));

  };

  const maintenancesHandle = (name, value) => {
    setState(prevState => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        [name]: value.id
      },
      shouldUpdate: true, // Trigger effect to check required fields
      updatedFormType: 'maintenance'
    }));
  };

  const saveToParent = (obj) => {
    setState(prevState => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        attributes: {
          ...prevState.maintenanceForm.attributes,
          ...obj
        }
      },
      shouldUpdate: true, // Trigger effect to check required fields
      updatedFormType: 'maintenance'
    }));
  };

  const addNotifications = () => {
    let obj = { ...state.notificationForm };
    if (
      (state.notificationCallStatus === 'PUT' ||
        state.notificationCallStatus === 'POST') &&
      obj.notificators &&
      obj.notificators.length
    ) {
      let notificatorsArr = obj.notificators.map(v => v.value);
      obj.notificators = notificatorsArr.join(',');
    }

    let params = '';
    if (state.notificationCallStatus === 'PUT' || state.notificationCallStatus === 'DELETE') {
      params = obj.id;
    }

    if (obj) {
      let check;
      if (obj.check !== undefined) {
        check = obj.check;
        delete obj.check;
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms };
        delete obj.alarms;
      }

      instance({
        url: `/api/notifications/${params}`,
        method: state.notificationCallStatus,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      })
        .then(notifications => {
          if (state.notificationCallStatus === 'DELETE') {
            props.dispatch(removeNotificationDeviceWiseSelectedList(obj));
            props.dispatch(removedNotification(obj));
            props.dispatch(
              Notifications.success({
                message: props.translate('notificationIsDeleted'),
                autoDismiss: 10
              })
            );
            setState(prevState => ({
              ...prevState,
              onDeleteConfirmationNotification: false,
              attributeChangesMessage: ''
            }));
          } else {
            setState(prevState => ({
              ...prevState,
              visableNotification: false
            }));

            if (state.notificationCallStatus === 'PUT') {
              let noti = { ...notifications, check };
              props.dispatch(updateNotificationDeviceWiseSelectedList(noti));
              props.dispatch(addNotification(noti));
              props.dispatch(
                Notifications.success({
                  message: props.translate('notificationIsUpdated'),
                  autoDismiss: 10
                })
              );
              setState(prevState => ({
                ...prevState,
                attributeChangesMessage: ''
              }));
            } else {
              notification_Selection(true, notifications);
              let noti = { ...notifications, check: true };
              props.dispatch(addNotificationDeviceWiseSelectedList(noti));
              props.dispatch(addNotification(noti));
              props.dispatch(
                Notifications.success({
                  message: props.translate('notificationIsCreated'),
                  autoDismiss: 10
                })
              );
              setState(prevState => ({
                ...prevState,
                attributeChangesMessage: ''
              }));
            }
          }
        })
        .catch(error => errorHandler(error, props.dispatch));
    }
  };


  const addGeofences = () => {
    let obj = state.geofenceForm;
    let params = '';
    if (
      state.geofenceCallStatus === 'PUT' ||
      state.geofenceCallStatus === 'DELETE'
    ) {
      params = obj.id;
    }

    if (obj) {
      let check;
      if (obj.check !== undefined) {
        check = obj.check;
        delete obj.check;
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms };
        delete obj.alarms;
      }

      instance({
        url: `/api/geofences/${params}`,
        method: state.geofenceCallStatus,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      })
        .then(geofences => {
          if (state.geofenceCallStatus === 'DELETE') {
            props.dispatch(removeGeofenceDeviceWiseSelectedList(obj));
            props.dispatch(deleteGeofence(obj));
            props.dispatch(
              Notifications.success({
                message: props.translate('geofenceDeleted'),
                autoDismiss: 10
              })
            );
            setState(prevState => ({
              ...prevState,
              onDeleteConfirmationGeofence: false,
              attributeChangesMessage: ''
            }));
          } else {
            setState(prevState => ({
              ...prevState,
              visableGeofence: false
            }));

            if (state.geofenceCallStatus === 'PUT') {
              let noti = { ...geofences, check };
              props.dispatch(updateGeofenceDeviceWiseSelectedList(noti));
              props.dispatch(addGeoFence(noti));
              props.dispatch(
                Notifications.success({
                  message: props.translate('geofenceUpdated'),
                  autoDismiss: 10
                })
              );
              setState(prevState => ({
                ...prevState,
                attributeChangesMessage: ''
              }));
            } else {
              geofence_Selection(true, geofences);
              let noti = { ...geofences, check: true };
              props.dispatch(addGeofenceDeviceWiseSelectedList(noti));
              props.dispatch(addGeoFence(noti));
              props.dispatch(
                Notifications.success({
                  message: props.translate('geofenceCreated'),
                  autoDismiss: 10
                })
              );
              setState(prevState => ({
                ...prevState,
                attributeChangesMessage: ''
              }));
            }
          }
        })
        .catch(error => errorHandler(error, props.dispatch));
    }
  };

  // const addDevicesFunc = () => {
  //   let obj = state.deviceForm;
  //   let params = '';
  //   if (
  //     state.deviceCallStatus === 'PUT' ||
  //     state.deviceCallStatus === 'DELETE'
  //   ) {
  //     params = obj.id;
  //   }

  //   if (obj) {
  //     let check;
  //     if (obj.check !== undefined) {
  //       check = obj.check;
  //       delete obj.check;
  //     }
  //     if (obj.alarms) {
  //       obj.attributes = { ...obj.attributes, alarms: obj.alarms };
  //       delete obj.alarms;
  //     }

  //     instance({
  //       url: `/api/devices/${params}`,
  //       method: state.deviceCallStatus,
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(obj)
  //     })
  //     .then(devices => {
  //       if (state.deviceCallStatus === 'DELETE') {
  //         props.dispatch(removeDeviceUserWiseSelectedList(obj));
  //         props.dispatch(removeDevice(obj));
  //         props.dispatch(
  //           Notifications.success({
  //             message: props.translate('deviceIsDeleted'),
  //             autoDismiss: 10
  //           })
  //         );
  //         setState(prevState => ({
  //           ...prevState,
  //           onDeleteConfirmationDevice: false,
  //           attributeChangesMessage: ''
  //         }));
  //       } else {
  //         setState(prevState => ({
  //           ...prevState,
  //           visableDevice: false
  //         }));

  //         if (state.deviceCallStatus === 'PUT') {
  //           let noti = { ...devices, check };
  //           props.dispatch(updateDeviceUserWiseSelectedList(noti));
  //           props.dispatch(addDevices(noti));
  //           props.dispatch(
  //             Notifications.success({
  //               message: props.translate('deviceIsUpdated'),
  //               autoDismiss: 10
  //             })
  //           );
  //           setState(prevState => ({
  //             ...prevState,
  //             attributeChangesMessage: ''
  //           }));
  //         } else {
  //           device_Selection(true, devices);
  //           let noti = { ...devices, check: true };
  //           props.dispatch(addDeviceUserWiseSelectedList(noti));
  //           props.dispatch(addDevices(noti));
  //           props.dispatch(
  //             Notifications.success({
  //               message: props.translate('deviceIsCreated'),
  //               autoDismiss: 10
  //             })
  //           );
  //           setState(prevState => ({
  //             ...prevState,
  //             attributeChangesMessage: ''
  //           }));
  //         }
  //       }
  //     })
  //     .catch(error => errorHandler(error, props.dispatch));
  //   }
  // };

  const addDrivers = () => {
    let obj = state.driverForm;
    let params = '';
    if (
      state.driverCallStatus === 'PUT' ||
      state.driverCallStatus === 'DELETE'
    ) {
      params = obj.id;
    }

    if (obj) {
      let check;
      if (obj.check !== undefined) {
        check = obj.check;
        delete obj.check;
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms };
        delete obj.alarms;
      }

      instance({
        url: `/api/drivers/${params}`,
        method: state.driverCallStatus,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      })
        .then(drivers => {
          if (state.driverCallStatus === 'DELETE') {
            props.dispatch(removeDriverDeviceWiseSelectedList(obj));
            props.dispatch(removeDriver(obj));
            props.dispatch(
              Notifications.success({
                message: props.translate('driverIsDeleted'),
                autoDismiss: 10
              })
            );
            setState(prevState => ({
              ...prevState,
              onDeleteConfirmationDriver: false,
              attributeChangesMessage: ''
            }));
          } else {
            setState(prevState => ({
              ...prevState,
              visableDriver: false
            }));

            if (state.driverCallStatus === 'PUT') {
              let noti = { ...drivers, check };
              props.dispatch(updateDriverDeviceWiseSelectedList(noti));
              props.dispatch(addDriver(noti));
              props.dispatch(
                Notifications.success({
                  message: props.translate('driverIsUpdated'),
                  autoDismiss: 10
                })
              );
              setState(prevState => ({
                ...prevState,
                attributeChangesMessage: ''
              }));
            } else {
              driver_Selection(true, drivers);
              let noti = { ...drivers, check: true };
              props.dispatch(addDriverDeviceWiseSelectedList(noti));
              props.dispatch(addDriver(noti));
              props.dispatch(
                Notifications.success({
                  message: props.translate('driverIsCreated'),
                  autoDismiss: 10
                })
              );
              setState(prevState => ({
                ...prevState,
                attributeChangesMessage: ''
              }));
            }
          }
        })
        .catch(error => errorHandler(error, props.dispatch));
    }
  };

  const addTrailers = () => {
    let obj = state.trailerForm;
    let params = '';
    if (state.trailerCallStatus === 'PUT' || state.trailerCallStatus === 'DELETE') {
      params = obj.id;
    }
    if (obj) {
      let check;
      if (obj.check !== undefined) {
        check = obj.check;
        delete obj.check;
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms };
        delete obj.alarms;
      }

      instance({
        url: `/api/binders/${params}`,
        method: state.trailerCallStatus,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...obj })
      })
        .then(trailers => {
          if (state.trailerCallStatus === 'DELETE') {
            props.dispatch(removeTrailerDeviceWiseSelectedList(obj));
            props.dispatch(removeTrailer(obj));
            props.dispatch(Notifications.success({
              message: props.translate('trailerIsDeleted'),
              autoDismiss: 10
            }));
            setState(prevState => ({
              ...prevState,
              onDeleteConfirmationTrailer: false,
              attributeChangesMessage: ''
            }));
          } else {
            setState(prevState => ({ ...prevState, visableTrailer: false }));

            if (state.trailerCallStatus === 'PUT') {
              let noti = { ...trailers, check };
              props.dispatch(updateTrailerDeviceWiseSelectedList(noti));
              props.dispatch(addTrailer(noti));
              props.dispatch(Notifications.success({
                message: props.translate('trailerIsUpdated'),
                autoDismiss: 10
              }));
              setState(prevState => ({ ...prevState, attributeChangesMessage: '' }));
            } else {
              trailer_Selection(true, trailers);
              let noti = { ...trailers, check: true };
              props.dispatch(addTrailerDeviceWiseSelectedList(noti));
              props.dispatch(addTrailer(noti));
              props.dispatch(Notifications.success({
                message: props.translate('trailerIsCreated'),
                autoDismiss: 10
              }));
              setState(prevState => ({ ...prevState, attributeChangesMessage: '' }));
            }
          }
        })
        .catch(error => errorHandler(error, props.dispatch));
    }
  };

  const addVehicles = () => {
    let obj = state.vehicleForm;
    let params = '';
    if (state.vehicleCallStatus === 'PUT' || state.vehicleCallStatus === 'DELETE') {
      params = obj.id;
    }
    if (obj) {
      let check;
      if (obj.check !== undefined) {
        check = obj.check;
        delete obj.check;
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms };
        delete obj.alarms;
      }

      instance({
        url: `/api/vehicles/${params}`,
        method: state.vehicleCallStatus,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...obj })
      })
        .then(vehicles => {
          if (state.vehicleCallStatus === 'DELETE') {
            props.dispatch(removeVehicleDeviceWiseSelectedList(obj));
            props.dispatch(removedvehicle(obj));
            props.dispatch(Notifications.success({
              message: props.translate('vehicleIsDeleted'),
              autoDismiss: 10
            }));
            setState(prevState => ({
              ...prevState,
              onDeleteConfirmationVehicle: false,
              attributeChangesMessage: ''
            }));
          } else {
            setState(prevState => ({ ...prevState, visableVehicle: false }));

            if (state.vehicleCallStatus === 'PUT') {
              let noti = { ...vehicles, check };
              props.dispatch(updateVehicleDeviceWiseSelectedList(noti));
              props.dispatch(addvehicle(noti));
              props.dispatch(Notifications.success({
                message: props.translate('vehicleIsUpdated'),
                autoDismiss: 10
              }));
              setState(prevState => ({ ...prevState, attributeChangesMessage: '' }));
            } else {
              vehicle_Selection(true, vehicles);
              let noti = { ...vehicles, check: true };
              props.dispatch(addVehicleDeviceWiseSelectedList(noti));
              props.dispatch(addVehicle(noti));
              props.dispatch(Notifications.success({
                message: props.translate('vehicleIsCreated'),
                autoDismiss: 10
              }));
              setState(prevState => ({ ...prevState, attributeChangesMessage: '' }));
            }
          }
        })
        .catch(error => {
          console.warn("Vehicle", error)
          errorHandler(error, props.dispatch)
        });
    }
  };

  const addComputedAttributes = () => {
    let obj = state.computedAttForm;
    let params = '';
    if (state.attributeCallStatus === 'PUT' || state.attributeCallStatus === 'DELETE') {
      params = obj.id;
      delete obj.deviceId;
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }
      if(obj.copyFromLast){
        delete obj.copyFromLast
      }
      setIsLoading(true)
      instance({
        url: `/api/attributes/computed/${params}`,
        method: `${state.attributeCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ ...obj })
      })
        .then(Attribute => {
          if (state.attributeCallStatus === 'DELETE') {
            props.dispatch(removedComputedAttributeDeviceWiseSelectedList(obj));
            props.dispatch(removedComputedAttribute(obj));
            props.dispatch(Notifications.success({
              message: props.translate('computedAttributeIsDeleted'),
              autoDismiss: 10
            }));
            setState(prevState => ({ ...prevState, onDeleteConfirmationAttributes: false }));
          } else {
            setState(prevState => ({ ...prevState, visableAttribute: false }));

            if (state.attributeCallStatus === 'PUT') {
              props.dispatch(updateComputedAttributeDeviceWiseSelectedList(Attribute));
              props.dispatch(addComputedAttribute(Attribute));
              props.dispatch(Notifications.success({
                message: props.translate('computedAttributeIsUpdated'),
                autoDismiss: 10
              }));
            } else {
              computedAttributes_Selection(true, Attribute);
              props.dispatch(addComputedAttributeDeviceWiseSelectedList(Attribute));
              props.dispatch(addComputedAttribute(Attribute));
              props.dispatch(Notifications.success({
                message: props.translate('computedAttributeIsCreated'),
                autoDismiss: 10
              }));
              setState(prevState => ({ ...prevState, visableListAttribute: false }));
            }
          }
      setIsLoading(false)

        })
        .catch(error => errorHandler(error, props.dispatch));
    }
  };

  const addSavedCommands = () => {
    let {
      attributes,
      id,
      description,
      type,
      textChannel
    } = state.savedCommandsForm;
    textChannel = textChannel === 'Yes' ? true : false;
    attributes = attributes || {};
    id = id || -1;
    let obj = { attributes, id, description, type, textChannel };
    obj.deviceId = 0;
    let params = '';
    if (state.commandsCallStatus === 'PUT' || state.commandsCallStatus === 'DELETE') {
      params = obj.id;
    }

    if (obj) {
      setIsLoading(true)
      instance({
        url: `/api/commands/${params}`,
        method: state.commandsCallStatus,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ ...obj })
      })
        .then(commands => {
          if (state.commandsCallStatus === 'DELETE') {
            props.dispatch(removedCommandsDeviceWiseSelectedList(obj));
            props.dispatch(removedCommands(obj));
            props.dispatch(Notifications.success({
              message: props.translate('savedCommandsIsDeleted'),
              autoDismiss: 10
            }));
            setState(prevState => ({ ...prevState, onDeleteConfirmationCommand: false }));
          } else {
            setState(prevState => ({ ...prevState, visableSavedCommands: false }));

            if (state.commandsCallStatus === 'PUT') {
              props.dispatch(updateCommandsDeviceWiseSelectedList(commands));
              props.dispatch(addCommands(commands));
              props.dispatch(Notifications.success({
                message: props.translate('savedCommandsIsUpdated'),
                autoDismiss: 10
              }));
            } else {
              savedCommands_Selection(true, commands);
              props.dispatch(addCommandsDeviceWiseSelectedList(commands));
              props.dispatch(addCommands(commands));
              props.dispatch(Notifications.success({
                message: props.translate('savedCommandsIsCreated'),
                autoDismiss: 10
              }));
              setState(prevState => ({ ...prevState, visableListSavedCommands: false }));
            }
          }
      setIsLoading(false)

        })
        .catch(error => {
      setIsLoading(false)
        
          errorHandler(error, props.dispatch)});
    }
  };

  const addMaintenances = () => {
    let obj = state.maintenanceForm;

    if (state.maintenanceForm.type === 'totalDistance') {
      obj.start = parseFloat(state.maintenanceForm.start * 1000);
      obj.period = parseFloat(state.maintenanceForm.period * 1000);
    } else if (state.maintenanceForm.type === 'hours') {
      obj.start = parseFloat(state.maintenanceForm.start * (1000 * 3600));
    } else if (state.maintenanceForm.type === 'date') {
      obj.start = 1;
    }

    let params = '';
    if (state.maintenanceCallStatus === 'PUT' || state.maintenanceCallStatus === 'DELETE') {
      params = obj.id;
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }

      instance({
        url: `/api/maintenance/${params}`,
        method: state.maintenanceCallStatus,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...obj })
      })
        .then(Maintenance => {
          if (state.maintenanceCallStatus === 'DELETE') {
            props.dispatch(removedMaintenanceDeviceWiseSelectedList(obj));
            props.dispatch(removedMaintenance(obj));
            props.dispatch(Notifications.success({
              message: props.translate('maintenanceIsDeleted'),
              autoDismiss: 10
            }));
            setState(prevState => ({
              ...prevState,
              onDeleteConfirmationMaintenance: false,
              attributeChangesMessage: ''
            }));
          } else {
            if (state.maintenanceCallStatus === 'PUT') {
              props.dispatch(updateMaintenanceDeviceWiseSelectedList(Maintenance));
              props.dispatch(addMaintenance(Maintenance));
              props.dispatch(Notifications.success({
                message: props.translate('maintenanceIsUpdated'),
                autoDismiss: 10
              }));
              setState(prevState => ({ ...prevState, attributeChangesMessage: '' }));
            } else {
              maintenance_Selection(true, Maintenance);
              props.dispatch(addMaintenanceDeviceWiseSelectedList(Maintenance));
              props.dispatch(addMaintenance(Maintenance));
              props.dispatch(Notifications.success({
                message: props.translate('MaintenanceIsCreated'),
                autoDismiss: 10
              }));
              setState(prevState => ({
                ...prevState,
                visableListMaintenance: false,
                attributeChangesMessage: ''
              }));
            }
            setState(prevState => ({
              ...prevState,
              visableMaintenance: false,
              attributeChangesMessage: ''
            }));
          }
        })
        .catch(error => errorHandler(error, props.dispatch));
    }
  };

  const addUsers = () => {
    let obj = state.userForm;
    let params = '';
    if (state.userCallStatus === 'PUT' || state.userCallStatus === 'DELETE') {
      params = obj.id;
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }

      instance({
        url: `/api/users/${params}`,
        method: state.userCallStatus,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...obj
        }
      })
        .then((User) => {
          if (state.userCallStatus === 'DELETE') {
            props.dispatch(removeUserUsereWise(obj));
            props.dispatch(
              Notifications.success({
                message: props.translate('userIsRemoved'),
                autoDismiss: 10
              })
            );
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationUser: false,
              attributeChangesMessage: ''
            }));
          } else {
            if (state.maintenanceCallStatus === 'PUT') {
              props.dispatch(updateUserDeviceWiseSelectedList(User));
              props.dispatch(
                Notifications.success({
                  message: props.translate('UserIsUpdated'),
                  autoDismiss: 10
                })
              );
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }));
            } else {
              maintenance_Selection(true, User);
              props.dispatch(addUserDeviceWiseSelectedList(User));
              props.dispatch(
                Notifications.success({
                  message: props.translate('UserIsCreated'),
                  autoDismiss: 10
                })
              );
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: '',
                visableListUser: false
              }));
            }

            setState((prevState) => ({
              ...prevState,
              visableUser: false,
              attributeChangesMessage: ''
            }));
          }
        })
        .catch((error) => {
          errorHandler(error, props.dispatch);
        });
    }
  };
  // 6th
  const editNotification = (item) => {
    props.history.push(`/notificationUpdate/${item[0].id}`);
    let a = item && item[0] && item[0].notificators ? item[0].notificators.split(',') : [];
    let notificators = a.length ? a.map(e => ({ label: props.translate(e), value: e })) : [];
    setState(prevState => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: item[0],
      notificationCallStatus: 'PUT',
      visableNotification: true,
      notificationForm: { ...item[0], notificators },
      addButtonStatus: 'sharedUpdate',
      defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
      attributeChangesMessage: '',
      itemAttributes: item[0].attributes,
      isHaveAccess: 'notificationUpdate'
    }));
  };

  const editComputedAttributes = (item) => {
    setState(prevState => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: item[0],
      attributeCallStatus: 'PUT',
      visableAttribute: true,
      computedAttForm: {
        ...item[0],
        expression: ReactHtmlParser(item[0].expression)[0]
      },
      addButtonStatus: 'sharedUpdate',
      isHaveAccess: 'attributeUpdate'
    }));
  };

  const editCommands = (item) => {
    setState(prevState => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: item[0],
      commandsCallStatus: 'PUT',
      visableSavedCommands: true,
      savedCommandsForm: { ...item[0] },
      addButtonStatus: 'sharedUpdate',
      isHaveAccess: 'commandUpdate'
    }));
  };

  const editMaintenance = (item) => {
    props.history.push(`/maintenanceUpdate/${item[0].id}`);
  };

  const addMaintenanceAttributes = () => {
    setState(prevState => ({
      ...prevState,
      isAddAttributes: true,
      componentType: 'Maintenance',
      itemAttributes: prevState.maintenanceForm.attributes
    }));
  };

  const notification_handleChange2 = (value, option) => {
    if (option && option.name === 'calendarId') {
      setState(prevState => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [option.name]: value.value
        }
      }));
    } else if (typeof option === 'object' && Object.keys(option).length) {
      setState(prevState => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [option.name]: value
        }
      }));
    } else if (value === 'type') {
      setState(prevState => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [value]: option
        }
      }));

    } else {
      setState(prevState => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          attributes: {
            ...prevState.notificationForm.attributes,
            [value]: option
          }
        }
      }));

    }
  };

  // direct arrow function
  const notification_handleChange = name => event => {
    const { target } = event;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    setState(prevState => ({
      ...prevState,
      notificationForm: {
        ...prevState.notificationForm,
        [name]: value
      }
    }));

  };
  // const geofence_handleChange = (name) => (event) => {
  //   const { target } = event;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;

  //   setState((prevState) => ({
  //     ...prevState,
  //     geofenceForm: {
  //       ...prevState.geofenceForm,
  //       [name]: value,
  //     },
  //   }));
  //   checkRequiredFields('geofence')
  // };

  // const driver_handleChange = (name) => (event) => {
  //   const { target } = event;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;

  //   setState((prevState) => ({
  //     ...prevState,
  //     driverForm: {
  //       ...prevState.driverForm,
  //       [name]: value,
  //     },
  //   }));
  //   checkRequiredFields('driver')
  // };

  // const trailer_handleChange = (name) => (event) => {
  //   const { target } = event;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;

  //   setState((prevState) => ({
  //     ...prevState,
  //     trailerForm: {
  //       ...prevState.trailerForm,
  //       [name]: value,
  //     },
  //   }));
  //   checkRequiredFields('trailer')
  // };

  // const vehicle_handleChange = (name) => (event) => {
  //   const { target } = event;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;

  //   setState((prevState) => ({
  //     ...prevState,
  //     vehicleForm: {
  //       ...prevState.vehicleForm,
  //       [name]: value,
  //     },
  //   }));
  //       checkRequiredFields('vehicle')
  // };

  // const group_handleChange = (name) => (event) => {
  //   const { target } = event;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;

  //   setState((prevState) => ({
  //     ...prevState,
  //     groupForm: {
  //       ...prevState.groupForm,
  //       [name]: value,
  //     },
  //   }));
  //  checkRequiredFields('group')
  // };

  const C_Att_handleChange = (name) => (event) => {
    const { target } = event;

    setState((prevState) => ({
      ...prevState,
      computedAttForm: {
        ...prevState.computedAttForm,
        [name]: target.value,
      },
    }));

  };

  const savedCommands_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (name === 'attributes') {
      let att = state.savedCommandsForm.attributes
      let newAtt = { [target.name]: target.value }
      att = { ...att, ...newAtt }
      value = att
      setState(prevState =>
      ({
        ...prevState,
        savedCommandsForm: {
          ...state.savedCommandsForm,
          [target.name]:
            target.type === 'checkbox' ? target.checked : target.value
        }
      })
      )
    }


    setState(prevState =>
    ({
      ...prevState,
      savedCommandsForm: {
        ...state.savedCommandsForm,
        [name]: value
      },
    })
    )
  }





  const maintenance_handleChange = (name) => (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        [name]: value,
      },
    }));

  };

  const maintenance_handleChange_attr = (name) => (date) => {
    const value =
      name === 'repeat' || name === 'status' ? date.target.checked : date;

    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        attributes: {
          ...prevState.maintenanceForm.attributes,
          [name]: value,
        },
      },
    }));
  };

  // remove

  const removeNotification = () => {
    setState((prevState) => ({
      ...prevState,
      notificationCallStatus: 'DELETE'
    }));
  };

  const removeGeofence = () => {
    setState((prevState) => ({
      ...prevState,
      geofenceCallStatus: 'DELETE'
    }));
  };

  const removeDrivers = () => {
    setState((prevState) => ({
      ...prevState,
      driverCallStatus: 'DELETE'
    }));
  };

  const removeTrailers = () => {
    setState((prevState) => ({
      ...prevState,
      trailerCallStatus: 'DELETE'
    }));
  };

  const removeGarage = () => {
    setState((prevState) => ({
      ...prevState,
      garageCallStatus: 'DELETE'
    }));
  };

  const removeVehicle = () => {
    setState((prevState) => ({
      ...prevState,
      vehicleCallStatus: 'DELETE'
    }));
  };

  const removeMaintenance = () => {
    setState((prevState) => ({
      ...prevState,
      maintenanceCallStatus: 'DELETE'
    }));
  };

  const removeUser = () => {
    setState((prevState) => ({
      ...prevState,
      userCallStatus: 'DELETE'
    }));
  };

  const removeComputedAttributes = () => {
    setState((prevState) => ({
      ...prevState,
      attributeCallStatus: 'DELETE'
    }));
  };

  const onRemovedNotification = (item) => {
    setState((prevState) => ({
      ...prevState,
      notificationForm: { ...item[0] },
      onDeleteConfirmationNotification: true,
    }));
  };

  const onRemovedGeofence = (item) => {
    setState((prevState) => ({
      ...prevState,
      geofenceForm: { ...item[0] },
      onDeleteConfirmationGeofence: true,
    }));
  };

  const onRemovedDriver = (item) => {
    setState((prevState) => ({
      ...prevState,
      driverForm: { ...item[0] },
      onDeleteConfirmationDriver: true,
    }));
  };

  const onRemovedTrailer = (item) => {
    setState((prevState) => ({
      ...prevState,
      trailerForm: { ...item[0] },
      onDeleteConfirmationTrailer: true,
    }));
  };

  const onRemovedVehicle = (item) => {
    setState((prevState) => ({
      ...prevState,
      vehicleForm: { ...item[0] },
      onDeleteConfirmationVehicle: true,
    }));
  };

  const onRemovedGarage = (item) => {
    setState((prevState) => ({
      ...prevState,
      garageForm: { ...item[0] },
      onDeleteConfirmationGarage: true,
    }));
  };

  const onRemovedAttribute = (item) => {
    setState((prevState) => ({
      ...prevState,
      computedAttForm: { ...item[0] },
      onDeleteConfirmationAttributes: true,
    }));
  };

  const onRemovedCommand = (item) => {
    setState((prevState) => ({
      ...prevState,
      savedCommandsForm: { ...item[0] },
      onDeleteConfirmationCommand: true,
    }));
  };

  const onRemovedMaintenance = (item) => {
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: { ...item[0] },
      onDeleteConfirmationMaintenance: true,
    }));
  };

  // const onRemovedUser = (item) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     userForm: { ...item[0] },
  //     onDeleteConfirmationUser: true,
  //   }));
  // };

  // const onRemovedDevice = (item) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     slectedUnits: item[0],
  //     onDeleteConfirmationUnits: true,
  //   }));
  //   return false;
  // };

  const removeCommands = () => {
    setState((prevState) => ({
      ...prevState,
      commandsCallStatus: 'DELETE',
    }));

  };

  const deleteGarages = () => {
    const id = state.garageForm.id;
    if (id) {
      instance({
        url: `/api/garages/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          props.dispatch(removedGarages({ id: id }));
          props.dispatch(
            Notifications.success({
              message: props.translate('GaragesIsDeleted'),
              autoDismiss: 10,
            })
          );
          fetchGarageNewLists();
        })
        .catch((error) => errorHandler(error, props.dispatch));
    }
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmationGarage: false,
    }));
  };

  const fetchGarageNewLists = () => {
    instance({
      url: `/api/garages/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => props.dispatch(getGarages(res)))
      .catch((error) => errorHandler(error, props.dispatch));
  };

  const removeDevices = () => {
    const { slectedUnits } = state;
    instance({
      url: `/api/devices/${slectedUnits.id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        props.dispatch(removeDevice(slectedUnits.id));

        props.dispatch(
          Notifications.success({
            message: props.translate(`Tracker Delete Successfully`),
            autoDismiss: 10
          })
        );
        props.dispatch(fetchDevices(props.logInUsers))
        const currentId = parseInt(props.id)
        getCurrentGroup(currentId)
        // props.history.push('/units');
        setState((prevState) => ({
          ...prevState,
          onDeleteConfirmationUnits: false,
        }));
      })
      .catch((error) => errorHandler(error, props.dispatch));
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmationGeofence: false,
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationUser: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationUnits: false,
      onDeleteConfirmation: false,
    }));
  };

  const onCloseC_Att_Modal = () => {
    setState((prevState) => ({
      ...prevState,
      visableAttribute: false,
      visableNotification: false,
      visableGeofence: false,
      visableMaintenance: false,
      visableSavedCommands: false,
      attributeChangesMessage: '',
      defaultAttributes: {},
    }));
  };

  const checkRequiredFields = (computedAtt) => {
    const {
      defaultAttributes,
      selecteItem,
      maintenanceForm,
      computedAttForm,
      savedCommandsForm,
      notificationForm,
    } = state;

    if (computedAtt === 'computedAtt') {
      const changed_comAt = !isEqual(selecteItem, computedAttForm);
      const { attribute, expression } = computedAttForm;
      if (
        attribute &&
        expression &&
        attribute.trim().length > 0 &&
        expression.trim().length > 0 &&
        changed_comAt
      ) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false,
        }));
      }
    } else if (computedAtt === 'Commands') {
      const changed_comm = !isEqual(selecteItem, savedCommandsForm);
      const { type, description } = savedCommandsForm;
      if (type && description && changed_comm) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false,
        }));
      }
    } else if (computedAtt === 'notification') {
      const attChangedValue = !isEqual(
        defaultAttributes,
        notificationForm.attributes
      );
      if (attChangedValue) {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: 'Change in attributes is pending',
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: '',
        }));
      }
      const changed_noti = !isEqual(selecteItem, notificationForm);
      const { type, notificators } = notificationForm;
      if (type && notificators && changed_noti) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false,
        }));
      }
    } else if (computedAtt === 'maintenance') {
      const Attvalue = !isEqual(defaultAttributes, maintenanceForm.attributes);
      if (Attvalue) {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: 'Change in attributes is pending',
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: '',
        }));
      }
      const changed_main = !isEqual(selecteItem, maintenanceForm);
      const { type, start, period, attributes } = maintenanceForm;
      if (type && period && (start || attributes.startDate) && changed_main) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false,
        }));
      }
    }
  };


  const getCurrentGroup = (id) => {
    let group = [];
    if (id) {
      const fetchData = () => {
        try {

          if (checkPrivileges('driver')) {
            instance({
              url: `/api/drivers`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              params: {
                groupId: id,
                all: true,
              },
            }).then(driver=>{
            setState((prevState) => ({
              ...prevState,
              mulitDriversSelect: driver,
              isDriverRecived: true,
            }));

            setState((prevState) => ({
              ...prevState,
              mulitDriversSelect: driver,
              isDriverRecived: true,
            }));

            let allData = props.drivers;
          
            if (driver.length) {
              allData.map((item) => {
                item.check = false;
                driver.map((main) => {
                  if (item.id && main.id && item.id === main.id) {
                    item.check = true;
                    main.check = true;
                  }
                });
              });
            } else {
              allData.map((n) => (n.check = false));
            }
            props.dispatch(getDriverDeviceWise(driver));
            props.dispatch(driverDeviceWiseSelectedList(allData));
            })
          }

          if (checkPrivileges('binder')) {

            instance({
              url: `/api/binders`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                groupId: id,
                userId: props.logInUsers.id
              }
            })

              .then(trailer => {
                setState(prevState => ({
                  ...prevState,
                  mulitTrailersSelect: trailer,
                  isTrailerRecived: true
                }))
                let allData = props.trailers
                if (trailer.length) {
                  allData.map(item => {
                    item.check = false
                    trailer.map(main => {
                      if (item.id && main.id && item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }
                props.dispatch(getTrailerDeviceWise(trailer))
                props.dispatch(trailerDeviceWiseSelectedList(allData))
              })

              .catch(() => {
                // errorHandler(error, props.dispatch)
              })
          }

          if (checkPrivileges('vehicle')) {
            instance({
              url: `/api/vehicles`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              params: {
                groupId: id,
                userId: props.logInUsers.id,
              },
            }).then((vehicle) =>{


            setState((prevState) => ({
              ...prevState,
              mulitVehiclesSelect: vehicle,
              isVehicleRecived: true,
            }));

            let allData = props.vehicles;
            if (vehicle.length) {
              allData.map((item) => {
                item.check = false;
                vehicle.map((main) => {
                  if (item.id && main.id && item.id === main.id) {
                    item.check = true;
                    main.check = true;
                  }
                });
              });
            } else {
              allData.map((n) => (n.check = false));
            }
            props.dispatch(getVehicleDeviceWise(vehicle));
            props.dispatch(vehicleDeviceWiseSelectedList(allData));
          })}
          if (checkPrivileges('garage')) {
            const garage = instance({
              url: `/api/garages`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              params: {
                groupId: id,
                userId: props.logInUsers.id,
              },
            }).then((res) => res.data);

            setState((prevState) => ({
              ...prevState,
              multigaragesSelect: garage,
              isgarageRecived: true,
            }));

            let allData = props.garages?.data || [];
            allData.map((item) => {
              item.check = item.parent = parseInt(id) === item.parentId;
              item.indeterminate = false;
              if (garage.length) {
                allData.map((item) => {
                  item.check = false;
                  garage.map((main) => {
                    if (item.id && main.id && item.id === main.id) {
                      item.check = true;
                      main.check = true;
                    }
                  });
                });
              }
            });
            props.dispatch(garageUsersWiseSelectedList(allData));
          }

          if (checkPrivileges('area')) {
             instance({
              url: `/api/areas`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              params: {
                groupId: id,
                userId: props.logInUsers.id,
              },
            }).then((area) => {
              setState((prevState) => ({
                ...prevState,
                multiareasSelect: area,
                isareaRecived: true,
              }));
  
              let allData = props.areas?.data || [];
              allData.map((item) => {
                item.check = item.parent = parseInt(id) === item.parentId;
                item.indeterminate = false;
                if (area.length) {
                  area.map((main) => {
                    if (item.id && main.id && item.id === main.id) {
                      item.indeterminate = main.directAccess === false;
                      item.check = main.directAccess;
                      item.groupAccess = main.groupAccess;
                    }
                  });
                }
              });
              props.dispatch(areaUsersWiseSelectedList(allData));
            
            })}
          if (checkPrivileges('service')) {
             instance({
              url: `/api/expensetypes`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              params: {
                groupId: id,
                userId: props.logInUsers.id,
              },
            }).then((service) => {
              
            setState((prevState) => ({
              ...prevState,
              multiserviceSelect: service,
              isserviceRecived: true,
            }));

            let allData = props.services || [];
            allData.map((item) => {
              item.check = item.parent = parseInt(id) === item.parentId;
              item.indeterminate = false;
              if (service.length) {
                service.map((main) => {
                  if (item.id && main.id && item.id === main.id) {
                    item.indeterminate = main.directAccess === false;
                    item.check = main.directAccess;
                    item.groupAccess = main.groupAccess;
                  }
                });
              }
            });
            props.dispatch(serviceUsersWiseSelectedList(allData));
          

            })}

          if (checkPrivileges('geofence')) {

            instance({
              url: `/api/geofences`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                groupId: id,
                all: true
              }
            })

              .then(geofences => {
                setState(prevState => ({
                  ...prevState,
                  multiGeofencesSelect: geofences,
                  isGroRecived: true
                }))

                let allData = props.geofences
                if (geofences.length) {
                  allData.map(item => {
                    item.check = false
                    geofences.map(main => {
                      if (item.id && main.id && item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }
                props.dispatch(getGeofenceDeviceWise(geofences))
                props.dispatch(geofenceDeviceWiseSelectedList(allData))
              })

              .catch(() => {
                // errorHandler(error, props.dispatch)
              })
          }

          if (checkPrivileges('device')) {

            instance({
              url: `/api/devices`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                groupId: id
              }
            })

              .then(devices => {
                setState(prevState => ({
                  ...prevState,
                  multiDevicesSelect: devices,
                  isGroRecived: true
                }))
                let allData = props.devices3

                if (devices.length) {
                  allData.map(item => {
                    item.check = false
                    devices.map(main => {
                      if (item.id && main.id && item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }
                props.dispatch(getDeviceUserWise(devices))
                props.dispatch(deviceUserWiseSelectedList(allData))
              })

              .catch(() => {
                // errorHandler(error, props.dispatch)
              })
          }

          if (checkPrivileges('attribute')) {

            instance({
              url: `/api/attributes/computed`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                groupId: id,
                all: true
              }
            })

              .then(attributes => {
                let allData = props.computedAttributes
                if (attributes.length) {
                  allData.map(item => {
                    item.check = false
                    attributes.map(main => {
                      if (item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }
                props.dispatch(getComputedAttributeDeviceWise(attributes))
                props.dispatch(
                  computedAttributeDeviceWiseSelectedList(allData)
                )
                setState(prevState => ({
                  ...prevState,
                  isComputedAttributesRecived: true
                }))
              })

              .catch(() => {
                // errorHandler(error, props.dispatch)
              })
          }

          if (checkPrivileges('command')) {
            instance({
              url: `/api/commands`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                groupId: id,
                all: true
              }
            })
              .then(commands => {
                let data = commands
                data.map(item => {
                  item.textChannel = item.textChannel ? 'Yes' : 'No'
                  return null
                })
                let allData = props.commands
                if (commands.length) {
                  allData.map(item => {
                    item.check = false
                    commands.map(main => {
                      if (item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }

                props.dispatch(getCommandsDeviceWise(data))
                props.dispatch(commandsDeviceWiseSelectedList(allData))
              })

              .catch(() => {
              })
          }
          if (checkPrivileges('maintenance')) {

            instance({
              url: `/api/maintenance`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                groupId: id,
                userId: props.logInUsers.id,
                all: true
              }
            })

              .then(maintenance => {
                let allData = props.maintenance
                if (maintenance.length) {
                  allData.map(item => {
                    item.check = false
                    maintenance.map(main => {
                      if (item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }

                props.dispatch(getMaintenanceDeviceWise(maintenance))
                props.dispatch(maintenanceDeviceWiseSelectedList(allData))
              })

              .catch(() => {
                // errorHandler(error, props.dispatch)
              })
          }
        } catch (error) {
          errorHandler(error, props.dispatch);
          console.warn(error)
        }
      };

      fetchData();

      // Reset certain state fields
      setState((prevState) => ({
        ...prevState,
        gOneform: {},
        aOneform: {},
        resFormData: {},
        mulitDriversSelect: '',
        mulitTrailersSelect: '',
        mulitVehiclesSelect: '',
        mulitUsersSelect: '',
        multiGeofencesSelect: '',
        isDriverRecived: false,
        isTrailerRecived: false,
        isgarageRecived: false,
        isareaRecived: false,
        isVehicleRecived: false,
        isGroRecived: false,
        unitParkingAttributesform: {},
        isHaveAccess: 'attributeCreate',
      }));

      group = props.groups.filter((dev) => parseInt(dev.id) === parseInt(id));
      if (group[0]) {
        setState((prevState) => ({
          ...prevState,
          resFormData: { ...group[0] },
        }));
      }
    }

    return { group };
  };

  //new implamentation with backend pagination
  // const getMoreItems = () => {
  //   if (state.currentId) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       isRecived: false,
  //     }));

  //     const { currentTab } = state;
  //     const pagItemValue = 'pagination' + currentTab;
  //     const { page, pagesize, itemSearch } = state[pagItemValue];

  //     instance({
  //       url: `/api/vehicles/linked`,
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       params: {
  //         groupId: props.id,
  //         page: page,
  //         limit: pagesize,
  //         search: itemSearch,
  //       },
  //     })
  //       .then((items) => {
  //         let lastPage = items.total / pagesize;
  //         let IsFloate = checkFloteNumber(lastPage);

  //         setState((prevState) => ({
  //           ...prevState,
  //           [pagItemValue]: {
  //             ...prevState[pagItemValue],
  //             total: items.total,
  //             lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
  //             hasNext: items.hasNext,
  //           },
  //           [currentTab + 'List']: items.data,
  //           isRecived: true,
  //         }));
  //       })
  //       .catch(() => {
  //         // errorHandler(error, props.dispatch)
  //       });
  //   }
  // };

  // const checkFloteNumber = (n) => {
  //   return Number(n) === n && n % 1 !== 0;
  // };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setState(prevState => ({ ...prevState, rowsPerPage }))
  }


  // const linkItem = (checked, item) => {
  //   const { currentTab } = state;
  //   let method = 'DELETE';
  //   let value = checked;
  //   let obj = {
  //     groupId: parseInt(props.id),
  //     [`${currentTab}Id`]: item.id
  //   };
  //   if (value) {
  //     method = 'POST';
  //   }

  //   instance({
  //     url: `/api/permissions`,
  //     method: method,
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     data: {
  //       ...obj
  //     }
  //   })
  //     .then(() => {
  //       const messageKey = value
  //         ? `${currentTab}AssignedSuccessfully`
  //         : `${currentTab}UnAssignedSuccessfully`;
  //         props.dispatch(
  //         Notifications.success({
  //           message: props.translate(messageKey),
  //           autoDismiss: 10
  //         })
  //       );
  //     })
  //     .catch(error => {
  //       errorHandler(error, props.dispatch);
  //     });
  // };

  // const onRemovedItem = (item) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     removeItemId: item[0].id,
  //     removeItemName: item[0].label || item[0].name || item[0].type,
  //     onDeleteConfirmation: true
  //   }));
  // };

  const removeItem = () => {
    const { removeItemId, currentTab } = state;

    instance({
      url: `/api/${currentTab}s/${removeItemId}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        onCancel();
        props.dispatch(
          Notifications.success({
            message: props.translate(`${currentTab}DeleteSuccessfully`),
            autoDismiss: 10
          })
        );
      })
      .catch(error => {
        errorHandler(error, props.dispatch);
      });
  };

  // const handleChangePage = (value) => {
  //   const { currentTab } = state;
  //   const pagItemValue = 'pagination' + currentTab;
  //   setState((prevState) => ({
  //     ...prevState,
  //     [pagItemValue]: {
  //       ...prevState[pagItemValue],
  //       page: value
  //     }
  //   }));
  // };

  // const SearchItem = (searchValue) => {
  //   const { currentTab } = state;
  //   const pagItemValue = 'pagination' + currentTab;
  //   setState((prevState) => ({
  //     ...prevState,
  //     [pagItemValue]: {
  //       ...prevState[pagItemValue],
  //       page: 1,
  //       itemSearch: searchValue
  //     }
  //   }));
  // };

  const setSelectedItem = (selecteditem) => {
    setState((prevState) => ({
      ...prevState,
      selecteditem
    }));
  };
  const { currentTab, selecteditem, removeItemName } = state
  const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'
  const selecteddevices = [...props.selecteddevices]


  selecteddevices.map(n => {
    n.parent = n.parentId === parseInt(props.id)
    return null
  })

  return (
    <Fragment>
      <h4 className='tab-title'>
        {state.resFormData ? state.resFormData.name : 'Resources'}
      </h4>
      <div className={' main-unit-modal-bg clearfix'} style={{ display: "flex" }}>
        {state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeItem}
            title={props.translate('areYouWantToDelete')}
            children={removeItemName}
          />
        )}

        {state.onDeleteConfirmationUnits && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDevices}
            title={props.translate('areYouWantToDelete')}
            children={state.slectedUnits.name}
          />
        )}
        {state.onDeleteConfirmationNotification && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeNotification}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'notification.' + state.notificationForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationDriver && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDrivers}
            title={props.translate('areYouWantToDelete')}
            children={state.driverForm.name}
          />
        )}
        {state.onDeleteConfirmationTrailer && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeTrailers}
            title={props.translate('areYouWantToDelete')}
            children={state.trailerForm.name}
          />
        )}
        {state.onDeleteConfirmationVehicle && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeVehicle}
            title={props.translate('areYouWantToDelete')}
            children={state.vehicleForm.name}
          />
        )}
        {state.onDeleteConfirmationGarage && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeGarage}
            title={props.translate('areYouWantToDelete')}
            children={state.garageForm.name}
          />
        )}
        {state.onDeleteConfirmationGeofence && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeGeofence}
            title={props.translate('areYouWantToDelete')}
            children={state.geofenceForm.name}
          />
        )}
        {state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeComputedAttributes}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              state.computedAttForm.description
            )}
          />
        )}
        {state.onDeleteConfirmationCommand && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeCommands}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'commands.' + state.savedCommandsForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationMaintenance && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeMaintenance}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'positiont.' + state.maintenanceForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationUser && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeUser}
            title={props.translate('areYouWantToDelete')}
            children={state.userForm.name}
          />
        )}
        {state.onDeleteConfirmationUnits && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDevices}
            title={props.translate('areYouWantToDelete')}
            children={state.slectedUnits.name}
          />
        )}
        {state.onDeleteConfirmationNotification && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeNotification}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'notification.' + state.notificationForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeComputedAttributes}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              state.computedAttForm.description
            )}
          />
        )}
        {state.onDeleteConfirmationCommand && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeCommands}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'commands.' + state.savedCommandsForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationMaintenance && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeMaintenance}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'positiont.' + state.maintenanceForm.type
            )}
          />
        )}

        <div style={{ maxWidth: 264, minWidth: 220 }}>
          <Tabs
            orientation={orientation}
            variant='scrollable'
            scrollButtons='auto'
            value={currentTab}
            onChange={tabChange}
            aria-label='Vertical tabs example'
            className='custom-tabs'
            textColor='#FFFFFF'
            TabIndicatorProps={{ hidden: true }}

          >
            <Tab
              value='user'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  {props.translate('basic')}{' '}
                  <ArrowRightIcon className='tab_menu_icon' />{' '}
                </span>
              }
              {...a11yProps('basic')}
            />
            {checkPrivileges('device') && (
              <Tab
                value='device'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sensorUnits')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(1)}
              />
            )}
            {checkPrivileges('driver') && (
              <Tab
                value='driver'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedDrivers')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('driver')}
              />
            )}
            {checkPrivileges('binder') && (
              <Tab
                value='binder'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedBinder')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('binder')}
              />
            )}
            {checkPrivileges('vehicle') && (
              <Tab
                value='vehicle'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedVehicle')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('vehicle')}
              />
            )}
            {checkPrivileges('garage') && (
              <Tab
                value='garage'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedGarage')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('garage')}
              />
            )}
            {checkPrivileges('area') && (
              <Tab
                value='area'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedArea')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('area')}
              />
            )}
            {checkPrivileges('service') && (
              <Tab
                value='service'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('service')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('service')}
              />
            )}
            {checkPrivileges('geofence') && (
              <Tab
                value='geofence'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedGeofences')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('geofence')}
              />
            )}
            {/* temprary comment notification for Release 3.2.1 */}
            {/* {checkPrivileges('notification') && (
                <Tab
                  value='notification'
                  classes={{
                    root: 'common-tab',
                    selected: 'tab-selected',
                    wrapper: 'tab-wrapper'
                  }}
                  label={
                    <span className='menu_item_text'>
                      {props.translate('sharedNotifications')}{' '}
                    </span>
                  }
                  {...a11yProps('notification')}
                />
              )} */}
            {checkPrivileges('attribute') && (
              <Tab
                value='attribute'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedComputedAttributes')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('attribute')}
              />
            )}
            {checkPrivileges('command') && (
              <Tab
                value='command'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedSavedCommand')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('command')}
              />
            )}
            {/* temprary comment maintenance for Release 3.2.1*/}
            {checkPrivileges('maintenance') && (
              <Tab
                value='maintenance'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedMaintenance')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('maintenance')}
              />
            )}
          </Tabs>
        </div>

        {currentTab === 'user' && (
          <TabPanel
            value={currentTab}
            index={'user'}
            style={{ width: '100%' }}
          >
            {selecteditem && (
              <GroupsModal
                {...props}
                viewScreen={'edit'}
                activeOperation={'edit'}
                selecteditem={selecteditem}
                setSelectedItem={setSelectedItem}
              />
            )}
          </TabPanel>
        )}
        {checkPrivileges('driver') && currentTab === 'driver' && (
          <TabPanel
            value={currentTab}
            index={'driver'}
            style={{ width: '100%' }}
          >
            {/* {(props.selecteddriver.length) ?( */}
            <CommonTableSection
              component_Selection={driver_Selection}
              themecolors={props.themecolors}
              componentData={props.selecteddriver}
              showCheckbox
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              onDelete={onRemovedDriver}
              label={props.translate('sharedDrivers')}
              canUpdate={false}
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'driverId',
                  props.id,
                  props.selecteddriver,
                  props.drivers,
                  props.dispatch,
                  'driver',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedDrivers')
                },
                {
                  id: 'uniqueId',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('uniqueId')
                }
              ]}
              canDelete={checkPrivileges('driverDelete')}
              canRemove={checkPrivileges('groupUnlinkDriver')}
              canAssign={checkPrivileges('groupLinkDriver')}
            />
            {/* ):<Loader />} */}
          </TabPanel>
        )}
        {checkPrivileges('device') && currentTab === 'device' && (
          <TabPanel
            value={currentTab}
            index={'device'}
            style={{ width: '100%' }}
          >
            {((props.devices.total > 0) && !selecteddevices.length) || (props.devices.isFetching) ?
              (<Loader />) : (<CommonTableSection
                component_Selection={unit_Selection}
                themecolors={props.themecolors}
                componentData={selecteddevices}
                parent={
                  selecteditem && selecteditem.id
                    ? selecteditem.id
                    : 0
                }
                showCheckbox
                // onDelete={false}
                label={props.translate('sensorUnits')}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={state.rowsPerPage}
                canUpdate={false}
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('sensorUnits')
                  }
                ]}
                // canDelete={checkPrivileges('deviceDelete')}
                canRemove={checkPrivileges('groupLinkDevice')}
                canAssign={checkPrivileges('groupUnlinkDevice')}
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'deviceId',
                    props.id,
                    props.selecteddevices,
                    props.devices3,
                    props.dispatch,
                    'device',
                    props.translate
                  )
                }
              />)}
          </TabPanel>
        )}
        {checkPrivileges('binder') && currentTab === 'binder' && (
          <TabPanel
            value={currentTab}
            index={'binder'}
            style={{ width: '100%' }}
          >
            {/* {(props.selectedtrailer.length) ?( */}
            <CommonTableSection
              component_Selection={trailer_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedtrailer}
              showCheckbox
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              onDelete={onRemovedTrailer}
              label={props.translate('sharedBinder')}
              canUpdate={false}
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'binderId',
                  props.id,
                  props.selectedtrailer,
                  props.trailers,
                  props.dispatch,
                  'binder',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedBinder')
                },
                {
                  id: 'uniqueId',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('BinderUniqueId')
                }
              ]}
              canDelete={checkPrivileges('binderDelete')}
              canRemove={checkPrivileges('groupUnlinkBinder')}
              canAssign={checkPrivileges('groupLinkBinder')}
            />
            {/* ):<Loader />} */}
          </TabPanel>
        )}
        {checkPrivileges('vehicle') && currentTab === 'vehicle' && (
          <TabPanel
            value={currentTab}
            index={'vehicle'}
            style={{ width: '100%' ,overflow:"auto"}}
          >
            {/* {(props.selectedvehicle.length) ?( */}
            <CommonTableSection
              component_Selection={vehicle_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedvehicle}
              showCheckbox
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              onDelete={onRemovedVehicle}
              label={props.translate('sharedVehicle')}
              canUpdate={false}
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'vehicleId',
                  props.id,
                  props.selectedvehicle,
                  props.vehicles,
                  props.dispatch,
                  'vehicle',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'label',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedVehicle')
                },
                {
                  id: 'vehicleLicensePlate',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('vehicleLicensePlate')
                }
              ]}
              canDelete={checkPrivileges('vehicleDelete')}
              canRemove={checkPrivileges('groupUnlinkVehicle')}
              canAssign={checkPrivileges('groupLinkVehicle')}
            />
            {/* ):<Loader />} */}
          </TabPanel>
        )}
        {/* Garage start */}
        {checkPrivileges('garage') && currentTab === 'garage' && (
          <TabPanel
            value={currentTab}
            index={'garage'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={garage_Selection}
              themecolors={props.themecolors}
              componentData={props.garages?.data || []}
              showCheckbox={true}
              showGroupAccess
              onDelete={onRemovedGarage}
              label={props.translate('sharedGarage')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'garageId',
                  props.id,
                  props.selectedGarages,
                  props.garages,
                  props.dispatch,
                  'garage',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedGarage')
                }
              ]}
              canDelete={checkPrivileges('garageDelete')}
              canRemove={checkPrivileges('groupUnlinkGarage')}
              canAssign={checkPrivileges('groupLinkGarage')}
            />
          </TabPanel>
        )}
        {/* Garage end */}

        {/* Area start */}
        {checkPrivileges('area') &&
          currentTab === 'area' && (
            <TabPanel
              value={currentTab}
              index={'area'}
              style={{ width: '100%' }}
            >
              <CommonTableSection
                component_Selection={area_Selection}
                themecolors={props.themecolors}
                componentData={props.areas?.data || []}
                showCheckbox={true}
                label={props.translate('sharedArea')}
                canUpdate={false}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={state.rowsPerPage}
                parent={
                  selecteditem && selecteditem.id
                    ? selecteditem.id
                    : 0
                }
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'areaId',
                    props.id,
                    props.selectedAreas,
                    props.selectedAreas || [],
                    props.dispatch,
                    'area',
                    props.translate
                  )
                }
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('sharedArea')
                  }
                ]}
                // canDelete={checkPrivileges('areaDelete')}
                canRemove={checkPrivileges('groupUnlinkArea')}
                canAssign={checkPrivileges('groupLinkArea')}
              />
            </TabPanel>
          )}
        {/* Area end */}

        {/* service start */}
        {checkPrivileges('service') &&
          currentTab === 'service' && (
            <TabPanel
              value={currentTab}
              index={'service'}
              style={{ width: '100%' }}
            >
              <CommonTableSection
                component_Selection={service_Selection}
                themecolors={props.themecolors}
                componentData={props.services || []}
                showCheckbox={true}
                label={props.translate('service')}
                canUpdate={false}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={state.rowsPerPage}
                parent={
                  selecteditem && selecteditem.id
                    ? selecteditem.id
                    : 0
                }
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'expenseTypeId',
                    props.id,
                    props.selectedServices,
                    props.selectedServices || [],
                    props.dispatch,
                    'service',
                    props.translate
                  )
                }
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('service')
                  }
                ]}
                // canDelete={checkPrivileges('areaDelete')}
                canRemove={checkPrivileges('groupUnlinkExpenseType')}
                canAssign={checkPrivileges('groupLinkExpenseType')}
              />
            </TabPanel>
          )}
        {/* service end */}

        {checkPrivileges('geofence') && currentTab === 'geofence' && (
          <TabPanel
            value={currentTab}
            index={'geofence'}
            style={{ width: '100%' }}
          >
            {/* {(props.selectedgeofence.length) ?( */}
            <CommonTableSection
              component_Selection={geofence_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedgeofence}
              showCheckbox={true}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              onDelete={onRemovedGeofence}
              label={props.translate('sharedGeofences')}
              canUpdate={false}
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'geofenceId',
                  props.id,
                  props.selectedgeofence,
                  props.geofences,
                  props.dispatch,
                  'geofence',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedGeofences')
                }
              ]}
              canDelete={checkPrivileges('geofenceDelete')}
              canRemove={checkPrivileges('groupUnlinkGeofence')}
              canAssign={checkPrivileges('groupLinkGeofence')}
            />
            {/* ):<Loader />} */}
          </TabPanel>
        )}
        {checkPrivileges('notification') && currentTab === 'notification' && (
          <TabPanel
            value={currentTab}
            index={'notification'}
            style={{ width: '100%' }}
          >
            {/* {(props.selectednotification.length) ?( */}
            <Notification
              onListNotification={onListNotification}
              // onCloseC_Modal={onCloseC_Modal}
              onCreateNewNotification={onCreateNewNotification}
              notification_Selection={notification_Selection}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              addNotification={addNotifications}
              notification_handleChange={notification_handleChange}
              notification_handleChange2={notification_handleChange2}
              notificationHandle={notificationHandle}
              calendars={props.calendars}
              notificationDeviceWise={props.notificationDeviceWise}
              themecolors={props.themecolors}
              notificationData={props.selectednotification}
              visableCreateNotification={state.visableNotification}
              notificationForm={state.notificationForm}
              notificationChannel={props.notificationChannel}
              notificationType={props.notificationType}
              translate={props.translate}
              onEdit={editNotification}
              onDelete={onRemovedNotification}
              addButtonStatus={state.addButtonStatus}
              addAttributes={addNotificationAttributes}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              canRemove={checkPrivileges('groupUnlinkNotification')}
              canAssign={checkPrivileges('groupLinkNotification')}
              attributeChangesMessage={state.attributeChangesMessage}
              rowsPerPage={state.rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'notificationId',
                  props.id,
                  props.selectednotification,
                  props.notification,
                  props.dispatch,
                  'notification',
                  props.translate
                )
              }
            />
            {/* ):<Loader />} */}
          </TabPanel>
        )}
        {checkPrivileges('attribute') && currentTab === 'attribute' && (
          <TabPanel
            value={currentTab}
            index={'attribute'}
            style={{ width: '100%' }}
          >
            {/* {(props.selectedComputedAttributes.length) ?( */}
            <ComputedAttributes
              {...props}
              onListComputedAttributes={onListComputedAttributes}
              translate={props.translate}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              // onCloseC_Modal={onCloseC_Modal}
              themecolors={props.themecolors}
              computedAttributesDeviceWise={
                props.computedAttributesDeviceWise
              }
              onCreateNewAttributes={onCreateNewAttributes}
              C_Att_handleChange={C_Att_handleChange}
              attributeHandle={attributeHandle}
              addComputedAttributes={addComputedAttributes}
              // computedAttributes_handleChange={
              //   computedAttributes_handleChange
              // }
              visableAttribute={state.visableAttribute}
              visableListAttribute={state.visableListAttribute}
              computedAttributesData={props.selectedComputedAttributes}
              computedAttForm={state.computedAttForm}
              computedAttributes_Selection={computedAttributes_Selection}
              onEdit={editComputedAttributes}
              onDelete={onRemovedAttribute}
              addButtonStatus={state.addButtonStatus}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              canRemove={checkPrivileges('groupUnlinkAttribute')}
              canAssign={checkPrivileges('groupLinkAttribute')}
              rowsPerPage={state.rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'attributeId',
                  props.id,
                  props.selectedComputedAttributes,
                  props.computedAttributes,
                  props.dispatch,
                  'attribute',
                  props.translate
                )
              }
              isLoading={isLoading}
            />
            {/* ):<Loader />} */}
          </TabPanel>
        )}
        {checkPrivileges('command') && currentTab === 'command' && (
          <TabPanel
            value={currentTab}
            index={'command'}
            style={{ width: '100%' }}
          >
            {/* {(props.selectedcommands.length) ?( */}
            <SavedCommands
              onListSavedCommands={onListSavedCommands}
              translate={props.translate}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              // onCloseC_Modal={onCloseC_Modal}
              themecolors={props.themecolors}
              commandsDeviceWise={props.commandsDeviceWise}
              onCreateNewSavedCommands={onCreateNewSavedCommands}
              visableAttribute={state.visableSavedCommands}
              addSavedCommands={addSavedCommands}
              savedCommands_handleChange={savedCommands_handleChange}
              savedCommandsHandle={savedCommandsHandle}
              visableListSavedCommands={state.visableListSavedCommands}
              visableSavedCommands={state.visableSavedCommands}
              commandsData={props.selectedcommands}
              savedCommandsForm={state.savedCommandsForm}
              savedCommands_Selection={savedCommands_Selection}
              commandsTypes={props.commandsTypes}
              onEdit={editCommands}
              onDelete={onRemovedCommand}
              addButtonStatus={state.addButtonStatus}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              canRemove={checkPrivileges('groupUnlinkCommand')}
              canAssign={checkPrivileges('groupLinkCommand')}
              rowsPerPage={state.rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'commandId',
                  props.id,
                  props.selectedcommands,
                  props.commands,
                  props.dispatch,
                  'command',
                  props.translate
                )
              }
              isLoading={isLoading}
            />
            {/* ):<Loader />} */}
          </TabPanel>
        )}
        {checkPrivileges('maintenance') && currentTab === 'maintenance' && (
          <TabPanel
            value={currentTab}
            index={'maintenance'}
            style={{ width: '100%', minWidth: 0 }}
          >
            {/* {(props.selectedMaintenance.length) ?( */}
            <MaintenanceForm
              onListMaintenance={onListMaintenance}
              // onCloseC_Modal={onCloseC_Modal}
              onCreateNewMaintenance={onCreateNewMaintenance}
              maintenance_Selection={maintenance_Selection}
              saveToParent={saveToParent}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              addMaintenance={addMaintenances}
              maintenance_handleChange={maintenance_handleChange}
              maintenance_handleChange_attr={
                maintenance_handleChange_attr
              }
              maintenancesHandle={maintenancesHandle}
              maintenanceDeviceWise={props.maintenanceDeviceWise}
              themecolors={props.themecolors}
              visableListMaintenance={state.visableListMaintenance}
              maintenanceData={props.selectedMaintenance}
              visableCreateMaintenance={state.visableMaintenance}
              maintenanceForm={state.maintenanceForm}
              translate={props.translate}
              onEdit={editMaintenance}
              onDelete={onRemovedMaintenance}
              addButtonStatus={state.addButtonStatus}
              addAttributes={addMaintenanceAttributes}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              canRemove={checkPrivileges('groupUnlinkMaintenance')}
              canAssign={checkPrivileges('groupLinkMaintenance')}
              rowsPerPage={state.rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              attributeChangesMessage={state.attributeChangesMessage}
              isAllowed
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'maintenanceId',
                  props.id,
                  props.selectedMaintenance,
                  props.maintenance,
                  props.dispatch,
                  'maintenance',
                  props.translate
                )
              }
            />
            {/* ):<Loader />} */}
          </TabPanel>
        )}
      </div>
    </Fragment>
  )
}

MainUnitModal.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => ({
  devices3: state.devices3,
  devices: state.devices,
  groups: state.groups,
  drivers: state.drivers,
  trailers: state.trailers,
  vehicles: state.vehicles,
  geofences: state.geoFence,
  users: state.users,
  themecolors: state.themeColors,
  notification: state.notification,
  notificationType: state.notificationType,
  notificationChannel: state.notificationChannel,
  selectednotification: state.selectednotification,
  selecteddriver: state.selecteddriver,
  selectedtrailer: state.selectedtrailer,
  selectedvehicle: state.selectedvehicle,
  selectedgroups: state.selectedgroups,
  selecteduser: state.selecteduseruser,
  selecteddevices: state.selecteddevices,
  selectedgeofence: state.selectedgeofence,
  geofenceDeviceWise: state.geofenceDeviceWise,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  sensorsDeviceWise: state.sensorsDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  calendars: state.calendars,
  category: state.category,
  positions: state.positions,
  roles: state.roles,
  ServerSetting: state.ServerSetting,
  logInUsers: state.logInUsers,
  selectedGarages: state.selectedGarages,
  garages: state.garages,
  selectedAreas: state.selectedAreas,
  areas: state.areas,
  selectedServices: state.selectedServices,
  services: state.services
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(
  (withLocalize(MainUnitModal))
)
