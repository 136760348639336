import React, { useState,useEffect } from 'react'
import Checkbox from './Checkbox'
import Style from 'style-it'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
import { useSelector } from 'react-redux';

const MaintenanceSelectors = (props) => {

  const [list, setList] = useState(props.allPreSelected ? { completed: true, required: true, expired: true, scheduled: true } : {});
  const [search, setSearch] = useState('');
  const [isChecked, setIsChecked] = useState(props.allPreSelected ? true : false);

  const themecolors=useSelector((state)=>state.themeColors)


  useEffect(() => {
    const updatedList = {};
    if (props.value && props.value.length) {
      props.value.forEach(d => (updatedList[d] = true));
      setList(updatedList);
    }
  }, []);

  useEffect(() => {
    return () => {
      setList({});
      setSearch('');
      setIsChecked(false);
    };
  }, []);

  const sendToParent = (updatedList) => {
    if (props.onChange) {
      props.onChange(Object.keys(updatedList), 'maintenance');
    }
  };

  
  const handleChange = (e, v) => {
    const updatedList = { ...list };
    if (e.target.checked) {
      updatedList[v.key] = true;
    } else {
      delete updatedList[v.key];
    }
    setList(updatedList);
    setIsChecked( props.maintenanceTypes &&  props.maintenanceTypes.length === Object.keys(updatedList).length);
    sendToParent(updatedList);
  };

  const handleToggleItems = (event) => {
    const updatedList = {};
    if (event.target.checked) {
      props.maintenanceTypes && props.maintenanceTypes.forEach(d => (updatedList[d.key] = true));
      setList(updatedList);
      setIsChecked(true);
    } else {
      setList({});
      setIsChecked(false);
    }
    sendToParent(updatedList);
  };

  const filteredList = props.maintenanceTypes && props.maintenanceTypes.filter(
    item => item.name && item.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Style>
      {`
      .selector-list li > label.active,
      .selector-list li > label:hover {
        background: ${themecolors.themeLightColor};
        color: ${themecolors.themeLightInverse};
      }
      .selector-list li {
          margin-bottom: 6px
      }
      .selector-list li > label {
          cursor: pointer;
          display: block;
          padding: 2px 6px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          background: ${themecolors.themeListingColor};
      }
      .selector-list {
          padding: 0;
          margin: 0;
          color: ${themecolors.themeListingInverse};
          list-style: none
      }
      .selector-label {
          font-size: 12px;
          line-height: 1.5;
          margin-left: 6px;
      }
      .selector-image {
          width: 26px;
          margin-left: 6px;
      }
      .selector-image img {
          height: auto;
          width: 26px;
          height: 26px;
          display: block;
          margin: auto;
      }
      .selector-check {
          height: 26px;
          display: inline-flex;
          align-items: center;
      }
      .selector-count {
          padding: 0 0 6px;
          font-size: 10px
      }`}
      <div>
        <div
          className='section-head clearfix section-head-filter'
          style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
        >
          <ul className='filter-row pull-left'>
            {/* <li>
              <SearchField
                label={props.translate('searchMaintenance')}
                type='search'
                placeholder='exp: ID, Name'
                value={search}
                onChange={handleSearchItem}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </li> */}
            <li style={{ paddingRight: 0, paddingTop: 3 }}>
              <Checkbox
                checked={isChecked}
                onChange={handleToggleItems}
                label={props.translate('selectAll')}
              />
            </li>
          </ul>
        </div>
        <div className='selector-count'>
          {' '}
          {Object.keys(list).length}{' '}
          {props.translate('Status Selected')}
        </div>
        <Scrollbar
          disableTracksWidthCompensation={true}
          style={{
            height:
              (props.minHeight ? parseInt(props.minHeight) : props.sidebar ? 15 : 15) *
              (props.rows || 10)
          }}
          scrollerProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props;
              return (
                <div {...restProps} ref={elementRef} id='scrollableDiv' />
              );
            }
          }}
        >
          <ul className='selector-list'>
            {filteredList && filteredList.map(row => (
              <li key={row.key}>
                <label className={list[row.key] ? 'active' : null}>
                  <span className='selector-check'>
                    <Checkbox
                      checked={list[row.key]}
                      value={row}
                      onChange={handleChange}
                    />
                  </span>
                  <span className='selector-label'>{row.name}</span>
                </label>
              </li>
            ))}
          </ul>
        </Scrollbar>
      </div>
    </Style>
  );
};

export default (withLocalize( MaintenanceSelectors));
