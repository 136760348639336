import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  popper: {
    opacity: 1,
  },
  tooltip: {
    margin: 'auto',
    opacity: 1,
    fontSize: 10,
  },
}));

const CustomTooltip = (props) => {
  const classes = useStyles(); 
  return (
    <Tooltip
      {...props}
      classes={{ popper: classes.popper, tooltip: classes.tooltip }}
      placement={props.placement || 'bottom'} 
      interactive={false}
    >
    {props.children}
    </Tooltip>
  );
};

export default CustomTooltip;
