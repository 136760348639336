// First we need to import axios.js
import axios from 'axios';


const instance = axios.create({
    
});


instance.interceptors.request.use(request => {

    return request;
}, error => {
    // return Promise.reject(error);
    console.error(error)
});
instance.interceptors.response.use(response => {
    
 

    if(response.data.status === 'success' || response.data.status === 'ambiguous'){
        return response.data.data;
    }else{
        // return response.data;
        return Promise.reject( response.data);
    }
}, error => {
    return Promise.reject(error);
});


export default instance;


//apis handle inside components
{/*
1. excel/pdf , send mail in report
2. send commands

*/}