import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import TextField from './../common/TextField';
import MenuItem from '../common/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '../common/Button';
import isEqual from 'react-fast-compare';
import { logInUserInfo } from './../../Actions/Users';
import { timeZonesTypes } from './../Attributes/timezone';
import SingleSelect from '../common/SingleSelect';
import {  checkPrivileges, errorHandler } from '../../Helpers';
import Notifications from 'react-notification-system-redux';
import {
  checkUnitsWithStandardType,
  measurementStandard,
  currencies,
} from '../Users/userDataTypes';
import { withLocalize } from 'react-localize-redux';
import instance from '../../axios';

const defaultValues = {
  measurementStandard: '',
  currencyLabel: 'AED',
  'web.liveRouteLength': '',
  'web.selectZoom': '',
  'web.maxZoom': '',
  'ui.hidePositionAttributes': '',
  distanceUnit: '',
  speedUnit: '',
  volumeUnit: '',
  timezone: '',
};

const UserDefault = ({ dispatch, languages, translate, classes, setActiveLanguage, location, logInUser }) => {
  const [form, setForm] = useState({
    gasolinePrice: 0.0,
    dieselPrice: 0.0,
    ethanolPrice: 0.0,
    hybridPrice: 0.0,
    electricPrice: 0.0,
    biodieselPrice: 0.0,
    cngPrice: 0.0,
    lpgPrice: 0.0,
    hydrogenPrice: 0.0,
    attributes: { ...defaultValues },
  });

  const [isVisableAddBtn, setIsVisibleAddBtn] = useState(false);
  useEffect(() => {
    formReset();
  }, []);

  const formReset = () => {
    const fuelPrices = logInUser.fuelPrices?.split(',')?.map((value) => parseFloat(value?.trim())) || [];
    const defaultValuesPrices = fuelPrices.map((price) => isNaN(price) ? 0 : price);

    setForm({
      ...form,
      gasolinePrice: defaultValuesPrices[0],
      dieselPrice: defaultValuesPrices[1],
      ethanolPrice: defaultValuesPrices[2],
      hybridPrice: defaultValuesPrices[3],
      electricPrice: defaultValuesPrices[4],
      biodieselPrice: defaultValuesPrices[5],
      cngPrice: defaultValuesPrices[6],
      lpgPrice: defaultValuesPrices[7],
      hydrogenPrice: defaultValuesPrices[8],
      attributes: {
        ...logInUser.attributes,
      },
    });

    setIsVisibleAddBtn(false);
  };

  const formSubmit = () => {
    // let { logInUser } = props;
    logInUser.attributes = { ...form.attributes };

    const formFuelPricesAsArray = [
      form.gasolinePrice,
      form.dieselPrice,
      form.ethanolPrice,
      form.hybridPrice,
      form.electricPrice,
      form.biodieselPrice,
      form.cngPrice,
      form.lpgPrice,
      form.hydrogenPrice,
    ];

    const fuelPricesFloat = formFuelPricesAsArray.map((price) => {
      if (price === '' || price === undefined || price === null) {
        return 0;
      } else {
        return parseFloat(price).toFixed(1);
      }
    });

    const fuelPricesAsString = fuelPricesFloat?.join(',');

    logInUser.fuelPrices = fuelPricesAsString;
    setIsVisibleAddBtn(false);
    instance({
      url: `api/users/${logInUser.id}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...logInUser,
      },
    })
      .then((user) => {
        dispatch(logInUserInfo(user));
        let lang = user.attributes.lang;
        setActiveLanguage(lang || 'en');

        dispatch(
          Notifications.success({
            message: translate('userDefaultInfoIsUpdated'),
            // message: 'userDefaultInfoIsUpdated',
            autoDismiss: 5,
            location: location,
          })
        );

        setIsVisibleAddBtn(false);
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      });
  };

  const changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    if (!value) {
      if (form.attributes[name]) {
        delete form.attributes[name];
      }
      setForm({
        ...form,
        attributes: {
          ...form.attributes,
        },
      });
      checkRequiredFields();
    } else {
      if (name === 'measurementStandard') {
        let data = checkUnitsWithStandardType(value);
        setForm({
          ...form,
          attributes: {
            ...form.attributes,
            [name]: event.target.type === 'number' ? parseInt(value) : value,
            distanceUnit: data[0].distanceUnit.key,
            speedUnit: data[0].speedUnit.key,
            volumeUnit: data[0].volumeUnit.key,
          },
        });
      } else {
        setForm({
          ...form,
          attributes: {
            ...form.attributes,
            [name]: event.target.type === 'number' ? parseInt(value) : value,
          },
        });
      }
      checkRequiredFields();
    }
  };

  const changeFuelPrice = (name, event) => {
    const { value } = event.target;
    if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      checkRequiredFields();
    }
  };

  const timezone_handleChange = (name, value) => {
    if (!value || value.value === 'None') {
      if (form.attributes[name]) {
        delete form.attributes[name];
      }
      setForm({
        ...form,
        attributes: {
          ...form.attributes,
        },
      });
      checkRequiredFields();
    } else {
      setForm({
        ...form,
        attributes: {
          ...form.attributes,
          [name]: value.value || value.key,
        },
      });
      checkRequiredFields();
    }
  };

  const checkRequiredFields = () => {
    const formAttributesChanged = !isEqual(form.attributes, logInUser.attributes);
    const formChanged = !isEqual(form, logInUser);
    const isFormValueChange = formAttributesChanged || formChanged;

    setIsVisibleAddBtn(isFormValueChange);
  };

  const searchFromZoneList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }

    let result = timeZonesTypes.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    return result.map((item) => ({
      id: item.key,
      key: item.key,
      label: item.label,
      value: item.key,
    }));
  };

  let defaultZones = [];
  timeZonesTypes.map((item) => {
    defaultZones.push({
      id: item.key,
      key: item.key,
      label: item.label,
      value: item.key,
    });
    return null;
  });

  let val = form.attributes && form.attributes.lang ? form.attributes.lang : 'en';
  let l = languages.find((e) => val === e.code);
  let langValue = { ...l, id: l.code, value: l.code, label: l.name };

  let currency =
    form.attributes && form.attributes.currencyLabel
      ? form.attributes.currencyLabel
      : 'AED';
  let c = currencies.find((e) => currency === e.value);
  if (!c) {
    c = { key: 'Select Currency', value: '' };
  }
  let currencyValue = { ...c, id: c.key, value: c.key, label: c.key };

  return (
    <div>
    <Grid container spacing={2}>
      {/* <Grid item xs={12} md={3} sm={6}>
        <TextField
          id='web.maxZoom'
          type='number'
          label={translate('attributeWebMaxZoom')}
          placeholder=''
          value={(form.attributes && form.attributes['web.maxZoom']) || ''}
          onChange={e => changeAttribute2('web.maxZoom', e)}
          variant='outlined'
          margin='dense'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3} sm={6}>
        <TextField
          id='web.selectZoom'
          type='number'
          label={translate('attributeWebSelectZoom')}
          placeholder=''
          value={
            (form.attributes && form.attributes['web.selectZoom']) || ''
          }
          onChange={e => changeAttribute2('web.selectZoom', e)}
          variant='outlined'
          margin='dense'
          fullWidth
        />
      </Grid> */}
      <Grid item xs={12} md={3} sm={6}>
        <SingleSelect
          array={[]}
          async
          selectName='timezone'
          isClearable
          defaultOptions={defaultZones}
          loadOptions={searchFromZoneList}
          label={translate('sharedTimezone')}
          value={
            form.attributes && form.attributes.timezone
              ? timeZonesTypes.find(t => t.key === form.attributes.timezone)
              : ''
          }
          handleChange={timezone_handleChange}
          canAssign={true}
        />
      </Grid>
      <Grid item xs={12} md={3} sm={6}>
        <SingleSelect
          array={languages.map(l => ({...l, id: l.code, key: l.code, value: l.code}))}
          selectName='lang'
          label={translate('loginLanguage')}
          value={(form.attributes && langValue) || ''}
          handleChange={timezone_handleChange}
          canAssign={true}
        />
      </Grid>
      <Grid item xs={12} md={3} sm={6}>
        <TextField
          id='measurementStandard'
          select
          label={translate('measurementStandard')}
          value={
            (form.attributes && form.attributes.measurementStandard) || ''
          }
          onChange={e => changeAttribute2('measurementStandard', e)}
          SelectProps={{
            MenuProps: {
              className: classes&&classes.menu
            }
          }}
          margin='dense'
          fullWidth
        >
          {measurementStandard.map(option => (
            <MenuItem key={option.key} value={option.key}>
              {translate(option.value)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={3} sm={6}>
        <SingleSelect
          array={currencies}
          selectName='currencyLabel'
          label={translate('currencyLabel')}
          value={(form.attributes && form.attributes.currencyLabel && currencyValue) || ''}
          handleChange={timezone_handleChange}
          canAssign={true}
        />
      </Grid>
    </Grid>

    {checkPrivileges('vehicle') ? (
      <>
        <h4 style={{ marginBottom:14,marginTop:14 }}>
          {translate('fuelPrices')}
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='gasolinePrice'
              label={translate('gasolinePrice/litre')}
              placeholder=''
              value={(form.gasolinePrice&&form.gasolinePrice)||""}
              onChange={(e)=>changeFuelPrice("gasolinePrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='dieselPrice'
              label={translate('diselPrice/litre')}
              placeholder=''
              value={(form.dieselPrice&&form.dieselPrice)||""}
              onChange={(e)=>changeFuelPrice("dieselPrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ethanolPrice'
              label={translate('ethanolPrice/litre')}
              placeholder=''
              value={(form.ethanolPrice&&form.ethanolPrice)||""}
              onChange={(e)=>changeFuelPrice("ethanolPrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='hybridPrice'
              label={translate('hybridPrice')}
              placeholder=''
              value={(form.hybridPrice&&form.hybridPrice)||""}
              onChange={(e)=>changeFuelPrice("hybridPrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='electricPrice'
              label={translate('electricPrice/unit')}
              placeholder=''
              value={(form.electricPrice&&form.electricPrice)||""}
              onChange={(e)=>changeFuelPrice("electricPrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='biodieselPrice'
              label={translate('biodieselPrice/litre')}
              placeholder=''
              value={(form.biodieselPrice&&form.biodieselPrice)||""}
              onChange={(e)=>changeFuelPrice("biodieselPrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='cngPrice'
              label={translate('cngPrice/kg')}
              placeholder=''
              value={(form.cngPrice&&form.cngPrice)||""}
              onChange={(e)=>changeFuelPrice("cngPrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='lpgPrice'
              label={translate('lpgPrice/kg')}
              placeholder=''
              value={(form.lpgPrice&&form.lpgPrice)||""}
              onChange={(e)=>changeFuelPrice("lpgPrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='hydrogenPrice'
              label={translate('hydrogenPrice/kg')}
              placeholder=''
              value={(form.hydrogenPrice&&form.hydrogenPrice)||""}
              onChange={(e)=>changeFuelPrice("hydrogenPrice",e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>

        </Grid>
      </>
    ) : null}

    {checkPrivileges('vehicle') ? (
      <>
        <h4 style={{ marginBottom:14,marginTop:14 }}>
          {translate('SetVehicleTagLabel')}
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='vt1'
              label={translate('tag_1')}
              placeholder=''
              value={(form.attributes && form.attributes['vt1']) || ''}
              onChange={e => changeAttribute2('vt1', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='vt2'
              label={translate('tag_2')}
              placeholder=''
              value={(form.attributes && form.attributes['vt2']) || ''}
              onChange={e => changeAttribute2('vt2', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='vt3'
              label={translate('tag_3')}
              placeholder=''
              value={(form.attributes && form.attributes['vt3']) || ''}
              onChange={e => changeAttribute2('vt3', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='vt4'
              label={translate('tag_4')}
              placeholder=''
              value={(form.attributes && form.attributes['vt4']) || ''}
              onChange={e => changeAttribute2('vt4', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='vt5'
              label={translate('tag_5')}
              placeholder=''
              value={(form.attributes && form.attributes['vt5']) || ''}
              onChange={e => changeAttribute2('vt5', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
        </Grid>
      </>
    ) : null}
    {checkPrivileges('driver') ? (
      <>
        <h4 style={{ marginBottom:14,marginTop:14 }}>
          {translate('SetStaffTagLabel')}
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='st1'
              label={translate('tag_1')}
              placeholder=''
              value={(form.attributes && form.attributes['st1']) || ''}
              onChange={e => changeAttribute2('st1', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='st2'
              label={translate('tag_2')}
              placeholder=''
              value={(form.attributes && form.attributes['st2']) || ''}
              onChange={e => changeAttribute2('st2', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='st3'
              label={translate('tag_3')}
              placeholder=''
              value={(form.attributes && form.attributes['st3']) || ''}
              onChange={e => changeAttribute2('st3', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='st4'
              label={translate('tag_4')}
              placeholder=''
              value={(form.attributes && form.attributes['st4']) || ''}
              onChange={e => changeAttribute2('st4', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='st5'
              label={translate('tag_5')}
              placeholder=''
              value={(form.attributes && form.attributes['st5']) || ''}
              onChange={e => changeAttribute2('st5', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
        </Grid>
      </>
    ) : null}

{checkPrivileges('device') ? (
      <>
        <h4 style={{marginBottom:14,marginTop:14 }}>
          {translate('SetUnitsTagLabel')}
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut1'
              label={translate('tag_1')}
              placeholder=''
              value={(form.attributes && form.attributes['ut1']) || ''}
              onChange={e => changeAttribute2('ut1', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut2'
              label={translate('tag_2')}
              placeholder=''
              value={(form.attributes && form.attributes['ut2']) || ''}
              onChange={e => changeAttribute2('ut2', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut3'
              label={translate('tag_3')}
              placeholder=''
              value={(form.attributes && form.attributes['ut3']) || ''}
              onChange={e => changeAttribute2('ut3', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut4'
              label={translate('tag_4')}
              placeholder=''
              value={(form.attributes && form.attributes['ut4']) || ''}
              onChange={e => changeAttribute2('ut4', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut5'
              label={translate('tag_5')}
              placeholder=''
              value={(form.attributes && form.attributes['ut5']) || ''}
              onChange={e => changeAttribute2('ut5', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
        </Grid>
      </>
    ) : null}
     {checkPrivileges('binder') ? (
      <>
        <h4 style={{ marginBottom:14,marginTop:14  }}>
          {translate('SetBindersTagLabel')}
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut1'
              label={translate('tag_1')}
              placeholder=''
              value={(form.attributes && form.attributes['bt1']) || ''}
              onChange={e => changeAttribute2('bt1', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut2'
              label={translate('tag_2')}
              placeholder=''
              value={(form.attributes && form.attributes['bt2']) || ''}
              onChange={e => changeAttribute2('bt2', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut3'
              label={translate('tag_3')}
              placeholder=''
              value={(form.attributes && form.attributes['bt3']) || ''}
              onChange={e => changeAttribute2('bt3', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut4'
              label={translate('tag_4')}
              placeholder=''
              value={(form.attributes && form.attributes['bt4']) || ''}
              onChange={e => changeAttribute2('bt4', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='ut5'
              label={translate('tag_5')}
              placeholder=''
              value={(form.attributes && form.attributes['bt5']) || ''}
              onChange={e => changeAttribute2('bt5', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
        </Grid>
      </>
    ) : null}
     {checkPrivileges('service') ? (
      <>
        <h4 style={{ marginBottom:14,marginTop:14}}>
          {translate('SetServicesTagLabel')}
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='et1'
              label={translate('tag_1')}
              placeholder=''
              value={(form.attributes && form.attributes['et1']) || ''}
              onChange={e => changeAttribute2('et1', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='et2'
              label={translate('tag_2')}
              placeholder=''
              value={(form.attributes && form.attributes['et2']) || ''}
              onChange={e => changeAttribute2('et2', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='et3'
              label={translate('tag_3')}
              placeholder=''
              value={(form.attributes && form.attributes['et3']) || ''}
              onChange={e => changeAttribute2('et3', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
         
        </Grid>
      </>
    ) : null}

    <br />
    {checkPrivileges('userUpdate') && (
      <Fragment>
        <Button
          variant='contained'
          onClick={formReset}
          style={{ marginLeft: 8, marginRight: 8 }}
        >
          {' '}
          {translate('resetButton')}
        </Button>
        <Button
          variant='contained'
          disabled={!isVisableAddBtn}
          onClick={() => formSubmit()}
        >
          {' '}
          {translate('sharedSave')}
        </Button>
      </Fragment>
    )}
  </div>
  );
};


const enhance =   connect()

export default enhance(withLocalize(UserDefault))

// const mapStateToProps = (state) => ({
//   // languages: state.localize.languages,
//   // logInUser: state.Users.logInUser,
// });

// export default connect(mapStateToProps)(withLocalize(UserDefault));
