import React from 'react'
import TextField from '../../../Components/common/TextField'
import Grid from '@mui/material/Grid'
import Button from '../../../Components/common/Button'
import Table from '../../../Components/common/TableMultiCheckbox'
import Strings from './../../../lang/en.json'
import SingleSelect from '../../../Components/common/SingleSelect'
import { checkPrivileges } from '../../../Helpers'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import Checkbox from '../../../Components/common/Checkbox'
import { SettingForm } from '../../Maintenance/SettingForm'

const Maintenance=(props)=>{

  const maintenance_handleChange =(name, value)=> {
    if (props.maintenancesHandle) {
      props.maintenancesHandle(name, value)
    }
  }
  const periodLabel =
  props.maintenanceForm.type === 'hours'
    ? 'hours'
    : props.maintenanceForm.type === 'date'
    ? 'days'
    : 'km'



    return (
      <Grid container style={{ height: '100%' }}>
        <Grid item sm={props.visableCreateMaintenance ? 7 : 12} xs={12}>
          <Table
            insideDialog
            createButton={
              checkPrivileges('maintenanceCreate') && (
                <div style={{ marginLeft: 10 }}>
                  <Button onClick={() => props.onCreateNewMaintenance()}>
                    {props.translate('Create')}
                  </Button>
                </div>
              )
            }
            title={props.translate('sharedMaintenance')}
            disableHead
            showCheckbox= {props.isAllowed}
            showGroupAccess={props.showGroupAccess}
            checkHandleChange={props.maintenance_Selection}
            rows={props.maintenanceData}
            isEditable
            canRemove={props.canAssign}
            canAssign={props.canRemove}
            canUpdate={checkPrivileges('maintenanceUpdate')}
            canDelete={checkPrivileges('maintenanceDelete')}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
            className='custom-table2'
            rowsPerPage={props.rowsPerPage}
            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            selectAll={props.selectAll}
            searchable
            rowDefinition={[
              {
                id: 'name',
                numeric: false,
                disablePadding: false,
                label: props.translate('sharedName')
              },
              {
                id: 'deviceName',
                numeric: false,
                disablePadding: false,
                label: props.translate('sharedDevice')
              },
              {
                id: 'attributes.mileage',
                numeric: false,
                disablePadding: false,
                label: props.translate('targetValue'),
                translate: true,
                colspan: 3
              },
              {
                id: 'attributes.repeat',
                numeric: false,
                disablePadding: false,
                label: props.translate('repeat')
              },
              {
                id: 'cost',
                numeric: false,
                disablePadding: false,
                label: props.translate('cost')
              }
              // {
              //   id: 'progress',
              //   numeric: false,
              //   disablePadding: false,
              //   label: props.translate('progress')
              // }
            ]}
          />
        </Grid>
        {props.visableCreateMaintenance && (
          <Grid item sm={5} xs={12}>
            <div
              style={{
                padding: '1px 20px',
                borderLeft: '1px solid ' + props.themecolors&&props.themecolors.themeLightColor,
                height: '100%'
              }}
            >
              <h4>{props.translate('sharedMaintenance')}</h4>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id='name'
                    margin='dense'
                    label={props.translate('sharedName')}
                    variant='outlined'
                    fullWidth
                    value={props.maintenanceForm.name}
                    onChange={props.maintenance_handleChange('name')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SingleSelect
                    array={postionAttributes}
                    selectName='type'
                    label={props.translate('sharedType')}
                    value={
                      props.maintenanceForm.type
                        ? {
                            key: props.maintenanceForm.type,
                            id: props.maintenanceForm.type,
                            value: props.maintenanceForm.type,
                            label: props.translate(
                              props.maintenanceForm.type
                            )
                          }
                        : ''
                    }
                    handleChange={maintenance_handleChange}
                    canRemove={
                      checkPrivileges('maintenanceCreate') ||
                      checkPrivileges('maintenanceUpdate')
                    }
                    canAssign={
                      checkPrivileges('maintenanceCreate') ||
                      checkPrivileges('maintenanceUpdate')
                    }
                  />
                </Grid>
                {props.maintenanceForm.type === 'date' ? (
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        error={false}
                        margin='dense'
                        label={props.translate('startDate')}
                        variant='outlined'
                        fullWidth
                        format='dd/MM/yyyy'
                        value={
                          props.maintenanceForm.attributes &&
                          props.maintenanceForm.attributes.startDate
                            ? new Date(
                                `${props.maintenanceForm.attributes.startDate}`
                              ).toString()
                            : null
                        }
                        onChange={props.maintenance_handleChange_attr(
                          'startDate'
                        )}
                        InputProps={{
                          classes: {
                            root: 'theme-cssOutlinedInput',
                            error: 'theme-input-error',
                            input: 'theme-input',
                            focused: 'theme-cssFocused',
                            notchedOutline: 'theme-notchedOutline'
                          }
                        }}
                        InputLabelProps={{
                          ...props.InputLabelProps,
                          classes: {
                            root: 'theme-label',
                            focused: 'theme-label-focused',
                            shrink: 'theme-label-shrink',
                            error: 'theme-label-error'
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <TextField
                      id='start'
                      type='number'
                      margin='dense'
                      label={props.translate('maintenanceStart')}
                      variant='outlined'
                      fullWidth
                      value={props.maintenanceForm.start}
                      onChange={props.maintenance_handleChange('start')}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    id='period'
                    type='number'
                    margin='dense'
                    label={
                      props.translate('maintenancePeriod') +
                      ' (' +
                      periodLabel +
                      ')'
                    }
                    variant='outlined'
                    fullWidth
                    value={props.maintenanceForm.period}
                    onChange={props.maintenance_handleChange('period')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Checkbox
                    checked={props.maintenanceForm.attributes.repeat}
                    onChange={props.maintenance_handleChange_attr(
                      'repeat'
                    )}
                    color='primary'
                    label={props.translate('repeat')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Checkbox
                    checked={props.maintenanceForm.attributes.status}
                    onChange={props.maintenance_handleChange_attr(
                      'status'
                    )}
                    color='primary'
                    label={props.translate('status')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SettingForm fullWidth deviceId={props.selecteddeviceId} maintenanceForm={props.maintenanceForm} form={props.maintenanceForm.attributes} mode={props.isHaveAccess === 'maintenanceCreate' ? 'create' : 'update'} saveToParent={props.saveToParent} dispatch={props.dispatch} translate={props.translate} />
                </Grid>
              </Grid>
              <div style={{ textAlign: 'right', marginTop: 10 }}>
                <Button
                  size='small'
                  style={{ marginRight: 15 }}
                  className='button-white'
                  onClick={() => props.onCloseC_Att_Modal()}
                >
                  {' '}
                  {props.translate('sharedCancel')}{' '}
                </Button>
                {checkPrivileges(props.isHaveAccess) && (
                  <Button
                    disabled={!props.isAddVisableBtn}
                    size='small'
                    onClick={() => props.addMaintenance()}
                  >
                    {' '}
                    {props.translate(props.addButtonStatus)}
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    )
}
export default Maintenance

const postionAttributes = [
  {
    key: 'totalDistance',
    name: Strings.totalDistance,
    valueType: 'number',
    dataType: 'distance'
  },
  {
    key: 'hours',
    name: Strings.hours,
    valueType: 'number',
    dataType: 'hours'
  },
  {
    key: 'date',
    name: Strings.date,
    valueType: 'number',
    dataType: 'hours'
  }
]
