import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Layout from '../../Layout'
import { ShowReports } from '../../Components/Trash/'
import { logInUserInfo } from '../../Actions/Users'
import instance from '../../axios'

const SystemLogs = (props) => {
  const { logInUser, themecolors, setActiveLanguage, dispatch, history } = props

  useEffect(() => {
    if (!logInUser) {
      instance({
        url: `/api/session`,
        method: 'GET',
        headers: { Accept: 'application/json; odata=verbose' }
      })
      .then(response => {
        setActiveLanguage(response.attributes.lang || 'en')
        dispatch(logInUserInfo(response))
      })
      .catch(() => {
        history.push('/logout')
      })
    }
  }, [logInUser, setActiveLanguage, dispatch, history])

  if (logInUser) {
    return (
      <Layout
        classFromChildren='has-padding'
        noSidebar
        {...props}
      >
        <div
          style={{
            background: themecolors.backgroundColor,
            color: themecolors.textColor,
            borderRadius: 6,
            paddingTop: 16,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <ShowReports {...props} />
        </div>
      </Layout>
    )
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themeColors
})

export default connect(mapStateToProps)(withLocalize(SystemLogs))
