import React, { useState } from 'react'
import Button from '../../../Components/common/Button'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { checkPrivileges } from '../../../Helpers'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
import MaintenanceFilterModal from '../Components/MaintenanceFilterModal'
import Tooltip from '@mui/material/Tooltip'
import { Grid } from '@mui/material'
import Card from '../../../Pages/Dashboard/components/Card/Card'
import Skeleton from '@mui/lab/Skeleton'
import FilterIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import MaintenanceFilter from '../../../Components/common/MaintenanceFilter'


const Maintenance = (props) => {

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}  
          .infinite-scroll-component .event-list-row {
            border-bottom: 1px solid ${props.themecolors.textColor}22
          }
          .infinite-scroll-component .event-list-row.active,
          .infinite-scroll-component .event-list-row:hover {
              background-color: ${props.themecolors&&props.themecolors.themeLightColor}88;
          } 
                
        `}
        <div className='fms-sidebar sidebar-bg-layer'>
        <div style={{ padding: 13 }}>
       <h3
          style={{ display: 'flex', alignItems: 'center', margin: 0 }}
        >
          <span>{props.translate('sharedMaintenance')}</span>

          {checkPrivileges('notification') ? (
            <Tooltip
                title={props.translate('serviceFilter')}
              >
                <span style={{ marginLeft: 'auto'}}>
                <Button
                style={{minWidth: 'inherit' }}
                  color='inherit'
                  onClick={props.toggleFilterForm}
                >
                  {!props.showStats ? <FilterIcon style={{ fontSize: 18 }} /> : <CloseIcon style={{ fontSize: 18 }} />}
                </Button></span>
              </Tooltip>
            ) : null}
          </h3>
        </div>
          {checkPrivileges('maintenance') && !props.showStats && (
            <div>
              <div className='section-head clearfix section-head-filter'>
                <ul className='list-view with-padding-right geofence-sidebar-list'>
                  <Scrollbar
                    disableTracksWidthCompensation={true}
                    scrollerProps={{
                      renderer: props => {
                        const { elementRef, ...restProps } = props
                        return (
                          <div
                            {...restProps}
                            ref={elementRef}
                            id='scrollableDiv'
                          />
                        )
                      }
                    }}
                  >
                    {props.itemPagination ? (
                      <InfiniteScroll
                        dataLength={props.itemPagination.items.length}
                        // next={props.fetchMoreItems}
                        // hasMore={props.itemPagination.hasNext}
                        loader={<Loader defaultStyle={true} imgSize={50} />}
                        scrollableTarget='scrollableDiv'
                        // endMessage={props.endMessage}
                      >
                        <ListRow
                          analyticData={props.analyticData}
                          translate={props.translate}
                          themecolors={props.themecolors}
                          currencyLabel={props.logInUser&&props.logInUser.attributes&&props.logInUser.attributes.currencyLabel}
                        />
                      </InfiniteScroll>
                    ) : null}
                  </Scrollbar>
                </ul>
              </div>
            </div>
          )}
          {props.showStats && (
            <div>
              <div className='section-head clearfix section-head-filter' style={{padding:'0 15px'}}>
              {
                <MaintenanceFilter
                  {...props}
                  formSubmit={props.submitSideBarSearch}
                  sidebar
                  loader={props.loadingSidebar}
                />
              }
              </div>
            </div>
          )}
        </div>
      </Style>
    )
  }

const ListRow1 = (props) => {

  const [state,setState] = useState({
    deviceSelector: false
  })

 const openDeviceSelector = (key, name, total, type) => {
    setState(preState=>({
      ...preState,
      deviceSelector: !state.deviceSelector,
      deviceSelectorKey: name,
      filterType: key,
      totalDevices: total&&total.count,
      statusType: type
    }))
  }

    const {completed,required,scheduled,expired,reminder} = props && props.analyticData || {}
    return (
      <>
      <Grid container spacing={12}>
        <Grid item xs={12}>
          <Card themecolors={props.themecolors}>
            <div style={{ marginTop: 0 }}>
              {!state.deviceSelector ? (<div style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <h3
                      style={{
                        fontSize: 22,
                        fontWeight: 600,
                        // textAlign: 'center',
                        marginBottom: 5,
                        marginTop: 0
                      }}
                    >
                      {(scheduled&&scheduled.count || 0)+(completed&&completed.count || 0)+(required&&required.count || 0)+(expired&&expired.count || 0)+(reminder&&reminder.count || 0)}
                      {" "+props.translate('total')}
                    </h3>
              </div>) : 
                <>
                <h3
                  style={{
                    fontSize: 18,
                    display: 'flex',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginBottom: 8,
                    marginTop: 8,
                    justifyContent: 'space-between'
                  }}
                >
                  <span>
                    {props.translate(state.deviceSelectorKey)} (
                    {state.totalDevices})
                  </span>
                  <Button onClick={() => openDeviceSelector(null)}>
                    {props.translate('sharedBack')}
                  </Button>
                </h3>
              </>
              }
              {!state.deviceSelector &&(
                    <div className='driver-state-wrap'>
                      <div className='driver-state-container'>
                        {checkPrivileges('maintenance') && (<div
                          className='driver-state2 not-register hover'
                          onClick={() =>
                            openDeviceSelector(
                              'reminder',
                              props.translate('reminder'),
                              reminder
                            )
                          }
                        >
                          <h3>{reminder && reminder.count || 0}</h3>
                          <p>{props.translate('reminder')}</p>
                        </div>)}

                        {checkPrivileges('maintenance') && (<div
                          className='driver-state2 not-register hover'
                          style={{background:'#1919FF'}}
                          onClick={() =>
                            openDeviceSelector(
                              'due',
                              props.translate('scheduled'),
                              scheduled,
                              'scheduled'
                            )
                          }
                        >
                          <h3>{scheduled && scheduled.count || 0}</h3>
                          <p>{props.translate('scheduled')}</p>
                        </div>)}
                      {checkPrivileges('service') &&(<>
                        <div
                          className='driver-state2  not-update hover'
                          style={{background: '#F7A35C'}}
                          onClick={() =>
                            openDeviceSelector(
                              'due',
                              props.translate('due'),
                              required,
                              'required'
                            )
                          }
                        >
                          <h3>{required && required.count || 0}</h3>
                          <p>{props.translate('due')}</p>
                        </div>
                        <div
                          className='driver-state2 online hover'
                          onClick={() =>
                            openDeviceSelector(
                              'history',
                              props.translate('completed'),
                              completed
                            )
                          }
                        >
                          <h3>{completed && completed.count || 0}</h3>
                          <p>
                            {props.translate('completed')}
                          </p>
                        </div>
                        <div
                          className='driver-state2 offline hover'
                          onClick={() =>
                            openDeviceSelector(
                              'due',
                              props.translate('overdue'),
                              expired,
                              'expired'
                            )
                          }
                        >
                          <h3>{expired && expired.count || 0}</h3>
                          <p>
                            {props.translate('overdue')}
                          </p>
                        </div>
                      </>)}
                      </div>
                    </div>
              )}
              {state.deviceSelector ? (
                  <div
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingBottom: 10
                    }}
                  >
                    <MaintenanceFilterModal readOnly rows={8} filterType={state.filterType} statusType={state.statusType || ''}/>
                  </div>
                ) : null}
            </div>
          </Card>
        </Grid>
      </Grid>

                    
          <Grid item xs={12} >
            <div
              style={{
                padding: 15,
                background: props.themecolors.backgroundColor,
                color: props.themecolors.textColor,
                borderRadius: 6,
                height: '100%'
              }}
            >
                <h3
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    textAlign: 'left',
                    marginBottom: 15,
                    marginTop: 20
                  }}
                >
                  {props.translate('expenses')+" "}
                  {(scheduled&&scheduled.cost || 0)+(completed&&completed.cost || 0)+(required&&required.cost || 0)+(expired&&expired.cost || 0)}
                  {" "+(props.currencyLabel||'')}
                </h3>

                <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height: 20 * 8
                      }}
                    >
                   {props.analyticData ? (
                    <MotionList
                      data={props.analyticData}
                      // showSelectedUnits={openMotionSelector}
                      translate={props.translate}
                      currencyLabel={props.currencyLabel}
                    />
                  ) : (
                    <BarGraphSkeleton2 />
                  )}
                  </Scrollbar>
            </div>
          </Grid>
        </>
    )
  }

const BarGraphSkeleton2 = () => {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
    </div>
  )
}
const MotionList = ({ data, className, translate,currencyLabel }) => {
  if(data.completed){
    const html = 
    <div>
      <div
        className={'motion-status-bar hover ' + className}
      >
        <span style={{ background: '#566374' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'>{translate('reminder')+" "+translate('work')}</span>
        <span className='motion-status-count'>{data.reminder.cost?.toLocaleString() +" "+(currencyLabel||'')}</span>
      </div>
      
      <div
        className={'motion-status-bar hover ' + className}
      >
        <span style={{ background: '#1919FF' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'>{translate('schedule')+" "+translate('work')}</span>
        <span className='motion-status-count'>{data.scheduled.cost?.toLocaleString()+" "+(currencyLabel||'')}</span>
      </div>

      {checkPrivileges('service') && (<><div
        className={'motion-status-bar hover ' + className}
      >
        <span style={{ background: '#28a745' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'>{translate('completed')+" "+translate('work')}</span>
        <span className='motion-status-count'>{data.completed.cost?.toLocaleString()+" "+(currencyLabel||'')}</span>
      </div>
      <div
        className={'motion-status-bar hover ' + className}
      >
        <span style={{ background: '#F7A35C' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'> {translate('due')+" "+translate('work')}</span>
        <span className='motion-status-count'>{data.required.cost?.toLocaleString()+" "+(currencyLabel||'')}</span>
      </div></>)}
    </div>
  return <div>{html}</div>
  }
  return null
  
}
const mapState = state => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withLocalize(ListRow1))
export default mapStateToProps(Maintenance)