import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid'
import ListItemText from '@mui/material/ListItemText'
import { connect } from 'react-redux'
import { userAttributesTypes } from './../Attributes/AttributesArrays'
import { withLocalize } from 'react-localize-redux'
import moment from 'moment'
// import Style from 'style-it'
import PinIcon from './../../assets/nav/pin.svg'
import VehicleIcon from './../../assets/nav/vehicle.svg'
import UnitsIcon from './../../assets/nav/units.svg'
import { ReactComponent as  LicensePlateIcon} from './../../assets/nav/license-plate.svg'
import Calendar from './../../assets/nav/calendar.svg'
import { ReactComponent as Vehicle } from './../../assets/nav/commute.svg'
import { setAttributeFormat } from '../../Helpers'

import TimeAgo from 'react-timeago'
import HtmlParser from 'react-html-parser'
import { ReactComponent as FileCopyIcon } from './../../assets/monitoring/copy.svg'
import Notifications from 'react-notification-system-redux'
import Scrollbar from 'react-scrollbars-custom'

// const styles = theme => ({
//   root: {
//     flexGrow: 1
//   },
//   paper: {
//     position: 'relative',
//     background: 'none',
//     color: 'inherit'
//   },
//   nested: {
//     paddingLeft: theme.spacing(1) * 4
//   }
// })

// class editItem extends React.Component {
  const editItem = (props) => {

  // constructor (props) {
  //   super(props)
  //   state = {
  //     selectedItem: []
  //   }
  //   formatObject = formatObject.bind(this)
  // }
  const [state, setState] = useState({
    selectedItem: []
  })


  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      selectedItem: formatObject(props)
    }));
  }, []); // Empty dependency array ensures this runs only once on mount
  
  useEffect(() => {
    if (props) {
      setState(prevState => ({
        ...prevState,
        selectedItem: formatObject(props)
      }));
    }
  }, [props]); // Add dependencies for props that you want to watch
  
  
  // const setIcons = data => {
  //   let logo
  //   if (data.category) {
  //     props.devicesIcons.map(item => {
  //       let res = item.includes(data.category)
  //       if (res) {
  //         logo = item
  //       }
  //       return null
  //     })
  //   } else {
  //     props.devicesIcons.map(item => {
  //       let res = item.includes('default')
  //       if (res) {
  //         logo = item
  //       }
  //       return null
  //     })
  //   }
  //   return logo
  // }
  const drawValue = (array) =>{
    let htmlObject = []
    let html = null
    let mainKey = ''

    if (array.length) {
      html = array.map((item, key) => {
        switch (item.key) {
          case 'name':
            mainKey = props.translate('sharedName')
            break
          case 'login':
            mainKey = props.translate('loginTitle')
            break
          case 'email':
            mainKey = props.translate('userEmail')
            break
          case 'phone':
            mainKey = props.translate('sharedPhone')
            break
          case 'readonly':
            mainKey = props.translate('serverReadonly')
            break
          case 'administrator':
            mainKey = props.translate('userAdmin')
            break
          case 'map':
            mainKey = props.translate('mapTitle')
            break
          case 'twelveHourFormat':
            mainKey = props.translate('settingsTwelveHourFormat')
            break
          case 'coordinateFormat':
            mainKey = props.translate('settingsCoordinateFormat')
            break
          case 'disabled':
            mainKey = props.translate('sharedDisabled')
            break
          case 'expirationTime':
            mainKey = props.translate('userExpirationTime')
            break
          case 'deviceReadonly':
            mainKey = props.translate('userDeviceReadonly')
            break
          case 'token':
            mainKey = props.translate('userToken')
            break
          case 'limitCommands':
            mainKey = props.translate('userLimitCommands')
            break
          case 'poiLayer':
            mainKey = props.translate('mapPoiLayer')
            break
          case 'password':
            mainKey = props.translate('attributeDevicePassword')
            break
          case 'attributes':
            mainKey = props.translate('sharedAttributes')
            break
          case 'trackers':
            mainKey = props.translate('sharedDevice')
            break
          default:
            mainKey = props.translate(item.key)
            break
        }
        if (typeof item.value === 'string' || item.value === null) {
          if (item.key !== 'data') {
            return (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <ListItemText
                  primary={item.value || 'null'}
                  secondary={mainKey}
                ></ListItemText>
              </Grid>
            )
          }
        }
        if (typeof item.value === 'boolean') {
          return (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <ListItemText
                primary={item.value ? 'Yes' : 'No'}
                secondary={mainKey}
              ></ListItemText>
            </Grid>
          )
        } else if (typeof item.value === 'object') {
          if (item.key === 'trackers') {
            let h = null
            if (item.value.length) {
              h = item.value.map(k => {
                return (
                  <p key={k.id}>
                    {
                      <img
                        className='row-image'
                        src={
                          '/assets/category/default/' +
                          (k.category || 'default') +
                          '.svg'
                        }
                        alt={k.name}
                      />
                    }
                    <span className='row-label'>{k.name}</span>
                    <span className='row-value'>{k.uniqueId}</span>
                  </p>
                )
              })

              let head = (
                <Grid item xs={12} md={6} key={item.key}>
                  <Paper
                    style={{
                      padding: 16,
                      background: props.themecolors&&props.themecolors.themeLightColor,
                      color: props.themecolors.textColor,
                      boxShadow: 'none'
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        margin: '0 0 15px'
                      }}
                    >
                      {props.translate('deviceTitle')}
                    </p>
                    <ul className='trackers-list clearfix'>{h}</ul>
                  </Paper>
                </Grid>
              )
              htmlObject.push(head)
              return null
            }
          } else {
            let o = Object.keys(item.value)
            let h = null
            if (o.length) {
              let hasData = false
              h = o.map(k => {
                hasData = true
                const selectedTrans = userAttributesTypes.filter(
                  el => el.key === k
                )
                if (typeof item.value[k] === 'boolean') {
                  return (
                    <p key={k}>
                      <span className='row-label'>
                        {selectedTrans && selectedTrans[0]
                          ? props.translate(selectedTrans[0].name)
                          : k}
                      </span>
                      <span className='row-value'>
                        {item.value[k] ? 'Yes' : 'No'}
                      </span>
                    </p>
                  )
                } else {
                  let kk = ''
                  if (
                    k === 'tag_1' &&
                    props.logInUsers &&
                    props.logInUsers.attributes &&
                    props.logInUsers.attributes.vt1
                  ) {
                    kk = props.logInUsers.attributes.vt1
                  }
                  if (
                    k === 'tag_2' &&
                    props.logInUsers &&
                    props.logInUsers.attributes &&
                    props.logInUsers.attributes.vt2
                  ) {
                    kk = props.logInUsers.attributes.vt2
                  }
                  if (
                    k === 'tag_3' &&
                    props.logInUsers &&
                    props.logInUsers.attributes &&
                    props.logInUsers.attributes.vt3
                  ) {
                    kk = props.logInUsers.attributes.vt3
                  }
                  if (
                    k === 'tag_4' &&
                    props.logInUsers &&
                    props.logInUsers.attributes &&
                    props.logInUsers.attributes.vt4
                  ) {
                    kk = props.logInUsers.attributes.vt4
                  }
                  if (
                    k === 'tag_5' &&
                    props.logInUsers &&
                    props.logInUsers.attributes &&
                    props.logInUsers.attributes.vt5
                  ) {
                    kk = props.logInUsers.attributes.vt5
                  }
                  return (
                    <p key={k}>
                      <span className='row-label'>
                        {kk
                          ? kk
                          : selectedTrans && selectedTrans[0]
                          ? props.translate(selectedTrans[0].name)
                          : props.translate(k)}:
                      </span>
                      <span className='row-value'>{item.value[k]}</span>
                    </p>
                  )
                }
              })
              let head = (
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      margin: '15px 0'
                    }}
                    className='with-border'
                  >
                    {mainKey}
                  </p>
                  <div className='clearfix'>
                    {hasData ? (
                      h
                    ) : (
                      <div style={{ padding: 16, background: '#fff' }}>
                        {props.translate('notFound')}
                      </div>
                    )}
                  </div>
                </div>
              )
              htmlObject.push(head)
              return null
            }
            return null
          }
        } else {
          return null
        }
        return null
      })
    }

    return { html, htmlObject }
  }

  const formatObject  =(props) =>{
    if (props.selectedItem) {
      let normalValues = []
      let objValues = []
      Object.keys(props.selectedItem).map((itm) => {
        if (
          typeof props.selectedItem[itm] !== 'object' ||
          props.selectedItem[itm] === null
        ) {
          normalValues.push({ key: itm, value: props.selectedItem[itm] })
        } else {
          objValues.push({ key: itm, value: props.selectedItem[itm] })
        }
        return null
      })

      return normalValues.concat(objValues)
    } else {
      return []
    }
  }

  const copyToClipboard = obj => {
    const el = document.createElement('textarea')
    el.value = `${obj.latitude},${obj.longitude}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    props.dispatch(
      Notifications.success({
        message: 'Copied to clipboard!',
        autoDismiss: 10
      })
    )
  }

  // render () {
    const { classes } = props
    const newSelected = {}
    state.selectedItem.map(e => (newSelected[e.key] = e.value))
    return (
      <>
         {/* <div 
        className={classes&&classes.root +  ' driver-modal-fixed'} 
        style={{
            // background:"blue",
            bottom:"0", right:"0",
            position:"absolute",
            minHeight:"80%",
            height:"400px", width:"500px",

              background: props.themecolors.backgroundColor,
              color: props.themecolors.textColor,

            }}></div> */}
      <style>
        {`
      .material-icons {vertical-align: middle; color: inherit}
      .row-label {
        min-width: inherit;
        display:table-cell;
        width: 105px;
        vertical-align: top
      }
      p {
        margin: 8px 0;
        word-break: break-word;
        display: table;
      }
      .row-label-inline {
        margin-right: 8px !important;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
      }
      .license-card {}
      .license-card-img {
        float: left;
        width: 100px;
        text-align: center;
      }
      .license-card-detail {
        margin-left: 110px;

      }
      .attribute-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: start;
        flex-wrap: wrap;
      }
      .attribute-list li {
        background: ${props.themecolors.backgroundColor};
        color: ${props.themecolors.textColor};
        padding: 8px 16px;
        border-radius: 6px;
        float: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .attribute-list .row-label-inline,
      .attribute-list .row-label,
      .attribute-list .row-value {
        font-size: 12px;
        margin: 4px 0
      }
      .attribute-list .row-label-inline {
        margin-right: auto
      }
      .trackeres-list {
        list-style: none;
        margin: 0 0 0 -16px;
        padding: 0;
        text-align: center;
      }
      .trackeres-list li {
        box-sizing: border-box;
        background: #fff;
        width: calc(50% - 16px);
        float: left;
        margin-left: 16px;
        margin-bottom: 16px;
        padding: 16px 16px 30px;
      }
      .trackeres-list .row-label {
        color: #333;
        font-size: 16px;
        font-weight: 700;
        display: block;
      }
      .row-image {
        width: 120px;
        height: 120px;
      }
      @media (max-width: 767px) {
        .license-card-img {
          float: none;
          width: auto;
        }
        .license-card-detail {
          margin-left: 0;
        }
        
      }
      `}  </style>
      
        <div className={classes&&classes.root +  ' driver-modal-fixed'} style={{
              background: props.themecolors.backgroundColor,
              color: props.themecolors.textColor,
              bottom:"57px", right:"60px", top:"53px",width:"306px",borderRadius:"4px", 
              boxShadow:"0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
              position:"fixed",
              // minHeight:"80%",
              // height:"400px", 
            }}>
        <Scrollbar disableTracksWidthCompensation contentProps={{style: {padding: 16}}}>
          <div
            // className={classes&&classes.paper}
            // elevation={0}
            
          >
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                  {
                    <div
                      style={{
                        margin: '5px',
                        marginBottom: '15px',
                        display: 'flex',
                        width: '100%'
                      }}
                    >
                      <Vehicle width={40} height={40} fill='currentColor' />
                      <div style={{ paddingLeft: 15, flex: 1, minWidth: 0 }}>
                        <h3 style={{ margin: '0 0 5px', maxWidth:150,overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{newSelected.label}</h3>
                        <span style={{display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                          {props.translate('deviceModel')}{' '}
                          {newSelected.model}
                        </span>
                        <CloseIcon
                          className={'feature-close-button'}
                          onClick={props.handleClose}
                        />
                      </div>
                    </div>
                  }
                </Grid>
                {newSelected.garage || newSelected.type || newSelected.expirationTime ||  newSelected.vehicleLicensePlate ? ( <Grid item xs={12} style={{ position: 'relative', paddingTop: 0 }}>
                  
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <div>
                          {newSelected.garage && (
                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                <svg fill='currentColor' width={14} height={14}>
                                  <use xlinkHref={`${PinIcon}#icon`} />
                                </svg>
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                                {newSelected.garage}
                              </span>
                            </div>
                          )}
                          {newSelected && newSelected.trackers && (
                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                <svg fill='currentColor' width={14} height={14}>
                                  <use xlinkHref={`${VehicleIcon}#icon`} />
                                </svg>
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                                {newSelected.trackers.label}
                              </span>
                            </div>
                          )}
                          {newSelected.type && (
                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                <svg fill='currentColor' width={14} height={14}>
                                  <use xlinkHref={`${UnitsIcon}#icon`} />
                                </svg>
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                                {newSelected.type}
                              </span>
                            </div>
                          )}
                          {newSelected.expirationTime && (
                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                <svg fill='currentColor' width={14} height={14}>
                                  <use xlinkHref={`${Calendar}#icon`} />
                                </svg>
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                              {(newSelected.expirationTime &&
                                moment(newSelected.expirationTime).format(
                                  'DD-MM-YYYY'
                                )) ||
                              null}
                              </span>
                            </div>
                          )}
                          {newSelected.vehicleLicensePlate && (
                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                  <LicensePlateIcon fill='currentColor' width={20} height={20}/>
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                                {newSelected.vehicleLicensePlate}
                              </span>
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                </Grid> ) : null}

                <Grid item xs={12} style={{paddingTop: 0}}>
                  {state.selectedItem &&
                    drawValue(state.selectedItem, classes).htmlObject}

                  {props.currentLocation && <>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        margin: '15px 0'
                      }}
                      className='with-border'
                    >
                      {props.translate('currentLocation')}
                    </p>
                    <p>
                        <span className='row-label'>
                          {props.translate('settingsCoordinates')}
                        </span>
                        <span className='row-value'>
                          
                          {setAttributeFormat('latitude', props.currentLocation.latitude)},
                          {setAttributeFormat('longitude', props.currentLocation.longitude)}
                          {
                            <button
                              onClick={() => copyToClipboard(props.currentLocation)}
                              className='button-to-normal'
                            >
                              <FileCopyIcon
                                fill='currentColor'
                                width='14'
                                height='14'
                              />
                            </button>
                          }
                        </span>
                    </p>
                    <p>
                        <span className='row-label'>
                          {props.translate('trailerAddress')}
                        </span>
                        <span className='row-value'>
                          {HtmlParser(props.currentLocation.address)}
                        </span>
                    </p>
                    <p>
                        <span className='row-label'>
                          {props.translate('gpsUpdated')}
                        </span>
                        <span className='row-value'>
                          <TimeAgo date={props.currentLocation.serverTime} minPeriod={60} />
                        </span>
                    </p>
                    </> }
                </Grid>
              </Grid>
          </div>
          </Scrollbar>
            </div>
      </>
    )
  // }
}

editItem.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => {
  return {
    themecolors: state.themeColors
  }
}
const mapStateToProps = connect(mapState)
export const EditItem = mapStateToProps(
  withLocalize(editItem)
)
