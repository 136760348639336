import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../Layout';
import { WorkFlowForm } from '../../Components/WorkFlow/WorkFlowForm';
import instance from '../../axios';
import Table from '../../Components/common/tableWithBackEndPagination'
import { checkPrivileges, errorHandler } from '../../Helpers';
import Notifications from 'react-notification-system-redux'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag';
import Button from '../../Components/common/Button';
import { FormControlLabel } from '@mui/material';
import axios from 'axios'
// import { FormControlLabel, Grid } from '@material-ui/core'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '../../Components/common/Checkbox'
import MenuItem from '../../Components/common/MenuItem'
import Menu from '../../Components/Menu/CommonMenu'
import { useDispatch, useSelector } from 'react-redux';
const CancelToken = axios.CancelToken
let source = ''

const WorkFlow = (props) => {
  const [state, setState] = useState({
    mode: '',
    initFetch: false,
    metrixData: [],
    categoriesList: [],
    workFlows: [],
    showTable: true,
    showForm: false,
    onDeleteConfirmation: false,
    selectedItem: '',
    currentPage: 1,
    pagesize: 20,
    countChecked: 0,
    anchorEl: '',
    statusFilter: [],
    filterStatusType: '',
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 1,
      hasNext: true,
      searchText: ''
    },
  }
  )

  const logInUser = useSelector((state) => state.logInUsers)
  const devices2 = useSelector((state) => state.devices2)
  const themecolors = useSelector((state) => state.themeColors)


  const dispatch = useDispatch()
  const fetchMoreItems = () => {
    fetchData(logInUser, state.currentPage, state.pagesize)
  }

  const fetchData = (userId, page, perPage, reset, sidebar, sidebarData) => {
    source = CancelToken.source()
    const query = 'all=true'
    let searchText = state.searchText

    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }
    if (reset) {
      setState((prevState) => ({
        ...prevState,
        itemPagination: { ...prevState.itemPagination, items: [] }
      }))
    }
    let sidebarURL = ''
    if (sidebar) {
      let city = parseInt(sidebarData.selectedCityId)
      sidebarURL = `/api/workflows/get?${sidebarData.selectedStatusId}${sidebarData.selectedvehicleId}&page=1&limit=50&from=${sidebarData.from}&to=${sidebarData.to}&interCity=${city}`
    }
    instance({
      url: sidebar ? sidebarURL : `/api/workflows/get?${query}&page=${page||1}&limit=${perPage||20}${searchText||""}${state.filterStatusType||""}`,
      method: 'GET',
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      setState((prevState) => ({
        ...prevState,
        workFlows: response
      }))
    }).catch(error => {
      console.error(error)
      // errorHandler(error,props.dispatch)
    })
  }

  const getMetrix = () => {
    instance({
      url: `/api/workflows/matrix/`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        setState((prevState) => ({
          ...prevState,
          metrixData: res
        }))

      }).catch(error => {
        console.error('error ===', error)
      })
  }

  const fetchCategories = () => {
    //(/api/devices/categories
    instance(`/api/devices/categories`)
      .then(response => {
        setState((prevState) => ({
          ...prevState,
          categoriesList: response?.categories
        }))
      })
      .catch(error => {
        console.error('error ===', error)

        // errorHandler(error, props.dispatch)
      })
  }

  const editWorkFlow = (item) => {
    setState((prevState) => ({
      ...prevState,
      form: item && item[0],
      form2: item && item[0],
      mode: 'edit',
      showTable: false,
      showForm: true,
    }))
  }
  const openCreateTypeFrom = () => {
    setState((prevState)=>({
      ...prevState,
      mode: 'create',
      showTable: false,
      showForm: true,
    }))
  }
  const modelControl = () => {
    setState((prevState)=>({
      ...prevState,
      mode: '',
      showTable: true,
      showForm: false,
    }))
  }

  const handleChangeRowsPerPage = (value) => {
    if (source) {
      source.cancel()
    }
    setState((prevState) => ({
      ...prevState,
      pagesize: value,
      currentPage: 1
    }))
  }

  const handleChangePage = (value) => {
    if (source) {
      source.cancel()
    }
    setState((prevState) => ({
      ...prevState,
      currentPage: value
    }))
  }
  const setPagination = (user) => {
    const { workFlows } = state
    if (workFlows && workFlows.data && workFlows.data.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / state.pagesize
      let IsFloate = checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pageSize = state.pagesize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }


  const checkFloteNumber = (n) => {
    return Number(n) === n && n % 1 !== 0
  }

  const onRemovedItem = (item) => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmation: true,
      selectedItem: item && item[0]
    }))
  }
  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmation: false,
      selectedItem: ""
    }))
  }

  const onDelete = () => {
    if (state.selectedItem && state.selectedItem.id) {
      instance({
        url: `/api/workflows//${state.selectedItem.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response) {
            dispatch(
              Notifications.success({
                message: props.translate('savedWorkFlowIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmation: false,
              selectedItem: ''
            }))
            fetchMoreItems()
          }
        }).catch(error => { errorHandler(error, dispatch) })
    }
  }

  const cancelWorkFlow = (e) => {
    instance({
      url: `api/workflows/cancel/${e.id}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        if (response) {
          dispatch(
            Notifications.success({
              message: props.translate('workFlowCanceled'),
              autoDismiss: 10
            })
          )
          fetchData(
            logInUser,
            state.currentPage,
            state.pagesize
          )
        }
      }).catch(error => {
        console.error('error ===', error)
      })
  }

  const finishWorkFlow = (e) => {
    instance({
      url: `api/workflows/finish/${e.id}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        if (response) {
          dispatch(
            Notifications.success({
              message: props.translate('workFlowFinished'),
              autoDismiss: 10
            })
          )
          fetchData(
            logInUser,
            state.currentPage,
            state.pagesize
          )
        }
      }).catch(error => {
        console.error('error ===', error)
      })
  }

  const searchItems = (e) => {
    if (source) {
      source.cancel()
    }
    setState((prevState) => ({
      ...prevState,
      searchText: e
    }))
  }
  const handleClickFilter = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget
    }))
  }

  const handleCloseFilter = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: ""
    }))
  }
  const handleChangeFilter = useCallback((name, event) => {
    const newStatusFilter = {
      ...state.statusFilter,
      [name]: event.target.checked,
    };
    setState((prevState) => ({
      ...prevState,
      statusFilter:newStatusFilter,
      countChecked: 0,
      currentPageHist: 1
    }))
    
    let filter = '';
    let newCountChecked = 0;

    Object.entries(newStatusFilter).forEach(([key, value]) => {
      if (value) {
        filter += '&status=' + key;
        newCountChecked++;
      }
    });

    setState((prevState) => ({
      ...prevState,
      countChecked:newCountChecked
    }))

    setState((prevState) => ({
      ...prevState,
      filterStatusType: filter
    }))
  },[state.filterStatusType,state.statusFilter])

  useEffect(() => {
    getMetrix()
    fetchCategories()
    if (
      logInUser &&
      logInUser.id &&
      state.initFetch === false
    ) {
      setState((prevState) => ({
        ...prevState,
        initFetch: true
      }))
    }
  }, [])

  useEffect(() => {
    fetchData(
      logInUser,
      state.currentPage,
      state.pagesize
    )
  }, [state.pagesize, state.currentPage, state.searchText, state.filterStatusType])

  const endMessage = (
    <p style={{ textAlign: 'center', color: '#ccc' }}>
      -- {props.translate('end')} --
    </p>
  )
  return (
    <div>
      <Layout
        endMessage={endMessage}
        classFromChildren='has-padding'
        editWorkFlow={editWorkFlow}
        fetchMoreItems={fetchMoreItems}
        itemPagination={{ ...state.itemPagination }}
        fetchData={fetchData}
        {...props}
        {...state}
      >
        {state.showTable &&
          (<div className='main-content-page'>
            <div
              style={{
                background: themecolors.backgroundColor,
                color: themecolors.textColor,
                borderRadius: 6,
                padding: 16
              }}
            >
              {state.showTable ? (
                <Table
                  rows={state?.workFlows?.data || []}
                  pagination={setPagination(state?.workFlows || {})}
                  handleChangeRowsPerPage={(n) => handleChangeRowsPerPage(n, 'workFlows')}
                  handleChangePage={(n) => handleChangePage(n, 'workFlows')}
                  logInUser={logInUser}
                  isEditable={true}
                  status={false}
                  completeMark={false}
                  themecolors={themecolors}
                  translate={props.translate}
                  isCancel
                  isFinish
                  searchable
                  workFlowSearch
                  workFlowEdit
                  SearchItem={searchItems}
                  cancelWorkFlow={cancelWorkFlow}
                  finishWorkFlow={finishWorkFlow}
                  device2={devices2}
                  // hasAccessOfCreate={checkPrivileges('workflowCreate')}
                  hasAccessOfUpdate={checkPrivileges('workflowUpdate')}
                  hasAccessOfDelete={checkPrivileges('workflowDelete')}
                  onDelete={onRemovedItem}
                  onEdit={editWorkFlow}
                  workFlowStatus
                  createButton={
                    checkPrivileges('workflowCreate') && (
                      <div style={{ marginLeft: 10 }}>
                        <Button onClick={openCreateTypeFrom}>
                          {props.translate('Create')}
                        </Button>
                      </div>
                    )
                  }
                  isFilter={
                    (<Filter
                      countChecked={state.countChecked}
                      anchorEl={state.anchorEl && state.anchorEl}
                      handleChangeFilter={handleChangeFilter}
                      handleClickFilter={handleClickFilter}
                      handleCloseFilter={handleCloseFilter}
                      translate={props.translate}
                      statusFilter={state.statusFilter}
                    />)
                  }
                  rowDefinition={[
                    {
                      id: 'refNum',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('refNum')
                    },
                    {
                      // id: 'vehicleType',
                      id: 'deviceIdWorkFLow',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('Vehicle')
                    },
                    {
                      id: 'startPoint',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('startPoint')
                    },
                    {
                      id: 'startOutSkirts',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('startOutSkirts')
                    },
                    {
                      id: 'endPoint',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('endPoint')
                    },
                    {
                      id: 'endOutSkirts',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('endOutSkirts')
                    },
                    {
                      id: 'perDiemCost',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('perDiemCost')
                    },
                    {
                      id: 'foodCost',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('foodCost')
                    },
                    {
                      id: 'cost',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('cost km')
                    },
                    {
                      id: 'foodPlusPerDiemCost',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('foodPlusPerDiemCost')
                    }, {
                      id: 'totalCost',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('totalCost')
                    },
                    {
                      id: 'mileage',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('Mileage km')
                    },
                    {
                      id: 'status',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('status')
                    },
                    {
                      id: 'interCity',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('tripType')
                    },
                    {
                      id: 'startTime',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('startTime')
                    },
                    {
                      id: 'endTime',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('endTime')
                    }
                  ]}
                />
              ) : null}

              {/* {state.mode === '' ? (
              <EmptyState
                text={props.translate('noCommandSelected')}
              />
            ) : null} */}
            </div>
          </div>)
        }

        {state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={onDelete}
            title={props.translate('areYouWantToDelete')}
          // children={props.item.description}
          />
        )}

        {state.mode === 'create' ? (
          <WorkFlowForm
            themecolors={themecolors}
            modelControl={modelControl}
            translate={props.translate}
            metrixData={state?.metrixData || []}
            categoriesList={state?.categoriesList || []}
            fetchMoreItems={fetchMoreItems}
            createMode
          />
        ) : null}
        {state.mode === 'edit' ? (
          <WorkFlowForm
            formData={state.form}
            themecolors={themecolors}
            modelControl={modelControl}
            translate={props.translate}
            metrixData={state?.metrixData || []}
            categoriesList={state?.categoriesList || []}
            fetchMoreItems={fetchMoreItems}
            editMode
          />
        ) : null}

      </Layout>
    </div>
  )
}


export default WorkFlow


const Filter = (props) => {
  const html = (
    <div>
      <div
        href={null}
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={props.handleClickFilter}
      >
        <p className='v-tab-heading pull-left' style={{ width: 100 }}>
          {props.translate('selected')}{": "}{props.countChecked}
        </p>
        <ArrowDropDownIcon style={{ color: '#FFFFFF' }} />
      </div>
      <Menu
        id='simple-menu'
        anchorEl={props?.anchorEl}
        open={Boolean(props?.anchorEl)}
        onClose={props.handleCloseFilter}
      >
        <MenuItem component={FormControlLabel} style={{ margin: 0 }}
          control={<Checkbox
            name='Pending'
            checked={props.statusFilter?.pending}
            onChange={(e) => props.handleChangeFilter('pending', e)}
            canAssign
            canRemove
          />}
          label={<span style={{ marginLeft: 8 }}>{props.translate('scheduled')}</span>}
        />
        <MenuItem component={FormControlLabel} style={{ margin: 0 }}
          control={<Checkbox
            name='Cancelled'
            checked={props.statusFilter?.cancelled}
            onChange={(e) => props.handleChangeFilter('cancelled', e)}
            canAssign
            canRemove
          />}
          label={<span style={{ marginLeft: 8 }}>{props.translate('cancelled')}</span>}
        />
        <MenuItem component={FormControlLabel} style={{ margin: 0 }}
          control={<Checkbox
            name='inprogress'
            checked={props.statusFilter?.inprogress}
            onChange={(e) => props.handleChangeFilter('inprogress', e)}
            canAssign
            canRemove
          />}
          label={<span style={{ marginLeft: 8 }}>{props.translate('inProgress')}</span>}
        />
        <MenuItem component={FormControlLabel} style={{ margin: 0 }}
          control={<Checkbox
            name='Completed'
            checked={props.statusFilter?.completed}
            onChange={(e) => props.handleChangeFilter('completed', e)}
            canAssign
            canRemove
          />}
          label={<span style={{ marginLeft: 8 }}>{props.translate('completed')}</span>}
        />
      </Menu>
    </div>
  );

  return <div className="v-filter-field">{html}</div>;
};