import React, { Component } from 'react'
import { updateLayer } from './../../Actions/Maps'
import { connect } from 'react-redux'
import './MapPagination.scss'

import PropTypes from 'prop-types'
// import { makeStyles } from '@mui/styles'
import MenuItem from './../common/MenuItem'

// let themeColors
const mapStateToProps = (state) => {
  // themeColors = state.themeColors
  return {
    themecolors: state.themeColors,
    mapLayer: state.mapLayer
  }
}

// const styles = theme => ({
//   pagination: {
//     '& .active > a': {
//       color: themeColors.themeDarkColor
//     },
//     '& li:hover a': {
//       color: themeColors.themeDarkColor
//     }
//   }
// })

class MapPagination1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
       maps: []
     }
    this.updateLayers = this.updateLayers.bind(this)
  }

  componentWillMount () {
    let mapsArr = []
    this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.maps&&this.props.logInUser.attributes.maps.map((item)=>{
      if(item!=='baidu'){
        mapsArr.push(item)
      }
    })
    this.setState({maps:mapsArr})
  }
  updateLayers (id) {
    this.props.dispatch(updateLayer({ id }))
    this.props.updateLayer()
  }

  render () {
    if (
      this.state.maps&&this.state.maps.length){
      return (
        <React.Fragment>
          {this.state.maps.map((itemMap, index) => {
            return (
              <MenuItem
                key={index}
                selected={this.props.mapLayer.id === itemMap}
                onClick={() => this.updateLayers(itemMap)}
              >
                {this.props.translate(itemMap)}
              </MenuItem>
            )
          })}
        </React.Fragment>
      )
    } else {
      return null
    }
  }
}

MapPagination1.propTypes = {
  classes: PropTypes.object.isRequired
}
export const MapPagination = connect(mapStateToProps)(
  MapPagination1
)
