import React, { PureComponent } from 'react'
import TimeAgo from 'react-timeago'
import { connect } from 'react-redux'
// import Icon from '@mui/material/Icon'
import CloseIcon from '@mui/icons-material/Close';
import './index.scss'
import { checkPrivileges, setAttributeFormat,setIconOnList } from '../../../Helpers'
import { unsetDeviceId } from './../../../Actions/Devices'
import Style from 'style-it'
import moment from 'moment'
import Scrollbar from 'react-scrollbars-custom'
import ProtocolDialog from '../ProtocolDialog'
// import PinIcon from './../../../assets/nav/pin.svg'
import ReactHtmlParser from 'react-html-parser'
// import GroupIcon from '@mui/icons-material/GroupWorkOutlined';
import { ReactComponent as FileCopyIcon } from './../../../assets/monitoring/copy.svg'
import { ReactComponent as OtherSensorIcon } from '../../../assets/sensors/sensor.svg'
import { ReactComponent as ElogicIcon } from '../../../assets/nav/algorithm.svg'
import { ReactComponent as AggregateIcon } from '../../../assets/sensors/chip.svg'
import { ReactComponent as MeasurementIcon } from '../../../assets/sensors/centimeter.svg'
import { ReactComponent as DiscreteIcon } from '../../../assets/sensors/discrete.svg'
import { ReactComponent as StaffIcon } from '../../../assets/nav/staff.svg'
import { ReactComponent as CallIcon } from '../../../assets/nav/phone-call.svg'
// import { ReactComponent as TrailerIcon } from '../../../assets/nav/trailerr.svg'
// import { ReactComponent as GeofenceIcon } from '../../../assets/nav/geofence.svg'
// import { ReactComponent as Energy } from '../../../assets/monitoring/energy.svg'
// import { ReactComponent as GPS } from '../../../assets/monitoring/satellite.svg'
// import { ReactComponent as GunPointer } from '../../../assets/monitoring/gun-pointer.svg'
// import { ReactComponent as DeviceModel} from '../../../assets/monitoring/3d-model.svg'
// import { ReactComponent as Shape} from '../../../assets/monitoring/Shape.svg'
// import { ReactComponent as Status} from '../../../assets/monitoring/charging-circle.svg'
// import { ReactComponent as SpeedoMeter} from '../../../assets/monitoring/speedometer.svg'
// import { ReactComponent as Unit} from '../../../assets/dashboard/units.svg'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Container, Draggable } from 'react-smooth-dnd'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Tooltip } from '@mui/material';
import instance from '../../../axios';
import { logInUserInfo } from '../../../Actions/Users';
// import {fetchComputedAttributesSensors }
const mapStateToProps = (state) => {
  let currentPosition = null
  let currentDevice = null
  let fixDriver = null
  if (state.deviceId) {
    currentDevice = state.devices3.find(d => d.id === state.deviceId)
    currentPosition = state.deviceRelatedData[state.deviceId]
    fixDriver = currentDevice.attributes.fixDriverId&&state.drivers.find(item => item.id === currentDevice.attributes.fixDriverId)
  }
  return {
    currentPosition: Object.assign({}, currentPosition),
    currentDevice: Object.assign({}, currentDevice),
    trailers: state.trailers,
    drivers: state.drivers,
    fixDriver,
   computedAttributesDeviceWise: state.computedAttributesDeviceWise,
    
    
    fetchComputedAttributesSensors:state.fetchComputedAttributesSensors,
    computedAttributes: state.computedAttributes,
    allComputedAttributes: state.allComputedAttributes,
    events: state.events,
    groups: state.groupNames,
    vehicles: state.vehicles,
    deviceId: state.deviceId,
    insideGeofence: state.insideGeofence,
    geofences: state.geoFence,
    logInUser: state.logInUsers
  }
}
class Row extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      device: {},
      details: {},
      toggleAll:true,
      toggleBasic:true,
      toggleLocation:true,
      toggleGeofence:true,
      toggleSensor:true,
      toggleDriver:true,
      toggleVehicle:true,
      toggleEvent:true,
      toggleWorKFLow:true,
      toggleTrailer:true,
      toggleInput:true,
      // listingType: ["w1","w2","w3","w4","w5","w6","w7","w8","w9","w10","w11","w12","w13","w14", "W15"],
      listingType:this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.monitoringListing ?   this.props.logInUser.attributes.monitoringListing    :  ["w1","w2","w3","w4","w5","w6","w7","w8","w9","w10","w11","w12","w13","w14", "W15"],
    }
    this.hideWidgets = this.hideWidgets.bind(this)
  }

  hideWidgets () {
    if(this.props.dispatch) {
      this.props.dispatch(unsetDeviceId({ id: this.props.deviceId }))
      document.body.classList.remove('shown-widgets')
    }
  }
  toggleAll = ()=>{
    this.setState({toggleAll:!this.state.toggleAll},()=>{
      if(this.state.toggleAll){
        this.setState({
          toggleBasic:true,
          toggleLocation:true,
          toggleSensor:true,
          toggleDriver:true,
          toggleGeofence:true,
          toggleVehicle:true,
          toggleEvent:true,
          toggleTrailer:true,
          toggleInput:true,
          toggleOutput:true,
          toggleWorKFLow:true,
        })
      }else{
        this.setState({
          toggleBasic:false,
          toggleLocation:false,
          toggleSensor:false,
          toggleDriver:false,
          toggleVehicle:false,
          toggleEvent:false,
          toggleTrailer:false,
          toggleInput:false,
          toggleOutput:false,
          toggleGeofence:false,
          toggleWorKFLow:false,

        })
      }
    })
  }
  handleBox =(type,value)=> this.setState({[type]:value})
  
  onDrop = dragResult => {
    let arr = this.state.listingType
    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]
    let itemToAdd = payload

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }

    
    this.setState({ listingType: result },()=>{
      let { logInUser } = this.props

      logInUser.attributes = { ...logInUser.attributes, monitoringListing:result }
      instance({
        url: `api/users/${logInUser.id}`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data:{
          ...logInUser
        }
       }) .then(user => {
          this.props.dispatch(logInUserInfo(user))
     })
    .catch(error => {console.error('err0r====', error)})
      
    })
  }
  render () {
    let cl = this.props?.currentPosition?.icons?.playicon?.label || ""
    if (this.props.deviceId) {

      const viewUserType = this.props?.logInUser?.attributes?.unitsViewType; // Assuming you have the value of viewUserType

      let color;
      if (viewUserType === "unitStatus") {
          // Set color based on device.status
          switch (this.props.currentDevice.status) {
              case "online":
                  color = "#00BF4E"; // Green
                  break;
              case "offline":
                  color = "#FF0000"; // Red
                  break;
              case "gpsNotUpdated":
                  color = "#1976d2"; // Grey
                  break;
              default:
                  color = "#00BF4E"; // Default to red if status is not recognized
          }
      } else if (viewUserType === "motionStatus") {
          // Set color based on pp (position.icons.playicon.label)
          // const pp = position.icons.playicon.label || "";
          switch (cl) {
            case "online":
            case "moving":
                color = "#00BF4E"; // Green
                break;
            case "parking":
                color = "#1E90FF"; // Blue
                break;
            case "idling":
                color = "#ffc107"; // Yellow
                break;
            case "stop":
                color = "#87CEFA"; // Light Blue
                break;
            case "not-updated":
                color = "#1976d2"; // Grey
                break;
            default:
                color = "#FF0000"; // Default to red if motion status is not recognized
        }
      }
      //  else{
      //   color =  this.props.currentDevice.attributes.color;
      // } 


        // let color = this.props.currentDevice.status === "online"  ?
        //               cl === "online"  ? "#00BF4E" :
        //               cl === "moving"  ? "#00BF4E" :
        //               cl === "offline"  ? "#FF0000" :
        //               cl === "parking"  ? "#1E90FF" :
        //               cl === "idling"  ? "#ffc107" :
        //               cl === "stop"  ? "#87CEFA" :
        //               cl === "gpsNotUpdated"  ? "#1976d2" :
        //               cl === "not-updated"  ? "#1976d2" : "#FF0000" 
        //         : this.props.currentDevice.status === "gpsNotUpdated" ? "#1976d2"
        //         : "#FF0000"
      return (
        <Style>
          {`
				.type-toggle.active {
					color: ${this.props.themecolors&&this.props.themecolors.themeDarkColor}
				}
			`}
          <div className='feature-row'>
            {checkPrivileges('device') && [
              <div key={0} className={'feature-row-head'}>
                <div
                  className='clearfix'
                  style={{
                    borderBottom: '1px solid ' + this.props.themecolors&&this.props.themecolors.themeLightColor,
                    display:"flex",
                    justifyContent:"space-between",
                    width:"90%"
                  }}
                >
                  <div    
                  style={{
                    // borderBottom: '1px solid ' + this.props.themecolors&&this.props.themecolors.themeLightColor,
                    display:"flex",
                    justifyContent:"space-between",
                    alignItems:"center"
                  }}>
                  {/* <img
                    style={{ width: 36, height: 36 }}
                    src={
                      '/assets/category/default/' +
                      (this.props.currentDevice.category || 'default') +
                      '.svg'
                    }
                    alt={this.props.currentDevice.category}
                  /> */}
                  { viewUserType === "default" ? 
                     <img
                     style={{height:"40px"}}
                     src={
                       '/assets/category/default/' +
                       (this.props.currentDevice.category || 'default') +
                       '.svg'
                     }
                     alt={this.props.currentDevice.name }
                   /> :

                  setIconOnList(this.props.currentDevice.category,color)}
                  <span className='feature-row-head-name'>
                    {this.props.currentDevice.name}
                  </span>
                  </div>
                  <div style={{backgroundColor:"#0a83a1", padding:"0px 0px 0px 10px", borderRadius:4,  display:'flex', alignItems:"center",maxHeight:25,marginTop:5,cursor: "pointer" }}>
                       <span style={{fontSize:11}}>
                        {!this.state.toggleAll ? "Open": "Close"}</span> 
                        {this.state.toggleAll ?  <ArrowDropUpIcon style={{color:"#fff"}} onClick={()=>{this.toggleAll()}} /> :  <ArrowDropDownIcon  style={{color:"#fff"}} onClick={()=>{this.toggleAll()}} /> }
                  </div>
                  
                </div>
                <CloseIcon
                    className={'feature-close-button'}
                    onClick={this.hideWidgets}
                    style={{marginLeft:5}}
                  />
              </div>,
              <div key={1} className='feature-row-inner'>
                <Scrollbar>

                      {/* {this.props.currentDevice?.attributes?.onWorkFlow && 
                          <FeatureSection
                            sensorsDesc= {this.props.sensorsDesc}
                            dispatch={this.props.dispatch}
                            key='WorkFlow'
                            type='WorkFlow'
                            device={this.props.currentDevice}
                            details={this.props.currentPosition}
                            translate={this.props.translate}
                            handleBox={this.handleBox}
                            copyToClipboard={this.props.copyToClipboard}
                            toggleWorKFLow={this.state.toggleWorKFLow}
                          />
                         } */}

                  <Container onDrop={this.onDrop} lockAxis='y'>
                    {this.state.listingType.map((item)=>( 
                      <Draggable key={item}>
                       
                      {item === "w1" ?
                      <div style={{cursor: "pointer"}}>
                        <FeatureSection
                          dispatch={this.props.dispatch}
                          key='basicDetails'
                          type='basicDetails'
                          device={this.props.currentDevice}
                          details={this.props.currentPosition}
                          groups={this.props.groups}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard}
                          logInUser={this.props.logInUser}
                          handleBox={this.handleBox}
                          toggleBasic={this.state.toggleBasic}
                        />
                      </div>
                                : item === "w15" ?
                                <div style={{cursor: "pointer"}}>
                                  <FeatureSection
                                    dispatch={this.props.dispatch}
                                    key='Location'
                                    type='Location'
                                    insideGeofence={this.props.insideGeofence}
                                    geofences={this.props.geofences}
                                    device={this.props.currentDevice}
                                    details={this.props.currentPosition}
                                    logInUser={this.props.logInUser}
                                    translate={this.props.translate}
                                    copyToClipboard={this.props.copyToClipboard}
                                    themecolors={this.props.themecolors}
                                    handleBox={this.handleBox}
                                    toggleLocation={this.state.toggleLocation}
                                    
                                  /> 
                                  
                                </div>
                                  :
                       item === "w2" ?
                      <div style={{cursor: "pointer"}}>
                        <FeatureSection
                          dispatch={this.props.dispatch}
                          key='Location'
                          type='Location'
                          insideGeofence={this.props.insideGeofence}
                          geofences={this.props.geofences}
                          device={this.props.currentDevice}
                          details={this.props.currentPosition}
                          logInUser={this.props.logInUser}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard}
                          themecolors={this.props.themecolors}
                          handleBox={this.handleBox}
                          toggleLocation={this.state.toggleLocation}
                          
                        /> 
                        
                      </div>
                       : item === "w3" ?
                      <div style={{cursor: "pointer"}}>
                        <FeatureSection
                          dispatch={this.props.dispatch}
                          key='Geofence'
                          type='Geofence'
                          insideGeofence={this.props.insideGeofence}
                          geofences={this.props.geofences}
                          device={this.props.currentDevice}
                          details={this.props.currentPosition}
                          logInUser={this.props.logInUser}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard}
                          themecolors={this.props.themecolors}
                          handleBox={this.handleBox}
                          toggleGeofence={this.state.toggleGeofence}
                        /> 
                      </div>
                      : item === "w4" ?
                        checkPrivileges('sensor') && 
                        <div style={{cursor: "pointer"}}>
                            <FeatureSection
                              trailers={this.props.trailers}
                              dispatch={this.props.dispatch}
                              key='Sensors'
                              type='Sensors'
                              device={this.props.currentDevice}
                              details={this.props.currentPosition}
                              drivers={this.props.drivers}
                              computedAttributes={this.props.computedAttributes}
                              allComputedAttributes={this.props.allComputedAttributes}
                              translate={this.props.translate}
                              copyToClipboard={this.props.copyToClipboard}
                              handleBox={this.handleBox}
                              toggleSensor={this.state.toggleSensor}
                            /> 
                        </div>
                          

                      : item === "w5" ?
                        checkPrivileges('binder') && 
                          <div style={{cursor: "pointer"}}>
                            <FeatureSection
                              trailers={this.props.trailers}
                              dispatch={this.props.dispatch}
                              key='trailer'
                              type='Trailer'
                              device={this.props.currentDevice}
                              details={this.props.currentPosition}
                              drivers={this.props.drivers}
                              computedAttributes={this.props.computedAttributes}
                              allComputedAttributes={this.props.allComputedAttributes}
                              translate={this.props.translate}
                              copyToClipboard={this.props.copyToClipboard}
                              handleBox={this.handleBox}
                              toggleTrailer={this.state.toggleTrailer}
                            /> 
                          </div>

                      : item === "w6" ?

                        checkPrivileges('binder') &&   
                          <div style={{cursor: "pointer"}}>
                              <FeatureSection
                                trailers={this.props.trailers}
                                dispatch={this.props.dispatch}
                                key='bin'
                                type='Bin'
                                device={this.props.currentDevice}
                                details={this.props.currentPosition}
                                drivers={this.props.drivers}
                                computedAttributes={this.props.computedAttributes}
                                allComputedAttributes={this.props.allComputedAttributes}
                                translate={this.props.translate}
                                copyToClipboard={this.props.copyToClipboard}
                              />
                            </div>
                      : item === "w7" ?

                        checkPrivileges('binder') &&
                          <div style={{cursor: "pointer"}}>
                              <FeatureSection
                                trailers={this.props.trailers}
                                dispatch={this.props.dispatch}
                                key='passenger'
                                type='Passenger'
                                device={this.props.currentDevice}
                                details={this.props.currentPosition}
                                drivers={this.props.drivers}
                                computedAttributes={this.props.computedAttributes}
                                allComputedAttributes={this.props.allComputedAttributes}
                                translate={this.props.translate}
                                copyToClipboard={this.props.copyToClipboard}
                            />
                            </div>

                      : item === "w8" ?
                        <div style={{cursor: "pointer"}}>
                          <FeatureSection
                            dispatch={this.props.dispatch}
                            key='DRIVER'
                            type='DRIVER'
                            fixDriver={this.props.fixDriver}
                            device={this.props.currentDevice}
                            details={this.props.currentPosition}
                            translate={this.props.translate}
                            handleBox={this.handleBox}
                            toggleDriver={this.state.toggleDriver}
                            trailers={this.props.trailers}
                            drivers={this.props.drivers}
                            computedAttributes={this.props.computedAttributes}
                            allComputedAttributes={this.props.allComputedAttributes}
                            copyToClipboard={this.props.copyToClipboard}
                           

                          />
                        </div>

                      : item === "w9" ?
                      <div style={{cursor: "pointer"}}>

                        <FeatureSection
                          dispatch={this.props.dispatch}
                          key='VEHICLES'
                          type='VEHICLES'
                          device={this.props.currentDevice}
                          details={this.props.currentPosition}
                          vehicles={this.props.vehicles}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard}
                          handleBox={this.handleBox}
                          toggleVehicle={this.state.toggleVehicle}
                        />
                        </div>

                      : item === "w10" ?
                      <div style={{cursor: "pointer"}}>

                        <FeatureSection
                          dispatch={this.props.dispatch}
                          key='EVENTS'
                          type='EVENTS'
                          device={this.props.currentDevice}
                          details={this.props.currentPosition}
                          events={this.props.events}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard}
                          handleBox={this.handleBox}
                          toggleEvent={this.state.toggleEvent}
                        />
                        </div>

                      : item === "w11" ?
                      <div style={{cursor: "pointer"}}>

                        <FeatureSection
                          dispatch={this.props.dispatch}
                          key='ODOMETER'
                          type='ODOMETER'
                          device={this.props.currentDevice}
                          details={this.props.currentPosition}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard} 
                        />
                        </div>

                      : item === "w12" ?
                      <div style={{cursor: "pointer"}}>

                        <FeatureSection
                          dispatch={this.props.dispatch}
                          key='ENGINEHOURS'
                          type='ENGINEHOURS'
                          details={this.props.currentPosition}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard}
                          />
                        </div>
                            
                      : item === "w13" ?
                      <div style={{cursor: "pointer"}}>
             
                        <FeatureSection
                          sensorsDesc= {this.props.sensorsDesc}
                          dispatch={this.props.dispatch}
                          key='INPUT'
                          type='INPUT'
                          computedAttributesDeviceWise={this.props.computedAttributesDeviceWise}

                          device={this.props.currentDevice}
                          details={this.props.currentPosition}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard}
                        />
                        </div>

                      : item === "w14" ?
                      <div style={{cursor: "pointer"}}>
                        <FeatureSection
                          sensorsDesc= {this.props.sensorsDesc}
                          dispatch={this.props.dispatch}
                          key='OUTPUT'
                          type='OUTPUT'
                          device={this.props.currentDevice}
                          details={this.props.currentPosition}
                          translate={this.props.translate}
                          copyToClipboard={this.props.copyToClipboard}
                        />
                        </div>
                         
                      
                      :  
                        null
                        } 



                        
                      </Draggable>
                      ))}
                  </Container>
                </Scrollbar>
               
              </div>
            ]}
          </div>
        </Style>
      )
    } else {
      return null
    }
  }
}

export class FeatureSection extends PureComponent {
  constructor (props) {
    super(props)
    this.getCardinal = this.getCardinal.bind(this)
    this.convertOdometer = this.convertOdometer.bind(this)
    this.checkLastResponseTime = this.checkLastResponseTime.bind(this)
    this.openProtocol = this.openProtocol.bind(this)
    this.closeProtocol = this.closeProtocol.bind(this)
    this.state = {
      displayOdo: 'km',
      km: 0,
      mi: 0,
      modal: false,
      input: '',
      protocol: '',
      inputName: '',
    }
  }

  convertOdometer (unit) {
    if (unit !== this.state.displayOdo) {
      this.setState({ displayOdo: unit })
    }
  }

  componentWillReceiveProps (NextProps) {
    this.setState({
      km:
        (NextProps.details &&
        NextProps.details.attributes &&
        NextProps.details.attributes.totalDistance
          ? NextProps.details.attributes.totalDistance / 1000
          : 0
        ).toFixed(2) || 0,
      mi:
        (NextProps.details &&
          NextProps.details.attributes &&
          NextProps.details.attributes.totalDistance &&
          (
            ((NextProps.details.attributes.totalDistance || 0) / 1000) *
            0.621371
          ).toFixed(2)) ||
        0
    })
  }

  componentWillMount () {
    this.setState({
      km:
        (this.props.details &&
        this.props.details.attributes &&
        this.props.details.attributes.totalDistance
          ? this.props.details.attributes.totalDistance / 1000
          : 0
        ).toFixed(2) || 0,
      mi:
        (this.props.details &&
          this.props.details.attributes &&
          this.props.details.attributes.totalDistance &&
          (
            ((this.props.details.attributes.totalDistance || 0) / 1000) *
            0.621371
          ).toFixed(2)) ||
        0
    })
  }

  //given "0-360" returns the nearest cardinal direction "N/NE/E/SE/S/SW/W/NW/N"
  getCardinal (angle) {
    //easy to customize by changing the number of directions you have
    let directions = 8

    let degree = 360 / directions
    angle = angle + degree / 2

    if (angle >= 0 * degree && angle < 1 * degree) return 'N'
    if (angle >= 1 * degree && angle < 2 * degree) return 'NE'
    if (angle >= 2 * degree && angle < 3 * degree) return 'E'
    if (angle >= 3 * degree && angle < 4 * degree) return 'SE'
    if (angle >= 4 * degree && angle < 5 * degree) return 'S'
    if (angle >= 5 * degree && angle < 6 * degree) return 'SW'
    if (angle >= 6 * degree && angle < 7 * degree) return 'W'
    if (angle >= 7 * degree && angle < 8 * degree) return 'NW'
    //Should never happen:
    return 'N'
  }

  checkLastResponseTime = minTime => {
    let posDate = this.props.device.lastUpdate
    if (minTime) {
      return moment(posDate).isBefore(moment().subtract(10, 'minutes'))
    } else if (this.props.details.serverTime) {
      posDate = this.props.details.serverTime
      return (
        moment(posDate).isBefore(moment().subtract(10, 'minutes')) &&
        !moment(this.props.device.lastUpdate).isBefore(
          moment().subtract(10, 'minutes')
        )
      )
    }
  }

  openProtocol (input, protocol, inputName) {
    this.setState({ modal: true, input, protocol, inputName })
  }

  closeProtocol () {
    this.setState({ modal: false, input: '', protocol: '', inputName: '' })
  }

  getStatusText = () => {
    let p = this.props.details
    const {stopTime, motion, parking, ignition} = p && p.attributes || {};    

    if (p && p.attributes) {
      if (!moment(p.serverTime).isBefore(moment().subtract(10, 'minutes'))) {
        // idling
        if (ignition && this.props.device && this.props.device.attributes && parseInt(stopTime) >
        (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000)) {
          return this.props.translate('idling')
        } else if (
          (p.attributes.ignition && motion) ||
          (p.protocol === 'osmand' && motion)
        ) {
          // moving
          return this.props.translate('moving')
        } else if (p.attributes.ignition !== true && motion) {
          // towing
          return this.props.translate('towing')
        } else if (parking) {
          return this.props.translate('parking')
        } else if (this.props.device && this.props.device.attributes && parseInt(stopTime) <
        (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000)) {
          return this.props.translate('stop')
        }
      }
    }
    return this.props.translate('unknown')
  }
  render () {
    const emptyLinks = null
    const position = this.props.details
    const { icons } = position;
    if (this.props.type === 'basicDetails') {
      let statusText = position.statusText
      // let deviceOnline = this.props.device?.attributes?.onlineTime || false
      // const end = moment(new Date())
      // const start = moment(this.props.details.lastUpdate)
      
      // const minuteDiff = Math.round(moment.duration(end.diff(start)).minutes())
      // const findStatus = statusText === 'online' && deviceOnline > minuteDiff ? true : false

      //Online time calculate : if device status is online, and online time set in device attributes,
      //if online time is less than last gps update, then it will show offline
      //  10 < 9 = online
      //  10 > 11 = offline
         return (
        <div className='feature-section' style={{marginTop:5}}>
          <div style={{backgroundColor:this.props.toggleBasic ? "rgba(10, 131, 161, 0.50)":"rgba(10, 131, 161, 0.25)", margin:"0 10px", padding:8,borderRadius:8}}>
              <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                     {!this.props.smallBox && (
                      <DragHandleIcon  style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleBasic",true)}} /> 
                     )}
                      {!this.props.smallBox && (this.props.toggleBasic ? 
                        <ArrowDropUpIcon style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleBasic", false)}} /> : 
                        <ArrowDropDownIcon  style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleBasic",true)}} /> 
                        )}
              </div> 
                {this.props.smallBox && (
                  <div className='clearfix item-row'>
                      <span className='row-label' style={{fontSize:"14px", fontWeight:500}}>{this.props.translate('unitInfo')}</span>
                   </div>)}
                  <div className='clearfix item-row'>
                      <span className='row-label'>{this.props.translate('IMEI')}</span>
                      <span className='row-value' onClick={() => this.props.copyToClipboard(this.props.device.uniqueId, true)}>{this.props.device.uniqueId}</span>
                  </div>
               
                 {(this.props.smallBox || this.props.toggleBasic) ? 
                   <>
                    {(this.props.logInUser&&this.props.logInUser.userType === -1) ||
                    (checkPrivileges('deviceModel') &&
                    (this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.viewModel))
                      ?
                      <div className='clearfix item-row'>
                        <span className='row-label'> {this.props.translate('deviceModel')} </span>
                        <span className='row-value' onClick={() => this.props.copyToClipboard((this.props.device && this.props.device.model) || (position && position.protocol), true)}>{(this.props.device && this.props.device.model) || (position && position.protocol)}</span>
                       </div> : null}
                       {this.props.device.phone ? (
                          <div className='clearfix item-row'>
                            <span className='row-label'>
                              {this.props.translate('sharedPhone')}
                            </span>
                            <span className='row-value' onClick={() => this.props.copyToClipboard(this.props.device.phone, true)}>{this.props.device.phone}</span>
                          </div>
                        ) : null}
                      
                      {/* <div style={{display:"flex", marginTop:5}}>
                        <span style={{alignSelf:"center",marginRight:"5px"}}>
                            <i className='circle' 
                                style={{ color:
                                  deviceOnline && statusText === 'online' ? (findStatus ? "green" :'red')
                                :
                                 statusText ==="offline" ? "red" : 
                                  statusText ==="online" ?"green":
                                  statusText ==="GPS not updated" ?"#0288d1":""
                                  }}>
                            </i>
                          </span>
                        <span style={{marginLeft:5, fontSize:"12px"}}>
                          <p  >{this.props.translate('deviceStatus')} </p>
                          <p style={{fontWeight:"bold"}} >{
                            deviceOnline && statusText === 'online' ? (findStatus  ? this.props.translate('tracker.' + 'online'
                            ) :this.props.translate('tracker.' + 'offline'
                            ))
                                :
                                statusText && this.props.translate('tracker.' + statusText
                            )}</p>
                        </span>
                      </div> */}
                      <div className='clearfix item-row'>
                        <span className='row-label'>
                          {this.props.translate('deviceStatus')}
                        </span>
                        <span className='row-value'>
                       {/* { deviceOnline && statusText === 'online' ? (findStatus ? "Online" :'Offline')
                                :
                                 statusText ==="offline" ? "Offline" : 
                                  statusText ==="online" ?"Online":
                                  statusText ==="GPS not updated" ?"GPS not updated":""} */}
                          {statusText && this.props.translate('tracker.' + statusText)}
                        </span>
                      </div>
                      {this.props.device && this.props.device.lastUpdate ? (
                          <div className='clearfix item-row'>
                            <span className='row-label'>
                              {this.props.translate('trackerUpdated')}
                            </span>
                            <span className='row-value'>
                              <TimeAgo title="" date={this.props.device.lastUpdate} minPeriod={60} />
                            </span>
                          </div>
                        ) : (
                          <div className='clearfix item-row'>
                            <span className='row-label'>
                              {this.props.translate('trackerUpdated')}
                            </span>
                            <span className='row-value'>
                              <TimeAgo title="" date={this.props.device.lastUpdate} minPeriod={60} />
                            </span>
                          </div>
                        )}
                        {position.serverTime ? (
                          <div className='clearfix item-row'>
                            <span className='row-label'>
                              {this.props.translate('gpsUpdated')}
                            </span>
                            <span className='row-value'>
                              <TimeAgo title="" date={position.serverTime} minPeriod={60} />
                            </span>
                          </div>
                        ) : null}

                            {position.attributes && position.attributes?.lastValidGpsTime ? (
                              <div style={{ display: "flex", marginTop: 5, justifyContent: "space-between", fontSize: "12px" }}>
                                <p>{this.props.translate('lastValidGpsTime')}</p>
                                <p style={{ fontWeight: "bold" }}>
                                  {/* Format the date string with the desired timezone */}
                                  {moment(position.attributes.lastValidGpsTime).tz(this.props?.logInUser?.attributes?.timezone || 'Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                                </p>
                              </div>
                            ) : null}


                    {/* {this.props.device && this.props.device.lastUpdate ? (
                      <div style={{display:"flex", marginTop:5}}>
                        <span style={{alignSelf:"center"}}><GunPointer style={{marginRight:"5px"}}/></span>
                        <span style={{marginLeft:5, fontSize:"12px"}}>
                          <p > {this.props.translate('trackerUpdated')}</p>
                          <p style={{fontWeight:"bold"}}  > <TimeAgo title="" date={this.props.device.lastUpdate} minPeriod={60} /> </p>
                        </span>
                      </div>)

                      : null}
                    {position&&position.serverTime  ? (
                      <div style={{display:"flex", marginTop:5}}>
                        <span style={{alignSelf:"center"}}><GPS  style={{marginRight:"5px"}}/></span>
                        <span style={{marginLeft:5, fontSize:"12px"}}>
                          <p > {this.props.translate('gpsUpdated')}</p>
                          <p style={{fontWeight:"bold"}}  > <TimeAgo title="" date={position.serverTime} minPeriod={60} /></p>
                        </span>
                      </div>)

                      : null}  */}
                      </>
                      :
                      <>
                      {/* {(this.props.logInUser&&this.props.logInUser.userType === -1) ||
                          (checkPrivileges('deviceModel') &&
                          (this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.viewModel))
                            ?
                        <div style={{display:"flex", marginTop:5, fontSize:"12px"}}>
                          <span>
                             {this.props.translate('deviceModel')} {"   "}
                             {(this.props.device && this.props.device.model) || (position && position.protocol)}
                          </span>
                        </div> : null
                      } */}
                      </>
                      }

          </div>
        </div>
      )
    }
    // short map / street view
    // sensors
    // -> deviceTemp
    // -> adb3 = fuel level
    // -> adb0 = ignition
    // -> hdop = GDP Accuracy
    else if (this.props.type === 'Location') {

      let parking = 0
      let stopTime = 0
      let idling = 0
      const geo = [];
      
      for(const k in this.props.insideGeofence) {
        if(this.props.insideGeofence[k].includes(parseInt(position.deviceId))) {
          geo.push(k)
        }
      }

      if (
        position.attributes &&
        this.props.device.attributes &&
        position.attributes.stopTime &&
        this.props.device.attributes.minimalParkingDuration
      ) {
        if (
          parseInt(position.attributes.stopTime) >
          (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000)
        ) {
          if(position.attributes.parking) {
            const ms = (position.attributes.stopTime - (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000));
            parking = ms > 0 ? ms : 0 
            stopTime = ms < 0 ?position.attributes.stopTime : 0
          }
          else {
            const ms = (position.attributes.idlTime - (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000));
            idling = ms > 0 ? ms : 0
            stopTime = ms < 0 ? position.attributes.idlTime : 0
          }
        } else {
          stopTime = position.attributes.stopTime
        }
      }
      let latitude = position && position.latitude ? position.latitude : 0
      let longitude = position && position.longitude ? position.longitude : 0

      let hdop =
        position.attributes && position.attributes.hdop
          ? position.attributes.hdop
          : 0

      return ( 
      <div className='feature-section' style={{marginTop:5}}>
        <div style={{backgroundColor:this.props.toggleLocation? "rgba(10, 131, 161, 0.50)":"rgba(10, 131, 161, 0.25)", margin:"0 10px", padding:8,borderRadius:8}}>
           <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <span className='row-label' style={{fontSize:"14px", fontWeight:500}}>{this.props.translate('location')}
              {/* <span className='row-value' style={{marginLeft:5}}>{this.props.device.uniqueId}</span> */}
              </span>
                {/* <span onClick={()=>{this.setState({toggleBasic:!this.state.toggleBasic})}}>collapse </span> */}
               {/* { this.state.toggleBasic */}
                <div>
                    {!this.props.smallBox &&
                      (<DragHandleIcon  style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleBasic",true)}} />) }
                    {!this.props.smallBox && 
                      (this.props.toggleLocation ? 
                      <ArrowDropUpIcon
                          onClick={()=>{this.props.handleBox("toggleLocation", false)}}
                        style={{color:"#fff"}} /> : 
                        <ArrowDropDownIcon
                          onClick={()=>{this.props.handleBox("toggleLocation", true)}}
                        style={{color:"#fff"}} />) }
                </div>
            </div>


          {/* <h3>
            <span style={{ marginRight: 5, verticalAlign: 'middle' }}>
              <svg fill='currentColor' width={14} height={14}>
                <use xlinkHref={`${PinIcon}#icon`} />
              </svg>
            </span>
            {this.props.translate('location')}
          </h3> */}

          {(this.props.smallBox || this.props.toggleLocation) ?
            <>
                {position.exists ? 
                  <div className='feature-content-section' style={{padding:0}}>
                    <div className='clearfix item-row'>
                      <span className='row-label'>
                        {this.props.translate('movementStatus')}
                      </span>
                      <span className='row-value'>{this.props.translate(icons && icons.playicon && icons.playicon.label || 'unknown')}</span>
                    </div>
                    <div className='clearfix item-row'>
                      <span className='row-label'>
                        {this.props.translate('sensorsTranslation.speed')}
                      </span>
                      <span className='row-value'>
                        {setAttributeFormat('speed', position.speed || 0)}
                      </span>
                    </div>
                    <div className='clearfix item-row'>
                      <span className='row-label'>
                        {this.props.translate('altitude')}
                      </span>
                      <span className='row-value'>
                        {setAttributeFormat('altitude', position.altitude)}
                      </span>
                    </div>
                    <div className='clearfix item-row'>
                      <span className='row-label'>
                        {this.props.translate('direction')}
                      </span>
                      <span className='row-value'>
                        <span style={{ display: 'inline-block' }}>
                          {setAttributeFormat('course', position.course || 0)}
                        </span>
                      </span>
                    </div>
                    {parking ? (
                      <div className='clearfix item-row'>
                        <span className='row-label'>
                          {this.props.translate('parking')}
                        </span>
                        <span className='row-value'>
                          {setAttributeFormat('hours', parking)}
                        </span>
                      </div>
                    ) : null}
                    {idling ? (
                      <div className='clearfix item-row'>
                        <span className='row-label'>
                          {this.props.translate('idling')}
                        </span>
                        <span className='row-value'>
                          {setAttributeFormat('hours', idling)}
                        </span>
                      </div>
                    ) : null}
                    {stopTime ? (
                      <div className='clearfix item-row'>
                        <span className='row-label'>
                          {this.props.translate('stopTime')}
                        </span>
                        <span className='row-value'>
                          {setAttributeFormat('hours', stopTime)}
                        </span>
                      </div>
                    ) : null}
                    {longitude !== 0 && latitude !== 0 && (
                      <div className='clearfix item-row'>
                        <span className='row-label'>
                          {this.props.translate('settingsCoordinates')}
                        </span>
                        <span className='row-value'>
                          {setAttributeFormat('latitude', latitude)},
                          {setAttributeFormat('longitude', longitude)}
                          {
                            <button
                              onClick={() => this.props.copyToClipboard(position)}
                              className='button-to-normal'
                            >
                              <FileCopyIcon
                                fill='currentColor'
                                width='14'
                                height='14'
                              />
                            </button>
                          }
                        </span>
                      </div>
                    )}
                    <div className='clearfix item-row'>
                      <span className='row-label'>
                        {this.props.translate('address')}
                      </span>
                      <span className='row-value'>
                        {position.address
                          ? ReactHtmlParser(`${position.address}`)
                          : null}
                      </span>
                    </div>
                    {hdop ? (
                      <div className='clearfix item-row'>
                        <span className='row-label'>
                          {this.props.translate('hdop')}
                        </span>
                        <span className='row-value'>
                          {setAttributeFormat('hdop', hdop)}
                        </span>
                      </div>
                    ) : null}
                  </div> : <div className='feature-content-section'>{this.props.translate('notAvailable')}</div>}
                  {this.state.modal === true ? (
                    <ProtocolDialog
                      protocol={this.state.protocol}
                      input={this.state.input}
                      inputName={this.state.inputName}
                      onClose={this.closeProtocol}
                    />
                  ) 
                  : null}
            </>:null}

              {this.state.modal === true ? (
                <ProtocolDialog
                  protocol={this.state.protocol}
                  input={this.state.input}
                  inputName={this.state.inputName}
                  onClose={this.closeProtocol}
                />
              ) : null}

        </div>
        </div>
      )
    } 
    else if (this.props.type === 'Geofence') {

      let parking = 0
      let stopTime = 0
      let idling = 0
      const geo = [];
      
      for(const k in this.props.insideGeofence) {
        if(this.props.insideGeofence[k].includes(parseInt(position.deviceId))) {
          geo.push(k)
        }
      }

      if (
        position.attributes &&
        this.props.device.attributes &&
        position.attributes.stopTime &&
        this.props.device.attributes.minimalParkingDuration
      ) {
        if (
          parseInt(position.attributes.stopTime) >
          (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000)
        ) {
          if(position.attributes.parking) {
            const ms = (position.attributes.stopTime - (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000));
            parking = ms > 0 ? ms : 0 
            stopTime = ms < 0 ?position.attributes.stopTime : 0
            console.error(parking,stopTime,idling)
          }
          else {
            const ms = (position.attributes.idlTime - (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000));
            idling = ms > 0 ? ms : 0
            stopTime = ms < 0 ? position.attributes.idlTime : 0
          }
        } else {
          stopTime = position.attributes.stopTime
        }
      }
      // let latitude = position && position.latitude ? position.latitude : 0
      // let longitude = position && position.longitude ? position.longitude : 0

      // let hdop =
      //   position.attributes && position.attributes.hdop
      //     ? position.attributes.hdop
      //     : 0

      return ( 
      <div className='feature-section' style={{marginTop:5}}>
    <div style={{backgroundColor:this.props.toggleGeofence? "rgba(10, 131, 161, 0.50)":"rgba(10, 131, 161, 0.25)", margin:"0 10px", padding:8,borderRadius:8}}>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <span className='row-label' style={{fontSize:"14px", fontWeight:500}}>{this.props.translate('geofence')}
              </span>
              {/* <div style={{display:'flex',justifyContent:"start", alignItems:"center" }}>
               
               <span style={{ verticalAlign: 'middle' }}>
                 <GeofenceIcon fill='currentColor' width={14} height={14} />
               </span>
               <h3>
               {this.props.translate('Geofences')}
               </h3>
             </div> */}
                {/* <span onClick={()=>{this.setState({toggleBasic:!this.state.toggleBasic})}}>collapse </span> */}
               {/* { this.state.toggleBasic */}
                  <div>
                    <DragHandleIcon  style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleBasic",true)}} /> 
                    {this.props.toggleGeofence ? 
                    <ArrowDropUpIcon
                         onClick={()=>{this.props.handleBox("toggleGeofence", false)}}
                      style={{color:"#fff"}} /> : 
                      <ArrowDropDownIcon
                         onClick={()=>{this.props.handleBox("toggleGeofence", true)}}
                      style={{color:"#fff"}} /> }
                  </div>
               </div>


          {/* <h3>
            <span style={{ marginRight: 5, verticalAlign: 'middle' }}>
              <svg fill='currentColor' width={14} height={14}>
                <use xlinkHref={`${PinIcon}#icon`} />
              </svg>
            </span>
            {this.props.translate('location')}
          </h3> */}
            {position.exists ?
              <>
                {this.props.toggleGeofence ? 
              <div 
              //  className='feature-content-section'
              >

          {position.exists && this.props.geofences && this.props.geofences.length 
            && this.props.geofences.filter(g => geo.includes(g.id.toString())).length ?
           <div className='feature-section'>
               
                <div
                //  className="feature-content-section"
                style={{padding:"0px 5px"}}
                 >
                  {this.props.geofences.map((g,i) => {
                      if(geo.includes(g.id.toString())) {
                        return <div key={i} className="clearfix item-row"><span className="row-label">{g.name}</span></div> 
                      }
                      else {
                        return null;
                      }
                  })}
                </div>

              </div> : null}

              </div>: 
              <span >
                    {/* {position.address
                      ? ReactHtmlParser(`${position.address}`)
                      : null} */}
                  </span>}
              </>
              :
                <div className='feature-content-section'>{this.props.translate('notAvailable')}</div>}
              {this.state.modal === true ? (
                <ProtocolDialog
                  protocol={this.state.protocol}
                  input={this.state.input}
                  inputName={this.state.inputName}
                  onClose={this.closeProtocol}
                />
              ) : null}

        </div>
        </div>
      )
    } 
    else if (this.props.type === 'Sensors') {
      return (
        <div className='feature-section' style={{marginTop:5}}>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <div style={{backgroundColor:this.props.toggleSensor? "rgba(10, 131, 161, 0.50)":"rgba(10, 131, 161, 0.25)", 
          margin:"0 10px",
           padding:8,
           borderRadius:8}}>
              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                {/* <h3>{this.props.translate('sensors')}</h3> */}
                <span className='row-label' style={{fontSize:"14px", fontWeight:500}}>{this.props.translate('sensors')}
              </span>
                  <div>
                    <DragHandleIcon  style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleBasic",true)}} /> 
                    {this.props.toggleSensor ? 
                        <ArrowDropUpIcon style={{color:"#fff"}}  onClick={()=>{this.props.handleBox("toggleSensor", false)}}/> : 
                        <ArrowDropDownIcon   style={{color:"#fff"}}  onClick={()=>{this.props.handleBox("toggleSensor", true)}}/>
                      }
                  </div>
           </div>
          <div 
          // className='feature-content-section'
          style={{paddingRight:"10px"}}
          >
          {this.props.toggleSensor ?<>
            {position.attributes &&
            this.props.allComputedAttributes && 
            this.props.allComputedAttributes.length ? 
            this.props.allComputedAttributes.length && this.props.allComputedAttributes.map( ({item, deviceIds}) => deviceIds.includes(this.props.device.id) && item.attribute !== 'binUniqueId' && item.attribute !== 'trailerUniqueId' && item.attribute !== 'passengerUniqueId'?
                    <Wrapper
                      key={item.id}
                      {...item}
                      logInUserAttr={
                        this.props.logInUser
                          ? this.props.logInUser.attributes
                          : {}
                      }
                      category={item.category}
                      unit={item.attributes && item.attributes.unit}
                      customUnit={item.attributes && item.attributes.customUnit}
                      value={position.attributes[item.attribute]}
                      unknown={position.attributes.unknown}
                      drivers={this.props.drivers}
                      trailers={this.props.trailers}
                      translate={this.props.translate}
                      item2={item}
                    />
                  : null ): <>{this.props.translate('notAvailable')}</>}
         </>: <></>}
          </div>


            {/* {position.attributes &&
            this.props.allComputedAttributes && 
            this.props.allComputedAttributes.length ? 
            this.props.allComputedAttributes.length && this.props.allComputedAttributes.map( ({item, deviceIds}, index) => deviceIds.includes(this.props.device.id) && item.attribute === 'driverUniqueId' && item.attribute !== 'binUniqueId' && item.attribute !== 'trailerUniqueId' && item.attribute !== 'passengerUniqueId' ?
                    <React.Fragment key={index}>
                    <h3>{this.props.translate('sharedDriver')}</h3>
                      <div className='feature-content-section'>
                        <Wrapper
                          driverDetails
                          key={item.id}
                          {...item}
                          logInUserAttr={
                            this.props.logInUser
                              ? this.props.logInUser.attributes
                              : {}
                          }
                          category={item.category}
                          unit={item.attributes && item.attributes.unit}
                          customUnit={item.attributes && item.attributes.customUnit}
                          value={position.attributes[item.attribute]}
                          unknown={position.attributes.unknown}
                          drivers={this.props.drivers}
                          trailers={this.props.trailers}
                          translate={this.props.translate}
                        />
                      </div>
                      </React.Fragment>
                  : null) : null} */}
          </div>

        </div>
      )
    }
    // else if (this.props.type === 'DRIVER') {
    //   return (
    //     <div className='feature-section' style={{marginTop:"5px"}}>
    //     {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
    //     <div style={{backgroundColor:"#0a83a1", margin:"0 10px", padding:8,borderRadius:8}}>
    //         <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
    //           <h3>{this.props.translate('sharedDriver')}</h3>
    //           <IconButton
    //                   aria-label="Show more"
    //                   // className={classnames(classes.expand, { [classes.expandOpen]: expanded })}
    //                   onClick={()=>{this.setState({toggleDriver:!this.state.toggleDriver})}}
    //               >
    //                 {this.state.toggleDriver ? 
    //                   <ArrowDropDownIcon style={{color:"#fff"}} /> : 
    //                   <ArrowDropUpIcon   style={{color:"#fff"}} /> }
    //           </IconButton>
    //     </div>

    //   { position.attributes &&
    //         this.props.allComputedAttributes && 
    //         this.props.allComputedAttributes.length ? 
    //         this.props.allComputedAttributes.length && this.props.allComputedAttributes.map( ({item, deviceIds}, index) => deviceIds.includes(this.props.device.id) && item.attribute === 'driverUniqueId' && item.attribute !== 'binUniqueId' && item.attribute !== 'trailerUniqueId' && item.attribute !== 'passengerUniqueId' ?
    //                 <React.Fragment key={index}>
    //                 <h3>{this.props.translate('sharedDriver')}</h3>
    //                   <div className='feature-content-section'>
    //                   {this.state.toggleDriver ? 
    //                     <Wrapper
    //                       driverDetails
    //                       key={item.id}
    //                       {...item}
    //                       logInUserAttr={
    //                         this.props.logInUser
    //                           ? this.props.logInUser.attributes
    //                           : {}
    //                       }
    //                       category={item.category}
    //                       unit={item.attributes && item.attributes.unit}
    //                       customUnit={item.attributes && item.attributes.customUnit}
    //                       value={position.attributes[item.attribute]}
    //                       unknown={position.attributes.unknown}
    //                       drivers={this.props.drivers}
    //                       trailers={this.props.trailers}
    //                       translate={this.props.translate}
    //                     />:""}
    //                   </div>
    //                   </React.Fragment>
    //               : null) : null}
    //     </div>

    //   </div>
    //   )
    // }

  
    else if (this.props.type === 'Trailer') {
      return (
        <>
        {position.attributes&&position.attributes.trailerUniqueId ? 
        <div className='feature-section '  style={{marginTop:5}}>
          {/* <h3>{this.props.translate('sharedTrailer')}</h3> */}
          <div style={{backgroundColor:"#0a83a1", margin:"0 10px", padding:8,borderRadius:8}}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              {/* <h3>{this.props.translate('sharedTrailer')}</h3> */}
              <span className='row-label' style={{fontSize:"14px", fontWeight:500}}>{this.props.translate('sharedTrailer')}
              {/* <span className='row-value' style={{marginLeft:5}}>{this.props.device.uniqueId}</span> */}
              </span>
                    {this.props.toggleTrailer ?   
                    <ArrowDropUpIcon style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleTrailer", false)}}/> 
                    : <ArrowDropDownIcon  style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleTrailer", true)}}/> }
        </div>
          <div className='feature-content-section'>
            <div>
              {this.props.trailers && this.props.trailers.length ? this.props.trailers.map(ev => (
                position.attributes.trailerUniqueId === ev.uniqueId ? 
                <>
                <div className='clearfix item-row'>
                    <span className='row-label'>
                      {this.props.translate('sharedName')}
                    </span>
                    <span className='row-value'>
                      {ev.name}
                    </span>
                </div>
                {this.props.toggleTrailer ? 
                <div className='clearfix item-row'>
                    <span className='row-label'>
                     {this.props.translate('trailerUniqueId')}
                    </span>
                    <span className='row-value'>
                      {ev.uniqueId}
                    </span>
                </div> :null}
                </>
                : null
              )) : <>{this.props.translate('notAvailable')}</>}
            </div>
          </div>
        </div>
        </div>

        : null}
        </>
        )
      }

    else if (this.props.type === 'Bin') {
      if(checkPrivileges('binder')) {
      return (
        <>
        {position.attributes&&position.attributes.binUniqueId ? 
        <div className='feature-section event-section meter-section' style={{marginTop:5}}>
          <h3>{this.props.translate('sharedBin')}</h3>
          <div className='feature-content-section'>
            <div>
              {this.props.trailers && this.props.trailers.length ? this.props.trailers.map(ev => (
                position.attributes.binUniqueId === ev.uniqueId ? 
                <>
                <div className='clearfix item-row'>
                    <span className='row-label'>
                      {this.props.translate('sharedName')}
                    </span>
                    <span className='row-value'>
                      {ev.name}
                    </span>
                </div>
                <div className='clearfix item-row'>
                    <span className='row-label'>
                      {this.props.translate('binUniqueId')}
                    </span>
                    <span className='row-value'>
                      {ev.uniqueId}
                    </span>
                </div></>
                : null
              )) : null}
            </div>
          </div>
        </div>
        : null}
        </>
        )
              }
        else{
          return null
        }
      }

    else if (this.props.type === 'Passenger') {
      if(checkPrivileges('binder')) {
      return (
        <>
        {position.attributes&&position.attributes.passengerUniqueId ? 
        <div className='feature-section event-section meter-section'  style={{marginTop:5}}>
          <h3>{this.props.translate('sharedPassenger')}</h3>
          <div className='feature-content-section'>
            <div>
              {this.props.trailers && this.props.trailers.length ? this.props.trailers.map(ev => (
                position.attributes.passengerUniqueId === ev.uniqueId ? 
                <>
                <div className='clearfix item-row'>
                    <span className='row-label'>
                      {this.props.translate('sharedName')}
                    </span>
                    <span className='row-value'>
                      {ev.name}
                    </span>
                </div>
                <div className='clearfix item-row'>
                    <span className='row-label'>
                      {this.props.translate('passengerUniqueId')}
                    </span>
                    <span className='row-value'>
                      {ev.uniqueId}
                    </span>
                </div></>
                : null
              )) : <>{this.props.translate('notAvailable')}</>}
            </div>
          </div>
        </div>
        : null}
        </>
        )
        }
        else{
          return null
        }
      }

     else if (this.props.type === 'DRIVER') {
      return (
        <div className='feature-section' style={{marginTop:5}}>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <div style={{backgroundColor:this.props.toggleDriver? "rgba(10, 131, 161, 0.50)":"rgba(10, 131, 161, 0.25)", 
          margin:"0 10px",
           padding:8,
           borderRadius:8}}>
              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <span className='row-label' style={{fontSize:"14px", fontWeight:500}}>{this.props.translate('sharedDriver')}
              </span>
           
                    {this.props.toggleDriver ?  
                    <ArrowDropUpIcon style={{color:"#fff"}}  onClick={()=>{this.props.handleBox("toggleDriver", false)}} />
                     : <ArrowDropDownIcon   style={{color:"#fff"}}  onClick={()=>{this.props.handleBox("toggleDriver", true)}} /> }
        </div>
        {this.props.toggleDriver ?  
          <>
        {this.props.fixDriver ?
          <div className='feature-content-section'>
            <div>
                <div className='clearfix item-row'>
                    <span className='row-label'>
                      {this.props.translate('sharedName')}
                    </span>
                    <span className='row-value' >
                      {this.props.fixDriver.name}
                    </span>
                </div>
                {this.props.toggleDriver ?<>
                <div className='clearfix item-row'>
                    <span className='row-label'>
                     {this.props.translate('driverUniqueId')}
                    </span>
                    <span className='row-value'>
                      {this.props.fixDriver.uniqueId}
                    </span>
                </div>
                {this.props.fixDriver.attributes && this.props.fixDriver.attributes.phone ?
                <div className='clearfix item-row'>
                    <span className='row-label'>
                     {this.props.translate('sharedPhone')}
                    </span>
                    <span className='row-value'>
                      {this.props.fixDriver.attributes.phone}
                    </span>
                </div> : null}</>:""}
            </div>
          </div>
      :<>
          {position.attributes &&
            this.props.allComputedAttributes && 
            this.props.allComputedAttributes.length ? 
            this.props.allComputedAttributes.length && this.props.allComputedAttributes.map( ({item, deviceIds}) => deviceIds.includes(this.props.device.id) && item.attribute !== 'binUniqueId' && item.attribute !== 'trailerUniqueId' && item.attribute !== 'passengerUniqueId'?
                    <Wrapper1
                      key={item.id}
                      {...item}
                      logInUserAttr={
                        this.props.logInUser
                          ? this.props.logInUser.attributes
                          : {}
                      }
                      category={item.category}
                      unit={item.attributes && item.attributes.unit}
                      customUnit={item.attributes && item.attributes.customUnit}
                      value={position.attributes[item.attribute]}
                      unknown={position.attributes.unknown}
                      drivers={this.props.drivers}
                      trailers={this.props.trailers}
                      translate={this.props.translate}
                      item2={item}
                    />
                  : null ): <>{this.props.translate('notAvailable')}</>}
                  </>} </> :<></>}
        </div>
        </div>
      ) 
      }
      else if (this.props.type === 'EVENTS') {
      return (
        <>
        <div className='feature-section event-section meter-section'  style={{marginTop:5}}>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <div style={{backgroundColor:this.props.toggleEvent? "rgba(10, 131, 161, 0.50)":"rgba(10, 131, 161, 0.25)", margin:"0 10px", padding:8,borderRadius:8}}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <span className='row-label' style={{fontSize:"14px", fontWeight:500}} >{this.props.translate('event')}</span>
           
                    {this.props.toggleEvent ? 
                    <ArrowDropDownIcon style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleEvent", false)}}/> 
                    : <ArrowDropUpIcon  style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleEvent", true)}}/> }
        </div>
          {/* <h3>{this.props.translate('event')}</h3> */}
          <div
          //  className='feature-content-section'
           >
            <div>
              {this.props.events && this.props.events.length ? this.props.events.map(ev => (
                <p key={ev.id + '' + ev.type}>
                     {!this.props.toggleEvent ? <>
                  {/* {ev.name}  */}
                  </> :
                  <>
                  {ev.name} <TimeAgo title="" date={ev.serverTime || ev.eventTime} />
                  </>
                  }
                </p>
              )) : <>{this.props.translate('notAvailable')}</>}
            </div>
          </div>
        </div>
        </div>
        </>
      )
    }
    else if (this.props.type === 'WorkFlow') {
      return (
        <>
<>
        <div className='feature-section event-section meter-section'  style={{marginTop:5}}>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <div style={{backgroundColor:this.props.toggleWorKFLow? "rgba(10, 131, 161, 0.50)":"rgba(10, 131, 161, 0.25)", margin:"0 10px", padding:8,borderRadius:8}}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <span className='row-label' style={{fontSize:"14px", fontWeight:500}} >{this.props.translate('worKFLow')}</span>
           
                    {this.props.toggleWorKFLow ? 
                    <ArrowDropUpIcon style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleWorKFLow", false)}}/> 
                    : <ArrowDropDownIcon  style={{color:"#fff"}} onClick={()=>{this.props.handleBox("toggleWorKFLow", true)}}/> }
        </div>
          {/* <h3>{this.props.translate('event')}</h3> */}
          {this.props.toggleWorKFLow ? 
              <div className='feature-content-section'>
                    <div>
                      {this.props.device && this.props.device.attributes ? 
                        <>
                          <div className='clearfix item-row'>
                              <span className='row-label'>
                                {this.props.translate('cityMileage')}
                              </span>
                              <span className='row-value'>
                                {this.props.device.attributes.interCityMileage ? (this.props.device.attributes.interCityMileage/1000).toFixed(2) + ' ' +'km'   : ''}
                              </span>
                          </div>
                          <div className='clearfix item-row'>
                              <span className='row-label'>
                                {this.props.translate('OutSideCityMileage')}
                              </span>
                              <span className='row-value'>
                                {this.props.device.attributes.cityMileage ? (this.props.device.attributes.cityMileage/1000).toFixed(2) + ' ' +'km'  : ''}
                              </span>
                          </div>
                        </>
                        : null}
                    </div>
                  </div> :null}
        </div>
        </div>
        </>










        {/* {this.props.device&&this.props.device.attributes&&this.props.device.attributes.onWorkFlow ? 
          <>
            <div className='feature-section event-section meter-section'>
              <h3>{this.props.translate('worKFLow')}</h3>
              <div className='feature-content-section'>
                <div>
                  {this.props.device && this.props.device.attributes ? 
                    <>
                      <div className='clearfix item-row'>
                          <span className='row-label'>
                            {this.props.translate('cityMileage')}
                          </span>
                          <span className='row-value'>
                            {this.props.device.attributes.interCityMileage ? (this.props.device.attributes.interCityMileage/1000).toFixed(2) + ' ' +'km'   : ''}
                          </span>
                      </div>
                      <div className='clearfix item-row'>
                          <span className='row-label'>
                            {this.props.translate('OutSideCityMileage')}
                          </span>
                          <span className='row-value'>
                            {this.props.device.attributes.cityMileage ? (this.props.device.attributes.cityMileage/1000).toFixed(2) + ' ' +'km'  : ''}
                          </span>
                      </div>
                    </>
                    : null}
                </div>
              </div>
            </div>
          </>
         : null} */}
        </>
        )
    }
     else if (this.props.type === 'VEHICLES') {
      return (
        <div className='feature-section' style={{marginTop:5}}>
        {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
        <div style={{backgroundColor:this.props.toggleVehicle? "rgba(10, 131, 161, 0.50)":"rgba(10, 131, 161, 0.25)", margin:"0 10px", padding:8,borderRadius:8}}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <span  className='row-label' style={{fontSize:"14px", fontWeight:500}}>{this.props.translate('sharedVehicle')}</span>
                {this.state.toggleVehicle ? 
                 <ArrowDropUpIcon style={{color:"#fff"}} onClick={()=>{this.setState({toggleVehicle:!this.state.toggleVehicle})}}/> 
                 : <ArrowDropDownIcon   style={{color:"#fff"}} onClick={()=>{this.setState({toggleVehicle:!this.state.toggleVehicle})}}/> }
        </div>
              {this.props.vehicles && this.props.vehicles.length ? this.props.vehicles.map(ev => (
                this.props.device.id === ev.deviceId ? 
                <>
                <div
                //  className='feature-section event-section meter-section'
                 >
                  {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
                  {/* <h3>{this.props.translate('sharedVehicle')}</h3> */}
                  <div
                  //  className='feature-content-section'
                   >
                  {this.state.toggleVehicle ? 
                  <>
                        <div className='clearfix item-row'>
                            <span className='row-label'>
                              {this.props.translate('sharedName')}
                            </span>
                            <span className='row-value'>
                              {ev.label}
                            </span>
                        </div>
                        <div className='clearfix item-row'>
                            <span className='row-label'>
                            {this.props.translate('vehicleLicensePlate')}
                            </span>
                            <span className='row-value'>
                              {ev.vehicleLicensePlate}
                            </span>
                        </div>
                        </>:
                        <div className='clearfix item-row'>
                            <span className='row-label'>
                              {this.props.translate('sharedName')}
                            </span>
                            <span className='row-value'>
                              {ev.label}
                            </span>
                        </div>
                        }
                        </div>
                </div>
                </>
                : null
              )) 
              : null
              }
            </div>
            </div>
         
        )
      }



      else if (this.props.type === 'ODOMETER') {
      return (
        <div className='feature-section meter-section' style={{marginTop:5}}>
          <div className='feature-content-section odo-meter-section'>
            <ul className='list-unstyled clearfix' style={{backgroundColor:"rgba(10, 131, 161, 0.50)"}}>
              <li className='odo-meter-header pull-left'>
                {this.props.translate('odometer')}
              </li>
              <li className='odo-meter-action pull-right'>
                <a
                  className={
                    'type-toggle ' +
                    (this.state.displayOdo === 'km' ? 'active' : '')
                  }
                  href={emptyLinks}
                  onClick={() => this.convertOdometer('km')}
                >
                  KM
                </a>
                |
                <a
                  className={
                    'type-toggle ' +
                    (this.state.displayOdo === 'mi' ? 'active' : '')
                  }
                  href={emptyLinks}
                  onClick={() => this.convertOdometer('mi')}
                >
                  Mile
                </a>
              </li>
              <li className='odo-meter pull-right'>
                <DrawHTML word={this.state[this.state.displayOdo]} />
              </li>
            </ul>
            {/* <TimeAgo title="" date={position.serverTime} minPeriod={60} /> */}
          </div>
        </div>
      )
      } else if (this.props.type === 'ENGINEHOURS') {
        return (
        <div className='feature-section meter-section' style={{marginTop:5,}}>
          <div className='feature-content-section odo-meter-section'>
            <ul className='list-unstyled clearfix' style={{backgroundColor:"rgba(10, 131, 161, 0.50)"}}>
              <li className='odo-meter-header pull-left'>
                {this.props.translate('reportEngineHours')}
              </li>
              <li className='odo-meter pull-right'>
              {this.props.details &&
                this.props.details.attributes &&
                this.props.details.attributes.hours ? (this.props.details.attributes.hours / (1000 * 3600)).toFixed(2)+" hrs" : null}
              </li>
            </ul>
            {/* <TimeAgo title="" date={position.serverTime} minPeriod={60} /> */}
          </div>
        </div>
      )
    } else if (
      this.props.type === 'INPUT' 
      // && this.props.device.model !== 'FX Tracker'
    ) {
      return (
        <div className='feature-section meter-section' style={{marginTop:10}}>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <div className='feature-content-section input-meter-section'>
            <ul className='list-unstyled clearfix' style={{backgroundColor:'rgba(10, 131, 161, 0.50)'}}>
              <li className='odo-meter-header pull-left'>
                {this.props.translate('input')}
              </li>
              <li className='odo-meter pull-right'>
               <DrawInput sensorsDesc= {this.props.sensorsDesc}  attributes={position.attributes} translate={this.props.translate} />
              </li>
            </ul>
            <TimeAgo title="" date={position.serverTime} minPeriod={60} />
          </div>
        </div>
      )
      // return (
      //   <div className='feature-section meter-section' style={{marginTop:5}}>
      //      <div style={{backgroundColor:'rgba(10, 131, 161, 0.50)',  margin:"0 10px", padding:8,borderRadius:8}}>
      //       <ul className='list-unstyled clearfix'  style={{ }}>
      //         <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",backgroundColor:'rgba(10, 131, 161, 0.50)'}}>
      //             <span className='row-label' style={{fontSize:"14px", fontWeight:500}}>{this.props.translate('input')}</span>
      //         </div>
             
      //         <li className='odo-meter' style={{margin:"5px 0"}}>
      //           <DrawInput sensorsDesc= {this.props.sensorsDesc}  attributes={position.attributes} />
      //         </li>
      //       </ul>
      //     </div>
      //   </div>
        
      // )
    } 
    
  
    else if (
      this.props.type === 'OUTPUT' 
      // &&
      // this.props.device.model !== 'FX Tracker'
    ) {
      return (
        
        <div className='feature-section meter-section'>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <div className='feature-content-section input-meter-section'>
            <ul className='list-unstyled clearfix' style={{backgroundColor:'rgba(10, 131, 161, 0.50)'}}>
              <li className='odo-meter-header pull-left'>
                {this.props.translate('output')}
              </li>
              <li className='odo-meter pull-right'>
                <DrawOutputHTML
                  sensorsDesc= {this.props.sensorsDesc}
                  attributes={position.attributes} 
                    word={
                      position.attributes && position.attributes.output
                        ? position.attributes.output
                        : 0
                    }
                  />
              </li>
            </ul>
            <TimeAgo title="" date={position.serverTime} minPeriod={60} />
          </div>
        </div>
      )
    } else if (
      this.props.type === 'INPUT' && // eslint-disable-line no-dupe-else-if
      this.props.device.model === 'FX Tracker' &&
      position &&
      position.attributes &&
      position.attributes.networkType
    ) {
      return (
        <div className='feature-section meter-section' style={{marginTop:5}}>
          <div className='feature-content-section odo-meter-section'>
            <ul className='list-unstyled clearfix' style={{backgroundColor:"rgba(10, 131, 161, 0.50)"}}>
     
              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <h3>{this.props.translate('GSM')}</h3>
             
        </div>
              <li className='odo-meter-action'>
                {position.attributes&&position.attributes.cellularType
                  ? position.attributes.cellularType
                  : signalStrength(position.attributes)}
              </li>
              <li className='odo-meter' style={{ marginTop: 6 }}>
                <span style={{ color: '#242121' }}>
                  {this.props.translate(position.attributes.networkType)}
                </span>
                |
              </li>
            </ul>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export class DrawHTML extends PureComponent {
  render () {
    const s = '' + this.props.word
    const cls = this.props.className || 'letter'
    const p = s.split(' ')
    if (p.length) {
      const before = p[0].split('')

      let w = [
        before.map((c, i) => {
          return (
            <span key={i} className={cls + ' ' + cls + '-' + i}>
              {c}
            </span>
          )
        })
      ]

      if (p[1]) {
        const after = p[1].split('')
        w.push(
          after.map((c, i) => {
            return (
              <span
                key={i}
                className={cls + ' ' + cls + '-alt ' + cls + '-' + i}
              >
                {c}
              </span>
            )
          })
        )
      }

      return w
    }
  }
}

export class DrawOutputHTML extends PureComponent {
  render() {
    const s = this.props.attributes || {};
    const cls = this.props.className || 'device-input';

    let a = {};
    a.value0 = s.dout0;
    a.value1 = s.dout1;
    a.value2 = s.dout2;
    a.value3 = s.dout3;
    a.value4 = s.dout4;
    a.value5 = s.dout5;

    const html = [];

    for (let i = 1; i <= 10; i++) {
      const attribute = a['value' + i];
      const ff = this?.props?.sensorsDesc?.find(
        (item) => item.attributes.key === i && item.attributes.IOtype === 'output'
      );

      const attributeValue = ff ? ff.attribute : '';
      const sensorValue = s.hasOwnProperty(attributeValue) ? s[attributeValue] : ''; // eslint-disable-line no-prototype-builtins
      const toolTipLabel = (ff?.description || '') + (sensorValue && i ? '-ON' : '-OFF');

      html.push(
        <Tooltip key={i} className="tooltip-container" title={toolTipLabel}>
          <span
            className={(attribute ? cls + '-alt ' : '') + cls + ' ' + cls + '-' + i}
            style={{
              backgroundColor: ff&&ff.attributes&&ff.attributes.IOtype==="output" ? 
                  sensorValue && i ? "green" : "red" 
            : ""}}
          >
            {i}
          </span>
        </Tooltip>
      );
    }

    return html;
  }
}


export class DrawInput extends PureComponent {
  render() {
    const s = this.props.attributes || {};
    const cls = this.props.className || 'device-input';
    let a = {};

    a.adc0 = s.din0 || s.input1;
    a.adc1 = s.din1 || s.input2;
    a.adc2 = s.din2 || s.input3;
    a.adc3 = s.din3 || s.input4;
    a.adc4 = s.din4 || s.input5;
    a.adc5 = s.din5 || s.input6;

    const html = [];

    for (let i = 1; i <= 10; i++) {
      const attribute = a['adc' + i];
      const ff = this?.props?.sensorsDesc?.find(
        (item) => item.attributes.key === i && item.attributes.IOtype === 'input'
      );
        
      const attributeValue = ff ? ff.attribute : '';
      const sensorValue = s.hasOwnProperty(attributeValue) ? s[attributeValue] : ''; // eslint-disable-line no-prototype-builtins
      const toolTipLabel = (ff?.description || '') + (sensorValue && i ? '-ON' : '-OFF');

      html.push(
        <Tooltip key={i} className="tooltip-container" title={toolTipLabel}>
          <span
            className={(attribute ? cls + '-alt ' : '') + cls + ' ' + cls + '-' + i}
            style={{
              backgroundColor: ff&&ff.attributes&&ff.attributes.IOtype==="input" ? 
                  sensorValue && i ? "green" : "red" 
            : ""}}
          >
            {i}
          </span>
        </Tooltip>
      );
    }

    return html;
  }
}

const Wrapper = ({
  // unknown,
  attribute,
  value,
  unit,
  customUnit,
  description,
  logInUserAttr,
  category,
  drivers,
  // trailers,
  translate,
  driverDetails,
  // item2
}) => {
  let u = ''
  // let trailer = null
  // if(attribute === 'trailerUniqueId' && trailers && trailers.length && parseInt(value)) {
  //     let trailerName = "";
  //     trailers.map(d => {
  //       if (
  //         value && 
  //         d.uniqueId === value
  //       ) {
  //         trailerName = d.name
  //       }
  //       return null
  //     })

  //     trailer = (<div className='clearfix item-row'>
  //       <TrailerIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('trailersName')}</span>
  //       <span className='row-value'>
  //           {trailerName || 'unknown'}
  //       </span>
  //     </div>);
  // }
  let driver = null
  if(attribute === 'driverUniqueId' && drivers && drivers.length && (value) && driverDetails) {    //parseInt(value) false if uniqueId have character values
    let driverName = "";
    let driverPhone = "";
    drivers.map(d => {
      if (
        value && 
        d.uniqueId === value
      ) {
        driverName = d.name
        driverPhone = d.attributes.phone
      }
      return null
    })

    driver = (<><div className='clearfix item-row'>
      <StaffIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('driverName')}</span>
      <span className='row-value'>
          {driverName || 'unknown'}
      </span>
    </div>
    <div className='clearfix item-row'>
      <CallIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('driverPhone')}</span>
      <span className='row-value'>
          {driverPhone || ''}
      </span>
    </div></>);
  }
  if (attribute === 'mileage' || attribute === 'distance') {
    u = logInUserAttr.distanceUnit || customUnit || unit
      // u = item2.attributes.unit || customUnit || unit
  } else if (attribute === 'fuel') {
    u = logInUserAttr.volumeUnit || customUnit || unit
  } else if (attribute === 'speed') {
    u = logInUserAttr.speedUnit || customUnit || unit
  } else {
    u = customUnit || unit
  }
  /* if (unknown && unknown.length && unknown.indexOf(attribute) !== -1) {
    return (
      <div className='clearfix item-row'>
        <span className='row-label'>{description}</span>
        <span className='row-value'>Unknown</span>
      </div>
    )
  } else */
   if (value !== undefined || attribute === 'ignition' ) {
        return (
          <>
          <div className='clearfix item-row'>
            {category === 0 ? <ElogicIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} />
             : category === 1 ? <DiscreteIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
             : category === 2 ? <MeasurementIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
             : category === 3 ? <OtherSensorIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
             : <AggregateIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} />}
            <span className='row-label' style={{marginLeft: 5}}>{description}</span>
            <span className='row-value'>
              {setAttributeFormat(attribute, value, u, null, category)}
            </span>
          </div>
          {driver}
          </>
        )
      }  
   else return null
}

const Wrapper1 = ({
  // unknown,
  attribute,
  value,
  // unit,
  // customUnit,
  // description,
  // logInUserAttr,
  // category,
  drivers,
  // trailers,
  translate,
  // driverDetails,
  // item2
}) => {
  // let u = ''
  // let trailer = null
  // if(attribute === 'trailerUniqueId' && trailers && trailers.length && parseInt(value)) {
  //     let trailerName = "";
  //     trailers.map(d => {
  //       if (
  //         value && 
  //         d.uniqueId === value
  //       ) {
  //         trailerName = d.name
  //       }
  //       return null
  //     })

  //     trailer = (<div className='clearfix item-row'>
  //       <TrailerIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('trailersName')}</span>
  //       <span className='row-value'>
  //           {trailerName || 'unknown'}
  //       </span>
  //     </div>);
  // }
  let driver = null
  // if(attribute === 'driverUniqueId' && drivers && drivers.length && (value) && driverDetails) {    //parseInt(value) false if uniqueId have character values
    if(attribute === 'driverUniqueId' && drivers && drivers.length && (value) ) {    //parseInt(value) false if uniqueId have character values
    let driverName = "";
    let driverPhone = "";
    let uniqueId = "";
    drivers.map(d => {
      if (
        value && 
        d.uniqueId === value
      ) {
        driverName = d.name
        driverPhone = d.attributes.phone
        uniqueId = d.uniqueId
      }
      return null
    })

    driver = (<>
    <div className='clearfix item-row'>
      <StaffIcon style={{verticalAlign: 'middle'}} 
      fill="currentColor" width={16} height={16} /> 
      <span style={{marginLeft: 5}} className='row-label'>
        {translate && translate('driverId')}</span>
      <span className='row-value' style={{ maxWidth:140,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",}}>
          {uniqueId || '-'}
      </span>
    </div>
    
    <div className='clearfix item-row'>
      <StaffIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('driverName')}</span>
      <span className='row-value' style={{ maxWidth:140,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",}}>
          {driverName || 'unknown'}
      </span>
    </div>
    <div className='clearfix item-row'>
      <CallIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('driverPhone')}</span>
      <span className='row-value' style={{ maxWidth:140,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",}}>
          {driverPhone || ''}
      </span>
    </div></>);
  }

   if (value !== undefined  ) {
        return (
          <>
          {driver}
          </>
        )
      }  
   else return null
}
export const FeatureRow = connect(mapStateToProps)(Row)

const signalStrength = att => {
  let ssd = 0
  if (att.signalStrength) {
    ssd = Math.round(att.signalStrength / 20)
  }
  let drawValue = []
  for (let i = 1; i < 6; i++) {
    if (i <= ssd) {
      drawValue.push(
        <li className={'sg-' + i}>
          <div id={'sg-' + i} style={{ background: 'white' }}></div>
        </li>
      )
    } else {
      drawValue.push(
        <li className={'sg-' + i}>
          <div id={'sg-' + i} style={{ background: '#04526666' }}></div>
        </li>
      )
    }
  }
  return (
    <ul id='signal-strength' style={{ padding: 0 }}>
      {drawValue}
    </ul>
  )
}
