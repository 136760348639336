import React, { useState,useEffect } from 'react'
import Dialog from '../common/Dialog'
import Tooltip from "../../Components/common/Tooltip"
import 'leaflet-control-geocoder'
import {useDispatch } from 'react-redux'
import TextField from '../common/TextField'
import Button from '../common/Button'
import PublishIcon from '@mui/icons-material/Publish';
import { withLocalize } from 'react-localize-redux'
import moment from 'moment'
// import L from 'leaflet'
import instance from '../../axios'
import Notifications from 'react-notification-system-redux'
import { errorHandler } from '../../Helpers'
import { addGeoFence, updateGeoFence } from '../../Actions/Devices'
const DataFormat = (data) => {
  // const remarksString = form.creditDebitNote.remarks?.join(", ") || "";

  return {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : '',
    calendarId: data.calendarId ? data.calendarId : 0,
    description: data.description ? data.description : "",
    area: data.area ? data.area : "",
    attributes: {
      ...data.attributes
    }
  };
}
const TrackModal = (props) => {
  const dispatch = useDispatch();
  const initialKmlDataForm = props.geofence || {
    name: "",
    calendarId: 0,
    description: "",
    area: "",
    attributes: {
      color: "",
      imported: false,
      type: "kml"
    }
  };
const edit=props.geofence?true:false
  const [kmlDataForm, setKmlDataForm] = useState(initialKmlDataForm);
  const [kmlContent, setKmlContent] = useState('');
  const [kmlType, setKmlType] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [kmlFile, setKmlFile] = useState(null);

  useEffect(() => {
    return () => {
      setKmlDataForm({
        name: "",
        calendarId: 0,
        description: "",
        area: "",
        attributes: {
          color: "",
          imported: false,
          type: "kml"
        }
      });
      setKmlContent('');
      setKmlType('');
      setDisableSubmit(true);
    };
  }, []);

  const onClose = () => {
    props.onClose();
  };

  const handleChange = (name, value) => {
    setKmlDataForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));
  };

  const checkReqFields = () => {
    const { name, area } = kmlDataForm;
    if (name && area) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  };
  useEffect(()=>{
    checkReqFields()
  },[kmlDataForm])

  const getLatLngString = (layer) => {
    if (!layer || !layer.latLngs || !Array.isArray(layer.latLngs)) {
      return '';
    }

    const latLngString = layer.latLngs.map(latLng => {
      if (latLng && latLng.lat && latLng.lng) {
        return `${latLng.lat} ${latLng.lng}`;
      } else {
        return '';
      }
    }).join(', ');

    return latLngString;
  };

  const uploadTrigger = () => {
    const el = document.getElementById('uploadTrigger')
    el.click()
  };

  const upload = (n) => {
    if (n.target.files) {
      let e = n.target.files[0],
        t = e.name.split('.'),
        i = e.size,
        ko = 50000000,
        r = ['geojson', 'json', 'gpx', 'kml', 'xml'],
        u = t[t.length - 1].toLowerCase()

      if (r.includes(u)) {
        if (i > ko) {
          return (
            'File size is too large. Max allowed size is ' +
            Math.round(ko / (1024 * 1000)) +
            'MB'
          )
        } else {
          let p = new FileReader()
          // const base64String = p.result;

          p.readAsText(e, 'UTF-8')
          p.onload = async o => {
            let n = o.target.result

            if ('kml' === u) {
              let t = new DOMParser().parseFromString(n, 'text/xml'),
                tag = t && t.documentElement && t.documentElement.nodeName
              if ('kml' !== tag && 'gpx' !== tag) {
                return 'File content is not valid.'
              }
              u = tag
            }

            const parser = new DOMParser()
            const kml = parser.parseFromString(n, 'text/xml')

            const layer = new L.KML(kml)
            const latLonString = getLatLngString(layer);

            // geofence work start from here...

            const geofences = [];
            // Object.values(layer._layers).map((l, i) =>  {

            //     let distance = 0
            //     let prepare = l._latlngs.map((pos, index) => {
            //       distance += pos.distanceTo(l._latlngs[index - 1] || pos)
            //       return '' + pos.lat + ' ' + pos.lng + ''
            //     })


            geofences.push({
              name: 'Test KML ' + (moment().format('YYYY-MM-DD') + ' ' + (i + 1)),
              calendarId: 0,
              description: 'Test KML',
              area: 'KML (' + latLonString + ')',
              attributes: {
                imported: true,
                // color: l.options.fillColor,
                // polylineDistance: distance / 1000
              }
            });
            // });
            // for(const geo of geofences) {

            //   const response = await Axios.post(`/api/geofences`, geo);
            //   console.log("response=====",response);

            // }
            setKmlContent(kml)
            setKmlType(u)
            setKmlDataForm((prevState) => ({
              ...prevState,
              area: 'KML (' + latLonString + ')',
              attributes: { ...prevState.attributes }
            }))
            return {
              name: e.name,
              size: Math.round(e.size / (1024 * 1000)) + 'MB'
            }
          }
        }
      } else {
        return 'Unsupported file type.'
      }
      return { name: e.name, size: (e.size / 1024).toFixed(2) + 'KB' }
    }
  }

  const kmlFileUpload = (event) => {
    if(event && event.target && event.target.files && event.target.files.length) {
      const kmlFile = upload(event)
      if (kmlFile) {
        setKmlFile(kmlFile)
      }
    }
  };

  const submitKMLForm = async()=>{
    console.warn(kmlFile)
    console.warn(kmlType)
    console.warn(kmlContent)


    const geo = kmlDataForm;
    let payload = DataFormat(geo)
    setDisableSubmit(true)
    onClose()
      instance({
        url:edit ? `/api/geofences/${kmlDataForm.id}` : `/api/geofences`,
        method: edit ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data :{
          ...payload
        }
      })
      .then(geofence => {
        setDisableSubmit(true)
        onClose()
        if(edit){
          dispatch(updateGeoFence({ ...geofence, visible: true }))
          dispatch(updateGeoFence({ ...geofence, visible: false }))
        }else {
          dispatch(
            addGeoFence([{ ...geofence, visible: true }])
          )
        }
            dispatch(
              Notifications.success({
                message:edit ? props.translate('kmlTrackUpdated') : props.translate('kmlTrackCreated'),
                autoDismiss: 10
              })
            )
          })
      .catch(error => {errorHandler(error,dispatch)})
  // }
  }
  return (
    <Dialog
      disableFooter
      title={props.translate('Tracks')}
      onClose={onClose}
      maxWidth='sm'
      // onFullScreen={onFullScreen}
    >
          <div style={{ position: 'relative' , padding:"10px"}}>
          <div  style={{display:"flex",  justifyContent:"space-between",  alignItems:"center"}}>
              <TextField
              fullWidth
              label={props.translate('sharedName')}
              required
              value={kmlDataForm.name || ''}
              onChange={e =>
                  handleChange('name',e.target.value)
                }
                />
              {!edit && (
                  <div style={{marginLeft:"10px"}} >
                      <Tooltip title={props.translate('uploadkml')}>
                        <Button 
                        onClick={uploadTrigger}
                        >
                          <PublishIcon style={{ fontSize: 18 ,  color:"white"}}/>
                        </Button>
                      </Tooltip>
                      <input
                        id='uploadTrigger'
                        style={{ display: 'none' }}
                        type='file'
                        onChange={kmlFileUpload}
                      />
                    </div>
       


                  )}
                    </div>
{
  kmlFile&&<h5 style={{margin:'10px 2px',padding:'0px',fontSize:"1rem"}}>Uploaded File : {kmlFile?.name}</h5>

}
              <TextField
              fullWidth
              rows={5}
              multiline
              value={kmlDataForm.description || ''}
              label={props.translate('sharedDescription')}
              onChange={e =>
                handleChange('description',e.target.value)
              }
              />
          </div>

            <div className='geofence-modal-form-footer'>
              <Button
                onClick={onClose}
                style={{ marginRight: 10 }}
                className={'button-white'}
              >
                {props.translate('sharedCancel')}
              </Button>
              <Button 
                disabled={disableSubmit}
                onClick={submitKMLForm}>
                  {edit
                    ? props.translate('update')
                    : props.translate('sharedCreate')}
              </Button>
        </div>
    </Dialog>
  )
};

export default withLocalize(TrackModal)
