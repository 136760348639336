import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { checkPrivileges, selectAll } from '../../Helpers';
import Dialog from '../common/Dialog';
import Table from '../common/TableMultiCheckbox';
import AddRecourcesGroup from './AddRecourcesGroup';
import { useSelector } from 'react-redux';

const resourceModal = (props) => {
  const [isCreate, setIsCreate] = useState(true)
  const [isVisableUserBtn, setIsVisableUserBtn] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [processing, setProcessing] = useState(false)
  const [form, setForm] = useState({})
  const themecolors = useSelector((state) => state.themeColors)

  useEffect(() => {
    checkRequiredFields();
  }, [form])

  const handleChange = (name, event) => {
    const { target } = event;
    let value = target.value.toString();
    setForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };
      return updatedForm;
    });
  };


  const checkRequiredFields = () => {
    let isShow = (form?.name && !isCreate) || (form?.name !== props.selectedResourse?.name && form?.name)
    if (isShow) {
      setIsVisableUserBtn(true)
    } else {
      setIsVisableUserBtn(false);
    }
  };

  const formSubmit = () => {
    props.changeResource(form);
    props.onCloseResource();
    setIsCreate(true);
  };

  const checkHandleChange = (preState, item) => {
    setProcessing(true);
    if (preState) {
      props.assignItem(form.id, item.id, () => setProcessingFunc(false));
    } else {
      props.unassignItem(form.id, item.id, () => setProcessingFunc(false));
    }
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
  };

  const onChange = () => {
    props.fetchNestedItems(props.selectedResourse.id, 1);
  };

  const setProcessingFunc = (processing) => {
    setProcessing(processing)
  }

  let itemData = []
  props.itemPagination && props.itemPagination.map((item) => {
    if (item.type === "kmlFile") {
      // Skip items with type "kmlFile"
      return;
    }

    if (props.nestedResources.data.find(({ id }) => id === item.id)) {
      item = {
        ...item,
        check: true
      };
    } else {
      item = {
        ...item,
        check: false
      };
    }

    itemData.push(item);
  });


  let rowDefinition = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: props.translate('sharedName')
    },
    {
      id: 'uniqueId',
      numeric: false,
      disablePadding: false,
      label: props.translate('uniqueId')
    }
  ];

  if (props.itemType === 'Vehicle') {
    rowDefinition = [
      {
        id: 'label',
        numeric: false,
        disablePadding: false,
        label: props.translate('sharedName')
      },
      {
        id: 'vehicleLicensePlate',
        numeric: false,
        disablePadding: false,
        label: props.translate('vehicleLicensePlate')
      }
    ]
  }

  if (props.itemType === 'Geofence') {
    rowDefinition = [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: props.translate('sharedName')
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: props.translate('sharedDescription'),
      }
    ]
  }
  useEffect(() => {
    if (props.selectedResourse) {
      setForm(props.selectedResourse);
      setIsVisableUserBtn(false);
    } else {
      setForm({ itemType: props.itemType });
    }
  }, [props.selectedResourse, props.itemType]);

  useEffect(() => {
    if (props.selectedResourse && props.selectedResourse.id !== (form && form.id)) {
      setForm(props.selectedResourse);
      setIsCreate(true);
    } else if (props.activeOperation === 'addResource' && isCreate) {
      setForm({ itemType: props.itemType });
      setIsCreate(false);
    }
  }, [props]);
  return (
    <div className="main-content-page">

      {['addResource', 'editResource'].includes(props.activeOperation) && (
        <Grid
          container
          spacing={0}
          className='driver-page-content'
          style={{
            background: themecolors.backgroundColor,
            color: themecolors.textColor
          }}
        >
          <AddRecourcesGroup
            isCreate={isCreate}
            isVisableUserBtn={isVisableUserBtn}
            form={form}
            processing={processing}
            rowsPerPage={rowsPerPage}
            title={props.title}
            itemType={props.itemType}
            handleChange={handleChange}
            formSubmit={formSubmit}
            onClose={props.onCloseResource}
          />
        </Grid>
      )}

      {props.linkResource && (
        <Dialog
          disableFooter
          title={props.selectedResourse.name}
          onClose={props.onCloseResource}
          maxWidth='sm'
        // onFullScreen={onFullScreen}
        >
          <Table
            rows={itemData || []}
            title={processing || props.nestedResources.loading ? props.translate('sharedLoading') : props.itemType}
            checkHandleChange={checkHandleChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            searchable
            // insideDialog
            // isEditable
            selectAll={e =>
              selectAll(
                e,
                'itemgroupid',
                'itemid',
                props.selectedResourse.id,
                itemData,
                itemData,
                props.dispatch,
                'abc',
                props.translate,
                onChange
              )
            }
            disableHead
            showCheckbox
            rowsPerPage={rowsPerPage}
            canRemove={checkPrivileges('itemgroupUnlinkItem')}
            canAssign={checkPrivileges('itemgroupLinkItem')}
            rowDefinition={rowDefinition}
          />
        </Dialog>
      )}
    </div>
  );

}
export default resourceModal