import React from 'react'
import Grid from '@mui/material/Grid'
import Table from '../../../Components/common/TableMultiCheckbox'


const CommonTableSection=(props)=>{
  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12}>
        <Table
          {...props}
          title={props.label}
          disableHead
          checkHandleChange={props.component_Selection}
          rows={props.componentData}
          isEditable
          searchable
          className='custom-table2'
        />
      </Grid>
    </Grid>
  )
}
export default CommonTableSection
// export default class CommonTableSection extends Component {
//   render () {

//   }
// }
