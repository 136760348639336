import React, { useCallback, useEffect, useReducer, useState } from 'react'
import AddGroupModal from './addGroupModal2'
import MarkerMap from "../common/Maps"
import CustomDialog from '../common/Dialog'
import { getGroups, addGroup, removeGroup, updateGroup } from '../../Actions/Groups'
import Button from '../common/Button';
import 'react-confirm-alert/src/react-confirm-alert.css'
import "./groupsModal.scss";
import isEqual from 'react-fast-compare';
import { checkPrivileges, errorHandler } from '../../Helpers'
import Notifications from 'react-notification-system-redux';
import instance from '../../axios';
import { useSelector } from 'react-redux'

const userDataFormat = (data, extAttriubtes, itemId) => {
  return {
    'attributes': extAttriubtes ? extAttriubtes : {},
    'id': itemId ? itemId : 0,
    'groupId': data.groupId ? data.groupId : "",
    'name': data.name ? data.name.trim() : ""
  }
}

const formDefaultState = {
  id: "",
  name: '',
  groupId: "",
  attributes: {}
}
const initialState = {
  deleteProcess: false,
  selectedDevice: '',
  addOption: false,
  editOption: false,
  u_attriubtes: '',
  defaultUserList: false,
  selectedOption: false,
  passwordOption: false,
  selectedDeviceTrackesList: '',
  isSelectedDevice: false,
  localTime: new Date(),
  directOption: false,
  isVisableUserBtn: false,
  isAttChanged: false,
  usesId: '',
  isMapModal: false,
  duplicateEntry: false,
  isVisableAddlocBtn: false,
  localPostionObj: '',
  selectedDevices: '',
  usersArray: [],
  itemAttributes: {},
  attributeChangesMessage: '',
  form: {
    ...formDefaultState
  }
};

const reducersGroups = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FORM':
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload
        }
      };
    case 'SET_IS_DELETE_PROCESS':
      return {
        ...state,
        deleteProcess: action.payload
      };
    case 'SET_SELECTED_DEVICE':
      return {
        ...state,
        selectedDevice: action.payload
      };
    case 'SET_IS_ADD_OPTION':
      return {
        ...state,
        addOption: action.payload
      };
    case 'SET_IS_EDIT_OPTION':
      return {
        ...state,
        editOption: action.payload
      };
    case 'SET_U_ATTRIBUTES':
      return {
        ...state,
        u_attriubtes: action.payload
      };
    case 'SET_IS_DEFAULT_USER_LIST':
      return {
        ...state,
        defaultUserList: action.payload
      };
    case 'SET_IS_SELECTED_OPTION':
      return {
        ...state,
        selectedOption: action.payload
      };
    case 'SET_IS_PASSWORD_OPTION':
      return {
        ...state,
        passwordOption: action.payload
      };
    case 'SET_SELECTED_DEVICE_TRACKES_LIST':
      return {
        ...state,
        selectedDeviceTrackesList: action.payload
      };
    case 'SET_IS_SELECTED_DEVICE':
      return {
        ...state,
        isSelectedDevice: action.payload
      };
    case 'SET_LOCAL_TIME':
      return {
        ...state,
        localTime: action.payload
      };
    case 'SET_IS_DIRECT_OPTION':
      return {
        ...state,
        directOption: action.payload
      };
    case 'SET_IS_VISABLE_USER_BTN':
      return {
        ...state,
        isVisableUserBtn: action.payload
      };
    case 'SET_IS_ATT_CHANGED':
      return {
        ...state,
        isAttChanged: action.payload
      };
    case 'SET_USER_ID':
      return {
        ...state,
        usesId: action.payload
      };
    case 'SET_IS_MAP_MODAL':
      return {
        ...state,
        isMapModal: action.payload
      };
    case 'SET_IS_DUPLICATE_ENTRY':
      return {
        ...state,
        duplicateEntry: action.payload
      };
    case 'SET_IS_VISABLE_ADD_LOC_BTN':
      return {
        ...state,
        isVisableAddlocBtn: action.payload
      };
    case 'SET_LOCAL_POSTION_OBJ':
      return {
        ...state,
        localPostionObj: action.payload
      };
    case 'SET_USERS_ARRAY':
      return {
        ...state,
        usersArray: action.payload
      };
    case 'SET_ITEM_ATTRIBUTES':
      return {
        ...state,
        itemAttributes: action.payload
      };
    case 'SET_ATTRIBUTES_MESSAGE':
      return {
        ...state,
        attributeChangesMessage: action.payload
      };
    default:
      return state;
  }
};


export const GroupsModal = (props) => {
  const [state, dispatchReducer] = useReducer(reducersGroups, initialState);
  const logInUser=useSelector((state)=>state.logInUsers)

const [isLoading,setIsLoading]=useState(false)
  useEffect(() => {
    if (props.showItemDetail) {
      dispatchReducer({ type: "SET_SELECTED_DEVICE", payload: props.selecteditem })
    } else {
      if (props.activeOperation === "edit" && (!state.editOption || !isEqual(state.selectedDevice, props.selecteditem))) {
        dispatchReducer({ type: "SET_SELECTED_DEVICE", payload: props.selecteditem })
        dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: true })
        dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: false })
        dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
        dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: props.selecteditem.attributes })
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
        dispatchReducer({ type: "UPDATE_FORM", payload: { ...props.selecteditem } })
        checkRequiredFields()
        updateUser(props.selecteditem)
      }
      if (props.activeOperation === 'delete' && state.deleteProcess === false) {
        dispatchReducer({ type: "SET_IS_DELETE_PROCESS", payload: true })
        deleteGroup(props.selecteditem)
      }
      if (props.activeOperation === 'add' && !state.addOption) {
        dispatchReducer({ type: "SET_SELECTED_DEVICE", payload: "" })
        dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: false })
        dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: true })
        dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
        dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: {} })
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
        dispatchReducer({ type: "UPDATE_FORM", payload: { ...formDefaultState } })
        checkRequiredFields()
      }
    }
  }, [props.activeOperation,props.selecteditem])
useEffect(()=>{
  checkRequiredFields()
},[state.form])
  const au_submit = () => {
    const { itemAttributes } = state
    let { name } = state.form
    if (name) {
      dispatchReducer({ type: "SET_SELECTED_DEVICE", payload: "" })
      dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
      const obj = userDataFormat(state.form, itemAttributes)
setIsLoading(true)
      instance({
        url: `/api/groups/`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(group => {
          props.dispatch(addGroup(group));
          //props.enqueueSnackbar(props.translate('groupAdded'), { autoHideDuration: 1000 });
          props.dispatch(Notifications.success({
            message: props.translate('resourceAdded'),
            autoDismiss: 10
          }));
setIsLoading(false)

          dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: false })
          dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: "" })
          dispatchReducer({ type: "SET_SELECTED_DEVICE", payload: "" })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
          dispatchReducer({ type: "SET_IS_DEFAULT_USER_LIST", payload: false })
          dispatchReducer({ type: "UPDATE_FORM", payload: { ...formDefaultState } })
          props.onCloseModal();
        }).catch(error => {
setIsLoading(false)

          errorHandler(error, props.dispatch)
        })
    } else {
      dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
      dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
      props.dispatch(Notifications.error({
        message: props.translate('emptyField'),
        autoDismiss: 10
      }));

    }
  }

  const uu_submit = () => {
    const { itemAttributes, selectedDevice } = state
    let { name } = state.form
    if (name.trim().length > 0) {
      let itemId = selectedDevice.id
      const obj = userDataFormat(state.form, itemAttributes, itemId)
setIsLoading(true)

      instance({
        url: `/api/groups/${itemId}`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then(group => {
          props.dispatch(updateGroup(group));
          userSelection(group)
          if (props.setSelectedItem) {
            props.setSelectedItem(group)
          }
setIsLoading(false)

          //props.enqueueSnackbar(props.translate('groupUpdated'), { autoHideDuration: 1000 });
          props.dispatch(Notifications.success({
            message: props.translate('resourceUpdated'),
            autoDismiss: 10
          }));
          dispatchReducer({ type: "SET_SELECTED_DEVICE", payload: group })
          dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: itemAttributes })
          dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: itemAttributes })
          dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
          dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
          dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
          updateUser(props.selecteditem)
        }).catch(error => {
setIsLoading(false)

          errorHandler(error, props.dispatch)
        })

    } else {
      dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
      dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
      //props.enqueueSnackbar(props.translate('emptyField'), { autoHideDuration: 1000 });
      props.dispatch(Notifications.error({
        message: props.translate('emptyField'),
        autoDismiss: 10
      }));
    }

  }

  const deleteGroup = (selectedDevice) => {

    if (window.confirm(props.translate('areYouWantToDelete'))) {

      if (selectedDevice.id) {
        instance({
          url: `/api/groups/${selectedDevice.id}`,
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data: {
            ...selectedDevice
          }
        })
          .then(response => {// eslint-disable-line no-unused-vars
            // if (response.ok) {
            props.dispatch(removeGroup(selectedDevice));
            //props.enqueueSnackbar(props.translate('groupDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(Notifications.success({
              message: props.translate('resourceDeleted'),
              autoDismiss: 10
            }));
            props.onCloseModal();
            dispatchReducer({ type: "SET_IS_DELETE_PROCESS", payload: false })
            dispatchReducer({ type: "SET_SELECTED_DEVICE", payload: "" })
            dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: "" })
          }).catch(error => { errorHandler(error, props.dispatch) })

      }
    }


  }

  const changeAttribute = (name, value, selectedOption, updateAtt) => {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: { ...updateAtt } })
      checkForAttrubutesChanged(selectedOption)
    }
    else {
      dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: { ...state.selectedDevice.attributes, [name]: value } })
      checkForAttrubutesChanged()
    }
  }

  const timezone_handleChange = (name, value) => {
    dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: { ...state.itemAttributes, [name]: value.value } })
    checkForAttrubutesChanged('updateAtt')
  }

  const changeAttribute2 = (name, event) => {
    let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
    dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: { ...state.itemAttributes, [name]: value } })
    checkForAttrubutesChanged('updateAtt')
  }


  const checkForAttrubutesChanged = (selectedOption) => {
    let changed = false
    if (state.selectedDevice) {
      changed = !isEqual(state.u_attriubtes, state.selectedDevice.attributes)
    } else {
      changed = !isEmpty(state.u_attriubtes)
    }
    let { name, email } = state.form
    let selectedDevice = {}
    if ((name && email) || (name && email && changed)) {
      selectedDevice = state.u_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: changed })
        dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: changed })
        dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...selectedDevice } })
      } else {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: changed })
        dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: changed })
        dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...state.itemAttributes, ...selectedDevice } })
      }

    } else {
      selectedDevice = state.u_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
        dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
        dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...selectedDevice } })
        checkRequiredFields()
      } else {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
        dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
        dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: { ...state.itemAttributes, ...selectedDevice } })
        checkRequiredFields()
      }
    }
    if (changed) {
      dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: true })
      dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: true })
      dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: 'Change in attributes is pending' })
    } else {
      dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
      dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
      dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: '' })
      checkRequiredFields()
    }
  }

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.prototype.hasOwnProperty.call(key))
        return false;
    }
    return true;
  }
  const modalControle = () => {
    if (state.isVisableUserBtn) {
      fatchGroups(logInUser.id)
    }
    dispatchReducer({ type: "SET_IS_ADD_OPTION", payload: false })
    dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: false })
    dispatchReducer({ type: "UPDATE_FORM", payload: '' })
    dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: false })
    dispatchReducer({ type: "SET_IS_ATT_CHANGED", payload: false })
    dispatchReducer({ type: "SET_ATTRIBUTES_MESSAGE", payload: '' })
    props.onCloseModal()
  }
  // const addUser=()=>{
  //   dispatchReducer({ type: "SET_SELECTED_DEVICE", payload:"" })
  //   dispatchReducer({ type: "SET_ITEM_ATTRIBUTES", payload: {} })
  //   dispatchReducer({ type: "SET_IS_ADD_OPTION", payload:true })
  // }
  const updateUser = () => {
    dispatchReducer({ type: "SET_IS_EDIT_OPTION", payload: true })
  }

  const userSelection = (item) => {
    dispatchReducer({ type: "SET_SELECTED_DEVICE", payload: item })
    dispatchReducer({ type: "SET_U_ATTRIBUTES", payload: item.attributes })
    dispatchReducer({ type: "UPDATE_FORM", payload: { ...item } })
  }
  const setExpirationTime = (e) => {
    e.stopPropagation()
    dispatchReducer({ type: "UPDATE_FORM", payload: { expirationTime: null } })
    checkRequiredFields()
  }
  const setNoToken = (e) => {
    e.stopPropagation()
    dispatchReducer({ type: "UPDATE_FORM", payload: { token: null } })
    checkRequiredFields()
  }

  const generateToken = () => {
    let symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i, newToken = '';
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    dispatchReducer({ type: "UPDATE_FORM", payload: { token: newToken } })
    checkRequiredFields()
  }
  const timeDateOnchange = (date) => {
    dispatchReducer({ type: "UPDATE_FORM", payload: { timeDate: date } })
  }
  const handleChange2 = (target, option) => {
    if (option.action === 'clear') {
      dispatchReducer({ type: "UPDATE_FORM", payload: { [option.name]: 0 } })
      checkRequiredFields()
    } else {
      dispatchReducer({ type: "UPDATE_FORM", payload: { [option.name]: target.value } })
      checkRequiredFields()
    }
  }
  const handleChange =useCallback((name) => (event) => {
    const { target } = event;
    let updateForm = {};
    if (target && target.type === "checkbox") {
      updateForm[name] = target.checked;
    }
    else if (name === "expirationTime") {
      updateForm[name] = event.toISOString()
    } else {
      updateForm[name] = target.value;
    }
    dispatchReducer({ type: "UPDATE_FORM", payload: updateForm })
    checkRequiredFields()
  },[state.form,checkRequiredFields])
  const checkRequiredFields = () => {
    let { name } = state.form
    let value = !isEqual(state.form, state.selectedDevice)
    if (state.editOption) {
      if (name.trim().length > 0 && value) {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: true })
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: false })

      } else {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: state.isAttChanged })
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: false })
      }
    } else {
      if (name.trim().length > 0) {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: true })
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: false })
      } else {
        dispatchReducer({ type: "SET_IS_VISABLE_USER_BTN", payload: state.isAttChanged })
        dispatchReducer({ type: "SET_IS_DUPLICATE_ENTRY", payload: false })
      }
    }

  }
  const fatchGroups = (id) => {
    if (id) {
      instance({
        url: `/api/groups`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          userId: id
        }
      })
        .then(groups => {
          props.dispatch(getGroups(groups));
          dispatchReducer({ type: SET_USER_ID, payload: logInUser.id })

        }).catch(() => {
        })
    }

  }
  const handleClickShowPostion = () => {
    dispatchReducer({ type: "SET_IS_MAP_MODAL", payload: true })
    dispatchReducer({ type: "SET_IS_VISABLE_ADD_LOC_BTN", payload: false })

    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false
    })
  }
  const getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      dispatchReducer({ type: "SET_LOCAL_POSTION_OBJ", payload: { latlng, zoom } })
      dispatchReducer({ type: "SET_IS_VISABLE_ADD_LOC_BTN", payload: true })
    }

  }

  const setSelectedLocation = () => {
    const { localPostionObj } = state
    if (localPostionObj) {
      dispatchReducer({ type: "UPDATE_FORM", payload: { ...state.form } })
      dispatchReducer({ type: "SET_IS_MAP_MODAL", payload: false })
      checkRequiredFields()
    }
  }

  const onCloseModal = () => {
    dispatchReducer({ type: "SET_IS_MAP_MODAL", payload: false })
  }
  return (
    <section style={{ width: '100%' }}>
      {props.viewScreen === 'add' ?
        <AddGroupModal
          viewScreen={props.viewScreen}
          selectedDevice={state.selectedDevice}
          formSubmit={au_submit}
          token={state.token}
          timeDate={state.timeDate}
          timeDateOnchange={timeDateOnchange}
          generateToken={generateToken}
          modalControle={modalControle}
          changeAttribute={changeAttribute}
          handleChange={handleChange}
          handleChange2={handleChange2}
          form={state.form}
          buttonText={props.translate('sharedCreate')}
          isVisableUserBtn={checkPrivileges('groupCreate') && state.isVisableUserBtn}
          handleClickShowPostion={handleClickShowPostion}
          duplicateEntry={state.duplicateEntry}
          setExpirationTime={setExpirationTime}
          setNoToken={setNoToken}
          itemAttributes={state.itemAttributes}
          attributeChangesMessage={state.attributeChangesMessage}
          btnCancelNotShow={false}
          timezone_handleChange={timezone_handleChange}
          changeAttribute2={changeAttribute2}
          isHaveAccess={'groupCreate'}
          translate={props.translate}
          isLoading={isLoading}

        />
        : null}
      {props.viewScreen === 'edit' ?
        <AddGroupModal
          viewScreen={props.viewScreen}
          selectedDevice={state.selectedDevice}
          formSubmit={uu_submit}
          token={state.token}
          timeDate={state.timeDate}
          timeDateOnchange={timeDateOnchange}
          generateToken={generateToken}
          modalControle={modalControle}
          changeAttribute={changeAttribute}
          handleChange={handleChange}
          handleChange2={handleChange2}
          form={state.form}
          editOption={state.editOption}
          buttonText={props.translate('sharedUpdate')}
          isVisableUserBtn={checkPrivileges('groupUpdate') && state.isVisableUserBtn}
          handleClickShowPostion={handleClickShowPostion}
          duplicateEntry={state.duplicateEntry}
          setExpirationTime={setExpirationTime}
          setNoToken={setNoToken}
          itemAttributes={state.itemAttributes}
          attributeChangesMessage={state.attributeChangesMessage}
          btnCancelNotShow={true}
          timezone_handleChange={timezone_handleChange}
          changeAttribute2={changeAttribute2}
          isHaveAccess={'groupUpdate'}
          translate={props.translate}
          isLoading={isLoading}

        />
        : null}
      {/* {state.viewScreen === 'view' ?
        <EditItem
          selectedItem={state.selectedDevice}
          handleClose={modalControle}
          form={state.form} />
        : null} */}
      {state.isMapModal &&
        <CustomDialog
          title={props.translate('setLocation')}
          themecolors={props.themecolors}
          visable={true}
          onClose={onCloseModal}
          isButtonVisable={true}
          draggable={true}
          CancelText={props.translate('sharedBack')}
          headerActions={<Button size="small" variant="contained" color="secondary" disabled={!state.isVisableAddlocBtn} onClick={() => setSelectedLocation()} > {props.translate('sharedAdd')}</Button>}
          bodyPadding={0}>
          <MarkerMap
            getPostion={getPostion}
            minHeight={500}
            zoom={state.selectedDevice.zoom || 0}
            lat={state.selectedDevice.latitude || 0}
            lng={state.selectedDevice.longitude || 0}
            height={'100%'} />
        </CustomDialog>
      }
    </section>
  );
}
