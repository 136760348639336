import React, {  forwardRef } from 'react'
import Switch from '@mui/material/Switch'
// import PropTypes from 'prop-types'
// import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import Style from 'style-it'

const mapStateToProps = (state) => {
  return { themecolors: state.themeColors }
}
function CustomSwitch (props) {
  const p = { ...props }
  delete p.dispatch
  return (
    <React.Fragment>
      <Style>{`
    .switch-base.switch-base-checked + .switch-base-bar {
      background-color: ${p.themecolors.themeLightColor}
    }
    .switch-base.switch-base-checked,
    .switch-base {
      color: ${p.themecolors.themeLightColor}
    }`}</Style>
      <Switch
        {...p}
        classes={{
          switchBase: 'switch-base',
          checked: 'switch-base-checked',
          track: 'switch-base-bar'
        }}
      />
    </React.Fragment>
  )
}

export default connect(mapStateToProps)(forwardRef(CustomSwitch))
