import React from 'react'
import Button from './../../../Components/common/Button'
import SearchItems from './../../../Components/Devices/searchItems'
import Grid from '@mui/material/Grid'
import DateRangePicker from './../../../Components/common/DateRangePicker'
import Scrollbar from 'react-scrollbars-custom'
import Checkbox from '../../../Components/common/Checkbox'
import { FormHelperText } from '@mui/material'

 const MessagesSidebar = (props) => {
  
 const createMessagesReports = (type) => {
   props.collapseSidebar()
   props.createMessagesReport(1,type)
  }

    let viewScreen = (
      <form style={{ padding: 20 }}>
        <h3 className='page-title'>{props.translate('DlogsForm')}</h3>
        <Grid container>
          <Grid item xs={12}>
            <DateRangePicker
 fill
 selectedDate={props.selectedDate}
 label={
   props.translate('reportFrom') +
   ' ' +
   props.translate('reportTo')
 }
 onEvent={props.selectDate}
 dlogs
 isDisabled={(date) => moment().diff(date, 'days', true) <= 0 || moment().diff(date, 'days', true) >= 3}
            />
                      <FormHelperText
                      style={{ fontWeight: "bold", color: "#ffc107" , fontSize:"12px"}} // Changed fontWeight and color
                    >
                      {props.translate(`You can select maximum 3 days`)}
                    </FormHelperText>
          </Grid>

          <Grid item xs={12} style={{ padding: '0 0 8px' }}>
            <SearchItems
              api='devices'
              fill
              isMulti={false}
              onChange={props.selectDevice}
              value={
                props.selectedDevice && props.selectedDevice.label
                  ? props.selectedDevice
                  : null
              }
              placeholder={props.translate('selectedTrackers')}
            />
          </Grid>
           <Grid item xs={12} style={{ padding: '0 0 8px' }}>    
       <Checkbox
         checked={props.detail && props.detail}
           onChange={e => props.changeDetail('detail', e)}
          canAssign
         canRemove
          />
       <span style={{ marginLeft: '8px'}}>
         {props.translate('detail')}
          </span>
           </Grid>
          <Grid item xs={12} style={{ padding: '0 0 8px' }}>
            <Button
              fullWidth
              disabled={!props.validatedForm}
              onClick={createMessagesReports}
            >
              {props.translate('submit')}
            </Button>
          </Grid>
          <div className='report-sidebar-switch' style={{margin: '0 auto', marginTop: '-5px'}}></div>
          <Grid item xs={12} style={{ padding: '0 0 8px' }}>
            <Button
              fullWidth
              disabled={!props.validatedForm}
              onClick={() => createMessagesReports('excel')}
            >
              {props.translate('DOWNLOAD')}
            </Button>
          </Grid>
        </Grid>
      </form>
    )

    if (viewScreen) {
      return (
        <aside className='fms-sidebar sidebar-bg-layer'>
          <Scrollbar>{viewScreen}</Scrollbar>
        </aside>
      )
    } else {
      return null
    }
  }

  export default MessagesSidebar;
