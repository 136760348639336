import React, { Component } from 'react';
import { connect } from 'react-redux';
import Checkbox from '../common/Checkbox';
import Style from 'style-it';
import SearchField from '../common/SearchField';
import { withLocalize } from 'react-localize-redux';
import Scrollbar from 'react-scrollbars-custom';

class VehicleSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      search: '',
      isChecked: false,
    };
  }

  componentDidMount() {
    this.initializeList(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.initializeList(this.props.value);
    }
  }

  initializeList(value) {
    const list = {};
    if (value && value.length) {
      value.map(d => (list[d] = true));
    }
    this.setState({ list }, () => {
      const isListSameLength = this.props.vehicles.length === Object.keys(this.state.list).length;
      this.setState({ isChecked: isListSameLength });
    });
  }

  componentWillUnmount() {
    this.setState({
      list: {},
      search: '',
      isChecked: false,
    });
  }

  sendToParent = () => {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list));
    }
  };

  onChange = (e, v) => {
    this.setState(
      prevState => {
        const list = { ...prevState.list };
        if (e.target.checked) {
          list[v.id] = true;
        } else {
          delete list[v.id];
        }
        return {
          list,
          isChecked: this.props.vehicles.length === Object.keys(list).length,
        };
      },
      this.sendToParent
    );
  };

  searchItem = text => {
    this.setState({ search: text.target.value });
  };

  toggleItems = event => {
    if (event.target.checked) {
      const list = {};
      this.props.vehicles.forEach(d => (list[d.id] = true));
      this.setState({ list, isChecked: true }, this.sendToParent);
    } else {
      this.setState({ list: {}, isChecked: false }, this.sendToParent);
    }
  };

  render() {
    const filteredVehicles = this.props.vehicles.filter(
      item => item.label && item.label.toLowerCase().includes(this.state.search.toLowerCase())
    );

    return (
      <Style>
        {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
          background: ${this.props.themecolors.themeLightColor};
          color: ${this.props.themecolors.themeLightInverse};
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            background: ${this.props.themecolors.themeListingColor};
        }
        .selector-list {
            padding: 0;
            margin: 0;
            color: ${this.props.themecolors.themeListingInverse};
            list-style: none
        }
        .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            height: 26px;
            display: inline-flex;
            align-items: center;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px
        }`}
        <div>
          <div className='section-head clearfix section-head-filter'>
            <ul className='filter-row pull-left'>
              <li>
                <SearchField
                  label={this.props.translate('searchVehicles')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              <li style={{ paddingRight: 0, paddingTop: 3 }}>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate('selectAll')}
                />
              </li>
            </ul>
          </div>
          <div className='selector-count'>
            {Object.keys(this.state.list).length} {this.props.translate('VehicleSelected')}
          </div>
          <Scrollbar style={{ height: (this.props.minHeight ? parseInt(this.props.minHeight) : 35) * (this.props.rows || 5) }}>
            <ul className='selector-list'>
              {filteredVehicles.map(row => (
                <li key={row.id}>
                  <label className={this.state.list[row.id] ? 'active' : ''}>
                    <span className='selector-check'>
                      <Checkbox
                        checked={this.state.list[row.id]}
                        value={row}
                        onChange={this.onChange}
                      />
                    </span>
                    <span className='selector-label'>{row.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    );
  }
}

const mapState = state => ({
  vehicles: state.vehicles,
  themecolors: state.themeColors,
});

export default connect(mapState)(withLocalize(VehicleSelector));
