import React, {  Fragment,useState } from 'react'
// import { EditForm } from '../../../Components/Geofence/editForm'
import Button from '../../../Components/common/Button'
import SearchField from '../../../Components/common/SearchField'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
// import { addcalendars } from '../../../Actions/Devices'
// import { geoFenceVisible, deleteGeofence } from '../../../Actions/Devices'
// import isEqual from 'react-fast-compare'
import Icon from '@mui/material/Icon'
import DeleteIcon from '@mui/icons-material/Delete';
import { checkPrivileges, errorHandler } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
// import { CheckIcon } from '../../../Components/common/CheckIcon'
import { removedNotification } from '../../../Actions/Notifications'
import { ReactComponent as NotificationOff } from '../../../assets/notification-off.svg'
import { ReactComponent as NotificationOn } from '../../../assets/notification-on.svg'
import Notifications from 'react-notification-system-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import instance from '../../../axios'
// class Notification extends Component {
  const Notification =(props)=>{

  // constructor (props) {
  //   super(props)
  //   state = { searchRecently: '' }
  // }

 const search =(e)=> {
    props.searchItems(e.target.value)
  }

  // render () {
    const { notification } = props

    const notification1 = notification.map(g => (
      <ListRow
        key={g.id}
        item={g}
        editNotificationForm={props.editNotificationForm}
        updateNotification={props.updateNotification}
      />
    ))

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar sidebar-bg-layer'>
          
          {checkPrivileges('notification') && (
            <div>
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <IconButton
                      color='inherit'
                      onClick={() => props.history.push('/events')}
                      style={{ padding: 0 }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </li>
                  <li>
                    <SearchField
                      label={props.translate('searchNotification')}
                      fullWidth
                      onChange={e => search(e)}
                    />
                  </li>
                  <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                    {checkPrivileges('notificationCreate') && (
                      <Button
                        size='small'
                        onClick={() => props.openCreateFrom()}
                      >
                        {props.translate('Create')}
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
              <ul className='list-view with-padding-right geofence-sidebar-list'>
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: props => {
                      const { elementRef, ...restProps } = props
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id='scrollableDiv'
                        />
                      )
                    }
                  }}
                >
                  {props.itemPagination ? (
                    <InfiniteScroll
                      dataLength={props.itemPagination.items.length}
                      next={props.fetchMoreItems}
                      hasMore={props.itemPagination.hasNext}
                      loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget='scrollableDiv'
                      endMessage={props.endMessage}
                    >
                      {notification1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
  // }
}

// class ListRow1 extends Component {
  const ListRow1 =(props)=>{

    const [state, setState] = useState({
      onDeleteConfirmation: false
    })

  const onDelete = () => {
    if (props.item.id) {
      // fetch(`api/notifications/${props.item.id}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `api/notifications/${props.item.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {// eslint-disable-line no-unused-vars
        // if (response.ok) {
          props.dispatch(removedNotification({ id: props.item.id }))
          props.dispatch(
            Notifications.success({
              message: props.translate('notificationIsDeleted'),
              autoDismiss: 10
            })
          )
          setState((prevState) => ({ ...prevState,  item: {} }))
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, props.dispatch)})
    }
  }

  // shouldComponentUpdate (nextProps, nextState) {
  //   return !isEqual(nextProps, props) || !isEqual(nextState, state)
  // }

  const onRemovedItem = () => {
    setState((prevState) => ({ ...prevState, 
      onDeleteConfirmation: true
    }))
  }
  const onCancel = () => {
    setState((prevState) => ({ ...prevState, 
      onDeleteConfirmation: false
    }))
  }

  // render () {
    return (
      <li className={'list-row clearfix'} key={props.item.id}>
        {checkPrivileges('notificationUpdate') && (
          <>
          <Tooltip
              classes={{
                popper: 'menu-popper',
                tooltip: 'menu-popper-tooltip'
              }}
              title={props.item.id + "   " + props.item.name}
              placement='right'
            >
             <label
              className='checkbox'
              onClick={() => props.editNotificationForm(props.item)}
            >
              <span className='unit-name-track' style={{ fontSize: 12 }}>
                {props.item.name}
              </span>
            </label>
          </Tooltip></>
        )}
        <div className='callout-actions'>
          {checkPrivileges('notificationDelete') && (
            <span className='action' onClick={onRemovedItem}>
              <DeleteIcon className='material-icons'/>
            </span>
          )}
          {checkPrivileges('notificationUpdate') && (
            <Tooltip
              classes={{
                popper: 'menu-popper',
                tooltip: 'menu-popper-tooltip'
              }}
              title={
                props.item.attributes&&props.item.attributes.suspend
                  ? 'Click here to turn on notification'
                  : 'Click here to turn off notification'
              }
            >
              <span
                className='action'
                onClick={() => props.updateNotification(props.item)}
              >
                <Icon className='material-icons'>
                  {props.item.attributes&&props.item.attributes.suspend ? (
                    <NotificationOff />
                  ) : (
                    <NotificationOn />
                  )}
                </Icon>
              </span>
            </Tooltip>
          )}
        </div>

        {state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={onDelete}
            title={props.translate('areYouWantToDelete')}
            children={props.item.name}
          />
        )}
      </li>
    )
  // }
}

const mapState = state => ({
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withLocalize(ListRow1))
export default mapStateToProps(Notification)
