import React, { useState, useEffect } from 'react';
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
// import isEqual from 'react-fast-compare'
import { checkPrivileges, errorHandler, postionAttributes } from '../../Helpers'
import Layout from './../../Layout'
import TextField from '../../Components/common/TextField'
import MenuItem from '../../Components/common/MenuItem'
import Button from '../../Components/common/Button'
import Checkbox from '../../Components/common/CheckboxPermissions'
import Notifications from 'react-notification-system-redux'
import { AppBar, Grid, Tab, Tabs } from '@mui/material'
import EmptyState from '../../Components/common/EmptyState'
import Dialog from '../../Components/common/Dialog'
import { Container } from './Example'
import SearchItems from '../../Components/Devices/searchItems'
import {
  addComputedAttribute,
  getComputedAttributes
} from '../../Actions/ComputedAttributes'
import Autocomplete from '../../Components/common/Autocomplete'
import ReactHtmlParser from 'react-html-parser'
import Tooltip from '../../Components/common/Tooltip'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import instance from '../../axios'
import HtmlParser from 'react-html-parser'

const ComputedAttributesDataFormat = {
  description: '',
  attribute: '',
  expression: '',
  type: '',
  attributes:{copyFromLast:false}
}

const Parameters = (props) => {
    const [state, setState] = useState({
        parameterForm: { ...ComputedAttributesDataFormat },
        mode: '',
        isVisible: true,
        model: false,
        assignMode: '',
        currentMaintenance: '',
        entityId: 0,
        repeatCall: false,
        expressionMode: 'dnd',
        initFetch: false,
        currentPage: 1,
        pageSize: 50,
        itemPagination: {
            items: [],
            total: 0,
            currentPage: 0,
            currentDevice: props.deviceId,
            hasNext: true,
            searchText: ''
        }
    });


    useEffect(() => {
      if (props.logInUser && props.logInUser.id && !state.initFetch) {
          setState((prevState) => ({ ...prevState, initFetch: true }));
          fetchData(props.logInUser, state.currentPage, state.pageSize);
      }
  }, [props.logInUser, state.initFetch]);

  useEffect(() => {
      return () => {
          setState({
              parameterForm: { ...ComputedAttributesDataFormat },
              mode: '',
              model: false,
              assignMode: '',
              currentMaintenance: '',
              entityId: 0,
              result: '',
              initFetch: false,
              currentPage: 1,
              pageSize: 50,
              itemPagination: {
                  items: [],
                  total: 0,
                  currentPage: 0,
                  currentDevice: props.deviceId,
                  hasNext: true,
                  searchText: ''
              }
          });
      };
  }, []);

  const fetchMoreItems = () => {
      fetchData(props.logInUser, state.currentPage, state.pageSize);
  };
  useEffect(()=>{
      fetchData(props.logInUser, 1, state.pageSize, true);
  },[state.searchText])

  const searchItems = (text) => {
      setState((prevState) => ({ ...prevState, searchText: text }));
  };

  const fetchData = (logInUser, page, perPage, reset = false) => {
      let searchText = state.searchText ? state.searchText: '';
      let items = reset ? [] : state.itemPagination.items;

      instance({
          url: `/api/attributes/computed/get`,
          method: 'GET',
          params: {
              userId: logInUser.id,
              category: 'elogic',
              all: true,
              page: page,
              limit: perPage,
              search: searchText
          }
      })
          .then((res) => {
              setState((prevState) => ({
                  ...prevState,
                  itemPagination: {
                      ...res,
                      items: items.concat(res.data)
                  },
                  currentPage: res.hasNext ? res.page + 1 : res.page
              }));
              props.dispatch(getComputedAttributes(items.concat(res.data)));
          })
          .catch(() => {});
  };

  const assignModal = (assignMode, currentMaintenance) => {
      setState((prevState) => ({
          ...prevState,
          assignMode,
          currentMaintenance,
          selectEntity: '',
          model: true
      }));
  };

  const selectEntity = (e) => {
      setState((prevState) => ({
          ...prevState,
          selectEntity: e
      }));
  };

  const submitAssignModel = () => {
      let obj = {
          attributeId: state.currentMaintenance.id
      };

      if (state.assignMode === 'unit') {
          obj.deviceId = state.selectEntity.id;
      } else if (state.assignMode === 'user') {
          obj.userId = state.selectEntity.id;
      } else if (state.assignMode === 'group') {
          obj.groupId = state.selectEntity.id;
      }

      assignParameters(obj);
  };

  const assignParameters = (obj) => {
      instance({
          method: 'POST',
          url: '/api/permissions',
          data: obj,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
      })
          .then(() => {
              props.dispatch(
                  Notifications.success({
                      message: props.translate('attributeAssignedSuccessfully'),
                      autoDismiss: 5,
                      location: 'tr'
                  })
              );
              setState((prevState) => ({
                  ...prevState,
                  model: false
              }));
          })
          .catch(() => {
              props.dispatch(
                  Notifications.error({
                      message: props.translate('assignFailed'),
                      autoDismiss: 5,
                      location: 'tr'
                  })
              );
          });
  };




  const closeAssignModal = () => {
    setState(prevState => ({
        ...prevState,
        model: false,
        assignMode: '',
        commandTitle: '',
        currentMaintenance: ''
    }));
};

const openCreateFrom = () => {
  setState(prevState => ({
      ...prevState,
      mode: '',
      parameterForm: { ...ComputedAttributesDataFormat }
  }));
  
  setState(prevState => ({
      ...prevState,
      mode: 'create',
      selectEntity: '',
      result: '',
      parameterForm: { ...ComputedAttributesDataFormat }
  }));
};


const editParameterForm = (parameterForm) => {
  setState(prevState => ({
      ...prevState,
      mode: '',
      parameterForm: {}
  }));

  let obj = JSON.parse(JSON.stringify(parameterForm));
  if (obj.type === 'totalDistance') {
      obj.start = parseFloat(obj.start / 1000);
      obj.period = parseFloat(obj.period / 1000);
  } else if (obj.type === 'hours') {
      obj.start = parseFloat(obj.start / (1000 * 3600));
  } else if (obj.type === 'date') {
      obj.start = 1;
  }
  delete obj.progress;

  setState(prevState => ({
      ...prevState,
      mode: 'update',
      selectEntity: '',
      result: '',
      parameterForm: { ...obj }
  }));
};


const closeCreateFrom = () => {
  setState(prevState => ({
      ...prevState,
      mode: '',
      parameterForm: ''
  }));
};


const handleChange = (name, value) => {
  let v = value;
  if(name === 'copyFromLast') {
      setState(prevState => ({
          ...prevState,
          parameterForm: {
              ...prevState.parameterForm,
              attributes: {
                  ...prevState.parameterForm.attributes,
                  [name]: v
              }
          }
      }));
  } else {
      setState(prevState => ({
          ...prevState,
          parameterForm: {
              ...prevState.parameterForm,
              [name]: v
          }
      }));
  }
}

 const checkRequiredFields = () => {
    const { description, expression, type, attribute } = state.parameterForm;
    if (description && type && expression && attribute) {
        setState(prevState => ({ ...prevState, isVisible: false }));
    } else {
        setState(prevState => ({ ...prevState, isVisible: true }));
    }
};

const loadAttributes = (value) => {
  setState(prevState => ({
      ...prevState,
      repeatCall: true,
      selectEntity: value,
      selctedDeviceAttributes: [],
      result: ''
  }));


};

useEffect(()=>{
  if (props.deviceRelatedData && props.deviceRelatedData[state.selectEntity?.id] && props.deviceRelatedData[state.selectEntity?.id].exists) {
    setState(prevState => ({
        ...prevState,
        selctedDeviceAttributes: Object.keys(props.deviceRelatedData[state.selectEntity?.id].attributes).filter(e => !['unknown'].includes(e))
    }));
}
},[props.deviceRelatedData,state.selectEntity])


const playResult = (items) => {
  let expression = '';
  if (items.length && (items.length % 2 === 1 || items.length > 2)) {
      items.forEach(item => {
          expression += ' ' + item.name;
      });

      setState(prevState => ({
          ...prevState,
          parameterForm: {
              ...prevState.parameterForm,
              expression
          },
          currentMaintenance: {
              ...prevState.currentMaintenance,
              expression
          }
      }));
      
      runTest();
      checkRequiredFields();
  } else {
      setState(prevState => ({
          ...prevState,
          result: '',
          parameterForm: {
              ...prevState.parameterForm,
              expression
          },
          currentMaintenance: {
              ...prevState.currentMaintenance,
              expression
          }
      }));
      
      checkRequiredFields();
  }
};

const submitForm = () => {
  let obj = JSON.parse(JSON.stringify(state.parameterForm));

  if (obj.type === 'totalDistance') {
      obj.start = parseFloat(obj.start * 1000);
      obj.period = parseFloat(obj.period * 1000);
  } else if (obj.type === 'hours') {
      obj.start = parseFloat(obj.start * (1000 * 3600));
  } else if (obj.type === 'date') {
      obj.start = 1;
  }

  delete obj.check;
  let params = state.mode === 'update' ? obj.id : '';
  setState(prevState => ({ ...prevState, isVisible: true }));

  if (obj) {
      instance({
          url: `/api/attributes/computed/${params}`,
          method: state.mode === 'update' ? 'PUT' : 'POST',
          data: { ...obj }
      })
      .then(attribute => {
          props.dispatch(addComputedAttribute(attribute));
          if (state.selectEntity && state.selectEntity.id) {
              const obj = {
                  deviceId: state.selectEntity.id,
                  attributeId: attribute.id
              };
              assignParameters(obj);
          }
          props.dispatch(
              Notifications.success({
                  message: state.mode === 'update' ? props.translate('computedAttributeIsUpdated') : props.translate('computedAttributeIsCreated'),
                  autoDismiss: 10
              })
          );
          closeCreateFrom();
      })
      .catch(error => {
          errorHandler(error, props.dispatch);
      });
  }
};

const runTest = () => {
  let body = { ...state.currentMaintenance };
  delete body.check;

  let id = state.selectEntity && state.selectEntity.id ? state.selectEntity.id : 0;

  if (id && state.repeatCall) {
      setState(prevState => ({ ...prevState, result: '...', repeatCall: false }));

      instance({
          url: `/api/attributes/computed/test`,
          method: `POST`,
          params: { deviceId: id },
          data: { ...body }
      })
      .then(result => {
          setState(prevState => ({ ...prevState, result: 'Result: ' + result }));
      })
      .catch(error => {
          const error1 = {
              ...error,
              message: HtmlParser(error?.message)[0]
          };
          errorHandler(error1, props.dispatch);
          let message = error1.message.split(':')[1];
          setState(prevState => ({ ...prevState, result: 'Result: ' + message.replace(')', '') }));
      });
  }
};


const changeBuilder = (event, expressionMode) => {
  setState(prevState => ({ ...prevState, expressionMode }));
};


  // shouldComponentUpdate (nextProps, nextState) {
  //   return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  // }

  // render () {
    const endMessage =
      state.itemPagination && state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {props.translate('notFound')}{' '}
        </p>
      )

    // if (checkPrivileges('attribute')) {
      return (
        <div>
          <Layout
            {...props}
            endMessage={endMessage}
            openCreateFrom={openCreateFrom}
            closeCreateFrom={closeCreateFrom}
            classFromChildren='has-padding'
            editParameterForm={editParameterForm}
            fetchMoreItems={fetchMoreItems}
            searchItems={searchItems}
            {...state}
          >
            <div className='main-content-page'>
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  color: props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                }}
              >
                {state.mode === 'create' ||
                state.mode === 'update' ? (
                  <CreateParameterForm
                    assignModal={assignModal}
                    submitForm={submitForm}
                    mode={state.mode}
                    closeCreateFrom={closeCreateFrom}
                    // handleChangeAttributes={handleChangeAttributes}
                    form={state.parameterForm}
                    handleChange={handleChange}
                    loadAttributes={loadAttributes}
                    selectEntity={state.selectEntity}
                    selctedDeviceAttributes={state.selctedDeviceAttributes}
                    themecolors={props.themecolors}
                    translate={props.translate}
                    playResult={playResult}
                    result={state.result}
                    changeBuilder={changeBuilder}
                    expressionMode={state.expressionMode}
                    isVisible={state.isVisible}
                  />
                ) : null}
                {state.model ? (
                  <Dialog
                    overflow={'visible'}
                    isVisableBtn={true}
                    headerActions={
                      <Button
                        onClick={submitAssignModel}
                        variant='outlined'
                        size='small'
                      >
                        {props.translate('assignElogic')}
                      </Button>
                    }
                    leftActions={
                      state.assignMode === 'unit' ? (
                        <Button
                          onClick={runTest}
                          variant='outlined'
                          size='small'
                        >
                          {props.translate('runTest')}
                        </Button>
                      ) : null
                    }
                    open={state.model}
                    onClose={closeAssignModal}
                    title={props.translate('assignElogic')}
                  >
                    <div style={{ padding: 16 }}>
                      <TextField
                        id='name'
                        margin='dense'
                        label={props.translate('sharedDescription')}
                        variant='outlined'
                        fullWidth
                        readOnly
                        value={
                          state.currentMaintenance &&
                          state.currentMaintenance.description
                        }
                      />

                      {state.assignMode === 'unit' ? (
                        <SearchItems
                          api='devices'
                          fill
                          isMulti={false}
                          onChange={selectEntity}
                          value={state.selectEntity}
                          placeholder={props.translate('selectedTrackers')}
                        />
                      ) : null}

                      {state.assignMode === 'user' ? (
                        <SearchItems
                          api='users'
                          fill
                          isMulti={false}
                          onChange={selectEntity}
                          value={state.selectEntity}
                          placeholder={props.translate('searchUsers')}
                        />
                      ) : null}

                      {state.assignMode === 'group' ? (
                        <SearchItems
                          api='groups'
                          fill
                          isMulti={false}
                          onChange={selectEntity}
                          value={state.selectEntity}
                          placeholder={props.translate('searchGroup')}
                        />
                      ) : null}
                      {state.result && (
                        <div
                          style={{
                            color: props.themecolors['error'],
                            fontWeight: 700,
                            fontStyle: 'italic'
                          }}
                        >
                          {ReactHtmlParser(state.result)}
                        </div>
                      )}
                    </div>
                  </Dialog>
                ) : null}
                {state.mode === '' ? (
                  <EmptyState
                    text={props.translate('noParameterSelected')}
                  />
                ) : null}
              </div>
            </div>
          </Layout>
        </div>
      )
    // } else {
    //   return null
    // }
  // }
}

const CreateParameterForm = ({
  form,
  handleChange,
  translate,
  selectEntity,
  // handleChangeAttributes,
  selctedDeviceAttributes,
  loadAttributes,
  submitForm,
  closeCreateFrom,
  mode,
  assignModal,
  themecolors,
  result,
  playResult,
  changeBuilder,
  expressionMode,
  isVisible
}) => {
  return (
    <div>
      <h4 className='page-title'>{translate('sharedComputedAttributes')}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='description'
            margin='dense'
            label={translate('sharedDescription')}
            variant='outlined'
            fullWidth
            value={form.description}
            onChange={e => handleChange('description', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            suggestions={postionAttributes}
            fieldName='attribute'
            translate={translate}
            label={translate('sharedAttribute')}
            value={form.attribute}
            handleChange={handleChange}
            canRemove={
              checkPrivileges('attributeCreate') ||
              checkPrivileges('attributeUpdate')
            }
            canAssign={
              checkPrivileges('attributeCreate') ||
              checkPrivileges('attributeUpdate')
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='type'
            select
            label={translate('sharedType')}
            value={form.type}
            onChange={e => handleChange('type', e.target.value)}
            margin='dense'
            fullWidth
          >
            {att_valueType.map(option => (
              <MenuItem key={option.Type} value={option.Type}>
                {translate(option.Type)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} style={{display:'flex',alignItems:'center'}}>
            <Checkbox
            canAssign
            canRemove
            translate={translate}
            checked={form.attributes.copyFromLast}
            onChange={e =>
              handleChange('copyFromLast', e.target.checked)
            }
            label={translate('copyFromLast')}
          />
        </Grid>
      </Grid>
      <h4>{translate('sharedExpression')}</h4>
      <AppBar
        position='static'
        color='inherit'
        style={{ background: 'none', boxShadow: 'none' }}
      >
        <Tabs
          value={expressionMode || 'dnd'}
          onChange={changeBuilder}
          aria-label='simple tabs example'
          indicatorColor='primary'
          textColor='primary'
          scrollButtons='auto'
          variant='scrollable'
          classes={{
            root: 'custom-tabs-root',
            flexContainer: 'custom-tabs',
            scrollable: 'custom-tabs-scrollable',
            indicator: 'custom-indicator',
            scrollButtons: 'scrollable-buttons'
          }}
        >
          <Tab
            classes={{
              root: 'custom-tab-button custom-tab-button-1',
              selected: 'custom-tab-button-selected'
            }}
            value='dnd'
            label={translate('dragDropBuilder')}
          />
          <Tab
            classes={{
              root: 'custom-tab-button custom-tab-button-1',
              selected: 'custom-tab-button-selected'
            }}
            value='custom'
            label={translate('directExpression')}
          />
        </Tabs>
      </AppBar>
      <div className='dnd-ebuilder-wrapper'>
        {expressionMode === 'dnd' ? (
          <DndProvider backend={HTML5Backend}>
            <Container
              playResult={playResult}
              result={result}
              form={form}
              themecolors={themecolors}
              selctedDeviceAttributes={selctedDeviceAttributes}
              deviceSelector={
                <div style={{ top: -4, position: 'relative' }}>
                  <SearchItems
                    api='devices'
                    fill
                    isMulti={false}
                    onChange={loadAttributes}
                    value={selectEntity}
                    placeholder={translate('selectedTrackers')}
                  />
                </div>
              }
            />
          </DndProvider>
        ) : null}
        {expressionMode === 'custom' ? (
          <Tooltip
            title={translate('eLogicParameterHint')}
            classes={{
              popper: 'menu-popper',
              tooltip: 'menu-popper-tooltip'
            }}
          >
            <TextField
              id='expression'
              label={translate('sharedExpressionLabel')}
              value={form.expression}
              onChange={e => handleChange('expression', e.target.value)}
              variant='outlined'
              margin='dense'
              multiline
              rows={3}
              rowsMax={5}
              fullWidth
            />
          </Tooltip>
        ) : null}
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        {mode !== 'create' ? (
          <>
            <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={() => assignModal('unit', form)}
            >
              {translate('assignUnit')}{' '}
            </Button>
            <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={() => assignModal('user', form)}
            >
              {translate('assignUser')}{' '}
            </Button>
            {/* <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={e => assignModal('group', form)}
            >
              {translate('assignGroup')}{' '}
            </Button> */}
          </>
        ) : null}

        <div style={{ marginLeft: 'auto' }} />
        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeCreateFrom}
        >
          {translate('sharedCancel')}{' '}
        </Button>
        {checkPrivileges('attributeCreate') && (
          <Button size='small' onClick={submitForm} disabled={isVisible}>
            {translate(mode === 'create' ? 'sharedCreate' : 'update')}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  computedAttributes: state.computedAttributes,
  deviceRelatedData: state.deviceRelatedData,
  themecolors: state.themeColors,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)((withLocalize(Parameters)))

const att_valueType = [
  {
    Type: 'string'
  },
  { Type: 'number' },
  { Type: 'boolean' }
]
