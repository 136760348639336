import React, { useState } from 'react';
import CustomDialog from './../common/Dialog'
import Table from './../common/Table'
import { connect } from 'react-redux';
import { updateGroup, removeGroup } from './../../Actions/Groups'
import Button from './../common/Button';
import TextField from './../common/TextField';
import MenuItem from './../common/MenuItem';
import isEqual from 'react-fast-compare';
import Attributes from './../Attributes/attributesModal'
import { checkPrivileges, errorHandler } from '../../Helpers'
import ConfirmDialoag from '../common/ConfirmDialoag'
import Notifications from 'react-notification-system-redux';
import instance from '../../axios';


const groupDefaultForm = {
    name: '',
    groupId: '',
}

const groupDataFormat = (data, extAttriubtes, itemId) => ({
    'id': itemId ? itemId : 0,
    'attributes': { ...extAttriubtes },
    'name': data && data.name && data.name.trim(),
    'groupId': data.groupId ? data.groupId : 0

})

const groupModal = (props) => {
    const [state, setState] = useState({
      gm_isVisable: false,
      selectedGroup: '',
      addOption: false,
      editOption: false,
      isHaveAccess: '',
      g_attriubtes: '',
      attm_isVisable: false,
      itemAttributes: {},
      attributeChangesMessage: '',
      isVisableGroupBtn: false,
      isAttChanged: false,
      form: groupDefaultForm,
      onDeleteConfirmation: false,
    });

    const editEnable = (selected) => {
        if (selected[0]) {
          setState((prevState) => ({
            ...prevState,
            itemAttributes: selected[0].attributes,
            editOption: true,
            isHaveAccess: 'groupUpdate',
            addOption: false,
            selectedGroup: selected[0],
            selectedOption: true,
            form: { ...selected[0] },
          }));
          checkRequiredFields();
        } else {
          setState((prevState) => ({
            ...prevState,
            itemAttributes: state.g_attriubtes,
            editOption: true,
            selectedOption: false,
          }));
          checkRequiredFields();
        }
      };
    
      const ag_submit = () => {
        let { name } = state.form;
        if (name.trim().length > 0) {
          const { itemAttributes, form } = state;
          const obj = groupDataFormat(form, itemAttributes);
          instance({
            url: `/api/groups`,
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            data: {
              ...obj,
            },
          })
            .then((group) => {
              setState((prevState) => ({
                ...prevState,
                addOption: false,
                g_attriubtes: '',
                isVisableGroupBtn: false,
                isAttChanged: false,
                form: '',
                attributeChangesMessage: '',
              }));
              props.dispatch(addGroup(group));
              props.dispatch(
                Notifications.success({
                  message: props.translate('groupAdded'),
                  autoDismiss: 10,
                })
              );
            })
            .catch((error) => {
              errorHandler(error, props.dispatch);
            });
        } else {
          setState((prevState) => ({
            ...prevState,
            isVisableGroupBtn: false,
            isAttChanged: false,
          }));
          props.dispatch(
            Notifications.error({
              message: props.translate('emptyField'),
              autoDismiss: 10,
            })
          );
        }
      };
    
      const ug_submit = () => {
        let { name } = state.form;
        if (name.trim().length > 0) {
          const { itemAttributes, selectedGroup, form } = state;
          let itemId = selectedGroup.id;
          const obj = groupDataFormat(form, itemAttributes, itemId);
    
          instance({
            url: `/api/groups/${itemId}`,
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            data: {
              ...obj,
            },
          })
            .then((group) => {
              props.dispatch(updateGroup(group));
              props.dispatch(
                Notifications.success({
                  message: props.translate('groupUpdated'),
                  autoDismiss: 10,
                })
              );
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: '',
              }));
            })
            .catch((error) => {
              errorHandler(error, props.dispatch);
            });
          setState((prevState) => ({
            ...prevState,
            selectedGroup: '',
            editOption: false,
            g_attriubtes: '',
            isVisableGroupBtn: false,
            isAttChanged: false,
            form: '',
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            isVisableGroupBtn: false,
            isAttChanged: false,
          }));
          props.dispatch(
            Notifications.error({
              message: props.translate('emptyField'),
              autoDismiss: 10,
            })
          );
        }
      };
    
      const deleteGroup = () => {
        let { selectedGroup } = state;
        if (selectedGroup.id) {
          instance({
            url: `/api/groups/${selectedGroup.id}`,
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            data: {
              ...selectedGroup,
            },
          })
            .then(() => {
              props.dispatch(removeGroup(selectedGroup));
              props.dispatch(
                Notifications.success({
                  message: props.translate('groupDeleted'),
                  autoDismiss: 10,
                })
              );
              setState((prevState) => ({
                ...prevState,
                selectedGroup: '',
                addOption: false,
                editOption: false,
                attributeChangesMessage: '',
                onDeleteConfirmation: false,
              }));
            })
            .catch((error) => {
              errorHandler(error, props.dispatch);
            });
        }
      };
    
    //   const modalControle = () => {
    //     setState((prevState) => ({
    //       ...prevState,
    //       addOption: false,
    //       editOption: false,
    //       selectedGroup: '',
    //       isVisableGroupBtn: false,
    //       isAttChanged: false,
    //       attributeChangesMessage: '',
    //       isHaveAccess: '',
    //       form: '',
    //     }));
    //   };
    
      const addGroup = () => {
        setState((prevState) => ({
          ...prevState,
          selectedGroup: '',
          editOption: false,
          addOption: true,
          isHaveAccess: 'groupCreate',
          form: '',
          attributeChangesMessage: '',
        }));
      };
    
      const changeAttribute = (name, value, selectedOption, updateAtt) => {
        if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
          setState((prevState) => ({
            ...prevState,
            g_attriubtes: { ...updateAtt },
          }));
          checkForAttrubutesChanged(selectedOption);
        } else {
          setState((prevState) => ({
            ...prevState,
            g_attriubtes: { ...state.selectedGroup.attributes, [name]: value },
          }));
          checkForAttrubutesChanged();
        }
      };
    
      const checkForAttrubutesChanged = (selectedOption) => {
        let changed = false;
        if (state.selectedGroup) {
          changed = !isEqual(state.g_attriubtes, state.selectedGroup.attributes);
        } else {
          changed = !isEmpty(state.g_attriubtes);
        }
        let { name } = state.form;
        let selectedGroup = {};
        if (name || (name && changed)) {
          selectedGroup = state.g_attriubtes;
          if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
            setState((prevState) => ({
              ...prevState,
              isVisableGroupBtn: changed,
              isAttChanged: changed,
              itemAttributes: { ...selectedGroup },
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              isVisableGroupBtn: changed,
              isAttChanged: changed,
              itemAttributes: { ...state.itemAttributes, ...selectedGroup },
            }));
          }
        } else {
          selectedGroup = state.g_attriubtes;
          if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
            setState((prevState) => ({
              ...prevState,
              isVisableGroupBtn: false,
              isAttChanged: false,
              itemAttributes: { ...selectedGroup },
            }));
            checkRequiredFields();
          } else {
            setState((prevState) => ({
              ...prevState,
              isVisableGroupBtn: false,
              isAttChanged: false,
              itemAttributes: { ...state.itemAttributes, ...selectedGroup },
            }));
            checkRequiredFields();
          }
        }
        if (changed) {
          setState((prevState) => ({
            ...prevState,
            attributeChangesMessage: 'Attributes changed in pending',
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            attributeChangesMessage: '',
          }));
          checkRequiredFields();
        }
      };
    
    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.prototype.hasOwnProperty.call(key))
                return false;
        }
        return true;
    }
    
      const checkRequiredFields = () => {
        let { itemAttributes } = state;
        for (let key in itemAttributes) {
          if (!itemAttributes[key]) {
            setState((prevState) => ({
              ...prevState,
              attributeChangesMessage: 'Required fields cannot be empty',
            }));
            return;
          }
        }
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: '',
        }));
      };
    
      return (
        <div>
        <CustomDialog
            themecolors={props.themecolors}
            visable={true}
            title={false}
            onClose={props.onCloseModal}
            draggable={true}
            isVisableBtn={checkPrivileges('groupCreate')}
            headerActions={<Button size="small" variant="contained" color="secondary" onClick={() => addGroup()}> {props.translate('sharedAdd')}</Button>}
            bodyPadding={0}>
            <Table isEditable={true} actionInHead={true} canUpdate={checkPrivileges('groupUpdate')} canDelete={checkPrivileges('groupDelete')} showInHead="name" insideDialog={true} rows={props.groups} themecolors={props.themecolors} title="Groups" onCreate={addGroup} onDelete={onRemovedItem} onEdit={editEnable} rowDefinition={[{ id: 'name', numeric: false, disablePadding: false, label: 'Name' }]} />
        </CustomDialog>

        {state.onDeleteConfirmation &&
            <ConfirmDialoag
                onCancel={onCancel}
                onOk={deleteGroup}
                title={props.translate('areYouWantToDelete')}
                children={state.selectedGroup.name}
            />}

        {state.addOption ?
            <CustomDialog
                themecolors={props.themecolors}
                visable={true}
                title={props.translate('AddNewGroup')}
                onClose={onCloseSubModal}
                isVisableBtn={true}
                maxWidth="xs"
                draggable={true}
                invisible={true}
                headerActions={<Button size="small" variant="contained" color="secondary" onClick={() => ag_submit()}
                    disabled={!state.isVisableGroupBtn}
                > {props.translate('sharedAdd')}</Button>
                }
                leftActions={<Button size="small" variant="contained" color="secondary" onClick={() => addAttributes()}
                > {props.translate('sharedAttribute')}</Button>}
                bodyPadding={'18px 24px'}>
                <TextField
                    label="Name"
                    type="search"
                    placeholder=""
                    value={state.form.name || ""}
                    onChange={handleChange('name')}
                    variant="outlined"
                    margin='dense'
                    inputProps={{ className: classes.input }}
                    InputLabelProps={{ classes: { root: classes.label, focused: classes.focused, shrink: classes.shrink } }}
                    fullWidth
                />
                <TextField
                    id="groupId"
                    select
                    label="Group"
                    value={state.form.groupId || ""}
                    variant="outlined"
                    onChange={handleChange('groupId')}
                    margin='dense'
                    inputProps={{ className: classes.input }}
                    InputLabelProps={{ classes: { root: classes.label, focused: classes.focused, shrink: classes.shrink } }}
                    fullWidth
                >
                    {props.groups.map(option => (
                        <MenuItem key={option.id} value={option.id} className={classes.MenuItem}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>

            </CustomDialog>
            : null}
        {state.selectedGroup && state.editOption ?
            <CustomDialog
                themecolors={props.themecolors}
                visable={true}
                title={'Update Group'}
                onClose={onCloseSubModal}
                isVisableBtn={true}
                draggable={true}
                invisible={true}
                maxWidth="xs"
                headerActions={<Button size="small" variant="contained" color="secondary" onClick={() => ug_submit()}
                    disabled={!state.isVisableGroupBtn}
                > {props.translate('update')}</Button>}
                leftActions={<Button size="small" variant="contained" color="secondary" onClick={() => addAttributes()}
                > {props.translate('sharedAttribute')}</Button>}
                bodyPadding={'18px 24px'}>
                <TextField
                    id="name"
                    label="Name"
                    type="text"
                    placeholder=""
                    value={state.form.name || ""}
                    onChange={handleChange('name')}
                    variant="outlined"
                    margin='dense'
                    inputProps={{ className: classes.input }}
                    InputLabelProps={{ classes: { root: classes.label, focused: classes.focused, shrink: classes.shrink } }}
                    fullWidth
                />
                <TextField
                    id="groupId"
                    select
                    label="Group"
                    value={state.form.groupId || ""}
                    variant="outlined"
                    onChange={handleChange('groupId')}
                    margin='dense'
                    inputProps={{ className: classes.input }}
                    InputLabelProps={{ classes: { root: classes.label, focused: classes.focused, shrink: classes.shrink } }}
                    fullWidth
                >
                    {props.groups.map(option => (
                        <MenuItem key={option.id} value={option.id} className={classes.MenuItem}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>

            </CustomDialog>
            : null}
        {
            state.attm_isVisable &&
            <Attributes
                onCloseModal={addAttributes}
                itemAttributes={state.itemAttributes}
                changeAttribute={changeAttribute}
                componentType={'group'}
                isHaveAccess={state.isHaveAccess}
            />
        }
    </div>
      );
    };

const mapState = state => ({
    groups: state.groups,
    themecolors: state.themeColors
});
const mapStateToProps = connect(mapState)
export const GroupModal = mapStateToProps(groupModal)