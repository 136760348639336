import React, { Fragment, PureComponent } from 'react'
import Menu from './../Menu/CommonMenu'
import { logInUserInfo } from './../../Actions/Users'
import MenuItem from './../common/MenuItem'
import Checkbox from './../common/Checkbox'
import { withLocalize } from 'react-localize-redux'
import BubbleChartIcon from '@mui/icons-material/BubbleChart'
import { connect } from 'react-redux'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
// import { enabledTail } from '../../Actions/Devices'
let clusters = false
class OptionMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      process: false
    }
    this.activateOption = this.activateOption.bind(this)
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  activateOption (event) {
    const clus = event.target.checked

    // fetch(`/api/users/${this.props.logInUser.id}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...this.props.logInUser,
    //     attributes: { ...this.props.logInUser.attributes, clusters: clus }
    //   })
    // })
    instance({
      url: `/api/users/${this.props.logInUser.id}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...this.props.logInUser,
        attributes: { ...this.props.logInUser.attributes, clusters: clus }
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(user => {
            this.props.dispatch(logInUserInfo(user))
            this.setState({ process: false, anchorEl: null })
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)})
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.attributes) {
      clusters = n.logInUser.attributes.clusters
    }
  }

  /* enabledTail = event => {
    this.props.dispatch(enabledTail(event))
  } */

  render () {
    const { anchorEl } = this.state
    if (this.props.logInUser && this.props.logInUser.attributes) {
      clusters = this.props.logInUser.attributes.clusters
    }
    const empty = null
    return (
      <Fragment>
        <a
          href={empty}
          aria-owns={anchorEl ? 'simple-menu2' : undefined}
          aria-haspopup='true'
          onClick={this.handleClick}
        >
          <BubbleChartIcon style={{ fontSize: 24 }} />
        </a>
        <Menu
          id='simple-menu2'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          themecolors={this.props.themecolors}
        >
          {/* <MenuItem>
            <Checkbox
              onChange={e => this.enabledTail(!this.props.tailEnabled)}
              checked={this.props.tailEnabled}
              value='checkedA'
              label={this.props.translate('enableTail')}
            />
          </MenuItem> */}
          <MenuItem>
            <Checkbox
              onChange={e => this.activateOption(e)}
              checked={clusters}
              value='checked'
              label={this.props.translate('clusterMarkers')}
            />
          </MenuItem>
        </Menu>
      </Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  logInUser: state.logInUsers,
  tailEnabled: state.tailEnabled
})

export default connect(mapStateToProps)(withLocalize(OptionMenu))
