import ChangedPasword from './changedPassword';
// import VerifiedEmails from './verifiedEmails';
import ServicePreferences from './servicePerferences';
import UserDefault from './userDefault';
import Permissions from './Permissions';
import ReleaseHistory from './ReleaseHistory';

export {
    ChangedPasword,
    ServicePreferences,
    UserDefault,
    Permissions,
    ReleaseHistory
}