import React, {  useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { EditItem } from './EditItem'
import { WaslShortList } from './WaslShortList'
import { withLocalize } from 'react-localize-redux'
import Loader from '../../Layout/Loader'
import isEqual from 'react-fast-compare'
import {
  updatevehicle,
  addvehicle
} from '../../Actions/Vehicles'
import AddVehicle from './addVehicle'
import Notifications from 'react-notification-system-redux'
import {checkPrivileges, errorHandler } from '../../Helpers'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import instance from '../../axios'

const vehicleDataFormat = (data, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...data.attributes },
  deviceId: data.deviceId,
  label: data.label,
  model: data.model,
  garage: data.garage,
  vehicleType: data.vehicleType,
  vin:data.vin,
  vehicleLicensePlate: data.vehicleLicensePlate,
  expirationTime: data.expirationTime,
  areaId: data && data.areaId ? data.areaId.id : '',
})

const trasmissionList=[
  {name:'Manual'},
  {name:'Automatic'},
  {name:'Semi-Automatic'},
  {name:'CVT'},
  {name:'Other'} 
]

const formDefault = {
  label: '',
  garage: '',
  model: '',
  vehicleType: '',
  expirationTime: '',
  vehicleLicensePlate: '',
  attributes: { }//vehicleLicensePlate: ''
}

// class vehicleModal extends Component {
const vehicleModal = (props) => {

  // constructor () {
  //   super()
  //   state = {
  //     trasmissionList:trasmissionList,
  //     selectedVehicle: '',
  //     addOption: false,
  //     editOption: false,
  //     isVisableTrackerModal: false,
  //     isVisableUserBtn: false,
  //     vehicleId: '',
  //     vehicleTracker: '',
  //     form: { ...formDefault },
  //     vehicleStatus: ''
  //   }
  //   handleChange = handleChange.bind(this)
  //   ac_submit = ac_submit.bind(this)
  //   onCloseSubModal = onCloseSubModal.bind(this)
  //   checkRequiredFields = checkRequiredFields.bind(this)
  // }

  const [state, setState] = useState({
       trasmissionList:trasmissionList,
      selectedVehicle: '',
      addOption: false,
      editOption: false,
      isVisableTrackerModal: false,
      isVisableUserBtn: false,
      vehicleId: '',
      vehicleTracker: '',
      form: { ...formDefault },
      vehicleStatus: ''
  })

  useEffect(() => {
    dataFormatting()
  }, [])

  useEffect(() => {
    checkRequiredFields()
  }, [state.vehicleTracker, state.form])
  
  // componentWillMount () {
  //   dataFormatting()
  // }
  const dataFormatting = () => {
    let findAreaId = props.areaList&&props.areaList.find(item => item.key === (props.selecteditem&&props.selecteditem.areaId));
    let dt = JSON.parse(JSON.stringify(props.selecteditem))
    if (props.showItemDetail && props.trackersApiResponce) {
      let formData = setFormData(dt)
      let attributes = dt.attributes
      let trackers = props.trackerData
      formData = { ...formData, attributes, trackers }
      setState((prevState)=>({
        ...prevState,
        selectedVehicle: formData,
        vehicleId: dt.id,
        vehicleTracker: props.trackerData
      }))
    } else {
      if (
        props.activeOperation === 'edit' &&
        props.trackersApiResponce
      ) {
        if (
          !state.editOption ||
          !isEqual(state.selectedVehicle, dt)
        ) {
          setState((prevState)=>({
            ...prevState,
            form: { 
              ...dt,
              areaId:findAreaId
            },
            vehicleId: dt.id,
            editOption: true,
            addOption: false,
            selectedVehicle: dt,
            vehicleTracker: props.trackerData
          }))
        }
      }
      if (props.activeOperation === 'add' && !state.addOption) {
        setState((prevState)=>({
          ...prevState,
          vehicleTracker: '',
          vehicleId: '',
          selectedVehicle: '',
          addOption: true,
          editOption: false,
          waslLinked:true,
          form:{...formDefault,attributes:{}}
        }))
      }
    }     
  }
  const setFormData = data => {
    let formData = {
      label: data.label,
      garage: data.garage,
      model: data.model,
      vehicleType: data.vehicleType,
      expirationTime: data.expirationTime,
      vehicleLicensePlate: data.vehicleLicensePlate || '',
      vin: data?.attributes?.vin || '',
      chassisNumber: data?.attributes?.chassisNumber || '',
      permittedSpeed: data?.attributes?.permittedSpeed || ''
    }
    return formData
  }
  // const ac_submit =()=> {
  //   let { form } = state
  //   const obj = vehicleDataFormat(form)
  //   setState({
  //     isVisableUserBtn: false
  //   })
  //   // fetch('/api/vehicles', {
  //   //   method: 'POST',
  //   //   headers: {
  //   //     Accept: 'application/json',
  //   //     'Content-Type': 'application/json'
  //   //   },
  //   //   body: JSON.stringify({
  //   //     ...obj
  //   //   })
  //   // })
  //   instance({
  //     url: `/api/vehicles`,
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     data:{
  //       ...obj
  //     }
  //   })
  //     // .then(response => {
  //     //   if (response.ok) {
  //     //     response.json()
  //         .then(data => {
  //           if (data.status === 'success') {
  //             props.dispatch(addvehicle(data.data))
  //             props.onCloseModal()
  //             setState({
  //               addOption: false,
  //               selectedVehicle: '',
  //               form: formDefault
  //             })

  //             props.dispatch(
  //               Notifications.success({
  //                 message: props.translate('vehicleIsAdded'),
  //                 autoDismiss: 10
  //               })
  //             )
  //           } else {
  //             if(data && data.message && data.message.includes('vehicles_deviceid')) {
  //               props.dispatch(
  //                 Notifications.error({
  //                   message: props.translate('alreadyAttachedWithVehicle'),
  //                   autoDismiss: 10
  //                 })
  //               )
  //             } else {
  //               props.dispatch(
  //                 Notifications.error({
  //                   message: props.translate('vehicleLicensePlateAllreadyInUsed'),
  //                   autoDismiss: 10
  //                 })
  //               )
  //             }
  //           }
  //         })
  //     //   } else {
  //     //     throw response
  //     //   }
  //     // })
  //     .catch(error => {errorHandler(error, props.dispatch)})
  //     // .catch(e => {
  //     //   props.dispatch(
  //     //     Notifications.error({
  //     //       message: props.translate('vehicleFormateIssue'),
  //     //       autoDismiss: 10
  //     //     })
  //     //   )
  //     // })
  // }
  const uc_submit = disableWaslCall => {
    let { form, selectedVehicle: { id } } = state
    const { waslEnabled } = props?.serversettings?.attributes || {};
    if(waslEnabled) {
      setState((prevState)=>({
        ...prevState,
        waslStatus: <span className="loading-status-text"> <LoadingIndicator /> Processing</span>,
      }))
    }
    setState((prevState)=>({
      ...prevState,
      vehicleStatus: <span className="loading-status-text"><LoadingIndicator /> Processing</span>,
    }))
    const obj = vehicleDataFormat(form, id)
    if(!obj.attributes.WASLKey) {
      delete obj.attributes.WASLKey;
    }
    setState((prevState)=>({
      ...prevState,
      isVisableUserBtn: false
    }))
    const data = {
      url: id ? `/api/vehicles/${id}` : `/api/vehicles/`,
      method: id ? `PUT` : `POST`,
      successMsg: id ? `vehicleIsUpdated` : `vehicleIsAdded`,
    };

    instance({
      url:data.url,
      method: data.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
    .then(d => {
      // if (d.status === 200 && d.data.status === 'success') {
        setState((prevState)=>({
          ...prevState,
          form:{...formDefault,attributes:{}}})
        )
        props.dispatch(id ? updatevehicle(d) : addvehicle(d))
        if(!disableWaslCall && waslEnabled && state.waslLinked) {
          submitWasl(obj, d);
        }
        else {
          handleAfterResponse();
        }
        props.dispatch(
          Notifications.success({
            message: props.translate(data.successMsg),
            autoDismiss: 10
          })
        )
        onCloseSubModal()
      // }
    })
    .catch(error => {errorHandler(error, props.dispatch)})
  }

  const handleAfterResponse = () => {
    setState((prevState)=>({
      ...prevState,
      vehicleStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>
    }))
  }

 const  onChangedDevice = item => {
    if (item) {

      const device = props.devices.find(d => d.id === item.id)

      setState((prevState)=>({
        ...prevState,
          vehicleTracker: { value: device.id, label: device.name, uniqueId: device.uniqueId },
          form: {
            ...state.form,
            deviceId: item.value
          }
        }
        // ,() => checkRequiredFields()
      ))
    } else {
      setState((prevState)=>({
        ...prevState,
          vehicleTracker: '',
          form: {
            ...state.form,
            deviceId: ''
          }
        }
        // ,() => checkRequiredFields()
      ))
    }
  }

  // const enableAssignTrackerModal = () => {
  //   setState({
  //     isVisableTrackerModal: true
  //   })
  // }
  // const onCloseModal = () => {
  //   setState({
  //     isVisableTrackerModal: false
  //   })
  // }
 const onCloseSubModal =() =>{
  setState((prevState)=>({
    ...prevState,
      addOption: false,
      editOption: false
    }))
    props.onCloseModal()
  }

  const handleChangeLicenseExpDate = name => event => {
    if (name === 'expirationTime') {
      setState((prevState)=>({
        ...prevState,
          form: {
            ...state.form,
            [name]: event.toISOString()
          }
        }
        // , () => checkRequiredFields()
      ))
    } 
  }
  const setExpirationTime = e => {
    e.stopPropagation()
    setState((prevState)=>({
      ...prevState,
        form: {
          ...state.form,
          expirationTime: null
        }
      }
      // ,() => checkRequiredFields()
    ))
  }
  const handleChange= (name, event) =>{
    const { target } = event
    if (target && target.type === 'checkbox') {
      setState((prevState)=>({
        ...prevState,
          form: {
            ...state.form,
            [name]: target.checked
          }
        }
        // ,() => checkRequiredFields()
      ))
    } else if(name === 'areaId'){
      setState((prevState)=>({
        ...prevState,
            form: {
              ...state.form,
              [name]: event
            }
          }
          // ,() => checkRequiredFields()
        ))
    } 
    else {
      let value = target.value.toString()
      setState((prevState)=>({
        ...prevState,
          form: {
            ...state.form,
            [name]: value
          }
        }
        // ,() => checkRequiredFields()
      ))
    }
  }
  const handleChangeForAttributesValues = (name, event) => {
    let { attributes } = state.form
    const target= name === 'trasmissionType'?event:event.target
    let value = name === 'trasmissionType' ? event :target.value.toString()
    if (value) {
      attributes[name] = value
      setState((prevState)=>({
        ...prevState,
          form: {
            ...state.form,
            attributes: { ...attributes }
          }
        }
        //, () => {
          // if (state.editOption) {
          //   setState({
          //     isVisableUserBtn: true
          //   })
          // }
        // }
      ))
      if (state.editOption) {
        setState((prevState)=>({
          ...prevState,
          isVisableUserBtn: true
        }))
      }
    } else {
      delete attributes[name]
      setState((prevState)=>({
        ...prevState,
          form: {
            ...state.form,
            attributes: { ...attributes }
          }
        }
        //, () => {
          // if (state.editOption) {
          //   setState({
          //     isVisableUserBtn: true,
          //     waslLinked:false
          //   })
          // }
        // }
      ))
      if (state.editOption) {
        setState((prevState)=>({
          ...prevState,
          isVisableUserBtn: true,
          waslLinked:false
        }))
      }
    }
  }
  const checkRequiredFields =()=> {
    let { label, vehicleLicensePlate } = state.form
    let value = true
    if (state.editOption) {
      value = !isEqual(state.form, props.selecteditem)
    }
    if (label && label.trim().length && vehicleLicensePlate && vehicleLicensePlate.trim().length && value) {
      setState((prevState)=>({
        ...prevState,
        isVisableUserBtn: true
      }))
    } else {
      setState((prevState)=>({
        ...prevState,
        isVisableUserBtn: false
      }))
    }
  }

  const submitWasl = vehicle => {
    const { waslCompanyRefKey } = props?.serversettings?.attributes || {};
    if(waslCompanyRefKey) {

      const {number, leftLetter, rightLetter, plateType, middleLetter, sequenceNumber} = vehicle.attributes;
      const { uniqueId } = state.vehicleTracker;
      if(number && leftLetter && rightLetter && plateType && middleLetter && sequenceNumber && uniqueId) {
        instance.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {  
          hook: 'registerVehicle',
          account_id: props.serversettings.id,
          data: {
            uniqueId,
            sequenceNumber,
            activity: 'DEFAULT',
            attributes: {
              rightLetter,
              number,
              leftLetter,
              middleLetter,
              plateType,
            }
          },
          hook_prefix: waslCompanyRefKey
        }).then(response => {
          // if(response.status === 200 && response.data && response.data.success) {
            setState((prevState)=>({
              ...prevState,
              waslStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>,
            }))
            if(response.result && response.result.referenceKey) {
              handleChangeForAttributesValues('WASLKey', { target: {value: response.result.referenceKey} } );
              uc_submit(true);
            }
          // }
        }).catch(error => {
              let res = error?.response?.data || {}
              let check = res&&res.data&&res.data.result
              if(check&& check.referenceKey){
                    handleChangeForAttributesValues('WASLKey', { target: {value: check.referenceKey} } );
                    uc_submit(true);
              }

              setState((prevState)=>({
                ...prevState,
            waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> invalid form</span>,
          }))
          handleAfterResponse()
          
        })
      }
      else {
        setState((prevState)=>({
          ...prevState,
          waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> incomplete form</span>,
        }))
        handleAfterResponse();
      }
    }
    else {
      handleAfterResponse();
    }
  }

  // render () {
    let { selecteditem } = props
    
    if (
      selecteditem.id !== state.vehicleId &&
      props.trackersApiResponce
    ) {
      dataFormatting()
    }
    return (
      
      <div style={{ height: '100%', position: 'relative', zIndex: 9999 }}>
        {state.addOption && (
          <AddVehicle
            {...state}
            dispatch={props.dispatch}
            logInUsers={props.logInUsers}
            onClose={onCloseSubModal}
            formSubmit={uc_submit}
            changeAttribute={changeAttribute}
            handleChange={handleChange}
            handleChangeForAttributesValues={
              handleChangeForAttributesValues
            }
            handleChangeLicenseExpDate={
              handleChangeLicenseExpDate
            }
            setExpirationTime={setExpirationTime}
            onChangedDevice={onChangedDevice}
            form={state.form}
            buttonText={'sharedCreate'}
            isHaveAccess={'vehicleCreate'}
            areaList={checkPrivileges('area') ?props.areaList : []}
          />
        )}
        {state.editOption && (
          <AddVehicle
            {...state}
            dispatch={props.dispatch}
            serversettings={props.serversettings}
            logInUsers={props.logInUsers}
            onClose={onCloseSubModal}
            formSubmit={uc_submit}
            changeAttribute={changeAttribute}
            handleChange={handleChange}
            handleChangeForAttributesValues={
              handleChangeForAttributesValues
            }
            handleChangeLicenseExpDate={
              handleChangeLicenseExpDate
            }
            setExpirationTime={setExpirationTime}
            onChangedDevice={onChangedDevice}
            buttonText={'update'}
            isHaveAccess={'vehicleUpdate'}
            areaList={checkPrivileges('area') ?props.areaList : []}
          />
        )}
        {state.selectedVehicle &&
        props.showItemDetail &&
        props.trackersApiResponce ? (
          <EditItem
            logInUsers={props.logInUsers}
            currentLocation={props.currentLocation}
            selectedItem={state.selectedVehicle}
            handleClose={onCloseSubModal}
            devicesIcons={props.devicesIcons}
          />
        ) : !state.addOption && !state.editOption ? (
          <Loader />
        ) : null}
        {props.showWaslShortList && props.vehicleHookData ? (
          <WaslShortList
            logInUsers={props.logInUsers}
            currentLocation={props.currentLocation}
            handleClose={onCloseSubModal}
            devicesIcons={props.devicesIcons}
            vehicleHookData={props.vehicleHookData}
            sequenceNumber={props.sequenceNumber}
          />
        ) : null}
      </div>
    )
  // }
}

const mapState = state => ({
  logInUsers: state.logInUsers,
  serversettings: state.ServerSetting,
  themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export const VehicleModal = mapStateToProps(
  (withLocalize(vehicleModal))
)

const LoadingIndicator = () => {
  return <span className="status-icon">
      <span className="loading-dot" style={{ animationDelay: '0ms' }} />
      <span className="loading-dot" style={{ animationDelay: '160ms' }} />
      <span className="loading-dot" style={{ animationDelay: '320ms' }} />
    </span>
  }