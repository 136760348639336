import React, { Component } from 'react'
import { connect } from 'react-redux'
import { convertToBinary } from '../../Helpers'
import CustomDialog from '../common/Dialog'
const mapStateToProps = (state) => {
  return {
    themecolors: state.themeColors
  }
}
class ProtocolDialog extends Component {
  render () {
    const dialoagHtml = []
    let protocolConvert = null
    if (this.props.input && this.props.protocol) {
      protocolConvert = convertToBinary(this.props.input, this.props.protocol)
      if (Object.keys(protocolConvert).length) {
        Object.keys(protocolConvert).map(key => {
          dialoagHtml.push(
            <div className='clearfix item-row'>
              <span className='row-label'>{key}</span>{' '}
              <span className='row-value'>{protocolConvert[key]}</span>
            </div>
          )
        })
      }
    }
    return (
      <CustomDialog
        title={this.props.inputName}
        themecolors={this.props.themecolors}
        visable
        onClose={this.props.handleClose}
        maxWidth='xs'
        bodyPadding={0}
      >
        <div className='feature-section feature-content-section'>
          {dialoagHtml}
        </div>
      </CustomDialog>
    )
  }
}

export default connect(mapStateToProps)(ProtocolDialog)
