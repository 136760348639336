import React, { memo } from 'react';
import { useDrop } from 'react-dnd';
import { Translate } from 'react-localize-redux';
import { ReactComponent as DnDIcon } from '../../assets/move.svg';

export const Dustbin = memo(function Dustbin({ accept, lastDroppedItem, onDrop, form }) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
console.warn('LAstDropped',lastDroppedItem)
    return (
        <div
            className={isOver ? 'dnd-drop dnd-hover dnd-dropable' : canDrop ? 'dnd-drop dnd-dropable' : 'dnd-drop'}
            ref={drop}
            role="region"
        >
            {lastDroppedItem && lastDroppedItem.length ? (
                <>
                    {form && form.attribute}: {lastDroppedItem.map(r => (
                        <span key={r.name} className="tag">{r.name}</span>
                    ))}
                    {(isOver || canDrop) && <span className="dropable-placeholder"></span>}
                </>
            ) : (
                <div className="dnd-empty">
                    <h2><Translate id="dragDrop" /></h2>
                    <DnDIcon />
                </div>
            )}
        </div>
    );
});
