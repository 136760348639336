import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import { getNotification } from '../../Actions/Notifications'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
import instance from '../../axios'

class NotificationSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: {},
      displayList: [],
      search: '',
      isChecked: false
    }
  }

  componentWillMount () {
    let list = {}
    if (this.props.value && this.props.value.length) {
      this.props.value.map(d => (list[d] = true))
      this.setState({ list })
    }

    this.prepareNotifications(this.props)
  }

  componentWillUnmount () {
    this.setState({
      list: {},
      search: '',
      displayList: [],
      isChecked: false
    })
  }

  componentWillReceiveProps (n) {
    this.prepareNotifications(n)
  }

  prepareNotifications = n => {
    let displayList = n.notificationList
    this.setState({ displayList })
  }

  sendToParent () {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list))
    }
  }

  onChange = (e, v) => {
    let list = { ...this.state.list }
    if (e.target.checked) {
      list[v.id] = true
    } else {
      delete list[v.id]
    }
    this.setState(
      {
        list,
        isChecked:
          this.props.notificationList.length === Object.keys(list).length
      },
      () => {
        this.sendToParent()
      }
    )
  }

  searchItem = text => {
    this.setState({ search: text.target.value })
  }

  toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      this.props.notificationList.map(d => (list[d.id] = true))
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent()
      })
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent()
      })
    }
  }

  componentDidMount () {
    if (this.props.logInUser) {
      this.fetchData()
    }
  }

  fetchData = () => {
    // fetch(`/api/notifications/get?limit=-1`)
    instance({
      url: `/api/notifications/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params :{
        limit: -1
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(res => {
          this.setState(
            {
              displayList: res.data
            },
            () => {
              this.props.dispatch(getNotification(res.data))
            }
          )
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(() => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  render() {
    let list = this.state.displayList.filter(
      item =>
        (item.name + '' + item.id).toLowerCase().indexOf(this.state.search) !==
        -1
    );
    return Style.it(
      `
        .selector-list li > label.active,
        .selector-list li > label:hover {
          background: ${this.props.themecolors.themeLightColor};
          color: ${this.props.themecolors.themeLightInverse};
        }
        .selector-list li {
          margin-bottom: 6px;
        }
        .selector-list li > label {
          cursor: pointer;
          display: block;
          padding: 2px 6px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          min-height: 26px;
          background: ${this.props.themecolors.themeListingColor};
        }
        .selector-list {
          padding: 0;
          margin: 0;
          color: ${this.props.themecolors.themeListingInverse};
          list-style: none;
        }
        .selector-label {
          font-size: 12px;
          line-height: 1.5;
          margin-left: 6px;
        }
        .selector-image {
          width: 26px;
          margin-left: 6px;
        }
        .selector-image img {
          height: auto;
          width: 26px;
          height: 26px;
          display: block;
          margin: auto;
        }
        .selector-check {
          padding: 2px 16px;
        }
        .selector-count {
          padding: 0 0 6px;
          font-size: 10px;
        }
      `,
      <div>
        <div
          className='section-head clearfix section-head-filter'
          style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
        >
           <ul className='filter-row pull-left'>
              <li>
                <SearchField
                  label={this.props.translate('searchNotification')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {!this.props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3}}>
                   <Checkbox
                   checked={this.state.isChecked}
                   onChange={this.toggleItems}
                  label={this.props.translate('selectAll')}
                      />

                </li>
              ) : null}
            </ul>
        </div>
        {!this.props.readOnly ? (
          <div className='selector-count'>
            {Object.keys(this.state.list).length}{' '}
            {this.props.translate('EventSelected')}
          </div>
        ) : null}
        <Scrollbar
          disableTracksWidthCompensation={true}
          style={{
            height:
              (this.props.minHeight
                ? parseInt(this.props.minHeight)
                : 32) * (this.props.rows || 10),
          }}
          scrollerProps={{
            renderer: (props) => {
              const { elementRef, ...restProps } = props;
              return (
                <div
                  {...restProps}
                  ref={elementRef}
                  id='scrollableDivNotification'
                />
              );
            },
          }}
        >
          <ul className='selector-list'>
            {list.map((row) => (
              <li key={row.id}>
                <label
                  className={this.state.list[row.id] ? 'active' : null}
                  style={{display:"flex",justifyContent:"flex-start"}}
                >
                  {!this.props.readOnly ? (
                    <span className='selector-check' style={{padding:3,margin:0}}>
                      <Checkbox
                        checked={this.state.list[row.id]}
                        value={row}
                        onChange={this.onChange}
                      />
                    </span>
                  ) : null}
                  <span className='selector-label' style={{padding:0,marginLeft:6}}>{row.name}</span>
                </label>
              </li>
            ))}
          </ul>
        </Scrollbar>
      </div>
    );
  }
}

const mapState = (state) => ({
  notificationList: state.notification,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(withLocalize(NotificationSelector));
