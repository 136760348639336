import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import Button from '../../../Components/common/Button'
import SearchField from '../../../Components/common/SearchField'
import { withLocalize } from 'react-localize-redux'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Notifications from 'react-notification-system-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'
import instance from '../../../axios'
import { removedTypes } from '../../../Actions/Services'


const Commands = (props) => {

 const search = (e) => {
    props.searchItems(e.target.value)
  }

    const { services } = props
    const commands1 = services.map(g => (
      <ListRow
        key={g.id}
        item={g}
        editCommandForm={props.editCommandForm}
        modeEmpty={props.modeEmpty}
        onEditType={props.onEditType}
      />
    ))

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('command') && (
            <div>
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <SearchField
                      label={props.translate('searchServices')}
                      fullWidth
                      onChange={e => search(e)}
                    />
                  </li>
                  <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                    {(checkPrivileges('expensetypeCreate')) && (
                      <Button
                        size='small'
                        onClick={() => props.openCreateFrom()}
                      >
                        {props.translate('Create')}
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
              <ul className='list-view with-padding-right geofence-sidebar-list'>
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: props => {
                      const { elementRef, ...restProps } = props
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id='scrollableDiv'
                        />
                      )
                    }
                  }}
                >
                  {props.itemPagination && props.itemPagination.items ? (
                    <InfiniteScroll
                      dataLength={props.itemPagination.items.length}
                      next={props.fetchMoreItems}
                      hasMore={props.itemPagination.hasNext}
                      scrollableTarget='scrollableDiv'
                      endMessage={props.endMessage}
                    >
                      {commands1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
  }

const ListRow1 = (props) => {

  const dispatch =useDispatch();

const [isLoading,setIsLoading]=useState(false)
    const [state,setState] = useState({
      onDeleteConfirmation: false 
      })

      const prevProps = useRef();
      const prevState = useRef();
    
      useEffect(() => {
        prevProps.current = props;
        prevState.current = state;
      });
    
      useEffect(() => {
        if (!isEqual(prevProps.current, props) || !isEqual(prevState.current, state)) {
          // Handle side-effects here if necessary
        }
      }, [props, state]);


 const onDelete = () => {
    if (props.item.id) {
setIsLoading(true)
      instance({
        url: `api/expensetypes/${props.item.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
          dispatch(removedTypes({ id: props.item.id }))
setIsLoading(false)

          dispatch(
            Notifications.success({
              message: props.translate('savedServiceIsDeleted'),
              autoDismiss: 10
            })
          )
          setState(prevState=>({ ...prevState, item: {} }))
      }).catch(error => {
setIsLoading(false)
        
        errorHandler(error, dispatch)})
    }
  }

  const onRemovedItem = () => {
    setState(prevState=>({
      ...prevState,
      onDeleteConfirmation: true
    }))
  }
 const onCancel = () => {
    setState(prevState=>({
      ...prevState,
      onDeleteConfirmation: false
    }))
  }

    return (
      <li className={'list-row clearfix'} key={props.item.id}>
        <label
          className='checkbox'
          onClick={() => props.editCommandForm(props.item)}
        >
          <span className='unit-name' style={{ fontSize: 12 }}>
            {props.item.name}
          </span>
        </label>
        <div className='callout-actions'>
          {(checkPrivileges('garageDelete') || true) && (
            <span className='action' onClick={onRemovedItem}>
              <DeleteIcon className='material-icons'/>
            </span>
          )}
        </div>
        <div className='callout-actions'>
          {(checkPrivileges('garageDelete') || true) && (
            <span className='action' onClick={()=>props.onEditType(props.item)}>
              <EditIcon className='material-icons'/>
            </span>
          )}
        </div>

        {state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={onDelete}
            title={props.translate('areYouWantToDelete')}
            children={props.item.description}
            loading={isLoading}
          />
        )}
      </li>
    )
}


export const ListRow = (withLocalize(ListRow1))
export default Commands;
