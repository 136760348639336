import React, { useCallback, useEffect, useState } from 'react'
import './index.scss'
import { searchvehicle } from '../../Actions/Vehicles'
import { searchDriver } from '../../Actions/Drivers'
import { searchJob } from '../../Actions/Jobs'
import { searchUser } from '../../Actions/Users'
import { searchcalendar } from '../../Actions/Devices'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle
} from '@fortawesome/free-solid-svg-icons'
import CloseIcon from '../../assets/nav/close.svg'
import Draggable from 'react-draggable'
import SidebarInner from './SidebarInner'


library.add([
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle
])



const Sidebar=(props)=> {
const [state,setState]=useState({
  collapse: false,
  view: true
})
const Wrapper =useCallback((props)=> {
  if (props.type === true) {
    return props.children
  } else {
    return (
      <Draggable axis='x' handle='.sidebar-dragicon' bounds='parent'>
        {props.children}
      </Draggable>
    )
  }
},[])
  const collapseSidebar =()=> {
    props.sidebarClose()
  }

 const collapseSidebarWithButton= () =>{
    props.collapseSidebarWithButton()
  }
useEffect(()=>{
  return()=>{
    if (props.location.pathname === '/drivers') {
      props.dispatch(searchDriver({ driver: '' }))
    } else if (props.location.pathname === '/schedules') {
      props.dispatch(searchJob({ job: '' }))
    } else if (props.location.pathname === '/calendars') {
      props.dispatch(searchcalendar({ calendar: '' }))
    } else if (props.location.pathname === '/vehicles' || 
      props.location.pathname === '/vehicle') {
      props.dispatch(searchvehicle({ vehicle: '' }))
    } else if (
      props.location.pathname.indexOf('/users') !== -1 ||
      props.location.pathname.indexOf('/users2') !== -1
    ) {
      props.dispatch(searchUser({ user: '' }))
    }
setState((prevstate)=>({...prevstate,view:false}))
  }
},[])


  const toggleListView = () => {
    if (props.location.pathname.indexOf('/monitoring') !== -1) {
      setState((prevstate)=>({
        ...prevstate,
        view:!prevstate.view
      }))
    }
  }

    const emptyLinks = null

    let cls = 'other-page'
    if (props.location.pathname.indexOf('/monitoring') !== -1) {
      cls = 'monitoring-page'
    }

    if (props.noSidebar) {
      return null
    } else {
      return (
        <Wrapper type={props.collapse}>
          <div
            className={
              'fms-sidebar-wrapper ' +
              cls +
              ' ' +
              props.view +
              ' ' +
              (state.view ? 'main-list-view' : 'main-thumb-view')
            }
          >
            <div className='sidebar-collapse-button'>
              <a
                className='hidden-responsive'
                href={emptyLinks}
                onClick={collapseSidebarWithButton}
              >
                {props.collapse ? (
                  <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                ) : (
                  <svg fill='currentColor' width={10} height={10}>
                    <use xlinkHref={`${CloseIcon}#icon`} />
                  </svg>
                )}
              </a>
              <a
                className='visible-responsive'
                href={emptyLinks}
                onClick={collapseSidebarWithButton}
              >
                {props.collapse ? (
                  <svg fill='currentColor' width={10} height={10}>
                    <use xlinkHref={`${CloseIcon}#icon`} />
                  </svg>
                ) : (
                  <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                )}
              </a>
              <a
                href={emptyLinks}
                className='sidebar-dragicon sidebar-thumbs-icon hidden-responsive'
              >
                <DragIndicatorIcon />
              </a>
            </div>

            <SidebarInner
              getSensorsData={props.getSensorsData}
              collapseSidebar={collapseSidebar}
              toggleListView={toggleListView}
              ListViewSwitch={state.view}
              {...props}
            />
          </div>
        </Wrapper>
      )
    }
}

export default Sidebar