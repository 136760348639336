import React, { useEffect, useState } from 'react'
import moment from 'moment'
import L from 'leaflet'
import Layout from './../../Layout'
import RouteMap from './../../Components/Maps/RouteMap'
import './../../assets/KML/index.js'
import 'moment-duration-format'
import { checkPrivileges, errorHandler, getDateTimeFormat, setAttributeFormat } from '../../Helpers'
import jsontoxml from 'jsontoxml'
import axios from 'axios'
import instance from '../../axios'
import { useDispatch, useSelector } from 'react-redux'
import { withLocalize } from 'react-localize-redux'

const ReportData = []

let trips = {}

const Tracks = (props) => {
  const [state, setState] = useState({
    groupsCall: false,
    view: 'displayForm',

    from: '',
    trackLoading: false,
    mainView: 'tracks',
    to: '',
    selectedDate: '',
    deviceId: [],
    groupId: [],
    reportsData: ReportData,
    tripsDialog: false,
    loading: false,
    validatedForm: false,
    detail: false,
    detail1: false,
    reportUnits: true,
    notificationId: [],
    minimalNoDataDuration: 0,
    minimalParkingDuration: 0,
    minimalTripDuration: 0,
    minimalTripDistance: 0,
    speedThreshold: 0,
    processInvalidPositions: false,
    useIgnition: false,
    advanceSettingsForm: {},
    routes: {},
    trips: trips,
    routesLoading: {},
    fetchAll: false,
    timemachineArray: {},
    timemachinePositions: [],
    kmlContent: '',
    isCheck: false,
    listType: "geofences"

  })

  const [routeFetch, setRouteFetch] = useState([])
  const [updatedRouteId, setUpdateRouteId] = useState(null)


  const devices = useSelector((state) => state.devices?.data)
  const logInUser = useSelector((state) => state.logInUsers)
  const groups = useSelector((state) => state.groups)
  const ServerSetting = useSelector((state) => state.ServerSetting)
  const themecolors = useSelector((state) => state.themeColors)
  const dispatch = useDispatch()
  const pageLoading = (action) => {
    setState((prevState) => ({
      ...prevState,
      trackLoading: action
    }))
  }

  const toggleDaywiseGroup = (groupId, check, key) => {

    
    const trips = JSON.parse(JSON.stringify(state.trips));

    trips[groupId]['data'][key].map(row => {
      if (check === true) {
        row.checked = true
        setState((prevState) => {
          let updatedState = { ...prevState, [key]: true }
          drawRoute(row, updatedState[key])
          return updatedState
        })
      } else {
        row.checked = false
        setState((prevState) => {
          let updatedState = { ...prevState, [key]: false }
          cancelRoute(row)
          return updatedState
        })
      }

      return null
    })

    setState((prevState) => ({ ...prevState, trips }))
  }
  const toggleAllGroup = (groupId, check) => {
    let trips = JSON.parse(JSON.stringify(state.trips))
    Object.keys(trips[groupId]['data']).map(key => {
      trips[groupId]['data'][key].map(row => {
        if (check === true) {
          row.checked = true
         drawRoute(row,true);
        } else {
          row.checked = false
          cancelRoute(row);
        }

        return null
      })
      return null
    })
    setState((prevState) => ({ ...prevState, trips ,isCheck:check}))
  };

  const changeCheckbox = (groupDate, groupId, rowId, check) => {
    let trips = JSON.parse(JSON.stringify(state.trips))

    trips[groupId]['data'][groupDate].map(row => {
      if (row.startPositionId === rowId) {
        row.checked = check
      }
      return null
    })
    setState((prevState) => ({ ...prevState, trips }))
  };

const [isUpdateColor,setisUpdateColor]=useState(false)
  const updateColor = (groupDate, groupId, rowId, color) => {
    let trips = JSON.parse(JSON.stringify(state.trips))
    let routes = JSON.parse(JSON.stringify(state.routes))

    trips[groupId]['data'][groupDate].map(row => {
      if (row.startPositionId === rowId) {
        row.color = color
        row.checked = true

        if (routes[row.startPositionId] && routes[row.startPositionId].row) {
          routes[row.startPositionId].row.color = color
        }
      }
      return null
    })

    let r = trips[groupId]['data'][groupDate].find(
      row => row.startPositionId === rowId
    )
    setState((prevState) => {
      let updatedTrips = { ...prevState.trips,...trips }
      let updatedRoutes = { ...prevState.routes,...routes }
      return { ...prevState, trips: updatedTrips, routes: updatedRoutes }
    })
    setUpdateRouteId(r)
    setisUpdateColor(true)
  }

  const updatedRoute = (id) => {
    cancelRoute(id)
    drawRoute(id)
  }

  useEffect(() => {
    if (updatedRouteId !== null&&isUpdateColor) {
      updatedRoute(updatedRouteId)
      setisUpdateColor(false)
    }
  }, [updatedRouteId,isUpdateColor])

  const changeMainView = (mainView) => {
    setState((prevState) => ({
      ...prevState,
      mainView: mainView
    }))
  }

  const advanceFormChange = (event, name) => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    setState((prevState) => ({
      ...prevState,
      advanceSettingsForm: { ...prevState.advanceSettingsForm, [name]: value }
    }))
  }

  const reportTypeSelection = () => {
    setState((prevState) => ({
      ...prevState,
      reportUnits: !prevState.reportUnits
    }))
  }

  // const onCloseModal = (e, name) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     [name]: false
  //   }))
  // }

  const openForm = () => {
    setState((prevState) => ({
      ...prevState,
      view: "displayForm",
      reportsData: [],
      routes: {}
    }))
  }

  // const saveData = (blob, filename) => {
  //   if (window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveOrOpenBlob(blob, filename)
  //   } else {
  //     const a = document.createElement('a')
  //     document.body.appendChild(a)
  //     const url = window.URL.createObjectURL(blob)
  //     a.href = url
  //     a.download = filename
  //     a.click()
  //     setTimeout(() => {
  //       window.URL.revokeObjectURL(url)
  //       document.body.removeChild(a)
  //     }, 0)
  //   }
  // }
  const getRandomColor = () => {
    let letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  // const calcTime = (date, offset) => {
  //   let d = new Date(date)

  //   let utc = d.getTime() + d.getTimezoneOffset() * 60000

  //   let nd = new Date(utc + 3600000 * offset)

  //   return nd.toISOString()
  // }

  const createReport = async (data) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      trackLoading: true,
      view: "",
      reportsData: []
    }))
    // let header
    let advanceSettings = {
      ...state.advanceSettingsForm,
      minimalTripDistance:
        state.advanceSettingsForm.minimalTripDistance !== ''
          ? parseFloat(state.advanceSettingsForm.minimalTripDistance) *
          1000
          : 500
    }
    delete advanceSettings.advanceSettings

    let form = {
      to: state.to || data.to,
      from: state.from || data.from,
      deviceIds: (state.deviceId.length !== 0 && state.deviceId) || (data.deviceIds.length !== 0 && [data.deviceIds]) || [],
      itemGroupIds: state.groupId || [],
      detail: state.detail1,
      types: state.notificationId || [],
      mail: false
      //  ...advanceSettings
    }

    // header = {
    //   headers: new Headers({
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json'
    //   }),
    //   method: 'POST',
    //   body: JSON.stringify(form)
    // }

    // fetch('/api/reports/trips', { ...header })
    await instance({
      url: `/api/reports/trips`,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }),
      method: 'POST',
      data: { ...form }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then(reports => {
        let trips = {}

        if (reports.length) {
          reports.map(position => {
            position.color = getRandomColor()

            trips[position.deviceId] = trips[position.deviceId] || {
              detail: position,
              data: {}
            }
            trips[position.deviceId]['data'][
              moment(position.startTime).format('YYYY-MM-DD')
            ] =
              trips[position.deviceId]['data'][
              moment(position.startTime).format('YYYY-MM-DD')
              ] || []
            trips[position.deviceId]['data'][
              moment(position.startTime).format('YYYY-MM-DD')
            ].push(position)
            return null
          })
        } else {
          setState((prevState) => ({ ...prevState, trackLoading: false }))
        }
        setState((prevState) => {
          // const updatedReports = [...prevState.reportsData, ...reports]
          // const updatedTrips = { ...prevState.trips, ...trips }
          return { ...prevState, reportsData: reports, trips: trips, fetchAll: false }
        })
        setState((prevState) => ({ ...prevState, view: "reportsData" }))

        //   })
        // } else {
        //   throw response
        // }
        //setState({loading: false})
      })
      .catch(error => { errorHandler(error, dispatch) })
  }

  const formatXml = xml => {
    var formatted = ''
    var reg = /(>)(<)(\/*)/g
    xml = xml.replace(reg, '$1\r\n$2$3')
    var pad = 0
    xml.split('\r\n').forEach((node) => {
      var indent = 0
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0
      } else if (node.match(/^<\/\w/)) {
        if (pad !== 0) {
          pad -= 1
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {// eslint-disable-line no-useless-escape
        indent = 1
      } else {
        indent = 0
      }

      var tab = ''
      for (var i = 0; i < pad; i++) {
        tab += '  '
      }

      formatted += tab + node + '\r\n'
      pad += indent
    })

    return formatted
  }
  const wrapperXML = (name, description, text) => {
    return formatXml(
      '<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document><name>' +
      name +
      '</name><description>' +
      description +
      '</description>' +
      text +
      '</Document></kml>'
    )
  }
  const clearSelection = () => {
    setState((prevState) => {
      let updatedState = { ...prevState, kmlContent: "", kmlType: null }
      const el = document.getElementById('clearSelection')
      if (el) {
        el.click()
      }
      return updatedState
    })
  }
  const downloadAllTracks = () => {
    if (state.reportsData && state.reportsData.length) {
      state.reportsData.map((row, index) => {
        fetchRoute(
          row,
          prepareRoute,
          undefined,
          state.reportsData.length === index + 1
        )
        return null
      })
    }
  }
  const downloadTracks = () => {
    let obj = JSON.parse(JSON.stringify(state.routes))
    let tracks = {}
    let serverTimeZoneName = 'Asia/Dubai'
    if (
      ServerSetting &&
      ServerSetting.attributes &&
      ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = ServerSetting.attributes.timezone
    }

    // rearrange tracks by unit
    Object.keys(obj).map(key => {
      const routeDef = obj[key]['row']
      const deviceId = routeDef['deviceId']
      tracks[deviceId] = tracks[deviceId] || {}
      tracks[deviceId][key] = routeDef
      tracks[deviceId].colors = tracks[deviceId].colors || []
      tracks[deviceId].colors.push(routeDef.color)
      tracks[deviceId].routesSummery = routeDef
      tracks[deviceId].routes = tracks[deviceId].routes || []
      tracks[deviceId].routes.push(obj[key].positions)
      return null
    })

    // initialize variables

    Object.keys(tracks).map(id => {
      let geojsonFeature = ''
      let geojsonFeatureStyle = ''
      let RouteTitle = ''
      let RouteDescription = ''
      let fileName = ''

      const { routesSummery, routes, colors } = tracks[id]
      RouteTitle =
        'Track ' +
        routesSummery.deviceName +
        ' ' +
        moment(routesSummery.startTime)
          .tz(serverTimeZoneName)
          .format('YYYY-MM-DD HH:mm') +
        ' - ' +
        moment(routesSummery.endTime)
          .tz(serverTimeZoneName)
          .format('YYYY-MM-DD HH:mm')
      RouteDescription = 'All tracks of ' + routesSummery.deviceName
      fileName = routesSummery.deviceName

      routes.map((route, index) => {
        let points = []
        route.map(pos => {
          points.push([pos.longitude + ',' + pos.latitude + ' '])
          return null
        })
        // get color, replace hash, split after each 2 letters, make reverse and finally join them back;
        const color = colors[index]
          .replace('#', '')
          .match(/.{1,2}/g)
          .reverse()
          .join('')

        // make styles
        const startId = 'markerStyleStart_' + index
        const endId = 'markerStyleEnd_' + index
        const styleId = 'mainKMLstyles_' + index

        let Placemark =
          '<Style id="' +
          startId +
          '"><IconStyle><Icon><href>https://flotilla.enroutech.com/assets/images/maps/start-marker.svg</href></Icon></IconStyle></Style>'
        Placemark +=
          '<Style id="' +
          endId +
          '"><IconStyle><Icon><href>https://flotilla.enroutech.com/assets/images/maps/end-marker.svg</href></Icon></IconStyle></Style>'
        Placemark +=
          '<Style id="' +
          styleId +
          '"><LineStyle><color>FF' +
          color +
          '</color><width>6</width></LineStyle></Style>'

        const currentRoute = route[0]['id']
        if (tracks[id] && tracks[id][currentRoute]) {
          const {
            startAddress,
            startTime,
            endAddress,
            endTime,
            distance,
            duration
          } = tracks[id][currentRoute]
          // make start point
          Placemark += jsontoxml({
            Placemark: {
              styleUrl: '#' + startId,
              name: 'Track #' + (index + 1) + ' start',
              Popup: {
                address: startAddress,
                time: moment(startTime)
                  .tz(serverTimeZoneName)
                  .format('YYYY-MM-DD HH:mm'),
                distance: setAttributeFormat('distance', distance),
                duration: setAttributeFormat('hours', duration)
              },
              Point: {
                coordinates: route[0].longitude + ',' + route[0].latitude
              },
              TimeStamp: {
                when: route[0].fixTime
              }
            }
          })

          // make end point
          Placemark += jsontoxml({
            Placemark: {
              styleUrl: '#' + endId,
              name: 'Track #' + (index + 1) + ' end',
              Popup: {
                address: endAddress,
                time: moment(endTime)
                  .tz(serverTimeZoneName)
                  .format('YYYY-MM-DD HH:mm'),
                distance: setAttributeFormat('distance', distance),
                duration: setAttributeFormat('hours', duration)
              },
              Point: {
                coordinates:
                  route[route.length - 1].longitude +
                  ',' +
                  route[route.length - 1].latitude
              },
              TimeStamp: {
                when: route[route.length - 1].fixTime
              }
            }
          })

          // make track
          Placemark += jsontoxml({
            Placemark: {
              name: 'Track #' + (index + 1),
              styleUrl: '#' + styleId,
              TimeSpan: {
                begin: route[0].fixTime,
                end: route[route.length - 1].fixTime
              },
              LineString: {
                coordinates: points
              }
            }
          })

          let folderXML = jsontoxml({
            Folder: {
              name: 'Track #' + (index + 1)
            }
          })

          let folderXMLFinal = folderXML.split('</name>')
          geojsonFeature +=
            folderXMLFinal[0] + '</name>' + Placemark + folderXMLFinal[1]
        }
        return null
      })

      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href =
        'data: application/xml;charset=utf-8,' +
        encodeURIComponent(
          wrapperXML(
            RouteTitle,
            RouteDescription,
            geojsonFeatureStyle + '' + geojsonFeature
          )
        )
      a.download = fileName + '.kml'
      a.click()
      a.remove()
      return null
    })
  }

  const fetchRoute = (row, callback, visible, isLastChild, type) => {
    // fetch(
    //   '/api/positions/' +
    //     row.deviceId +
    //     '?startPositionId=' +
    //     row.startPositionId +
    //     '&endPositionId=' +
    //     row.endPositionId +
    //     '&from=' +
    //     moment.utc(row.startTime).toISOString() +
    //     '&to=' +
    //     moment.utc(row.endTime).toISOString(),
    //   {
    //     method: 'GET',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   })
    instance({
      url: `/api/positions/${row?.deviceId}`,
      method: 'GET',
      params: {
        startPositionId: row?.startPositionId,
        endPositionId: row?.endPositionId,
        from: moment.utc(row?.startTime).toISOString(),
        to: moment.utc(row?.endTime).toISOString(),
      }
    })

      .then(positions => {
        callback(positions, row, visible, isLastChild, type)

      }).catch(() => {
        // errorHandler(error, dispatch)
      })
  }
  const upload = (n) => {
    if (n.target.files) {
      let e = n.target.files[0],
        t = e.name.split('.'),
        i = e.size,
        ko = 50000000,
        r = ['geojson', 'json', 'gpx', 'kml', 'xml'],
        u = t[t.length - 1].toLowerCase()

      if (r.includes(u)) {
        if (i > ko) {
          return (
            'File size is too large. Max allowed size is ' +
            Math.round(ko / (1024 * 1000)) +
            'MB'
          )
        } else {
          let p = new FileReader()
          p.readAsText(e, 'UTF-8')
          p.onload = async o => {
            let n = o.target.result
            if ('kml' === u) {
              let t = new DOMParser().parseFromString(n, 'text/xml'),
                tag = t && t.documentElement && t.documentElement.nodeName
              if ('kml' !== tag && 'gpx' !== tag) {
                return 'File content is not valid.'
              }
              u = tag
            }

            const parser = new DOMParser()
            const kml = parser.parseFromString(n, 'text/xml')

            const layer = new L.KML(kml)
            // geofence work start from here...

            const geofences = [];
            Object.values(layer._layers).map((l, i) => {

              if (l instanceof L.Polygon) {

                const seeArea = L.GeometryUtil.geodesicArea(l._latlngs[0]);

                let prepare = l._latlngs[0].map(pos => {
                  return '' + pos.lat + ' ' + pos.lng + ''
                })

                geofences.push({
                  name: 'Imported Polygon ' + (moment().format('YYYY-MM-DD') + ' ' + (i + 1)),
                  calendarId: 0,
                  description: 'Imported Polygon',
                  area: 'POLYGON((' + prepare.join(', ') + '))',
                  attributes: {
                    type: 'polygon',
                    color: l.options.fillColor,
                    latlng: l._latlngs[0],
                    area: seeArea
                  }
                });
              } else if (l instanceof L.Polyline) {

                let distance = 0
                let prepare = l._latlngs.map((pos, index) => {
                  distance += pos.distanceTo(l._latlngs[index - 1] || pos)
                  return '' + pos.lat + ' ' + pos.lng + ''
                })
                geofences.push({
                  name: 'Imported Polyline ' + (moment().format('YYYY-MM-DD') + ' ' + (i + 1)),
                  calendarId: 0,
                  description: 'Imported Polyline',
                  area: 'LINESTRING (' + prepare.join(', ') + ')',
                  attributes: {
                    imported: true,
                    color: l.options.fillColor,
                    polylineDistance: distance / 1000
                  }
                });
              }

            });

            for (const geo of geofences) {

              const response = await axios.post(`/api/geofences`, geo);
              console.error(response);

            }

            setState((prevState) => ({
              ...prevState,
              kmlContent: kml,
              kmlType: u
            }))

            return {
              name: e.name,
              size: Math.round(e.size / (1024 * 1000)) + 'MB'
            }
          }
        }
      } else {
        return 'Unsupported file type.'
      }
      return { name: e.name, size: (e.size / 1024).toFixed(2) + 'KB' }
    }
  }

  const prepareRoute = (positions, row, visible, isLastChild, type) => {
    let key = moment(row.startTime).format('YYYY-MM-DD')
    if (positions.length) {
      const newRoute = {
        visible: type === 'single' ? visible : (visible !== undefined ? (state[key] || state.isCheck) : (state.routes[row.startPositionId]?.visible)),
        positions,
        row
      };
  
      setState((prevState) => ({
        ...prevState,
        routes: {
          ...prevState.routes,
          [row.startPositionId]: newRoute 
        },
        routesLoading: {
          ...prevState.routesLoading,
          [row.startPositionId]: false
        }
      }));

      if (isLastChild) {
        downloadTracks()
      }
    } else {
      alert('data not found!')
      setState((prevState) => ({ ...prevState, routesLoading: { ...prevState.routesLoading, [row.startPositionId]: false } }))
    }
  }


  const drawRoute = (row, visible, type) => {
    if (state.routes && !state.routes[row.startPositionId]) {
      let routesLoading = { ...state.routesLoading }
      routesLoading[row.startPositionId] = visible
      setState((prevState) => ({
        ...prevState,
        routesLoading: routesLoading
      }))
      setRouteFetch((prevQueue) => [...prevQueue, { row, visible, type }]);
    } else {
      let updatedRoutes = { ...state.routes }
      updatedRoutes[row.startPositionId].visible = true
      setState((prevState) => ({
        ...prevState,
        routes: updatedRoutes
      }))
    }
  };
  useEffect(() => {
    if (routeFetch?.length > 0) {
      routeFetch.forEach((fetchItem) => {
        fetchRoute(fetchItem.row, prepareRoute, fetchItem.visible, false, fetchItem.type);
      });
      setRouteFetch([]);
    }
  }, [routeFetch]);

  const cancelRoute = (row) => {
    if (row && row.startPositionId) {
      if (state.routes[row.startPositionId]) {
        if (state.routes && state.routes[row.startPositionId]) {
          let updatedroutes = { ...state.routes }
          updatedroutes[row.startPositionId].visible = false
          setState(prevState => { return { ...prevState, routes: updatedroutes } })
        }
      }
    }
  }

  const showDates = (e, date) => {
    // const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    let timezone = 'Asia/Dubai'
    if (
      ServerSetting &&
      ServerSetting.attributes &&
      ServerSetting.attributes.timezone
    ) {
      timezone = ServerSetting.attributes.timezone
    }
    if (
      logInUser &&
      logInUser.attributes &&
      logInUser.attributes.timezone
    ) {
      timezone = logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      // to: adjustedEndDate.toISOString(),

      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }

    let timeFormat = getDateTimeFormat()
    setState((prevState) => ({
      ...prevState,
      selectedDate:
        moment(date.startDate).format(timeFormat) +
        ' - ' +
        moment(date.endDate).format(timeFormat),
      ...formatedDate
    }))
  }

  const selectedNotifications = (notificationId) => {
    var notif = []
    if (notificationId && notificationId.length) {
      notificationId.map(d => {
        notif.push(d.value)
        return null
      })
    }
    setState((prevState) => ({
      ...prevState,
      notificationId: notif
    }))

  }

  const selectedDevices = (deviceId) => {
    setState((prevState) => ({
      ...prevState,
      deviceId: deviceId
    }))
  }

  const selectedGroups = (groupId) => {
    if (groupId && groupId.length) {
      setState((prevState) => ({
        ...prevState,
        groupId: groupId
      }))
    }
  }
  const showListType = (type) => {
    setState((prevState) => ({
      ...prevState,
      listType: type
    }))
  }

  let serverTimeZoneName = 'Asia/Dubai'
  if (
    ServerSetting &&
    ServerSetting.attributes &&
    ServerSetting.attributes.timezone
  ) {
    serverTimeZoneName = ServerSetting.attributes.timezone
  }
  if (
    logInUser &&
    logInUser.attributes &&
    logInUser.attributes.timezone
  ) {
    serverTimeZoneName = logInUser.attributes.timezone
  }

  useEffect(() => {
    let form = {
      to: moment().endOf('day').toISOString(),
      from: moment().startOf('day').toISOString(),
      deviceIds: props.match.params.id || []
      //  ...advanceSettings
    }
    if (props.match.params.id) {
      createReport(form)
    }
  }, [props.match.params.id])

  useEffect(() => {
    if (
      (state.groupId.length || state.deviceId.length) &&
      state.selectedDate
    ) {
      setState((prevState) => ({
        ...prevState,
        validatedForm: true
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        validatedForm: false
      }))
    }
  }, [state.groupId, state.deviceId, state.selectedDate, state.notificationId]);
  return (
    <div className='track-wrapper'>
      {checkPrivileges('track') && (
        <Layout
          {...props}
          {...state}
          validatedForm={state.validatedForm}
          reportsData={state.reportsData}
          deviceId={state.deviceId}
          view={state.view}
          devices={devices}
          mainView={state.mainView}
          trips={state.trips}
          translate={props.translate}
          groups={groups}
          logInUser={logInUser}
          ServerSetting={ServerSetting}
          kmlFileUpload={upload}
          clearSelection={clearSelection}
          downloadAllTracks={downloadAllTracks}
          openForm={openForm}
          pageLoading={pageLoading}
          drawRoute={drawRoute}
          cancelRoute={cancelRoute}
          createReport={createReport}
          advanceSettingsForm={state.advanceSettingsForm}
          advanceSettings={state.advanceSettingsForm.advanceSettings}
          advanceFormChange={advanceFormChange}
          reportTypeSelection={reportTypeSelection}
          showDates={showDates}
          selectedDevices={selectedDevices}
          selectedDate={state.selectedDate}
          selectedNotifications={selectedNotifications}
          selectedGroups={selectedGroups}
          changeCheckbox={changeCheckbox}
          updateColor={updateColor}
          toggleAllGroup={toggleAllGroup}
          toggleDaywiseGroup={toggleDaywiseGroup}
          changeMainView={changeMainView}
        >
          <RouteMap
            themecolors={themecolors}
            serverTimeZoneName={serverTimeZoneName}
            routes={state.routes}
            kmlContent={state.kmlContent}
            showListType={showListType}
            listType={state.listType}
            translate={props.translate}
          />
        </Layout>
      )}
    </div>
  )
}

export default withLocalize(Tracks);
