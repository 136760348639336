import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '../../../Components/common/Button';
import Table from '../../../Components/common/TableMultiCheckbox';
import { checkPrivileges } from '../../../Helpers';

const Notification = (props) => {
  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item sm={props.visableCreateNotification ? 8 : 12} xs={12}>
        <Table
          insideDialog
          createButton={
            checkPrivileges('notificationCreate') && (
              <div style={{ marginLeft: 10 }}>
                <Button size='small' onClick={props.onCreateNewNotification}>
                  {props.translate('Create')}
                </Button>
              </div>
            )
          }
          title={props.translate('sharedNotifications')}
          key='Notifi'
          disableHead
          showCheckbox
          checkHandleChange={props.notification_Selection}
          rows={props.notificationData}
          rowsPerPage={props.rowsPerPage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          isEditable
          canUpdate={checkPrivileges('notificationUpdate')}
          canDelete={checkPrivileges('notificationDelete')}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          themecolors={props.themecolors}
          selectAll={props.selectAll}
          searchable
          canRemove={props.canAssign}
          canAssign={props.canRemove}
          className='custom-table2'
          rowDefinition={[
            {
              id: 'name',
              numeric: false,
              disablePadding: false,
              label: props.translate('sharedName')
            },
            {
              id: 'type',
              numeric: false,
              disablePadding: false,
              label: props.translate('notificationType'),
              translate: true,
              prefix: ''
            },
            {
              id: 'notificators1',
              numeric: false,
              disablePadding: false,
              label: props.translate('notificationNotificatorsMail')
            },
            {
              id: 'notificators2',
              numeric: false,
              disablePadding: false,
              label: props.translate('notificationNotificatorsWeb')
            },
            {
              id: 'notificators3',
              numeric: false,
              disablePadding: false,
              label: props.translate('notificationNotificatorsFirebase')
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Notification;
