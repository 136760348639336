import React, { useState } from 'react'
import { themes } from '../../Reducers/Theme'
import Style from 'style-it';
import LoginFormFunc from './LoginComponent';
import ForgotFormFunc from './ForgotComponent';


// const mapState = () => ({
//     themecolors: themes[2],
//   })
const Login = (props) => {
  const [view, setView] = useState("login")
  const themecolors = themes[2]

  const switchView = (view) => {
    setView(view)
  }
  return (<React.Fragment>
    <Style>
      {`
        a {
          color: ${themecolors.textColor}
        }
        .loginWrapperInner {
          background: ${themecolors.backgroundColor};
        }
        .loginLoaderWrapper {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
        .login-footer {
          border-top: 1px solid ${themecolors && themecolors.themeLightColor};
        }
        `}

    </Style>
    <div className='loginWrapper'>
      <div className='loginWrapperInner'>
        {view === 'login' ? (
          <LoginFormFunc {...props} switchView={switchView} />
        ) : null}
        {view === 'forgot' ? (
          <ForgotFormFunc {...props} switchView={switchView} />
        ) : null}
      </div>
      <div
        className='white-label-bg'
        style={{ backgroundImage: `url("${props?.whiteLabling.logInPageImage}")` }}
      ></div>
    </div>
  </React.Fragment>
  )
}

export default Login;