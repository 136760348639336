export const templates = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'GET_TEMPLATES':
      return action.templates

    case 'UPDATE_TEMPLATES':
      var getUpdatedData = state.map((content) => {
        var group = action.templates.map((d) => {
          if (content['id'] === d['id']) {
            return { ...content, d }
          } else {
            return null
          }
        })

        return group[0] ? group[0] : content
      })

      return getUpdatedData.length ? getUpdatedData : [...action.templates]

    case 'ADD_TEMPLATE':
      return state.concat(action.template)

    case 'UPDATE_TEMPLATE':
      const grp = state.map(gr => {
        if (gr.rpTmpl.id === action.template.rpTmpl.id) {
          return action.template
        } else {
          return gr
        }
      })

      return grp

    case 'REMOVE_TEMPLATE':
      const templates = state.filter(gr => gr.rpTmpl.id !== action.template.id)
      return templates

    default:
      return state
  }
}


export function selectedtemplates (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'TEMPLATE_USER_WISE_SELECTED_LIST':
      return action.template
    case 'UPDATE_TEMPLATE_USER_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.template.id) {
          return m
        } else {
          return action.template
        }
      })
      return updatedata

    case 'ADD_TEMPLATE_USER_WISE_SELECTED_LIST':
      return state.concat(action.template)
    case 'REMOVED_TEMPLATE_USER_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.template.id)
      return rData
    default:
      return state
  }
}