import React, { useState } from 'react';
import TextField from './../../Components/common/TextFieldLg';
import Button from './../../Components/common/Button';
import Loader from './../../Layout/Loader';
import { withLocalize } from 'react-localize-redux';
// import { connect } from 'react-redux';
import Style from 'style-it';
import { themes } from '../../Reducers/Theme';
import { Notifications } from 'react-notification-system-redux';
import instance from '../../axios';
import { errorHandler } from '../../Helpers';
import { NavLink } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import ReplayIcon from "@mui/icons-material/Replay";
// import InfoIcon from './../../assets/monitoring/info.svg'


const generateCaptcha = () => {
    let allCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
        'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd',
        'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
        't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    let generatedCaptcha = Array.from({ length: 6 }, () => {
        let randomCharacter = allCharacters[Math.floor(Math.random() * allCharacters.length)];
        return randomCharacter;
    }).join(''); // concatenate characters without spaces

    return generatedCaptcha;
}





const ForgotComponent = (props) => {
    const [email, setEmail] = useState("");
    const [userInput, setUserInput] = useState('')
    // const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState(generateCaptcha());
    const [isVisableUserBtn, setIsVisableUserBtn] = useState(false)
    const themecolors=themes[2]


    const handleRegenerateCaptcha = () => {
        setCaptcha(generateCaptcha())
        setUserInput("")
        setIsVisableUserBtn(false)
    }

    const handleForgotPassword = (event) => {
        event.preventDefault()
        setLoading(true)
        // setErrorMessage("")
        instance({
            url: `/api/password/forget`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            },
            data: (`email= ${email}`)
        })
            .then(res => { // eslint-disable-line no-unused-vars
                setLoading(false)
                // setErrorMessage(props.translate("emailSentSuccessfully"))
                props.dispatch(
                    Notifications.success({
                        message: props.translate('emailSentSuccessfully'),
                        autoDismiss: 10
                    })
                )
            }).catch(error => { errorHandler(error, props.dispatch, errorCallBack, true) })

    }

    const errorCallBack = (data) => {
        if (data.statusCode === "401") {
            setLoading(false)
            // setErrorMessage(props.translate("notEnoughPrivileges"))
        } else {
            setLoading(false)
            // setErrorMessage(props.translate("invalidEmailAddress"))
        }
    }

    const checkRequiredFields = () => {
        if (email) {
            if (process.env.REACT_APP_CAPTCHA_ENABLE === true) {
                if (
                    userInput &&
                    captcha === userInput
                ) {
                    setIsVisableUserBtn(true)
                } else {
                    setIsVisableUserBtn(false)
                }
            }
            else {
                setIsVisableUserBtn(true)
            }
        }
        else {
            setIsVisableUserBtn(false)
        }
    }

    const handleCaptchaChange = (event) => {
        setUserInput(event.target.value)
        checkRequiredFields()
    }

    const handleChange = (event) => {
        const { id, value } = event.target;
        if (id === 'email') setEmail(value);
    }


    if (loading) {
        return (
            <div className='loginLoaderWrapper'>
                <Loader />
            </div>
        );
    } else {
        return (
            <form className='loginForm' onSubmit={handleForgotPassword} autoComplete='off'>
                <Style>
                    {`
            a {
              color: ${themecolors?.textColor}
            }
            .loginWrapperInner {
              background: ${themecolors?.backgroundColor}
            }
            .login-footer {
              border-top: 1px solid ${themecolors && themecolors?.themeLightColor};
            }
            `}
                </Style>
                <div className='login-logo-wrapper'>
                    <img className='login-logo' src={props.whiteLabling.logo} alt='logo' />
                </div>
                <p
                    style={{
                        marginTop: 0,
                        marginBottom: 30,
                        fontSize: 12,
                        color: themecolors?.textColor
                    }}
                >
                    {props?.translate('resetPasswordMessage')}
                </p>
                {/* {errorMessage && (
                    <div
                        className='alert alert-danger'
                        style={{
                            color:
                                errorMessage === 'Email sent successfully'
                                    ? themecolors['success']
                                    : themecolors['error'],
                            background: themecolors.textColor
                        }}
                    >
                        <svg fill='currentColor' width={16} height={16}>
                            <use xlinkHref={`${InfoIcon}#icon`} />
                        </svg>
                        {errorMessage}
                    </div>)} */}
                <TextField
                    themecolors={themecolors}
                    id='email'
                    variant='outlined'
                    label={props?.translate('userEmail') + ' *'}
                    type='text'
                    value={email || ""}
                    onChange={handleChange}
                    margin='dense'
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {process.env.REACT_APP_CAPTCHA_ENABLE === true && <>
                    <p style={{ margin: "10px 0px 0px 0px", fontSize: 12, color: themecolors?.textColor }}>Captcha Code:</p>
                    <div
                        className='form-group'
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                        <p
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "28px",
                                padding: "2px  5px",
                                fontFamily: "Henny Penny , Bradley Hand, cursive",
                                backgroundColor: "grey",
                                borderRadius: "2px",
                                color: "dimgray",
                                width: "85%",
                                textDecoration: "line-through",
                                minHeight: "42px",
                                userSelect: "none",

                            }}
                        >{captcha || ""}</p>
                        <p style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "28px",
                            padding: "2px  5px",
                            backgroundColor: themecolors.themeListingColor,
                            borderRadius: "2px",
                            width: "14%",
                            alignItems: "center",

                            minHeight: "42px"
                        }}>
                            <Tooltip title="Regenerate Captcha" style={{ color: themecolors.textColor }}>
                                <ReplayIcon onClick={handleRegenerateCaptcha} className="actionButton" />
                            </Tooltip>
                        </p>
                    </div>
                    <div className='form-group'>

                        <TextField
                            id='captcha'
                            label='Enter the CAPTCHA below:'
                            type='text'
                            value={userInput || ''}
                            onChange={handleCaptchaChange}
                            margin='dense'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                style: {
                                    borderColor: userInput === captcha ? 'green' : 'red',
                                },
                                classes: {
                                    root: 'theme-cssOutlinedInput',
                                    error: 'theme-input-error',
                                    input: 'theme-input',
                                    focused: 'theme-cssFocused',
                                    notchedOutline: 'theme-notchedOutline',
                                },
                            }}
                        />
                    </div>
                </>}

                <p style={{ textAlign: 'center', marginTop: 0 }}>
                    <Button
                        // disabled={!this.state.isVisableUserBtn} 
                        disabled={process.env.REACT_APP_CAPTCHA_ENABLE === true ? !isVisableUserBtn : false}
                        themecolors={themecolors} type='submit' className='btn-submit'>
                        {props?.translate('submit')}
                    </Button>
                </p>
                <p style={{ textAlign: 'center' }}>
                    <NavLink
                        to={'/login'}
                        onClick={() => props?.switchView('login')}
                    >
                        {props?.translate('loginTitle')}
                    </NavLink>
                </p>

                <div className='login-footer'>
                    <a
                        href={
                            props?.whiteLabling && props?.whiteLabling.termsLink
                        }
                    >
                        {props?.translate('about')}
                    </a>
                    <a
                        href={
                            props?.whiteLabling && props?.whiteLabling.termsLink
                        }
                    >
                        {props?.translate('termsAndConditions')}
                    </a>
                    <a
                        href={
                            props?.whiteLabling &&
                            props?.whiteLabling.privacyPolicyLink
                        }
                    >
                        {props?.translate('privacy')}
                    </a>
                </div>
            </form>
        );
    }
};

export default withLocalize(ForgotComponent);
