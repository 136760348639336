import React from 'react';
import SimpleModalWrapped from '../common/Modal';
import Grid from '@mui/material/Grid';
import TextField from '../common/TextField';
import { Translate, withLocalize } from 'react-localize-redux';

const AddRecourcesGroup = (props) => {
  React.useEffect(() => {

    return () => {

    };
  }, []);

  return (
    <div style={{ height: '100%', flex: 1 }}>
      <SimpleModalWrapped
        visable={true}
        notShowAttributesBtn
        isButtonVisable={true}
        title={props.title || 'sharedName'}
        formSubmit={props.formSubmit}
        modalControle={props.onClose}
        buttonText={props.form && props.form.id ? 'update' : 'sharedCreate'}
        btnCloseText='sharedBack'
        isVisableBtn={props.isVisableUserBtn}
        selectedItemId={props.selectedVehicle}
        content={
          <form autoComplete='off'>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  id='name'
                  label={<Translate id="sharedName"/>}
                  required
                  placeholder=''
                  value={(props.form && props.form.name) || ''}
                  onChange={e => props.handleChange('name', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  id='description'
                  label={<Translate id="sharedDescription"/>}
                  placeholder=''
                  value={(props.form && props.form.description) || ''}
                  onChange={e => props.handleChange('description', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              {/* <Grid item md={4} sm={6} xs={12}>
                <TextField
                  id='itemType'
                  label={'Item Type'}
                  placeholder=''
                  value={(props.form && props.form.itemType) || ''}
                  onChange={e => props.handleChange('itemType', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid> */}
            </Grid>
          </form>
        }
      />
    </div>
  );
};

export default withLocalize(AddRecourcesGroup);
