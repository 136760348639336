import React, { useState,useEffect } from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Button from './../common/Button'
// import isEqual from 'react-fast-compare'
import Panel from './../common/Panel'
import { filterGreaterThan2, ReactBaseTable, SliderColumnFilter2 } from '../common/ReactBaseTable'
import Dialog from '../common/Dialog';
import { FormControlLabel, Grid } from '@mui/material';
import Switch from '../common/Switch';

const DetailView = (props) => {

  const [state,setState]=useState({
    eventsDialog: false,
    loading: false
  })


 const handleClick = (event) => {
    event.stopPropagation()
    setState(prevState=>({...prevState, eventsDialog: true }))
  }

 const handleChange = (event, name) => {
    props.handleChange(event, name)
  }

 const onCloseModal = () => {
  setState(prevState=>({...prevState,  eventsDialog: false }))
  }

  // componentDidUpdate () {
  //   this.props.onRender()
  // }

  useEffect(()=>{
    props.onRender()
  },[props.onRender])

//  const setColumns = (columns) => {
//   setState(prevState=>({...prevState, 
//       columns
//     }))
//   }

  // shouldComponentUpdate (nextProps, nextState) {
  //   return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  // }

 const renderRowSubComponent =  ({ row }) => <ReactBaseTable id={"childTable_"+row.id} columns={props.columns} hiddenColumns={props.hiddenColumns} data={row.original.rows} total={row.original.total} />


    const { table/* , disableFilter, toggleFilter */ } = props
    const { eventsDialog } = state;
    return (
      <div>
        <Panel
          title={props.title}
          bodyPadding={0}
          open={true}
          headerActions={
            <>
              {/* <Button
              size='small'
              color='inherit'
              style={{marginLeft: 'auto' }}
              onClick={e => toggleFilter(e)}
              >
                {disableFilter ? 'Show Filter' : 'Hide Filter'}
                
              </Button> |  */}
              <Button
                size='small'
                color='inherit'
                style={{marginLeft: 'auto' }}
                onClick={e => handleClick(e, 'tripsDialog')}
              >
                Show/Hide Columns
              </Button>
            </>
          }
        >

         {table && table.data  && ( <ReactBaseTable
            id="parentTable"
            disableFooter
            columns={[
              {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                  <span {...getToggleAllRowsExpandedProps()}>
                    {isAllRowsExpanded ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                  </span>
                ),
                Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                  </span>,
                width: 35
              },
              { Header: '', accessor: 'head', width: '100%', filter: filterGreaterThan2, Filter: SliderColumnFilter2 }
              ]}
            data={ table && table.data }
            renderRowSubComponent={renderRowSubComponent}
          />)}
        </Panel>

        {eventsDialog && (
          <Dialog
            key='eventsDialog'
            visable={true}
            title='Show/Hide Columns'
            onClose={e => onCloseModal(e)}
            isButtonVisable={true}
            draggable={true}
            cancelText='Close'
            bodyPadding={20}
          >
            <Grid container direction='row'>
              {Object.keys(props.dialogColumns).map(k => (
                <Grid item lg={6} key={k}>
                  <FormControlLabel
                    key={k}
                    classes={{ label: 'form-control-label' }}
                    control={
                      <Switch
                        key={k}
                        checked={props.dialogColumns[k].checked}
                        onChange={e => handleChange(e, k)}
                        value={k}
                      />
                    }
                    label={props.dialogColumns[k].label}
                  />
                </Grid>
              ))}
            </Grid>
          </Dialog>
        )}

      </div>
    )
  
}

export default DetailView;
