import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@mui/material';
import Typography from '@mui/material/Typography'
// import { makeStyles } from '@mui/styles'
import { UsersModal } from './../../Components/Users/usersModal'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import {
  checkPrivileges,
  errorHandler,
  postionAttributesTypeComp,
  selectAll
} from '../../Helpers'
import isEqual from 'react-fast-compare'
import Notifications from 'react-notification-system-redux'
import ComputedAttributes from './Components/computedAttributes'
import ReactHtmlParser from 'react-html-parser'

/* ACTIONS */
import { addGeoFence, deleteGeofence, fetchDashboards } from '../../Actions/Devices'
import {
  // getDriverDeviceWise,
  // getTrailerDeviceWise,
  // getDashboardDeviceWise,
  // getVehicleDeviceWise,
  addDriverDeviceWiseSelectedList,
  addTrailerDeviceWiseSelectedList,
  addDashboardDeviceWiseSelectedList,
  addWidgetDeviceWiseSelectedList,
  addVehicleDeviceWiseSelectedList,
  driverDeviceWiseSelectedList,
  trailerDeviceWiseSelectedList,
  dashboardDeviceWiseSelectedList,
  widgetDeviceWiseSelectedList,
  vehicleDeviceWiseSelectedList,
  RemoveDriverDeviceWise,
  RemoveTrailerDeviceWise,
  // RemoveDashboardDeviceWise,
  RemoveVehicleDeviceWise,
  updateDriverDeviceWiseSelectedList,
  updateTrailerDeviceWiseSelectedList,
  updateDashboardDeviceWiseSelectedList,
  updateWidgetDeviceWiseSelectedList,
  updateVehicleDeviceWiseSelectedList,
  addDriverDeviceWise,
  addTrailerDeviceWise,
  // addDashboardDeviceWise,
  addVehicleDeviceWise,
  // addNotification,
  removeDriverDeviceWiseSelectedList,
  removeTrailerDeviceWiseSelectedList,
  removeDashboardDeviceWiseSelectedList,
  removeWidgetDeviceWiseSelectedList,
  removeVehicleDeviceWiseSelectedList,
  getUserUsereWise,
  updateUserDeviceWiseSelectedList,
  addUserDeviceWiseSelectedList,
  addGeofenceDeviceWiseSelectedList,
  removeGeofenceDeviceWiseSelectedList,
  addNotificationDeviceWise,
  RemoveNotificationDeviceWise,
  notificationDeviceWiseSelectedList,
  getNotificationDeviceWise,
  removedNotification,
  removeNotificationDeviceWiseSelectedList,
  addNotificationDeviceWiseSelectedList,
  updateNotificationDeviceWiseSelectedList,
  // getGeofenceDeviceWise,
  geofenceDeviceWiseSelectedList,
  updateGeofenceDeviceWiseSelectedList,
  RemoveGeofenceDeviceWise,
  addGeofenceDeviceWise,
  updateGroupsDeviceWiseSelectedList,
  RemoveGroupsDeviceWise,
  addGroupsDeviceWise,
  removeGroupDeviceWiseSelectedList,
  groupDeviceWiseSelectedList,
  addDeviceUserWise,
  RemoveDeviceUserWise,
  templateUserWiseSelectedList,
  deviceUserWiseSelectedList,
  updateDeviceUserWiseSelectedList,
  removeDeviceUserWiseSelectedList,
  // addDeviceUserWiseSelectedList,
  removeUserUsereWise,
  updateTemplateUserWiseSelectedList
} from '../../Actions/Notifications'
import {
  addComputedAttribute,
  computedAttributeDeviceWiseSelectedList,
  // getComputedAttributeDeviceWise,
  addComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise,
  removedComputedAttribute,
  removedComputedAttributeDeviceWiseSelectedList,
  addComputedAttributeDeviceWiseSelectedList,
  updateComputedAttributeDeviceWiseSelectedList,
  getComputedAttributes
} from '../../Actions/ComputedAttributes'
import {
  addCommands,
  // getCommandsDeviceWise,
  commandsDeviceWiseSelectedList,
  addCommandsDeviceWise,
  RemoveCommandsDeviceWise,
  removedCommands,
  addCommandsDeviceWiseSelectedList,
  updateCommandsDeviceWiseSelectedList,
  removedCommandsDeviceWiseSelectedList,
  getCommands
} from '../../Actions/Commands'
import {
  addMaintenance,
  addMaintenanceDeviceWise,
  RemoveMaintenanceDeviceWise,
  getMaintenanceDeviceWise,
  maintenanceDeviceWiseSelectedList,
  removedMaintenance,
  addMaintenanceDeviceWiseSelectedList,
  updateMaintenanceDeviceWiseSelectedList,
  removedMaintenanceDeviceWiseSelectedList,
  getMaintenance
} from '../../Actions/Maintenance'
import { removeDevice } from './../../Actions/Devices'
import { removeGroup } from './../../Actions/Groups'
import { garageUsersWiseSelectedList, updategarageUsersWiseSelectedList, removedGarages, getGarages } from './../../Actions/Garages'
import { areaUsersWiseSelectedList, updateAreaUsersWiseSelectedList } from './../../Actions/Areas'
import { addDriver, removeDriver } from './../../Actions/Drivers'
import {
  addTrailers,
  removeTrailer
} from './../../Actions/Trailer'
import { addvehicle, removedvehicle, getVehicles } from './../../Actions/Vehicles'
/* ACTIONS END */

// common sections
import Notification from './Components/notification'
import CommonTableSection from './Components/commonTableSection'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import SavedCommands from './Components/savedCommands'
import MaintenanceForm from './Components/maintenance'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from '../../Components/common/Button'
import instance from '../../axios'
import { serviceUsersWiseSelectedList, updateServiceUsersWiseSelectedList } from '../../Actions/Services'
// const styles = theme => ({
//   root: {
//     [theme.breakpoints&&theme.breakpoints.up&&theme.breakpoints.up('md')]: {
//       display: 'flex',
//       flexGrow: 1
//     }
//   },
//   tabs: {
//     [theme.breakpoints&&theme.breakpoints.up&&theme.breakpoints.up('md')]: {
//       borderRight: `1px solid ${theme.palette&&theme.palette.divider&&theme.palette.divider}`
//     }
//   },
//   scrollButtons: {
//     color: '#333'
//   },
//   indicator: {
//     backgroundColor: '#333'
//   }
// })

// const VerticalTabs =  (theme => ({
//   flexContainer: {
//     [theme.breakpoints&&theme.breakpoints.up&&theme.breakpoints.up('md')]: {
//       paddingBottom: 48,
//       flexDirection: 'column',
//       paddingTop: 10
//     }
//   },
//   root: {
//     textAlign: 'center',
//     [theme.breakpoints&&theme.breakpoints.up&&theme.breakpoints.up('md')]: {
//       minWidth: 250,
//       borderRight: `1px solid ${theme.palette&&theme.palette.divider&&theme.palette.divider}`
//     }
//   },
//   indicator: {
//     display: 'none'
//   },
//   scrollable: {
//     [theme.breakpoints&&theme.breakpoints.up&&theme.breakpoints.up('sm')]: {
//       overflow: 'auto'
//     }
//   }
// }))(Tabs)

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ color: 'inherit', ...other.style }}
    >
      {children}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

// --> parking form

const unitParkingAttFormat = {
  'parking.minIdleTime': 0,
  'parking.maxIdleSpeed': 0
}

const NotificationDataFormat = {
  always: '',
  attributes: {},
  calendarId: '',
  id: '',
  notificators: '',
  type: ''
}

const ComputedAttributesDataFormat = {
  description: '',
  attribute: '',
  expression: '',
  type: ''
}
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: '',
  id: '',
  description: '',
  type: 'custom',
  textChannel: ''
}
const maintenanceDataFormat = {
  attributes: {},
  name: '',
  type: '',
  start: '',
  period: ''
}

const locations = [
  '#user',
  '#changePassword',
  '#dashboard',
  '#widget',
  '#device',
  '#group',
  '#driver',
  '#binder',
  '#template',
  '#vehicle',
  '#garage',
  '#area',
  '#users',
  '#geofence',
  '#notification',
  '#attribute',
  '#command',
  '#maintenance'
]
// class MainUnitModal extends React.Component {
const MainUnitModal = (props) => {


  const [state, setState] = useState({
    viewScreen: null,
    currentTab: 'user',
    currentId: 0,
    selecteditem: null,
    activeOperation: '',
    unitParkingAttributesform: { ...unitParkingAttFormat },
    notificationForm: { ...NotificationDataFormat },
    computedAttForm: { ...ComputedAttributesDataFormat },
    savedCommandsForm: { ...savedCommandsDataFormat },
    maintenanceForm: { ...maintenanceDataFormat },

    visableAttribute: false,
    visableNotification: false,
    visableSavedCommands: false,
    visableMaintenance: false,
    visableGeofence: false,

    visableListNotification: true,
    visableListGeofence: true,
    visableListAttribute: true,
    visableListSavedCommands: true,
    visableListMaintenance: true,

    notificationCallStatus: '',
    attributeCallStatus: '',
    driverCallStatus:"",
    userCallStatus:"",
    commandsCallStatus: '',
    maintenanceCallStatus: '',
    rowsPerPage: 5,
    onDeleteConfirmationGarage: false,
    onDeleteConfirmationGroup: false,
    onDeleteConfirmationTrailer: false,
    onDeleteConfirmationVehicle: false,
    onDeleteConfirmationGeofence: false,
    onDeleteConfirmationCommand: false,
    onDeleteConfirmationDashboard: false,
    onDeleteConfirmationAttributes:false,
    onDeleteConfirmationDriver:false,
    onDeleteConfirmationMaintenance:false,
    allItemGroups: [],
    selectedItemGroups: []
  })

  const [isLoading,setIsLoading]=useState(false)


  useEffect(() => {
    if (props.id) {
      setState(prevState => ({ ...prevState, viewScreen: 'edit' }));

      if (props.hash) {
        let hash = locations.indexOf(props.hash);
        if (hash !== -1) {
          setState(prevState => ({ ...prevState, currentTab: props.hash.substring(1) }));
        }
      }

      if (props.id && props.users.length) {
        const currentId = parseInt(props.id);
        setState(prevState => ({ ...prevState, currentId }));

        // getCurrentUser(currentId);
        fetchAllVehicles(props.logInUser.id);

        const selecteditem = props.users.find(user => parseInt(user.id) === currentId);
        if (selecteditem) {
          setState(prevState => ({ ...prevState, selecteditem }));
        }
      }
    }

    if (props.addUserScreen) {
      setState(prevState => ({ ...prevState, viewScreen: 'add' }));
    }

    fetchItemGroups();
  }, []); // Empty dependency array means this useEffect runs only once after the initial render

  // Handle changes to props and state
  useEffect(() => {
    if (props.id) {
      setState(prevState => ({ ...prevState, viewScreen: 'edit' }));

      if (props.hash) {
        let hash = locations.indexOf(props.hash);
        if (hash !== -1) {
          setState(prevState => ({ ...prevState, currentTab: props.hash.substring(1) }));
        }
      }

      if (props.id && props.users.length) {
        const currentId = parseInt(props.id);
        if (currentId !== state.currentId) {
          setState(prevState => ({ ...prevState, currentId }));

          getCurrentUser(currentId);
          fetchAllVehicles(props.logInUser.id);

          const selecteditem = props.users.find(user => parseInt(user.id) === currentId);
          if (selecteditem) {
            setState(prevState => ({ ...prevState, selecteditem }));
          }
        }
      }
    }

    if (props.addUserScreen) {
      setState(prevState => ({ ...prevState, viewScreen: 'add' }));
    }

    // fetchItemGroups();
  }, [
    props.id,
    props.hash,
    state.currentId,
    // props.users.length,
    props.addUserScreen
  ]);

  useEffect(() => {
    if (state.groupCallStatus === "DELETE") {
      addGroup()
    }
  }, [state.groupCallStatus])

  useEffect(() => {
    if (state.trailerCallStatus === "DELETE") {
      addTrailer()
    }
  }, [state.trailerCallStatus])

  useEffect(() => {
    if (state.vehicleCallStatus === "DELETE") {
      addVehicle()
    }
  }, [state.vehicleCallStatus])

  useEffect(() => {
    if (state.garageCallStatus === "DELETE") {
      deleteGarages()
    }
  }, [state.garageCallStatus])

  useEffect(() => {
    if (state.geofenceCallStatus === "DELETE") {
      addGeofence()
    }
  }, [state.geofenceCallStatus])

  useEffect(() => {
    if (state.commandsCallStatus === "DELETE") {
      addSavedCommands()
    }
  }, [state.commandsCallStatus])

  useEffect(() => {
    if (state.dashboardCallStatus === "DELETE") {
      addDashboard()
    }
  }, [state.dashboardCallStatus])

  useEffect(() => {
    if (state.savedCommandsForm) {
      checkRequiredFields('Commands')
    }
  }, [state.savedCommandsForm])

  useEffect(() => {
    if (state.computedAttForm) {
      checkRequiredFields('computedAtt')
    }
  }, [state.computedAttForm])

  useEffect(() => {
    if (state.attributeCallStatus === "DELETE") {
      addComputedAttributes()
    }
  }, [state.attributeCallStatus])

  useEffect(() => {
    if (state.driverCallStatus === "DELETE") {
      addDrivers()
    }
  }, [state.driverCallStatus])

  useEffect(() => {
    if (state.userCallStatus === "DELETE") {
      addUser()
    }
  }, [state.userCallStatus])

  useEffect(() => {
    if (state.maintenanceCallStatus === "DELETE") {
      addMaintenances()
    }
  }, [state.maintenanceCallStatus])


  useEffect(() => {
    if (state.notificationForm) {
      checkRequiredFields('notification')
    }
    else if (state.maintenanceForm) {
      checkRequiredFields('maintenance')
    }
    else if (state.notificationCallStatus) {
      addNotification()
    }
    // else if (state.trailerCallStatus) {
    //   addTrailer()
    // }


    else if (state.widgetCallStatus) {
      addWidget()
    }

  


  }, [
    state.notificationForm,
    state.maintenanceForm,
    state.notificationCallStatus,
    state.dashboardCallStatus, state.widgetCallStatus,
    state.maintenanceCallStatus,

    state.commandsCallStatus,
  ]);


  // componentWillMount () {

  //   if (props.id) {
  //     setState((prevState)=>({...prevState, viewScreen: 'edit' })

  //     if (props.hash) {
  //       let hash = locations.indexOf(props.hash)
  //       if (hash !== -1) {
  //         setState((prevState)=>({...prevState, currentTab: props.hash.substring(1, props.hash.length) })
  //       }
  //     }

  //     if (
  //       props.id &&
  //       parseInt(state.currentId) !== parseInt(props.id) &&
  //       props.users.length
  //     ) {
  //       setState(
  //         {
  //           currentId: parseInt(props.id)
  //         },
  //         () => {
  //           getCurrentUser(props.id)
  //           fetchAllVehicles(props.logInUser.id)

  //           props.users.map(user => {
  //             if (parseInt(user.id) === parseInt(state.currentId)) {
  //               setState((prevState)=>({...prevState, selecteditem: user })
  //             }
  //             return null
  //           })
  //          }
  //       )
  //     }
  //   }

  //   if (props.addUserScreen) {
  //     setState((prevState)=>({...prevState, viewScreen: 'add' })
  //   }
  //   fetchItemGroups()
  // }

  // change tab






  // componentWillReceiveProps (n) {
  //   if (n.id) {
  //     setState((prevState)=>({...prevState, viewScreen: 'edit' })

  //     if (n.hash !== props.hash) {
  //       let hash = locations.indexOf(n.hash)
  //       if (hash !== -1) {
  //         setState((prevState)=>({...prevState, currentTab: n.hash.substring(1, n.hash.length) })
  //       }
  //     }

  //     if (
  //       n.id &&
  //       parseInt(state.currentId) !== parseInt(n.id) &&
  //       n.users.length
  //     ) {
  //       setState(
  //         {
  //           currentId: parseInt(n.id)
  //         },
  //         () => {
  //           getCurrentUser(n.id)
  //           fetchAllVehicles(n.logInUser.id)
  //           n.users.map(user => {
  //             if (parseInt(user.id) === parseInt(state.currentId)) {
  //               setState((prevState)=>({...prevState, selecteditem: user })
  //             }
  //             return null
  //           })
  //           fetchItemGroups()
  //         }
  //       )
  //     }
  //   }

  //   if (n.addUserScreen) {
  //     setState((prevState)=>({...prevState, viewScreen: 'add' })
  //   }
  // }


  const tabChange = (event, currentTab) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: 5,
      currentTab: currentTab
    }));
    props.history.push('/users/' + props.id + '#' + currentTab);
  };

  const fetchItemGroups = () => {
    if (props.logInUser && props.logInUser.id) {
      instance({
        method: 'GET',
        url: `/api/itemgroups/get?itemType=Device&userId=${props.logInUser.id}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        setState((prevState) => ({
          ...prevState,
          allItemGroups: response
        }));
      }).catch(err => { console.error('error===', err) });
    }
    if (props.id) {
      instance({
        method: 'GET',
        url: `/api/itemgroups/get?itemType=Device&userId=${props.id}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        setState((prevState) => ({
          ...prevState,
          selectedItemGroups: response
        }));
        state.allItemGroups.data && state.allItemGroups.data.map(item => {
          const linkedItemGroups = response.data.find(userId => item.id === userId.id);
          if (linkedItemGroups) {
            item.check = true;
          } else {
            item.check = false;
          }
        });
      }).catch(err => { console.error('error===', err) });
    }
  };

  const onCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      activeOperation: ''
    }));
  };
  // addNewGroups = () => {
  //   setState((prevState)=>({...prevState,
  //     isaddGroupVisable: !state.isaddGroupVisable
  //   })
  // }

  // logo_handelChanges (item) {
  //   setState(
  //     {
  //       aOneform: {
  //         category: item.name
  //       }
  //     },
  //     () => {
  //       isButtonVisable('aFormOne')
  //     }
  //   )
  // }

  // Common

  // 1st

  const onListNotification = () => {
    setState((prevState) => ({
      ...prevState,
      visableListNotification: true
    }));
  };

  const onListComputedAttributes = () => {
    setState((prevState) => ({
      ...prevState,
      visableListAttribute: true
    }));
  };

  const onListSavedCommands = () => {
    setState((prevState) => ({
      ...prevState,
      visableListSavedCommands: true
    }));
  };

  const onListMaintenance = () => {
    setState((prevState) => ({
      ...prevState,
      visableListMaintenance: true
    }));
  };

  const onCreateNewNotification = () => {
    props.history.push('/notificationCreate');
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      notificationCallStatus: 'POST',
      visableNotification: true,
      notificationForm: { ...NotificationDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'notificationCreate'
    }));
  };

  const onCreateNewAttributes = () => {
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      attributeCallStatus: 'POST',
      visableAttribute: true,
      computedAttForm: { ...ComputedAttributesDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'attributeCreate'
    }));
  };


  const onCreateNewSavedCommands = () => {
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      commandsCallStatus: 'POST',
      visableSavedCommands: true,
      savedCommandsForm: { ...savedCommandsDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'commandCreate'
    }));
  };



  const onCreateNewMaintenance = () => {
    props.history.push('/maintenanceCreate');
  };

  const onCreateNewDashboard = () => {
    props.history.push('/dashboardBuilder');
  };

  // 3rd
  const notification_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...props.selectednotification];
    let obj = {
      userId: props.id,
      notificationId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        if (response) {
          if (method === 'DELETE') {
            item.check = false;
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = false;
              }
              return null;
            });
            props.dispatch(updateNotificationDeviceWiseSelectedList(item));
            props.dispatch(RemoveNotificationDeviceWise(item));
            props.dispatch(Notifications.success({
              message: props.translate('notificationUnAssignedSuccessfully'),
              autoDismiss: 10
            }));
          } else {
            item.check = true;
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = true;
              }
              return null;
            });
            props.dispatch(updateNotificationDeviceWiseSelectedList(item));
            props.dispatch(addNotificationDeviceWise(item));
            props.dispatch(Notifications.success({
              message: props.translate('notificationAssignedSuccessfully'),
              autoDismiss: 10
            }));
          }
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };

  const geofence_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...props.selectedgeofence];
    let obj = {
      userId: props.id,
      geofenceId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        if (response) {
          if (method === 'DELETE') {
            item.check = false;
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = false;
              }
              return null;
            });
            props.dispatch(updateGeofenceDeviceWiseSelectedList(item));
            props.dispatch(RemoveGeofenceDeviceWise(item));
            props.dispatch(Notifications.success({
              message: props.translate('geofenceUnAssignedSuccessfully'),
              autoDismiss: 10
            }));
          } else {
            item.check = true;
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = true;
              }
              return null;
            });
            props.dispatch(updateGeofenceDeviceWiseSelectedList(item));
            props.dispatch(addGeofenceDeviceWise(item));
            props.dispatch(Notifications.success({
              message: props.translate('geofenceAssignedSuccessfully'),
              autoDismiss: 10
            }));
          }
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };



  // ItemGroupCB = (res,type)=>{
  //    let allData = state.allItemGroups&&state.allItemGroups.data || []

  //   if (type) {
  //      allData.map(dt => {
  //         dt.check = true
  //      })
  //     setState((prevState)=>({...prevState,
  //       allItemGroups:{
  //         ...state.allItemGroups,
  //         data:allData

  //       }
  //     })
  //        props.dispatch(
  //       Notifications.success({
  //         message: props.translate('allItemGroupAssignedSuccessfully'),
  //         autoDismiss: 10
  //       })
  //     )
  //     } else {
  //        allData.map(dt => {
  //            dt.check = false
  //       })
  //       setState((prevState)=>({...prevState,
  //         allItemGroups:{
  //           ...state.allItemGroups,
  //           data:allData
  //         }
  //       })
  //        props.dispatch(
  //         Notifications.success({
  //           message: props.translate('allItemGroupUnAssignedSuccessfully'),
  //           autoDismiss: 10
  //         })
  //       )
  //   }

  //   }

  const group_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...props.selectedgroups];
    let obj = {
      userId: props.id,
      groupId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        if (response) {
          if (method === 'DELETE') {
            item.check = false;
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = false;
              }
              return null;
            });
            props.dispatch(updateGroupsDeviceWiseSelectedList(item));
            props.dispatch(RemoveGroupsDeviceWise(item));
            props.dispatch(Notifications.success({
              message: props.translate('groupUnAssignedSuccessfully'),
              autoDismiss: 10
            }));
          } else {
            item.check = true;
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = true;
              }
              return null;
            });
            props.dispatch(updateGroupsDeviceWiseSelectedList(item));
            props.dispatch(addGroupsDeviceWise(item));
            props.dispatch(Notifications.success({
              message: props.translate('groupAssignedSuccessfully'),
              autoDismiss: 10
            }));
          }
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };

  const unit_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...props.selecteddevices];
    let obj = {
      userId: props.id,
      deviceId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        if (response) {
          if (method === 'DELETE') {
            item.check = false;
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = false;
              }
              return null;
            });
            props.dispatch(updateDeviceUserWiseSelectedList(item));
            props.dispatch(RemoveDeviceUserWise(item));
            props.dispatch(Notifications.success({
              message: props.translate('trackerIsUnAssignedSuccessfully'),
              autoDismiss: 10
            }));
          } else {
            item.check = true;
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = true;
              }
              return null;
            });
            props.dispatch(updateDeviceUserWiseSelectedList(item));
            props.dispatch(addDeviceUserWise(item));
            props.dispatch(Notifications.success({
              message: props.translate('trackerIsAssignedSuccessfully'),
              autoDismiss: 10
            }));
          }
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };

  const driver_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...props.selecteddriver];
    let obj = {
      userId: props.id,
      driverId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateDriverDeviceWiseSelectedList(item));
          props.dispatch(RemoveDriverDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('driverUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateDriverDeviceWiseSelectedList(item));
          props.dispatch(addDriverDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('driverAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };

  const trailer_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = [...props.selectedtrailer];
    let obj = {
      userId: props.id,
      binderId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateTrailerDeviceWiseSelectedList(item));
          props.dispatch(RemoveTrailerDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('trailerUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateTrailerDeviceWiseSelectedList(item));
          props.dispatch(addTrailerDeviceWise(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('trailerAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };

  const garage_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = props.selectedGarages && props.selectedGarages || [];
    let obj = {
      userId: props.id,
      garageId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updategarageUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('garageUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updategarageUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('garageAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };

  const area_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = props.selectedAreas && props.selectedAreas || [];
    let obj = {
      userId: props.id,
      areaId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateAreaUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('areaUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateAreaUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('areaAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };

  const service_Selection = (checked, item) => {
    let method = 'DELETE';
    let value = checked;
    let allData = props.selectedServices && props.selectedServices || [];
    let obj = {
      userId: props.id,
      expenseTypeId: item.id
    };
    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(() => {
        if (method === 'DELETE') {
          item.check = false;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          props.dispatch(updateServiceUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('serviceUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        } else {
          item.check = true;
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          props.dispatch(updateServiceUsersWiseSelectedList(item));
          props.dispatch(
            Notifications.success({
              message: props.translate('serviceAssignedSuccessfully'),
              autoDismiss: 10
            })
          );
        }
      }).catch(error => { errorHandler(error, props.dispatch) });
  };


  // itemGroup_Selection = (checked, item) => {
  //   let method = 'DELETE'
  //   let value = checked
  //   let allData = state.allItemGroups&&state.allItemGroups.data || []
  //   let obj = {
  //     userId: props.id,
  //     itemGroupId: item.id
  //   }
  //   if (value) {
  //     method = 'POST'
  //   }
  //   instance({
  //     url: `/api/permissions`,
  //     method: method,
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     data:{
  //           ...obj
  //         }
  //   })
  //   .then(response => {  // eslint-disable-line no-unused-vars
  //       if (method === 'DELETE') {
  //         item.check = false
  //         allData.map(dt => {
  //           if (dt.id === item.id) {
  //             dt.check = false
  //           }
  //           return null
  //         })
  //         setState((prevState)=>({...prevState,
  //           allItemGroups:{
  //             ...state.allItemGroups,
  //             data:allData
  //           }
  //         })
  //         // props.dispatch(updateServiceUsersWiseSelectedList(item))
  //          props.dispatch(
  //           Notifications.success({
  //             message: props.translate('itemGroupUnAssignedSuccessfully'),
  //             autoDismiss: 10
  //           })
  //         )
  //       } else {
  //         item.check = true
  //         allData.map(dt => {
  //           if (dt.id === item.id) {
  //             dt.check = true
  //           }
  //           return null
  //         })
  //         setState((prevState)=>({...prevState,
  //           allItemGroups:{
  //             ...state.allItemGroups,
  //             data:allData

  //           }
  //         })
  //         // props.dispatch(updateServiceUsersWiseSelectedList(item))
  //           props.dispatch(
  //           Notifications.success({
  //             message: props.translate('itemGroupAssignedSuccessfully'),
  //             autoDismiss: 10
  //           })
  //         )
  //       }
  //     // }
  //   }).catch(error => {errorHandler(error, props.dispatch)})
  // }


  const dashboard_Selection = (checked, item) => {
    let method = 'DELETE';
    const value = checked;
    // const allData = [...props.selecteddashboard];
    const obj = {
      userId: props.id,
      dashboardId: item.id,
    };

    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        // const updatedAllData = allData.map((dt) => {
        //   if (dt.id === item.id) {
        //     dt.check = method === 'POST';
        //   }
        //   return dt;
        // });

        props.dispatch(updateDashboardDeviceWiseSelectedList(item));
        props.dispatch(
          Notifications.success({
            message: props.translate(
              method === 'POST'
                ? 'dashboardAssignedSuccessfully'
                : 'dashboardUnAssignedSuccessfully'
            ),
            autoDismiss: 10,
          })
        );
      })
      .catch((error) => {
        errorHandler(error, props.dispatch);
      });
  };

  const widget_Selection = (checked, item) => {
    let method = 'DELETE';
    const value = checked;
    // const allData = [...props.selectedwidget];
    const obj = {
      userId: props.id,
      gadgetId: item.id,
    };

    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        // const updatedAllData = allData.map((dt) => {
        //   if (dt.id === item.id) {
        //     dt.check = method === 'POST';
        //   }
        //   return dt;
        // });

        props.dispatch(updateWidgetDeviceWiseSelectedList(item));
        props.dispatch(
          Notifications.success({
            message: props.translate(
              method === 'POST'
                ? 'widgetAssignedSuccessfully'
                : 'widgetUnAssignedSuccessfully'
            ),
            autoDismiss: 10,
          })
        );
      })
      .catch((error) => {
        errorHandler(error, props.dispatch);
      });
  };

  const template_Selection = (checked, item) => {
    let method = 'DELETE';
    const value = checked;
    // const allData = [...props.selectedtemplates];
    const obj = {
      userId: props.id,
      rpTmplId: item.id,
    };

    if (value) {
      method = 'POST';
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        ...obj,
      },
    })
      .then(() => {
        // const updatedAllData = allData.map((dt) => {
        //   if (dt.id === item.id) {
        //     dt.check = method === 'POST';
        //   }
        //   return dt;
        // });

        props.dispatch(updateTemplateUserWiseSelectedList(item));
        props.dispatch(
          Notifications.success({
            message: props.translate(
              method === 'POST'
                ? 'templateAssignedSuccessfully'
                : 'templateUnAssignedSuccessfully'
            ),
            autoDismiss: 10,
          })
        );
      })
      .catch((error) => {
        errorHandler(error, props.dispatch);
      });
  };

// vehicle start
const vehicle_Selection = (checked, item) => {
  let method = 'DELETE'
  let value = checked
  let allData = [...props.selectedvehicle]
  let obj = {
    userId:props.id,
    vehicleId: item.id
  }
  if (value) {
    method = 'POST'
  }

  instance({
    url: `/api/permissions`,
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data:{
          ...obj
        }
  })
  .then(() => {
      if (method === 'DELETE') {
        item.check = false
        allData.map(dt => {
          if (dt.id === item.id) {
            dt.check = false
          }
          return null
        })
        props.dispatch(updateVehicleDeviceWiseSelectedList(item))
        props.dispatch(RemoveVehicleDeviceWise(item))
        props.dispatch(
          Notifications.success({
            message:props.translate('vehicleUnAssignedSuccessfully'),
            autoDismiss: 10
          })
        )
      } else {
        item.check = true
        allData.map(dt => {
          if (dt.id === item.id) {
            dt.check = true
          }
          return null
        })
        props.dispatch(updateVehicleDeviceWiseSelectedList(item))
        props.dispatch(addVehicleDeviceWise(item))
        props.dispatch(
          Notifications.success({
            message: props.translate('vehicleAssignedSuccessfully'),
            autoDismiss: 10
          })
        )
      }
  }).catch(error => {errorHandler(error, this.props.dispatch)})
}
// vehicle end


  const fetchAllVehicles = (id) => {
    instance({
      method: 'GET',
      url: `/api/vehicles/get?all=true&userId=${id}&limit=${-1}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        props.dispatch(getVehicles(response.data));
        // getCurrentUser(id);
      })
      .catch((error) => {
        console.error(error);
      });
  };




  const computedAttributes_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = props.computedAttributes
    let obj = {
      userId: props.id,
      attributeId: item.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {    // eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          props.dispatch(
            updateComputedAttributeDeviceWiseSelectedList(item)
          )
          props.dispatch(RemoveComputedAttributeDeviceWise(item))
          //props.enqueueSnackbar(props.translate('computedAttributeUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'computedAttributeUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          props.dispatch(
            updateComputedAttributeDeviceWiseSelectedList(item)
          )
          props.dispatch(addComputedAttributeDeviceWise(item))
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'computedAttributeAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        }
      })
      .catch(error => {
        errorHandler(error, props.dispatch, errorCallBack)
      })
  }

  const errorCallBack = () => {
    setState((prevState) => ({ ...prevState, visableListAttribute: false }, () => {
      setState((prevState) => ({ ...prevState, visableListAttribute: true }))
    }))
  }

  const savedCommands_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = props.commands
    let obj = {
      userId: props.id,
      commandId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {   // eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          props.dispatch(updateCommandsDeviceWiseSelectedList(item))
          props.dispatch(RemoveCommandsDeviceWise(item))
          //props.enqueueSnackbar(props.translate('savedCommandsUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'savedCommandsUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          props.dispatch(updateCommandsDeviceWiseSelectedList(item))
          props.dispatch(addCommandsDeviceWise(item))
          //props.enqueueSnackbar(props.translate('savedCommandsAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'savedCommandsAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        }
        // }
      }).catch(error => { errorHandler(error, props.dispatch) })
  }

  const maintenance_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = props.maintenance
    let obj = {
      userId: props.id,
      maintenanceId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {    // eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          props.dispatch(updateMaintenanceDeviceWiseSelectedList(item))
          props.dispatch(RemoveMaintenanceDeviceWise(item))
          //props.enqueueSnackbar(props.translate('maintenanceUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          props.dispatch(
            Notifications.success({
              message: props.translate(
                'maintenanceUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          props.dispatch(updateMaintenanceDeviceWiseSelectedList(item))
          props.dispatch(addMaintenanceDeviceWise(item))
          //props.enqueueSnackbar(props.translate('maintenanceAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          props.dispatch(
            Notifications.success({
              message: props.translate('maintenanceAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
        // }
      }).catch(error => { errorHandler(error, props.dispatch) })
  }

  // 4th
  const notificationHandle = (name, value) => {
    setState(prevState => ({
      ...prevState,
      notificationForm: {
        ...prevState.notificationForm,
        [name]: value.id
      }
    })
    )
  }

  const attributeHandle = (name, value) => {
    let selectedValue = postionAttributesTypeComp
      .filter(m => m.key === value)
      .map(m => m.valueType)
    setState(prevState => ({
      ...prevState,
      computedAttForm: {
        ...prevState.computedAttForm,
        [name]: value,
        type: selectedValue[0] ? selectedValue[0] : 'string'
      }
    }
      // () => checkRequiredFields('computedAtt')
    ))
  }

  const savedCommandsHandle = (name, value) => {
    setState(prevState => ({
      ...prevState,
      savedCommandsForm: {
        ...prevState.savedCommandsForm,
        type: value.id
      }
    })
    )
  }

  const maintenancesHandle = (name, value) => {
    setState(prevState => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        [name]: value.id
      }
    })
    )
  }

  // 5th
  const addNotification = () => {
    let obj = { ...state.notificationForm }
    if (
      (state.notificationCallStatus === 'PUT' ||
        state.notificationCallStatus === 'POST') &&
      obj.notificators &&
      obj.notificators.length
    ) {
      let notificatorsArr = []
      obj.notificators.map(v => {
        notificatorsArr.push(v.value)
        return v
      })
      let notificators = notificatorsArr.join(',')
      obj.notificators = notificators
    }
    let params = ''
    if (
      state.notificationCallStatus === 'PUT' ||
      state.notificationCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/notifications/${params}`, {
      //   method: `${state.notificationCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/notifications/${params}`,
        method: `${state.notificationCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(notifications => {
          // if (response.ok) {
          if (state.notificationCallStatus === 'DELETE') {
            props.dispatch(removeNotificationDeviceWiseSelectedList(obj))
            props.dispatch(removedNotification(obj))
            //props.enqueueSnackbar(props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('notificationIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationNotification: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(notifications => {
            setState((prevState) => ({
              ...prevState,
              visableNotification: false
            }))

            if (state.notificationCallStatus === 'PUT') {
              let noti = { ...notifications, check }
              props.dispatch(
                updateNotificationDeviceWiseSelectedList(noti)
              )
              props.dispatch(addNotification(noti))

              //props.enqueueSnackbar(props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('notificationIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              notification_Selection(true, notifications)
              let noti = { ...notifications, check: true }
              props.dispatch(addNotificationDeviceWiseSelectedList(noti))
              props.dispatch(addNotification(noti))
              // /props.enqueueSnackbar(props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('notificationIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }

  const addGeofence = () => {
    let obj = state.geofenceForm
    let params = ''
    if (
      state.geofenceCallStatus === 'PUT' ||
      state.geofenceCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }

      instance({
        url: `/api/geofences/${params}`,
        method: `${state.geofenceCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(geofences => {
          // if (response.ok) {
          if (state.geofenceCallStatus === 'DELETE') {
            props.dispatch(removeGeofenceDeviceWiseSelectedList(obj))
            props.dispatch(deleteGeofence(obj))
            //props.enqueueSnackbar(props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('geofenceDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationGeofence: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(geofences => {
            setState((prevState) => ({
              ...prevState,
              visableGeofence: false
            }))

            if (state.geofenceCallStatus === 'PUT') {
              let noti = { ...geofences, check }
              props.dispatch(updateGeofenceDeviceWiseSelectedList(noti))
              props.dispatch(addGeoFence(noti))

              //props.enqueueSnackbar(props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('geofenceUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              geofence_Selection(true, geofences)
              let noti = { ...geofences, check: true }
              props.dispatch(addGeofenceDeviceWiseSelectedList(noti))
              props.dispatch(addGeoFence(noti))
              // /props.enqueueSnackbar(props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('geofenceCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
            //     })
            //   }
            // }
            // else{
            //   throw response
          }

          setState((prevState) => ({
            ...prevState,
            geofenceCallStatus: ''
          }))
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }

  const addGroup = () => {
    let obj = state.groupForm
    let params = ''
    if (
      state.groupCallStatus === 'PUT' ||
      state.groupCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/groups/${params}`, {
      //   method: `${state.groupCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/groups/${params}`,
        method: `${state.groupCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(groups => {
          // if (response.ok) {
          if (state.groupCallStatus === 'DELETE') {
            props.dispatch(removeGroupDeviceWiseSelectedList(obj))
            props.dispatch(removeGroup(obj))
            //props.enqueueSnackbar(props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('resourceDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationGeofence: false,
              onDeleteConfirmationGroup: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(groups => {
            setState((prevState) => ({
              ...prevState,
              visableGeofence: false
            }))

            if (state.groupCallStatus === 'PUT') {
              let noti = { ...groups, check }
              props.dispatch(updateGeofenceDeviceWiseSelectedList(noti))
              props.dispatch(addGeoFence(noti))

              //props.enqueueSnackbar(props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('resourceUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              group_Selection(true, groups)
              let noti = { ...groups, check: true }
              props.dispatch(addGeofenceDeviceWiseSelectedList(noti))
              props.dispatch(addGeoFence(noti))
              // /props.enqueueSnackbar(props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('resourceAdded'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
          setState((prevState) => ({
            ...prevState,
            groupCallStatus: ''
          }))
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }

  // const addDevice = () => {
  //   let obj = state.deviceForm
  //   let params = ''
  //   if (
  //     state.deviceCallStatus === 'PUT' ||
  //     state.deviceCallStatus === 'DELETE'
  //   ) {
  //     params = obj.id
  //   }
  //   if (obj) {
  //     let check
  //     if (obj.check !== undefined) {
  //       check = obj.check
  //       delete obj.check
  //     }
  //     if (obj.alarms) {
  //       obj.attributes = { ...obj.attributes, alarms: obj.alarms }
  //       delete obj.alarms
  //     }
  //     // fetch(`/api/devices/${params}`, {
  //     //   method: `${state.deviceCallStatus}`,
  //     //   headers: {
  //     //     Accept: 'application/json',
  //     //     'Content-Type': 'application/json'
  //     //   },
  //     //   body: JSON.stringify({
  //     //     ...obj
  //     //   })
  //     // })
  //     instance({
  //       url: `/api/devices/${params}`,
  //       method: `${state.deviceCallStatus}`,
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       body:{
  //             ...obj
  //           }
  //     })
  //       .then(devices => {
  //         // if (response.ok) {
  //           if (state.deviceCallStatus === 'DELETE') {
  //             props.dispatch(removeDeviceUserWiseSelectedList(obj))
  //             props.dispatch(removeDevice(obj))
  //             //props.enqueueSnackbar(props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
  //             props.dispatch(
  //               Notifications.success({
  //                 message: props.translate('deviceIsDeleted'),
  //                 autoDismiss: 10
  //               })
  //             )
  //             setState((prevState)=>({...prevState,
  //               onDeleteConfirmationDevice: false,
  //               attributeChangesMessage: ''
  //             }))
  //           } else {
  //             // response.json().then(devices => {
  //               setState((prevState)=>({...prevState,
  //                 visableDevice: false
  //               }))

  //               if (state.deviceCallStatus === 'PUT') {
  //                 let noti = { ...devices, check }
  //                 props.dispatch(updateDeviceUserWiseSelectedList(noti))
  //                 props.dispatch(addDevices(noti))

  //                 //props.enqueueSnackbar(props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
  //                 props.dispatch(
  //                   Notifications.success({
  //                     message: props.translate('deviceIsUpdated'),
  //                     autoDismiss: 10
  //                   })
  //                 )
  //                 setState((prevState)=>({...prevState,
  //                   attributeChangesMessage: ''
  //                 }))
  //               } else {
  //                 device_Selection(true, devices)
  //                 let noti = { ...devices, check: true }
  //                 props.dispatch(addDeviceUserWiseSelectedList(noti))
  //                 props.dispatch(addDevices(noti))
  //                 // /props.enqueueSnackbar(props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
  //                 props.dispatch(
  //                   Notifications.success({
  //                     message: props.translate('deviceIsCreated'),
  //                     autoDismiss: 10
  //                   })
  //                 )
  //                 setState((prevState)=>({...prevState,
  //                   attributeChangesMessage: ''
  //                 }))
  //               }
  //         //     })
  //         //   }
  //         // } else {
  //         //   throw response
  //         }
  //       })
  //       .catch(error => {
  //         errorHandler(error, props.dispatch)

  //       })
  //   }
  // }

  const addDrivers = () => {
    let obj = state.driverForm
    let params = ''
    if (
      state.driverCallStatus === 'PUT' ||
      state.driverCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/drivers/${params}`, {
      //   method: `${state.driverCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/drivers/${params}`,
        method: `${state.driverCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(drivers => {
          // if (response.ok) {
          if (state.driverCallStatus === 'DELETE') {
            props.dispatch(removeDriverDeviceWiseSelectedList(obj))
            props.dispatch(removeDriver(obj))
            //props.enqueueSnackbar(props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('driverIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationDriver: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(drivers => {
            setState((prevState) => ({
              ...prevState,
              visableDriver: false
            }))

            if (state.driverCallStatus === 'PUT') {
              let noti = { ...drivers, check }
              props.dispatch(updateDriverDeviceWiseSelectedList(noti))
              props.dispatch(addDriver(noti))

              //props.enqueueSnackbar(props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('driverIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              driver_Selection(true, drivers)
              let noti = { ...drivers, check: true }
              props.dispatch(addDriverDeviceWiseSelectedList(noti))
              props.dispatch(addDriver(noti))
              // /props.enqueueSnackbar(props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('driverIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
       

            //     })
            //   }
            // }
            // else{
            //   throw response
          }
          setState((prevState) => ({ ...prevState, driverCallStatus: "" }))
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }

  const addTrailer = () => {
    let obj = state.trailerForm
    let params = ''
    if (
      state.trailerCallStatus === 'PUT' ||
      state.trailerCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      instance({
        url: `/api/binders/${params}`,
        method: `${state.trailerCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(trailers => {
          // if (response.ok) {
          if (state.trailerCallStatus === 'DELETE') {
            props.dispatch(removeTrailerDeviceWiseSelectedList(obj))
            props.dispatch(removeTrailer(obj))
            //props.enqueueSnackbar(props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('trailerIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationTrailer: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(trailers => {
            setState((prevState) => ({
              ...prevState,
              visableTrailer: false
            }))

            if (state.trailerCallStatus === 'PUT') {
              let noti = { ...trailers, check }
              props.dispatch(updateTrailerDeviceWiseSelectedList(noti))
              props.dispatch(addTrailers(noti))
              //props.enqueueSnackbar(props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('trailerIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              trailer_Selection(true, trailers)
              let noti = { ...trailers, check: true }
              props.dispatch(addTrailerDeviceWiseSelectedList(noti))
              props.dispatch(addTrailers(noti))
              // /props.enqueueSnackbar(props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('trailerIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
          setState((prevState) => ({
            ...prevState,
            trailerCallStatus: ''
          }))
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }


  const addDashboard = () => {
    let obj = state.dashboardForm
    let params = ''
    if (
      state.dashboardCallStatus === 'PUT' ||
      state.dashboardCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }

      instance({
        url: `/api/dashboards/${params}`,
        method: `${state.dashboardCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(dashboards => {
          // if (response.ok) {
          if (state.dashboardCallStatus === 'DELETE') {
            props.dispatch(removeDashboardDeviceWiseSelectedList(obj))
            fetchDashboards(props.dispatch, props.logInUser)
            // props.dispatch(removeDashboard(obj))
            props.dispatch(
              Notifications.success({
                message: props.translate('dashboardIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationDashboard: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(dashboards => {
            setState((prevState) => ({
              ...prevState,
              visableDashboard: false
            }))

            if (state.dashboardCallStatus === 'PUT') {
              let noti = { ...dashboards, check }
              props.dispatch(updateDashboardDeviceWiseSelectedList(noti))
              // props.dispatch(addDashboards(noti))
              props.dispatch(
                Notifications.success({
                  message: props.translate('dashboardIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              dashboard_Selection(true, dashboards)
              let noti = { ...dashboards, check: true }
              props.dispatch(addDashboardDeviceWiseSelectedList(noti))
              // props.dispatch(addDashboards(noti))
              props.dispatch(
                Notifications.success({
                  message: props.translate('dashboardIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
          setState((prevState) => ({
            ...prevState,
            dashboardCallStatus: ''
          }))
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }
  const addWidget = () => {
    let obj = state.widgetForm
    let params = ''
    if (
      state.widgetCallStatus === 'PUT' ||
      state.widgetCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }

      instance({
        url: `/api/gadgets/${params}`,
        method: `${state.widgetCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(widgets => {
          // if (response.ok) {
          if (state.widgetCallStatus === 'DELETE') {
            props.dispatch(removeWidgetDeviceWiseSelectedList(obj))
            // props.dispatch(removeWidget(obj))
            props.dispatch(
              Notifications.success({
                message: props.translate('widgetIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationWidget: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(widgets => {
            setState((prevState) => ({
              ...prevState,
              visableWidget: false
            }))

            if (state.widgetCallStatus === 'PUT') {
              let noti = { ...widgets, check }
              props.dispatch(updateWidgetDeviceWiseSelectedList(noti))
              // props.dispatch(addWidgets(noti))
              props.dispatch(
                Notifications.success({
                  message: props.translate('widgetIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              widget_Selection(true, widgets)
              let noti = { ...widgets, check: true }
              props.dispatch(addWidgetDeviceWiseSelectedList(noti))
              // props.dispatch(addWidgets(noti))
              props.dispatch(
                Notifications.success({
                  message: props.translate('widgetIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }
  // start vehicle
  const addVehicle = () => {
    let obj = state.vehicleForm
    let params = ''
    if (
      state.vehicleCallStatus === 'PUT' ||
      state.vehicleCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      instance({
        url: `/api/vehicles/${params}`,
        method: `${state.vehicleCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(vehicles => {
          if (state.vehicleCallStatus === 'DELETE') {
            props.dispatch(removeVehicleDeviceWiseSelectedList(obj))
            props.dispatch(removedvehicle(obj))
            props.dispatch(
              Notifications.success({
                message: props.translate('vehicleIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationVehicle: false,
              attributeChangesMessage: ''
            }))
          } else {
            setState((prevState) => ({
              ...prevState,
              visableVehicle: false
            }))

            if (state.vehicleCallStatus === 'PUT') {
              let noti = { ...vehicles, check }
              props.dispatch(updateVehicleDeviceWiseSelectedList(noti))
              props.dispatch(addvehicle(noti))
              props.dispatch(
                Notifications.success({
                  message: props.translate('vehicleIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              vehicle_Selection(true, vehicles)
              let noti = { ...vehicles, check: true }
              props.dispatch(addVehicleDeviceWiseSelectedList(noti))
              props.dispatch(addvehicle(noti))
              props.dispatch(
                Notifications.success({
                  message: props.translate('vehicleIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
          }
          setState((prevState) => ({
            ...prevState,
            vehicleCallStatus: ''
          }))
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }
  // end vehicle
  const addComputedAttributes = () => {
    let obj =state.computedAttForm
    let params = ''
    if (
      state.attributeCallStatus === 'PUT' ||
      state.attributeCallStatus === 'DELETE'
    ) {
      params = obj.id
      delete obj.indeterminate
      delete obj.parent
      delete obj.deviceId
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }
      setIsLoading(true)
      instance({
        url: `/api/attributes/computed/${params}`,
        method: `${state.attributeCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(Attribute => {
          // if (response.ok) {
          if (state.attributeCallStatus === 'DELETE') {
            props.dispatch(
              removedComputedAttributeDeviceWiseSelectedList(obj)
            )
            props.dispatch(removedComputedAttribute(obj))
            //props.enqueueSnackbar(props.translate('computedAttributeIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('computedAttributeIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationAttributes: false
            }))
          } else {
            // response.json().then(Attribute => {
            setState((prevState) => ({
              ...prevState,
              visableAttribute: false
            }))
            if (state.attributeCallStatus === 'PUT') {
              props.dispatch(
                updateComputedAttributeDeviceWiseSelectedList(Attribute)
              )
              props.dispatch(addComputedAttribute(Attribute))
              //props.enqueueSnackbar(props.translate('computedAttributeIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('computedAttributeIsUpdated'),
                  autoDismiss: 10
                })
              )
            } else {
              computedAttributes_Selection(true, Attribute)
              props.dispatch(
                addComputedAttributeDeviceWiseSelectedList(Attribute)
              )
              props.dispatch(addComputedAttribute(Attribute))
              //props.enqueueSnackbar(props.translate('computedAttributeIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('computedAttributeIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                visableListAttribute: false
              }))
            }
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
      setIsLoading(false)

          setState((prevState) => ({ ...prevState, attributeCallStatus: "" }))

        }).catch(error => {
      setIsLoading(false)
          
          errorHandler(error, props.dispatch) })
    }
  }

  const addSavedCommands = () => {
    let {
      attributes,
      id,
      description,
      type,
      textChannel
    } = state.savedCommandsForm
    textChannel = textChannel === 'Yes' ? true : false
    attributes = attributes || {}
    id = id || -1
    let obj = { attributes, id, description, type, textChannel }
    obj.deviceId = 0
    let params = ''
    if (
      state.commandsCallStatus === 'PUT' ||
      state.commandsCallStatus === 'DELETE'
    ) {
      params = obj.id
    }

    if (obj) {
      setIsLoading(true)
      instance({
        url: `/api/commands/${params}`,
        method: `${state.commandsCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(commands => {
          if (state.commandsCallStatus === 'DELETE') {
            props.dispatch(removedCommandsDeviceWiseSelectedList(obj))
            props.dispatch(removedCommands(obj))
            //props.enqueueSnackbar(props.translate('savedCommandsIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('savedCommandsIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationCommand: false
            }))
          } else {
            // response.json().then(commands => {
            setState((prevState) => ({
              ...prevState,
              visableSavedCommands: false
            }))
            if (state.commandsCallStatus === 'PUT') {
              props.dispatch(
                updateCommandsDeviceWiseSelectedList(commands)
              )
              props.dispatch(addCommands(commands))
              //props.enqueueSnackbar(props.translate('savedCommandsIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('savedCommandsIsUpdated'),
                  autoDismiss: 10
                })
              )
            } else {
              savedCommands_Selection(true, commands)
              props.dispatch(addCommandsDeviceWiseSelectedList(commands))
              props.dispatch(addCommands(commands))
              //props.enqueueSnackbar(props.translate('savedCommandsIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('savedCommandsIsCreated'),
                  autoDismiss: 10
                })
              )

              setState((prevState) => ({ ...prevState, visableListSavedCommands: false }))
            }
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
      setIsLoading(false)

          setState((prevState) => ({ ...prevState, commandsCallStatus: "" }))
        }).catch(error => { 
      setIsLoading(false)
          
          errorHandler(error, props.dispatch) })
    }
  }

  const addMaintenances = () => {
    let obj = state.maintenanceForm

    if (state.maintenanceForm.type === 'totalDistance') {
      obj.start = parseFloat(state.maintenanceForm.start * 1000)
      obj.period = parseFloat(state.maintenanceForm.period * 1000)
    } else if (state.maintenanceForm.type === 'hours') {
      obj.start = parseFloat(state.maintenanceForm.start * (1000 * 3600))
    } else if (state.maintenanceForm.type === 'date') {
      obj.start = 1
    }

    let params = ''
    if (
      state.maintenanceCallStatus === 'PUT' ||
      state.maintenanceCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }

      instance({
        url: `/api/maintenance/${params}`,
        method: `${state.maintenanceCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(Maintenance => {
          // if (response.ok) {
          if (state.maintenanceCallStatus === 'DELETE') {
            props.dispatch(removedMaintenanceDeviceWiseSelectedList(obj))
            props.dispatch(removedMaintenance(obj))
            //props.enqueueSnackbar(props.translate('maintenanceIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('maintenanceIsDeleted'),
                autoDismiss: 10
              })
            )

            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationMaintenance: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(Maintenance => {
            if (state.maintenanceCallStatus === 'PUT') {
              props.dispatch(
                updateMaintenanceDeviceWiseSelectedList(Maintenance)
              )
              props.dispatch(addMaintenance(Maintenance))
              //props.enqueueSnackbar(props.translate('maintenanceIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('maintenanceIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              maintenance_Selection(true, Maintenance)
              props.dispatch(
                addMaintenanceDeviceWiseSelectedList(Maintenance)
              )
              props.dispatch(addMaintenance(Maintenance))
              //props.enqueueSnackbar(props.translate('MaintenanceIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('MaintenanceIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: '',
                visableListMaintenance: false
              }))
            }

            setState((prevState) => ({
              ...prevState,
              visableMaintenance: false,
              attributeChangesMessage: ''
            }))
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
          setState((prevState) => ({
            ...prevState,
            maintenanceCallStatus: ''
          }))
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
  }

  const addUser = () => {
    let obj = state.userForm
    let params = ''
    if (
      state.userCallStatus === 'PUT' ||
      state.userCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }

      instance({
        url: `/api/users/${params}`,
        method: `${state.userCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(User => {
          // if (response.ok) {
          if (state.userCallStatus === 'DELETE') {
            props.dispatch(removeUserUsereWise(obj))

            props.dispatch(
              Notifications.success({
                message: props.translate('userIsRemoved'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationUser: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(User => {
            if (state.maintenanceCallStatus === 'PUT') {
              props.dispatch(updateUserDeviceWiseSelectedList(User))
              props.dispatch(
                Notifications.success({
                  message: props.translate('UserIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              maintenance_Selection(true, User)

              props.dispatch(addUserDeviceWiseSelectedList(User))

              //props.enqueueSnackbar(props.translate('UserIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              props.dispatch(
                Notifications.success({
                  message: props.translate('UserIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: '',
                visableListUser: false
              }))
            }

            setState((prevState) => ({
              ...prevState,
              visableUser: false,
              attributeChangesMessage: ''
            }))
            //     })
            //   }
            // }
            // else{
            //   throw response
          }
          setState((prevState) => ({
            ...prevState,
    userCallStatus:""
          }))
        }).catch(error => { 
          setState((prevState) => ({
            ...prevState,
            onDeleteConfirmationUser: false,
          }))
          errorHandler(error, props.dispatch) 
        })
    }
  }

  // 6th
  const editNotification = (item) => {
    props.history.push(`/notificationUpdate/${item[0].id}`)
    let a =
      item && item[0] && item[0].notificators
        ? item[0].notificators.split(',')
        : []
    let notificators = a.length
      ? a.map(e => ({ label: props.translate(e), value: e }))
      : []
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: item[0],
      notificationCallStatus: 'PUT',
      visableNotification: true,
      notificationForm: { ...item[0], notificators },
      addButtonStatus: 'sharedUpdate',
      defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
      attributeChangesMessage: '',
      itemAttributes: item[0].attributes,
      isHaveAccess: 'notificationUpdate'
    }))
  }

  const editComputedAttributes = (item) => {
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: item[0],
      attributeCallStatus: 'PUT',
      visableAttribute: true,
      computedAttForm: {
        ...item[0],
        expression: ReactHtmlParser(item[0].expression)[0]
      },
      addButtonStatus: 'sharedUpdate',
      isHaveAccess: 'attributeUpdate'
    }))
  }

  const editCommands = (item) => {
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: item[0],
      commandsCallStatus: 'PUT',
      visableSavedCommands: true,
      savedCommandsForm: { ...item[0] },
      addButtonStatus: 'sharedUpdate',
      isHaveAccess: 'commandUpdate'
    }))
  }

  const editDashboard = (item) => {
    props.history.push(`/dashboardBuilder/${item[0].id}`)
  }

  const editMaintenance = (item) => {
    props.history.push(`/maintenanceUpdate/${item[0].id}`)
    // let obj = JSON.parse(JSON.stringify(item[0]))
    // if (obj.type === 'totalDistance') {
    //   obj.start = parseFloat(obj.start / 1000)
    //   obj.period = parseFloat(obj.period / 1000)
    // } else if (obj.type === 'hours') {
    //   obj.start = parseFloat(obj.start / (1000 * 3600))
    //   obj.period = parseFloat(obj.period / (1000 * 3600))
    // } else if (obj.type === 'date') {
    //   obj.start = 1
    // }

    // setState((prevState)=>({...prevState,
    //   isAddVisableBtn: false,
    //   selecteItem: item[0],
    //   maintenanceCallStatus: 'PUT',
    //   visableMaintenance: true,
    //   maintenanceForm: { ...obj },
    //   addButtonStatus: 'sharedUpdate',
    //   attributeChangesMessage: '',
    //   defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
    //   itemAttributes: item[0].attributes,
    //   isHaveAccess: 'maintenanceUpdate'
    // })
  }

  const addMaintenanceAttributes = () => {
    setState((prevState) => ({
      ...prevState,
      isAddAttributes: true,
      componentType: 'Maintenance',
      //defaultAttributes: JSON.parse(JSON.stringify(state.maintenanceForm.attributes)),
      itemAttributes: state.maintenanceForm.attributes
    }))
  }

  const notification_handleChange2 = (value, option) => {
    if (option && option.name === 'calendarId') {
      setState((prevState) => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [option.name]: value.value
        }
      }))
    } else if (typeof option === 'object' && Object.keys(option).length) {
      // for select
      setState((prevState) => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [option.name]: value
        }
      }))
    } else if (value === 'type') {
      setState((prevState) => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [value]: option
        }
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          attributes: {
            ...prevState.notificationForm.attributes,
            [value]: option
          }
        }
      }))
    }
  }

  // direct arrow function
  const notification_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    setState((prevState) => ({
      ...prevState,
      notificationForm: {
        ...prevState.notificationForm,
        [name]: value
      }
    }))
  }

  //   const geofence_handleChange = name => event => {
  //     const { target } = event
  //     let value = target.type === 'checkbox' ? target.checked : target.value
  //     setState(
  //       {
  //         geofenceForm: {
  //           ...state.geofenceForm,
  //           [name]: value
  //         }
  //       },
  //       () => checkRequiredFields('geofence')
  //     )
  //   }

  //  const  driver_handleChange = name => event => {
  //     const { target } = event
  //     let value = target.type === 'checkbox' ? target.checked : target.value
  //     setState(
  //       {
  //         driverForm: {
  //           ...state.driverForm,
  //           [name]: value
  //         }
  //       },
  //       () => checkRequiredFields('driver')
  //     )
  //   }

  //   const trailer_handleChange = name => event => {
  //     const { target } = event
  //     let value = target.type === 'checkbox' ? target.checked : target.value
  //     setState(
  //       {
  //         trailerForm: {
  //           ...state.trailerForm,
  //           [name]: value
  //         }
  //       },
  //       () => checkRequiredFields('trailer')
  //     )
  //   }

  //   const dashboard_handleChange = name => event => {
  //     const { target } = event
  //     let value = target.type === 'checkbox' ? target.checked : target.value
  //     setState(
  //       {
  //         dashboardForm: {
  //           ...state.dashboardForm,
  //           [name]: value
  //         }
  //       },
  //       () => checkRequiredFields('dashboard')
  //     )
  //   }

  //  const  widget_handleChange = name => event => {
  //     const { target } = event
  //     let value = target.type === 'checkbox' ? target.checked : target.value
  //     setState(
  //       {
  //         widgetForm: {
  //           ...state.widgetForm,
  //           [name]: value
  //         }
  //       },
  //       () => checkRequiredFields('widget')
  //     )
  //   }

  //   //start vehicle
  //  const  vehicle_handleChange = name => event => {
  //     const { target } = event
  //     let value = target.type === 'checkbox' ? target.checked : target.value
  //     setState(
  //       {
  //         vehicleForm: {
  //           ...state.vehicleForm,
  //           [name]: value
  //         }
  //       },
  //       () => checkRequiredFields('vehicle')
  //     )
  //   }
  //   //end vehicle

  //   const group_handleChange = name => event => {
  //     const { target } = event
  //     let value = target.type === 'checkbox' ? target.checked : target.value
  //     setState(
  //       {
  //         groupForm: {
  //           ...state.groupForm,
  //           [name]: value
  //         }
  //       },
  //       () => checkRequiredFields('group')
  //     )
  //   }

  const C_Att_handleChange = name => event => {
    const { target } = event
    setState((prevState) => ({
      ...prevState,
      computedAttForm: {
        ...prevState.computedAttForm,
        [name]: target.value
      }
    }))
  }

  const savedCommands_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (name === 'attributes') {
      let att = state.savedCommandsForm.attributes
      let newAtt = { [target.name]: target.value }
      att = { ...att, ...newAtt }
      value = att
      setState((prevState) => ({
        ...prevState,
        savedCommandsForm: {
          ...prevState.savedCommandsForm,
          [target.name]:
            target.type === 'checkbox' ? target.checked : target.value
        }
      }))
    }
    setState((prevState) => ({
      ...prevState,
      savedCommandsForm: {
        ...prevState.savedCommandsForm,
        [name]: value
      }
    }))
  }

  const maintenance_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        [name]: value
      }
    }))
  }

  const maintenance_handleChange_attr = name => date => {
    const value =
      name === 'repeat' || name === 'status' ? date.target.checked : date
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        attributes: {
          ...prevState.maintenanceForm.attributes,
          [name]: value
        }
      }
    }))
  }

  // remove

  const removeNotification = () => {
    setState(prevState => ({
      ...prevState,
      notificationCallStatus: 'DELETE'
    }))
  }

  const removeGeofence = () => {
    setState(prevState => ({
      ...prevState,
      geofenceCallStatus: 'DELETE'
    }))
  }

  const removeGroups = () => {
    setState(prevState => ({
      ...prevState,
      groupCallStatus: 'DELETE'
    }))
  }

  const removeDrivers = () => {
    setState(prevState => ({
      ...prevState,
      driverCallStatus: 'DELETE'
    }))
  }
  const removeTrailers = () => {
    setState(prevState => ({
      ...prevState,
      trailerCallStatus: 'DELETE'
    }))
  }
  const removeGarage = () => {
    setState(prevState => ({
      ...prevState,
      garageCallStatus: 'DELETE'
    })
    )
  }
  const removeDashboard = () => {
    setState(prevState => ({
      ...prevState,
      dashboardCallStatus: 'DELETE'
    }))
  }
  const removeWidget = () => {
    setState(prevState => ({
      ...prevState,
      widgetCallStatus: 'DELETE'
    }))
  }
  //start vehicle
  const removeVehicle = () => {
    setState(prevState => ({
      ...prevState,
      vehicleCallStatus: 'DELETE'
    }))
  }
  //end vehicle

  const removeMaintenance = () => {
    setState(prevState => ({
      ...prevState,
      maintenanceCallStatus: 'DELETE'
    }))
  }

  const removeUser = () => {
    setState(prevState => ({
      ...prevState,
      userCallStatus: 'DELETE'
    }))
  }

  const removeComputedAttributes = () => {
    setState(prevState => ({
      ...prevState,
      attributeCallStatus: 'DELETE'
    }))
  }

  const onRemovedNotification = item => {
    setState((prevState) => ({
      ...prevState,
      notificationForm: { ...item[0] },
      onDeleteConfirmationNotification: true
    }))
  }

  const onRemovedGeofence = item => {
    setState((prevState) => ({
      ...prevState,
      geofenceForm: { ...item[0] },
      onDeleteConfirmationGeofence: true
    }))
  }

  const onRemovedGroup = item => {
    setState((prevState) => ({ ...prevState, onDeleteConfirmationGroup: !prevState.onDeleteConfirmationGroup }))
    setState((prevState) => ({
      ...prevState,
      groupForm: { ...item[0] },
      // onDeleteConfirmationGroup:!prevState.onDeleteConfirmationGroup
    }))
  }
  const onRemovedDriver = item => {
    setState((prevState) => ({
      ...prevState,
      driverForm: { ...item[0] },
      onDeleteConfirmationDriver: true
    }))
  }
  const onRemovedTrailer = item => {
    setState((prevState) => ({
      ...prevState,
      trailerForm: { ...item[0] },
      onDeleteConfirmationTrailer: true
    }))
  }
  const onRemovedGarage = item => {
    setState((prevState) => ({
      ...prevState,
      garageForm: { ...item[0] },
      onDeleteConfirmationGarage: true
    }))
  }
  const onRemovedDashboard = item => {
    setState((prevState) => ({
      ...prevState,
      dashboardForm: { ...item[0] },
      onDeleteConfirmationDashboard: true
    }))
  }
  const onRemovedWidget = item => {
    setState((prevState) => ({
      ...prevState,
      widgetForm: { ...item[0] },
      onDeleteConfirmationWidget: true
    }))
  }

  const onRemovedTemplate = item => {
    setState((prevState) => ({
      ...prevState,
      templateForm: { ...item[0] },
      onDeleteConfirmationTemplate: true
    }))
  }
  //start vehicle
  const onRemovedVehicle = item => {
    setState((prevState) => ({
      ...prevState,
      vehicleForm: { ...item[0] },
      onDeleteConfirmationVehicle: true
    }))
  }
  // end vehicle
  const onRemovedAttribute = item => {
    setState((prevState) => ({
      ...prevState,
      computedAttForm: { ...item[0] },
      onDeleteConfirmationAttributes: true
    }))
  }

  const onRemovedCommand = item => {
    setState((prevState) => ({
      ...prevState,
      savedCommandsForm: { ...item[0] },
      onDeleteConfirmationCommand: true
    }))
  }

  const onRemovedMaintenance = item => {
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: { ...item[0] },
      onDeleteConfirmationMaintenance: true
    }))
  }

  const onRemovedUser = item => {
    setState((prevState) => ({
      ...prevState,
      userForm: { ...item[0] },
      onDeleteConfirmationUser: true
    }))
  }

  const onRemovedDevice = item => {
    // e.preventDefault()
    setState((prevState) => ({
      ...prevState,
      slectedUnits: item[0],
      onDeleteConfirmationUnits: true
    }))
    return false
  }

  const removeCommands = () => {
    setState((prevState) => ({
      ...prevState,
      commandsCallStatus: 'DELETE'
    }))
  }

  const deleteGarages = () => {
    let id = state.garageForm.id
    if (id) {
      instance({
        url: `/api/garages/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {   // eslint-disable-line no-unused-vars
          props.dispatch(removedGarages({ id: id }))
          props.dispatch(
            Notifications.success({
              message: props.translate('GaragesIsDeleted'),
              autoDismiss: 10
            })
          )
          fetchGarageNewLists()
          setState((prevState) => ({ ...prevState, garageCallStatus: "" }))
        }).catch(error => { errorHandler(error, props.dispatch) })
    }
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmationGarage: false,
    }))
  }

  const fetchGarageNewLists = () => {
    instance({
      url: `/api/garages/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(res => { props.dispatch(getGarages(res)) })
      .catch(error => {
        console.error('error===', error)

        // errorHandler(error, props.dispatch)
      })
  }
  const removeDevices = () => {
    let { slectedUnits } = state
    // fetch(`/api/devices/${slectedUnits.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/devices/${slectedUnits.id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => { // eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        props.dispatch(removeDeviceUserWiseSelectedList(slectedUnits))
        props.dispatch(removeDevice(slectedUnits))
        setState((prevState) => ({ ...prevState, onDeleteConfirmationUnits: false }))
        props.dispatch(
          Notifications.success({
            message: props.translate('deviceIsDeleted'),
            autoDismiss: 10
          })
        )
        // }
      }).catch(error => { errorHandler(error, props.dispatch) })
  }

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmationGeofence: false,
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationTrailer: false,
      onDeleteConfirmationVehicle: false,
      onDeleteConfirmationUser: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationGarage: false,
      onDeleteConfirmationGroup: false,
      onDeleteConfirmationUnits: false,
      onDeleteConfirmationDriver:false,
      onDeleteConfirmationDashboard: false
    }))
  }

  const handleChangeRowsPerPage = rowsPerPage => {
    setState((prevState) => ({ ...prevState, rowsPerPage }))
  }

  // comman for all
  const onCloseC_Att_Modal = () => {
    setState((prevState) => ({
      ...prevState,
      visableAttribute: false,
      visableNotification: false,
      visableGeofence: false,
      visableMaintenance: false,
      visableSavedCommands: false,
      attributeChangesMessage: '',
      defaultAttributes: {}
    }))
  }

  // check for required field
  const checkRequiredFields = (computedAtt) => {
    // changed attribute work is in pendding
    const {
      defaultAttributes,
      selecteItem,
      maintenanceForm,
      computedAttForm,
      savedCommandsForm,
      notificationForm
    } = state
    if (computedAtt === 'computedAtt') {
      let changed_comAt = !isEqual(selecteItem, computedAttForm)
      let { attribute, expression } = state.computedAttForm
      if (
        attribute &&
        expression &&
        attribute.trim().length > 0 &&
        expression.trim().length > 0 &&
        changed_comAt
      ) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false
        }))
      }
    } else if (computedAtt === 'Commands') {
      let changed_comm = !isEqual(selecteItem, savedCommandsForm)
      let { type, description } = state.savedCommandsForm
      if (type && description && changed_comm) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false
        }))
      }
    } else if (computedAtt === 'notification') {
      // attChangedValue is for attributes changing value
      let attChangedValue = !isEqual(
        defaultAttributes,
        notificationForm.attributes
      )
      if (attChangedValue) {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: 'Change in attributes is pending'
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: ''
        }))
      }
      let changed_noti = !isEqual(selecteItem, notificationForm)
      let { type, notificators } = state.notificationForm
      if (type && notificators && changed_noti) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false
        }))
      }
    } else if (computedAtt === 'maintenance') {
      let Attvalue = !isEqual(defaultAttributes, maintenanceForm.attributes)
      if (Attvalue) {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: 'Change in attributes is pending'
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: ''
        }))
      }
      let changed_main = !isEqual(selecteItem, maintenanceForm)
      let { type, start, period, attributes } = state.maintenanceForm
      if (type && period && (start || attributes.startDate) && changed_main) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false
        }))
      }
    }
  }

  // const isButtonVisable = (target) => {
  //   if (target === 'parkingAttForm') {
  //     const { attributes } = state.resFormData
  //     let att = Object.keys(attributes).reduce((array, key) => {
  //       return [...array, [key, attributes[key]]]
  //     }, [])
  //     let unitParkingAttributes = []
  //     att.map(item => {
  //       if (item[0] === 'parking.minIdleTime') {
  //         unitParkingAttributes.push({ 'parking.minIdleTime': item[1] / 60000 })
  //       }
  //       if (item[0] === 'parking.maxIdleSpeed') {
  //         unitParkingAttributes.push({
  //           'parking.maxIdleSpeed': Math.round(item[1] * 1.852)
  //         })
  //       }
  //       return null
  //     })
  //     let value = {
  //       'parking.maxIdleSpeed': 0,
  //       'parking.minIdleTime': 0
  //     }
  //     unitParkingAttributes.map(item => {
  //       value = { ...value, ...item }
  //       return null
  //     })
  //     setState((prevState)=>({...prevState,
  //       isVisableParkingAttBtn: !isEqual(
  //         value,
  //         state.unitParkingAttributesform
  //       )
  //     }))
  //   }
  //   if (target === 'aFormOne') {
  //     const { category } = state.resFormData
  //     setState((prevState)=>({...prevState,
  //       isVisableASOneBtn: !isEqual(category, state.aOneform.category)
  //     }))
  //   }
  // }

  // const handleChangeForParkingAtt = name => event => {
  //   const { target } = event
  //   setState(preState => ({
  //     ...preState,
  //     unitParkingAttributesform: {
  //       ...preState.unitParkingAttributesform,
  //       [name]: parseFloat(target.value)
  //     }
  //   }))
  //   setState(
  //     {
  //       isVisableParkingAttBtn: state.isVisableParkingAttBtn
  //     },
  //     () => {
  //       isButtonVisable('parkingAttForm')
  //     }
  //   )
  // }

  // const parkingformSubmit = () =>  {
  //   let { selecteditem } = state
  //   if (selecteditem) {
  //     let attributes = selecteditem.attributes
  //     attributes = {
  //       ...attributes,
  //       'parking.minIdleTime': (
  //         state.unitParkingAttributesform['parking.minIdleTime'] *
  //         60 *
  //         1000
  //       ).toString(),
  //       'parking.maxIdleSpeed': (
  //         state.unitParkingAttributesform['parking.maxIdleSpeed'] / 1.852
  //       ).toString()
  //     }
  //     selecteditem.attributes = attributes
  //     setState(
  //       {
  //         selecteditem
  //       },
  //       () => formSubmit()
  //     )
  //   }
  // }

  const getCurrentUser = id => {
    if (id) {
      // for dashboard
      if (checkPrivileges('dashboard')) {
        // fetch(`/api/dashboards?userId=${id}&all=true&limit=-1`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/dashboards`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id,
            all: true,
            limit: -1
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(dashboard => {
            setState((prevState) => ({
              ...prevState,
              mulitDashboardsSelect: dashboard,
              isDashboardRecived: true
            }))
            let allData = props.dashboards
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (dashboard && dashboard.length) {
                dashboard.map(main => {
                  if (item.id && main.id && item.id === main.id) {
                    item.check = true
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(dashboardDeviceWiseSelectedList(allData))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for widget
      if (props.widgets.length) {
        // fetch(`/api/dashboards/gadgets?userId=${id}&all=true&limit=-1`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/dashboards/gadgets`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id,
            all: true,
            limit: -1
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(widget => {
            setState((prevState) => ({
              ...prevState,
              mulitWidgetsSelect: widget.data,
              isWidgetRecived: true
            }))
            let allData = props.widgets
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (widget.data && widget.data.length) {
                widget.data.map(main => {
                  if (item.id && main.id && item.id === main.id) {
                    item.check = true
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(widgetDeviceWiseSelectedList(allData))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for driver
      if (checkPrivileges('driver')) {
        // fetch(`/api/drivers/linked?userId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/drivers/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(driver => {
            setState((prevState) => ({
              ...prevState,
              mulitDriversSelect: driver,
              isDriverRecived: true
            }))

            let allData = props.drivers
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (driver && driver.length) {
                driver.map(main => {
                  if (item.id && main.entityId && item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(driverDeviceWiseSelectedList(allData))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for trailer
      if (checkPrivileges('binder')) {
        //  fetch(`/api/binders/linked?userId=${id}`, {
        //     method: 'GET',
        //     headers: {
        //       Accept: 'application/json',
        //       'Content-Type': 'application/json'
        //     }
        //   })
        instance({
          url: `/api/binders/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(trailer => {
            // if(trailer.length) {
            setState((prevState) => ({
              ...prevState,
              multiTrailersSelect: trailer,
              isTrailerRecived: true
            }))

            let allData = props.trailers
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (trailer.length) {
                trailer.map(main => {
                  if (item.id && main.entityId && item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(trailerDeviceWiseSelectedList(allData))
            // }
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for garage
      if (checkPrivileges('garage')) {
        instance({
          url: `/api/garages/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          .then(garage => {
            setState((prevState) => ({
              ...prevState,
              multigaragesSelect: garage,
              isgarageRecived: true
            }))

            let allData = props.garages && props.garages.data
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (garage.length) {
                garage.map(main => {
                  if (item.id && main.entityId && item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(garageUsersWiseSelectedList(allData))
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })

      }

      // for area
      if (checkPrivileges('area')) {
        instance({
          url: `/api/areas/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          .then(area => {
            setState((prevState) => ({
              ...prevState,
              multiareasSelect: area,
              isareaRecived: true
            }))
            let allData = props.areas && props.areas.data
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (area.length) {
                area.map(main => {
                  if (item.id && main.entityId && item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(areaUsersWiseSelectedList(allData))
          }).catch(error => {
            console.error('error===', error)

          })
      }


      if (checkPrivileges('service')) {
        instance({
          url: `/api/expensetypes/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id,
            // userId: props.logInUsers.id
          }
        })
          .then(service => {
            setState((prevState) => ({
              ...prevState,
              multiserviceSelect: service,
              isserviceRecived: true
            }))
            let allData = props.services && props.services
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (service.length) {
                service.map(main => {
                  if (item.id && main.entityId && item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(serviceUsersWiseSelectedList(allData))
          }).catch(error => {
            console.error('error===', error)

          })
      }


      //for vehicles
      if (checkPrivileges('vehicle')) {
        instance({
          url: `/api/vehicles/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(vehicle => {
            // if(vehicle.length) {
            setState((prevState) => ({
              ...prevState,
              multiVehiclesSelect: vehicle,
              isVehicleRecived: true
            }))

            let allData = props.vehicles
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (vehicle.length) {
                vehicle.map(main => {
                  if (item.id && main.entityId && item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(vehicleDeviceWiseSelectedList(allData))
            // }
            // })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for users
      if (checkPrivileges('user')) {
        // fetch(`/api/users/get?userId=${id}&all=true&limit=-1`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/users/get`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id,
            all: true,
            limit: -1
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(users => {
            setState((prevState) => ({
              ...prevState,
              mulitUsersSelect: users,
              isUsersRecived: true
            }))
            props.dispatch(getUserUsereWise(users.data))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for geofences
      if (checkPrivileges('geofence')) {
        //   fetch(`/api/geofences/linked?userId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/geofences/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(geofences => {
            setState((prevState) => ({
              ...prevState,
              multiGeofencesSelect: geofences,
              isGroRecived: true
            }))

            let allData = props.geofences
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (geofences.length) {
                geofences.map(main => {
                  if (item.id && main.entityId && item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(geofenceDeviceWiseSelectedList(allData))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      if (checkPrivileges('group')) {
        // fetch(`/api/groups?userId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/groups`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(groups => {
            setState((prevState) => ({
              ...prevState,
              multiGroupsSelect: groups,
              isGroRecived: true
            }))

            let allData = props.groups

            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              if (groups.length) {
                groups.map(main => {
                  if (item.id && main.id && item.id === main.id) {
                    item.check = true
                    main.check = true
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(groupDeviceWiseSelectedList(allData))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      if (checkPrivileges('device')) {
        ////// fetch(`/api/devices/get?userId=${id}&all=true&limit=-1`, {
        // fetch(`/api/devices/linked?userId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/devices/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id,
            all: true,
            limit: -1
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(res => {
            if (res) {
              setState((prevState) => ({
                ...prevState,
                multiDevicesSelect: res,
                isGroRecived: true
              }))

              let allData = props.devices2

              allData.map(item => {
                item.indeterminate = false
                item.check = false
                item.groupAccess = false
                item.check = item.parent = parseInt(id) === item.parentId
                if (res.length) {
                  res.map(main => {
                    if (item.id && main.entityId && item.id === main.entityId) {
                      item.indeterminate = !main.directAccess
                      item.check = main.directAccess
                      item.groupAccess = main.groupAccess
                    }
                    return null
                  })
                  return null
                }
              })
              props.dispatch(deviceUserWiseSelectedList(allData))
            }
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      if (checkPrivileges('rptmpl')) {
        // fetch(`/api/report/templates?userId=${id}&all=true&limit=-1`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/report/templates`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id,
            all: true,
            limit: -1
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(res => {
            if (res) {
              setState((prevState) => ({
                ...prevState,
                multiDevicesSelect: res,
                isGroRecived: true
              }))

              const allData = props.templates.map(item => {
                const data = { ...item.rpTmpl }
                data.check = data.parent = parseInt(id) === item.rpTmpl.parentId
                if (res.length) {
                  res.map(main => {
                    if (item.rpTmpl.id && main.rpTmpl.id && item.rpTmpl.id === main.rpTmpl.id) {
                      data.directAccess = main.rpTmpl.directAccess
                      data.indeterminate = main.rpTmpl.directAccess === false
                      data.check = main.rpTmpl.directAccess
                    }
                    return null
                  })
                }
                return data
              })
              props.dispatch(templateUserWiseSelectedList(allData))
            }
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for Notification
      if (checkPrivileges('notification')) {
        // fetch(`/api/notifications?userId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/notifications`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(notifications => {
            let allData = props.notification
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              if (notifications.length) {
                notifications.map(main => {
                  if (item.id && main.id && item.id === main.id) {
                    item.check = true
                    main.check = true
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(getNotificationDeviceWise(notifications))
            props.dispatch(notificationDeviceWiseSelectedList(allData))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for ComputedAttributes
      if (checkPrivileges('attribute')) {
        // fetch(`/api/attributes/computed/linked?userId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/attributes/computed/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(attributes => {
            let allData = props.computedAttributes
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (attributes && attributes.length) {
                attributes.map(main => {
                  if (item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            props.dispatch(
              computedAttributeDeviceWiseSelectedList(allData)
            )
            setState((prevState) => ({
              ...prevState,
              isComputedAttributesRecived: true
            }))
            instance({
              method: 'GET',
              url: `/api/attributes/computed/get?userId=${props.logInUser.id}&category=elogic&all=true&page=1&limit=-1`,
            })
              .then(response => {
                // if(response && response.status === 200) {
                props.dispatch(getComputedAttributes(response.data))
                // }
              }).catch(error => {
                console.error('error===', error)

                // errorHandler(error, props.dispatch)
              })
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for SavedCommands
      if (checkPrivileges('command')) {
        //   fetch(`/api/commands/linked?userId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/commands/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(commands => {
            let allData = props.commands
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (commands && commands.length) {
                commands.map(main => {
                  if (item.id === main.entityId) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            instance({
              method: 'GET',
              url: `/api/commands/get?userId=${props.logInUser.id}&limit=${-1}&all=${true}`,
            })
              .then(response => {
                // if(response && response.status === 200) {
                props.dispatch(getCommands(response.data))
                // }
              }).catch(error => {
                console.error('error===', error)

                // errorHandler(error, props.dispatch)
              })

            props.dispatch(commandsDeviceWiseSelectedList(allData))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)

            // errorHandler(error, props.dispatch)
          })
      }

      // for Maintenance
      if (checkPrivileges('maintenance')) {
        // fetch(`/api/maintenance/linked?userId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/maintenance/linked`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            userId: id
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(maintenance => {
            let allData = props.maintenance
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              if (maintenance.length) {
                maintenance.map(main => {
                  if (item.id === main.entityId) {
                    item.check = true
                    main.check = true
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
            instance({
              method: 'GET',
              url: `/api/maintenance/get?userId=${props.logInUser.id}&limit=${-1}&all=${true}`,
            })
              .then(response => {
                // if(response && response.status === 200) {
                props.dispatch(getMaintenance(response.data))
                // }
              }).catch(error => {
                console.error('error===', error)

                // errorHandler(error, props.dispatch)
              })
            props.dispatch(getMaintenanceDeviceWise(maintenance))
            props.dispatch(maintenanceDeviceWiseSelectedList(allData))
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => {
            console.error('error===', error)
            // errorHandler(error, props.dispatch)
          })
      }
    }
    setState((prevState) => ({
      ...prevState,
      gOneform: {},
      aOneform: {},
      resFormData: {},
      mulitDriversSelect: '',
      mulitDashboardsSelect: '',
      mulitWidgetsSelect: '',
      mulitTrailersSelect: '',
      mulitUsersSelect: '',
      multiGeofencesSelect: '',
      isDriverRecived: false,
      isDashboardRecived: false,
      isWidgetRecived: false,
      isTrailerRecived: false,
      isgarageRecived: false,
      isareaRecived: false,
      isVehicleRecived: false,
      isGroRecived: false,
      unitParkingAttributesform: {},
      isHaveAccess: 'attributeCreate'
    }))

    let user = ''
    user = props.users.filter(dev => {
      return parseInt(dev.id) === parseInt(id)
    })
    if (user[0]) {
      let { name, uniqueId, groupId, phone, attributes } = user[0]

      let { category } = user[0]
      let f_3 = { category }

      let att = []

      if (attributes) {
        att = Object.keys(attributes).reduce((array, key) => {
          return [...array, [key, attributes[key]]]
        }, [])
      }

      let devicePassword = ''
      let ptime = { 'parking.minIdleTime': 0 },
        pspeed = { 'parking.maxIdleSpeed': 0 }
      att.map(item => {
        // if (item[0] === 'devicePassword') {
        //   devicePassword = item[1]
        // }
        if (item[0] === 'parking.minIdleTime') {
          ptime['parking.minIdleTime'] = item[1] / 60000
        }
        if (item[0] === 'parking.maxIdleSpeed') {
          pspeed['parking.maxIdleSpeed'] = Math.round(item[1] * 1.852)
        }
        return null
      })

      let f_1 = { name, uniqueId, groupId, phone, devicePassword }

      setState((prevState) => ({
        ...prevState,
        gOneform: { ...f_1 },
        aOneform: { ...f_3 },
        unitParkingAttributesform: {
          ...state.unitParkingAttributesform,
          ...pspeed,
          ...ptime
        },
        resFormData: { ...user[0] }
      }))
    }

    return { user }
  }

  // componentWillReceiveProps (n) {
  //   if (n.id) {
  //     setState((prevState)=>({...prevState, viewScreen: 'edit' }))

  //     if (n.hash !== props.hash) {
  //       let hash = locations.indexOf(n.hash)
  //       if (hash !== -1) {
  //         setState((prevState)=>({...prevState, currentTab: n.hash.substring(1, n.hash.length) })
  //       }
  //     }

  //     if (
  //       n.id &&
  //       parseInt(state.currentId) !== parseInt(n.id) &&
  //       n.users.length
  //     ) {
  //       setState(
  //         {
  //           currentId: parseInt(n.id)
  //         },
  //         () => {
  //           getCurrentUser(n.id)
  //           fetchAllVehicles(n.logInUser.id)
  //           n.users.map(user => {
  //             if (parseInt(user.id) === parseInt(state.currentId)) {
  //               setState((prevState)=>({...prevState, selecteditem: user })
  //             }
  //             return null
  //           })
  //           fetchItemGroups()
  //         }
  //       )
  //     }
  //   }

  //   if (n.addUserScreen) {
  //     setState((prevState)=>({...prevState, viewScreen: 'add' })
  //   }
  // }

  // const selectDriver = item => {
  //   setState((prevState)=>({...prevState,
  //     driverObj: item[0],
  //     activeOperation: 'edit',
  //     openDriverModal: true
  //   }))
  // }

  // render () {
  const { currentTab, selecteditem } = state
  const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'

  let selecteduser = [...props.selecteduser]

  selecteduser.map(u => {
    if (props.roles.length) {
      props.roles.map(r => {
        if (r.id === u.roleId) {
          u.roleName = r.name
        }
        return null
      })
    }
    return null
  })

  const selecteddevices = [...props.selecteddevices]

  selecteddevices.map(n => {
    // const dev = n.id
    n.parent = n.parentId === parseInt(props.id)
    return null
  })

  return (
    <Fragment>
      <h4 className='tab-title'>
        {state.resFormData ? state.resFormData.name : 'User'}
      </h4>
      <div className={' main-unit-modal-bg clearfix'} style={{ display: "flex" }}>
        {state.onDeleteConfirmationUnits && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDevices}
            title={props.translate('areYouWantToDelete')}
            children={state.slectedUnits.name}
          />
        )}
        {state.onDeleteConfirmationNotification && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeNotification}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'notification.' + state.notificationForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationGroup && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeGroups}
            title={props.translate('areYouWantToDelete')}
            children={state.groupForm.name}
          />
        )}
        {state.onDeleteConfirmationDriver && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDrivers}
            title={props.translate('areYouWantToDelete')}
            children={state.driverForm.name}
          />
        )}
        {state.onDeleteConfirmationTrailer && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeTrailers}
            title={props.translate('areYouWantToDelete')}
            children={state.trailerForm.name}
          />
        )}
        {state.onDeleteConfirmationGarage && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeGarage}
            title={props.translate('areYouWantToDelete')}
            children={state.garageForm.name}
          />
        )}
        {state.onDeleteConfirmationDashboard && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDashboard}
            title={props.translate('areYouWantToDelete')}
            children={state.dashboardForm.name}
          />
        )}
        {state.onDeleteConfirmationWidget && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeWidget}
            title={props.translate('areYouWantToDelete')}
            children={state.widgetForm.name}
          />
        )}
        {/* start vehicle */}
        {state.onDeleteConfirmationVehicle && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeVehicle}
            title={props.translate('areYouWantToDelete')}
            children={state.vehicleForm.name}
          />
        )}
        {/* end vehicle */}
        {state.onDeleteConfirmationGeofence && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeGeofence}
            title={props.translate('areYouWantToDelete')}
            children={state.geofenceForm.name}
          />
        )}
        {state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeComputedAttributes}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              state.computedAttForm.description
            )}
          />
        )}
        {state.onDeleteConfirmationCommand && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeCommands}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'commands.' + state.savedCommandsForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationMaintenance && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeMaintenance}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'positiont.' + state.maintenanceForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationUser && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeUser}
            title={props.translate('areYouWantToDelete')}
            children={state.userForm.name}
          />
        )}
        {state.onDeleteConfirmationUnits && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDevices}
            title={props.translate('areYouWantToDelete')}
            children={state.slectedUnits.name}
          />
        )}
        {state.onDeleteConfirmationNotification && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeNotification}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'notification.' + state.notificationForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeComputedAttributes}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              state.computedAttForm.description
            )}
          />
        )}
        {state.onDeleteConfirmationCommand && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeCommands}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'commands.' + state.savedCommandsForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationMaintenance && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeMaintenance}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'positiont.' + state.maintenanceForm.type
            )}
          />
        )}
        {/* <div style={{ maxWidth: 380, minWidth: 200 }}> */}
          <Tabs
            orientation={orientation}
            variant='scrollable'
            scrollButtons='auto'
            value={currentTab}
            onChange={tabChange}
            aria-label='Vertical tabs example'
            className='custom-tabs-users'
            textColor='#FFFFFF'
            TabIndicatorProps={{ hidden: true }}
          >
            <Tab
              value='user'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  {props.translate('basic')}{' '}
                  <ArrowRightIcon className='tab_menu_icon' />{' '}
                </span>
              }
              {...a11yProps(0)}
            />
            {checkPrivileges("userChangePassword") && (
              <Tab
                value='changePassword'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('changePassword')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(12)}
              />)}
            {checkPrivileges('dashboard') && (
              <Tab
                value='dashboard'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('dashboard')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(12)}
              />
            )}
            {checkPrivileges('dashboard') && props.widgets.length && (
              <Tab
                value='widget'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('widget')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(13)}
              />
            )}
            {checkPrivileges('device') && (
              <Tab
                value='device'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sensorUnits')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(1)}
              />
            )}
            {checkPrivileges('group') && (
              <Tab
                value='group'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('resources')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(2)}
              />
            )}
            {checkPrivileges('driver') && (
              <Tab
                value='driver'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedDrivers')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(3)}
              />
            )}

            {checkPrivileges('binder') && (
              <Tab
                value='binder'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedBinder')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(9)}
              />
            )}

            {checkPrivileges('rptmpl') && (
              <Tab
                value='template'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('templates')}
                    <ArrowRightIcon className='tab_menu_icon' />
                  </span>
                }
                {...a11yProps(11)}
              />
            )}

            {checkPrivileges('vehicle') && (
              <Tab
                value='vehicle'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedVehicle')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(10)}
              />
            )}
            {checkPrivileges('garage') && (
              <Tab
                value='garage'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedGarage')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(14)}
              />
            )}
            {checkPrivileges('area') && (
              <Tab
                value='area'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedArea')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(15)}
              />
            )}

            {checkPrivileges('service') && (
              <Tab
                value='service'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('service')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps('service')}
              />
            )}

            {/* {
                <Tab
                  value='itemGroup'
                  classes={{
                    root: 'common-tab',
                    selected: 'tab-selected',
                    wrapper: 'tab-wrapper'
                  }}
                  label={
                    <span className='menu_item_text'>
                      {props.translate('itemGroup')}{' '}
                      <ArrowRightIcon className='tab_menu_icon'/>{' '}
                    </span>
                  }
                  {...a11yProps('service')}
                />
              } */}

            {checkPrivileges('user') && (
              <Tab
                value='users'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('access')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(3)}
              />
            )}
            {checkPrivileges('geofence') && (
              <Tab
                value='geofence'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedGeofences')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(4)}
              />
            )}
            {/* temprary hide for release 3.2.1 */}
            {/* {checkPrivileges('notification') && (
                <Tab
                  value='notification'
                  classes={{
                    root: 'common-tab',
                    selected: 'tab-selected',
                    wrapper: 'tab-wrapper'
                  }}
                  label={
                    <span className='menu_item_text'>
                      {props.translate('sharedNotifications')}{' '}
                      <ArrowRightIcon className='tab_menu_icon'/>{' '}
                    </span>
                  }
                  {...a11yProps(5)}
                />
              )} */}
            {checkPrivileges('attribute') && (
              <Tab
                value='attribute'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedComputedAttributes')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(6)}
              />
            )}
            {checkPrivileges('command') && (
              <Tab
                value='command'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedSavedCommand')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(7)}
              />
            )}
            {checkPrivileges('maintenance') && (
              <Tab
                value='maintenance'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {props.translate('sharedMaintenance')}{' '}
                    <ArrowRightIcon className='tab_menu_icon' />{' '}
                  </span>
                }
                {...a11yProps(8)}
              />
            )}
          </Tabs>
        {/* </div> */}


        {currentTab === 'user' && (
          <TabPanel
            value={currentTab}
            index={'user'}
            style={{ width: '100%' }}
          >
            {selecteditem && (
              <UsersModal
                {...props}
                viewScreen={state.viewScreen}
                onCloseModal={onCloseModal}
                activeOperation={'edit'}
                selecteditem={state.resFormData}
                getCurrentUser={getCurrentUser}
                fetchUserLimit={props.extra.fetchUserLimit}
              />
            )}
          </TabPanel>
        )}
        {currentTab === 'changePassword' && checkPrivileges("userChangePassword") && (
          <TabPanel
            value={currentTab}
            index={'changePassword'}
            style={{ width: '100%' }}
          >
            {selecteditem && (
              <UsersModal
                {...props}
                viewScreen={state.viewScreen}
                onCloseModal={onCloseModal}
                activeOperation={'changePassword'}
                selecteditem={state.resFormData}
                getCurrentUser={getCurrentUser}
                fetchUserLimit={props.extra.fetchUserLimit}
              />
            )}
          </TabPanel>
        )}
        {checkPrivileges('dashboard') && currentTab === 'dashboard' && (
          <TabPanel
            value={currentTab}
            index={'dashboard'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={dashboard_Selection}
              themecolors={props.themecolors}
              componentData={props.selecteddashboard}
              showCheckbox={true}
              onDelete={onRemovedDashboard}
              label={props.translate('dashboard')}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              createButton={
                checkPrivileges('dashboardCreate') && (
                  <div style={{ marginLeft: 10 }}>
                    <Button onClick={() => onCreateNewDashboard()}>
                      {props.translate('Create')}
                    </Button>
                  </div>
                )
              }
              onEdit={editDashboard}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'dashboardId',
                  props.id,
                  props.selecteddashboard,
                  props.dashboards,
                  props.dispatch,
                  'dashboard',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('dashboard')
                }
              ]}
              // canUpdate={false}
              canDelete={checkPrivileges('dashboardDelete')}
              canUpdate={checkPrivileges('dashboardUpdate')}
              canRemove={checkPrivileges('userUnlinkDashboard')}
              canAssign={checkPrivileges('userLinkDashboard')}
            />
          </TabPanel>
        )}
        {checkPrivileges('dashboard') && props.widgets.length && currentTab === 'widget' ? (
          <TabPanel
            value={currentTab}
            index={'widget'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={widget_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedwidget}
              showCheckbox={true}
              onDelete={onRemovedWidget}
              label={props.translate('widget')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'gadgetId',
                  props.id,
                  props.selectedwidget,
                  props.widgets,
                  props.dispatch,
                  'widget',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('widget')
                }
              ]}
              canRemove={checkPrivileges('userUnlinkGadget')}
              canAssign={checkPrivileges('userLinkGadget')}
            />
          </TabPanel>
        ) : null}
        {checkPrivileges('device') && currentTab === 'device' && (
          <TabPanel
            value={currentTab}
            index={'device'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={unit_Selection}
              themecolors={props.themecolors}
              componentData={selecteddevices}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              showCheckbox
              showGroupAccess
              onDelete={onRemovedDevice}
              label={props.translate('sensorUnits')}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              canUpdate={false}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sensorUnits')
                }
              ]}
              canDelete={checkPrivileges('deviceDelete')}
              canRemove={checkPrivileges('userUnlinkDevice')}
              canAssign={checkPrivileges('userLinkDevice')}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'deviceId',
                  props.id,
                  props.selecteddevices,
                  props.devices2,
                  props.dispatch,
                  'device',
                  props.translate
                )
              }
            />
          </TabPanel>
        )}
        {checkPrivileges('group') && currentTab === 'group' && (
          <TabPanel
            value={currentTab}
            index={'group'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={group_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedgroups}
              showCheckbox
              onDelete={onRemovedGroup}
              label={props.translate('resources')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('resources')
                }
              ]}
              canDelete={checkPrivileges('groupDelete')}
              canRemove={checkPrivileges('userUnlinkGroup')}
              canAssign={checkPrivileges('userLinkGroup')}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'groupId',
                  props.id,
                  props.selectedgroups,
                  props.groups,
                  props.dispatch,
                  'group',
                  props.translate
                )
              }
            />
          </TabPanel>
        )}
        {checkPrivileges('driver') && currentTab === 'driver' && (
          <TabPanel
            value={currentTab}
            index={'driver'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={driver_Selection}
              themecolors={props.themecolors}
              componentData={props.selecteddriver}
              showCheckbox={true}
              showGroupAccess
              onDelete={onRemovedDriver}
              label={props.translate('sharedDrivers')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'driverId',
                  props.id,
                  props.selecteddriver,
                  props.drivers,
                  props.dispatch,
                  'driver',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedDrivers')
                }
              ]}
              canDelete={checkPrivileges('driverDelete')}
              canRemove={checkPrivileges('userUnlinkDriver')}
              canAssign={checkPrivileges('userLinkDriver')}
            />
          </TabPanel>
        )}
        {checkPrivileges('binder') && currentTab === 'binder' && (
          <TabPanel
            value={currentTab}
            index={'binder'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={trailer_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedtrailer}
              showCheckbox={true}
              showGroupAccess
              onDelete={onRemovedTrailer}
              label={props.translate('sharedBinder')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'binderId',
                  props.id,
                  props.selectedtrailer,
                  props.trailers,
                  props.dispatch,
                  'binder',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedBinder')
                }
              ]}
              canDelete={checkPrivileges('binderDelete')}
              canRemove={checkPrivileges('userUnlinkBinder')}
              canAssign={checkPrivileges('userLinkBinder')}
            />
          </TabPanel>
        )}

        {checkPrivileges('rptmpl') && currentTab === 'template' && (
          <TabPanel
            value={currentTab}
            index={'template'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={template_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedtemplates}
              showCheckbox={true}
              onDelete={onRemovedTemplate}
              label={props.translate('templates')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'rpTmplId',
                  props.id,
                  props.selectedtemplates,
                  props.templates,
                  props.dispatch,
                  'template',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('reportTemplate')
                }
              ]}
              canDelete={checkPrivileges('templateDelete')}
              canRemove={checkPrivileges('userUnlinkRpTmpl')}
              canAssign={checkPrivileges('userLinkRpTmpl')}
            />
          </TabPanel>
        )}

        {checkPrivileges('vehicle') && currentTab === 'vehicle' && (
          <TabPanel
            value={currentTab}
            index={'vehicle'}
            style={{ width: '100%',overflow:"auto" }}
          >
            <CommonTableSection
              component_Selection={vehicle_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedvehicle}
              showCheckbox={true}
              showGroupAccess
              onDelete={onRemovedVehicle}
              label={props.translate('sharedVehicle')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'vehicleId',
                  props.id,
                  props.selectedvehicle,
                  props.vehicles,
                  props.dispatch,
                  'vehicle',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'label',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedVehicle')
                }
              ]}
              canDelete={checkPrivileges('vehicleDelete')}
              canRemove={checkPrivileges('userUnlinkVehicle')}
              canAssign={checkPrivileges('userLinkVehicle')}
            />
          </TabPanel>
        )}
        {/* vahicles end */}
        {/* Garage start */}
        {checkPrivileges('garage') && currentTab === 'garage' && (
          <TabPanel
            value={currentTab}
            index={'garage'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={garage_Selection}
              themecolors={props.themecolors}
              componentData={props.garages?.data || []}
              showCheckbox={true}
              showGroupAccess
              onDelete={onRemovedGarage}
              label={props.translate('sharedGarage')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'garageId',
                  props.id,
                  props.selectedGarages,
                  props.garages?.data || [],
                  props.dispatch,
                  'garage',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedGarage')
                }
              ]}
              canDelete={checkPrivileges('garageDelete')}
              canRemove={checkPrivileges('userUnlinkGarage')}
              canAssign={checkPrivileges('userLinkGarage')}
            />
          </TabPanel>
        )}
        {/* Garage end */}
        {/* Area start */}
        {checkPrivileges('area') && currentTab === 'area' && (
          <TabPanel
            value={currentTab}
            index={'area'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={area_Selection}
              themecolors={props.themecolors}
              componentData={props.areas?.data || []}
              showCheckbox={true}
              label={props.translate('sharedArea')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'areaId',
                  props.id,
                  props.selectedAreas,
                  props.selectedAreas || [],
                  props.dispatch,
                  'area',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedArea')
                }
              ]}
              // canDelete={checkPrivileges('areaDelete')}
              canRemove={checkPrivileges('userUnlinkArea')}
              canAssign={checkPrivileges('userLinkArea')}
            />
          </TabPanel>
        )}
        {/* Area end */}

        {/* Service start */}
        {checkPrivileges('service') && currentTab === 'service' && (
          <TabPanel
            value={currentTab}
            index={'service'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={service_Selection}
              themecolors={props.themecolors}
              componentData={props.services || []}
              showCheckbox={true}
              label={props.translate('service')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'expenseTypeId',
                  props.id,
                  props.selectedServices,
                  props.selectedServices || [],
                  props.dispatch,
                  'service',
                  props.translate
                )
              }
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('service')
                }
              ]}
              // canDelete={checkPrivileges('areaDelete')}
              canRemove={checkPrivileges('userUnlinkExpenseType')}
              canAssign={checkPrivileges('userLinkExpenseType')}
            />
          </TabPanel>
        )}
        {/* Service end */}

        {/* itemGroup start */}
        {/* {currentTab === 'itemGroup' && (
              <TabPanel
              value={currentTab}
              index={'itemGroup'}
              style={{ width: '100%' }}
            >
              <CommonTableSection
                component_Selection={itemGroup_Selection}
                themecolors={props.themecolors}
                componentData={state.allItemGroups.data || []}
                showCheckbox={true}
                label={props.translate('itemGroup')}
                canUpdate={false}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={state.rowsPerPage}
                parent={
                  selecteditem && selecteditem.id
                    ? selecteditem.id
                    : 0
                }
                selectAll={e =>
                  selectAll(
                    e,
                    'userId',
                    'itemGroupId',
                    props.id,
                    state.allItemGroups?.data || [],
                    state.allItemGroups?.data || [],
                    props.dispatch,
                    'itemGroupId',
                    props.translate,
                    ItemGroupCB
                  )
                }
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: props.translate('name')
                  }
                ]}
                // canDelete={checkPrivileges('areaDelete')}
                canRemove={checkPrivileges('userUnlinkExpenseType')}
                canAssign={checkPrivileges('userLinkExpenseType')}
              />
            </TabPanel>
            )} */}
        {/* itemGroup end */}

        {checkPrivileges('user') && currentTab === 'users' && (
          <TabPanel
            value={currentTab}
            index={'users'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              //component_Selection={geofence_Selection}
              themecolors={props.themecolors}
              componentData={selecteduser}
              showCheckbox={false}
              onDelete={onRemovedUser}
              label={props.translate('access')}
              canUpdate={false}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedName')
                },
                {
                  id: 'email',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('userEmail')
                },
                {
                  id: 'roleName',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('roleName')
                },
                {
                  id: 'parentName',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('parentName')
                }
              ]}
              canDelete={checkPrivileges('userDelete')}
            />
          </TabPanel>
        )}
        {checkPrivileges('geofence') && currentTab === 'geofence' && (
          <TabPanel
            value={currentTab}
            index={'geofence'}
            style={{ width: '100%' }}
          >
            <CommonTableSection
              component_Selection={geofence_Selection}
              themecolors={props.themecolors}
              componentData={props.selectedgeofence}
              showCheckbox={true}
              showGroupAccess
              onDelete={onRemovedGeofence}
              label={props.translate('sharedGeofences')}
              canUpdate={false}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'geofenceId',
                  props.id,
                  props.selectedgeofence,
                  props.geofences,
                  props.dispatch,
                  'geofence',
                  props.translate
                )
              }
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: props.translate('sharedGeofences')
                }
              ]}
              canDelete={checkPrivileges('geofenceDelete')}
              canRemove={checkPrivileges('userUnlinkGeofence')}
              canAssign={checkPrivileges('userLinkGeofence')}
            />
          </TabPanel>
        )}
        {checkPrivileges('notification') && currentTab === 'notification' && (
          <TabPanel
            value={currentTab}
            index={'notification'}
            style={{ width: '100%' }}
          >
            <Notification
              onListNotification={onListNotification}
              // onCloseC_Modal={onCloseC_Modal}
              onCreateNewNotification={onCreateNewNotification}
              notification_Selection={notification_Selection}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              addNotification={addNotification}
              notification_handleChange={notification_handleChange}
              notification_handleChange2={notification_handleChange2}
              notificationHandle={notificationHandle}
              calendars={props.calendars}
              notificationDeviceWise={props.notificationDeviceWise}
              themecolors={props.themecolors}
              notificationData={props.selectednotification}
              visableCreateNotification={state.visableNotification}
              notificationForm={state.notificationForm}
              notificationChannel={props.notificationChannel}
              notificationType={props.notificationType}
              translate={props.translate}
              onEdit={editNotification}
              onDelete={onRemovedNotification}
              addButtonStatus={state.addButtonStatus}
              addAttributes={addNotificationAttributes}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'notificationId',
                  props.id,
                  props.selectednotification,
                  props.notification,
                  props.dispatch,
                  'notification',
                  props.translate
                )
              }
              canRemove={checkPrivileges('userUnlinkNotification')}
              canAssign={checkPrivileges('userLinkNotification')}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              attributeChangesMessage={state.attributeChangesMessage}
            />
          </TabPanel>
        )}
        {checkPrivileges('attribute') && currentTab === 'attribute' && (
          <TabPanel
            value={currentTab}
            index='attribute'
            style={{ width: '100%' }}
          >
            <ComputedAttributes
              {...props}
              onListComputedAttributes={onListComputedAttributes}
              translate={props.translate}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              // onCloseC_Modal={onCloseC_Modal}
              themecolors={props.themecolors}
              computedAttributesDeviceWise={
                props.computedAttributesDeviceWise
              }
              onCreateNewAttributes={onCreateNewAttributes}
              C_Att_handleChange={C_Att_handleChange}
              attributeHandle={attributeHandle}
              addComputedAttributes={addComputedAttributes}
              // computedAttributes_handleChange={
              //   computedAttributes_handleChange
              // }
              visableAttribute={state.visableAttribute}
              visableListAttribute={state.visableListAttribute}
              computedAttributesData={props.selectedComputedAttributes}
              computedAttForm={state.computedAttForm}
              computedAttributes_Selection={computedAttributes_Selection}
              onEdit={editComputedAttributes}
              onDelete={onRemovedAttribute}
              addButtonStatus={state.addButtonStatus}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              showGroupAccess
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'attributeId',
                  props.id,
                  props.selectedComputedAttributes,
                  props.computedAttributes,
                  props.dispatch,
                  'attribute',
                  props.translate
                )
              }
              canRemove={checkPrivileges('userUnlinkAttribute')}
              canAssign={checkPrivileges('userLinkAttribute')}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              isLoading={isLoading}
            />
          </TabPanel>
        )}
        {checkPrivileges('command') && currentTab === 'command' && (
          <TabPanel
            value={currentTab}
            index='command'
            style={{ width: '100%' }}
          >
            <SavedCommands
              onListSavedCommands={onListSavedCommands}
              translate={props.translate}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              // onCloseC_Modal={onCloseC_Modal}
              themecolors={props.themecolors}
              commandsDeviceWise={props.commandsDeviceWise}
              onCreateNewSavedCommands={onCreateNewSavedCommands}
              visableAttribute={state.visableSavedCommands}
              addSavedCommands={addSavedCommands}
              savedCommands_handleChange={savedCommands_handleChange}
              savedCommandsHandle={savedCommandsHandle}
              visableListSavedCommands={state.visableListSavedCommands}
              visableSavedCommands={state.visableSavedCommands}
              commandsData={props.selectedcommands}
              savedCommandsForm={state.savedCommandsForm}
              savedCommands_Selection={savedCommands_Selection}
              commandsTypes={props.commandsTypes}
              onEdit={editCommands}
              onDelete={onRemovedCommand}
              addButtonStatus={state.addButtonStatus}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              showGroupAccess
              canRemove={checkPrivileges('userUnlinkCommand')}
              canAssign={checkPrivileges('userLinkCommand')}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'commandId',
                  props.id,
                  props.selectedcommands,
                  props.commands,
                  props.dispatch,
                  'command',
                  props.translate
                )
              }
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              isLoading={isLoading}
            />
          </TabPanel>
        )}
        {checkPrivileges('maintenance') && currentTab === 'maintenance' && (
          <TabPanel
            value={currentTab}
            index='maintenance'
            style={{ width: '100%', minWidth: 0 }}
          >
            <MaintenanceForm
              onListMaintenance={onListMaintenance}
              // onCloseC_Modal={onCloseC_Modal}
              onCreateNewMaintenance={onCreateNewMaintenance}
              maintenance_Selection={maintenance_Selection}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              addMaintenance={addMaintenance}
              maintenance_handleChange={maintenance_handleChange}
              maintenance_handleChange_attr={
                maintenance_handleChange_attr
              }
              maintenancesHandle={maintenancesHandle}
              maintenanceDeviceWise={props.maintenanceDeviceWise}
              themecolors={props.themecolors}
              visableListMaintenance={state.visableListMaintenance}
              maintenanceData={props.selectedMaintenance}
              visableCreateMaintenance={state.visableMaintenance}
              maintenanceForm={state.maintenanceForm}
              translate={props.translate}
              onEdit={editMaintenance}
              onDelete={onRemovedMaintenance}
              addButtonStatus={state.addButtonStatus}
              addAttributes={addMaintenanceAttributes}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              showGroupAccess
              canRemove={checkPrivileges('userUnlinkMaintenance')}
              canAssign={checkPrivileges('userLinkMaintenance')}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'maintenanceId',
                  props.id,
                  props.selectedMaintenance,
                  props.maintenance,
                  props.dispatch,
                  'maintenance',
                  props.translate
                )
              }
              attributeChangesMessage={state.attributeChangesMessage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              isAllowed
            />
          </TabPanel>
        )}
      </div>
    </Fragment>
  )
  // }
}

MainUnitModal.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => ({
  logInUser: state.logInUsers,
  dashboards: state.dashboards?.data,
  widgets: state.widgets?.data,
  devices: state.devices.data,
  devices2: state.devices3,
  groups: state.groups,
  drivers: state.drivers,
  vehicles: state.vehicles,
  trailers: state.trailers,
  geofences: state.geoFence,
  users: state.users,
  themecolors: state.themeColors,
  notification: state.notification,
  notificationType: state.notificationType,
  notificationChannel: state.notificationChannel,
  selectednotification: state.selectednotification,
  selecteddriver: state.selecteddriver,
  selectedtrailer: state.selectedtrailer,
  selecteddashboard: state.selecteddashboard,
  selectedwidget: state.selectedwidget,
  selectedvehicle: state.selectedvehicle,
  selectedgroups: state.selectedgroups,
  selecteduser: state.selecteduseruser,
  selecteddevices: state.selecteddevices,
  selectedgeofence: state.selectedgeofence,
  geofenceDeviceWise: state.geofenceDeviceWise,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  sensorsDeviceWise: state.sensorsDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  selectedtemplates: state.selectedtemplates,
  selectedGarages: state.selectedGarages,
  calendars: state.calendars,
  templates: state.templates,
  category: state.category,
  positions: state.positions,
  roles: state.roles,
  ServerSetting: state.ServerSetting,
  garages: state.garages,
  selectedAreas: state.selectedAreas,
  areas: state.areas,
  selectedServices: state.selectedServices,
  services: state.services
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(
  (withLocalize(MainUnitModal))
)
