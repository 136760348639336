import React, { useState,useEffect } from 'react'
import Button from '../../../Components/common/Button'
import SearchField from '../../../Components/common/SearchField'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import { checkPrivileges,errorHandler } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
import { removedComputedAttribute } from '../../../Actions/ComputedAttributes'
import Notifications from 'react-notification-system-redux'
import instance from '../../../axios'
const Parameters = (props) => {
  
 const search = (e) => {
    props.searchItems(e.target.value)
  }

    const {
      computedAttributes,
      translate,
      closeCreateFrom,
      editParameterForm
    } = props
    let parameter1 = computedAttributes.map(g => {
      if (g.category === 0) {
        return (
          <ListRow
            key={g.id}
            item={g}
            editParameterForm={editParameterForm}
            closeCreateFrom={closeCreateFrom}
            translate={translate}
          />
        )
      }
      return null
    })

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('attribute') && (
            <div>
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <SearchField
                      label={props.translate('sharedSearch')}
                      fullWidth
                      onChange={e => search(e)}
                    />
                  </li>
                  <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                    {checkPrivileges('attributeCreate') && (
                      <Button
                        size='small'
                        onClick={() => props.openCreateFrom()}
                      >
                        {props.translate('Create')}
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
              <ul className='list-view with-padding-right geofence-sidebar-list'>
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: props => {
                      const { elementRef, ...restProps } = props
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id='scrollableDiv'
                        />
                      )
                    }
                  }}
                >
                  {props.itemPagination ? (
                    <InfiniteScroll
                      dataLength={props.itemPagination.items.length}
                      next={props.fetchMoreItems}
                      hasMore={props.itemPagination.hasNext}
                      loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget='scrollableDiv'
                      endMessage={props.endMessage}
                    >
                      {parameter1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
  
}

const ListRow1 = (props) => {

  const [state,setState] = useState({
    onDeleteConfirmation: false
  })

  useEffect(() => {
    return () => {
      setState(prevState => ({ ...prevState, onDeleteConfirmation: false }));
    };
  }, []);
  

 const onDelete = () => {
    if (props.item.id) {
      instance({
        url: `api/attributes/computed/${props.item.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
          props.dispatch(
            Notifications.success({
              message: props.translate('computedAttributeIsDeleted'),
              autoDismiss: 10
            })
          )
          props.dispatch(
            removedComputedAttribute({ id: props.item.id })
          )
          props.closeCreateFrom()
          setState(prevState=>({...prevState, item: {} }))
      }).catch(error => {errorHandler(error, props.dispatch)})
    }
  }

 const onRemovedItem = () => {
    setState(prevState => ({ ...prevState, onDeleteConfirmation: true }));
  };


 const onCancel = () => {
    setState(prevState => ({ ...prevState, onDeleteConfirmation: false }));
  }

    return (
      <li className={'list-row clearfix'} key={props.item.id}>
        <label
          className='checkbox'
          onClick={() => props.editParameterForm(props.item)}
        >
          <span className='unit-name' style={{ fontSize: 12 }}>
            {props.item.description}
          </span>
        </label>
        <div className='callout-actions'>
          {checkPrivileges('attributeDelete') && (
            <span className='action' onClick={onRemovedItem}>
              <DeleteIcon className='material-icons'/>
            </span>
          )}
        </div>

        {state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={onDelete}
            title={props.translate('areYouWantToDelete')}
            children={props.item.description}
          />
        )}
      </li>
    )
}

const mapState = state => ({
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withLocalize(ListRow1))
export default mapStateToProps(Parameters)
