import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import Moment from 'moment'
import { getTrash } from '../../Actions/Trash'
// import { fetchGeofences, fetchDrivers, fetchDevices, fetchUsers } from './../../Actions/Devices/index'
// import { fetchTrailers } from './../../Actions/Trailer'
import Table from '../common/tableWithBackEndPagination'
import { withLocalize } from 'react-localize-redux'
import {
    checkPrivileges,errorHandler, 
    PaginationConfig,} from '../../Helpers'
import instance from '../../axios'
import ConfirmDialoag from '../common/ConfirmDialoag'
import Loader from '../../Layout/Loader'
// import instance from '../../axios'

let source
// class usersModal extends Component {
    // const usersModal = ({ logInUser, themecolors, ServerSetting, trash, dispatch, translate }) => {
const usersModal = ({themecolors,ServerSetting , logInUser,  dispatch  , location, translate}) => {

    const [state, setState] = useState({
        ...PaginationConfig,
        selectedUser: '',
        defaultItemsList: false,
        isSelectedUser: false,
        onDeleteConfirmation: false,
        onRestoreConfirmation: false,
        onRestoreForce: false,
        confirmationText: '',
        isArchived: true,
        tableData: [],
        pagData: '',
        multiChecked: false,
        hasToCheckAll: false,
        pageSize: 20,
        itemSearch:''
    });

    // useEffect(() => {
    //     dispatch(Notifications.removeAll());
    //     getTrashData();
    
    //     // return () => {
    //     //   source.current.cancel();
    //     // };
    //   }, [dispatch, state.isArchived]);
    
    //   useEffect(() => {
    //     if (state.isArchived) {
    //       getTrashData();
    //     }
    //   }, [state.isArchived]);

    //   useEffect(() => {
    //       getTrashData();
    //   }, []);

    
      useEffect(() => {
        getTrashData();
    }, [state.page,state.pageSize, state.itemSearch]);
      
    const getTrashData = () => {

        let {page,pageSize,itemSearch,} = state
        // let {page,pageSize,itemSearch, userTypes,} = state

        if (logInUser && logInUser.id) {
            // setState({
            //     isArchived: false
            // },()=>{   

                let apiCall = `/api/archives/get?page=${page}&limit=${pageSize}&search=${itemSearch}`
                // let apiCall = `/api/archives/get?page=${page}&limit=${pageSize}&search=${itemSearch}${userTypes || ''}`
                source = axios.CancelToken.source()
                // instance(apiCall, {
                //         cancelToken: source.token
                //     })
                    instance({
                        url: apiCall,
                        method: 'GET',
                        cancelToken: source.token,
                        headers: { Accept: 'application/json; odata=verbose' ,}
                      })
                    .then(response => {
                        // if (response&&response.status === 200 && response.data&&response.data.status ==='success') {
                                let udata = response.data
                                dispatch(getTrash(udata))
                                let users = udata.map(u => {
                                    if (u.timeStamp) {
                                        return {
                                            ...u,
                                            timeStamp: logInUser && logInUser.twelveHourFormat
                                                ? Moment(u.timeStamp).format('YYYY-MM-DD hh:mm A')
                                                : Moment(u.timeStamp).format('YYYY-MM-DD HH:mm')
                                        }
                                    } else {
                                        return { ...u }
                                    }
                                })
                                let currentDateTime = Date()
                                let current = Moment(currentDateTime).format('YYYY-MM-DD hh:mm A')
                                const settingExpire = users.map((data) => {
                                    const duration = Moment.duration((Moment(current)).diff(data.timeStamp));
                                    let diff = 30 - duration._data.days
                                    return { ...data, expire: 'Auto delete in ' + diff + ' days', checked: false }
                                })

                                setState(prevState => ({
                                    ...prevState,
                                        tableData: settingExpire,
                                    defaultItemsList: true,
                                    pagData: response&&response
                                }))
                                // setState({
                                //     tableData: settingExpire,
                                //     defaultItemsList: true,
                                //     pagData: response&&response
                                // })
                        // }
                        // else {
                        //     setState({
                        //         pagData: ''
                        //     })
                        // }
                    })
                    .catch(e => {
                        console.warn('Request canceled = ', e);
                    })
            // })
        } }

    

    // componentWillMount() {
    //     this.props.dispatch(Notifications.removeAll())
    //     this.getTrashData(this.props)
    // }
    // componentWillReceiveProps(n) {
    //     if(this.state.isArchived){
    //         this.getTrashData(n)
    //     }
    // }
    // componentWillUnmount() {
    //     if (source) {
    //         source.cancel()
    //     }
    // }





    const  removeItem = (selected) => {
        if (selected && selected.length) {
            setState(prevState => ({
                ...prevState,
                onDeleteConfirmation: true,
                selectedUser: selected[0],
            }))
        } else {
            setState(prevState => ({
                ...prevState,
                onDeleteConfirmation: true,
                directDelete: false,
                selectedUser: selected,
            }))
        }
    }

   const restoreItem = (selected) => {
        if (selected && selected.length) {
            setState(prevState => ({
                ...prevState,   
                selectedUser: selected[0],
                onRestoreConfirmation: true,
            }))
        } else {
            setState(prevState => ({
                    ...prevState,
                    directDelete: false,
                    selectedUser: selected,
                    onRestoreConfirmation: true,
                }))
        }
    }


    const deleteItem = () => {
        let { selectedUser } = state
        if (state.multiChecked) {
            instance({
                url: 'api/archives/deleteall',
                method: 'DELETE',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                }
                })
                            .then(dele => {// eslint-disable-line no-unused-vars
                                setState(prevState => ({
                                    ...prevState,
                                    multiChecked: false
                                }))
                                getTrashData();
                                dispatch(
                                    Notifications.success({
                                        message: translate('allDeletedSuccessfully'),
                                        autoDismiss: 5,
                                        location: location
                                    })
                                )
                            })
                .catch(error => { errorHandler(error, dispatch) })
        }
        else {
            if (selectedUser.id) {
                instance({
                    url: `api/archives/delete/${selectedUser.id}`,
                    method: 'DELETE',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                    }
                    })
                                .then(user => {// eslint-disable-line no-unused-vars
                                    getTrashData();
                                    dispatch(
                                        Notifications.success({
                                            message: translate('deleteSuccessfully'),
                                            autoDismiss: 5,
                                            location: location
                                        })
                                    )
                                })
                                .catch(error => {
                                    errorHandler(error, dispatch, errorCallBack)
                                  })
                    .catch(e => {
                        if (e && e.text) {
                            e.text().then(() => {
                                dispatch(
                                    Notifications.error({
                                        message: 'some thing went wrong',
                                        autoDismiss: 5,
                                        location: location
                                    }) )
                            })}
                    }) }  }
                    setState(prevState => ({
                        ...prevState,
                            onDeleteConfirmation: false,
                        }))
    }


    // // restoring user
   const  restoreSelectedItem = (isForceRestore ) => {
        let { selectedUser } = state
        if (selectedUser && selectedUser.id) {

            instance({
                method: 'PUT',
                url: `api/archives/unarchive/${selectedUser.id}?forceRestore=${isForceRestore}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
              })
            .then(response => {// eslint-disable-line no-unused-vars
                    dispatch(
                        Notifications.success({
                            message: translate('restoreSuccessfully'),
                            autoDismiss: 5,
                            location: location
                        })
                    )
                    getTrashData()     
                    fetchData(selectedUser.itemType)
                })
                .catch((error)=>{ errorHandler(error, dispatch, errorCallBack)})
                setState(prevState => ({
                    ...prevState,
                onRestoreConfirmation: false
            }))
        }
    }


    const errorCallBack = (data) => {
        let { selectedUser } = state
        if(data.statusCode === '422'){
            setState(prevState => ({
                ...prevState,
                onRestoreForce: true,
                confirmationText: `Not a ${selectedUser.itemType} creator ? ${selectedUser.itemType} parent user not found. Please activate ${selectedUser.itemType} parent user first OR click 'RESTORE UNDER MY ACCOUNT' button to restore ${selectedUser.itemType} under your account.`
            }));
        }
    }

    const fetchData = (type) => {
        if(type === 'User'){
            fetchUsers(dispatch,logInUser)
        }else if(type === 'Device'){
            dispatch(fetchDevices(logInUser, true))
        }else if(type === 'Driver'){
            fetchDrivers(dispatch,logInUser) 
        }else if(type === 'Trailer'){
            fetchTrailers(dispatch,logInUser)
        }else if(type === 'Geofence'){
            fetchGeofences(dispatch,logInUser)
        }
    }


     const onCancel = () => {
        setState(prevState => ({
            ...prevState,
            onDeleteConfirmation: false,
            onRestoreConfirmation: false,
            onRestoreForce: false
        }))
    }

    const handleChangeRowsPerPage = value => {
    setState(prevState => ({
        ...prevState,
        itemSearch: '',
        pageSize: value,
        page: 1,
    }))
    }
   const  handleChangePage = value => {
    setState(prevState => ({
        ...prevState,
        page: value,
    }))
    }

     const SearchItem = searchValue => {
        source.cancel()
        setState(prevState => ({
            ...prevState,
                page: 1,
                itemSearch: searchValue
            }))
    }


         const checkFloteNumber =(n)=> {
                return Number(n) === n && n % 1 !== 0
            }
          const   setPagination = user => {
                if (state.pagData) {
                    let nUser = { ...user }
                    let lastPage = nUser.total / state.pageSize
                    let IsFloate = checkFloteNumber(lastPage)
                    delete nUser.data
                    nUser.pageSize = state.pageSize
                    nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
                    return nUser
                }
                return {}
            }
          const   handleAllCheckboxes = e => {
                const hasToCheckAll = !state.hasToCheckAll;
                const updatedArray = state.tableData.map((item) => {
                    item.checked = hasToCheckAll;
                    return item;
                });

                setState(prevState => ({
                    ...prevState,
                    tableData: updatedArray,
                    hasToCheckAll: e.target.checked,
                    multiChecked: true,
                }));
            };

          const   handleCheckboxClick = (clickedItem) => {
                const updatedArray = state.tableData.map((item) => {
                    item.checked = item.id === clickedItem.id ? !item.checked : item.checked;
                    return item;
                });
                setState(prevState => ({
                    ...prevState,
                    ...state,
                    tableData: updatedArray,
                }));
            };
    

        return (
            <div style={{ position: 'relative' }}>
                {state.defaultItemsList ? (<>
                    <Table
                        rows={state.tableData || []}
                        pagination={setPagination(state.pagData)}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage}
                        SearchItem={SearchItem}
                        onDelete={removeItem}
                        onRestore={restoreItem}
                        isEditable
                        restore
                        trashLabel
                        handleCheckboxClick={(e) => handleCheckboxClick(e)}
                        handleAllCheckboxes={(e) => handleAllCheckboxes(e)}
                        searchSelectedId={state && state.searchSelectedId}
                        ServerSetting={ServerSetting}
                        logInUser={logInUser}
                        themecolors={themecolors}
                        isRowIcons={false}
                        searchable
                        isCursoPointer
                        filterDropdown
                        setSerialNo
                        translate={translate}
                        hasAccessOfDelete={checkPrivileges('userDelete')}
                        rowDefinition={rowDefinitionR(translate)}
                    /></>
                ) : (
                    <Loader />
                )}
                {state.onDeleteConfirmation && (
                    <ConfirmDialoag
                        onCancel={onCancel}
                        onOk={deleteItem}
                        title={translate('areYouWantToDelete')}
                        children={state.selectedUser.label}
                    />
                )}
                {state.onRestoreConfirmation && (
                    <ConfirmDialoag
                        onCancel={onCancel}
                        onOk={() => restoreSelectedItem(false)}
                        title={translate('areYouWantToRestore')}
                        children={state.selectedUser.label}
                    />
                )}
                {state.onRestoreForce && (
                    <ConfirmDialoag
                        onCancel={onCancel}
                        onOk={() => restoreSelectedItem(true)}
                        title={translate(state.confirmationText)}
                        OkText={translate('restoreForcefully')}
                        // children={state.selectedUser.label}
                    />
                )}
            </div>)}

const mapState = state => {
    return {
        logInUser: state.logInUsers,
        themecolors: state.themeColors,
        ServerSetting: state.ServerSetting,
        trash: state.trash,
    }
}
const mapStateToProps = connect(mapState)
export const ShowReports = mapStateToProps(withLocalize(usersModal))

const rowDefinitionR = translate => {
    if (localStorage.getItem('adminToken')) {
        return [
            {
                id: 'label',
                numeric: false,
                disablePadding: false,
                label: translate('sharedDescription')
            },
            {
                id: 'itemType',
                numeric: false,
                disablePadding: false,
                label: translate('sharedType')
            },
            {
                id: 'timeStamp',
                numeric: false,
                disablePadding: false,
                label: translate('delete')
            },
            {
                id: 'expire',
                numeric: false,
                disablePadding: false,
                label: translate('userExpirationTime')
            }
        ]
    } else {
        return [
            {
                id: 'label',
                numeric: false,
                disablePadding: false,
                label: translate('sharedDescription')
            },
            {
                id: 'itemType',
                numeric: false,
                disablePadding: false,
                label: translate('sharedType')
            },
            {
                id: 'timeStamp',
                numeric: false,
                disablePadding: false,
                label: translate('delete')
            },
            {
                id: 'expire',
                numeric: false,
                disablePadding: false,
                label: translate('userExpirationTime')
            }
        ]
    }
}
