import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import EmptyState from '../../Components/common/EmptyState'
import Layout from '../../Layout'
import { JobsModal } from '../../Components/Jobs/jobModal'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { getJobs } from '../../Actions/Jobs'
// import axios from 'axios'
import instance from '../../axios'
 
// const CancelToken = axios.CancelToken
let source

// class Jobs extends Component {
  const Jobs = (props) =>{

    const [state, setState] = useState({
      templatesCall: false,
      selecteditem: '',
      isVisable: false,
      showItemDetail: false,
      activeOperation: '',
      selecteditemId: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: props.deviceId,
        hasNext: true,
        searchText: '',
        itemToEdit: null,
      }
    })

    useEffect(() => {
         if (
            props.logInUser &&
            props.logInUser.id &&
            state.initFetch === false
          ) {
            fetchData(
              props.logInUser.id,
              state.currentPage,
              state.pagesize
            )
          }
    }, [])

    useEffect(() => {
      // if(state.searchText){
      //   source = CancelToken.source()
        fetchData(props.logInUser.id, 1, state.pagesize, true)
      // }
      // else if(state.itemPagination || state.currentPage){
      //   props.dispatch(getJobs(o?.concat(n.data)))
      // }
      }, [state.searchText])
    
    useEffect(() => {
      if (props.logInUser && props.logInUser.id && state.initFetch === false) {
        setState((prevState) => ({ ...prevState,
            initFetch: true
          } ))
          fetchData(
            props.logInUser.id,
            state.currentPage,
            state.pagesize
          )
      }
    }, [])

  // componentWillMount () {
  //   if (
  //     props.logInUser &&
  //     props.logInUser.id &&
  //     state.initFetch === false
  //   ) {
  //     fetchData(
  //       props.logInUser.id,
  //       state.currentPage,
  //       state.pagesize
  //     )
  //   }
  // }

  // componentWillUnmount () {
  //   if (source) {
  //     source.cancel()
  //   }
  //   setState({
  //     selecteditem: '',
  //     isVisable: false,
  //     showItemDetail: false,
  //     activeOperation: '',
  //     selecteditemId: '',
  //     initFetch: false,
  //     currentPage: 1,
  //     pagesize: 50,
  //     itemPagination: {
  //       items: [],
  //       total: 0,
  //       currentPage: 0,
  //       currentDevice: props.deviceId,
  //       hasNext: true,
  //       searchText: ''
  //     }
  //   })
  // }
  // componentWillReceiveProps (n) {
  //   if (n.logInUser && n.logInUser.id && state.initFetch === false) {
  //     setState(
  //       {
  //         initFetch: true
  //       },
  //       () => {
  //         fetchData(
  //           n.logInUser.id,
  //           state.currentPage,
  //           state.pagesize
  //         )
  //       }
  //     )
  //   }
  // }

  const fetchMoreItems = () => {
    fetchData(
      props.logInUser.id,
      state.currentPage,
      state.pagesize
    )
  }

  const searchItems = text => {
    if (source) {
      source.cancel()
    }
    setState((prevState) => ({ ...prevState,
        searchText: text
      }
      // ,() => {
      //   source = CancelToken.source()
      //   fetchData(props.logInUser.id, 1, state.pagesize, true)
      // }
    ))
  }

  const removeDuplicates = (n, o) => {
    let items = []
    o?.map(j => {
      let found = n.data.find(e => e.id === j.id)
      if (found) {
        items.push(found)
      }
    })

    if (!items.length) {
      setState((prevState) => ({ ...prevState,
          itemPagination: {
            ...n,
            items: o?.concat(n.data)
          },
          currentPage: n.hasNext ? n.page + 1 : n.page
        }
        // ,
        // () => {
        //   props.dispatch(getJobs(o.concat(n.data)))
        // }
      ))
      props.dispatch(getJobs(o.concat(n.data)))
    }
  }

  const fetchData = (userId, page, perPage, reset) => {
    let searchText = state.searchText

    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }

    let items = state.itemPagination.items
    if (reset) {
      setState((prevState) => ({ ...prevState,
          itemPagination: { ...prevState.itemPagination, items: [] }
        }
      ))
      items = []
    }

    instance({
      method: 'GET',
      url: `/api/jobs/get?userId=${userId}&page=${page}&limit=${perPage}${searchText}`,
      cancelToken: source?.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      // if (response.status === 200) {
        // if (response.data.status === 'success') {
          removeDuplicates(response, items)
        // } else {
          //something went wrong
        // }
      // }
    }).catch(() => {
      // errorHandler(error, props.dispatch)
    })
  }

 const  removedItem = () => {
  setState((prevState) => ({ ...prevState,
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'delete',
      address: ''
    }))
  }


  const editItem = (item) => {
    setState((prevState) => ({ ...prevState, isVisable: false, itemToEdit: item }));
  };

  useEffect(() => {
    if (state.itemToEdit !== null) {
      setState((prevState) => ({
        ...prevState,
        isVisable: true,
        showItemDetail: false,
        selecteditem: {
          ...prevState.itemToEdit,
          attributes: {
            ...prevState.itemToEdit?.attributes,
            controlDays: state.itemToEdit?.attributes?.controlDays || [],
            controlTimeStart: state.itemToEdit?.attributes.controlTimeStart || '00:00',
            controlTimeEnd: state.itemToEdit?.attributes.controlTimeEnd || '23:59',
            geoTimeThreshold: (state.itemToEdit?.attributes.geoTimeThreshold || 60000) / 60000,
          },
        },
        activeOperation: 'edit',
        itemToEdit: null, // Reset the itemToEdit after updating
      }));
    }
  }, [state.itemToEdit]);


  // const editItem = (item) => {
  //   setState((prevState) => ({ ...prevState, isVisable: false }));

  //   setTimeout(() => {
  //     setState((prevState) => ({
  //       ...prevState,
  //       isVisable: true,
  //       showItemDetail: false,
  //       selecteditem: {
  //         ...item,
  //         attributes: {
  //           ...item.attributes,
  //           controlDays: item.attributes.controlDays || [],
  //           controlTimeStart: item.attributes.controlTimeStart || '00:00',
  //           controlTimeEnd: item.attributes.controlTimeEnd || '23:59',
  //           geoTimeThreshold: (item.attributes.geoTimeThreshold || 60000) / 60000,
  //         },
  //       },
  //       activeOperation: 'edit',
  //     }));
  //   }, 0);
  // };

  const addItem = () => {
    props.history.push('/schedules')
    setState((prevState) => ({ ...prevState,
      isVisable: true,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: 'add'
    }))
  }
  const onCloseModal = () => {
    setState((prevState) => ({ ...prevState,
      isVisable: false,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: '',
      onDeleteConfirmation: false
    }))
    props.history.push('/schedules')
  }
  const selecteItem = id => {
    setState((prevState) => ({ ...prevState,
      selecteditemId: id
    }))
    if (props.jobs.length) {
      props.jobs.map(item => {
        if (item.id.toString() === id.toString()) {
          editItem(item)
        }
      })
    }
  }
  const onCancel = () => {
    setState((prevState) => ({ ...prevState,
      onDeleteConfirmation: false
    }))
  }
  const onRemovedItem = item => {
    setState((prevState) => ({ ...prevState,
      selecteditem: item,
      onDeleteConfirmation: true
    }))
  }

  // render () {
    if (
      props.match.params.id &&
      props.jobs &&
      props.jobs.length &&
      props.match.params.id.toString() !==
        state.selecteditemId.toString()
    ) {
      selecteItem(props.match.params.id)
    }
    return (
      <div>
        <Layout
          {...props}
          addJob={addItem}
          editItem={editItem}
          removedItem={onRemovedItem}
          classFromChildren={'has-padding'}
          fetchMoreItems={fetchMoreItems}
          // itemPagination={{ ...state.itemPagination }}
          itemPagination={state.itemPagination }
          searchItems={searchItems}
        >
          <div className='main-content-page'>
            {state.isVisable && (
              <JobsModal
              {...props}
                onCloseModal={onCloseModal}
                selecteditem={state.selecteditem}
                showItemDetail={state.showItemDetail}
                activeOperation={state.activeOperation}
                logInUser={props.logInUser}
              />
            )}
            {state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={onCancel}
                onOk={removedItem}
                title={props.translate('areYouWantToDelete')}
                children={state.selecteditem.description}
              />
            )}
            {!state.isVisable && !state.showItemDetail && (
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  color: props.themecolors.textColor,
                  borderRadius: 6
                }}
              >
                <EmptyState text={props.translate('noScheduleSelected')} />
              </div>
            )}
          </div>
        </Layout>
      </div>
    )
  // }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
  jobs: state.jobs
})

export default connect(mapStateToProps)(withLocalize(Jobs))
