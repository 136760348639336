import React, { useEffect, useRef, useState } from 'react'
// import isEqual from 'react-fast-compare'
// import isEqual from 'react-fast-compare'

import { withLocalize } from 'react-localize-redux'
import L from 'leaflet'
import {
  MapContainer as Map, TileLayer, ZoomControl, Circle,
  Polygon,
  Tooltip,
  Polyline,
} from 'react-leaflet'
import { connect } from 'react-redux'
import moment from 'moment'
// import * as turf from '@turf/turf'
import * as jQuery from 'jquery'
import vis from 'vis'
import Timeline from 'react-visjs-timeline'
import '../../leaflet-plugin-trackplayback/control.trackplayback/control.playback.css'
import '../../leaflet-plugin-trackplayback/control.trackplayback'
import '../../leaflet-plugin-trackplayback/leaflet.trackplayback'
import Loader from './../../Layout/Loader'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Grid, IconButton } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ReplayIcon from '@material-ui/icons/Replay'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import ReactHtmlParser from 'react-html-parser'
// import { MapFilters } from './MapFilters'
import Button from '../common/Button'


const TimemachineMap = (props) => {
  const initializeState = (props) => {
    let serverTimeZoneName = getTimeZoneName(props);
    let serverTimeZone = moment.tz(serverTimeZoneName).utcOffset();
    return {
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      maxZoom: 16,
      reset: 0,
      presistZoom: false,
      MarkerDOM: null,
      fitBounds: {},
      bounds: [],
      loading: true,
      applied: false,
      showControls: true,
      custom: { speed: 13, play: false, tracks: true, points: false },
      selectGroups: {},
      options: initializeTimelineOptions(serverTimeZone),
    };
  }

  const initializeTimelineOptions = (serverTimeZone) => {
    return {
      selectable: false,
      width: '100%',
      stack: false,
      showMajorLabels: true,
      zoomMin: 10000 * 60 * 10,
      type: 'range',
      showTooltips: true,
      tooltip: {
        followMouse: true,
      },
      format: {
        minorLabels: {
          minute: 'HH:mm',
          hour: 'HH',
        },
      },
      moment: function (date) {
        return date ? vis.moment(date).utcOffset(serverTimeZone) : vis.moment().utcOffset(serverTimeZone);
      },
    };
  }

  const getTimeZoneName = (props) => {
    let serverTimeZoneName = 'Asia/Dubai';
    if (props.ServerSetting && props.ServerSetting.attributes && props.ServerSetting.attributes.timezone) {
      serverTimeZoneName = props.ServerSetting.attributes.timezone;
    }
    if (props.logInUser && props.logInUser.attributes && props.logInUser.attributes.timezone) {
      serverTimeZoneName = props.logInUser.attributes.timezone;
    }
    return serverTimeZoneName;
  }

  const [state, setState] = useState(initializeState(props))
  // const [timelineEl, setTimeLineRef] = useState(null)
  const trackplaybackControlRef = useRef(null);
  const playbacktrackRef = useRef(null);
  const timelineElRef = useRef(null);


  const [mapInstance, setMapInstance] = useState(null);

  // const prevPropsRef = useRef();
  const [showMap, setShowMap] = useState(true);


  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loading: false,
      maxZoom: props.mapLayer.maxZoom
    }));
  }, [props.mapLayer.maxZoom]);

  useEffect(() => {
    async function reinitialize() {
      setShowMap(false);
      cleanupPreviousPlayback();
      if (props.timemachineArray && Object.keys(props.timemachineArray).length > 0 && mapInstance) {
        await handleTimemachineArrayUpdate(props.timemachineArray)
        setShowMap(true)
      }
    }
    reinitialize()

    return () => {
      setShowMap(false);
    };
  }, [props.loading,props.timemachineArray, mapInstance]);



  const handleTimemachineArrayUpdate = async (data) => {
    setState(prevState => ({ ...prevState, loading: false }));
    initializePlayback(data);
  }

  const cleanupPreviousPlayback = () => {
    if (trackplaybackControlRef.current?.trackPlayBack?.tracks) {
      trackplaybackControlRef.current._closeBtn.click();
    }
    if (timelineElRef.current?.$el.groupsData?.length) {
      const ids = timelineElRef.current?.$el.groupsData.getIds();
      clickHandler({ group: ids[0] });
    }
    if (mapInstance) {
      resetMapZoomBasedOnBounds();
    }
  };

  const resetMapZoomBasedOnBounds = () => {
    if (props.timemachineBounds?.length && mapInstance) {
      if (!state.presistZoom) {
        mapInstance.setMaxZoom(16);
      }
      mapInstance.fitBounds(props.timemachineBounds);
    }
  }

  const initializePlayback = (data) => {

    const themeDarkColor = props.themecolors?.themeDarkColor || '';
    const menuActiveBackground = props.themecolors?.menuActiveBackground || '';
    if (data?.length && mapInstance) {
      playbacktrackRef.current = L.trackplayback(data, mapInstance, {
        clockOptions: {
          speed: state.custom.speed,
        },
        trackLineOptions: {
          isDraw: true,
          stroke: true,
          color: themeDarkColor,
          weight: 4,
          fill: false,
          opacity: 1,
          optionsMulty: [
            { color: '#b1b1b1' },
            { color: '#06a9f5' },
            { color: '#202020' },
            { color: '#D10B41' },
            { color: '#78c800' },
          ],
        },
        targetOptions: {
          useImg: true,
          imgUrl: '/assets/category/default/arrowtop.svg',
          width: 50,
          height: 50,
        },
        trackPointOptions: {
          isDraw: false,
          useCanvas: false,
          stroke: false,
          color: themeDarkColor,
          fill: true,
          fillColor: menuActiveBackground,
          opacity: 1,
          radius: 6,
          imgUrl: '/assets/category/default/arrowtop.svg',
        },
      });
      initializeTrackPlaybackControl()
      setupPlaybackEvents()
    } else {
      removeTimemachine();
    }
  };


  const setupPlaybackEvents = () => {
    if (playbacktrackRef) {
      playbacktrackRef.current?.on('tick', (e) => {
        if (e?.target?.clock && e.target.getEndTime() === e.time) {
          setState((prevState) => ({
            ...prevState,
            custom: { ...prevState.custom, play: false }
          }))
        }

        if (timelineElRef.current && timelineElRef.current?.$el?.dom && e?.target?.tracks) {
          timelineElRef.current?.$el?.setCustomTime(e.time, 1);
        }
      });
    }
  }

  const initializeTrackPlaybackControl = () => {
    if (playbacktrackRef.current !== null) {
      const control = L.trackplaybackcontrol(playbacktrackRef.current, {
        autoPlay: state.custom.play,
        position: 'bottomright',
      });
      trackplaybackControlRef.current = control
      // trackplaybackControlRef.current=L.trackplaybackcontrol(playbacktrackRef.current, {
      //   autoPlay: state.custom.play,
      //   position: 'bottomright',
      // });

      if (mapInstance) {
        trackplaybackControlRef.current.addTo(mapInstance);
        // mapInstance.addControl(control);
      }
      if (state.custom.play) {
        trackplaybackControlRef.current._play();
      }
      if (state.custom.points) {
        trackplaybackControlRef.current._showTrackPoint({
          target: { checked: true },
        });
      }
      if (state.custom.tracks) {
        trackplaybackControlRef.current._showTrackLine({
          target: { checked: true },
        });
      }
    }
    if (timelineElRef.current?.$el) {
      let serverTimeZoneName = 'Asia/Dubai';
      if (props.ServerSetting?.attributes?.timezone) {
        serverTimeZoneName = props.ServerSetting.attributes.timezone;
      }
      if (props.logInUser?.attributes?.timezone) {
        serverTimeZoneName = props.logInUser.attributes.timezone;
      }
      const serverTimeZone = moment.tz(serverTimeZoneName).utcOffset();
      const options = {
        ...state.options,
        moment: (date) => moment(date).utcOffset(serverTimeZone),
        ...props.options,
      };
      timelineElRef.current?.$el.setOptions(options);
    }

  };

  const removeTimemachine = () => {
    if (timelineElRef.current?.$el?.setGroups) {
      timelineElRef.current?.$el?.setGroups([]);
      timelineElRef.current?.$el?.setItems([]);
    }
    cleanupPreviousPlayback();
    props.onDismiss()
    setState((prevState) => ({
      ...prevState,
      applied: false,
      showControls: false,
      custom: { speed: 13, play: false, tracks: true, points: true }

    }))
  }


    ;


  const onRangeChangeHandler = (e) => {
    if (trackplaybackControlRef.current) {
      trackplaybackControlRef.current._slider.value = e.time
        ? moment.utc(e.time).valueOf()
        : moment.utc().valueOf()
    }
    if (playbacktrackRef.current && playbacktrackRef.current.tracks) {
      playbacktrackRef.current.setCursor(
        e.time ? moment.utc(e.time).valueOf() : moment.utc().valueOf()
      )
    }
  }

  useEffect(() => {
    return () => {
      removeTimemachine()
    }
  }, [])

  useEffect(() => {
    if (mapInstance) {
      mapInstance.setMaxZoom(16)
      if (jQuery('.leaflet-control-layers-selector')) {
        jQuery('.leaflet-control-layers-selector').click()
      }
    }
  }, [props.timemachineArray])

  useEffect(() => {
    if (timelineElRef.current !== null && Object.keys(props.timemachineArray)?.length > 0) {
      timelineElRef.current?.$el.addCustomTime(moment().valueOf(), 1)
    }
  }, [props.timemachineArray])



  const setBoundOptions = () => {
    return {}
  }

  const showTracks = e => {
    trackplaybackControlRef.current?._showTrackLine(e)
    setState((prevState) => ({
      ...prevState,
      custom: { ...prevState.custom, tracks: e.target.checked }
    }))
  }
  const showPoints = e => {
    trackplaybackControlRef.current?._showTrackPoint(e)
    setState((prevState) => ({
      ...prevState,
      custom: { ...prevState.custom, points: e.target.checked }
    }))
  }

  const setSpeed = type => {
    if (type) {
      trackplaybackControlRef.current?._quick()
    } else {
      trackplaybackControlRef.current?._slow()
    }
    setState((prevState) => ({
      ...prevState,
      custom: {
        ...prevState.custom,
        speed: type ? prevState.custom.speed + 1 : prevState.custom.speed - 1
      }
    }))
  }

  const restart = () => {
    setState((prevState) => ({
      ...prevState,
      custom: { ...prevState.custom, play: true }
    }))
    trackplaybackControlRef.current?._restart()
  }


  const playToggle = () => {
    if (trackplaybackControlRef.current) {
      if (trackplaybackControlRef.current._play) {
        trackplaybackControlRef.current?._play()
        setState((prevState) => ({
          ...prevState,
          custom: { ...prevState.custom, play: !prevState.custom.play }
        }))
      }
    }
  }

  const clickHandler = (ev) => {
    if (ev && ev.group) {
      let groups = [...props.groups];
      let newSelectGroups = { ...state.selectGroups };

      groups.map((g) => {
        if (g.id === ev.group) {
          if (!g.className) {
            g.className = 'selected-group';
            newSelectGroups[ev.group] = true;
          } else {
            newSelectGroups[ev.group] = false;
            g.className = '';
          }
        }
      });
      setState((prevState) => ({
        ...prevState,
        selectGroups: newSelectGroups
      }))

      if (timelineElRef.current?.$el) {
        timelineElRef.current?.$el?.setGroups(groups);
      }
    }
  };

  useEffect(() => {
    if (playbacktrackRef.current) {
      const handleTick = (e) => {
        if (e.target && e.target.tracks) {
          let b = [];
          e.target.tracks.forEach((t) => {
            let arr = t.getTrackPointsBeforeTime(e.time);

            if (arr && arr.length) {
              let a = arr.pop();
              let s = t.getStartTrackPoint();
              if (state.selectGroups[s.id]) {
                b.push({ lat: a.lat, lng: a.lng });
              }
            }
          });

          if (b.length && mapInstance && mapInstance._layers && mapInstance._layers.length !== 0) {
            mapInstance.fitBounds(b);
          }
        }
      };

      playbacktrackRef.current.on('tick', handleTick);

      return () => {


        playbacktrackRef.current.off('tick', handleTick);
      };
    }
  }, [state.selectGroups]);


  const onAddLayer = (e, id) => {
    setState((prevState) => ({
      ...prevState,
      fitBounds: { ...prevState.fitBounds, [id]: e.target.getBounds() }
    }))
  }

  // const updateVisible = () => {
  //   setTimeout(() => {
  //     let bounds = []
  //     props.geoFence.map(g => {
  //       if (g.visible === true && state.fitBounds[g.id]) {
  //         bounds.push(state.fitBounds[g.id])
  //       }
  //       return null
  //     })
  //     if (bounds.length) {
  //       mapInstance.fitBounds(bounds)
  //     }
  //   }, 50)
  // }

  const checkZoom = options => {
    if (state.presistZoom) {
      setState((prevState) => ({
        ...prevState,
        maxZoom: options.zoom,
        reset: true
      }))
      mapInstance.setMaxZoom(options?.zoom)
    }
  }

  const resetMapZoom = (zoom) => {
    setState((prevState) => ({
      ...prevState,
      maxZoom: zoom || props.mapLayer.maxZoom,
      reset: false
    }))
    mapInstance?.setMaxZoom(zoom)

  }

  const presistZoom = (e) => {
    setState((prevState) => ({
      ...prevState,
      presistZoom: e.target.checked
    }))
  }

  useEffect(() => {
    if (state.presistZoom) {
      resetMapZoom(props.mapLayer.maxZoom);
    } else {
      resetMapZoom(16);
    }
  }, [state.presistZoom]);

  const position = [state.lat, state.lng]

  const geofences = props.geoFence.length > 0 && props.geoFence.map(obj => {
    if (obj.attributes.type === 'circle' && obj.visible === true) {
      return (
        <Circle
          onAdd={e => onAddLayer(e, obj.id)}
          id={obj.id}
          radius={obj.attributes.radius}
          center={obj.attributes.latlng}
          color={obj.attributes.color}
          key={obj?.id}
        >
          <Tooltip direction={'top'} permanent>
            <div>
              <span>{ReactHtmlParser(obj.name)}</span>
            </div>
          </Tooltip>
        </Circle>
      )
    } else if (obj.attributes.type === 'polygon' && obj.visible === true) {
      return (
        <Polygon
          onAdd={e => onAddLayer(e, obj.id)}
          id={obj.id}
          key={obj.id + '__1'}
          positions={obj.attributes.latlng}
          color={obj.attributes.color}
        >
          <Tooltip direction={'top'} permanent>
            <div>
              <span>{ReactHtmlParser(obj.name)}</span>
            </div>
          </Tooltip>
        </Polygon>
      )
    } else if (obj.attributes.type === 'polyline' && obj.visible === true) {
      return (
        <Polyline
          onAdd={e => onAddLayer(e, obj.id)}
          id={obj.id}
          key={obj.id + '__1'}
          positions={obj.attributes.latlng}
          color={obj.attributes.color}
        >
          <Tooltip direction={'top'} permanent>
            <div>
              <span>{ReactHtmlParser(obj.name)}</span>
            </div>
          </Tooltip>
        </Polyline>
      )
    }

    return ''
  })

  let crs = {}
  if (['yandexMap', 'yandexSat'].includes(props.mapLayer.id)) {
    crs = { crs: L.CRS.EPSG3395 }
  }

  // const MapContent = ({ getMap }) => {
  //   const map = useMap();

  //   React.useEffect(() => {
  //     if (map) {
  //       getMap(map)
  //     }
  //   }, [map, getMap]);

  //   return null;
  // };

  const thisMap = [
    <Map
      boundsOptions={setBoundOptions}
      key={1}
      onZoomAnim={checkZoom}
      center={position}
      zoom={state.zoom}
      zoomControl={false}
      style={{ height: '100%' }}
      maxZoom={state.maxZoom}
      ref={setMapInstance}

      {...crs}
    >
      {
        trackplaybackControlRef.current !== null ? <TileLayer
          {...props.mapLayer}
          maxNativeZoom={state.maxZoom}
          maxZoom={state.maxZoom}
          minZoom={state.minZoom}
        /> : null
      }

      <ZoomControl position={'topright'} />
      {geofences}
      {/* <MapContent getMap={handleMapLoad}/> */}
      {/* <div className='map-filters-wrapper'>
        <MapFilters
          disableBottomLeftFilters
          disablePOIFilters
          updateVisible={updateVisible}
          themecolors={props.themecolors}
          translate={props.translate}
          mapRef={mapInstance}
          showListType={props.showListType}
          listType={props.listType}
          timeMachine
        />
      </div> */}
    </Map>]

  return (
    <React.Fragment>
      {props.loading === true && !showMap ? <Loader /> : <>
        {['osm', ''].includes(props.mapLayer.id) ? thisMap : null}
        {['carto'].includes(props.mapLayer.id) ? thisMap : null}
        {['googleTerrain'].includes(props.mapLayer.id) ? thisMap : null}
        {['gccStreet'].includes(props.mapLayer.id) ? thisMap : null}
        {['googleSatellite'].includes(props.mapLayer.id) ? thisMap : null}
        {['googleHybrid'].includes(props.mapLayer.id) ? thisMap : null}
        {['googleRoad'].includes(props.mapLayer.id) ? thisMap : null}
        {['baidu'].includes(props.mapLayer.id) ? thisMap : null}
        {['yandexMap', 'yandexSat'].includes(props.mapLayer.id)
          ? thisMap
          : null}
        {trackplaybackControlRef.current !== null && props.loading !== true && state.showControls && (
          <div className='trackplaybackcontrol'>
            <Grid container>
              <Grid item className='show-tracks'>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='inherit'
                      id='showTracks'
                      onChange={e => showTracks(e)}
                      checked={state.custom.tracks}
                    />
                  }
                  label={props.translate('showTracks')}
                />
              </Grid>
              <Grid item className='show-points'>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='showPoints'
                      color='inherit'
                      onChange={e => showPoints(e)}
                      checked={state.custom.points}
                    />
                  }
                  label={props.translate('showPoints')}
                />
              </Grid>
              <Grid item className='show-points'>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='presistZoom'
                      color='inherit'
                      onChange={e => presistZoom(e)}
                      checked={state.presistZoom}
                    />
                  }
                  label={props.translate('presistZoom')}
                />
              </Grid>
              {state.reset === true ? <Grid item className='show-points'>
                <Button onClick={() => resetMapZoom(props.mapLayer.maxZoom)}>Reset Map Zoom</Button>
              </Grid> : null}

              <Grid item className='track-control-right speed-controls'>
                <IconButton onClick={() => setSpeed(false)}>
                  <NavigateBeforeIcon />
                </IconButton>
                <div className='speed-text'>
                  {props.translate('sensorsTranslation.speed')}{' '}
                  {'X' + state.custom.speed}
                </div>
                <IconButton onClick={() => setSpeed(true)}>
                  <NavigateNextIcon />
                </IconButton>
              </Grid>
              <Grid item className='playback-controls'>
                <IconButton onClick={() => restart()}>
                  <ReplayIcon />
                </IconButton>
                <IconButton onClick={() => playToggle()}>
                  {!state.custom.play ? <PlayArrowIcon /> : <PauseIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </div>
        )}
        {props.items && props.loading !== true && (
          <Timeline
            key='timeline-1'
            // options={{
            //   height: '500px', // Set the desired height here
            // }}
            ref={timelineElRef}
            options={state.options}
            items={props.items}
            groups={props.groups}
            clickHandler={clickHandler}
            timechangeHandler={onRangeChangeHandler}
          />
        )}
      </>}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  mapLayer: state.mapLayer,
  devices2: state.devices2,
  devices: state.devices.data,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  geoFence: state.geoFence
})

export default connect(mapStateToProps)(withLocalize(TimemachineMap))