import React, { useState ,useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../Components/common/Button'
import SearchField from '../../../Components/common/SearchField'
import { withLocalize } from 'react-localize-redux'
import isEqual from 'react-fast-compare'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
import { removedCommands } from '../../../Actions/Commands'
import Notifications from 'react-notification-system-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import instance from '../../../axios'

const Commands = (props) => {

const [searchCommands,setSearchCommands]=useState("")
const search = (e) => {
 let value=e.target.value||""
setSearchCommands(value) 
};

useEffect(() => {
    props.searchItems(searchCommands);
  
}, [searchCommands]);

    const { commands } = props

    const commands1 = commands?.map(g => (
      <ListRow
        key={g.id}
        item={g}
        editCommandForm={props.editCommandForm}
        modeEmpty={props.modeEmpty}
        setSearchCommands={setSearchCommands}
      />
    ))

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('command') && (
            <div>
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <SearchField
                      label={props.translate('searchCommand')}
                      fullWidth
                      value={searchCommands}
                      onChange={e => search(e)}
                    />
                  </li>
                  <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                    {checkPrivileges('commandCreate') && (
                      <Button
                        size='small'
                        onClick={() => props.openCreateFrom()}
                      >
                        {props.translate('Create')}
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
              <ul className='list-view with-padding-right geofence-sidebar-list'>
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: props => {
                      const { elementRef, ...restProps } = props
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id='scrollableDiv'
                        />
                      )
                    }
                  }}
                >
                  {props.itemPagination ? (
                    <InfiniteScroll
                      dataLength={props.itemPagination.items.length}
                      next={props.fetchMoreItems}
                      hasMore={props.itemPagination.hasNext}
                      loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget='scrollableDiv'
                      endMessage={props.endMessage}
                    >
                      {commands1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
}

const ListRow1 = (props) => {
  const dispatch = useDispatch ();
  const prevProps = useRef(props);
  const prevState = useRef(state);
  const [isLoading,setIsLoading]=useState(false)

  useEffect(() => {
    if (!isEqual(props, prevProps.current) || !isEqual(state, prevState.current)) {
      prevProps.current = props;
      prevState.current = state;
    }
  }, [props, state]);

 const onRemovedItem = () => {
    setState(prevState=>({
      ...prevState,
      onDeleteConfirmation: true
      }))
  }

    const [state,setState] = useState({
      onDeleteConfirmation: false
    })

 const onDelete = () => {
  if(isLoading){
    return;
  }
    if (props.item.id) {
      setIsLoading(true)
      instance({
        url: `api/commands/${props.item.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
      setIsLoading(false)

           dispatch(removedCommands({ id: props.item.id }))
           dispatch(
            Notifications.success({
              message: props.translate('savedCommandsIsDeleted'),
              autoDismiss: 10
            })
          )
        
          props.modeEmpty()
          props.setSearchCommands("")
    
         setState(prevState=>({
          ...prevState,
           item: {} 
          }))

      }).catch(error => {
      setIsLoading(false)
        
        errorHandler(error, dispatch)}
      )
    }
  }


 const onCancel = () => {
    setState(prevState=>({
      ...prevState,
      onDeleteConfirmation: false
      }))
  }

    return (
      <li className={'list-row clearfix'} key={props.item.id}>
        <label
          className='checkbox'
          onClick={() => props.editCommandForm(props.item)}
        >
          <span className='unit-name' style={{ fontSize: 12 }}>
            {props.item.description}
          </span>
        </label>
        <div className='callout-actions'>
          {checkPrivileges('commandDelete') && (
            <span className='action' onClick={onRemovedItem}>
              <DeleteIcon className='material-icons'/>
            </span>
          )}
        </div>
     

        {state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={onDelete}
            title={props.translate('areYouWantToDelete')}
            children={props.item.description}
            loading={isLoading}
          />
        )}
      </li>
    )
}


export const ListRow = (withLocalize(ListRow1))
export default Commands;
