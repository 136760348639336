import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import { markerEnable, applyDevicesFilter, setTrackId, resetBounds, updateDeviceVisible } from './../../Actions/Devices'
// import Icon from '@mui/material/Icon'
import './MapFilters.scss'
import sheet from './icons/sheet.svg'
import { Callout, CalloutPOI } from './../Features/Callout'
import OptionMenu from './MapOptionMenu'
import OptionGroupMenu from './MapOptionGroupMenu'
import { checkPrivileges } from '../../Helpers'
import Tooltip from '@mui/material/Tooltip'
import Style from 'style-it'
import PaginationMenu from './PaginationMenu'
import TrafficMenu from './TrafficMenu'
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { ReactComponent as PowerCut } from '../../assets/monitoring/powerCut.svg'
import { ReactComponent as WorkOff } from '../../assets/monitoring/work-off.svg'

const mapStateToProps = (state) => {
  return {
    notificationSettings: state.notificationSettings,
    trackId: state.trackId
  }
}

let notificationSettings = []

class MapFilter1 extends React.PureComponent {
  constructor (props) {
    super(props)
    this.openCallout = this.openCallout.bind(this)
    this.markerEnabled = this.markerEnabled.bind(this)
    this.markerDisabled = this.markerDisabled.bind(this)
    this.tiggerEvent = this.tiggerEvent.bind(this)
    this.setCallout = this.setCallout.bind(this)
    this.addShape = this.addShape.bind(this)
    this.state = {
      callout: 0,
      setCallout: this.setCallout.bind(this)
    }
  }

  markerEnabled () {
    this.props.dispatch(markerEnable({ enable: true }))
  }

  markerDisabled () {
    this.props.dispatch(markerEnable({ enable: false }))
  }

  componentWillReceiveProps (n) {
    if (n.poiForm && n.poiForm.description && this.state.callout !== 2) {
      if (!isEqual(n.poiForm.description, this.props.poiForm.description)) {
        this.setState({
          callout: 2
        })
      }
    }
  }

  tiggerEvent (event) {
    return event || false
  }

  setCallout (id) {
    this.setState({
      callout: id !== this.state.callout ? id : 0
    })
  }

  openCallout (id) {
    this.setCallout(id)
  }

  addShape (type) {
    let element
    if (type === 'polygon') {
      element = document.getElementById('addMeasurePolygon')
    } else if (type === 'polyline') {
      element = document.getElementById('addMeasurePolyline')
    }
    if (element) {
      element.click()
    }
  }

  render () {
    const emptyLinks = null

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
					.filter-actions-list > li:hover > a {
						color: ${this.props.themecolors&&this.props.themecolors.themeDarkColor}
					},
					.sub-menu .active a {
						color: ${this.props.themecolors&&this.props.themecolors.themeDarkColor}
					},
					.sub-menu label:hover{
						color: ${this.props.themecolors&&this.props.themecolors.themeDarkColor}
					},
					.sub-menu a:hover {
						color: ${this.props.themecolors&&this.props.themecolors.themeDarkColor}
          }
          .map-filters-bar {
            color: #222;
            background: #fff;
          }
				`}
        <div className='map-filters-bar'>
          <div className='inner-map-filter-bar clearfix'>
            {!this.props.disableBottomLeftFilters && (<div className='map-actions-left pull-left'>
              <ul className='filter-actions-list'>
                <li className='has-menu'>
                  <a href={emptyLinks}>
                    <LinearScaleIcon/>
                  </a>
                  <ul className='sub-menu left-top'>
                    <li>
                      <a
                        href={emptyLinks}
                        onClick={() => this.addShape('polygon')}
                      >
                        {this.props.translate('areaCalculator')}
                      </a>
                    </li>
                    <li>
                      <a
                        href={emptyLinks}
                        onClick={() => this.addShape('polyline')}
                      >
                        {this.props.translate('measureDistance')}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>)}
            {!this.props.disableBottomRightFilters && (
              <div className='map-actions-right pull-right'>
                <ul className='filter-actions-list'>
                  <li className='has-callout'>
                    <a
                      href={emptyLinks}
                      title={this.props.translate('sharedGeofence')}
                      onClick={this.openCallout.bind(this, 1)}
                    >
                      <span>
                        <svg fill='currentColor' width={24} height={24}>
                          <use xlinkHref={`${sheet}#icon-geofence`} />
                        </svg>
                      </span>
                    </a>
                    {this.state.callout === 1 ? (
                      <>
                      <Callout
                        key={1}
                        className={
                          this.state.callout === 1 ? 'callout-open' : ''
                        }
                        {...this.props}
                        {...this.state}
                        title={this.props.monitoring ? "test"  : "test 2"}
                        // title={this.props.translate('sharedGeofence ')}
                        tiggerEvent={this.tiggerEvent}
                      />
                      </>
                    ) : null}
                  </li>
                  {!this.props.disablePOIFilters &&
                  <li className='has-callout'>
                    <a
                      href={emptyLinks}
                      title={this.props.translate('POI')}
                      onClick={this.openCallout.bind(this, 2)}
                    >
                      <PinDropIcon/>
                    </a>
                    {this.state.callout === 2 ? (
                      <CalloutPOI
                        key={2}
                        className={
                          this.state.callout === 2 ? 'callout-open' : ''
                        }
                        {...this.props}
                        {...this.state}
                        title={this.props.translate('POI')}
                        tiggerEvent={this.tiggerEvent}
                      />
                    ) : null}
                  </li>}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Style>
    )
  }
}

class MapFilter2 extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = { open: true }
    this.showSelectedUnits = this.showSelectedUnits.bind(this)
  }

  showSelectedUnits (data) {
    if(this.props.trackId) {
      this.props.dispatch(
        updateDeviceVisible({ checked: false, id: this.props.trackId })
      )
      this.props.dispatch(resetBounds())
      this.props.dispatch(setTrackId(0))
      
      setTimeout(() => {
        this.applyBound(data);
      }, 0)
    }
    else {
      this.applyBound(data);
    }
  }

  applyBound = (data) => {
    const list = [...this.props.filterList]
    let newList = []
    if (list.includes(data)) {
      list.map(l => (l !== data ? newList.push(l) : null))
    } else {
      newList = [...list].concat(data)
    }
    this.props.dispatch(applyDevicesFilter(newList))
  }

  componentWillReceiveProps (nextProps) {
    if (
      nextProps.logInUser &&
      nextProps.logInUser.attributes &&
      nextProps.logInUser.attributes.notificationSettings
    ) { 
      notificationSettings = []
      nextProps.notificationSettings.map(o => {
        if (nextProps.logInUser.attributes.notificationSettings) {
          nextProps.logInUser.attributes.notificationSettings.map(n => {
            if (o.id === n.id) {
              o.checked = n.checked
              notificationSettings.push(o)
            }
            return null
          })
        }

        return null
      })
    } else {
      notificationSettings = nextProps.notificationSettings
    }
  }

  render () {
    const emptyLinks = null
    const buttons = (
      <>
        <li className='has-menu'>
          <OptionGroupMenu themecolors={this.props.themecolors} />
        </li>
        <li className='has-menu'>
          <OptionMenu themecolors={this.props.themecolors} />
        </li>
        <li className='has-menu'>
          <PaginationMenu
            updateLayer={this.props.updateLayer}
            themecolors={this.props.themecolors}
          />
        </li>
          {checkPrivileges('trafficLayer') ? 
          <TrafficMenu
              themecolors={this.props.themecolors}
              selectedMap={this.props&&this.props.mapLayer&&this.props.mapLayer.id}
            /> : null }
      </>
    )
    return (
      <Style>
        {`
          .material-icons {vertical-align: middle; color: inherit}
          .map-filters-bar-vertical .filter-actions-list li.active > a {
            background-color: ${this.props.themecolors&&this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors&&this.props.themecolors.themeLightInverse}
          }
          .map-filters-bar-vertical { color: ${this.props.themecolors&&this.props.themecolors.themeInverse}; background-color: ${this.props.themecolors&&this.props.themecolors.themeDarkColor}}
				`}

        <div
          className={
            'map-filters-bar-vertical clearfix ' +
            (this.state.open ? 'collapse-open' : '')
          }
        >
          {checkPrivileges('device') && (
            <div className='map-actions-left'>
              <ul className='filter-actions-list'>
                {notificationSettings.map((item, index) => {
                  if (item.checked) {
                    const key = item.notificationKey
                    return (
                      <DrawIcon
                        key={index}
                        item={item}
                        showSelectedUnits={this.showSelectedUnits}
                        emptyLinks={emptyLinks}
                        themecolors={this.props.themecolors}
                        translate={this.props.translate}
                        isActive={this.props.filterList.includes(key)}
                        count={
                          this.props.allNotifications[key] &&
                          this.props.allNotifications[key].length
                            ? this.props.allNotifications[key].length
                            : 0
                        }
                      />
                    )
                  } else {
                    return null
                  }
                })}

                {buttons}
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
}

class DrawIcon extends React.PureComponent {
  render () {
    const { item, themecolors, emptyLinks, count, isActive } = this.props
    return (
      (item.label === "workFlowOff" || item.label==='workFlowOn') ?
      <>
      {checkPrivileges("workflow") ? 
      <>
        <Tooltip title={this.props.translate(item.label)} placement='top'>
          <li className={isActive ? 'active' : ''}>
            <a
              href={emptyLinks}
              title=''
              className={item.class}
              onClick={() => this.props.showSelectedUnits(item.notificationKey)}
            >
              { <span className={'rel-icons ' + item.notificationKey}>
                      {item.label === "workFlowOff" ? 
                        <WorkOff fill="gray" width={22} height={22} />
                      :
                      <WorkOff fill="#28a745" width={22} height={22} />
                      }
                  </span> }
              <span
                className='counter'
                style={{
                  color: themecolors.themeInverse
                }}
              >
                {count}
              </span>
            </a>
          </li>
        </Tooltip>
        </>
        :null}
      </>:
      <Tooltip title={this.props.translate(item.label)} placement='top'>
        <li className={isActive ? 'active' : ''}>
          <a
            href={emptyLinks}
            title=''
            className={item.class}
            onClick={() => this.props.showSelectedUnits(item.notificationKey)}
          >
            <span className={'rel-icons ' + item.notificationKey}>
             {item.icon === "powerCut" ? 
              <PowerCut fill="#fff" width={22} height={22} />
            : <>
              <svg fill='currentColor' width={24} height={24}>
                <use xlinkHref={`${sheet}#icon-${item.icon}`} />
              </svg>
            </>
            }
            </span>
            <span
              className='counter'
              style={{
                color: themecolors.themeInverse
              }}
            >
              {count}
            </span>
          </a>
        </li>
      </Tooltip>
    )
  }
}

export const MapFilters = MapFilter1
export const MapFiltersVerticle = connect(mapStateToProps)(MapFilter2)
