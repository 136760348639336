import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import moment from 'moment'
import Style from 'style-it'
import { setAttributeFormat } from '../../Helpers'
import { ReactComponent as FileCopyIcon } from './../../assets/monitoring/copy.svg'
import Notifications from 'react-notification-system-redux'
import Scrollbar from 'react-scrollbars-custom'

// const styles = theme => ({
//   root: {
//     flexGrow: 1
//   },
//   paper: {
//     position: 'relative',
//     background: 'none',
//     color: 'inherit'
//   },
//   nested: {
//     paddingLeft: theme.spacing(1) * 4
//   }
// })

// class waslShortList extends React.Component {
const waslShortList = (props) => {

  // constructor (props) {
  //   super(props)
  //   state = {
  //     selectedItem: []
  //   }
  // }

  const copyToClipboard = obj => {

    const el = document.createElement('textarea')
    el.value = `${obj.latitude},${obj.longitude}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    props.dispatch(
      Notifications.success({
        message: 'Copied to clipboard!',
        autoDismiss: 10
      })
    )
  }

    const { classes } = props
    let vehicleHookData = props.vehicleHookData
    return (
      <Style>
        {`
      .material-icons {vertical-align: middle; color: inherit}
      .row-label {
        min-width: inherit;
        display:table-cell;
        width: 105px;
        vertical-align: top
      }
      p {
        margin: 8px 0;
        word-break: break-word;
        display: table;
      }
      .row-label-inline {
        margin-right: 8px !important;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
      }
      .license-card {}
      .license-card-img {
        float: left;
        width: 100px;
        text-align: center;
      }
      .license-card-detail {
        margin-left: 110px;

      }
      .attribute-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: start;
        flex-wrap: wrap;
      }
      .attribute-list li {
        background: ${props.themecolors.backgroundColor};
        color: ${props.themecolors.textColor};
        padding: 8px 16px;
        border-radius: 6px;
        float: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .attribute-list .row-label-inline,
      .attribute-list .row-label,
      .attribute-list .row-value {
        font-size: 12px;
        margin: 4px 0
      }
      .attribute-list .row-label-inline {
        margin-right: auto
      }
      .trackeres-list {
        list-style: none;
        margin: 0 0 0 -16px;
        padding: 0;
        text-align: center;
      }
      .trackeres-list li {
        box-sizing: border-box;
        background: #fff;
        width: calc(50% - 16px);
        float: left;
        margin-left: 16px;
        margin-bottom: 16px;
        padding: 16px 16px 30px;
      }
      .trackeres-list .row-label {
        color: #333;
        font-size: 16px;
        font-weight: 700;
        display: block;
      }
      .row-image {
        width: 120px;
        height: 120px;
      }
      @media (max-width: 767px) {
        .license-card-img {
          float: none;
          width: auto;
        }
        .license-card-detail {
          margin-left: 0;
        }
        
      }
      `}
      {props.vehicleHookData ? (
        <div className={classes.root +  ' driver-modal-fixed'} style={{
              background: props.themecolors.backgroundColor,
              color: props.themecolors.textColor
            }}>
        <Scrollbar disableTracksWidthCompensation contentProps={{style: {padding: 16}}}>
          <Paper
            className={classes.paper}
            elevation={0}
          >
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                  {
                    <div
                      style={{
                        margin: '5px',
                        marginBottom: '15px',
                        display: 'flex',
                        width: '100%'
                      }}
                    >
                      <div style={{ paddingLeft: 15, flex: 1, minWidth: 0 }}>
                        <h3 style={{ margin: '0 0 5px' }}>{props?.sequenceNumber || ''}</h3>
                        <CloseIcon
                          className={'feature-close-button'}
                          onClick={props.handleClose}
                        />
                      </div>
                    </div>
                  }
                </Grid>
                {vehicleHookData&&vehicleHookData.plate ? ( <Grid item xs={12} style={{ position: 'relative', paddingTop: 0 }}>
                  
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <div>
                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                    {'Vehicle IMEI'}
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                                    {vehicleHookData&&vehicleHookData.vehicleIMEI}
                              </span>
                            </div>

                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                 {'Status'}
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                                {vehicleHookData.vehicleLocationInformation.vehicleStatus}
                              </span>
                            </div>
                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                    {'Received Time'}
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                              {(vehicleHookData.vehicleLocationInformation &&
                                moment(vehicleHookData.vehicleLocationInformation.receivedTime).format(
                                  'DD-MM-YYYY'
                                )) ||
                              null}
                              </span>
                            </div>
                            <div style={{ display: 'flex', paddingBottom: 16 }}>
                              <strong>
                                    {'Actual Time'}
                              </strong>{' '}
                              &nbsp;
                              <span style={{ marginLeft: 10 }}>
                              {(vehicleHookData.vehicleLocationInformation &&
                                moment(vehicleHookData.vehicleLocationInformation.actualTime).format(
                                  'DD-MM-YYYY'
                                )) ||
                              null}
                              </span>
                            </div>
                        </div>
                      </Grid>
                    </Grid>
                </Grid> ) : null}

                <Grid item xs={12} style={{paddingTop: 0}}>
                  {vehicleHookData&&vehicleHookData.vehicleLocationInformation && <>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        margin: '15px 0'
                      }}
                      className='with-border'
                    >
                      {props.translate('Vehicle Location')}
                    </p>
                    <p>
                        <span className='row-label'>
                          {props.translate('settingsCoordinates')}
                        </span>
                        <span className='row-value'>
                          
                          {setAttributeFormat('latitude', vehicleHookData.vehicleLocationInformation.latitude)},
                          {setAttributeFormat('longitude', vehicleHookData.vehicleLocationInformation.longitude)}
                          {
                            <button
                              onClick={() => copyToClipboard(vehicleHookData.vehicleLocationInformation)}
                              className='button-to-normal'
                            >
                              <FileCopyIcon
                                fill='currentColor'
                                width='14'
                                height='14'
                              />
                            </button>
                          }
                        </span>
                    </p>
                    </> }
                </Grid>
              </Grid>
          </Paper>
          </Scrollbar>
            </div>) :null}
      </Style>
    )
}

waslShortList.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => {
  return {
    themecolors: state.themeColors
  }
}
const mapStateToProps = connect(mapState)
export const WaslShortList = mapStateToProps(
  withLocalize(waslShortList)
)
