import React, { 
  // useEffect,
   useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  // getGroups,
   removeGroup } from '../../Actions/Groups';
import { checkPrivileges, errorHandler } from '../../Helpers';
import ConfirmDialoag from '../common/ConfirmDialoag'
import Style from 'style-it';
// import axios from 'axios';
import Notifications from 'react-notification-system-redux';
import instance from '../../axios';
import { NavLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import SupervisedUserCircleIcon from '@mui/icons-material/DeviceHub';


// const CancelToken = axios.CancelToken;
// let source;

const GroupsShortList = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();

  // const logInUser = useSelector((state) => state.logInUsers);
  const searchQuery = useSelector((state) => state.searchRecently ? state.searchRecently.toLowerCase() : '');
  const groups = useSelector((state) =>
    (state.groups || []).filter((item) =>
      item.name?.toLowerCase().includes(searchQuery)
    )
  );

  const deleteItem = () => {
    if (selectedItem && selectedItem.id) {
      instance({
        url: `/api/groups/${selectedItem.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...selectedItem
        }
      })
        .then(() => {
          dispatch(removeGroup(selectedItem));
          dispatch(
            Notifications.success({
              message: props.translate('resourceDeleted'),
              autoDismiss: 10
            })
          );
          setIsDeleteConfirmation(false);
          props.history.push("/resources");
          props.closeScreen();
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
    }
  };

  const onCancel = () => {
    setIsDeleteConfirmation(false);
  };

  const onRemovedGroup = (item) => {
    setIsDeleteConfirmation(true);
    setSelectedItem(item);
  };

  // const fetchGroups = (id) => {
  //   if (source) {
  //     source.cancel();
  //   }
  //   source = CancelToken.source();
  //   if (id) {
  //     instance({
  //       method: "GET",
  //       url: `/api/groups?userId=${id}`,
  //       cancelToken: source.token
  //     })
  //       .then((response) => {
  //         dispatch(getGroups(response));
  //       })
  //       .catch((error) => {
  //         errorHandler(error, dispatch);
  //       });
  //   }
  // };

  // useEffect(() => {
  //   if (logInUser) {
  //     fetchGroups(logInUser.id);
  //   }
  //   return () => {
  //     if (source) {
  //       source.cancel();
  //     }
  //   };
  // }, [logInUser]);

  return (
    <Style>
      {`
        .material-icons {vertical-align: middle; color: inherit}
      `}
      <div>
        {groups.length > 0 && (
          <div className='individual-view'>
            <ul className='list-view'>
              {groups.map((group) => (
                <ListRow
                  key={group.id}
                  data={group}
                  deleteItem={onRemovedGroup}
                  editItem={props.editItem}
                  translate={props.translate}
                />
              ))}
            </ul>
          </div>
        )}
        {isDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={deleteItem}
            title={props.translate('areYouWantToDelete')}
            children={selectedItem.name}
          />
        )}
      </div>
    </Style>
  );
};

const ListRow = (props) => {
  return (
    <li>
      <NavLink
        activeClassName='active'
        to={'/resources/' + props.data.id}
        className={'clearfix list-row'}
      >
        <label className='checkbox'>
          <span
            className='unit-name'
            style={{ minWidth: 220, maxWidth: 220, marginLeft: 0 }}
          >
            <span
              style={{
                marginRight: '10px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}
            >
              <SupervisedUserCircleIcon style={{ fontSize: 16, verticalAlign: 'middle' }} />
            </span>
            {props.data.name} {props.data.uniqueId}
          </span>
        </label>
        <div className='pull-right'>
          {checkPrivileges('groupDelete') && (
            <span
              className='action-item'
              title={props.translate('delete')}
              onClick={() => props.deleteItem(props.data)}
            >
              <DeleteIcon style={{ fontSize: 16 }} />
            </span>
          )}
        </div>
      </NavLink>
    </li>
  );
};

export default GroupsShortList;
