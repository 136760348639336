import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Panel from './Panel'
import GenericReport from './../Reports/GenericReport'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
// import ReactTable from 'react-table'
import moment from 'moment'
import isEqual from 'react-fast-compare'
import pieChartDefault from './Charts/pieChartDefault'
import lineStokeDefault from './Charts/lineChartDefault'
import 'highcharts'
import ReactHighcharts from 'react-highcharts'
import { getDateTimeFormat } from '../../Helpers'
import { ReactBaseTable } from './ReactBaseTable'
const TheadThStyle = {
  fontSize: 13,
  fontWeight: 700,
  padding: 5
  //padding: '10px 14px'
}
const TheadTdStyle = {
  fontSize: 13,
  fontWeight: 400,
  padding:5
  //padding: '10px 14px'
}

function TabContainer (props) {
  return (
    <Typography component='div' className='tab-container-outer'>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = () => ({
  root: {
    flexGrow: 1,
    width: '100%',
    borderRadius: 6
  },
  tab: {
    background: '#ebebeb',
    marginLeft: 5,
    borderRadius: '4px 4px 0 0'
  },
  indicator: {
    backgroundColor: '#333'
  }
})

let TabsContainers
class ScrollableTabsButtonAuto extends React.Component {
  state = {
    value: 0,
    nested: 0,
    mainColums: [],
    summeryColumns: [],
    disableFilter: true
  }

  toggleFilter = (event, callback, value) => {
    event.stopPropagation();
    this.setState({disableFilter: !this.state.disableFilter}, () => {
      callback && callback(value);
    })
  }

  handleChange = (event, value) => {
    this.setState({ value })
    if (this.props.tabs) {
      TabsContainers = this.props.tabs.map((tab, index) => {
        if (index === value) {
          return this.drawContainer(
            tab,
            index,
            this.props.reportType,
            this.props.heading
          )
        } else {
          return null
        }
      })
    }
  }

  handleChange2 = (event, value) => {
    this.setState({ nested: value })
  }

  defineColumns (col) {
    const columns = [];
    col.columns.map(c => {
      columns.push({
        Header: c.h,
        id: c.f,
        // width: ReportColumns[c.f] ? ReportColumns[c.f].width : 100,
        accessor: row => {
          let data = row[c.f] ? row[c.f].v : "";
          switch (c.f) {
            case 'distance':
              data = Math.round(row[c.f].v * 100) / 100
            break
            case 'entranceTime':
            case 'exitTime':
            case 'startTime':
            case 'endTime':
            case 'eventTime':
            case 'serverTime':
              data = row[c.f] && row[c.f].r
                ? moment(row[c.f].r).format(getDateTimeFormat())
                : null
              break
              case 'attributes':
                data =
                  typeof row[c.f].v === 'object'
                    ? JSON.stringify(row[c.f].v)
                    : row[c.f].v
                break
              case 'sensorValue':
                data =
                  typeof row[c.f].v === 'boolean'
                    ? row[c.f].v === true
                      ? 'ON'
                      : 'OFF'
                    : row[c.f].v
                break

            default:
              break
          
          }
          return data
        }
      })
    })
    return columns;
  }

  drawTable = (table, idx, tab) => {
    if (table.type === 'pieChart') {
      let piseries = []
      table.value.map(v => {
        piseries.push({ name: v.h, y: v.v, color: v.c })
        return null
      })
      let pieData = [
        {
          colorByPoint: true,
          data: piseries
        }
      ]

      let pieChart = {
        config: pieChartDefault().initializeWithOptions({
          chart: {
            height: '240px'
          },
          title: {
            text: table.head
          },
          series: pieData,
          legend: {
            enabled: false
          },
          yAxis: {
            gridLineWidth: 0
          },
          tooltip: {
            formatter: function () {
              return (
                '<strong>' +
                this.key +
                '</strong>' +
                ' <br> <strong>' +
                parseFloat(this.percentage.toFixed(2)) +
                '%</strong>'
              )
            }
          }
        }),
        show: true
      }
      return (
        <div style={{ marginTop: '0px' }} key={idx + '__121'}>
          <ReactHighcharts
            domProps={{ id: 'enginHourReport' }}
            config={pieChart.config}
          />
        </div>
      )
    } else if (table.type === 'lineChart') {
      let lineChartData = []
      let timeFormat = this.props.timeFormat ? '%l': '%k'
      table.rows.map(v => {
        let time12 = moment(v&&v.time&&v.time.r).format(getDateTimeFormat())
        let time =new Date(time12).getTime() // working
        lineChartData.push([time, v&&v.fuel&&v.fuel.v])
        return null
      })
      let lineChart = {
        config: lineStokeDefault(this.props.serverTimeZone).initializeWithOptions({
          title: {
            text: table.head,
            style: {
              font: 'normal 12px "Roboto"',
              color: this.props.themecolors.textColor,
              textTransform: 'none'
            }
          },
          series: [
            {
              data: lineChartData,
              lineWidth: 0.5,
              name: 'Fuel'
            }
          ],
          yAxis: {
            lineWidth: 1,
            gridLineWidth: 0,
            labels: {
              style: {
                font: 'normal 12px "Roboto"',
                color: this.props.themecolors.textColor,
                textTransform: 'none'
              }
            },
            title: {
              text: 'Fuel level (ltr)',
              style: {
                font: 'normal 12px "Roboto"',
                color: this.props.themecolors.textColor,
                textTransform: 'none'
              }
            }
          },
          xAxis: {
            labels: {
              format: `{value: %d_${timeFormat}:%M:%S}`,
              // tickInterval: 4 * 3600 * 1000,
              // formatter: function() {
              //   return highcharts.dateFormat('%b/%e/%Y', this.value);
              // },
              rotation: -50,
              style: {
                font: 'normal 12px "Roboto"',
                color: this.props.themecolors.textColor,
                textTransform: 'none'
              }
            },
            ordinal: false,
            type: "datetime",
            gridLineWidth: 0,
            title: {
              text: 'Time',
              style: {
                font: 'normal 12px "Roboto"',
                color: this.props.themecolors.textColor,
                textTransform: 'none'
              }
            }
          }
        })
      }
      return (
        <div style={{ marginTop: '0px' }} key={idx + '__121'}>
          <ReactHighcharts
            domProps={{ id: 'fuleReport' }}
            config={lineChart.config}
          />
        </div>
      )
    } else if (table.type === 'table') {
      return <ReactBaseTable disableFooter data={table.data || []} columns={this.defineColumns(table)} />
    } else if (table.type === 'nestedTable') {
      this.setState({
        mainColums: table.columns || [],
        summeryColumns: table.columns || []
      })

      return (
        <div style={{ marginBottom: 16, marginTop: 16 }} key={idx + '__121'}>
    
          <GenericReport
            onRender={this.props.onRender}
            detail={tab.type === 'detail'}
            key={idx}
            table={table}
            title={tab.head}
            serverTimeZone={this.props.serverTimeZone}
            translate={this.props.translate}
            toggleFilter={this.toggleFilter}
            disableFilter={this.state.disableFilter}
          />
        </div>
      )
    } else if (table.type === 'mapTable') {
      const data1 = table.rows;
      return (
        <div style={{ marginBottom: 16 }} key={idx}>
          <Panel title={table.head} bodyPadding={0} maxWidth={400} open={true}>
            <Table key={idx}>
              <TableBody>
                {Object.keys(data1).map(i => {
                  let column = {}

                  this.state.summeryColumns.map(c => {
                    if (c.f === i) {
                      column = c
                    }
                    return null
                  })

                  let v = data1[i].v
                  switch (i) {
                    case 'averageSpeed':
                    case 'distance':
                    case 'maxSpeed':
                      v = Math.round(v * 100) / 100
                      break
                    case 'serverTime':
                      v = moment(data1[i].r).format(getDateTimeFormat())
                      break
                    default:
                      break
                  }

                  return (
                    <TableRow key={i}>
                      <TableCell
                        style={{
                          ...TheadThStyle,
                          textTransform: 'capitalize',
                          background: 'none',
                          color: this.props.themecolors.textColor
                        }}
                      >
                        {' '}
                        {column.h || i.replace(/([a-z])([A-Z])/g, '$1 $2')}{' '}
                      </TableCell>
                      <TableCell
                        style={{
                          ...TheadTdStyle,
                          color: this.props.themecolors.textColor
                        }}
                      >
                        {' '}
                        {v}{' '}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Panel>
        </div>
      )
    }
    else if (table.type === 'string') {
      return (<div style={{padding: '16px 0'}}>
        <Typography key={idx} component='strong'>
          {table.head}: {table.value}
        </Typography>
      </div>
      )
    }
    return null
  }

  componentWillReceiveProps (nextProps) {
    if (!isEqual(nextProps.tabs, this.props.tabs)) {
      this.setState({ value: 0, nested: 0 })
    }
  }

  drawContainer (tab, index,) {
    return (
      <TabContainer key={index}>
        {tab.groupBy === 'date' ? <Tabs
          value={this.state.nested}
          onChange={this.handleChange2}
          scrollButtons='on'
          variant='scrollable'
          classes={{
            root: 'custom-tabs-root custom-tabs-root-border',
            flexContainer: 'custom-tabs',
            scrollable: 'custom-tabs-scrollable',
            indicator: 'custom-indicator',
            scrollButtons: 'scrollable-buttons'
          }}
        >
           {tab.tabs.map((sec, ind) => {
                return (
                  <Tab
                    key={ind}
                    label={sec.head}
                    classes={{
                      root: 'custom-tab-button custom-tab-button-1',
                      selected: 'custom-tab-button-selected'
                    }}
                    value={ind}
                  />
                )
              })}
            
        </Tabs> : null}

        {tab.groupBy !== 'device' && tab.groupBy !== 'driver'
          ? tab.tabs.map((sec, ind) => {
              if (this.state.nested === ind) {
                return (
                  <div key={ind}>
                    <TabContainer key={ind}>
                      {sec &&
                        sec.sections && sec.sections.length ? 
                        sec.sections.map((a, b) => this.drawTable(a, b, tab)) : null}
                    </TabContainer>
                  </div>
                )
              } else {
                return null
              }
            })
          : tab && tab?.sections.map((a, b) => this.drawTable(a, b, tab))}
      </TabContainer>
    )
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.fetching === true && this.state.value !== 0) {
      this.setState({ value: 0 })
    }
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  componentDidUpdate () {
    if (this.props.heading) {
      this.props.onRender()
    }
  }

  render () {
    const { classes, tabs, reportType, heading } = this.props
    const { value } = this.state
    let TabsNav
    if (tabs && tabs.length) {
      TabsNav = tabs.map((tab, index) => {
        return (
          <Tab
            key={index}
            label={tab.label}
            classes={{
              root: 'custom-tab-button custom-tab-button-1',
              selected: 'custom-tab-button-selected'
            }}
            value={index}
          />
        )
      })

      TabsContainers = tabs.map((tab, index) => {
        if (index === value) {
          return this.drawContainer(tab, index, reportType, heading)
        }
        return null
      })

      return (
        <div className={classes.root} style={{ background: this.props.themecolors.backgroundColor}}>
          <AppBar
            position='static'
            color='inherit'
            style={{ background: 'none', boxShadow: 'none', paddingTop: 16 }}
          >
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor='primary'
              textColor='primary'
              scrollButtons='on'
              variant='scrollable'
              classes={{
                root: 'custom-tabs-root custom-tabs-root-border',
                flexContainer: 'custom-tabs',
                scrollable: 'custom-tabs-scrollable',
                indicator: 'custom-indicator',
                scrollButtons: 'scrollable-buttons'
              }}
            >
              {TabsNav}
            </Tabs>
          </AppBar>
          {TabsContainers}
        </div>
      )
    } else {
      return null
    }
  }
}

ScrollableTabsButtonAuto.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ScrollableTabsButtonAuto)