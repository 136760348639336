import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar'
// import { NavLink } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
import Style from 'style-it'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'


const Users=(props)=>{
  const logInUser=useSelector((state)=>state.logInUsers)
  return (
    <Style>
      {`
        .material-icons {vertical-align: middle; color: inherit}
      `}
      <div>
        {props.users?.length > 0 && (
          <div className='individual-view'>
            <ul className='list-view'>
              {props.users.map((user) => (
                <ListRow
                  key={user.id}
                  data={user}
                  deleteItem={removedItem}
                  editItem={props.editItem}
                  translate={props.translate}
                  selecteItem={props.selecteItem}
                  selectedItemId={props.selectedItemId}
                  logInUser={logInUser}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </Style>
  );

}

const ListRow=(props)=>{
  const checkUserType = (Utype)=> {
    let type = ''
    if (Utype === -1) {
      type = 'O'
    } else if (Utype === 1) {
      type = 'U'
    } else if (Utype === 2) {
      type = 'A'
    } else if (Utype === 3) {
      type = 'AA'
    } else if (Utype === 4) {
      type = 'SP'
    } else if (Utype === 5) {
      type = 'R'
    } else if (Utype === 6) {
      type = 'D'
    } else {
      type = 'U'
    }
    return type
  }
  const empty = null
  return(
    <li>
    <a
      href={empty}
      className={
        'clearfix list-row ' +
        (props.selectedItemId === props.data.id ? 'active' : '')
      }
    >
      <label
        className='checkbox'
        onClick={() => props.selecteItem(props.data)}
      >
        <span
          className='unit-name'
          style={{
            minWidth: 220,
            maxWidth: 220,
            marginLeft: 0,
            lineHeight: '24px'
          }}
        >
          <span
            style={{
              marginRight: '10px',
              display: 'inline-block',
              verticalAlign: 'middle'
            }}
          >
            <Avatar
              className={
                'avatar-' + checkUserType(props.data.userType)
              }
              style={{ width: 25, height: 25, fontSize: 12 }}
            >
              {checkUserType(props.data.userType)}
            </Avatar>
          </span>{' '}
          {props.data.name} {props.data.uniqueId}
        </span>
      </label>
      {props.logInUser.administrator && (
        <div className='pull-right'>
          {checkPrivileges('userDelete') && (
            <span
              className='action-item hovered '
              title={props.translate('delete')}
              onClick={() => props.removedItem(props.data)}
            >
              <DeleteIcon style={{fontSize: 16}}/>
            </span>
          )}
          <span
            className='action-item hovered'
            title={props.translate('edit')}
            onClick={() => props.editItem(props.data)}
          >
            {' '}
            <EditIcon style={{fontSize: 16}}/>
          </span>
        </div>
      )}
    </a>
  </li>
  )
}

const Users2=(props)=>{
const logInUser=useSelector((state)=>state.logInUsers)
  return (
    <Style>
      {`
        .material-icons {vertical-align: middle; color: inherit}
      `}
      <div>
        {props.users?.length > 0 && (
          <div className='individual-view'>
            <ul className='list-view'>
              {props.users.map((user) => (
                <ListRow2
                  key={user.id}
                  data={user}
                  {...props}
                  logInUser={logInUser}
                  removedItem={props.removedItem}
                  editItem={props.editItem}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </Style>
  );

}

const ListRow2=(props)=>{
  const checkUserType = (Utype)=> {
    let type = ''
    if (Utype === -1) {
      type = 'O'
    } else if (Utype === 1) {
      type = 'U'
    } else if (Utype === 2) {
      type = 'A'
    } else if (Utype === 3) {
      type = 'AA'
    } else if (Utype === 4) {
      type = 'SP'
    } else if (Utype === 5) {
      type = 'R'
    } else if (Utype === 6) {
      type = 'D'
    } else {
      type = 'U'
    }
    return type
  }
  return(
    <li>
    <Link
     
      to={'/users/' + props.data.id}
      className={
        'clearfix list-row ' +
        (parseInt(props.match && props.match.params && props.match.params.id) ===
          parseInt(props.data && props.data.id)
          ? 'active'
          : '')
      }
    >
      <label className='checkbox'>
        <span
          className='unit-name'
          style={{ minWidth: 220, maxWidth: 220, marginLeft: 0 }}
        >
          <span
            style={{
              marginRight: '10px',
              display: 'inline-block',
              verticalAlign: 'middle'
            }}
          >
            <Avatar
              className={
                'avatar-' + checkUserType(props.data.userType)
              }
              style={{ width: 18, height: 18, fontSize: 10 }}
            >
              {checkUserType(props.data.userType)}
            </Avatar>
          </span>{' '}
          {props.data.name} {props.data.uniqueId}
        </span>
      </label>
      {props.logInUser.administrator && checkPrivileges('userDelete') && (
        <div className='pull-right'>
          <span
            className='action-item '
            title={props.translate('delete')}
            onClick={() => props.removedItem(props.data)}
          >
            <DeleteIcon style={{fontSize: 16}}/>
          </span>
        </div>
      )}
    </Link>
  </li>
  )
}
export const UsersShortList = Users
export const UsersShortList2 = Users2