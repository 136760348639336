import React from 'react'
import { withLocalize } from 'react-localize-redux'
// import Icon from '@mui/material/Icon'
import TrafficIcon from '@mui/icons-material/Traffic';
import { connect } from 'react-redux'
import { Tooltip } from '@mui/material'
import { toggleTrafficLayer } from '../../Actions/Maps'
function TrafficMenu (props) {
  
  const toggleMapTraffic = () => {
      props.dispatch(toggleTrafficLayer())
  }
    const empty = null
    return (
    <Tooltip title={props.translate('toggleTraffic')} placement='top'>
        <li className={props.mapTraffic ? 'active' : ''}>
            <a href={empty} title='' onClick={props.selectedMap !== 'gccStreet' ? toggleMapTraffic: null}>
                <TrafficIcon style={{ fontSize: 24, height: 24 }}/>
            </a>
        </li>
    </Tooltip>)
}
const mapStateToProps = ({mapTraffic}) => ({
  mapTraffic
})

export default connect(mapStateToProps)(withLocalize(TrafficMenu))
