import instance from "../../axios";
import { checkPrivileges } from "../../Helpers";

export const getTemplates = templates => ({
  type: 'GET_TEMPLATES',
  templates
})
export const updateTemplates = templates => ({
  type: 'UPDATE_TEMPLATES',
  templates
});
export const updateTemplate = template => ({
  type: 'UPDATE_TEMPLATE',
  template
});
export const addTemplate = template => ({
  type: 'ADD_TEMPLATE',
  template
});

export const removeTemplate = template => ({
  type: 'REMOVE_TEMPLATE',
  template
});



export const fetchTemplates = (dispatch, userInfoFront) => {
  // inital fetch call for vehicles
  if (checkPrivileges('rptmpl')) {
    // fetch(`/api/report/templates?all=true&userId=${userInfoFront.id}&limit=-1`, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/report/templates`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        all: true,
        userId: userInfoFront.id,
        limit: -1
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(response => {
          // const { data } = response
          if (response) {
            dispatch(getTemplates(response))
          }
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(() => {
      // errorHandler(error, dispatch)
    })
  }
}