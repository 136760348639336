import React from 'react'
import { connect } from 'react-redux'
import {
  setTrackId,
  updateDeviceVisible,
  toggleGroupDevices,
  setDeviceId,
  unsetDeviceId,
  fetchMoreDevices,
  resetGroupBy,
  updateGroupNames,
  // applyGroupByFilter
} from './../../Actions/Devices'
import InfiniteScroll from 'react-infinite-scroll-component'
import { updateEvents } from './../../Actions/Notifications'
import UnitMenu from './../Menu/UnitMenu'
import Collapse from '@mui/material/Collapse'
import Tooltip from '@mui/material/Tooltip'
import './index.scss'
import { CheckIcon } from '../common/CheckIcon'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from './../../assets/monitoring/info.svg'
import { ReactComponent as PlayIcon } from '../../assets/monitoring/play.svg'
import { ReactComponent as StopIcon } from '../../assets/monitoring/stop.svg'
import { ReactComponent as IgnitionIcon } from '../../assets/monitoring/idling.svg'
import { ReactComponent as TowingIcon } from '../../assets/monitoring/towing.svg'
import { ReactComponent as ParkingIcon } from '../../assets/monitoring/parking.svg'
import { ReactComponent as QuestionIcon } from '../../assets/monitoring/question.svg'
import { ReactComponent as PowerCutIcon } from '../../assets/monitoring/powerCut.svg'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { ReactComponent as WorkOff } from '../../assets/monitoring/work-off.svg'

import { getComputedAttributes } from '../../Actions/ComputedAttributes'
import Scrollbar from 'react-scrollbars-custom'
import Loader from './../../Layout/Loader'
import instance from '../../axios'
import { checkPrivileges } from '../../Helpers'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ChevronRight"
class Devices extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedId: ''
    }
  }
  componentWillUnmount () {
    this.setState(
      {
        setNull: true
      },
      () => {
        this.state = null
      }
    )
  }

  selectGroup = id => {
    if (this.state.selectedId === id) {
      // reset
      this.setState(
        {
          selectedId: ''
        },
        () => {
          this.props.dispatch(resetGroupBy())
        }
      )
    } else {
      this.setState(
        {
          selectedId: id
        },
        () => {
          // this.props.dispatch(applyGroupByFilter(id))
        }
      )
    }
  }

  groupCollapse = event => {
    event.preventDefault()
  }

  render () {
    if (this.state.setNull) {
      return null
    }
    if (this.props.switchView === 'units') {
      const data = this.props.devices.map(device => {
        return (
          <ListRow
           {...this.props}
            monitoringMap={this.props.monitoringMap}
            openDeviceSettings={this.props.openDeviceSettings}
            collapseSidebar={this.props.collapseSidebar}
            key={device.id}
            item={device}
            trackId={this.props.trackId}
            deviceId={this.props.deviceId}
            dispatch={this.props.dispatch}
            showBox={this.props.showBox}
            relatedData={this.props.deviceRelatedData[device.id]}
            deviceRelatedData={this.props.deviceRelatedData}
            themecolors={this.props.themecolors}
            translate={this.props.translate}
          />
        )
      })
      return (
        <div className='individual-view list-row-condest'>
          <ul className='list-view list-view-children'>{data}</ul>
        </div>
      )
    } else {
      return (
        <DrawGroups
          selectGroup={this.selectGroup}
          {...this.props}
          selectedId={this.state.selectedId}
          groupCollapse={this.groupCollapse}
        />
      )
    }
  }
}

class DrawGroups1 extends React.PureComponent {
  render () {
    return (
      <div className='group-view list-row-condest'>
        <ul className='list-view-group'>
          {this.props.groups.map((group, index) => (
            <DrawGroupRow key={index} group={group} {...this.props} />
          ))}
          {/* <DrawGroupRow
            key='empty'
            group={{ id: 'nogroup', name: 'No Group' }}
            {...this.props}
          /> */}
        </ul>
      </div>
    )
  }
}

export class DrawGroupRow extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      checked: true,
      childUnits:[],
      loader:false
    }
  }

  toggleGroupDevices = event => {
     this.setState(
      {
        checked: event.target.checked
      },
      () => {
        this.props.dispatch(
          toggleGroupDevices({
            id: this.props.group.id,
            checked: this.state.checked,
            ids: Object.keys(this.props.visibleGroups)
          })
        )
      }
    )
  }
  
  findResourceUnits = (id)=>{
    this.setState({loader:true},()=>{
    instance({
      url: `/api/devices`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        groupId: id,
       }
    })
    .then(res=>{ 
       this.setState({
        childUnits:res,
        loader:false
      })
    })
    .catch(err=>{console.warn('err ===', err)})
  })
  }
 
  componentWillReceiveProps( n ) {
    if(n.isChecked !== this.props.isChecked) {
      this.setState({
        checked: n.isChecked
      })
    }
    if(this.props.visibleGroups && n.visibleGroups && n.visibleGroups[n.group.id] !== this.props.visibleGroups[n.group.id]) {
      this.setState({
        checked: n.visibleGroups[n.group.id]
      })
    }
  }

  render () {
    const emptyLinks = null
    const groups = this.props.groupWiseDevices[this.props.group.id]
    const checked = this.props.selectedId === this.props.group.id

    if (groups && groups.length) {
      return (
        <li key={this.props.group.id}>
          <div className='clearfix group-list-row list-row'>
            <label className='checkbox'>
              <input
                type='checkbox'
                checked={this.state.checked}
                onChange={e => this.toggleGroupDevices(e)}
              />
              <CheckIcon />
              <span className='unit-name'>{this.props.group.name}</span>
            </label>
            <div
              className='pull-right'
              onClick={() => this.props.selectGroup(this.props.group.id)}
            >
              <span className='action-item action-additional-menu'>
                <a
                  href={emptyLinks}
                  onClick={e =>
                    this.props.groupCollapse(e, this.props.group.id)
                  }
                  title='Additional Menu'
                >
                  {checked ? (
                    <KeyboardArrowUpIcon/>
                  ) : (
                    <KeyboardArrowDownIcon/>
                  )}
                </a>
              </span>
            </div>
          </div>
          <Collapse in={checked} timeout='auto' unmountOnExit>
            <ListRows
              child={this.props.devicesData.data}
              {...this.props}
            />
          </Collapse>
        </li>
      )
    } else {
      return (
        <li key={this.props.group.id}>
          <div className='clearfix group-list-row list-row' >

          <label onClick={() => this.props.selectGroup(this.props.group.id)} className='checkbox'>
            <span
              className='unit-name'
              style={{ minWidth: 200, maxWidth: 200, marginLeft: 0 }}
              >
                <a
                  onClick={() => this.findResourceUnits(this.props.group.id)}
                  href={emptyLinks}
                  title='Additional Menu '
                >
                  {checked ? (
                      <ExpandMoreIcon style={{width: 20, height: 20, verticalAlign: 'middle', marginRight: 5}} />
                  ) : (
                   <ExpandLessIcon style={{width: 20, height: 20, verticalAlign: 'middle', marginRight: 5}} />
                  )}
                  {this.props.group.name} - {this.props.group.attributes.devicesCount} 
                </a>
              </span>
          
              {/* <input
                type='checkbox'
                checked={this.state.checked}
                onChange={e => this.toggleGroupDevices(e)}
              /> */}
              {/* <CheckIcon /> */}
              {/* <span className='unit-name'>{this.props.group.name}</span> */}
            </label>
            {/* <div
              className='pull-right'
              onClick={e => this.props.selectGroup(this.props.group.id)}
            >
              <span className='action-item action-additional-menu'>
                <a
                  href={emptyLinks}
                  onClick={e => this.findResourceUnits(this.props.group.id)}
                  title='Additional Menu'
                >
                  {checked ? (
                    <KeyboardArrowUpIcon/>
                  ) : (
                    <KeyboardArrowDownIcon/>
                  )}
                </a>
              </span>
            </div> */}
          </div>
           <Collapse in={checked} timeout='auto' unmountOnExit>
           {this.state.loader ? <Loader/> : 
           <ListRows
           child={this?.state?.childUnits}
           loader={this.state.loader}
                // child={
                //   this?.state?.childUnits && this?.props?.fromArray
                //     ? this.state.childUnits.map(e => {
                //       let childObject = {}
                //          childObject = this?.props?.fromArray.find(row => row.id === e.id);
                //          return childObject ? childObject : {};
                //       })
                //     : this.state.childUnits 
                // }
                {...this.props}
              />
          }

              {/* {this.state.childUnits.map(e => {
                let childObject = {}
                 childObject =  this?.props?.fromArray.find(row => row.id === e.id);
                 if(childObject){
                   return (
                    <ListRows
                      {...this.props}
                      child={childObject ? [childObject] : []}
                      resources
                    />
                  );
                }
                else{
                  return null
                }
              })}  */}


            {/* <ListRows 
            child={this.state.childUnits} 
            {...this.props} /> */}

            
                    {/* {this.state.childUnits && this.state.childUnits.length && this.state.childUnits.map((item,index)=>{
                         return(
                          <p style={{background:"#0a83a1",color: "#ffffff", marginBottom:3,borderRadius: 4,boxSizing: "border-box", margin: 5,   padding: 5}}>{item.name}</p>
                        )
                    })} */}


                    
          </Collapse>
        </li>
      )
    }
  }
}

export class ListRows extends React.Component {
  fetchNext = () => {
    this.props.dispatch(fetchMoreDevices(false, 0, this.props.group.id))
  }
  render () {
     let hasMatchingObjects = false;
     if (this.props?.child?.length) {
      return (
        <div style={{ height: 250 }}>
          {this.props.resources ?
        
        this.props.child.map((device) => {
          return (
            <ListRow
              {...this.props}
              monitoringMap={this.props.monitoringMap}
              openDeviceSettings={this.props.openDeviceSettings}
              collapseSidebar={this.props.collapseSidebar}
              key={device.id}
              item={device}
              relatedData={this.props.deviceRelatedData[device.id]}
              dispatch={this.props.dispatch}
              showBox={this.props.showBox}
              themecolors={this.props.themecolors}
              translate={this.props.translate}
              isCheckedFn={this.props.isCheckedFn}
              trackId={this.props.trackId}
              deviceId={this.props.deviceId}
            />
          )
        })
        :
          <Scrollbar
            disableTracksWidthCompensation={true}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id={'scrollableDivDevices' + this.props.group.id}
                  />
                )
              }
            }}
          >
            <InfiniteScroll
              dataLength={100}
              next={this.fetchNext}
              hasMore={this.props.child.hasNext}
              loader={<Loader defaultStyle={true} />}
              endMessage={this.props.endMessage}
              scrollableTarget={'scrollableDivDevices' + this.props.group.id}
            >
              <ul className='list-view list-view-children'>
                {this.props.child.map((device) => {
                   if (Object.keys(device).length === 0) {
                    return null; // Skip rendering empty objects
                  }
                  hasMatchingObjects = true;
                  return (
                    <ListRow
                      {...this.props}
                      openDeviceSettings={this.props.openDeviceSettings}
                      collapseSidebar={this.props.collapseSidebar}
                      key={device?.id}
                      item={device}
                      relatedData={this.props?.deviceRelatedData[device?.id]}
                      dispatch={this.props.dispatch}
                      showBox={this.props.showBox}
                      themecolors={this.props.themecolors}
                      translate={this.props.translate}
                      isCheckedFn={this.props.isCheckedFn}
                      trackId={this.props.trackId}
                      deviceId={this.props.deviceId}
                    />
                  )
                })}
                 {!hasMatchingObjects && <p
                       style={{ textAlign: 'center', color: 'rgb(204, 204, 204)' }}>
                        No matching objects found.</p>
                   }
              </ul>
            </InfiniteScroll>
          </Scrollbar>}
        </div>
      )
    } else {
      return (
        <p style={{ textAlign: 'center', color: 'rgb(204, 204, 204)' }}>
          empty
        </p>
      )
    }
  }
}

export const ListRowAlter1 = React.memo(({deviceRelatedData, ...others}) => others.item ? <ListRow1 {...others} deviceRelatedData={deviceRelatedData} relatedData={deviceRelatedData[others.item.id]} /> : null)
ListRowAlter1.displayName='ListRowAlter1'
export class ListRow1 extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      sensorsDesc:[]
    }
  }

  handleChange = event => {
    const { target } = event

    this.props.dispatch(
      updateDeviceVisible({ checked: target.checked, id: this.props.item.id })
    )
  }
  viewOnMap = () => {
    if (this.props.relatedData && this.props.relatedData.exists) {
      this.props.dispatch(
        updateDeviceVisible({ checked: true, id: this.props.item.id })
      )
      this.props.dispatch(setTrackId(this.props.item.id))
    }

    if (this.props.deviceId !== this.props.item.id) {
      this.props.dispatch(unsetDeviceId({ id: this.props.item.id }))
      document.body.classList.remove('shown-widgets')
    }

    this.props.collapseSidebar()
  }

  addComputedAttributes = id => {

    instance({
      url: `/api/attributes/computed`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params :{
        deviceId: id,
        all: true
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(Attribute => {
          this.props.dispatch(getComputedAttributes(Attribute))
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(() => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  viewDetails = () => {
    if (window.innerWidth <= 959) {
      this.props.collapseSidebar()
    }
    if (this.props.deviceId === this.props.item.id) {
      this.props.dispatch(unsetDeviceId({ id: this.props.item.id }))
      document.body.classList.remove('shown-widgets')
    } else {
      this.props.dispatch(setDeviceId({ id: this.props.item.id }))
      document.body.classList.add('shown-widgets')

            
       this.props.getSensorsData && this.props.getSensorsData(this.props.item.id)
      // this.props.dispatch(fetchComputedAttributesSensors(this.props.item.id));


      // instance({
      //   url: `api/attributes/computed`,
      //   method: 'GET',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   params :{
      //     deviceId: this.props.item.id ,
      //     // limit: 4,
      //     category:"all",
      //     all:true
      //   }
      // })
      // .then(
      //   response => {
      //     if (response) {
      //       this.setState({
      //         sensorsDesc:response
      //       })
      //       // response.json()
      //       // .then(events => {
      //       //   this.props.dispatch(updateEvents(events.data))
      //       // })
      //     }
      //     else{
      //      console.log("error")
      //     }
      //   }).catch(error => {
      //     // errorHandler(error, this.props.dispatch)
      //   })

        
      // this.addComputedAttributes(this.props.item.id)
      // fetch('/api/events/get?deviceId=' + this.props.item.id + '&limit=4')
      instance({
        url: `/api/events/get`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params :{
          deviceId: this.props.item.id ,
          limit: 4
        }
      })

        .then(events => {
              this.props.dispatch(updateEvents(events.data))
        }).catch(() => {
          // errorHandler(error, this.props.dispatch)
        })
      this.props.dispatch(updateGroupNames([]));
      // fetch('/api/itemgroups/names?itemId=' + this.props.item.id + '&itemType=Device')
      instance({
        url: `/api/itemgroups/names`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params :{
          itemId: this.props.item.id,
          itemType: 'Device'
        }
      })

            .then(res => {
              this.props.dispatch(updateGroupNames(res.data))

        }).catch(() => {
          // errorHandler(error, this.props.dispatch)
        })


    }
  }

  openMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  openDeviceSettings = () => {
    this.props.openDeviceSettings(this.props.item.id)
  }

  onClose = () => {
    this.setState({ anchorEl: null })
  }

  /* shouldComponentUpdate (next, state) {
  
    return (
      !isEqual(next.data, this.props.item) ||
      !isEqual(next.relatedData, this.props.relatedData) ||
      (!isEqual(next.trackId, this.props.trackId) &&
        (this.props.trackId === next.data.id ||
          next.trackId === next.data.id)) ||
      !isEqual(next.deviceId, this.props.deviceId) ||
      !isEqual(state, this.state)
    )
  } */

  render () {
    const emptyLinks = null
    const { statusClass, exists, icons } = this.props.relatedData || {}
      const icon = icons && icons.playicon && icons.playicon.icon ? icons.playicon.icon : "";
      let checkPowerCut = this.props.relatedData&&this.props.relatedData.attributes&&this.props.relatedData.attributes.devicePowerCut ? true :false
      let RelatedIcon = null;
      let PowerCut = PowerCutIcon;
      let WorkFLow = WorkOff;
      if(icon === 'play') { RelatedIcon = PlayIcon; }
      else if(icon === 'ignition') { RelatedIcon = IgnitionIcon; }
      else if(icon === 'towing') { RelatedIcon = TowingIcon; }
      else if(icon === 'parking') { RelatedIcon = ParkingIcon; }
      else if(icon === 'stop') { RelatedIcon = StopIcon; }
      else if(icon === 'unknown') { RelatedIcon = QuestionIcon; }

    //  let deviceOnline = this.props.item&&this.props.item.attributes&&this.props.item.attributes.onlineTime || false

      // const end = moment(new Date())
      // const start = moment(this.props?.relatedData?.lastUpdate)
      // const minuteDiff = Math.round(moment.duration(end.diff(start)).minutes())
      // const findStatus = deviceOnline > minuteDiff ? true : false
      // const showStatus = statusClass === 'online' && deviceOnline ? (findStatus ? "online" :"offline" ) :statusClass
    return (
      <li>
        <div
          className={
            'clearfix list-row' +
            (this.props.item.id === this.props.trackId ? ' active ' : '') +
            ' ' +
            (!exists
              ? 'disabled-row'
              : statusClass === 'not-updated'
              ? 'not-updated'
              : '')
          }
        >
          <label className='checkbox'>
            <input
              type='checkbox'
              onChange={this.handleChange}
              checked={this.props.item.visible}
            />
            <CheckIcon className='theme-input-checkbox' />
          </label>
          <Tooltip
            title={this.props.item.name}
            classes={{
              popper: 'menu-popper',
              tooltip: 'menu-popper-tooltip'
            }}
          >
            <a
              onClick={this.viewOnMap}
              href={emptyLinks}
              className='unit-name'
              style={{ textDecoration: 'none' }}
            >
              <img
                src={
                  '/assets/category/default/' +
                  (this.props.item.category || 'default') +
                  '.svg'
                }
                // alt={this.props.item.name}
              />
              {this.props.item.name}
            </a>
          </Tooltip>
          <div className='pull-right list-action-bar'>
            <span
              className={
                'action-item action-view ' +
                (this.props.deviceId === this.props.item.id ? 'view' : '')
              }
            >
              <a
                href={emptyLinks}
                onClick={this.viewDetails}
                title={this.props.translate('viewDetails')}
                style={{ cursor: 'pointer' }}
              >
                <svg fill='currentColor' width={16} height={16}>
                  <use xlinkHref={`${InfoIcon}#icon`} />
                </svg>
              </a>
            </span>
            
            {exists ? <span className={'action-item action-view2 '}>
                <a
                  href={emptyLinks}
                  onClick={() => this.props.showBox(this.props.item)}
                  title={this.props.translate(icons.playicon.label)}
                >
                  <LocationSearchingIcon fill="currentColor" style={{width: 16, height: 16}} />
                </a>
              </span> : null}
            {icons && (
                <Tooltip
                  title={checkPowerCut ? "On" : ""}
                  classes={{
                    popper: 'menu-popper',
                    tooltip: 'menu-popper-tooltip'
                  }}
                >
                <span style={{marginTop:8, paddingRight:4}}>
                  <a
                    href={emptyLinks}
                    title={this.props.translate(icons.playicon.label)}
                  >
                    {checkPowerCut ? <PowerCut fill="#FF0000" width={16} height={16} />  : null }
                  </a></span></Tooltip>
              )}
            {icons &&(
              checkPowerCut ? <></> 
              : <span className={'action-item action-running '+icons.play}>
                  <a
                    href={emptyLinks}
                    title={this.props.translate(icons.playicon.label)}
                  >
                    {RelatedIcon && <RelatedIcon fill="currentColor" width={16} height={16} />}
                  </a>
                </span>
            )}
               {/* {icons && ( */}
               {checkPrivileges('workflow') && icons && (
              <span className={'action-item action-running '+icons.play}>
                <a
                  href={emptyLinks}
                  title={
                    (this.props.item&&this.props.item.attributes&&this.props.item.attributes.onWorkFlow) ?
                      this.props.translate('On') :this.props.translate('Off')}
                >
                  {(this.props.item&&this.props.item.attributes&&this.props.item.attributes.onWorkFlow) ?
                    <WorkFLow fill="#28a745" width={16} height={16} />
                    : <WorkFLow fill="silver" width={16} height={16} />
                    }
                </a>
              </span>
            )}
             <span className={'action-item action-connection ' + statusClass}>
              <a
                href={emptyLinks}
                title={this.props.translate('tracker.' + statusClass)}
              >
                <i className='circle'></i>
              </a>
            </span>

            <span className='action-item action-ellipsis has-menu' style={{marginRight:10}}>
              <a
                href={emptyLinks}
                onClick={this.openMenu}
                title={this.props.translate('monitoringPanelCustomizer')}
              >
                <MoreVertIcon style={{fontSize: 16}}/>
              </a>
              {this.state.anchorEl && (
                <UnitMenu
                copyToClipboard={this.props.copyToClipboard}
                 deviceRelatedData={this.props.deviceRelatedData}
                 ServerSetting={this.props.ServerSetting}
                devices={this.props.devices}
                   sensorsDesc={this.state.sensorsDesc}
                  id={this.props.item.id}
                  onClose={this.onClose}
                  anchorEl={this.state.anchorEl}
                  menuClass='level-right'
                  removeDevice={this.onRemovedItem}
                  editDevice={this.editDevice}
                  relatedData={this.props.relatedData}
                  data={this.props.item}
                  openDeviceSettings={this.openDeviceSettings}
                  themecolors={this.props.themecolors}
                  translate={this.props.translate}
                  dispatch={this.props.dispatch}
                  logInUser={this.props.logInUser}
                />
              )}
            </span>
          </div>
        </div>
      </li>
    )
  }
}

const mapStateToProps2 = state => {
return{
  deviceRelatedData: state.deviceRelatedData,
  ServerSetting: state.ServerSetting,
  logInUser:state.logInUsers

}
}
const mapStateToProps = state => {
  let visibleGroupsArr = {};
  let visibleGroups = {};
  const ids = state.groups.map(g => g.id);
  state.devices3.map(d => {
    const gid = ids.includes(d.groupId) ? d.groupId : 'nogroup'; 
    visibleGroupsArr[gid] = visibleGroupsArr[gid] || [];
    visibleGroupsArr[gid].push(d.visible);
    return null
  })
  ids.push('nogroup');
  ids.map(e => {
    visibleGroups[e] = visibleGroupsArr[e] && visibleGroupsArr[e].includes(false) ? false : true
    return null
  })

  return {
    groups: state.groups,
    groupWiseDevices: state.groupWiseDevices,
    visibleGroups,
    ServerSetting: state.ServerSetting,
    logInUser:state.logInUsers,
  deviceRelatedData: state.deviceRelatedData,


  }
}
export const DrawGroups = connect(mapStateToProps)(DrawGroups1)
export const ListRow = ListRow1
export const ListRowAlter = connect(mapStateToProps2)(ListRowAlter1)
export const DevicesList = connect(mapStateToProps2)(Devices)
