import React from 'react'
import TextField from '../../../Components/common/TextField'
import MenuItem from '../../../Components/common/MenuItem'
import Grid from '@mui/material/Grid'
import Button from '../../../Components/common/Button'
import Checkbox from '../../../Components/common/Checkbox'
import Table from '../../../Components/common/TableMultiCheckbox'
import { timeZonesTypes } from '../../../Components/Attributes/timezone'
import { checkPrivileges } from '../../../Helpers'
import SingleSelect from '../../../Components/common/SingleSelect'


const SavedCommands =(props)=> {


  const savedCommands_handleChange =(name, value) =>{
    if (props.savedCommandsHandle) {
      props.savedCommandsHandle(name, value)
    }
  }

    return (
      <Grid container style={{ height: '100%' }}>
        <Grid item sm={props.visableSavedCommands ? 8 : 12} xs={12}>
          <Table
            createButton={
              checkPrivileges('commandCreate') && (
                <div style={{ marginLeft: 16 }}>
                  {props.sendCommand
                    ? checkPrivileges('commandSend') && (
                        <Button
                          style={{ marginRight: 16 }}
                          size='small'
                          onClick={() => props.sendCommandsModal()}
                        >
                          {' '}
                          {props.translate('sendCommand')}
                        </Button>
                      )
                    : null}
                  <Button onClick={() => props.onCreateNewSavedCommands()}>
                    {props.translate('sharedCreate')}
                  </Button>
                </div>
              )
            }
            title={props.translate('sharedSavedCommand')}
            insideDialog
            disableHead
            showCheckbox
            showGroupAccess={props.showGroupAccess}
            checkHandleChange={props.savedCommands_Selection}
            rows={props.commandsData}
            isEditable
            selectAll={props.selectAll}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
            themecolors={props.themecolors}
            searchable
            canRemove={props.canAssign}
            canAssign={props.canRemove}
            canUpdate={checkPrivileges('commandUpdate')}
            canDelete={checkPrivileges('commandDelete')}
            className='custom-table2'
            rowsPerPage={props.rowsPerPage}
            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            rowDefinition={[
              {
                id: 'description',
                numeric: false,
                disablePadding: false,
                label: props.translate('sharedDescription')
              },
              {
                id: 'type',
                numeric: false,
                disablePadding: false,
                label: props.translate('sharedType'),
                translate: true,
                prefix: 'commands.'
              }
            ]}
          />
        </Grid>
        {props.visableSavedCommands && (
          <Grid item sm='4' xs='12'>
            <div
              style={{
                padding: '1px 20px',
                borderLeft: '1px solid ' + props.themecolors&&props.themecolors.themeLightColor,
                height: '100%'
              }}
            >
              <h4>{props.translate('sharedSavedCommand')}</h4>
              <TextField
                id='description'
                margin='dense'
                label={props.translate('sharedDescription')}
                variant='outlined'
                fullWidth
                value={props.savedCommandsForm.description}
                onChange={props.savedCommands_handleChange('description')}
              />
              {/* <Checkbox
                checked={props.savedCommandsForm.textChannel}
                onChange={props.savedCommands_handleChange('textChannel')}
                color='primary'
                label={props.translate('commands.sendSms')}
              /> */}
                <SingleSelect
                    array={props.formType}
                    selectName='type'
                    label={props.translate('sharedType')}
                    value={props.savedCommandsForm && props.savedCommandsForm.type? 
                      {
                        key: props.savedCommandsForm.type,
                        id: props.savedCommandsForm.type,
                        value: props.savedCommandsForm.type,
                        label: props.savedCommandsForm.type
                      } :''
                    }
                    handleChange={savedCommands_handleChange}
                    canRemove={true}
                    canAssign={true}
                />
              {[
                 'custom',
                 'silenceTime',
                 'setPhonebook',
                 'voiceMessage',
                 'outputControl',
                 'setIndicator',
                 'configuration',
                 'setOdometer',
                 'alarmClock',
                 'alarmSpeed',
                 'alarmVibration',
                 'setSilenceTime'
              ].includes(props.savedCommandsForm.type) ? (
                <TextField
                  id='data'
                  name='data'
                  margin='dense'
                  label={props.translate('commandData')}
                  variant='outlined'
                  fullWidth
                  value={props.savedCommandsForm.attributes.data}
                  onChange={props.savedCommands_handleChange('attributes')}
                />
              ) : null}
              {props.savedCommandsForm.type === 'positionPeriodic' && (
                <div>
                  <TextField
                    id='frequency'
                    name='frequency'
                    type='number'
                    margin='dense'
                    label={props.translate('commandFrequency')}
                    variant='outlined'
                    fullWidth
                    value={props.savedCommandsForm.attributes.frequency}
                    onChange={props.savedCommands_handleChange(
                      'attributes'
                    )}
                  />
                  <TextField
                    id='frequencyType'
                    name='frequencyType'
                    select
                    value={
                      props.savedCommandsForm.attributes.frequencyType
                    }
                    onChange={props.savedCommands_handleChange(
                      'attributes'
                    )}
                    margin='dense'
                    fullWidth
                  >
                    {frequencyType.map(option => (
                      <MenuItem key={option.type} value={option.type}>
                        {option.type}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              {props.savedCommandsForm.type === 'setTimezone' && (
                <TextField
                  id='timezone'
                  name='timezone'
                  select
                  label={props.translate('commandTimezone')}
                  value={props.savedCommandsForm.timezone}
                  onChange={props.savedCommands_handleChange('attributes')}
                  margin='dense'
                  fullWidth
                >
                  {timeZonesTypes.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {[
                'sendSms',
                'sendUssd',
                'sosNumber',
                'outputControl',
                'message'
              ].includes(props.savedCommandsForm.type) ? (
                <div>
                  {['sosNumber', 'outputControl'].includes(
                    props.savedCommandsForm.type
                  ) ? (
                    <TextField
                      id='index'
                      name='index'
                      type='number'
                      margin='dense'
                      label={props.translate('commandIndex')}
                      variant='outlined'
                      fullWidth
                      value={props.savedCommandsForm.index}
                      onChange={props.savedCommands_handleChange(
                        'attributes'
                      )}
                    />
                  ) : null}
                  {['sendSms', 'sendUssd', 'sosNumber'].includes(
                    props.savedCommandsForm.type
                  ) ? (
                    <TextField
                      id='phoneNumber'
                      margin='dense'
                      name='phoneNumber'
                      label={props.translate('commandPhone')}
                      variant='outlined'
                      fullWidth
                      value={props.savedCommandsForm.phoneNumber}
                      onChange={props.savedCommands_handleChange(
                        'attributes'
                      )}
                    />
                  ) : null}
                  {['sendSms', 'message'].includes(
                    props.savedCommandsForm.type
                  ) ? (
                    <TextField
                      id='messages'
                      name='messages'
                      margin='dense'
                      label={props.translate('Messages')}
                      variant='outlined'
                      fullWidth
                      value={props.savedCommandsForm.messages}
                      onChange={props.savedCommands_handleChange(
                        'attributes'
                      )}
                    />
                  ) : null}
                </div>
              ) : null}
              {[
                'alarmFall',
                'alarmRemove',
                'alarmBattery',
                'modeDeepSleep',
                'modePowerSaving',
                'setAgps',
                'voiceMonitoring',
                'modifyPowerSaving',
                'modifyDeepSleep',
                'alarmSos'
              ].includes(props.savedCommandsForm.type) ? (
                <Checkbox
                  checked={props.savedCommandsForm.enable}
                  onChange={props.savedCommands_handleChange('attributes')}
                  color='primary'
                  label={props.translate('commandEnable')}
                  name='enable'
                />
              ) : null}
              {props.savedCommandsForm.type === 'setConnection' && (
                <div>
                  <TextField
                    id='server'
                    name='server'
                    margin='dense'
                    label={props.translate('commandServer')}
                    variant='outlined'
                    fullWidth
                    value={props.savedCommandsForm.server}
                    onChange={props.savedCommands_handleChange(
                      'attributes'
                    )}
                  />
                  <TextField
                    id='port'
                    name='port'
                    type='number'
                    margin='dense'
                    label={props.translate('commandPort')}
                    variant='outlined'
                    fullWidth
                    value={props.savedCommandsForm.port}
                    onChange={props.savedCommands_handleChange(
                      'attributes'
                    )}
                  />
                </div>
              )}
              {props.savedCommandsForm.type === 'novementAlarm' && (
                <TextField
                  id='radius'
                  name='radius'
                  margin='dense'
                  label={props.translate('commandRadius')}
                  variant='outlined'
                  fullWidth
                  value={props.savedCommandsForm.radius}
                  onChange={props.savedCommands_handleChange('attributes')}
                />
              )}
              <div style={{ textAlign: 'right', marginTop: 10 }}>
                <Button
                  size='small'
                  style={{ marginRight: 15 }}
                  className='button-white'
                  onClick={() => props.onCloseC_Att_Modal()}
                >
                  {props.translate('sharedCancel')}{' '}
                </Button>
                {checkPrivileges(props.isHaveAccess) && (
                  <Button
                    disabled={!props.isAddVisableBtn||props.isLoading}
                    size='small'
                    onClick={() => props.addSavedCommands()}
                  >
                    {props.isLoading?"loading":props.translate(props.addButtonStatus)}{' '}
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    )
  }
export default SavedCommands

const frequencyType = [{ type: 's' }, { type: 'm' }, { type: 'h' }]
