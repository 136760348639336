import React, {  useState ,useEffect, useRef} from 'react'
import { connect } from 'react-redux'
import { fetchNotificationCount, getNotificationType } from '../../Actions/Notifications'
// import isEqual from 'react-fast-compare'
import { withLocalize } from 'react-localize-redux'
import {
  UserEventDetails
} from './../../Components/Events/userEvents'
import Layout from './../../Layout'
import MarkerMap from '../../Components/Maps'
// import Loader from '../../Layout/Loader'
import './Style.scss'
import { checkPrivileges, errorHandler } from '../../Helpers'
import moment from 'moment'
import axios from 'axios'
import instance from '../../axios'

const CancelToken = axios.CancelToken
let source = CancelToken.source()

// class Events extends Component {
  const Events =(props)=>{
    const [state, setState] = useState({
      isVisable: false,
      selecteditemId: '',
      selectitem: [],
      deviceData: {},
      itemDetails: '',
      isEnable: false,
      showNotification: false,
      address: '',
      latitude: '',
      longitude: '',
      loading: false,
      applied: false,
      validatedForm: false,
      notificationId: [],
      notificationType: [],
      alreadySubmit: false,
      from: "",
      to: "",
      selectedDate: '',
      showAllNotification: false,
      isProcessCompleted: false,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: props.deviceId,
        hasNext: true,
        searchText: ''
      },
      showFilterForm: false
    })
    
    const detailsDiv = useRef(null);
  const toggleFilterForm = () => {
    if(state.showFilterForm === false) {
      setState((prevState) => ({ ...prevState, 
        selectitem: [],
        notificationId: [],
        notificationType: [],
        from: '',
        to: '',
        showFilterForm: true
      }))

    }
    else if(state.showFilterForm === true) {
      setState((prevState) => ({ ...prevState, 
          selectitem: [],
          notificationId: [],
          notificationType: [],
          from: '',
          to: '',
          showFilterForm: false
        }))
        fetchMoreItems(true);
    }
    // setState({showFilterForm: !state.showFilterForm})
  }

  const toggleFilterForm2 = () => {
    setState((prevState) => ({ ...prevState, showFilterForm: !state.showFilterForm}))
  }

  // componentWillUnmount () {
  //   if (source) {
  //     source.cancel()
  //   }

  //   setState({
  //     isVisable: false,
  //     selecteditemId: '',
  //     selectitem: [],
  //     itemDetails: '',
  //     isEnable: false,
  //     showNotification: false,
  //     address: '',
  //     latitude: '',
  //     longitude: '',
  //     assignDriver: '',
  //     loading: false,
  //     applied: false,
  //     validatedForm: false,
  //     notificationId: [],
  //     alreadySubmit: false,
  //     from: '',
  //     to: '',
  //     selectedDate: '',
  //     showAllNotification: false,
  //     isProcessCompleted: false,
  //     currentPage: 1,
  //     pagesize: 50,
  //     itemPagination: {
  //       items: [],
  //       total: 0,
  //       currentPage: 0,
  //       currentDevice: props.deviceId,
  //       hasNext: true,
  //       searchText: ''
  //     }
  //   })
  // }

  // componentWillReceiveProps(n) {
  //   if(n.logInUser && n.logInUser.id && state.alreadySubmit === false) {
  //     if(n.match.params.id) {
  //       setState({selectitem: [...state.selectitem, n.match.params.id]})
  //     }
  //     setState({
  //       alreadySubmit: true
  //     }, () => {
  //       fetchMoreItems(true);
  //     })
  //   }
  // }

  // componentWillMount () {
  //   if(props.logInUser && props.logInUser.id && state.alreadySubmit === false) {
  //     if(props.match.params.id) {
  //       setState({selectitem: [...state.selectitem, props.match.params.id]})
  //     }
  //     setState({
  //       alreadySubmit: true
  //     }, () => {
  //       fetchMoreItems(true);
  //     })
  //   }
  //   const urls = [] // a list of urls to fetch in parallel
  //   if (!props.notificationType.length) {
  //     urls.push('/api/notifications/types/')
  //   }
  //   source = CancelToken.source()
  //   urls.map(url =>
  //     axios
  //       .get(url, {
  //         cancelToken: source.token,
  //         headers: {
  //           Accept: 'application/json',
  //           'Content-Type': 'application/json'
  //         }
  //       })
  //       .then(res => {
  //         if (res.status === 200) {
  //           if (url === '/api/notifications/types/') {
  //             props.dispatch(getNotificationType(res))
  //           }
  //         }
  //       })
  //   )
  // }

  // useEffect(() => {
  //   if(n.logInUser && n.logInUser.id && state.alreadySubmit === false) {
  //     if(n.match.params.id) {
  //       setState({selectitem: [...state.selectitem, n.match.params.id]})
  //     }
  //     setState({
  //       alreadySubmit: true
  //     }, () => {
  //       fetchMoreItems(true);
  //     })
  //   }
  // }, [])

  useEffect(() => {
     if(props.logInUser && props.logInUser.id && state.alreadySubmit === false) {
      if(props.match.params.id) {
        setState((prevState) => ({ ...prevState,selectitem: [...prevState.selectitem, props.match.params.id]}))
      }
      setState((prevState) => ({ ...prevState,
        alreadySubmit: true
      }))
    
    }
    const urls = [] // a list of urls to fetch in parallel
    if (!props.notificationType.length) {
      urls.push('/api/notifications/types/')
    }
    source = CancelToken.source()
    urls.map(url =>
      axios
        .get(url, {
          cancelToken: source.token,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          if (res.status === 200) {
            if (url === '/api/notifications/types/') {
              props.dispatch(getNotificationType(res))
            }
          }
        })
    )
  }, [])
  


  const fetchMoreItems = (reset, cb) => {
     // if (source) {
    //   source.cancel()
    // }
    fetchData(
      props.logInUser.id,
      reset ? 1 : state.currentPage,
      state.pagesize,
      reset || null,
      cb
    )
  }

  const fetchData = (userId, page, perPage, reset, cb) => {
    let timezone = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)
    const from =
      state.from
    const to =
      state.to
    let items = state.itemPagination.items
    if (reset) {
      items = []
    }
     let method = 'POST'
    let url = `/api/events/get`
    
    instance({
      method,
      url,
      data: { 
        userId: userId,
        deviceIds: state.selectitem,
        types: state.notificationType,
        notificationIds: state.notificationId,
        fromDate: from,
        toDate: to,
        search:"",
        page: page,
        limit: perPage
      },
     })
      .then(response => {
        // if (response.status === 200) {
          const res = response
          setState((prevState) => ({ ...prevState,
            showNotification: true,
            itemPagination: {
              ...res,
              items: items.concat(res.data)
            },
            currentPage: res.hasNext ? res.page + 1 : res.page
          }))
          if(cb) {
            cb()
          }
        // }
      })
      .catch(error => {
        errorHandler(error, props.dispatch)
        setState((prevState) => ({ ...prevState, showNotification: false }))
      })
  }

 

  const setPageLoader =(value)=> {
    setState((prevState) => ({ ...prevState, loading: value }))
  }

  // const selecteItem =(item) =>{
  //   setState((prevState) => ({ ...prevState,
  //     selectitem: { ...item, label: item.label || item.name, value: item.id },
  //     isVisable: true,
  //     showNotification: false,
  //     isEnable: false
  //     /*notificationId: [],
  //           from: '',
  //           to: '',
  //           selectedDate: '',*/
  //   }))
  // }

  const makeRead = item => {
    let items = state.itemPagination.items.map(row => {
      if(item.id === row.id) {
        row.isRead = true
      }
      return row
    })
    setState((prevState) => ({ ...prevState,
      itemPagination: {
        ...prevState.itemPagination,
        items
      }}))
      axios.get(`/api/events/read/${item.id}`).then(response => {
        if(response && response.status === 200) {
          if(response.data && response.data.status === 'success') {
            fetchNotificationCount(props.dispatch, props.logInUser)
          }
        }
      }) 
  }

  const selectedItem =(selectitem)=> {
    setState((prevState) => ({ ...prevState,
      selectitem,
      isVisable: true,
      showNotification: false,
      isEnable: false
    }))
  }
  
  const [isCallApi,setisCallApi]=useState(false)

  useEffect(() => {
    if (state.alreadySubmit && isCallApi) {
      fetchMoreItems(true, toggleFilterForm2);
      setisCallApi(false);
    }
  }, [isCallApi, state.alreadySubmit]);
  
  useEffect(() => {
    if (state.alreadySubmit && !isCallApi) {
      fetchMoreItems(true);
    }
  }, [state.alreadySubmit]);
  

  const showNotification = obj => {
    setState((prevState) => ({ 
      ...prevState, 
      ...obj,
      showNotification: false , alreadySubmit: true}))
      setisCallApi(true)
  }

  const onCloseModal = () =>{
    setState((prevState) => ({ ...prevState,
      isVisable: false,
      selectitem: []
    }))
  }

  const showDetail =(item) =>{
    /* let device = null
    device = props.devices.find(d => item.deviceId === d.id)
    if (!device) {
      device = null
    }
    console.log(item); */
    if(state.itemDetails.positionId !== item.positionId) {
      getPostionAddress(item.positionId);
    }
    if(item.isRead !== true) makeRead(item);
    setSelectedDevice();

    setState((prevState) => ({ ...prevState,
      isEnable: true,
      itemDetails: {...item, isRead: true},
      // deviceData: device
    }))

    const scrollTime = state.applied ? 0 : 1000

    setTimeout(() => {
      if (detailsDiv.current) {
        setState((prevState) => ({ ...prevState, applied: true }))
        detailsDiv.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, scrollTime)

    
  }

  const hideDetails = () => {
    setState((prevState) => ({ ...prevState,
      isEnable: false,
      itemDetails: '',
      deviceData: {},
      longitude: 0,
      latitude: 0
    }))
  }
  
  const getPostionAddress = id => {
    if (id !== 0) {
      // fetch(`/api/positions?id=${id}`, {
      //   method: 'Get',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/positions`,
        method: `GET`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params:{
          id: id
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(postion => {
              setState((prevState) => ({ ...prevState,
                address: postion[0].address,
                latitude: postion[0].latitude,
                longitude: postion[0].longitude
              }))
          //   })
          // } else {
          //   throw response
          // }
        })
        .catch(() => {
    
          // errorHandler(error, props.dispatch)
          setState((prevState) => ({ ...prevState,
            address: null,
            latitude: null,
            longitude: null
          }))
        })
    } else {
      setState((prevState) => ({ ...prevState,
        address: null,
        latitude: null,
        longitude: null
      }))
    }
  }

  // shouldComponentUpdate (nextProps, nextState) {
  //   return !isEqual(nextProps, props) || !isEqual(nextState, state)
  // }

  // const getNotificationType = () => {
  //   let array = []
  //   if (props.notificationType.length) {
  //     props.notificationType.map((item) => {
  //       array.push({
  //         name: props.translate('notification.' + item.type),
  //         id: item.type
  //       })
  //       return null
  //     })
  //     return array
  //   } else {
  //     return array
  //   }
  // }

  // const handleChange = () => event => {
  //   const { target } = event
  //   let value = target.type === 'checkbox' ? target.checked : target.value
  //   setState((prevState) => ({ ...prevState,
  //     validatedForm: value,
  //     showAllNotification: value,
  //     showNotification: false,
  //     isEnable: false
  //   }))
  //   if (value === true) {
  //     setState((prevState) => ({ ...prevState,
  //       notificationId: [],
  //       to: '',
  //       from: ''
  //     }))
  //   }
  // }

  const disableShowBtn = () => {
    setState((prevState) => ({ ...prevState,
      validatedForm: false
    }))
  }

  const setSelectedDevice = () => {
    let item = props.devices.find(
      item => parseInt(props.match.params.id) === parseInt(item.id)
    )
    if (item) {
      setState((prevState) => ({ ...prevState,
        selecteditem: { ...item, label: item.name, value: item.id }
      }))
    }
  }

  // const fetchNotifications = () => {
  //   let serverTimeZoneName = 'Asia/Dubai'
  //   if (
  //     props.ServerSetting &&
  //     props.ServerSetting.attributes &&
  //     props.ServerSetting.attributes.timezone
  //   ) {
  //     serverTimeZoneName = props.ServerSetting.attributes.timezone
  //   }

  //   let serverTimeZone = moment.tz(serverTimeZoneName).utcOffset() / 60
  //   let clientTimezone = moment().utcOffset() / 60

  //   const f =
  //     state.from || ''
  //   const t =
  //     state.to || ''

  //   let from = calcTime(
  //     moment(f).toISOString(),
  //     clientTimezone - serverTimeZone + clientTimezone
  //   )
  //   let to = calcTime(
  //     moment(t).toISOString(),
  //     clientTimezone - serverTimeZone + clientTimezone
  //   )

  //   let eventId = ''
  //   if (state.notificationId.length) {
  //     state.notificationId.map(id => {
  //       eventId += '&type=' + id
  //       return null
  //     })
  //   }

  //   let id = state.selecteditem.value
  //   setState((prevState) => ({ ...prevState,
  //       totalEvents: [],
  //       currentPage: 1,
  //       hasMore: true
  //     }  ))

  //      instance({
  //       url: `/api/events/device/${id}`,
  //       method: `GET`,
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       params:{
  //         page: state.currentPage,
  //         limit: 20,
  //         from: from,
  //         to: to,
  //         type: eventId
  //       }
  //     })
  //         .then(res => {
  //           setState((prevState) => ({ ...prevState,
  //             ...res,
  //             totalEvents: state.totalEvents.concat(res.data),
  //             currentPage: res.page + 1,
  //             hasMore: res.hasNext
  //           }))
  //     }).catch(() => {
  //       // errorHandler(error, props.dispatch)
  //     })
  // }

  // const screenVaildation = (isValidet, num, value) => {
  //   let { validateValue } = state
  //   if (isValidet) {
  //     for (let i = 0; i < validateValue.length; i++) {
  //       if (validateValue[i] === num) {
  //         validateValue.splice(i, 1)
  //       }
  //     }
  //   } else {
  //     validateValue.push(num)
  //     validateValue = [...new Set(validateValue)]
  //   }
  //   setState((prevState) => ({ ...prevState,
  //     validateValue: validateValue,
  //     wizardData: {
  //       ...prevState.wizardData,
  //       [`form${num}`]: value
  //     }
  //   }))
  // }

  const markAsReadAll = () => {
    if(window.confirm('Are you sure? Do you want to mark all as read?')) {
      instance({
        method: 'GET',
        url: '/api/events/readall'
      }).then(response => {// eslint-disable-line no-unused-vars
        fetchMoreItems(true, () => fetchNotificationCount(props.dispatch, props.logInUser));
      }).catch(error => {// eslint-disable-line no-unused-vars
        // errorHandler(error, props.dispatch)
      })
    }
  }

  // const calcTime = (date, offset) => {
  //   let d = new Date(date)

  //   let utc = d.getTime() + d.getTimezoneOffset() * 60000

  //   let nd = new Date(utc + 3600000 * offset)

  //   return nd.toISOString()
  // }

  // render () {
    let serverTimeZoneName = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = props.ServerSetting.attributes.timezone
    }

    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = props.logInUser.attributes.timezone
    }

    let deviceCategory = "";
    if(state.itemDetails && state.itemDetails.deviceId && props.devices)  {
      const d = props.devices.find(dev => dev.id === state.itemDetails.deviceId);
      if(d) {
        deviceCategory = d.category || 'default';
      }
    }

    return (
      <>
        <Layout
          {...props}
          selectedItem={selectedItem}
          classFromChildren='notification-page'
          showNotification={showNotification}
          toggleFilterForm={toggleFilterForm}
          showFilterForm={state.showFilterForm}
          userEvents={{
            markAsReadAll: markAsReadAll,
            setPageLoader: setPageLoader,
            className: 'DeviceWiseEvents',
            showDetail: showDetail,
            itemDetails: state.selectitem,
            devices: props.devices,
            onCloseModal: onCloseModal,
            loading: state.loading,
            notificationId: state.notificationId,
            disableShowBtn: disableShowBtn,
            validatedForm: state.validatedForm,
            itemPagination: state.itemPagination,
            fetchMoreItems: fetchMoreItems,
            serverTimeZone: serverTimeZoneName,
            translate: props.translate
          }}
          
        >
          {checkPrivileges('device') && (
            <div style={{ minHeight: '100%' }}>
              <MarkerMap
                eventMarker={deviceCategory ? `/assets/category/default/${deviceCategory}top.svg` : ``}
                showMarker={state.isEnable}
                zoom={state.isEnable ? 15 : 3}
                lat={state.latitude}
                lng={state.longitude}
                height={'100%'}
              />
            </div>
          )}
        </Layout>
        {state.isEnable && (
          <div
            ref={detailsDiv}
            className='feature-row-1'
            style={{ boxShadow: 'none' }}
          >
            <UserEventDetails
              // geofences={props.geofences}
              // getPostionAddress={getPostionAddress}
              data={state.itemDetails}
              // device={state.deviceData}
              address={state.address}
              // latitude={state.latitude}
              // longitude={state.longitude}
              translate={props.translate}
              hideDetails={hideDetails}
              serverTimeZone={serverTimeZoneName}
            />
          </div>
        )}
      </>
    )
  // }
}

const mapStateToProps = state => ({
  geofences: state.geoFence,
  devices: state.devices3,
  notificationType: state.notificationType,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
  themecolors: state.themeColors
})

export default connect(mapStateToProps)(withLocalize(Events))
