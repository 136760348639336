import React, {  useState } from 'react'
import SimpleModalWrapped from '../common/Modal'
import { Translate } from 'react-localize-redux'
import Grid from '@mui/material/Grid'
import TextField from '../common/TextField'
import SearchDevices from '../Devices/searchItems'
// import { makeStyles } from '@mui/styles'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from './../common/Tooltip'
import IconButton from '@mui/material/IconButton'
import CrossIcons from '@mui/icons-material/Remove'
import moment from 'moment'
import { Api, checkPrivileges, plateTypes } from '../../Helpers'
import Select from '../common/Select'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'
import Button from '../common/Button'
import SingleSelect from '../common/SingleSelect'


// class addVehicle extends Component {
  const addVehicle = (props) => {

  // constructor () {
  //   super()
  //   state = {
  //     attm_isVisable: false,
  //     inquiryResponse: '',
  //     errorResponse: '',

  //   }
  //   addAttributes = addAttributes.bind(this)
  // }

  const [state, setState] = useState({
      attm_isVisable: false,
      inquiryResponse: '',
      errorResponse: '',
  })
  useEffect(() => {
    if (n.form.id !== props.form.id) {
      setState(prevState => ({
        ...prevState,
        inquiryResponse: '',
        errorResponse: ''
      }));
    }
  }, [n.form.id, props.form.id]); // Add dependencies for props that you want to watch
  
//  const  addAttributes = ()=> {
//   setState((prevState)=>({
//     ...prevState,
//       attm_isVisable: !state.attm_isVisable
//     }))
//   }

//  const  uploadFile = (e) => {
//     console.warn("image =",e.target.files[0])
//   }

 const  handleChange = (a,b) => {
    props.handleChangeForAttributesValues(a, {target: b});
  }

 const  verify = () => {
    setState((prevState)=>({
      ...prevState,
      errorResponse: '', inquiryResponse: ''
    }))

    if(props.form.attributes.sequenceNumber) {
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
        hook: 'getVehicle',
        account_id: props.serversettings.id,
        data: JSON.stringify({
          sequenceNumber: props.form.attributes.sequenceNumber,
          activity: 'DEFAULT'
        })
      }).then(response => {
        
        if(response.status === 200 && response.data && response.data.referenceKey) {
          props.handleChangeForAttributesValues('WASLKey', { target: {value: response.data.referenceKey} } );
          props.formSubmit();
          props.dispatch(
            Notifications.success({
              message: <Translate id='Integrated With Wasl' />,
              autoDismiss: 10
            })
          )
        }

      }).catch(error => {
        if(error.response && error.response.data) {
          if(error.response.data && error.response.data.message) {
            const { message, data } = error.response.data;
             setState((prevState)=>({
                 ...prevState, 
                 inquiryResponse: data?.resultCode || (message.toLowerCase() === 'bad request' ? 'Not Registered' : message) }))
          }
          else {
            const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
            setState((prevState)=>({
              ...prevState, 
              inquiryResponse: resultMsg || resultCode || errorMsg || errorCode }))
          }
        }
      })
    }
    else {
      props.dispatch(Notifications.error({
        message: <Translate id='Please enter sequence number first' />,
        autoDismiss: 10
      }))
    }
  }

 const  submitWasl = () => {
  setState((prevState)=>({
    ...prevState,
    errorResponse: '', inquiryResponse: ''}))
    const { waslCompanyRefKey } = props?.serversettings?.attributes || {};
    if(waslCompanyRefKey) {
      const { number, leftLetter, rightLetter, plateType, middleLetter, sequenceNumber } = props.form.attributes;
      const { uniqueId } = props.vehicleTracker;
      if(number && leftLetter && rightLetter && plateType && middleLetter && sequenceNumber && uniqueId) {
        axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
          hook: 'registerVehicle',
          account_id: props.serversettings.id,
          data: JSON.stringify({
            uniqueId,
            sequenceNumber,
            activity: 'DEFAULT',
            attributes: {
              rightLetter,
              number,
              leftLetter,
              middleLetter,
              plateType,
            }
          }),
          hook_prefix: waslCompanyRefKey
        }).then(response => {
          axios.get(`${Api}/refreshCache`).then(response => {// eslint-disable-line no-unused-vars

          }).catch(() => {})
          if(response.status === 200 && response.data && response.data.success) {
            if(response.data&&response.data.result && response.data.success &&response.data.result.referenceKey) {
              props.handleChangeForAttributesValues('WASLKey', { target: {value: response.data.result.referenceKey} } );
              props.formSubmit();
            }
          }
        }).catch(error => {
          if(error.response && error.response.data) {
            const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
            setState((prevState)=>({
              ...prevState,  errorResponse: resultMsg || resultCode || errorMsg || errorCode }))
              // props.handleChangeForAttributesValues('WASLKey', { target: {value:error.response.data&&error.response.data.data&&error.response.data.data.result.referenceKey} } );
              // props.formSubmit();
          }
        })
      } else {
        setState((prevState)=>({
          ...prevState, errorResponse: 'integration form incomplete'}));
      }
    }
    else {
      props.dispatch(Notifications.error({
        message: <Translate id='Please Register your company first' />,
        autoDismiss: 10
      }));
    }
  }

 const  changeIMEI = () => {
  setState((prevState)=>({
    ...prevState, errorResponse: '', inquiryResponse: ''}))
    const { waslCompanyRefKey } = props?.serversettings?.attributes || {};
    const { WASLKey, sequenceNumber } = props.form.attributes;
    const { uniqueId } = props.vehicleTracker;
    if(waslCompanyRefKey && WASLKey) {
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
        hook: 'updateVehicle',
        account_id: props.serversettings.id,
        data: JSON.stringify({sequenceNumber, imeiNumber: uniqueId, activity: 'DEFAULT'})
      }).then(response => {
        if(response && response.data && response.data.resultCode);
        setState((prevState)=>({
          ...prevState, inquiryResponse: response.data.resultCode}))
          props.formSubmit()
      }).catch(error => {
        if(error.response && error.response.data) {
          const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
          setState((prevState)=>({
            ...prevState, errorResponse: resultMsg || resultCode || errorMsg || errorCode }))
        }
        let errMsg = (error.response&&error.response.data&&error.response.data.data&&error.response.data.data.resultCode) || 'somethingWentWrong'
        props.dispatch(
          Notifications.error({
            message: <Translate id={errMsg}/>,
            autoDismiss: 10
          })
        )
      });
      
    }
  }
  
 const  unlinkWasl = () => {
  setState((prevState)=>({
    ...prevState,errorResponse: '', inquiryResponse: ''}))
    const { waslCompanyRefKey } = props?.serversettings?.attributes || {};
    const { WASLKey } = props.form.attributes;
    
    if(waslCompanyRefKey && WASLKey) {
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
        hook: 'deleteVehicle',
        account_id: props.serversettings.id,
        hook_prefix: waslCompanyRefKey,
        hook_postfix: WASLKey,
        data: {
          activity: 'DEFAULT'
        }
      }).then(response => {// eslint-disable-line no-unused-vars
        props.handleChangeForAttributesValues('WASLKey', { target: {value: ''} },false );
        props.formSubmit();
      }).catch(error => {
        console.warn(error);
        if(error.response && error.response.data) {
          const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
          setState((prevState)=>({
            ...prevState,errorResponse: resultMsg || resultCode || errorMsg || errorCode }))
        }
        let errMsg = (error.response&&error.response.data&&error.response.data.data&&error.response.data.data.resultCode) || 'somethingWentWrong'
        props.dispatch(
          Notifications.error({
            message: <Translate id={errMsg}/>,
            autoDismiss: 10
          })
        )
      })
    }
    else {
      props.dispatch(
        Notifications.error({
          message: <Translate id='Please Register your company first' />,
          autoDismiss: 10
        })
      )
    }
  }

  

  // render () {
    const { form: { attributes: { WASLKey } } } = props
    const { waslEnabled, waslCompanyRefKey } = props?.serversettings?.attributes || {};
    return (
      <div style={{ height: '100%' }}>
        <SimpleModalWrapped
          visable={true}
          notShowAttributesBtn
          isButtonVisable={true}
          title='sharedVehicle'
          formSubmit={props.formSubmit}
          modalControle={props.onClose}
          buttonText={props.buttonText}
          btnCloseText='sharedBack'
          isVisableBtn={props.isVisableUserBtn}
          selectedItemId={props.selectedVehicle.id}
          waslFooter={
            waslEnabled ? (WASLKey ? <><Button onClick={changeIMEI}>Change IMEI</Button><Button style={{ marginLeft: 10 }} onClick={unlinkWasl} >Un-Link WASL</Button></> : <Button onClick={submitWasl}>Link WASL</Button>) : null 
          }
          content={
            <form autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='label'
                    label={<Translate id='label' />}
                    required
                    placeholder=''
                    value={props.form.label || ''}
                    onChange={e => props.handleChange('label', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vehicleLicensePlate'
                    label={<Translate id='vehicleLicensePlate' />}
                    required
                    placeholder=''
                    value={props.form.vehicleLicensePlate || ''}
                    onChange={e =>
                      props.handleChange(
                        'vehicleLicensePlate',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='country'
                    label={<Translate id='country' />}
                    placeholder=''
                    value={props.form?.attributes?.country || ''}
                    onChange={e => props.handleChangeForAttributesValues('country', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='registrationNumber'
                    label={<Translate id='registrationNumber' />}
                    placeholder=''
                    value={props.form.attributes.registrationNumber || ''}
                    onChange={e => props.handleChangeForAttributesValues('registrationNumber', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='variant'
                    label={<Translate id='variant' />}
                    placeholder=''
                    value={props.form.attributes.variant || ''}
                    onChange={e => props.handleChangeForAttributesValues('variant', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='wheels'
                    label={<Translate id='wheels' />}
                    placeholder=''
                    value={props.form.attributes.wheels || ''}
                    onChange={e => props.handleChangeForAttributesValues('wheels', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                   <SingleSelect
                      array={props?.trasmissionList || []}
                      selectName='trasmissionType'
                      label={<Translate id='trasmissionType' />}
                        value={props.form&&props.form.attributes&&props.form.attributes.trasmissionType
                          ? { id:props.form.attributes.trasmissionType,
                              label:props.form.attributes.trasmissionType,
                              value: props.form.attributes.trasmissionType,
                            }
                          : ''
                        }
                      handleChange={props.handleChangeForAttributesValues}
                      canRemove={true}
                      canAssign={true}
                      />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='make'
                    label={<Translate id='make' />}
                    placeholder=''
                    value={props.form.attributes.make || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'make',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='model'
                    label={<Translate id='model' />}
                    placeholder=''
                    value={props.form.model || ''}
                    onChange={e => props.handleChange('model', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='year'
                    label={<Translate id='year' />}
                    placeholder=''
                    value={props.form.attributes.year || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'year',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='color'
                    label={<Translate id='attributeColor' />}
                    placeholder=''
                    value={props.form.attributes.color || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'color',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='fuel_type'
                    label={<Translate id='fuel_type' />}
                    placeholder=''
                    value={props.form.attributes.fuel_type || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'fuel_type',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='capacity'
                    label={<Translate id='capacity' />}
                    placeholder=''
                    value={props.form.attributes.capacity || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'capacity',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='garage'
                    label={<Translate id='garage' />}
                    placeholder=''
                    value={props.form.garage || ''}
                    onChange={e => props.handleChange('garage', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  style={{ postion: 'relative', zIndex: 2 }}
                >
                  <SearchDevices
                    isMulti={false}
                    api='devices'
                    isClearable
                    onChange={props.onChangedDevice}
                    value={props.vehicleTracker}
                    placeholder={<Translate id='searchUnits' />}
                  />
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vehicleType'
                    label={<Translate id='sharedType' />}
                    placeholder=''
                    value={props.form.vehicleType || ''}
                    onChange={e => props.handleChange('vehicleType', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vin'
                    label={<Translate id='vin' />}
                    placeholder=''
                    value={props.form?.vin || ''}
                    onChange={e => props.handleChange('vin', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='chassisNumber'
                    label={<Translate id='chassisNumber' />}
                    placeholder=''
                    value={props.form.attributes.chassisNumber || ''}
                    onChange={e =>
                      props.handleChangeForAttributesValues(
                        'chassisNumber',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                {/* start          -----------pending task from backEnd--------- Expiration Date*/}
                <Grid item md={4} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      error={
                        props.form.expirationTime &&
                        props.form.expirationTime <
                          moment().startOf('day').toISOString()
                      }
                      helperText={
                        props.form.expirationTime &&
                        props.form.expirationTime <
                          moment().startOf('day').toISOString()
                          ? <Translate id='licenseExpired' />
                          : ''
                      }
                      margin='dense'
                      label={<Translate id='V/LExpirationTime' />}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        props.form.expirationTime
                          ? new Date(
                              `${props.form.expirationTime}`
                            ).toString()
                          : null
                      }
                      onChange={props.handleChangeLicenseExpDate('expirationTime')}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title={<Translate id='noLimit' />}>
                              <IconButton
                                onClick={e => props.setExpirationTime(e)}
                                style={{ color: 'inherit' }}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {checkPrivileges('area') ?
                <Grid item md={4} sm={6} xs={12}>
                   <SingleSelect
                      array={props?.areaList || []}
                      selectName='areaId'
                      label={<Translate id='sharedArea' />}
                        value={props.form&&props.form.areaId&&props.form.areaId.key
                          ? {
                              id: props.form.areaId.key,
                              value: props.form.areaId.key,
                              label: props.form.areaId.name,
                              uniqueId:props.form.areaId.uniqueId,
                            }
                          : ''
                        }
                        // value={props.form.vehicleType || ''}
                      // onChange={e => props.handleChange('vehicleType', e)}
                      handleChange={props.handleChange}
                      canRemove={true}
                      canAssign={true}
                      />
                </Grid>
                :null}
                  {/*---end--- */}

                  {/* ------state-------------upload pdf file-----pending task from backEnd
                <Grid item md={4} sm={6} xs={12}>
                  <Tooltip title='Upload pdf'>
                    <Button
                      style={{ float: 'right', maxWidth: '40px',minWidth: '40px' }}
                      variant='contained'
                      margin='dense'
                      component="label"
                      onChange={e => uploadFile(e)}
                    >
                      <PublishIcon  style={{ color: '#fff'}}/>
                      <input
                        type="file"
                        hidden
                      />
                    </Button>
                  </Tooltip>
                </Grid>
                ----end---- */}
                {/* <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='permittedSpeed'
                    type='number'
                    label={<Translate id='permittedSpeed' />}
                    placeholder=''
                    value={props.form.attributes.permittedSpeed || ''}
                    onChange={e =>
                      props.handleChangeForAttributesValues(
                        'permittedSpeed',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                  <Grid item xs={12}>
                  <h3 style={{ margin: 0 }} className='with-border'>
                    {<Translate id='tags' />}
                  </h3>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  {props.form.attributes.tags}
                  <TextField
                    id='tag_1'
                    label={
                      props.logInUsers.attributes &&
                      props.logInUsers.attributes.vt1
                        ? props.logInUsers.attributes.vt1
                        : <Translate id='tag_1' />
                    }
                    placeholder=''
                    value={props.form.attributes.tag_1 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_1',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_2'
                    label={
                      props.logInUsers.attributes &&
                      props.logInUsers.attributes.vt2
                        ? props.logInUsers.attributes.vt2
                        : <Translate id='tag_2' />
                    }
                    placeholder=''
                    value={props.form.attributes.tag_2 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_2',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_3'
                    label={
                      props.logInUsers.attributes &&
                      props.logInUsers.attributes.vt3
                        ? props.logInUsers.attributes.vt3
                        : <Translate id='tag_3' />
                    }
                    placeholder=''
                    value={props.form.attributes.tag_3 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_3',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_4'
                    label={
                      props.logInUsers.attributes &&
                      props.logInUsers.attributes.vt4
                        ? props.logInUsers.attributes.vt4
                        : <Translate id='tag_4' />
                    }
                    placeholder=''
                    value={props.form.attributes.tag_4 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_4',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_5'
                    label={
                      props.logInUsers.attributes &&
                      props.logInUsers.attributes.vt5
                        ? props.logInUsers.attributes.vt5
                        : <Translate id='tag_5'/>
                    }
                    placeholder=''
                    value={props.form.attributes.tag_5 || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'tag_5',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              
                <Grid item xs={12}>
                  <h3 style={{ margin: 0 }} className='with-border'>
                    {<Translate id='integration' />}
                  </h3>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <label style={{position: 'static'}}>{<Translate id='sequenceNumber' />}</label>
                  <TextField
                    id='sequenceNumber'
                    label={<Translate id='sequenceNumber' />}
                    placeholder=''
                    value={props.form.attributes.sequenceNumber || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'sequenceNumber',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                  <small><em>{state.inquiryResponse}</em></small>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                    <label style={{position: 'static', marginBottom: 8, display: 'block'}}>&nbsp;</label>
                    {waslEnabled && waslCompanyRefKey ? <Button onClick={verify}>Check already registered</Button> : null}
                </Grid>
                <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
                  <h4 style={{marginBottom: 0}}>{<Translate id='vehicleLicensePlate' />}</h4>
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 8}} md={2} sm={6} xs={12}>
                  <label>{<Translate id='plateType' />}</label>
                  <Select
                    key='plateType'
                    array={plateTypes}
                    multi={props.form.attributes.plateType && plateTypes.filter(e => props.form.attributes.plateType == e.id)}
                    onChange={e=>handleChange('plateType', e)}
                    canAssign={true}
                    canRemove={true}
                  />
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 6}} md={2} sm={6} xs={12}>
                  <label>{<Translate id='leftLetter' />}</label>
                  <TextField
                    id='leftLetter'
                    label={null}
                    placeholder=''
                    value={props.form.attributes.leftLetter || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'leftLetter',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 4}} md={2} sm={6} xs={12}>
                  <label>{<Translate id='middleLetter' />}</label>
                  <TextField
                    id='middleLetter'
                    label={null}
                    placeholder=''
                    value={props.form.attributes.middleLetter || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'middleLetter',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 2}} md={2} sm={6} xs={12}>
                  <label>{<Translate id='rightLetter' />}</label>
                  <TextField
                    id='rightLetter'
                    label={null}
                    placeholder=''
                    value={props.form.attributes.rightLetter || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'rightLetter',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <label>{<Translate id='number' />}</label>
                  <TextField
                    id='number'
                    label={null}
                    placeholder=''
                    value={props.form.attributes.number || ''}
                    onChange={e => {
                      return props.handleChangeForAttributesValues(
                        'number',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                
              </Grid>
              {state.errorResponse ? <p>WASL ERROR: {state.errorResponse}</p>: null}
              {props.vehicleStatus ? <ul className="process-list">
                <li>Vehicle Status: {props.vehicleStatus}</li>
            </ul> : null}
            </form>
          }
        />
      </div>
    )
  // }
}

export default addVehicle